import React from "react";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import SkiptiDialog from "../../skipti/SkiptiDialog";
import EnterZipStep from "./Steps/EnterZipStep";
import { steps } from "./staticData";
import HowDoesItWork from "./Steps/HowDoesItWork";
import ProductsStep from "./Steps/ProductsStep";

const _styles = {
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const LocationBarPopup = ({
  texts,
  toggle,
  isChangeDialogOpen,
  currentStep,
  onSubmit,
  values,
  changeHandler,
  errors,
  isProcessing,
  additionalStepClickHandler,
  openDialog,
  setPageNumber,
  getProducts,
  zip,
}) => {
  return (
    <>
      <div
        className="locationContainer tw-flex tw-justify-evenly tw-items-center  tw-border-darkcharcoal tw-rounded-full tw-border-2 tw-p-2
        tw-cursor-pointer tw-absolute tw-z-10 tw-bg-white locationBar hover:tw-text-skiptiOrange hover:tw-border-skiptiOrange hover:tw-transition-all tw-duration-150"
        onClick={openDialog}
      >
        <div className="tw-p-1">
          <MyLocationIcon fontSize="medium" />
        </div>

        <p className="tw-text-sm fontSizeQuery">
          {texts.availableText}
          &nbsp;
          <strong>{texts.address}</strong>
        </p>
      </div>

      <SkiptiDialog
        fullWidth
        open={isChangeDialogOpen}
        toggle={zip ? toggle : null}
        withCloseIcon={!!zip}
        className="paymentStyleCenter"
        maxWidth="xl"
        title=" "
        PaperProps={{
          style: {
            height: "100%",
          },
        }}
        backgroundStyles={
          currentStep === steps.howDoesItWork
            ? _styles
            : {
                ..._styles,
                backgroundImage: "url(/bitmap_map.svg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }
        }
      >
        <div className="tw-flex tw-justify-center">
          <div>
            {currentStep !== steps.howDoesItWork ? (
              <div className="tw-flex tw-justify-center tw-mb-10">
                <img
                  className=" tw-w-32"
                  src="/bitmap_icon.svg"
                  alt="location_icon"
                />
              </div>
            ) : null}
            <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
              {currentStep === steps.howDoesItWork ? (
                <HowDoesItWork returnFunc={additionalStepClickHandler} />
              ) : currentStep !== steps.withProductsStep ? (
                <EnterZipStep
                  changeHandler={changeHandler}
                  isProcessing={isProcessing}
                  additionalStepClickHandler={additionalStepClickHandler}
                  onSubmit={onSubmit}
                  errors={errors}
                  currentStep={currentStep}
                  values={values}
                />
              ) : (
                <ProductsStep
                  changeHandler={changeHandler}
                  isProcessing={isProcessing}
                  onSubmit={onSubmit}
                  errors={errors}
                  currentStep={currentStep}
                  values={values}
                  setPageNumber={setPageNumber}
                  getProducts={getProducts}
                  zip={zip}
                  toggle={toggle}
                />
              )}
            </div>
          </div>
        </div>
      </SkiptiDialog>
    </>
  );
};

export default LocationBarPopup;
