import React, { Component } from "react";
import { connect } from "react-redux";
import { withWidth } from "@material-ui/core";
import { getCityName } from "../../reducers/authReducer";
import { imageServerUrl } from "../../utils";

class HeroDiscoverEvents extends Component {
  state = {};

  render() {
    const heroText = "The Easiest Way To Get Your Event Up And Running";
    const heroDescription =
      "We know preparing for events can be time consuming and expensive. Events.com rental service allows you to book the rental gear you need to make your event a success.<br/>You'll find everything you need from a PA system and tents, to party games and more.";

    return (
      <div className="tw-mt-4  tw-p-0 skipti-hero tw-text-white discover ">
        <div className="tw-w-full tw-h-full">
          <div
            className="tw-flex  md:tw-p-12 tw-p-6  tw-h-full  tw-text-white sm:tw-text-black"
            style={{
              backgroundColor: "#00bcda",
            }}
          >
            <div className=" md:tw-w-1/2 tw-my-3  tw-self-center tw-text-white">
              <h2 className="md:tw-w-2/3 tw-mb-6">{heroText}</h2>
              <div
                className=" tw-my-3"
                dangerouslySetInnerHTML={{ __html: heroDescription }}
              />

              <div
                className="tw-align-self-end tw-align-items-end tw-w-100 text-center tw-my-3"
                style={{ zIndex: 2 }}
              >
                <a href="#" className="btn btn-skipti-primary float-right">
                  RENT YOUR GEAR
                </a>
              </div>
            </div>
            <div className="md:tw-flex tw-justify-center md:tw-w-1/2  tw-my-3 tw-hidden tw-self-center tw-text-white">
              <img
                src={`${imageServerUrl}/partners/events/headerimageevents.png`}
                alt=""
                style={{
                  zIndex: "2",
                  width: "500px",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  city: getCityName(state),
});

export default connect(mapStateToProps)(withWidth()(HeroDiscoverEvents));
