import React, { Component } from "react";
import PropTypes from "prop-types";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { MixpanelConsumer } from "react-mixpanel";
import { connect } from "react-redux";
import { Modal } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { detect } from "detect-browser";
import queryString from "querystring";
import {
  getUserInfo,
  setSocialId,
  fetchAddressById,
  showSnackbar,
  setReturnUrl,
  openLogInModal,
  setClient,
  fetchLocationByIPAddress,
  setItemRentQueryStringOptions,
  setZipCode,
} from "./actions";
import { getUser, getIsLoggedIn, getToken } from "./reducers/authReducer";
import { getAddressById } from "./reducers/addressesReducer";
import { getItemRentInfo } from "./reducers/itemRentReducer";

import AuthRoute from "./components/utils/AuthRoute";
import ScrollToTop from "./components/utils/ScrollToTop";
import NoMatch from "./components/utils/NoMatch";
import ItemReject from "./components/partials/ItemReject";
import ItemApprove from "./components/partials/ItemApprove";
import CustomerPaymentFailed from "./components/partials/CustomerPaymentFailed";
import Main from "./components/main";

// import ItemsShow from "./components/items/ItemsShow";
import Discover from "./components/partials/Discover";
import Owners from "./components/Owners";
import NewExperience from "./components/experiences/NewExperience";
import EditExperience from "./components/experiences/EditExperience";
import ShowExperience from "./components/experiences/ShowExperience";
import IndexExperience from "./components/experiences/IndexExperience";
// import FinalizeRentRequest from "./components/items/FinalizeRentRequest";
import Renters from "./components/Renters";
import ShowCategory from "./components/categories/ShowCategory";
import Profile from "./components/users/Profile";
import OrganizationProfile from "./components/partials/OrganizationProfile";
import ContactUs from "./components/ContactUs";
import About from "./components/About";
import Investors from "./components/Investors";
import SkiptiCoins from "./components/SkiptiCoins";
import Support from "./components/Support";
import ShowArticle from "./components/articles/ShowArticleV2";
import ArticleList from "./components/articles/ArticleList";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import "./index.css";
// import history from "./history";
// import ItemsEdit from "./components/items/ItemsEdit";
import asyncComponent from "./components/utils/AsyncComponent";
import ConfirmEmail from "./components/partials/ConfirmEmail";
import Unsubscribe from "./components/partials/Unsubscribe";
import SkiptiSnackbar from "./components/utils/Snackbar";
import CompleteYourProfile from "./components/complete_profile/CompleteYourProfile";
// import AdminArticleList from "./components/admin_articles/AdminArticleList";
import RedundantApproval from "./components/partials/RedundantApproval";
import {
  getCustomerReviewModalState,
  getMerchantReviewModalState,
} from "./reducers/userReducer";
// import MerchantReview from "./components/reviews/MerchantReview";
import NewArrivalsList from "./components/new_arrivals/NewArrivalsList";
import TrendingList from "./components/trending/TrendingList";
import ResetPassword from "./components/partials/ResetPassword";

// import ReactGA from "react-ga";
import mainHistory from "./history";
import { webServerUrl } from "./utils/index";

import VoteItems from "./components/voting/VoteItems";
import SocialShare from "./components/partials/SocialShare";
import Ping from "./components/skipti/Ping";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
import ItemShow from "./components/items/item_show/ItemShow";
import Blacklist from "./components/blacklist/Blacklist";
import {
  SuperAdmin,
  SkiptiPartnerMaintenanceAdmin,
  SuperSkiptiAdmin,
  PartnerAdmin,
  AllAdmins, AssociateAdmin
} from "./accessRoleChecks";
// import CustomerReview from "./components/reviews/CustomerReview";
import DiscoverPartner from "./components/partials/DiscoverPartner";
import SendRental from "./components/dashboard/SendRental";

const AsyncCustomerReview = asyncComponent(() =>
  import("./components/reviews/CustomerReview")
);
const AsyncMerchantReview = asyncComponent(() =>
  import("./components/reviews/MerchantReview")
);
const AsyncDriverReview = asyncComponent(() =>
  import("./components/reviews/DriverReview")
);
// import ItemsShow from "./components/items/ItemsShow";

const AsyncWaiver = asyncComponent(() =>
  import("./components/partials/Waiver")
);

const AsyncTermsAndConditions = asyncComponent(() =>
  import("./components/partials/TermsAndConditions")
);

const AsyncPrivacyPolicy = asyncComponent(() =>
  import("./components/partials/PrivacyPolicy")
);

const AsyncOrders = asyncComponent(() =>
  import("./components/dashboard/NewOrders")
);
const AsyncAdminOrders = asyncComponent(() =>
  import("./components/dashboard/admin/AdminOrders/index")
);
const AsyncAdminOrdersForToday = asyncComponent(() =>
  import("./components/dashboard/admin/AdminOrdersForToday")
);
const AsyncAdminDepots = asyncComponent(() =>
  // import("./components/admin_depots/DepotList")
  import("./components/admin_depots/new/DepotList")
);
const AsyncAdminDepot = asyncComponent(() =>
  // import("./components/admin_depots/Depot")
  import("./components/admin_depots/new/Depot/index")
);
const AsyncAdminDepotTime = asyncComponent(() =>
  // import("./components/admin_depots/Depot")
  import("./components/admin_depots/new/Depot/DepotWithTimeFilter")
);
const AsyncServiceRecords = asyncComponent(() =>
  import("./components/admin_serviceRecords/ServiceRecords")
);
const AsyncAdminOrderOptions = asyncComponent(() =>
  import("./components/admin_orderOptions/OrderOptions")
);
const AsyncAdminReports = asyncComponent(() =>
  import("./components/admin_reports/Reports")
);
const AsyncTeamMembers = asyncComponent(() =>
  import("./components/admin_teamMembers/TeamMembers")
);
const AsyncCustomers = asyncComponent(() =>
  import("./components/admin_customers/Customers")
);
const AsyncAdminDrivers = asyncComponent(() =>
  import("./components/admin_drivers/DriversList")
);
const AsyncAdminDriver = asyncComponent(() =>
  import("./components/admin_drivers/Driver")
);

const AsyncAdminDmsRoutes = asyncComponent(() =>
  import("./components/admin_dms/Routes/DMSRoutes")
);

const AsyncAdminDmsManifest = asyncComponent(() =>
  import("./components/admin_dms/DriverManifest/DriverManifest")
);

const AsyncAdminDmsIndividualRoute = asyncComponent(() =>
  import("./components/admin_dms/Routes/IndividualPage/RoutesIndividualPage")
);

const AsyncAdminDmsDrivers = asyncComponent(() =>
  import("./components/admin_dms/Drivers/DMSDrivers")
);

const AsyncAdminDmsIndividualPage = asyncComponent(() =>
  import("./components/admin_dms/Drivers/IndividualDriver/DmsDriver")
);

const AsyncAdminDmsTeams = asyncComponent(() =>
  import("./components/admin_dms/DMSTeams")
);

const AsyncAdminIndividualTeam = asyncComponent(() =>
  import("./components/admin_dms/IndividualTeam/IndividualTeam")
);

const AsyncUnifiedCalendar = asyncComponent(() =>
  import("./components/dashboard/OrderCalendar")
);
const AsyncCalendar = asyncComponent(() =>
  import("./components/dashboard/UserOrderCalendar")
);
// const AsyncOrderDetails = asyncComponent(() =>
//   import("./components/dashboard/OrderDetails")
// );

const AsyncOrderDetails = asyncComponent(() =>
  import("./components/dashboard/OrderDetail")
);

const AsyncPrintReceiptOrder = asyncComponent(() =>
  import("./components/dashboard/PrintReceipt/OrderPrintReceipt")
);

const AsyncMyItems = asyncComponent(() =>
  import("./components/dashboard/MyItems")
);

const AsyncAddEditCreditCard = asyncComponent(() =>
  import("./components/dashboard/AddEditCreditCard")
);
const AsyncPaymentOptionsList = asyncComponent(() =>
  import("./components/dashboard/PaymentOptionsList")
);
const AsyncChangePassword = asyncComponent(() =>
  import("./components/dashboard/ChangePassword")
);
const AsyncDashboardOverview = asyncComponent(() =>
  import("./components/dashboard/DashboardOverview")
);
const AsyncItemRentComplete = asyncComponent(() =>
  import("./components/items/rent/ItemRentComplete")
);
const AsyncItemDemoComplete = asyncComponent(() =>
  import("./components/items/ItemDemo/ItemDemoComplete")
);
// AsyncItemSubscriptionComplete
const AsyncItemSubscriptionComplete = asyncComponent(() =>
  import("./components/items/ItemSubscriptionFlow/ItemSubscriptionComplete")
);
const AsyncMyProfile = asyncComponent(() =>
  import("./components/dashboard/MyProfile")
);
const AsyncPolicyPortal = asyncComponent(() =>
  import("./components/admin_policyPortal/PolicyPortal")
);
const AsyncIndividualPolicyPortal = asyncComponent(() =>
  import(
    "./components/admin_policyPortal/IndividualPolicyPortal/IndividualPolicyPortal"
  )
);
// const AsyncCalendar = asyncComponent(() =>
//   import("./components/dashboard/Calendar")
// );
const AsyncReviews = asyncComponent(() =>
  import("./components/dashboard/Reviews")
);
const AsyncAddresses = asyncComponent(() =>
  import("./components/dashboard/Addresses")
);
const AsyncCreateAddress = asyncComponent(() =>
  import("./components/dashboard/CreateAddress")
);
const AsyncResources = asyncComponent(() =>
  import("./components/dashboard/Resources")
);

const AsyncItemsEdit = asyncComponent(() =>
  import("./components/items/ItemsEdit")
);
const AsyncOrderComplete = asyncComponent(() =>
  import("./components/partials/OrderComplete")
);
const AsyncAddArticle = asyncComponent(() =>
  import("./components/admin_articles/AddArticle")
);
const AsyncAdminArticleList = asyncComponent(() =>
  import("./components/admin_articles/AdminArticleList")
);
const AsyncEditArticle = asyncComponent(() =>
  import("./components/admin_articles/EditArticle")
);
const AsyncItemsNew = asyncComponent(() =>
  import("./components/items/ItemsNew")
);
const AsyncItemsSearch = asyncComponent(() =>
  import("./components/items/ItemsSearch")
);
const AsyncPNLReport = asyncComponent(() =>
  import("./components/reports/PNLReport")
);
const AsyncItemRent = asyncComponent(() =>
  import("./components/items/ItemRent")
);
const AsyncItemDemo = asyncComponent(() =>
  import("./components/items/ItemDemo/ItemDemoFlow")
);

const AsyncItemAppointment = asyncComponent(() =>
  import("./components/items/itemAppointment/ItemAppointmentFlow")
);

const AsyncItemAppointMentComplete = asyncComponent(() =>
  import("./components/items/itemAppointment/ItemAppointmentComplete")
);

const AsyncItemSubscription = asyncComponent(() =>
  import("./components/items/ItemSubscriptionFlow/ItemSubscriptionFlow")
);

const AsyncItemDeliver = asyncComponent(() =>
  import("./components/items/DeliverWizard/ItemDeliver")
);

const AsyncArner = asyncComponent(() => import("./components/team/Arner"));
const AsyncMike = asyncComponent(() => import("./components/team/Mike"));
const AsyncLynn = asyncComponent(() => import("./components/team/Lynn"));

const AsyncModifyDeliveryForOrder = asyncComponent(() =>
  import("./components/dashboard/admin/ModifyDeliveryForOrder")
);

const AsyncCreateOrderForOtherUser = asyncComponent(() =>
  import("./components/dashboard/admin/CreateOrderForOtherUser")
);

const AsyncItemBuyFlow = asyncComponent(() =>
  import("./components/items/ItemBuy/ItemBuyFlow")
);

const AsyncItemBuyAppointmentFlow = asyncComponent(() =>
  import("./components/items/itemAppointmentBuy/AppointmentBuyFlow")
);
const AsyncItemBuyAppointmentComplete = asyncComponent(() =>
  import("./components/items/itemAppointmentBuy/ItemAppointmentBuyComplete")
);

const AsyncItemBuyComplete = asyncComponent(() =>
  import("./components/items/ItemBuy/ItemBuyComplete")
);
const AsyncOrderBuyFlow = asyncComponent(() =>
  import("./components/items/OrderBuy/OrderBuyFlow")
);
const AsyncOrderBuyComplete = asyncComponent(() =>
  import("./components/items/OrderBuy/OrderBuyComplete")
);
const AsyncSendRental = asyncComponent(() =>
  import("./components/dashboard/SendRental")
);
// mockupPage
const AsyncHDMockupPage = asyncComponent(() =>
  import("./components/partner/homedepot/mockupPage")
);

// if (process.env.NODE_ENV !== 'production') {
//    const { whyDidYouUpdate } = require('why-did-you-update')
//    whyDidYouUpdate(React,{ exclude: /^Connect/ })
// }

// const trackPageView = location => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// };

// const initGa = h => {
//   ReactGA.initialize(googleAnalyticsTrackingID, {
//     debug: true
//   });
//   trackPageView(h.location);
//   h.listen(trackPageView);
// };

// initGa(history);

class App extends Component {
  static propTypes = {
    getUserInfo: PropTypes.func.isRequired,
    isCustomerReviewModalOpen: PropTypes.bool.isRequired,
    isMerchantReviewModalOpen: PropTypes.bool.isRequired,
  };

  async componentDidMount() {
    // const advancedMatching = {
    //   em: this.props.current_user.email
    // }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    // const options = {
    //   autoConfig: true, // set pixel's autoConfig
    //   debug: false // enable logs
    // };

    // ReactPixel.init("351382278841500", advancedMatching, options);
    // ReactPixel.pageView();
    // ReactPixel.fbq("track", "PageView");

    this.props.fetchLocationByIPAddress();

    const browser = detect();

    // handle the case where we don't detect the browser
    if (browser) {
      console.log(browser.name);
      console.log(browser.version);
      console.log(browser.os);
      if (browser.name === "ie") {
        // eslint-disable-next-line no-alert
        alert(
          "We detected that you are using Internet Explorer, which is not supporting some of our features. Please use a more modern browser like Chrome or Firefox"
        );
      }
    }
    const queryParams = queryString.parse(window.location.search.slice(1));

    if (Object.keys(queryParams).length !== 0) {
      this.props.setItemRentQueryStringOptions({
        promoCode: queryParams.promoCode,
        source: queryParams.source,
      });

      // ------- get Client parameter to Local Storage and save it to redux if it's not already there
      // const fromClient = localStorage.getItem("fromClient");

      // if (
      //   // fromClient &&
      //   this.props.itemRentInfo &&
      //   !this.props.itemRentInfo.client
      // ) {
      //   this.props.setClient(fromClient);
      // }

      if (queryParams.client) {
        this.props.setClient(queryParams.client);

        // save Client parameter to Local Storage
        // localStorage.setItem("fromClient", queryParams.client);
      }
      if (queryParams.zip) {
        this.props.setZipCode(queryParams.zip);
      }

      if (!this.props.isLoggedIn) {
        if (queryParams.signin) this.props.openLogInModal();
        else if (queryParams.signup) this.props.openLogInModal(true);
      }
    }
    // this.props.setReturnUrl(window.location.pathname + window.location.search);
    await this.props.getUserInfo(null, false, window.analytics);
    // if (
    //   this.props.isLoggedIn &&
    //   this.props.current_user &&
    //   this.props.current_user.defaultLocation &&
    //   this.props.current_user.defaultLocation !==
    //     "00000000-0000-0000-0000-000000000000"
    // ) {
    //   await this.props.fetchAddressById(
    //     this.props.current_user.defaultLocation
    //   );

    //   if (
    //     this.props.addressById &&
    //     this.props.addressById.data &&
    //     this.props.addressById.data.address &&
    //     this.props.addressById.data.address.stateProvince !== "MA"
    //   ) {
    //     this.props.showSnackbar(
    //       <div style={{ width: "300px" }}>
    //         <p>Your Address is not in our Beta Service Area</p>
    //       </div>,
    //       "info",
    //       10000
    //     );
    //   }
    // }

    // axios.defaults.headers.common.Authorization = `Bearer ${this.props.token}`;

    this.props.setSocialId(window.location.search.split("kid=")[1]);
  }

  render() {
    const { isCustomerReviewModalOpen, isMerchantReviewModalOpen } = this.props;

    return (
      <Router history={mainHistory}>
        <MixpanelConsumer>
          {(mixpanel) => (
            <ScrollToTop>
              <div className="tw-flex tw-flex-col tw-h-full">
                <ErrorBoundary>
                  <Helmet>
                    <title>Skipti - Freedom To Experience More</title>

                    {/* <!-- For Google --> */}
                    <meta
                      name="description"
                      content="Innovative rentals delivered to your door."
                    />
                    <meta
                      name="keywords"
                      content="Skipti, rent, rent cool things, rental platform"
                    />
                    <meta name="author" content="" />
                    <meta name="copyright" content="" />
                    <meta name="application-name" content="" />

                    {/* <!-- For Facebook --> */}
                    <meta
                      property="og:title"
                      content="Skipti - Freedom To Experience More"
                    />
                    <meta property="og:type" content="article" />
                    <meta
                      property="og:image"
                      content={`${webServerUrl}/images/logo-skipti-vertical.jpg`}
                    />
                    <meta property="og:image:type" content="image/jpg" />

                    <meta property="og:url" content={webServerUrl} />
                    <meta
                      property="og:description"
                      content="Innovative rentals delivered to your door."
                    />
                    <meta property="fb:app_id" content="232980713801350" />

                    {/* <!-- For Twitter --> */}
                    <meta name="twitter:card" content="summary" />
                    <meta
                      name="twitter:title"
                      content="Skipti - Freedom To Experience More"
                    />
                    <meta
                      name="twitter:description"
                      content="Innovative rentals delivered to your door."
                    />
                    {/* <meta
                name="twitter:image"
                content="https://skiptiwebsiteprod.azurewebsites.net/images/logo-skipti-vertical.jpg"
              /> */}
                  </Helmet>
                  <Switch>
                    <Route exact path="/">
                      <Main mixpanel={mixpanel} />
                    </Route>
                    {/* <Route path="/newarrivals">
                      <NewArrivalsList />
                    </Route> */}
                    {/* <Route path="/trending">
                      <TrendingList />
                    </Route> */}

                    <AuthRoute
                      path="/items/new"
                      component={AsyncItemsNew}
                      accessRoleCheck={PartnerAdmin}
                    />
                    <Route path="/items/search">
                      <AsyncItemsSearch mixpanel={mixpanel} />
                    </Route>
                    <Route path="/items/rent/:id/complete">
                      {({ location, match, history }) => (
                        <AsyncItemRentComplete
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/items/demo/:id/complete">
                      {({ location, match, history }) => (
                        <AsyncItemDemoComplete
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/items/appointment/:id/complete">
                      {({ location, match, history }) => (
                        <AsyncItemAppointMentComplete
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/items/buyAppointment/:id/complete">
                      {({ location, match, history }) => (
                        <AsyncItemBuyAppointmentComplete
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/items/subscription/:id/complete">
                      {({ location, match, history }) => (
                        <AsyncItemSubscriptionComplete
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/items/rent/:id">
                      {({ location, match, history }) => (
                        <AsyncItemRent
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                      {/* <AsyncItemRent /> */}
                    </Route>
                    <Route path="/items/demo/:id">
                      {({ location, match, history }) => (
                        <AsyncItemDemo
                          match={match}
                          location={location}
                          history={history}
                          mixpanel={mixpanel}
                        />
                      )}
                      {/* <AsyncItemRent /> */}
                    </Route>
                    <Route path="/items/appointment/:id">
                      {({ location, match, history }) => (
                        <AsyncItemAppointment
                          match={match}
                          location={location}
                          history={history}
                          mixpanel={mixpanel}
                        />
                      )}
                      {/* <AsyncItemRent /> */}
                    </Route>
                    <Route path="/items/subscription/:id">
                      {({ location, match, history }) => (
                        <AsyncItemSubscription
                          match={match}
                          location={location}
                          history={history}
                          mixpanel={mixpanel}
                        />
                      )}
                      {/* <AsyncItemRent /> */}
                    </Route>
                    <Route path="/items/deliver/:id">
                      {({ location, match, history }) => (
                        <AsyncItemDeliver
                          match={match}
                          location={location}
                          history={history}
                        />
                      )}
                    </Route>
                    {/* AsyncOrderBuyFlow */}
                    <Route path="/orders/buy/:id/complete">
                      {({ location, match, history }) => (
                        <AsyncOrderBuyComplete
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/orders/buy/:id">
                      {({ location, match, history }) => (
                        <AsyncOrderBuyFlow
                          match={match}
                          location={location}
                          history={history}
                        />
                      )}
                    </Route>
                    <Route path="/items/buy/:id/complete">
                      {({ location, match, history }) => (
                        <AsyncItemBuyComplete
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/items/buyAppointment/:id">
                      {({ location, match, history }) => (
                        <AsyncItemBuyAppointmentFlow
                          match={match}
                          location={location}
                          history={history}
                        />
                      )}
                    </Route>
                    <AuthRoute
                      path="/items/edit/:id"
                      component={AsyncItemsEdit}
                    />
                    {/* <Route
                      path="/items-old/:id"
                      // render={() => (
                      //   <ItemsShow
                      //     mixpanel={
                      //       null
                      //       // mixpanel
                      //     }
                      //   />
                      // )}
                      component={ItemsShow}
                    /> */}
                    <Route
                      path="/items/:id"
                      render={() => (
                        <ItemShow
                          mixpanel={
                            // null
                            mixpanel
                          }
                        />
                      )}
                      // component={ItemShow}
                    />
                    <Route path="/item/reject/:jwt">
                      {({ match }) => (
                        <ItemReject
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/item/approve/:jwt">
                      {({ match }) => (
                        <ItemApprove
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/item/redundant/:jwt">
                      {({ match }) => (
                        <RedundantApproval
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/item/customerpaymentfaield/:jwt">
                      <CustomerPaymentFailed />
                    </Route>
                    <AuthRoute
                      path="/dashboard/items"
                      component={AsyncMyItems}
                      accessRoleCheck={PartnerAdmin}
                    />
                    <AuthRoute
                      path="/dashboard/changepassword"
                      component={AsyncChangePassword}
                    />
                    <AuthRoute
                      path="/dashboard/profile"
                      component={AsyncMyProfile}
                    />
                    <AuthRoute
                      exact
                      path="/dashboard/policyportal"
                      component={AsyncPolicyPortal}
                      accessRoleCheck={AllAdmins}
                    />
                    <AuthRoute
                      path="/dashboard/policyportal/:folderId"
                      component={AsyncIndividualPolicyPortal}
                      accessRoleCheck={AllAdmins}
                    />
                    <AuthRoute
                      path="/dashboard/addresses/new"
                      component={AsyncCreateAddress}
                    />
                    <AuthRoute
                      path="/dashboard/addresses/edit/:id"
                      component={AsyncCreateAddress}
                    />
                    <AuthRoute
                      exact
                      path="/dashboard/addresses"
                      component={AsyncAddresses}
                    />
                    <AuthRoute
                      exact
                      accessRoleCheck={SuperAdmin}
                      path="/dashboard/articles"
                      component={AsyncAdminArticleList}
                    />
                    <AuthRoute
                      exact
                      accessRoleCheck={SuperAdmin}
                      path="/articles/add"
                      component={AsyncAddArticle}
                    />
                    <AuthRoute
                      exact
                      accessRoleCheck={SuperAdmin}
                      path="/articles/edit/:id"
                      component={AsyncEditArticle}
                    />
                    <AuthRoute
                      path="/dashboard/unified-calendar"
                      accessRoleCheck={AllAdmins}
                      component={AsyncUnifiedCalendar}
                    />
                    <AuthRoute
                      path="/dashboard/reviews/driver/:id?"
                      component={AsyncDriverReview}
                    />
                    <AuthRoute
                      path="/dashboard/reviews/:type?"
                      component={AsyncReviews}
                    />
                    <AuthRoute
                      path="/dashboard/resources"
                      component={AsyncResources}
                    />
                    <AuthRoute
                      path="/dashboard/payment-options/add"
                      component={AsyncAddEditCreditCard}
                    />
                    <AuthRoute
                      path="/dashboard/payment-options"
                      component={AsyncPaymentOptionsList}
                    />
                    <AuthRoute
                      path="/dashboard/send-rental"
                      component={AsyncSendRental}
                    />
                    <AuthRoute
                      exact
                      path="/dashboard"
                      component={AsyncDashboardOverview}
                    />
                    <AuthRoute
                      exact
                      path="/completeyourprofile"
                      component={CompleteYourProfile}
                    />
                    <Route path="/users/:id">
                      {({ match }) => (
                        <Profile
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    {/* <Route path="/organization/:id">
                      <OrganizationProfile />
                    </Route> */}
                    <Route path="/partners/:name">
                      <OrganizationProfile />
                    </Route>
                    <Route path="/members/:name">
                      <OrganizationProfile />
                    </Route>
                    <Route path="/discover">
                      <Discover />
                    </Route>
                    <Route path="/events-com">
                      <DiscoverPartner events />
                    </Route>
                    <Route path="/organization/:id">
                      {({ match }) => (
                        <DiscoverPartner
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/newpartners/:name">
                      {({ match }) => (
                        <DiscoverPartner
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    {/* <Route path="/categories/:categoryName">
                      {({ match }) => (
                        <ShowCategory
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route> */}
                    {/* <Route exact path="/renters">
                      <Renters />
                    </Route> */}
                    {/* <Route path="/renters/register" /> */}
                    {/* <Route exact path="/owners">
                      <Owners />
                    </Route> */}
                    {/* <Route path="/owners/register" /> */}
                    <Route exact path="/experiences">
                      <IndexExperience />
                    </Route>
                    <Route path="/experiences/edit/:id">
                      {({ match }) => (
                        <EditExperience
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/experiences/new">
                      <NewExperience />
                    </Route>
                    <Route path="/experiences/:id">
                      {({ match }) => (
                        <ShowExperience
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    {/* <Route path="/contactus">
                      <ContactUs />
                    </Route> */}
                    <Route path="/about">
                      <About />
                    </Route>
                    {/* <Route path="/investors">
                      <Investors />
                    </Route> */}
                    {/* <Route path="/skipticoins">
                      <SkiptiCoins />
                    </Route> */}
                    {/* <Route path="/support">
                      <Support />
                    </Route> */}
                    <Route path="/articles/:id">
                      {({ match }) => (
                        <ShowArticle
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/articles">
                      <ArticleList />
                    </Route>
                    <Route path="/confirmemail/:jwt">
                      {({ match }) => (
                        <ConfirmEmail
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/unsubscribe">
                      <Unsubscribe />
                    </Route>
                    <Route path="/privacypolicy">
                      <AsyncPrivacyPolicy />
                    </Route>
                    <Route path="/termsandconditions">
                      <AsyncTermsAndConditions />
                    </Route>
                    <Route path="/ordercomplete/:jwt">
                      {({ match }) => (
                        <AsyncOrderComplete
                          match={match}
                          // mixpanel={mixpanel}
                        />
                      )}
                    </Route>
                    <Route path="/vote">
                      <VoteItems />
                    </Route>
                    <AuthRoute
                      path="/dashboard/orders"
                      component={AsyncOrders}
                    />
                    <AuthRoute
                      path="/dashboard/admin/adminorders"
                      component={AsyncAdminOrders}
                      accessRoleCheck={AllAdmins}
                    />
                    <AuthRoute
                      path="/dashboard/admin/orders-for-today"
                      component={AsyncAdminOrdersForToday}
                      accessRoleCheck={AllAdmins}
                    />
                    <AuthRoute
                      path="/dashboard/admin/admindepots/:depotId"
                      component={AsyncAdminDepot}
                      accessRoleCheck={AllAdmins}
                      exact
                    />
                    <AuthRoute
                      path="/dashboard/admin/admindepots/:depotId/filtered"
                      component={AsyncAdminDepotTime}
                      accessRoleCheck={AllAdmins}
                      exact
                    />
                    <AuthRoute
                      path="/dashboard/admin/admindepots"
                      component={AsyncAdminDepots}
                      accessRoleCheck={AllAdmins}
                    />
                    <AuthRoute
                      path="/dashboard/admin/service-records"
                      component={AsyncServiceRecords}
                      accessRoleCheck={AllAdmins}
                    />
                    <AuthRoute
                      path="/dashboard/admin/order-options"
                      component={AsyncAdminOrderOptions}
                      accessRoleCheck={AssociateAdmin}
                    />
                    <AuthRoute
                      path="/dashboard/admin/reports"
                      component={AsyncAdminReports}
                      accessRoleCheck={SkiptiPartnerMaintenanceAdmin}
                    />

                    {/* Remove later */}
                    {/* <AuthRoute
                      path="/dashboard/admin/team-members"
                      component={AsyncTeamMembers}
                      accessRoleCheck={SkiptiPartnerMaintenanceAdmin}
                    /> */}

                    <AuthRoute
                      path="/dashboard/admin/customers"
                      component={AsyncCustomers}
                      accessRoleCheck={AllAdmins}
                    />

                    {/* Remove this pages later */}

                    <AuthRoute
                      path="/dashboard/admin/admindrivers/:type/:driverId"
                      component={AsyncAdminDriver}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />
                    <AuthRoute
                      path="/dashboard/admin/admindrivers"
                      component={AsyncAdminDrivers}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />

                    <AuthRoute
                      path="/dashboard/admin/dms-routes"
                      component={AsyncAdminDmsRoutes}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />
                    <AuthRoute
                      path="/dashboard/admin/dms-route/:routeId"
                      component={AsyncAdminDmsIndividualRoute}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />
                    <AuthRoute
                      path="/dashboard/admin/dms-individual-driver/:driverId"
                      component={AsyncAdminDmsIndividualPage}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />
                    <AuthRoute
                      path="/dashboard/admin/dms-drivers"
                      component={AsyncAdminDmsDrivers}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />
                    <AuthRoute
                      path="/dashboard/admin/dms-teams"
                      component={AsyncAdminDmsTeams}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />
                    <Route path="/manifest/:orderId">
                      <AsyncAdminDmsManifest />
                    </Route>
                    <AuthRoute
                      path="/dashboard/admin/individual-team/:id"
                      component={AsyncAdminIndividualTeam}
                      accessRoleCheck={SuperSkiptiAdmin}
                    />

                    <AuthRoute
                      path="/dashboard/calendar"
                      component={AsyncCalendar}
                    />

                    <AuthRoute
                      path="/dashboard/order/modifydelivery/:id"
                      component={AsyncModifyDeliveryForOrder}
                      accessRoleCheck={AllAdmins}
                    />
                    <AuthRoute
                      path="/dashboard/order/:id"
                      component={AsyncOrderDetails}
                    />
                    <AuthRoute
                      path="/dashboard/print-receipt/order/:id"
                      component={AsyncPrintReceiptOrder}
                    />

                    <AuthRoute
                      path="/dashboard/orderforotheruser/:id"
                      component={AsyncCreateOrderForOtherUser}
                    />

                    <Route path="/socialshare">
                      <SocialShare />
                    </Route>
                    <Route path="/ping">
                      <Ping />
                    </Route>
                    <Route path="/howitworks">
                      <HowItWorks />
                    </Route>
                    <Route path="/waiver">
                      <AsyncWaiver />
                    </Route>
                    {/* <Route path="/arner">
                      <AsyncArner />
                    </Route>
                    <Route path="/mike">
                      <AsyncMike />
                    </Route>
                    <Route path="/lp">
                      <AsyncLynn />
                    </Route> */}
                    <Route
                      exact
                      path="/categories"
                      render={() => <Redirect to="/discover" />}
                    />
                    {/* <Route exact path="/resetpassword">
                      <ResetPassword />
                    </Route> */}
                    <Route exact path="/resetpassword">
                      {({ location, match, history }) => (
                        <ResetPassword
                          match={match}
                          location={location}
                          history={history}
                          // mixpanel={mixpanel}
                        />
                      )}
                      {/* <AsyncItemRent /> */}
                    </Route>
                    <Route exact path="/blacklist" component={Blacklist} />
                    <Route
                      exact
                      path="/homedepot-tiller"
                      component={AsyncHDMockupPage}
                    />

                    <Route>
                      <NoMatch />
                    </Route>
                    {/* <AuthRoute path="/items/new" component={ItemsNew} /> */}
                  </Switch>
                  <SkiptiSnackbar />
                  <Modal
                    open={isCustomerReviewModalOpen}
                    onClose={this.closeCustomerReviewModal}
                  >
                    <AsyncCustomerReview />
                  </Modal>
                  <Modal
                    open={isMerchantReviewModalOpen}
                    onClose={this.closeMerchantReviewModal}
                  >
                    <AsyncMerchantReview />
                  </Modal>
                </ErrorBoundary>
              </div>
            </ScrollToTop>
          )}
        </MixpanelConsumer>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  token: getToken(state),
  isLoggedIn: getIsLoggedIn(state),
  current_user: getUser(state),
  addressById: getAddressById(state),
  isCustomerReviewModalOpen: getCustomerReviewModalState(state),
  isMerchantReviewModalOpen: getMerchantReviewModalState(state),
  itemRentInfo: getItemRentInfo(state),
});

export default connect(mapStateToProps, {
  getUserInfo,
  setSocialId,
  fetchAddressById,
  showSnackbar,
  setReturnUrl,
  openLogInModal,
  setClient,
  fetchLocationByIPAddress,
  setItemRentQueryStringOptions,
  setZipCode,
})(App);
