import { useEffect } from "react";
import { useFormikContext } from "formik";
import { getFieldErrorNames } from "./helpers";

export const ScrollToErrorField = (props) => {
  const formikContext = useFormikContext();

  useEffect(() => {
    if (!formikContext) return;

    if (formikContext.isValid || !formikContext.isSubmitting) {
      return;
    }

    const fieldErrorNames = getFieldErrorNames(formikContext.errors);

    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name="${fieldErrorNames[0]}"]`
    );

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [formikContext]);

  return null;
};
