import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { showSnackbar } from "../../actions/snackbar";
import { serverUrl } from "../../utils";

export const selectBillingData = (state) => state.paymentInfo.billingData;
export const selectPaymentCardData = (state) =>
  state.paymentInfo.paymentCardData;
export const selectPaymentStripe = (state) => state.paymentInfo.cardStripeInfo;
export const selectNewCardExists = (state) => state.paymentInfo.isNewCard;
export const selectFetchingPaymentOptions = (state) =>
  state.paymentInfo.fetching;
export const selectIsBillingIsNew = (state) => state.paymentInfo.isNewBilling;

export const updatePaymentInfoData = createAsyncThunk(
  "updatePaymentInfo",
  async (values, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = state.auth.token;
    const paymentCard = state.paymentInfo.paymentCardData;
    const _billing = { ...state.paymentInfo.billingData };

    _billing.resourceId =
      _billing.resourceId === "empty string"
        ? "00000000-0000-0000-0000-000000000000"
        : _billing.resourceId;

    const _mapped = values ?? {};
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/resources/changebillingaddress/${paymentCard.id}`,
        { ..._billing, ..._mapped },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            isBillingAddress: true,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const update_NewPaymentInfoData = createAsyncThunk(
  "update_NewPaymentInfo",
  async ({ mappedAddress, userId, obj }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = state.auth.token;

    const billing = state.paymentInfo.billingData;
    const _addresses = state.addresses.byUserId.data.resources;

    let selectedObj;

    if (billing.resourceId === "empty string") {
      selectedObj = { ...billing };
      selectedObj.resourceId = "00000000-0000-0000-0000-000000000000";
    } else {
      selectedObj = _addresses.find(
        (el) => el.resourceId === billing.resourceId
      );
    }

    const newPaymentInfo = state.paymentInfo.cardStripeInfo;

    obj.location = { ...mappedAddress, ...selectedObj };
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/payment/account/${userId}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            token: newPaymentInfo.token.id,
            isDefault: true,
            isBillingAddress: true,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "paymentInfo",
  initialState: {
    isNewCard: false,
    isNewBilling: false,
    cardStripeInfo: null,
    billingData: "",
    paymentCardData: null,
    fetching: false,
    error: false,
    // paymentOptions: [],
  },
  reducers: {
    setCardStripeInfo: (state, { payload }) => ({
      ...state,
      cardStripeInfo: payload,
      isNewCard: true,
    }),
    setBillingData: (state, { payload }) => ({
      ...state,
      billingData: payload,
    }),
    setPaymentData: (state, { payload }) => ({
      ...state,
      paymentCardData: payload,
      isNewCard: false,
    }),
    setPaymentDataFromStripe: (state, { payload }) => ({
      ...state,
      paymentCardData: payload,
    }),
    setIsNewBilling: (state, { payload }) => ({
      ...state,
      isNewBilling: payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePaymentInfoData.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(updatePaymentInfoData.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.paymentOptions = [];
      })
      .addCase(updatePaymentInfoData.fulfilled, (state, { payload }) => {
        state.error = false;
        state.fetching = false;
        state.paymentOptions = payload ? payload.profiles : [];
      })
      .addCase(update_NewPaymentInfoData.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(update_NewPaymentInfoData.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.paymentOptions = [];
      })
      .addCase(update_NewPaymentInfoData.fulfilled, (state) => {
        state.error = false;
        state.fetching = false;
      });
  },
});
export const {
  setCardStripeInfo,
  setBillingData,
  setPaymentData,
  setPaymentDataFromStripe,
  setIsNewBilling,
} = slice.actions;

export default slice.reducer;
