import * as TYPES from "../types";
import * as helpers from "../utils";

const removeDeletedAddress = (addressResourceId, addressList) =>
  addressList.filter((c) => c.resourceId !== addressResourceId);

const addressesReducer = (
  state = {
    byUserId: helpers.makeInitial(),
    byId: helpers.makeInitial(),
    deliveryAddresses: [],
  },
  action
) => {
  const { payload, error } = action;

  switch (action.type) {
    case TYPES.ADDRESSES_BYUSERID_REQUEST:
      return { ...state, byUserId: helpers.makeStartLoading() };
    case TYPES.ADDRESSES_BYUSERID_SUCCESS:
      return {
        ...state,
        byUserId: helpers.makeLoadSuccess(payload),
        deliveryAddresses: payload.resources.filter(
          (loc) => !loc.address.isPOBox
        ),
      };
    case TYPES.ADDRESSES_BYUSERID_FAIL:
      return { ...state, byUserId: helpers.makeLoadFailed(error) };
    case TYPES.ADDRESS_BYID_REQUEST:
      return { ...state, byId: helpers.makeStartLoading() };
    case TYPES.ADDRESS_BYID_SUCCESS:
      return { ...state, byId: helpers.makeLoadSuccess(payload) };
    case TYPES.ADDRESS_BYID_FAIL:
      return { ...state, byId: helpers.makeLoadFailed(error) };
    case TYPES.CREATE_ADDRESS_REQUEST:
      return { ...state, createdAddress: helpers.makeStartLoading() };
    case TYPES.CREATE_ADDRESS_SUCCESS:
      return { ...state, createdAddress: helpers.makeLoadSuccess(payload) };
    case TYPES.CREATE_ADDRESS_FAIL:
      return { ...state, createdAddress: helpers.makeLoadFailed(error) };
    case TYPES.ADDRESS_BYID_DELETE_SUCCESS:
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          data: {
            ...state.byUserId.data,
            resources: removeDeletedAddress(
              action.addressResourceId,
              state.byUserId.data.resources
            ),
          },
        },
      };
    case TYPES.SIGN_OUT_SUCCESS:
      return {
        ...state,
        byId: helpers.makeInitial(),
        byUserId: helpers.makeInitial(),
      };
    default:
      return state;
  }
};

export const getAddressesByUserId = (state) =>
  state.addresses && state.addresses.byUserId.isLoaded
    ? state.addresses.byUserId.data.resources
    : [];
export const getAddressesIsFetching = (state) =>
  state.addresses.byUserId.isFetching;

export const getIsLoadedAddressesByUserId = (state) =>
  state.addresses.byUserId.isLoaded;

export const getIsFetchingAddresses = (state) =>
  state.addresses.byUserId.isFetching;
export const getAddressById = (state) => state.addresses.byId;
export const getCreatedAddress = (state) => state.addresses.createdAddress;
export const getDefaultAddressZip = (state) =>
  state.addresses.byId.isLoaded && state.addresses.byId.data.address.zipPostal;

export const getDeliveryAddresses = (state) =>
  state.addresses ? state.addresses.deliveryAddresses : [];
export default addressesReducer;
