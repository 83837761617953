import moment from "moment";
import React from "react";

const DateBlock = ({ dateRange, title, events, styles }) => {
  return (
    <div className="box tw-p-4" style={styles}>
      <p className="title tw-pb-1">
        <strong>{title?.toUpperCase()}</strong>
      </p>
      <p className="dates">
        {dateRange
          ? moment.tz(dateRange, "UTC").local().format("MM/DD/YYYY")
          : "No date selected"}
      </p>
      <p className="dates">
        {events
          ? `${moment.tz(events?.starts_at, "UTC").local().format("h:mma")} - 
                   ${moment.tz(events?.ends_at, "UTC").local().format("h:mma")}`
          : ""}
      </p>
    </div>
  );
};

export default DateBlock;
