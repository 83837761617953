/* eslint-disable */
(function (global) {
  if (!("HTMLCanvasElement" in global)) return;
  if (!("toDataURL" in global.HTMLCanvasElement.prototype)) return;
  if ("toBlob" in global.HTMLCanvasElement.prototype) return;
  Object.defineProperty(global.HTMLCanvasElement.prototype, "toBlob", {
    value(callback /* , type, encoderOptions */) {
      const url = this.toDataURL.apply(this, [].slice.call(arguments, 1));
      const m = /^data:(.*?);base64,(.*)$/.exec(url);
      const type = m[1];
      const b64 = m[2];
      setTimeout(() => {
        callback(
          new Blob(
            [
              new Uint8Array(
                global
                  .atob(b64)
                  .split("")
                  .map((c) => c.charCodeAt(0))
              ),
            ],
            { type }
          )
        );
      }, 0);
    },
    writable: true,
    enumerable: true,
    configurable: true,
  });
})(window.self);
