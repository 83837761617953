/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IconButton, Modal, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import SkiptiButton from "../skipti/Button";
import {
  deleteProduct,
  showSnackbar,
  fetchItemsByOwnerId,
} from "../../actions";
import { getUser, getToken } from "../../reducers/authReducer";

class EditProfileButtons extends Component {
  static propTypes = {
    resourceId: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
  };

  state = {
    areYouSureModalOpen: false,
  };

  handleDelete = () => () => {
    this.setState({
      areYouSureModalOpen: true,
    });
  };

  deleteItem = async () => {
    this.setState({
      areYouSureModalOpen: false,
    });
    this.props.deleteProfile(this.props.resourceId);
  };

  closeModal = () => {
    this.setState({ areYouSureModalOpen: false });
  };

  render() {
    const { resourceId, handleEditProfile, duplicateProfile } = this.props;
    return (
      <>
        <hr className="tw-mb-2" />
        <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-center">
          <div className="">
            <Tooltip placement="top" title="Edit">
              <IconButton onClick={() => handleEditProfile(resourceId)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </div>
          {duplicateProfile !== null && (
            <div className="">
              <Tooltip placement="top" title="Duplicate">
                <IconButton onClick={() => duplicateProfile(resourceId)}>
                  <AddToPhotosIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}

          <div className="">
            <Tooltip placement="top" title="Delete">
              <IconButton onClick={this.handleDelete(resourceId)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.areYouSureModalOpen}
          onClose={this.closeModal}
        >
          <div
            className="tw-max-w-screen-md tw-relative tw-mx-auto tw-m-8"
            role="document"
            tabIndex={-1}
          >
            <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md">
              <div className="tw-relative tw-flex-1 tw-p-16">
                Are you sure you want to delete the item?
                <div className="tw-text-center tw-mt-4">
                  <SkiptiButton
                    design="secondary dialog"
                    className="tw!-mr-2"
                    onClick={this.closeModal}
                    style={{ marginRight: "1rem" }}
                  >
                    No
                  </SkiptiButton>

                  <SkiptiButton
                    design="primary dialog"
                    onClick={this.deleteItem}
                  >
                    Yes
                  </SkiptiButton>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  current_user: getUser(state),
  jwt: getToken(state),
});
export default connect(mapStateToProps, {
  deleteProduct,
  showSnackbar,
  fetchItemsByOwnerId,
})(withRouter(EditProfileButtons));
