import React from "react";
import PropTypes from "prop-types";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";

export default function LayoutItem({ children, showSearch }) {
  return (
    <>
      <Navbar showSearch={showSearch} />
      <div className="tw-w-full tw-mx-auto content tw-px-0">{children}</div>
      <Footer />
    </>
  );
}
