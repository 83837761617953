import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

import { showSnackbar } from "../../../actions/snackbar";
import { getToken } from "../../../reducers/authReducer";
import { convertUtsOffsetToTimezoneAbr, serverUrl } from "../../../utils";

export const selectRegionTeams = (state) => state.dmsRoutes.regionTeams;

export const selectDmsRoutes = (state) => state.dmsRoutes.dmsRoutes;
export const selectDmsRoutesMeta = (state) => state.dmsRoutes.routesMeta;
export const selectRoutesFetching = (state) => state.dmsRoutes.routesFetching;
export const selectIndividualRoute = (state) => state.dmsRoutes.individualRoute;

export const selectCurrentTeamDrivers = (state) =>
  state.dmsRoutes.currentTeamDrivers;

export const selectRoutesHistory = (state) => state.dmsRoutes.dmsRoutesHistory;

export const selectRoutesHistoryMeta = (state) => state.dmsRoutes.historyMeta;
export const selectRoutesHistoryFetching = (state) =>
  state.dmsRoutes.routesHistoryFetching;

export const selectAttachmentHistoryFetching = (state) =>
  state.dmsRoutes.postAttachmentFetching;

export const selectChangeStatusPending = (state) =>
  state.dmsRoutes.pendingChangeStatus;

const splitingModelOfRouteByid = (model) => {
  const _keys = Object.keys(model);
  let _individualRoute = {};
  _keys.forEach((key) => {
    switch (key) {
      case "costUSD":
        _individualRoute.routePrice = model[key].amount;
        break;

      case "dropOffLocation":
        {
          const _temp = {};
          const _address = model[key].address;
          _temp.dropOffAdditionalInfo = model[key].additionalInfo;
          _temp.dropOffName = model[key].name;
          _temp.dropOffphone = model[key].phone;
          _temp.dropOffAddress = `${_address.street1}  ${_address.street2}, ${_address.city}, ${_address.stateProvince} ${_address.zipPostal}`;
          _individualRoute = { ..._individualRoute, ..._temp };
        }
        break;

      case "pickupLocation":
        {
          const _temp = {};
          const _address = model[key].address;
          _temp.pickupAdditionalInfo = model[key].additionalInfo;
          _temp.pickupName = model[key].name;
          _temp.pickupPhone = model[key].phone;
          _temp.pickupAddress = `${_address.street1} ${_address.street2}, ${_address.city}, ${_address.stateProvince} ${_address.zipPostal}`;
          _individualRoute = { ..._individualRoute, ..._temp };
        }
        break;

      case "dtBeginsLocal":
        {
          const _beginsDateLocal = moment(model[key]);
          const _endDateLocal = moment(model.dtEndsLocal);
          _individualRoute.dateWindow = `${_beginsDateLocal.format(
            "MM/DD/YYYY"
          )} | ${_beginsDateLocal.format("hh:mm a")}-${_endDateLocal.format(
            "hh:mm a"
          )} ${model.timezoneAbbr}`;
        }
        break;

      case "productInfoViewModel":
        {
          const _temp = {};

          _temp.addOn = model[key].productAddOn.length
            ? model[key].productAddOn.reduce(
                (acc, cur) => (acc += `${cur.name}, \n`),
                ""
              )
            : "";

          const productIncludes = model[key].productIncludes;

          if (productIncludes && productIncludes.length > 1) {
            productIncludes.splice(0, 1);

            _temp.includes = productIncludes.join(", ");
          }

          _temp.productName = model[key].productName;
          _individualRoute = { ..._individualRoute, ..._temp };
        }
        break;

      case "isDelivery":
        _individualRoute.direction = model[key] ? "Outbound" : "Inbound";
        break;

      case "driverId":
        _individualRoute.driverId = model.extDriverId;
        break;
      case "status":
        _individualRoute.status = model[key];
        _individualRoute.statusAsKey = model[key].replace(/ /g, "_");
        break;

      default:
        _individualRoute[key] = model[key];
        break;
    }
  });

  return _individualRoute;
};

export const getDmsRoutesNew = createAsyncThunk(
  "dmsDrivers/getDmsRoutesNew",
  async (
    {
      pageSize = 50,
      pageNumber = 1,
      loadArchive = false,
      from,
      to,
      search,
      sortBy,
    },
    thunkAPI
  ) => {
    const jwt = getToken(thunkAPI.getState());
    const _sendingData = {
      pageNumber,
      pageSize,
      LoadArchivedTickets: loadArchive,
    };

    if (from) {
      _sendingData.from = moment(from)
        .utc(true)
        .set({
          hour: 4,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toISOString();
    }
    if (to) {
      _sendingData.to = moment(to)
        .add(1, "day")
        .utc(true)
        .set({
          hour: 4,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toISOString();
    }

    if (search) {
      _sendingData.search = search;
    }
    if (sortBy) {
      _sendingData.sortBy = sortBy;
    }

    try {
      const res = await axios.get(`${serverUrl}/api/v1/route/dms`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: _sendingData,
      });

      const ticketsWithReasonProperty = [];
      const { data } = res;
      data.ticketsRoutesList.forEach((ticket) => {
        const _newTicket = {
          ...ticket.ticketWViewModel,
          reason: ticket.route ? ticket.route.reason : null,
          orderId: ticket.route ? ticket.route.fkOrderId : null,
          routeId: ticket.route ? ticket.route.id : null,
        };
        ticketsWithReasonProperty.push(_newTicket);
      });
      data.ticketsList = ticketsWithReasonProperty;
      return data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      );
    }
  }
);

export const changeStatus = createAsyncThunk(
  "dmsDriver/changeStatus",
  async ({ routeId, status, driverId }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    const _params = {
      status,
    };

    if (status === 100) {
      _params.driverId = driverId;
    }

    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/route/${routeId}/status `,
        _params,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const archiveUnarchiveRoutesOrder = createAsyncThunk(
  "dmsDriver/archiveRoutes",
  async ({ chosenRoutes, procedure }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const tickets = selectDmsRoutes(thunkAPI.getState());
    const _ticketsAsObj = {};
    tickets.forEach((ticket) => (_ticketsAsObj[ticket.id] = ticket));
    const _routesIds = [];
    chosenRoutes.forEach((route) => {
      _routesIds.push(_ticketsAsObj[route].routeId);
    });

    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/route/${procedure}`,
        {
          routeIds: _routesIds,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const assignTeamDriver = createAsyncThunk(
  "dmsDriver/assignTeamDriver",
  async ({ routeId, driverId, teamId, isPrimary }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const _driverID = !driverId || driverId === "withoutDriver";
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/route/${routeId}/assign`,
        {
          driverId: _driverID ? null : driverId,
          teamId: teamId === "withoutTeam" ? null : teamId,
          isPrimary,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getRouteById = createAsyncThunk(
  "dmsDriver/getRouteById",
  async ({ routeId }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/route/details/${routeId}`,

        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return splitingModelOfRouteByid(res.data);
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getHistory = createAsyncThunk(
  "dmsDriver/getHistory",
  async ({ routeId, pageNumber = 1, pagesize = 100 }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    const params = {
      routeId,
      pageNumber,
      pagesize,
    };

    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/route/history`,

        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params,
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const postAttachments = createAsyncThunk(
  "dmsDriver/postAttachments",
  async ({ routeId, content, type }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    // (  Note = 1, ImageBase64 = 2,)
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/route/attachment`,
        {
          routeId,
          content,
          type,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(showSnackbar(res.data, "success"));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "dmsRoutesSlice",
  initialState: {
    dmsRoutes: [],
    routesMeta: {
      totalItems: 0,
      totalPages: 0,
      pageNumber: 1,
    },
    routesFetching: true,
    routesHistoryFetching: true,
    postAttachmentFetching: false,
    pendingChangeStatus: false,
    individualRoute: {},
    dmsRoutesHistory: [],
    historyMeta: {
      totalItems: 0,
      totalPages: 0,
      pageNumber: 1,
    },
  },
  reducers: {
    cleanIndividualRoute(state) {
      state.individualRoute = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDmsRoutesNew.pending, (state) => {
        state.routesFetching = true;
      })
      .addCase(getDmsRoutesNew.rejected, (state) => {
        state.routesFetching = false;
        state.dmsRoutes = [];
        state.routesMeta.totalItems = 0;
        state.routesMeta.pageNumber = 1;
        state.routesMeta.totalPages = 0;
      })
      .addCase(getDmsRoutesNew.fulfilled, (state, { payload }) => {
        state.routesFetching = false;
        state.dmsRoutes = payload.ticketsList;
        state.routesMeta.totalItems = payload.totalItems;
        state.routesMeta.pageNumber = payload.pageNumber;
        state.routesMeta.totalPages = payload.totalPages;
      })
      .addCase(getRouteById.pending, (state) => {
        state.routesFetching = true;
        // state.individualRoute = {};
      })
      .addCase(getRouteById.rejected, (state) => {
        state.routesFetching = false;
        state.individualRoute = {};
      })
      .addCase(getRouteById.fulfilled, (state, { payload }) => {
        state.routesFetching = false;
        state.individualRoute = payload;
      })
      .addCase(getHistory.pending, (state) => {
        state.routesHistoryFetching = true;
      })
      .addCase(getHistory.rejected, (state) => {
        state.routesHistoryFetching = false;
        state.dmsRoutesHistory = {};
        state.historyMeta = {
          totalItems: 0,
          totalPages: 0,
          pageNumber: 1,
        };
      })
      .addCase(getHistory.fulfilled, (state, { payload }) => {
        state.routesHistoryFetching = false;
        state.dmsRoutesHistory = payload.history;
        state.historyMeta.totalItems = payload.totalItems;
        state.historyMeta.totalPages = payload.totalPages;
        state.historyMeta.pageNumber = payload.pageNumber;
      })
      .addCase(postAttachments.pending, (state) => {
        state.postAttachmentFetching = true;
      })
      .addCase(postAttachments.fulfilled, (state) => {
        state.postAttachmentFetching = false;
      })
      .addCase(postAttachments.rejected, (state) => {
        state.postAttachmentFetching = false;
      })
      .addCase(changeStatus.pending, (state) => {
        state.pendingChangeStatus = true;
      })
      .addCase(changeStatus.rejected, (state) => {
        state.pendingChangeStatus = false;
      })
      .addCase(changeStatus.fulfilled, (state) => {
        state.pendingChangeStatus = false;
      });
  },
});

export const { cleanIndividualRoute } = slice.actions;

export default slice.reducer;
