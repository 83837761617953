import React, { Component } from "react";
import { connect } from "react-redux";
import { withWidth } from "@material-ui/core";
import { getCityName } from "../../reducers/authReducer";
import * as helpers from "../../utils";

class HeroDiscoverPartner extends Component {
  state = {};

  render() {
    const { organization } = this.props;

    const _logo =
      organization && organization.logo?.includes("http")
        ? organization.logo
        : `${helpers.imageServerUrl}/partners/${organization.logo}`;
    const _headerImage =
      organization && organization.headerImage?.includes("http")
        ? organization.headerImage
        : `${helpers.imageServerUrl}/partners/${organization.headerImage}`;

    return (
      <div className="  tw-p-0  tw-text-white ">
        {" "}
        <div className="tw-w-full tw-h-full">
          <div
            style={{
              backgroundImage: `url(${_headerImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {organization && organization.logo && (
              <img
                alt="organization logo"
                src={_logo}
                style={{
                  position: "absolute",
                  margin: "0 auto",
                  maxWidth: "100%",
                  maxHeight: "300px",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  city: getCityName(state),
});

export default connect(mapStateToProps)(withWidth()(HeroDiscoverPartner));
