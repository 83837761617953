export const setSelectedItemsToRedux = (items) => (dispatch) => {
  dispatch({
    type: "SET_DEMO_ITEMS",
    payload: items,
  });
};

export const setSelectedAppointmentItemsToRedux = (item) => (dispatch) => {
  dispatch({
    type: "SET_APPOINTMENT_ITEMS",
    payload: item,
  });
};

export const setSelectedAppointmentItemToRedux = (item) => (dispatch) => {
  dispatch({
    type: "SET_APPOINTMENT_ITEM",
    payload: item,
  });
};

export const setAllDemoItems = (items) => (dispatch) => {
  dispatch({
    type: "SET_ALL_DEMO_ITEMS",
    payload: items,
  });
};
