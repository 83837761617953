import React, { Component } from "react";
import PartnerTestimonialTile from "./PartnerTestimonialTile";
import { imageServerUrl } from "../../utils";

const testimonials = [
  {
    name: "Rug Doctor, LLC - Andy Potter ",
    text: `<p>“Rug Doctor had been looking for ways to innovate and overcome some of the constraints of our legacy rental program.  Rug Doctor was long considered one of the pioneers in accessibility over ownership, but our program had not evolved to meet the new generation of consumers. We were introduced to Skipti and immediately saw potential in leveraging their platform to evolve and grow our business.  Using the Skipti platform has allowed us to engage directly with the consumer and provide ultra-convenient access to our machines with minimal friction.  Renting through Skipti eliminated the consumer’s burden of two trips to the store, waiting for help from a store employee and filling out paper forms.  Together we Skipti, we created a new consumer experience while providing all the features a consumer expects in age of Amazon/Uber.
    </p>  
    <br />
    <p>For Rug Doctor, we now get real time transactions, a complete transaction loop, digitized consumer information and instant feedback on the rental experience.  The Skipti platform also allows us to better utilize inventory, reducing capital outlays and creating a more efficient equipment servicing model.  We’ve taken our program into the modern consumer era.
    </p>
    <br />
    <p>In addition to the platform, the Skipti leadership provided an invaluable service to help us understand and consider the best approaches in building this new program.  Internally, we weren’t staffed with the expertise needed to navigate this new way of conducting business.  The Skipti team helped in both our strategy and execution of this new program.  Their guidance was essential to getting the most value from their platform.”</p>`,
    role: " Chief Technology Officer",
    image:
      "https://skipti.com/wp-content/uploads/2019/08/Rug-Doctor-Logo-2016-Tagline-Spot-300x55.jpg",
    avatar: `${imageServerUrl}/andycto.jpeg`,
  },
  {
    name: "Manuel Moustaid",
    text: `<p>“Skipti is the perfect tool to increase awareness and sales at the same time. Skipti allows us to expand the reach of our stores directly to the living room of prospects.”</p><br />`,
    role: "Regional Retail Manager at Devialet",
    image: `${imageServerUrl}/devialet-logo.jpg`,
    avatar: `${imageServerUrl}/manuel-devialet.png`,
  },
];

export default function PartnerTestimonialsRow() {
  return (
    <>
      {testimonials.map((testimonial, index) => (
        <PartnerTestimonialTile
          key={index}
          noOfTiles={1}
          testimonial={testimonial}
        />
      ))}
    </>
  );
}
