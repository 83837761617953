import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import MixpanelContext from "react-mixpanel";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormHelperText } from "@material-ui/core";
import SkiptiProgress from "../skipti/Spinner";

import {
  register,
  clearErrors,
  setSignInMessage,
  authenticate,
  fetchAddressesByUserId,
} from "../../actions";
import {
  getRegisterErrors,
  getSignInMessage,
  getSignInErrors,
  getShowGuestOption,
} from "../../reducers/authReducer";
import { getSocialId } from "../../reducers/userReducer";
import { imageServerUrl } from "../../utils";

import SocialLogins from "./SocialLogins";

yup.addMethod(yup.mixed, "sameAs", function a(ref, message) {
  return this.test("sameAs", message, function b(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});
const styles = (theme) => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
});

export const customTheme = createTheme({
  palette: {
    secondary: {
      light: "#484848",
      main: "#212121",
      dark: "#000000",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Lato",
  },
  props: {
    MuiButton: {
      size: "large",
    },
  },
});

const validationSchema = (isGuest) => {
  if (!isGuest) {
    return yup.object().shape({
      email: yup
        .string()
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email address"
        ),
      password: yup
        .string()
        .required("This field is required")
        .min(
          6,
          "Password must be at least 6 characters long with mixed upper and lower case and contain at least one digit"
        )
        .matches(
          /(?=.*[0-9])/,
          "Password must be at least 6 characters long with mixed upper and lower case and contain at least one digit"
        )

        .matches(
          /(?=.*[A-Z])/,
          "Password must be at least 6 characters long with mixed upper and lower case and contain at least one digit"
        )
        .matches(
          /(?=.*[a-z])/,
          "Password must be at least 6 characters long with mixed upper and lower case and contain at least one digit"
        )
        .sameAs(yup.ref("confirmpassword"), "Passwords don't match"),
      confirmpassword: yup
        .string()
        .required("This field is required")
        .min(6, "Your password is too short")
        .sameAs(yup.ref("password"), "Passwords don't match"),
    });
  }

  return yup.object().shape({
    email: yup
      .string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      ),
  });
};

class RegisterForm extends Component {
  static propTypes = {
    register: PropTypes.func.isRequired,
    onLogInClick: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    isPromotion: PropTypes.bool,
    isInRentWiz: PropTypes.bool,
  };

  static defaultProps = {
    isPromotion: false,
    isInRentWiz: false,
  };

  static contextType = MixpanelContext;

  state = {
    isSubmitting: false,
    showPromo: false,
    showPassword: {
      new: false,
      confirm: false,
    },
    isGuestMode: false,
  };

  handleGuestMode = () => {
    this.setState((_prev) => ({ ..._prev, isGuestMode: !_prev.isGuestMode }));
  };

  authenticate = async (values, source) => {
    const hasLoadingFunc = !!this.props.setLoading;
    if (hasLoadingFunc) {
      this.props.setLoading(true);
    }
    const success = await this.props.authenticate(
      values,
      this.context,
      window.analytics,
      source
    );
    if (success) {
      this.props.fetchAddressesByUserId();
      if (this.props.onClose) {
        this.props.onClose();
      }
      if (this.props.onSuccessLogin) {
        if (hasLoadingFunc) {
          this.props.setLoading(false);
        }
        this.props.onSuccessLogin();
      }
    } else if (hasLoadingFunc) {
      this.props.setLoading(false);
    }
  };

  handleSubmit = async (user, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ isSubmitting: true });
    await this.props.register(
      user,
      this.props.socialID,
      this.context,
      window.analytics,
      this.props.isInRentWiz,
      this.state.isGuestMode
    );
    this.setState({ isSubmitting: false });
    setSubmitting(false);
    if (this.props.isInRentWiz && !this.props.errors)
      this.props.setRegisterInWizard(true);
  };

  handleSignInClick = () => {
    this.props.clearErrors();
    this.props.onLogInClick();
  };

  handleClickShowPassword = (passwordType) => () => {
    this.setState(({ showPassword }) => ({
      showPassword: {
        ...showPassword,
        [passwordType]: !showPassword[passwordType],
      },
    }));
  };

  handleMouseDownPassword = () => (event) => {
    event.preventDefault();
  };

  responseFacebook = (response) => {
    console.log(response);
  };

  render() {
    const {
      classes,
      isPromotion,
      hideClose,
      isInRentWiz,
      isFetching,
      onClose,
      message,
    } = this.props;
    const { isSubmitting, isGuestMode } = this.state;
    return (
      <ThemeProvider theme={customTheme}>
        <SkiptiProgress loading={isSubmitting} opacity="0.01" />
        {hideClose && isGuestMode === false && (
          <Typography variant="subtitle1" align="center">
            {/* In order to receive rental confirmation and check the status of your
            order please create an account below. */}
            To complete your order and receive booking information please create
            a Skipti account below.
          </Typography>
        )}
        {!hideClose && (
          <button
            type="button"
            className="tw-float-right close-button-modal"
            onClick={onClose}
          >
            <i className="fas fa-times" />
          </button>
        )}
        <div>
          {!hideClose && (
            <>
              <p className="tw-mb-0 tw-text-sm tw-text-center tw-uppercase tw-text-lightgrey">
                It&apos;s FREE and EASY
              </p>
              <img
                src={`${imageServerUrl}/2020-1009 Skipto Website popup Logo-rev01.png`}
                alt="skipti_logo"
                className="tw-mb-0 tw-mx-auto tw-w-40"
              />

              {isPromotion ? (
                <p className="tw-text-center">
                  Limited time offer for Signing Up. <br />{" "}
                  <span className="fw-medium">Get $20 in Skipti credits</span>
                </p>
              ) : (
                ""
              )}
            </>
          )}

          {message ? <p className="tw-text-center">{message}</p> : null}
          {this.props.signInErrors && (
            <Paper elevation={2} className={classes.paper}>
              <Typography component="p" color="error">
                {this.props.signInErrors}
              </Typography>
            </Paper>
          )}
          <>
            {isGuestMode !== true ? (
              <>
                <SocialLogins authenticate={this.authenticate} />
                {this.props.withGuest && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="tw-block tw-mb-2 tw-w-full"
                    onClick={this.handleGuestMode}
                  >
                    <SvgIcon>
                      <>
                        <defs>
                          <filter colorInterpolationFilters="auto" id="aa">
                            <feColorMatrix
                              in="SourceGraphic"
                              values="0 0 0 0 0.960784 0 0 0 0 0.298039 0 0 0 0 0.015686 0 0 0 1.000000 0"
                            />
                          </filter>
                        </defs>
                        <g filter="url(#aa)" fill="none" fillRule="evenodd">
                          <path
                            d="M0 3.6v16.8c0 .664.536 1.2 1.2 1.2h3.6v-2.4H2.4V4.8h2.4V2.4H1.2C.536 2.4 0 2.936 0 3.6zm17.09-1.164-9.6-2.4A1.2 1.2 0 0 0 6 1.2v21.6a1.198 1.198 0 0 0 1.49 1.164l9.6-2.4A1.2 1.2 0 0 0 18 20.4V3.6a1.2 1.2 0 0 0-.91-1.164zM12 12.226a1.201 1.201 0 0 1-2.4 0v-.453a1.2 1.2 0 1 1 2.4.001v.452z"
                            fill="#000"
                            fillRule="nonzero"
                          />
                        </g>
                      </>
                    </SvgIcon>
                    &nbsp;Continue as Guest
                  </Button>
                )}
                <div
                  className=" tw-my-4 tw-text-lightgrey"
                  style={{
                    width: " 100%",
                    height: "15px",
                    borderBottom: "1px solid grey",
                    textAlign: "center",
                  }}
                >
                  <span style={{ backgroundColor: " #FFF", padding: "0 10px" }}>
                    or
                  </span>
                </div>

                <div className="tw-flex tw-justify-center tw-my-4">
                  CONTINUE WITH EMAIL
                </div>
              </>
            ) : (
              <>
                <h4 className="tw-text-center tw-text-2xl">Guest Checkout</h4>
                <p className="tw-text-center tw-text-sm tw-my-3">
                  Please note: Guest check out will temporarily save your
                  information to ensure roundtrip delivery. Sign Up to access
                  order tracking features and make additional orders.
                </p>
              </>
            )}
            <Formik
              initialValues={{
                email: "",
                password: "",
                confirmpassword: "",
                acceptedTandC: false,
                promoCode: "",
              }}
              onSubmit={this.handleSubmit}
              validationSchema={validationSchema(isGuestMode)}
            >
              {({
                handleBlur,
                handleChange,
                values,
                handleSubmit,
                touched,
                errors,
              }) => (
                <Form>
                  {this.props.errors && (
                    <Paper elevation={2} className={`${classes.paper} tw-mb-6`}>
                      <Typography component="p" color="error">
                        {this.props.errors}
                      </Typography>
                    </Paper>
                  )}
                  <div className="tw-mb-4">
                    <FormControl
                      color="secondary"
                      fullWidth
                      error={!!touched.email && !!errors.email}
                    >
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        disabled={isFetching || isSubmitting}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ tabIndex: "1" }}
                      />
                      {!!touched.email && !!errors.email && (
                        <FormHelperText error>
                          <strong>{errors.email}</strong>
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  {isGuestMode !== true && (
                    <>
                      <div className="tw-mb-4">
                        <FormControl
                          color="secondary"
                          fullWidth
                          error={!!touched.password && !!errors.password}
                        >
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <Input
                            id="password"
                            name="password"
                            disabled={isFetching || isSubmitting}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={
                              this.state.showPassword.new === true
                                ? "text"
                                : "password"
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword("new")}
                                  onMouseDown={this.handleMouseDownPassword(
                                    "new"
                                  )}
                                >
                                  {this.state.showPassword.new ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ tabIndex: "2" }}
                          />
                          {!!touched.password && !!errors.password && (
                            <FormHelperText error>
                              <strong>{errors.password}</strong>
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div className="tw-mb-4">
                        <FormControl
                          color="secondary"
                          fullWidth
                          error={
                            !!touched.confirmpassword &&
                            !!errors.confirmpassword
                          }
                        >
                          <InputLabel htmlFor="confirmpassword">
                            Confirm Password
                          </InputLabel>
                          <Input
                            id="confirmpassword"
                            name="confirmpassword"
                            type={
                              this.state.showPassword.confirm === true
                                ? "text"
                                : "password"
                            }
                            value={values.confirmpassword}
                            disabled={isFetching || isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword(
                                    "confirm"
                                  )}
                                  onMouseDown={this.handleMouseDownPassword(
                                    "confirm"
                                  )}
                                >
                                  {this.state.showPassword.confirm ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ tabIndex: "3" }}
                          />
                          {!!touched.confirmpassword &&
                            !!errors.confirmpassword && (
                              <FormHelperText error>
                                <strong>{errors.confirmpassword}</strong>
                              </FormHelperText>
                            )}
                        </FormControl>
                      </div>
                    </>
                  )}

                  {isGuestMode !== true ? (
                    <>
                      <div className="tw-mb-4">
                        <FormControlLabel
                          value="acceptedTandC"
                          control={
                            <Checkbox
                              checked={values.acceptedTandC}
                              onChange={handleChange}
                              value="acceptedTandC"
                              name="acceptedTandC"
                            />
                          }
                          label={
                            <div className="tw-text-xs">
                              <span>I accept the </span>
                              <Link
                                className="tw-text-skiptiOrange"
                                to="/termsandconditions"
                                target="_blank"
                              >
                                Terms of Use
                              </Link>
                              <span> and </span>
                              <Link
                                className="tw-text-skiptiOrange"
                                to="/privacypolicy"
                                target="_blank"
                              >
                                Privacy Policy
                              </Link>
                            </div>
                          }
                        />
                      </div>
                      {isInRentWiz ? (
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          className="tw-block tw-mb-2 tw-w-full"
                          variant="contained"
                          color="secondary"
                          disabled={
                            !values.acceptedTandC || isFetching || isSubmitting
                          }
                        >
                          Create account
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          className="tw-block tw-mb-2 tw-w-full"
                          variant="contained"
                          disableElevation
                          color="secondary"
                          disabled={
                            !values.acceptedTandC || isFetching || isSubmitting
                          }
                        >
                          Sign Up
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        color="secondary"
                        className="tw-block tw-mb-2 tw-w-full"
                        disabled={isFetching || isSubmitting}
                        disableElevation
                      >
                        Continue as Guest
                      </Button>
                      <div className="tw-text-center tw-mt-3 tw-text-xs">
                        <span>All guest checkouts accept the </span>
                        <br />
                        <Link
                          className="tw-text-skiptiOrange"
                          to="/termsandconditions"
                          target="_blank"
                        >
                          Terms of Use
                        </Link>
                        <span> and </span>
                        <Link
                          className="tw-text-skiptiOrange"
                          to="/privacypolicy"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </>

          {!hideClose && (
            <>
              <hr />
              <p className="tw-text-center tw-my-0">
                Already a user?{" "}
                <a
                  onClick={this.handleSignInClick}
                  href="#"
                  disabled={isFetching || isSubmitting}
                  className="tw-mt-4 tw-text-skiptiOrange fw-medium tw-cursor-pointer"
                >
                  Login
                </a>
              </p>
            </>
          )}
          {isGuestMode && (
            <>
              <div
                className="tw-my-1 tw-text-lightgrey"
                style={{
                  width: " 100%",
                  height: "15px",
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                }}
              >
                <span style={{ backgroundColor: " #FFF", padding: "0 10px" }}>
                  or
                </span>
              </div>
              <p className="tw-mt-5 tw-text-center">
                <a
                  onClick={this.handleGuestMode}
                  href="#"
                  className="tw-text-skiptiOrange fw-medium tw-cursor-pointer"
                >
                  Sign up
                </a>
              </p>
            </>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  errors: getRegisterErrors(state),
  withGuest: getShowGuestOption(state) || ownProps.withGuest,
  signInErrors: getSignInErrors(state),
  socialID: getSocialId(state),
  message: getSignInMessage(state),
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    register,
    clearErrors,
    setSignInMessage,
    authenticate,
    fetchAddressesByUserId,
  })(RegisterForm)
);
