import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import omit from "lodash/omit";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { DateRangePicker } from "react-dates";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { withWidth } from "@material-ui/core";

import { showSnackbar } from "../../actions";

const propTypes = {
  // example props for the demo
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  onDatesChange: PropTypes.func.isRequired,
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
  className: PropTypes.string,
  isError: PropTypes.bool,
  checkForBlockedDates: PropTypes.func,
  onFocusChange: PropTypes.func,
  isBSTDemo: PropTypes.bool,
  showSnackbar: PropTypes.func,
};

const defaultProps = {
  onFocusChange: () => {},
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDate: null,
  endDate: null,
  isBSTDemo: false,
  startDateId: "START_DATE",
  startDatePlaceholderText: "Start",
  endDateId: "END_DATE",
  className: "",
  isError: false,
  endDatePlaceholderText: "End",
  withoutFooter: false,
};

class DateRangePickerWrapper extends React.Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
    onDatesChange: PropTypes.func.isRequired,
    startDateId: PropTypes.string,
    endDateId: PropTypes.string,
    className: PropTypes.string,
    isError: PropTypes.bool,
    checkForBlockedDates: PropTypes.func,
    onFocusChange: PropTypes.func,
    showSnackbar: PropTypes.func,
    withoutFooter: PropTypes.bool,
  };

  static defaultProps = {
    onFocusChange: () => {},
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,
    startDate: null,
    endDate: null,
    startDateId: "START_DATE",
    endDateId: "END_DATE",
    className: "",
    isError: false,
    withoutFooter: false,
  };

  state = {
    focusedInput: null,
    openToolkit: false,
  };

  onFocusChange = (focusedInput) => {
    this.props.onFocusChange(focusedInput);
    this.setState({ focusedInput });
    document.activeElement.blur();
  };

  renderCalendarInfo = () => {
    const { minimumNights, maximumNights, withoutFooter } = this.props;
    if (this.props.isError) {
      return (
        <div
          style={{
            display: "flex",
            paddingBottom: "22px",
            justifyContent: "center",
          }}
        >
          <ErrorOutlineIcon style={{ color: "red" }} />
          <span style={{ color: "red", paddingLeft: "5px" }}>
            The date range you selected is not valid
          </span>
        </div>
      );
    }
    return withoutFooter ? null : (
      <div
        style={
          this.props.withPortal
            ? {
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "22px",
                position: "absolute",
                bottom: "70px",
              }
            : {
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "22px",
              }
        }
      >
        <div
          style={{
            display: "inline-flex",
            flex: 1,
            padding: "0 13px 0 23px",
          }}
        >
          <div
            style={{
              width: "39px",
              height: "38px",
              flexShrink: 0,
            }}
            className="CalendarDay CalendarDay_1 CalendarDay__defaultCursor CalendarDay__defaultCursor_2 CalendarDay__default CalendarDay__default_3 CalendarDay__blocked_calendar CalendarDay__blocked_calendar_5"
          >
            {/* 1 */}
          </div>
          <span
            style={{ top: "-5px", position: "relative", paddingLeft: "10px" }}
          >
            The item is reserved by another user
          </span>
        </div>
        <div
          style={{ display: "inline-flex", flex: 1, padding: "0 13px 0 3px" }}
        >
          <div
            style={{
              width: "39px",
              height: "38px",
              cursor: "default",
              flexShrink: 0,
            }}
            className="CustomCalendarDay CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2 CalendarDay__highlighted_calendar CalendarDay__highlighted_calendar_3"
          >
            {/* 1 */}
          </div>
          <span
            style={{
              top: "-5px",
              position: "relative",
              paddingLeft: "10px",
            }}
          >
            Choose rental start and end dates
          </span>
        </div>
        <div
          style={{
            width: "100%",
            padding: "0 13px 0 23px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>Minimum rental days: {minimumNights}</span>
          <span>Maximum rental days: {maximumNights}</span>
        </div>
      </div>
    );
  };

  isOutsideRange = (day) => {
    const { startDate, endDate, maximumNights, isBSTDemo, inAdminPortal } =
      this.props;
    const { focusedInput } = this.state;
    const maxDate = moment(startDate).add(maximumNights, "day");
    const today = moment();
    if (focusedInput === "endDate" && maxDate.isBefore(day)) {
      return true;
    }

    if (inAdminPortal) {
      return false;
    }

    // BST HARDCODED WORKAROUND
    if (
      isBSTDemo &&
      moment(day).utc().isSame(moment().utc().add(1, "d"), "day") &&
      moment().utc().isAfter(moment().hour(17).utc())
    )
      return false;

    if (
      isBSTDemo &&
      (focusedInput === "startDate" ||
        (focusedInput === "endDate" && !startDate)) &&
      moment(day).isAfter(moment().hour(17).add(1, "d"))
    ) {
      return true;
    }

    if (
      moment(day).isSame(moment(), "day") &&
      moment().isAfter(moment().hour(18).minutes(30))
    ) {
      return true;
    }

    if (moment(day).isBefore(moment(), "day")) {
      return true;
    }
    return false;
  };

  getTooltipTitle = (day) => {
    const text = (
      <div>
        {this.props.minimumNights} night minimum. <br /> Pick future date.
      </div>
    );
    return this.props.startDate && day === this.props.startDate ? text : "";
  };

  toggleToolkit = (val) => this.setState({ openToolkit: val });

  renderDay = (day) => {
    const isDesktop = this.props.width === "lg" || this.props.width === "md";
    return (
      <Tooltip
        arrow
        title={this.getTooltipTitle(day)}
        placement="top"
        open={isDesktop ? undefined : this.state.openToolkit}
        onClose={() => this.toggleToolkit(false)}
      >
        <div
          onClick={() => this.toggleToolkit(true)}
          className="day-outer-wrapper"
        >
          <div className="day-inner-wrapper">
            <div>{moment(day).format("D")}</div>
          </div>
        </div>
      </Tooltip>
    );
  };

  render() {
    const { focusedInput } = this.state;
    const { startDate, endDate, className } = this.props;
    if (startDate && endDate && !this.props.disabled) {
      if (
        this.props.checkForBlockedDates &&
        this.props.checkForBlockedDates(startDate, endDate)
      ) {
        this.props.onDatesChange({
          startDate: startDate.add(1, "days"),
          endDate: endDate.add(1, "days"),
        });
        // this.props.showSnackbar(
        //   "Selected range contains blocked dates. Please select different dates.",
        //   "warning"
        // );
      }
    }
    const props = omit(this.props, [
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "stateDateWrapper",
      "className",
      "isError",
      "maximumNights",
      "checkForBlockedDates",
      "width",
      "isBSTDemo",
      "inAdminPortal",
      "showSnackbar",
      "withoutFooter",
    ]);

    return (
      <div className={className}>
        <DateRangePicker
          {...props}
          block
          readOnly
          hideKeyboardShortcutsPanel
          onDatesChange={props.onDatesChange}
          keepOpenOnDateSelect={props.keepOpenOnDateSelect}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          renderDayContents={this.renderDay}
          onPrevMonthClick={props.onPrevMonthClick}
          onNextMonthClick={props.onNextMonthClick}
          isDayBlocked={props.isDayBlocked}
          minimumNights={props.minimumNights || 3}
          initialVisibleMonth={() => {
            if (
              moment().isSame(moment().endOf("month"), "day") &&
              moment().isAfter(moment().hour(18).minutes(30))
            ) {
              return moment().add(1, "months").startOf("month");
            }
            if (startDate) {
              return moment(startDate);
            }

            return moment();
          }}
          isOutsideRange={this.props.isOutsideRange || this.isOutsideRange}
          numberOfMonths={this.props.width === "xs" ? 1 : 2}
          orientation={props.orientation}
          withPortal={props.withPortal}
          isDayHighlighted={props.isDayHighlighted}
          renderCalendarInfo={this.renderCalendarInfo}
        />
      </div>
    );
  }
}

export default withWidth()(
  connect(null, { showSnackbar })(DateRangePickerWrapper)
);
