import * as TYPES from "../types";
import * as helpers from "../utils";

export default (
  state = { items: helpers.makeInitial(), byId: helpers.makeInitial() },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.CATEGORY_BYID_REQUEST:
      return { ...state, byId: helpers.makeStartLoading() };
    case TYPES.CATEGORY_ALL_REQUEST:
      return { ...state, items: helpers.makeStartLoading() };
    case TYPES.CATEGORY_BYID_SUCCESS:
      return { ...state, byId: helpers.makeLoadSuccess(payload) };
    case TYPES.CATEGORY_ALL_SUCCESS:
      return { ...state, items: helpers.makeLoadSuccess(payload) };
    case TYPES.CATEGORY_BYID_FAIL:
      return { ...state, byId: helpers.makeLoadFailed(error) };
    case TYPES.CATEGORY_ALL_FAIL:
      return { ...state, items: helpers.makeLoadFailed(error) };
    default:
      return state;
  }
};

export const getCategories = (state) => state.categories.items;
export const getCategory = (state) => state.categories.byId;
export const getCategoriesForDropdown = (state) =>
  state.categories.items.isLoaded
    ? state.categories.items.data.map((category) => ({
        value: category.id.toString(),
        key: category.id,
        text: category.displayName,
      }))
    : [];
