import axios from "axios";
import moment from "moment";
import uuid from "uuid";
import mixpanel from "mixpanel-browser";
import history from "../history";
import * as TYPES from "../types";
import * as helpers from "../utils";
import { getIsLoggedIn, getToken, getUser } from "../reducers/authReducer";
import { showSnackbar } from "./snackbar";
import {
  getDateRange,
  getProductById,
  getAvailCalendarStartDate,
  getAvailCalendarEndDate,
  getSearchString,
  getSearchCategory,
  getProductByIdHasAttributes,
  getSize,
  getProductId,
  getLastRequestedSize,
  getZip,
  getProductsTrending,
  getLastRequestedZip,
} from "../reducers/itemsReducer";
import {
  getSelectedAddons,
  getItemRentInfo,
} from "../reducers/itemRentReducer";
import {
  getAddressesByUserId,
  getDefaultAddressZip,
} from "../reducers/addressesReducer";
import { getZipFromLatLong, getRegion } from "../reducers/userReducer";

import { createProductResourceRequest } from "../components/items/utils/serverObjects";
import { setOrderPromoCode } from "./orders";

import { getCartKey } from "../components/items/item_upsell/CartSlice";
import { SET_ITEM_RENT_QUERY_STRING_OPTIONS } from "../types";
import { setExperienceType, setStoreId } from "./itemRent";
import { experienceTypes } from "./utils";
import { toQueryObject } from "../utils";
import { rugDoctorUnavailableItemIds } from "../utils/constants";

export const setItemNeedsSize = (b) => (dispatch) =>
  dispatch({ type: TYPES.ITEMS_NEEDS_SIZE, payload: b });

export const setColor = (color) => (dispatch) =>
  dispatch({ type: TYPES.ITEMS_COLOR_CHANGE, payload: color });

export const setSize = (size) => (dispatch) =>
  dispatch({ type: TYPES.ITEMS_SIZE_CHANGE, payload: size });

export const selectItemByOwner = (item) => (dispatch) =>
  dispatch({ type: TYPES.ITEM_SELECT_BYOWNER, payload: item });

export const deleteItemByOwner = () => (dispatch) =>
  dispatch({ type: TYPES.ITEM_DELETE_BYOWNER });

const requestItemsByOwnerId = () => ({
  type: TYPES.ITEMS_BYOWNERID_REQUEST,
});
const successItemsByOwnerId = (payload) => ({
  type: TYPES.ITEMS_BYOWNERID_SUCCESS,
  payload,
});
const failItemsByOwnerId = (error) => ({
  type: TYPES.ITEMS_BYOWNERID_FAIL,
  error,
});

const requestCreateProduct = () => ({
  type: TYPES.CREATE_PRODUCT_REQUEST,
});
const successCreateProduct = (payload) => ({
  type: TYPES.CREATE_PRODUCT_SUCCESS,
  payload,
});
const failCreateProduct = (error) => ({
  type: TYPES.CREATE_PRODUCT_FAIL,
  error,
});

const setItemPrice = (itemPricing) => ({
  type: TYPES.SET_ITEM_PRICE,
  payload: itemPricing,
});

export const computePrice =
  ({
    startDate,
    endDate,
    sourceZip,
    destinationZip,
    deliveryEvent,
    returnEvent,
    events,
    promoCode,
    sentPromoCode,
    callback,
  }) =>
  async (dispatch, getState) => {
    const _sourceZip = sourceZip || getZip(getState()) || null;
    const _region = getRegion(getState());
    const _destinationZip =
      destinationZip || (_region && _region.defaultZipPostal) || null;
    let _startDate = startDate || getDateRange(getState()).startDate;
    let _endDate = endDate || getDateRange(getState()).endDate;
    const product = getProductById(getState());
    const currentUser = getUser(getState());
    const jwt = getToken(getState());
    const selectedAddons = getSelectedAddons(getState());
    const itemRentInfo = getItemRentInfo(getState());

    if (!product.isLoaded) {
      return null;
    }

    if (!_startDate && !_endDate) {
      _startDate = moment().utc().add(10, "m").add(25, "y");
      _endDate = moment().utc().add(1, "d").add(25, "y");
    }

    if (_startDate && !_endDate) {
      return;
    }

    const productData = product.data;
    if (moment(startDate).isBefore(moment())) {
      // add 10 minutes if the start date is in the past
      _startDate = moment().utc().add(10, "m");
    }

    if (!deliveryEvent) {
      deliveryEvent = {
        id: itemRentInfo.startAvailWindowId || null,
        expires_at: "",
        starts_at: moment(_startDate).utc(),
        ends_at: moment(_startDate).add(30, "m").utc(),
      };
    }

    if (!returnEvent) {
      returnEvent = {
        id: itemRentInfo.endAvailWindowId || null,
        expires_at: "",
        starts_at: moment(_endDate).utc(),
        ends_at: moment(_endDate).add(30, "m").utc(),
      };
    }

    const obj = createProductResourceRequest({
      item: product.data,
      user: currentUser,
      itemRentInfo,
      deliveryEvent,
      returnEvent,
      selectedAddons,
      events,
      promoCode,
      region: _region?.id,
    });
    try {
      let res = {};

      res = await axios.post(
        `${
          helpers.serverUrl
        }/api/v1/resourcerequest/productreceipt?currencyType=1${
          _sourceZip ? `&sourceZipPostal=${_sourceZip}` : ""
        }${_destinationZip ? `&destinationZipPostal=${_destinationZip}` : ""}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (promoCode && sentPromoCode && res.data.promoCredit <= 0) {
        callback?.((_prev) => _prev + 1);
        dispatch(showSnackbar(`Promo Code "${promoCode}" Not Valid!`, "error"));
        dispatch(setOrderPromoCode(null));
      }
      if (promoCode && sentPromoCode && res.data.promoCredit > 0) {
        dispatch(showSnackbar("Promo Code Successfully Applied!", "success"));
      }
      dispatch(setItemPrice(res.data));
    } catch (e) {
      dispatch(
        setItemPrice({
          basePerDayUsd: productData.basePerDayUsd,
          subsequentDayUsd: productData.subsequentDayUsd,
        })
      );
      // dispatch(
      //   showSnackbar(
      //     (e.response && e.response.data) || e.message,
      //     "error",
      //     null,
      //     null,
      //     JSON.stringify(e),
      //     JSON.stringify(obj)
      //   )
      // );
    }
  };

export const addOnSelect = (addOn) => (dispatch) => {
  dispatch({ type: TYPES.ADDON_SELECT, payload: addOn });
  dispatch(computePrice({}));
};
export const addOnRemove = (addOn) => (dispatch) => {
  dispatch({ type: TYPES.ADDON_REMOVE, payload: addOn });
  dispatch(computePrice({}));
};

const prevValues = {
  pageSize: null,
  pageNumber: null,
};
// eslint-disable-next-line
export const fetchItemsByOwnerId =
  (data, refreshCase) => async (dispatch, getState) => {
    dispatch(requestItemsByOwnerId());
    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    const user = getUser(getState());
    try {
      // we are getting the organization id on the backend so we're passing in undefined for it in here
      let params;
      if (refreshCase === "REFRESH") {
        params = {
          pageSize: prevValues.pageNumber || 10,
          pageNumber: prevValues.pageSize || 1,
          organizationId: user.resourceOrganization,
        };
      } else {
        prevValues.pageNumber = data.pagesize;
        prevValues.pageSize = data.pagenumber;
        params = {
          pageSize: data.pagesize || 10,
          pageNumber: data.pagenumber || 1,
          organizationId: user.resourceOrganization,
        };
      }
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/productteasers/mine/${
          data.searchString || "*"
        }`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params,
        }
      );
      dispatch(successItemsByOwnerId(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failItemsByOwnerId(e));
    }
  };

export const createProduct = (values) => async (dispatch, getState) => {
  dispatch(requestCreateProduct());

  const jwt = getToken(getState());
  try {
    const res = await axios.post(
      `${helpers.serverUrl}/api/v1/resources/products/`,
      values,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    dispatch(successCreateProduct(res.data));
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e),
        JSON.stringify(values)
      )
    );
    dispatch(failCreateProduct(e));
  }
};
export const fetchAvailabilityCalendarRequest = (payload) => ({
  type: TYPES.AVAILABILITY_CALENDAR_FETCH_REQUEST,
  payload,
});
export const fetchAvailabilityCalendarSuccess = (payload) => ({
  type: TYPES.AVAILABILITY_CALENDAR_FETCH_SUCCESS,
  payload,
});
export const fetchAvailabilityCalendarFail = (error) => ({
  type: TYPES.AVAILABILITY_CALENDAR_FETCH_FAIL,
  error,
});

export const fetchAvailabilityCalendarReset = () => ({
  type: TYPES.AVAILABILITY_CALENDAR_FETCH_RESET,
});

export const fetchAvailabilityCalendar =
  (
    productId,
    startDate,
    endDate,
    regionId,
    attributes = {},
    zipPostal,
    keepPreviousEvents = false
  ) =>
  async (dispatch, getState) => {
    // get zip code if user is authenticated and has default address. Otherwise fallback to zip from local storage
    const zipCode =
      zipPostal ||
      getZip(getState()) ||
      getDefaultAddressZip(getState()) ||
      getZipFromLatLong(getState()) ||
      localStorage.getItem("zipCode");

    // get Cart Key
    const cartkey = getCartKey(getState());

    // get product id from redux if product id not present in call
    productId = productId || getProductId(getState());
    // get product has attributes
    const hasAttributes = getProductByIdHasAttributes(getState());
    console.log("keepPreviousEvents", keepPreviousEvents);
    if (!keepPreviousEvents) {
      dispatch(fetchAvailabilityCalendarReset());
    }

    // #region get date range from call or from redux state
    let dateRange = {};
    if (startDate && endDate) {
      dateRange = {
        startDate,
        endDate,
      };
    } else {
      dateRange = getDateRange(getState());
    }
    // #endregion
    if (!dateRange.startDate || !dateRange.endDate) {
      // if you got here make sure that the startDate and endDate parameters are either set in the call or have been set in redux
    } else {
      // #region check start date and end date
      const availCalendarStartDate = getAvailCalendarStartDate(getState());
      const availCalendarEndDate = getAvailCalendarEndDate(getState());
      const pid = productId || getProductById(getState()).data.resourceId;
      const jwt = getToken(getState());
      let actualEndDate = null;
      let actualStartDate = null;
      let hasModifiedStartDate = false;
      let hasModifiedEndDate = false;
      if (availCalendarEndDate && availCalendarStartDate) {
        if (moment(startDate).isBefore(availCalendarStartDate, "month")) {
          hasModifiedStartDate = true;
          actualStartDate = dateRange.startDate;
          actualEndDate = availCalendarStartDate;
        }
        if (moment(endDate).isAfter(availCalendarEndDate, "month")) {
          hasModifiedEndDate = true;
          actualStartDate = availCalendarEndDate;
          actualEndDate = dateRange.endDate;
        }
      } else {
        hasModifiedEndDate = true;
        hasModifiedStartDate = true;
        actualEndDate = dateRange.endDate;
        actualStartDate = dateRange.startDate;
      }

      if (!actualStartDate) {
        actualStartDate = startDate;
      }
      if (!actualEndDate) {
        actualEndDate = endDate;
      }
      // #endregion
      const selectedSize =
        (attributes && attributes.size) || getSize(getState());
      const lastRequestedSize = getLastRequestedSize(getState());

      const lastRequestedZip = getLastRequestedZip(getState());

      dispatch(
        fetchAvailabilityCalendarRequest({
          shouldShowLoading:
            (!hasModifiedEndDate &&
              !hasModifiedStartDate &&
              lastRequestedSize !== selectedSize) ||
            lastRequestedSize === "",
        })
      );

      // if the end date/start date and selected size didn't change -> we already have the calendar
      if (
        (!actualEndDate || !actualStartDate) &&
        zipCode === lastRequestedZip &&
        (!hasAttributes || selectedSize === lastRequestedSize)
      ) {
        return dispatch(
          fetchAvailabilityCalendarSuccess({
            events: [],
            startDate: availCalendarStartDate,
            endDate: availCalendarEndDate,
            hasModifiedSize: false,
            lastRequestedSize: selectedSize,
            lastRequestedZip: zipCode,
          })
        );
      }
      // if we don't have new dates but the item has attributes we always want to check if the size changed and get new dates
      if (!actualEndDate && !actualStartDate && hasAttributes) {
        actualStartDate = availCalendarStartDate;
        actualEndDate = availCalendarEndDate;
      }
      if (moment(actualStartDate).isBefore(moment(), "month")) {
        actualStartDate = moment().startOf("month").isBefore(moment(), "day")
          ? moment()
          : moment().startOf("month");
      }
      if (zipCode) {
        try {
          let res;

          if (hasAttributes) {
            // try to get size from redux
            // if size is not yet set it means it's the first call (without selected dates) -> don't make it!
            if (selectedSize === "") {
              dispatch(fetchAvailabilityCalendarFail("Size is not yet set"));
              return null;
            }
            const reqArr = [
              {
                label: "size",
                attributes: [{ value: getSize(getState()) }],
              },
            ];
            res = await axios.post(
              `${
                helpers.serverUrl
              }/api/v1/calendar/reservations/attributed/${pid}?zipPostal=${zipCode}&beginDt=${actualStartDate.toISOString()}&endDt=${actualEndDate.toISOString()}`,
              reqArr,
              {
                headers: {
                  Authorization: `Bearer ${jwt}`,
                },
              }
            );
          } else {
            // const region = getRegion(getState());
            // if (!regionId && region) {
            //   regionId = region.id;
            // }

            // const isLoggedIn = getIsLoggedIn(getState());
            // if (!regionId && isLoggedIn) {
            //   const addresses = getAddressesByUserId(getState());

            //   const addressInRegion = addresses.find(
            //     (a) => a.address?.region === regionId
            //   );
            //   if (addressInRegion) {
            //     regionId = addressInRegion.address?.region;
            //   } else {
            //     regionId = addresses[0]?.address?.region;
            //   }
            // }

            res = await axios.get(
              `${
                helpers.serverUrl
              }/api/v1/calendar/events/product/${pid}?beginDt=${actualStartDate.toISOString()}&endDt=${actualEndDate.toISOString()}&zipcode=${zipCode}${
                cartkey && typeof cartkey !== "undefined"
                  ? `&cartkey=${cartkey}`
                  : ""
              }`,
              {
                headers: {
                  Authorization: `Bearer ${jwt}`,
                },
              }
            );
          }

          dispatch(
            fetchAvailabilityCalendarSuccess({
              events: res.data.map((event) => ({ ...event, uId: uuid() })),
              startDate: hasModifiedStartDate
                ? actualStartDate
                : availCalendarStartDate,
              endDate: hasModifiedEndDate
                ? actualEndDate
                : availCalendarEndDate,
              hasAttributes,
              hasModifiedSize: lastRequestedSize !== selectedSize,
              lastRequestedSize: selectedSize,
              lastRequestedZip: zipCode,
            })
          );
        } catch (e) {
          dispatch(
            fetchAvailabilityCalendarFail(e.response && e.response.data)
          );
          dispatch({
            type: TYPES.PRODUCT_BYID_ERROR,
            payload: (e.response && e.response.data) || e.message,
          });

          // dispatch(
          //   showSnackbar(
          //     (e.response && e.response.data) || e.message,
          //     "error",
          //     null,
          //     null,
          //     JSON.stringify(e),
          //     `${
          //       helpers.serverUrl
          //     }/api/v1/calendar/events/product/${pid}?beginDt=${actualStartDate.toISOString()}&endDt=${actualEndDate.toISOString()}&region=${regionId}`
          //   )
          // );
        }
      }
    }
  };
const setDateRangeSuccess = (payload) => ({
  type: TYPES.DATERANGE_SET_SUCCESS,
  payload,
});
export const setStartDate = (payload) => ({
  type: TYPES.DATERANGE_SET_START_SUCCESS,
  payload,
});
export const setEndDate = (payload) => ({
  type: TYPES.DATERANGE_SET_END_SUCCESS,
  payload,
});

export const setDateRange = (startDate, endDate) => (dispatch) => {
  dispatch(setDateRangeSuccess({ startDate, endDate }));
};

const requestProductById = () => ({
  type: TYPES.PRODUCT_BYID_REQUEST,
});
const successProductById = (payload) => ({
  type: TYPES.PRODUCT_BYID_SUCCESS,
  payload,
});
const failProductById = (error) => ({
  type: TYPES.PRODUCT_BYID_FAIL,
  error,
});
export const resetProductById = () => ({
  type: TYPES.PRODUCT_BYID_RESET,
});
// eslint-disable-next-line
export const fetchProductById =
  (productId, includeAddOns, zipCode) => async (dispatch, getState) => {
    // If product not available redirect to discover page
    const isUnavailableProduct =
      rugDoctorUnavailableItemIds.includes(productId);

    if (isUnavailableProduct) {
      return history.push("/discover");
    }

    dispatch(requestProductById());
    if (!productId) {
      productId = {};
    }

    const isLoggedIn = getIsLoggedIn(getState());
    if (isLoggedIn && !zipCode) {
      const region = getRegion(getState());
      const addresses = getAddressesByUserId(getState());
      let addressInRegion = null;
      if (region && region.id) {
        addressInRegion = addresses?.find(
          (a) => a?.address.region === region.id
        );
      }

      if (addressInRegion) {
        zipCode = addressInRegion?.address?.zipPostal;
      } else {
        zipCode = addresses[0]?.address?.zipPostal;
      }
    }

    if (!zipCode || typeof zipCode === "undefined" || zipCode === "undefined") {
      zipCode =
        getZip(getState()) ||
        getDefaultAddressZip(getState()) ||
        getZipFromLatLong(getState()) ||
        localStorage.getItem("zipCode");
    }

    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${
          helpers.serverUrl
        }/api/v1/resources/product/${productId}?includeAddOns=${
          includeAddOns || false
        }&zipCode=${zipCode}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      const { damageProtection, ...restProperties } = res.data;

      const _experienceTypeObject = Object.values(experienceTypes).find(
        (experienceType) =>
          experienceType.id ===
          (res.data.experienceType || experienceTypes.Rental.id)
      );

      const _product = {
        ...restProperties,
        defaultSelected: damageProtection?.defaultSelected || true,
        description: damageProtection?.description || "",
        disablePopupText: damageProtection?.disablePopupText || "",
        disablePopupTitle: damageProtection?.disablePopupTitle || "",
        imageUrl: damageProtection?.imageUrl || "",
        title: damageProtection?.title || "",
        currencyType: damageProtection?.currencyType || 1,
        amount: damageProtection?.amount || 11.25,
        experienceTypeObject: _experienceTypeObject,
      };

      const isTransportAssistProduct =
        _experienceTypeObject.id === experienceTypes.TransportAssist.id;
      const depotId = _product?.storeLocation?.depotId;

      // If product is Transport Assist and URL doesn't include storeId parameter
      // we need to get it from product and save
      if (
        isTransportAssistProduct &&
        !toQueryObject(window.location.search).get("storeId")
      ) {
        if (depotId) {
          dispatch(setStoreId(_product.storeLocation.depotId));
        } else {
          dispatch(showSnackbar("No Store Id was provided"));
        }
      }

      dispatch(successProductById(_product));
      dispatch(setExperienceType(_experienceTypeObject));

      return _product;
    } catch (e) {
      dispatch(showSnackbar(e?.response?.data ?? e.message));
      dispatch(failProductById(e));
      if (e.response && e?.response?.status === 404) {
        history.push("/discover");
      }
    }
  };

const requestProductsByCategory = () => ({
  type: TYPES.PRODUCTS_BYCATEGORY_REQUEST,
});

const successProductsByCategory = (payload) => ({
  type: TYPES.PRODUCTS_BYCATEGORY_SUCCESS,
  payload,
});

const failProductsByCategory = (error) => ({
  type: TYPES.PRODUCTS_BYCATEGORY_FAIL,
  error,
});

export const fetchProductsByCategory = (categoryId) => async (dispatch) => {
  dispatch(requestProductsByCategory());
  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/search/productteasers/category-id/${categoryId}`
    );
    dispatch(successProductsByCategory(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failProductsByCategory(e));
  }
};

export const fetchProductsByCategoryName =
  (categoryName, { pageSize, pageNumber }) =>
  async (dispatch) => {
    dispatch(requestProductsByCategory());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/productteasers/category-name/${categoryName}`,
        {
          params: {
            pageSize,
            pageNumber,
          },
        }
      );
      dispatch(successProductsByCategory(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProductsByCategory(e));
    }
  };

const requestItemsBySearchStringAndCategory = (
  searchString,
  searchCategory
) => ({
  type: TYPES.PRODUCT_BYSEARCH_REQUEST,
  searchString,
  searchCategory,
});
const successItemsBySearchStringAndCategory = (
  payload,
  searchString,
  searchCategory
) => ({
  type: TYPES.PRODUCT_BYSEARCH_SUCCESS,
  payload,
  searchString,
  searchCategory,
});
const failItemsBySearchStringAndCategory = (
  error,
  searchString,
  searchCategory
) => ({
  type: TYPES.PRODUCT_BYSEARCH_FAIL,
  error,
  searchString,
  searchCategory,
});

export const fetchItemsBySearchStringAndCategory =
  ({
    searchString,
    searchCategory,
    pageSize,
    pageNumber,
    priceLow,
    priceHigh,
    ratingLow,
    ratingHigh,
    sortDirection,
    sortVector,
  }) =>
  async (dispatch, getState) => {
    const searchStr = searchString || getSearchString(getState());
    const searchCat = searchCategory || getSearchCategory(getState());
    dispatch(requestItemsBySearchStringAndCategory(searchStr, searchCat));
    const zipCode =
      getZip(getState()) ||
      getDefaultAddressZip(getState()) ||
      getZipFromLatLong(getState()) ||
      localStorage.getItem("zipCode");
    try {
      const res = await axios.get(
        `${
          helpers.serverUrl
        }/api/v1/search/productteasers/filtered-zip/${encodeURIComponent(
          searchStr
        )}`,
        {
          params: {
            category: searchCat,
            pageSize: pageSize || 50,
            pageNumber: pageNumber || 1,
            priceLow,
            priceHigh,
            ratingLow,
            ratingHigh,
            sortDirection: sortDirection !== -1 ? sortDirection : undefined,
            sortVector: sortVector !== -1 ? sortVector : undefined,
            zipPostal: zipCode,
          },
        }
      );
      dispatch(
        successItemsBySearchStringAndCategory(
          res.data,
          searchStr.replace(/%2f/g, "/"),
          searchCat
        )
      );
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      dispatch(failItemsBySearchStringAndCategory(e, searchStr, searchCat));
    }
  };

const requestItemsByOrganizationId = () => ({
  type: TYPES.ITEMS_BYORGANIZATIONID_REQUEST,
});
const successItemsByOrganizationId = (payload) => ({
  type: TYPES.ITEMS_BYORGANIZATIONID_SUCCESS,
  payload,
});
const failItemsByOrganizationId = (error) => ({
  type: TYPES.ITEMS_BYORGANIZATIONID_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchItemsByOrganizationId =
  (data) =>
  async (
    dispatch
    // getState
  ) => {
    dispatch(requestItemsByOrganizationId());
    if (!data) {
      data = {};
    }

    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/resources/products/${
          data.organizationId
        }?pagesize=${data.pagesize || 10}&pagenumber=${data.pagenumber || 1}`
      );

      dispatch(successItemsByOrganizationId(res.data));

      return res.data;
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      dispatch(failItemsByOrganizationId(e));
    }
  };

const requestProductsByRecentlyViewed = () => ({
  type: TYPES.PRODUCTS_BYRECENTLYVIEWED_REQUEST,
});

const successProductsByRecentlyViewed = (payload) => ({
  type: TYPES.PRODUCTS_BYRECENTLYVIEWED_SUCCESS,
  payload,
});

const failProductsByRecentlyViewed = (error) => ({
  type: TYPES.PRODUCTS_BYRECENTLYVIEWED_FAIL,
  error,
});

// eslint-disable-next-line
export const fetchProductsByRecentlyViewed =
  (data) => async (dispatch, getState) => {
    dispatch(requestProductsByRecentlyViewed());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      // no need to pass the userID as it will get the current user from the JWT token

      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/productteasers/recent-view?${
          data.siteEntryId !== null ? `siteEntryId=${data.siteEntryId}&` : ""
        }maxResults=${data.pageSize || 6}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successProductsByRecentlyViewed(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProductsByRecentlyViewed(e));
    }
  };

const requestProductsTrending = () => ({
  type: TYPES.PRODUCTS_TRENDING_REQUEST,
});
const successProductsTrending = (payload) => ({
  type: TYPES.PRODUCTS_TRENDING_SUCCESS,
  payload,
});
const failProductsTrending = (error) => ({
  type: TYPES.PRODUCTS_TRENDING_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProductsTrending = (data) => async (dispatch, getState) => {
  const trendingProducts = getProductsTrending(getState());
  if (trendingProducts.isFetching) {
    return;
  }
  dispatch(requestProductsTrending());

  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());
  try {
    // no need to pass the userID as it will get the current user from the JWT token
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/search/productteasers/trending-paged`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageNumber: data.pageNumber || 1,
          pageSize: data.pageSize || 12,
        },
      }
    );

    dispatch(successProductsTrending(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failProductsTrending(e));
  }
};

const requestProductsByRecentlyUploaded = () => ({
  type: TYPES.PRODUCTS_BYRECENTLYUPLOADED_REQUEST,
});
const successProductsByRecentlyUploaded = (payload) => ({
  type: TYPES.PRODUCTS_BYRECENTLYUPLOADED_SUCCESS,
  payload,
});
const failProductsByRecentlyUploaded = (error) => ({
  type: TYPES.PRODUCTS_BYRECENTLYUPLOADED_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProductsByRecentlyUploaded =
  (data) => async (dispatch, getState) => {
    dispatch(requestProductsByRecentlyUploaded());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${
          helpers.serverUrl
        }/api/v1/search/productteasers/recent-paged?category-id=${
          data.catnumber || 0
        }&pageSize=${data.pageSize || 6}&pageNumber=${data.pageNumber || 1}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successProductsByRecentlyUploaded(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProductsByRecentlyUploaded(e));
    }
  };

const requestProductsFeatured = () => ({
  type: TYPES.PRODUCTS_FEATURED_REQUEST,
});
const successProductsFeatured = (payload) => ({
  type: TYPES.PRODUCTS_FEATURED_SUCCESS,
  payload,
});
const failProductsFeatured = (error) => ({
  type: TYPES.PRODUCTS_FEATURED_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProductsFeatured = (data) => async (dispatch, getState) => {
  dispatch(requestProductsFeatured());

  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());
  try {
    // no need to pass the userID as it will get the current user from the JWT token
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/search/productteasers/featured`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          maxResults: data.pageSize || 6,
        },
      }
    );

    dispatch(successProductsFeatured(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failProductsFeatured(e));
  }
};

// fetchProductsAllCategories
const requestProductsForAllCategories = () => ({
  type: TYPES.PRODUCTS_FORALLCATEGORIES_REQUEST,
});
const successProductsForAllCategories = (payload) => ({
  type: TYPES.PRODUCTS_FORALLCATEGORIES_SUCCESS,
  payload,
});
const failProductsForAllCategories = (error) => ({
  type: TYPES.PRODUCTS_FORALLCATEGORIES_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProductsForAllCategories =
  (data) => async (dispatch, getState) => {
    dispatch(requestProductsForAllCategories());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/productteasers/all?pageSize=${
          data.pagesize || 6
        }&pageNumber=${data.pagenumber || 1}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successProductsForAllCategories(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProductsForAllCategories(e));
    }
  };

// fetchProductsAllCategoriesByOrg
const requestProductsForAllCategoriesByOrg = () => ({
  type: TYPES.PRODUCTS_FORALLCATEGORIES_BYORG_REQUEST,
});
const successProductsForAllCategoriesByOrg = (payload) => ({
  type: TYPES.PRODUCTS_FORALLCATEGORIES_BYORG_SUCCESS,
  payload,
});
const failProductsForAllCategoriesByOrg = (error) => ({
  type: TYPES.PRODUCTS_FORALLCATEGORIES_BYORG_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProductsForAllCategoriesByOrg =
  (data) => async (dispatch, getState) => {
    dispatch(requestProductsForAllCategoriesByOrg());
    const zipCode =
      getZip(getState()) ||
      getDefaultAddressZip(getState()) ||
      getZipFromLatLong(getState()) ||
      localStorage.getItem("zipCode");
    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/productteasers/org/${
          data.id
        }?pageSize=${data.pagesize || 10}&pageNumber=${
          data.pagenumber || 1
        }&zipCode=${zipCode}
        `,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successProductsForAllCategoriesByOrg(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProductsForAllCategoriesByOrg(e));
    }
  };

const deleteProductRequest = (resourceId) => ({
  type: TYPES.PRODUCT_BYID_DELETE_REQUEST,
  resourceId,
});
const deleteProductSuccess = (resourceId) => ({
  type: TYPES.PRODUCT_BYID_DELETE_SUCCESS,
  resourceId,
});
const deleteProductFail = (resourceId, error) => ({
  type: TYPES.PRODUCT_BYID_DELETE_FAIL,
  resourceId,
  error,
});

export const deleteProduct = (resourceId) => async (dispatch, getState) => {
  dispatch(deleteProductRequest(resourceId));
  const jwt = getToken(getState());
  try {
    await axios.delete(
      `${helpers.serverUrl}/api/v1/resources/product/${resourceId}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch(deleteProductSuccess(resourceId));
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
    dispatch(deleteProductFail(resourceId, e));
  }
};

const requestVotableProducts = () => ({
  type: TYPES.PRODUCTS_VOTABLE_REQUEST,
});
const successVotableProducts = (payload) => ({
  type: TYPES.PRODUCTS_VOTABLE_SUCCESS,
  payload,
});
const failVotableProducts = (error) => ({
  type: TYPES.PRODUCTS_VOTABLE_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchVotableProducts =
  (data, withoutLoader = false) =>
  async (dispatch, getState) => {
    if (!withoutLoader) {
      dispatch(requestVotableProducts());
    }

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/productteasers/votable`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            pageNumber: data.pageNumber || 1,
            pageSize: data.pageSize || 12,
          },
        }
      );

      dispatch(successVotableProducts(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failVotableProducts(e));
    }
  };

const requestDeliveryWindows = () => ({
  type: TYPES.DELIVERY_WINDOWS_REQUEST,
});
const successDeliveryWindows = (payload) => ({
  type: TYPES.DELIVERY_WINDOWS_SUCCESS,
  payload,
});
const failDeliveryWindows = (error) => ({
  type: TYPES.DELIVERY_WINDOWS_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchDeliveryWindows = (data) => async (dispatch, getState) => {
  dispatch(requestDeliveryWindows());

  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());
  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/ResourceRequest/windows-delivery/${data.locationId}?productId=${data.productId}&readyByUtc=${data.readyByDate}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch(successDeliveryWindows(res.data));
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
    dispatch(failDeliveryWindows(e));
  }
};

const requestReturnWindows = () => ({
  type: TYPES.RETURN_WINDOWS_REQUEST,
});
const successReturnWindows = (payload) => ({
  type: TYPES.RETURN_WINDOWS_SUCCESS,
  payload,
});
const failReturnWindows = (error) => ({
  type: TYPES.RETURN_WINDOWS_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchReturnWindows = (data) => async (dispatch, getState) => {
  dispatch(requestReturnWindows());

  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());
  try {
    // no need to pass the userID as it will get the current user from the JWT token
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/ResourceRequest/windows-return/${data.locationId}?productId=${data.productId}&readyByUtc=${data.readyByDate}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    dispatch(successReturnWindows(res.data));
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
    dispatch(failReturnWindows(e));
  }
};

const requestDeliveryWindowsInventoryByZip = () => ({
  type: TYPES.DELIVERY_WINDOWS_INVENTORY_BY_ZIP_REQUEST,
});
const successDeliveryWindowsInventoryByZip = (payload) => ({
  type: TYPES.DELIVERY_WINDOWS_INVENTORY_BY_ZIP_SUCCESS,
  payload,
});
const failDeliveryWindowsInventoryByZip = (error) => ({
  type: TYPES.DELIVERY_WINDOWS_INVENTORY_BY_ZIP_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchDeliveryWindowsInventoryByZip =
  (data) => async (dispatch, getState) => {
    dispatch(requestDeliveryWindowsInventoryByZip());
    const region = getRegion(getState());
    if (!data) {
      data = {};
    }
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/ResourceRequest/windows-inventory-zip/${
          data.regionId || region.id
        }`,
        {
          params: {
            destinationZipPostal: data.destinationZipPostal,
            productId: data.productId,
            beginUtc: data.beginUtc,
            endUtc: data.endUtc,
            getItNow: data.getItNow,
          },
        }
      );
      dispatch(successDeliveryWindowsInventoryByZip(res.data));
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      dispatch(failDeliveryWindowsInventoryByZip(e));
      if (
        e.response &&
        e.response.data === "Only the Boston Region is currently supported"
      ) {
        mixpanel.track("Unserviced Zip Entered", {
          zipCode: data.destinationZipPostal,
          productId: data.productId,
        });
      }
    }
  };

const requestDeliveryOptionsByZip = () => ({
  type: TYPES.DELIVERY_OPTIONS_BY_ZIP_REQUEST,
});
export const successDeliveryOptionsByZip = (payload) => ({
  type: TYPES.DELIVERY_OPTIONS_BY_ZIP_SUCCESS,
  payload,
});
export const failDeliveryOptionsByZip = (error) => ({
  type: TYPES.DELIVERY_OPTIONS_BY_ZIP_FAIL,
  error,
});
export const resetDeliveryOptions = () => ({
  type: TYPES.DELIVERY_OPTIONS_BY_ZIP_RESET,
});
// eslint-disable-next-line
export const fetchDeliveryOptionsByZip =
  (data) => async (dispatch, getState) => {
    dispatch(requestDeliveryOptionsByZip());
    if (!data) {
      data = {};
    }

    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v2/Delivery/options-zip/${data.productId}`,

        {
          params: {
            beginUtc: moment(data.beginUtc).toISOString(),
            zipPostal: data.destinationZipPostal,
            endUtc: moment(data.endUtc).toISOString(),
            ignoreInventory: data.ignoreInventory,
          },
        }
      );

      dispatch(successDeliveryOptionsByZip(res.data));
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      dispatch(failDeliveryOptionsByZip(e.response && e.response.data));

      dispatch(failDeliveryOptionsByZip(e));
      if (e.response && e.response.status === 400) {
        mixpanel.track("Unserviced Zip Entered", {
          zipCode: data.destinationZipPostal,
          productId: data.productId,
        });
      }
    }
  };

const requestDeliveryOptionsByGPS = () => ({
  type: TYPES.DELIVERY_OPTIONS_BY_GPS_REQUEST,
});
const successDeliveryOptionsByGPS = (payload) => ({
  type: TYPES.DELIVERY_OPTIONS_BY_GPS_SUCCESS,
  payload,
});
const failDeliveryOptionsByGPS = (error) => ({
  type: TYPES.DELIVERY_OPTIONS_BY_GPS_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchDeliveryOptionsByGPS = (data) => async (dispatch) => {
  dispatch(requestDeliveryOptionsByGPS());
  if (!data) {
    data = {};
  }
  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v2/Delivery/options-gps/${data.productId}?latitude=${data.latitude}&longitude=${data.longitude}&beginUtc=${data.beginUtc}&endUtc=${data.endUtc}`,
      null
    );

    dispatch(successDeliveryOptionsByGPS(res.data));
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
    dispatch(failDeliveryOptionsByGPS(e));
    if (
      e.response &&
      e.response.data === "Only the Boston Region is currently supported"
    ) {
      mixpanel.track("Unserviced Zip Entered", {
        zipCode: data.destinationZipPostal,
        productId: data.productId,
      });
    }
  }
};

export const setItemRentQueryStringOptions = (payload) => ({
  type: TYPES.SET_ITEM_RENT_QUERY_STRING_OPTIONS,
  payload,
});

export const resetItemRentQueryStringOptions = () => ({
  type: TYPES.RESET_ITEM_RENT_QUERY_STRING_OPTIONS,
});

const requestDeliveryWindowsInventoryByGPS = () => ({
  type: TYPES.DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_REQUEST,
});
const successDeliveryWindowsInventoryByGPS = (payload) => ({
  type: TYPES.DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_SUCCESS,
  payload,
});
const failDeliveryWindowsInventoryByGPS = (error) => ({
  type: TYPES.DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchDeliveryWindowsInventoryByGPS =
  (data) => async (dispatch, getState) => {
    dispatch(requestDeliveryWindowsInventoryByGPS());
    const region = getRegion(getState());
    if (!data) {
      data = {};
    }
    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/ResourceRequest/windows-inventory-gps/${region.id}`,
        {
          params: {
            latitude: data.latitude,
            longitude: data.longitude,
            productId: data.productId,
            beginUtc: data.beginUtc,
            endUtc: data.endUtc,
            getItNow: data.getItNow,
          },
        }
      );

      dispatch(successDeliveryWindowsInventoryByGPS(res.data));
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e.response)
        )
      );
      dispatch(failDeliveryWindowsInventoryByGPS(e));
    }
  };

const requestExperiencesAll = () => ({
  type: TYPES.EXPERIENCES_ALL_REQUEST,
});
const successExperiencesAll = (payload) => ({
  type: TYPES.EXPERIENCES_ALL_SUCCESS,
  payload,
});
const failExperiencesAll = (error) => ({
  type: TYPES.EXPERIENCES_ALL_FAIL,
  error,
});
const successArticlesAll = (payload) => ({
  type: TYPES.ARTICLES_ALL_SUCCESS,
  payload,
});
const failArticlesAll = (error) => ({
  type: TYPES.ARTICLES_ALL_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchAllProductTeasers = (data) => async (dispatch, getState) => {
  dispatch(requestProductsFeatured());
  dispatch(requestVotableProducts());
  dispatch(requestProductsByRecentlyUploaded());
  dispatch(requestProductsTrending());
  dispatch(requestExperiencesAll());

  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());
  const zipCode =
    getDefaultAddressZip(getState()) ||
    getZip(getState()) ||
    getZipFromLatLong(getState()) ||
    localStorage.getItem("zipCode");

  const region = getRegion(getState());

  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/search/productteasers/home`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          maxResults: data.pageSize || 6,
          zipPostal: zipCode,
          region: region.id,
        },
      }
    );

    dispatch(successArticlesAll(res.data.content));
    dispatch(successProductsFeatured(res.data.featured));
    dispatch(successVotableProducts(res.data.votable));
    dispatch(successProductsByRecentlyUploaded(res.data.recent));
    dispatch(successProductsTrending(res.data.trending));
    dispatch(successExperiencesAll(res.data.content));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failProductsFeatured(e));
    dispatch(failVotableProducts(e));
    dispatch(failProductsByRecentlyUploaded(e));
    dispatch(failProductsTrending(e));
    dispatch(failExperiencesAll(e));
    dispatch(failArticlesAll(e));
  }
};

const requestZipInfo = () => ({
  type: TYPES.ZIP_INFO_REQUEST,
});

const failZipInfo = (error) => ({
  type: TYPES.ZIP_INFO_FAIL,
  error,
});

const getRegionZipStatus = (data) => {
  if (data.isActive && data.isServiced && data.id > 1) {
    return "Active";
  }
  if (!data.isActive && data.isServiced) {
    return "Closed";
  }
  if (!data.isActive && !data.isServiced) {
    return "Unserviced";
  }
};

export const fetchZipInfo =
  (zip, showMessage) => async (dispatch, getState) => {
    dispatch(requestZipInfo());
    const productId =
      getProductId(getState()) || "00000000-0000-0000-0000-000000000000";
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/verify/serviceable-product/${zip}?productid=${productId}`
      );
      const { zipPostal, Id, IsServiced, regionName, State } = res.data;
      res.data = {
        ...res.data,
        defaultZipPostal: zipPostal,
        id: Id,
        isServiced: IsServiced,
        name: regionName,
        state: State,
      };

      if (showMessage !== true) {
        showMessage = false;
      }
      dispatch({
        type: TYPES.CHANGE_REGION,
        payload: res.data,
      });
      localStorage.setItem("region", JSON.stringify(res.data));
      const regionZipStatus = getRegionZipStatus(res.data);
      if (showMessage) {
        switch (regionZipStatus) {
          case "Active":
            // dispatch(
            //   showSnackbar(
            //     `You have added a valid zip code for the ${res.data.name} region`,
            //     "success"
            //   )
            // );
            break;
          case "Closed":
            // dispatch(
            //   showSnackbar(`You have entered a valid zip code`, "success")
            // );
            break;
          case "Unserviced":
            dispatch(
              showSnackbar(`The entered zip code is Unserviced`, "error")
            );
            break;
          default:
            dispatch(
              showSnackbar(`The zip code you added is not serviced`, "error")
            );
        }
      }

      const _address = `${res.data.name ? `${res.data.name}, ` : ""} ${
        res.data.state
      } ${res.data.zipPostal}`;

      dispatch({
        type: TYPES.SET_ZIP_ADDRESS,
        payload: JSON.stringify({
          address: _address,
          isServicedZip: res.data.isServiced,
        }),
      });

      return res.data;
    } catch (e) {
      dispatch(failZipInfo((e.response && e.response.data) || e.message));
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  };

export const requestZipVerficationByStoreId =
  (zip, storeId) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${helpers.serverUrl}/api/v1/verify/serviceable-depot/${storeId}`,
        {
          params: {
            zipPostal: zip,
          },
        }
      );

      return data;
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  };

export const setZipCode =
  (payload, showMessage, withFetchZipInfo = true) =>
  (dispatch) => {
    dispatch({ type: TYPES.SET_ZIP, payload });
    dispatch({ type: "SET_ITEM_RENT_ZIP", payload });
    let res = {};
    if (withFetchZipInfo) {
      res = dispatch(fetchZipInfo(payload, showMessage));
    }

    localStorage.setItem("zipCode", payload);

    if (Object.keys(res)) {
      return res;
    }
  };

export const resetZipCode = () => (dispatch) => {
  const payload = null;
  dispatch({ type: TYPES.RESET_ZIP });
  dispatch({ type: TYPES.RESET_ZIP_ADDRESS });
  dispatch({ type: "SET_ITEM_RENT_ZIP", payload });
  localStorage.setItem("zipCode", "");
};

const requestProductsInRegion = () => ({
  type: TYPES.PRODUCTS_IN_REGION_REQUEST,
});
const successProductsInRegion = (payload) => ({
  type: TYPES.PRODUCTS_IN_REGION_SUCCESS,
  payload,
});
const failProductsInRegion = (error) => ({
  type: TYPES.PRODUCTS_IN_REGION_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProductsInRegion = (data) => async (dispatch, getState) => {
  dispatch(requestProductsInRegion());

  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());
  try {
    // no need to pass the userID as it will get the current user from the JWT token
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/search/productteasers/zip/${data}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    dispatch(successProductsInRegion(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failProductsInRegion(e));
  }
};

const requestProductAddOnsByZip = () => ({
  type: TYPES.PRODUCT_ADD_ONS_BY_ZIP_REQUEST,
});
const successProductAddOnsByZip = (payload) => ({
  type: TYPES.PRODUCT_ADD_ONS_BY_ZIP_SUCCESS,
  payload,
});
const failProductAddOnsByZip = (error) => ({
  type: TYPES.PRODUCT_ADD_ONS_BY_ZIP_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProductAddOnsByZip = (data) => async (dispatch, getState) => {
  dispatch(requestProductAddOnsByZip());

  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());
  try {
    // no need to pass the userID as it will get the current user from the JWT token
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/resources/product-addons/${data.productId}?zipcode=${data.zip}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    dispatch(successProductAddOnsByZip(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failProductAddOnsByZip(e));
  }
};

const requestProfiledResourcesForProductId = () => ({
  type: TYPES.PROFILED_RESOURCES_FOR_PRODUCT_REQUEST,
});
const successProfiledResourcesForProductId = (payload) => ({
  type: TYPES.PROFILED_RESOURCES_FOR_PRODUCT_SUCCESS,
  payload,
});
const failProfiledResourcesForProductId = (error) => ({
  type: TYPES.PROFILED_RESOURCES_FOR_PRODUCT_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProfiledResourcesForProductId =
  (data) => async (dispatch, getState) => {
    dispatch(requestProfiledResourcesForProductId());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());

    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/profiled/attributes/${data.productId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successProfiledResourcesForProductId(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProfiledResourcesForProductId(e));
    }
  };

const requestProductsWithFilters = () => ({
  type: TYPES.PRODUCTS_WITH_FILTERS_REQUEST,
});
const successProductsWithFilters = (payload) => ({
  type: TYPES.PRODUCTS_WITH_FILTERS_SUCCESS,
  payload,
});
const failProductsWithFilters = (error) => ({
  type: TYPES.PRODUCTS_WITH_FILTERS_FAIL,
  error,
});
export const resetProductsWithFilters = () => ({
  type: TYPES.PRODUCTS_WITH_FILTERS_RESET,
});

// ORGANIZATION_PRODUCTS
const requestOrganizationProducts = () => ({
  type: TYPES.ORGANIZATION_PRODUCTS_REQUEST,
});
const successOrganizationProducts = (payload) => ({
  type: TYPES.ORGANIZATION_PRODUCTS_SUCCESS,
  payload,
});
const successOrganizationProductsCategories = (payload) => ({
  type: TYPES.ORGANIZATION_PRODUCTS_CATEGORIES_SUCCESS,
  payload,
});
const failOrganizationProducts = (error) => ({
  type: TYPES.ORGANIZATION_PRODUCTS_FAIL,
  error,
});
export const resetOrganizationProducts = () => ({
  type: TYPES.ORGANIZATION_PRODUCTS_RESET,
});

// eslint-disable-next-line
export const fetchProductsWithFilters =
  (data) => async (dispatch, getState) => {
    dispatch(requestProductsWithFilters());

    if (!data) {
      data = {};
    }

    const jwt = getToken(getState());
    const cartkey = data.hasOwnProperty("cartKey")
      ? data.cartKey
      : getCartKey(getState());

    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/products`,
        {
          params: {
            ...(data?.organizationFriendlyName
              ? { organizationFriendlyName: data.organizationFriendlyName }
              : data?.organizationId && {
                  organizationId: data.organizationId,
                }),
            categoryId: data?.categoryId,
            subcategory: data.subcategory,
            includeInWidget: data.includeInWidget,
            isBundle: data.isBundle,
            isBundled: data.isBundled,
            parentBundle: data.parentBundle,
            featured: data.featured,
            publishStatus: data.publishStatus,
            priceFrom: data.priceFrom,
            priceTo: data.priceTo,
            rating: data.rating,
            depotId: data.depotId,
            zipCode: data.zipCode,
            utcBegin: data?.utcBegin && data.utcBegin.toISOString(),
            utcEnd: data?.utcEnd && data.utcEnd.toISOString(),
            region: data.region,
            searchString: data.searchString,
            pagesize: data.pagesize,
            pagenumber: data.pagenumber,
            ignoreReserved: data?.ignoreReserved,
            cartkey,
            // @todo: old query param in case of undefined will place only "false" value, which is incorrect
            // data?.includeCartItems ? `&includeCartItems=${data.includeCartItems}` : "false"
            includeCartItems: data.includeCartItems,
            hasInventory:
              typeof data?.hasInventory === "boolean"
                ? data.hasInventory
                : null,
            isDecommissioned: data?.isDecommissioned,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successProductsWithFilters(res.data));
      if (
        res.data.categories &&
        res.data.categories.length < 1 &&
        data.nextStep
      ) {
        data.nextStep();
      }
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProductsWithFilters(e));
    }
  };

export const fetchProductsByOrganization =
  (params) => async (dispatch, getState) => {
    dispatch(requestOrganizationProducts());

    const jwt = getToken(getState());

    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/products`,
        {
          params,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      const organizationResources = res?.data?.categories;
      const organizationProductsByResourceId = {};
      const subCategories = new Set();

      if (organizationResources) {
        organizationResources.forEach((item) => {
          // add resources
          for (const resource of item.resources) {
            const existResource =
              organizationProductsByResourceId[resource.resourceId];

            // if resource already exist add current category to exist resource
            // @todo: This categories not in use currently
            if (existResource) {
              existResource.productCategories.push(item.category);

              continue;
            }

            organizationProductsByResourceId[resource.resourceId] = resource;
            organizationProductsByResourceId[
              resource.resourceId
            ].productCategories.push(item.category);

            // @todo: sub-categories for products filtration (Home Depot only)
            if (resource.productSubcategoryName) {
              subCategories.add(resource.productSubcategoryName);
            }
          }
        });
      }

      dispatch(
        successOrganizationProducts(
          Object.values(organizationProductsByResourceId)
        )
      );
      dispatch(
        successOrganizationProductsCategories(Array.from(subCategories))
      );
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failOrganizationProducts(e));
    }
  };

export const fetchProductsWithFiltersAppointment =
  (data) => async (dispatch, getState) => {
    dispatch(requestProductsWithFilters());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      // no need to pass the userID as it will get the current user from the JWT token
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/products`,
        {
          params: {
            ...(data?.organizationFriendlyName
              ? { organizationFriendlyName: data.organizationFriendlyName }
              : data?.organizationId && {
                  organizationId: data.organizationId,
                }),
            pagesize: data.pagesize,
            pagenumber: data.pagenumber,
            isBundled: data.isBundled,
            isDecommissioned: false,
            hasInventory: data.hasInventory,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successProductsWithFilters(res.data));
      if (res.data.categories && res.data.categories.length < 1) {
        data.nextStep();
      }
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProductsWithFilters(e));
    }
  };
