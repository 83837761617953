import React, { Component } from "react";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import withWidth from "@material-ui/core/withWidth";
import SkiptiButton from "../skipti/Button";

class OptionalServiceDisablePopper extends Component {
  state = {
    anchorEl: null,
    open: this.props.isOpen,
  };

  handlePriceInfoClick = (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  handleClickAway = () => {
    this.setState({
      open: false,
    });
  };

  handleArrowRef = (node) => {
    // this.setState({
    //   arrowRef: node,
    // });
  };

  defaultAction = () => {
    console.log("here");
  };

  render() {
    const {
      handleChange,
      isOpen,
      anchorEl,
      title,
      description,
      onCancel,
      onProceed,
    } = this.props;
    const id = isOpen ? "simple-popper" : null;

    return (
      <div className="tw-inline-block">
        <ClickAwayListener onClickAway={handleChange ?? this.defaultAction}>
          <>
            <Popper
              id={id}
              open={isOpen}
              style={{ zIndex: 3333, width: "350px" }}
              anchorEl={anchorEl}
              transition
              placement="left"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <div className="tw-p-12">
                      <h4 className="tw-mb-4">
                        <strong>{title}</strong>
                      </h4>
                      <button
                        type="button"
                        className="tw-float-right close-button-modal"
                        onClick={onCancel}
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="tw-flex tw-justify-between tw-flex-col tw-my-6">
                        {description}
                      </div>
                      <div className="tw-flex">
                        <SkiptiButton
                          secondary
                          style={{ marginLeft: "auto", marginRight: "10px" }}
                          type="button"
                          onClick={onCancel}
                        >
                          Cancel
                        </SkiptiButton>
                        <SkiptiButton
                          primary
                          style={{}}
                          type="button"
                          onClick={onProceed}
                        >
                          Proceed
                        </SkiptiButton>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </>
        </ClickAwayListener>
      </div>
    );
  }
}
export default withWidth()(OptionalServiceDisablePopper);
