import { createSlice } from "@reduxjs/toolkit";

const layoutDashboardSlice = createSlice({
  name: "layoutDashboard",
  initialState: {
    collapses: [],
  },
  reducers: {
    addCollapseItem(state, action) {
      state.collapses = [...state.collapses, action.payload];
    },
    setCollapses(state, action) {
      state.collapses = action.payload;
    },
  },
});

export const { addCollapseItem, setCollapses } = layoutDashboardSlice.actions;
export const getCollapses = (state) => state.collapses.collapses;
export default layoutDashboardSlice.reducer;
