import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import { additionalTexts, steps, stepsText } from "../staticData";

const EnterZipStep = ({
  changeHandler,
  isProcessing,
  additionalStepClickHandler,
  onSubmit,
  currentStep,
  values,
  errors,
}) => {
  const [stepProperties, setStepProperties] = useState(stepsText.zipCodeStep);

  useEffect(() => {
    setStepProperties(stepsText[currentStep]);
  }, [currentStep]);

  const disableChecker = () => {
    if (currentStep === steps.thankYouStep) {
      return false;
    }

    if (currentStep === steps.unservicedAreaStep) {
      if (values.email && errors.email) {
        return true;
      }
      return false;
    }
    return (
      errors[stepProperties.fieldName] ||
      isProcessing ||
      !values[stepProperties.fieldName]
    );
  };

  return (
    <>
      <h3 className="tw-font-bold tw-mb-5 tw-text-lg">
        {stepProperties.mainText}
      </h3>

      {stepProperties.additionalText ? (
        <p className="tw-font-bold tw-mb-5 tw-text-sm">
          {stepProperties.additionalText}
        </p>
      ) : null}

      <div>
        {stepProperties.fieldName ? (
          <TextField
            placeholder={stepProperties.placeholder}
            onChange={changeHandler}
            fullWidth
            disabled={isProcessing}
            type={stepProperties.fieldType}
            name={stepProperties.fieldName}
            style={{
              marginBottom: "20px",
            }}
            value={values[stepProperties.fieldName]}
            error={errors[stepProperties.fieldName] !== undefined}
            helperText={errors[stepProperties.fieldName]}
          />
        ) : null}

        <ButtonWithSpinner
          isDisabled={disableChecker()}
          clickHandler={onSubmit}
          buttonText={stepProperties.buttonText}
          isProcessed={isProcessing}
        />

        <p
          onClick={additionalStepClickHandler}
          className="tw-text-center tw-text-skiptiOrange tw-cursor-pointer tw-underline tw-text-sm tw-mt-5"
        >
          <span>{additionalTexts[currentStep]}</span>
        </p>
      </div>
    </>
  );
};

export default EnterZipStep;
