import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import isEmail from "validator/lib/isEmail";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";

import LocationBarPopup from "./LocationBarPopup";
import LocationBarSlider from "./LocationBarSlider";
import useZip from "../../../hooks/useZip";
import { getZipModalOpen } from "../../../reducers/authReducer";
import { steps } from "./staticData";
import { showSnackbar } from "../../../actions";
import {
  ZIP_MODAL_CLOSE,
  PRODUCT_BYID_ERROR,
  HIDE_PRODUCTS_IN_POPUP,
} from "../../../types";
import {
  getIsAvailabilityError,
  showProductsInPopup,
} from "../../../reducers/itemsReducer";

const LocationBar = () => {
  const {
    updateZipCode,
    currentAddress,
    isServiced,
    isProcessing,
    isValidZipCode,
    addEmailInInterestList,
    updateErrorMessages,
    zipErrors,
    zip,
  } = useZip();

  const matches = useMediaQuery("(min-width:768px)");

  const [texts, setText] = useState({
    availableText: "",
    address: "",
  });

  const [isChangeDialogOpen, setIsChangeDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.zipCodeStep);
  const formRef = useRef(null);
  const history = useHistory();
  const zipModalOpen = useSelector(getZipModalOpen);
  const isAvailabilityError = useSelector(getIsAvailabilityError);
  const canShowProductsInPopup = useSelector(showProductsInPopup);
  const dispatch = useDispatch();

  const openPopup = useCallback(() => {
    if (isAvailabilityError && isServiced && canShowProductsInPopup) {
      setCurrentStep(steps.withProductsStep);
    }
    setIsChangeDialogOpen(true);
  }, [isAvailabilityError, isServiced, canShowProductsInPopup]);

  useEffect(() => {
    if (zipModalOpen) {
      openPopup();
    }
  }, [zipModalOpen]);

  useEffect(() => {
    setText({
      availableText: isServiced
        ? "Showing products in: "
        : "Outside of Delivery Area:",
      address: currentAddress,
    });
  }, [currentAddress, isServiced]);

  const closePopup = (showMessage = true) => {
    if (zipErrors && isChangeDialogOpen && showMessage) {
      dispatch(showSnackbar(zipErrors, "error"));
    }
    setCurrentStep(steps.zipCodeStep);
    setIsChangeDialogOpen(false);

    dispatch({ type: HIDE_PRODUCTS_IN_POPUP });
    dispatch({ type: PRODUCT_BYID_ERROR, payload: "" });

    updateErrorMessages();
    if (formRef.current) {
      formRef.current.handleReset();
    }
    dispatch({ type: ZIP_MODAL_CLOSE });
  };

  const submitHandler = async (values) => {
    switch (currentStep) {
      case steps.zipCodeStep: {
        const res = await updateZipCode(values.zipCode);
        if (res) {
          formRef.current.handleReset();
          closePopup();
        } else {
          setCurrentStep(steps.unservicedAreaStep);
        }
        break;
      }
      case steps.unservicedAreaStep:
      case steps.withProductsStep: {
        if (formRef.current.values.email) {
          await addEmailInInterestList({
            email: formRef.current.values.email,
            zipPostal: formRef.current.values.zipCode,
          });
          setCurrentStep(steps.thankYouStep);
        } else {
          closePopup();
        }
        break;
      }

      case steps.thankYouStep: {
        setCurrentStep(steps.zipCodeStep);
        formRef.current.handleReset();
        break;
      }
      default: {
        break;
      }
    }
  };

  const validationReason = async (values) => {
    const errors = {};
    if (values.zipCode.length > 5) {
      errors.zipCode = "Zipcode length should be 5 digits";
    } else if (!isValidZipCode(values.zipCode) || values.zipCode === "00000") {
      errors.zipCode = "You added an invalid Zip Code!";
    }
    if (values.email && !isEmail(values.email)) {
      errors.email = "Invalid Email!";
    }
    return errors;
  };

  const additionalTextsHandler = () => {
    switch (currentStep) {
      case steps.zipCodeStep: {
        setCurrentStep(steps.howDoesItWork);
        break;
      }
      case steps.unservicedAreaStep: {
        formRef.current.handleReset();
        setCurrentStep(steps.zipCodeStep);
        break;
      }
      case steps.thankYouStep: {
        history.push("/discover");
        formRef.current.handleReset();
        closePopup(false);
        break;
      }
      case steps.howDoesItWork: {
        setCurrentStep(steps.zipCodeStep);
        break;
      }

      default:
        break;
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          zipCode: "",
          email: "",
        }}
        enableReinitialize
        validate={validationReason}
        innerRef={formRef}
      >
        {({ handleChange, values, errors }) => (
          <Form>
            {matches ? (
              <LocationBarPopup
                texts={texts}
                toggle={closePopup}
                isChangeDialogOpen={isChangeDialogOpen}
                currentStep={currentStep}
                onSubmit={() => submitHandler(values)}
                values={values}
                changeHandler={handleChange}
                errors={errors}
                isProcessing={isProcessing}
                additionalStepClickHandler={additionalTextsHandler}
                openDialog={openPopup}
                zip={zip}
              />
            ) : (
              <LocationBarSlider
                texts={texts}
                toggle={closePopup}
                openDialog={openPopup}
                isChangeDialogOpen={isChangeDialogOpen}
                currentStep={currentStep}
                onSubmit={() => submitHandler(values)}
                values={values}
                changeHandler={handleChange}
                errors={errors}
                isProcessing={isProcessing}
                additionalStepClickHandler={additionalTextsHandler}
                zip={zip}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LocationBar;
