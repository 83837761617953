import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers/index";
import * as helpers from "../utils";

let preloadedState = {};
try {
  preloadedState = {
    auth: {
      token: localStorage.getItem("skiptiJWT"),
      isLoggedIn: !!localStorage.getItem("skiptiJWT"),
      logInModalOpen: false,
      user: {
        userId: localStorage.getItem("skiptiJWT")
          ? localStorage.getItem("skiptiUserId")
          : "",
        resourceOrganization: localStorage.getItem("skiptiOrgId"),
        socialId: "",
      },
      city: helpers.city,
      // city: "albuquerque"
    },
    user: {
      isCustomerReviewModalOpen: false,
      isMerchantReviewModalOpen: false,
      isDriverReviewModalOpen: false,
      regions: [],
      region: JSON.parse(localStorage.getItem("region")) || {
        defaultZipPostal: "77373",
        id: 14,
        name: "Houston",
        state: "TX",
      },
    },
  };
} catch (e) {
  preloadedState = {
    auth: {
      token: null,
      isLoggedIn: false,
      logInModalOpen: false,
      user: {
        region: null,
        regions: [],
        skippedFlags: [],
        usersByOrganizationId: helpers.makeInitial(),
        reviewsAsOwner: helpers.makeInitial(),
        reviewsAsRenter: helpers.makeInitial(),
        customerReviewsAsAuthor: helpers.makeInitial(),
        merchantReviewsAsAuthor: helpers.makeInitial(),
        isCustomerReviewModalOpen: false,
        isMerchantReviewModalOpen: false,
        isDriverReviewModalOpen: false,
        customerReview: {},
        merchantReview: {},
        driverReview: {},
        isFromRugdoctor: false,
        zipFromService: "",
        isCurrentLocationServiceable: "",
      },
      city: helpers.city,
      // city: "albuquerque"
    },
  };
}

// eslint-disable-next-line
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
    trace: true,
    traceLimit: 25,
  }) || compose;

const reduxStore = createStore(
  reducers,
  preloadedState,
  helpers.environment === "development"
    ? composeEnhancers(applyMiddleware(thunk))
    : composeEnhancers(applyMiddleware(thunk))
);

export default reduxStore;
