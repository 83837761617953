import mixpanel from "mixpanel-browser";
import * as TYPES from "../types";

export const hideSnackbar = (key) => ({
  type: TYPES.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

/**
 *
 * @param {string} message
 * @param {"warning" | "success" | "error"} variant
 * @param {any} autoHideDuration
 * @param {number} key
 * @param {{}} moreDetails
 */
export const showSnackbar =
  (
    message,
    variant = "error",
    autoHideDuration,
    key,
    moreDetails,
    requestPayload
  ) =>
  (dispatch) => {
    let _message = message;
    if (variant === "error") {
      // @radu we can add mixpanel here
      const _error = moreDetails ? JSON.parse(moreDetails) : null;
      if (_error && _error.response && Number(_error.response.status) === 403) {
        _message =
          "You do not currently have access rights to perform this operation. Please contact your administrator";
      }

      // if (error)
      mixpanel.track("Error shown in snackbar", {
        error: _message,
        url: moreDetails && moreDetails.config && moreDetails.config.url,
        params: moreDetails && moreDetails.config && moreDetails.config.params,
        requestPayload,
      });
      window.analytics.track("Error shown in snackbar", {
        error: _message,
        url: moreDetails && moreDetails.config && moreDetails.config.url,
        params: moreDetails && moreDetails.config && moreDetails.config.params,
        requestPayload,
      });
    }
    dispatch({
      type: TYPES.ENQUEUE_SNACKBAR,
      notification: {
        message: _message,
        options: {
          variant,
        },
        key: key || new Date().getTime() + Math.random(),
      },
    });
  };

export const removeSnackbar = (key) => ({
  type: TYPES.REMOVE_SNACKBAR,
  key,
});
