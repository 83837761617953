import React from "react";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink } from "react-router-hash-link";
import { Skeleton } from "@material-ui/lab";

export default function CategoryList(props) {
  const settings = {
    arrows: true,
    dots: false,
    slidesToScroll: 2,
    slidesToShow: 10,
    infinite: false,
    centerPadding: "60px",
    focusOnSelect: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 10,
          arrows: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          arrows: true,
        },
      },
      {
        breakpoint: 922,
        settings: {
          slidesToShow: 6,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          arrows: false,
        },
      },
    ],
  };
  const { categories } = props;
  return (
    <div className="category-list tw-mx-auto tw-py-6 tw-px-4 tw-border-t-2 tw-border-almostwhite">
      <div className="fade-container">
        <div className="tw-container tw-mx-auto tw-px-4">
          <ul className="carousel-categories tw-pl-0 tw-list-none tw-mb-0">
            <Carousel {...settings} className="tw-pl-0 tw-list-none tw-mb-0">
              {categories.length === 0
                ? Array(3)
                    .fill({})
                    .map((item, index) => (
                      <li key={index}>
                        <div style={{ height: "8px" }} />
                        <Skeleton
                          variant="rect"
                          width="80%"
                          style={{
                            minHeight: "15px",
                            height: "100%",
                          }}
                        />
                      </li>
                    ))
                : categories.map(({ category }) => (
                    <li key={category.id}>
                      <HashLink
                        className="tw-text-verylightgrey tw-text-sm tw-text tw-font-hnMedium"
                        smooth
                        to={`#${category.displayName}`}
                      >
                        {category.displayName}
                      </HashLink>
                    </li>
                  ))}
            </Carousel>
          </ul>
        </div>
      </div>
    </div>
  );
}
