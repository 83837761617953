import React from "react";
import Popover from "@mui/material/Popover";
import { IconButton, Icon } from "@material-ui/core";

export default function OversizedDeliveryPopup({
  popoverState,
  setPopoverState,
}) {
  return (
    <Popover
      open={Boolean(popoverState)}
      anchorEl={popoverState}
      onClose={() => setPopoverState(null)}
      anchorOrigin={{
        vertical: -65,
        horizontal: -330,
      }}
    >
      <IconButton
        size="small"
        style={{
          position: "absolute",
          top: "8px",
          right: "8px",
        }}
        onClick={() => setPopoverState(null)}
      >
        <Icon fontSize="small">close</Icon>
      </IconButton>
      <div className="tw-px-5 tw-py-6" style={{ width: "300px" }}>
        <p className="tw-text-center tw-font-semibold tw-pb-1">
          Delivery Notice
        </p>
        <p className="tw-text-sm">
          This order includes an oversized item. Oversized items incur
          additional handling and transport fees.
        </p>
      </div>
    </Popover>
  );
}
