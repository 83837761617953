import React, { useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import SkiptiDialog from "../skipti/SkiptiDialog";
import Spinner from "../skipti/Spinner";

const RegisterInterestAlert = (props) => {
  const location = useLocation();
  const history = useHistory();

  const onClosePopup = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("alternative")) {
      queryParams.delete("alternative");
      history.replace({
        search: queryParams.toString(),
      });
    }

    props.onClose();
  };

  const renderSplitText = () => {
    const paragraphParts = props.data.bodyText.split("<br/>");

    return (
      <div className="tw-text-center">
        <p className="tw-font-bold">{paragraphParts[0]}</p>
        <p className="tw-font-bold">---</p>
        <p className="tw-mb-5">{paragraphParts[1]}</p>
      </div>
    );
  };

  return (
    <SkiptiDialog
      open
      withCloseIcon
      toggle={onClosePopup}
      maxWidth="xs"
      fullWidth={true}
      title=" "
    >
      {props.data && (
        <div>
          <div className="tw-flex tw-justify-center">
            <img
              className="tw-w-2/3"
              src={props.data.popupLogo}
              alt="Organization Logo"
            />
          </div>

          {renderSplitText()}

          <div className="tw-flex tw-justify-center">
            <Button
              variant="contained"
              style={{ background: "#333333", width: 250, height: 50 }}
              onClick={onClosePopup}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </SkiptiDialog>
  );
};

RegisterInterestAlert.propTypes = {
  from: PropTypes.string,
  onClose: PropTypes.func,
  isLoaded: PropTypes.bool,
  data: PropTypes.shape({
    popupLogo: PropTypes.string,
    bodyText: PropTypes.string,
  }),
};

export default RegisterInterestAlert;
