import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import strings from "../strings.json";
import Mosaic from "./partials/Mosaic";

import LayoutHome from "./layouts/LayoutHome";
import { getIsLoggedIn, getCityName } from "../reducers/authReducer";
import { getCategories } from "../reducers/categoriesReducer";
import ProductsTrending from "./partials/ProductsTrending";
import ProductsFeatured from "./partials/ProductsFeatured";
import Featurette from "./partials/Featurette";
import ExperienceRow from "./partials/ExperienceRow";
import { imageServerUrl } from "../utils";
import FeaturetteVideo from "./partials/FeaturetteVideo";
import {
  getProductsByRecentlyViewed,
  getProductsVotable,
  getZip,
} from "../reducers/itemsReducer";
import MainAlbuquerqueBox from "./partials/MainAlbuquerqueBox";

import VotingRow from "./voting/VoteItems";

import {
  showSnackbar,
  openLogInModal,
  fetchAllProductTeasers,
} from "../actions";
import InventoryInterest from "./partials/InventoryInterest";
import { getRegion } from "../reducers/userReducer";
import ProductsInRegion from "./partials/ProductsInRegion";
import { getExperiences } from "../reducers/experiencesReducer";

const timer = null;
const noProducts = 6;

class Main extends Component {
  state = {};

  componentDidMount() {
    this.props.fetchAllProductTeasers({ pagesize: noProducts });

    this.props.mixpanel.track("Main");
    window.analytics.page("Home");
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  static getDerivedStateFromProps = (props) => ({
    featuretteVideo: strings[props.city].main.featuretteVideo || {},
    video: strings[props.city].main.video,
    video720:
      strings[props.city].main.video720 || strings[props.city].main.video,
    video1080:
      strings[props.city].main.video1080 || strings[props.city].main.video,
    featurette1: strings[props.city].main.featurette1 || {},
    featurette2: strings[props.city].main.featurette2 || {},
  });

  render() {
    const { video, video720, featuretteVideo, featurette1 } = this.state;

    return (
      <LayoutHome showSearch>
        <div>
          {this.props.isLoggedIn === true ? (
            <Mosaic categories={this.props.categories} />
          ) : (
            <div>
              {this.props.city === "albuquerque" ? (
                <MainAlbuquerqueBox />
              ) : null}
              <FeaturetteVideo
                type={1}
                left
                video={`${imageServerUrl}/${video}`}
                videoHD={`${imageServerUrl}/${video720}`}
                showPlayButton
              >
                <>
                  <h2 className="tw-text-skiptiOrange tw-text-4xl">
                    {featuretteVideo.title}
                  </h2>
                  <p className="tw-text-mediumgrey">{featuretteVideo.text}</p>
                </>
              </FeaturetteVideo>

              {featurette1 !== null ? (
                <Featurette
                  type={0}
                  right
                  image={`${imageServerUrl}/${featurette1.image}`}
                >
                  <>
                    <h2 className="tw-text-skiptiOrange">
                      <div
                        dangerouslySetInnerHTML={{ __html: featurette1.title }}
                      />
                    </h2>
                    <p className="text-color4">{featurette1.text}</p>
                    <br />

                    <button
                      type="button"
                      onClick={() => {
                        this.props.openLogInModal(true);
                        this.props.mixpanel.track(
                          "Clicked Sign Up from Featurette #2"
                        );
                      }}
                      className="btn-skipti-primary mr-3"
                    >
                      Sign Up
                    </button>
                  </>
                </Featurette>
              ) : null}
            </div>
          )}

          {this.props.region && this.props.region.id !== 2 ? (
            <div className="tw-w-full tw-mx-auto tw-my-12 tw-px-4">
              <ProductsInRegion noProducts={6} />
            </div>
          ) : null}

          {/* <ProductsFeatured noProducts={6} />
          <div className="tw-w-full tw-mx-auto tw-my-12 tw-px-4">
            <h3 className="tw-my-4 tw-text-2xl">Trending</h3>
            <div className="tw-mx-0 tw-flex tw-flex-wrap">
              <ProductsTrending noProducts={6} />
            </div>
          </div> */}

          <div className="tw-w-full tw-mx-auto tw-my-4 tw-px-4">
            <div className="tw-mx-0 tw-flex tw-flex-wrap tw-my-1">
              <Link
                to="/discover"
                href="/discover"
                className="btn btn-skipti-orange tw-float-left tw-ml-4"
              >
                Show Items in Other Areas{" "}
                <i className="fal fa-angle-down ml-2" />
              </Link>
            </div>
          </div>
          {/* ) : null} */}
          {/* {this.props.experiences?.data?.teasers?.length > 0 && (
            <div className="tw-w-full tw-mx-auto tw-my-12 tw-px-4">
              <h3 className="tw-my-4 tw-text-2xl">Experiences:</h3>
              <p>
                Skipti will soon be powering curated experiences around the
                world. Here is a taste of what our Boston audience is up to.
              </p>
              <div className="tw-mx-0 tw-flex tw-flex-wrap tw-my-4">
                <ExperienceRow articleType={0} />
              </div>
            </div>
          )} */}
          {/* {this.props.votableItems?.data?.length > 0 ? (
            <div className="tw-w-full tw-mx-auto tw-my-12 tw-px-4">
              <h3 className="tw-my-4 tw-text-2xl">Coming soon</h3>
              <p>
                Our coming soon section is the best way for users to help us
                select our inventory. We place our top picks on the site and
                allow our users to select their favorites by adding themselves
                to the waitlist. The items with the largest waitlist will be
                made available on our platform first. We will notify all of our
                members via email when new items arrive.
              </p>
              <div className="tw-mx-0 tw-my-1 tw-flex tw-flex-wrap">
                <VotingRow />
              </div>
            </div>
          ) : null} */}

          {this.props.isLoggedIn !== true ? (
            <>
              <Featurette
                type={0}
                left
                image={`${imageServerUrl}/main/boston/savespace2.jpg`}
              >
                <>
                  <h2 className="tw-text-orange-500 tw-text-4xl tw-mb-2">
                    Save Space
                  </h2>
                  <p className="tw-text-gray-500 tw-text-lg">
                    We know your space is valuable. Clutter causes stress, and
                    storage units are costly. With Skipti, living in a small
                    city apartment doesn’t have to be limiting. When you’re done
                    using something, it’s gone.
                  </p>
                  <br />

                  <button
                    type="button"
                    className="btn-skipti-primary mr-3"
                    onClick={() => this.props.openLogInModal(true)}
                  >
                    Sign Up
                  </button>
                </>
              </Featurette>
              <Featurette
                type={0}
                right
                image={`${imageServerUrl}/main/boston/savetheplanet.jpg`}
              >
                <>
                  <h2 className="tw-text-orange-500 tw-text-4xl tw-mb-2">
                    Save The Planet
                  </h2>
                  <p className="tw-text-gray-500 tw-text-lg">
                    Did you know we only use 20% of the things we own every
                    month? Sharing resources creates less idle capacity and
                    reduces over production and waste. We believe efficiency is
                    the first step towards environmental sustainability.
                  </p>
                  <br />
                  <Link
                    to="/about"
                    href="/about"
                    className="btn-skipti-primary mr-3"
                  >
                    About our mission
                  </Link>
                </>
              </Featurette>

              <Featurette
                type={0}
                left
                image={`${imageServerUrl}/main/boston/trybeforeyoubuy.jpg`}
              >
                <>
                  <h2 className="tw-text-orange-500 tw-text-4xl tw-mb-2">
                    Try Before You Buy
                  </h2>
                  <p className="tw-text-gray-500 tw-text-lg">
                    We spend an average of 15 hours researching before making a
                    big purchase decision. That’s longer than it takes to
                    receive a rental from Skipti. If you’re curious how things
                    work in real life, skip the “unboxing” videos and try it for
                    yourself. Never worry about tricky returns and refunds.
                  </p>
                  <br />
                  <Link
                    to="/howitworks"
                    href="/howitworks"
                    className="btn-skipti-primary tw-mr-4"
                  >
                    How It Works
                  </Link>
                </>
              </Featurette>

              <InventoryInterest />
            </>
          ) : null}
        </div>
      </LayoutHome>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
  productCategories: getCategories(state).data || [],
  city: getCityName(state),
  recentProducts: getProductsByRecentlyViewed(state),
  votableItems: getProductsVotable(state),
  zip: getZip(state),
  region: getRegion(state),
  experiences: getExperiences(state),
});

export default connect(mapStateToProps, {
  showSnackbar,
  openLogInModal,
  fetchAllProductTeasers,
})(Main);
