/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import {
  Accordion,
  useMediaQuery,
  useTheme,
  AccordionSummary,
  makeStyles,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import ItemIncluded from "../../partials/ItemIncluded";
import ItemRules from "../../partials/ItemRules";
import ItemReviews from "../../partials/ItemReviews";
import ExperienceTile from "../../partials/ExperienceTile";
import SkiptiRating from "../../partials/Rating";
// import MobilePricebar from "./MobilePricebar";
import { ITEM_DELIVERY_MODEL } from "../../../actions/utils";

const conditionsArray = [
  {
    id: 1,
    name: "meh",
  },
  {
    id: 2,
    name: "usable",
  },
  {
    id: 3,
    name: "good",
  },
  {
    id: 4,
    name: "great",
  },
  {
    id: 5,
    name: "almost new",
  },
];

const useStyles = makeStyles({
  AccordionRoot: {
    boxShadow: "none",
    borderBottom: "1px solid #d6d6d6",
    borderTop: "0",
  },
  AccordionExpanded: {
    color: "rgb(240, 86, 35)",
  },
});

export default function ItemShowDescription(props) {
  const theme = useTheme();
  const classes = useStyles();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { product, pageMode } = props;

  const setSignatureText = (item) => {
    if (item.okDropoffOnPorch === true || item.okPickupOnPorch === true) {
      if (item.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY) {
        return `This item does not require you to be present during drop-off. Please take appropriate action to secure your order after it has been delivered.`;
      }

      if (item.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY) {
        return `This item does not require you to be present during pickup. Please take appropriate action to make sure the item is safe and secure and add all pickup instructions for the driver in the notes field.`;
      }

      return `This item does not require you to be present during drop-off or pickup. Please take appropriate action to secure your order after it has been delivered.`;
    }

    if (item.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY) {
      return `Please ensure you will be available to meet the driver upon dropoff.`;
    }

    if (item.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY) {
      return `Please ensure you will be available to meet the driver upon pickup.`;
    }

    return `Please ensure you will be available to meet the driver upon dropoff and pickup.`;
  };

  const getHtmlData = () => {
    const getHtml = product.descriptionHtml;
    if (/\swidth/g.test(getHtml)) {
      if (getHtml !== undefined) {
        const newHtml = getHtml.replace(/\swidth=/g, "max-width=");
        return newHtml;
      }
    }
    return getHtml;
  };

  return (
    <div className="md:tw-w-7/12 lg:tw-w-8/12 tw-px-4">
      {product.productCategories.map((category, index) => (
        // <Link
        //   key={category.id || `cat${index}`}
        //   to={`/categories/${category.name}`}
        //   href={`/categories/${category.name}`}
        //   id="btn-rent-it"
        //   className=""
        // >
        <h6
          key={category.id || `cat${index}`}
          className="tw-uppercase tw-text-skiptiOrange fw-bold category"
        >
          {category.name}
        </h6>
        // </Link>
      ))}
      <div className="tw-flex tw-flex-wrap tw-mx-0">
        <div className="tw-w-full">
          <h2 className="tw-w-full tw-px-4 product-title">
            {product.productName}
          </h2>
        </div>
      </div>

      <div className="tw-text-skiptiOrange meta-data">
        <SkiptiRating
          rating={product.productRating || 5}
          size="large"
          maxRating={5}
          readonly
        />
        {product.numberOfReviews && product.numberOfReviews > 0 ? (
          <HashLink smooth to="#reviews">
            <span className="tw-ml-2 tw-mr-12">
              {product.numberOfReviews} reviews
            </span>
          </HashLink>
        ) : null}
        {product.isFeatured && (
          <span>
            <img
              src="../images/ico-featured.svg"
              alt="featured item"
              className="ico-stats tw-mr-2 tw-inline"
            />
            Featured item
          </span>
        )}
      </div>

      <div className="tw-mb-0 item-description">
        <p
          className="tw-mb-4"
          dangerouslySetInnerHTML={{
            __html: getHtmlData(),
          }}
        />
      </div>
      {pageMode === "rent" ? (
        <p className="tw-text-mediumgrey tw-mb-4">
          <em>
            {`This item's condition is ${
              product &&
              product.condition &&
              _.find(conditionsArray, ["id", product.condition]).name
            }`}
          </em>
        </p>
      ) : null}

      <p className="tw-text-mediumgrey tw-mb-4">
        <em>{setSignatureText(product)}</em>
      </p>

      <Accordion
        classes={{
          root: classes.AccordionRoot,
        }}
      >
        <AccordionSummary
          classes={{
            expanded: classes.AccordionExpanded,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          What&apos;s Included ({product.includes.length || "0"})
        </AccordionSummary>
        <AccordionDetails>
          {product.includes !== null &&
          typeof product.includes !== "undefined" &&
          product.includes.length > 0 ? (
            <ItemIncluded included={product.includes} />
          ) : (
            <p>This item has no included things</p>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion
        classes={{
          root: classes.AccordionRoot,
        }}
      >
        <AccordionSummary
          classes={{
            expanded: classes.AccordionExpanded,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          Rules ({product.rules.length || "0"})
        </AccordionSummary>
        <AccordionDetails>
          {product.rules !== null &&
          typeof product.rules !== "undefined" &&
          product.rules.length > 0 ? (
            <ItemRules rules={product.rules} />
          ) : (
            <p>This item has no rules</p>
          )}
        </AccordionDetails>
      </Accordion>

      {/* -------Owner tips* */}
      {product.tags !== null &&
      typeof product.tags !== "undefined" &&
      product.tags.length > 0 ? (
        <Accordion
          classes={{
            root: classes.AccordionRoot,
          }}
        >
          <AccordionSummary
            classes={{
              expanded: classes.AccordionExpanded,
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Owner tips ({product.tags.length || "0"})
          </AccordionSummary>
          <AccordionDetails>
            <ItemRules rules={product.tags} />
          </AccordionDetails>
        </Accordion>
      ) : null}

      <div id="reviews" className="relative" style={{ top: "-100px" }} />
      <Accordion
        classes={{
          root: classes.AccordionRoot,
        }}
      >
        <AccordionSummary
          classes={{
            expanded: classes.AccordionExpanded,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          Reviews ({product.numberOfReviews})
        </AccordionSummary>
        <AccordionDetails>
          {
            // {#if reviews}}
            product.reviews !== null &&
            typeof product.reviews !== "undefined" &&
            product.reviews.length > 0 ? (
              <div className="skipti-reviews">
                <ItemReviews
                  reviews={product.reviews}
                  ownersAccuracy={product.ownersAccuracy}
                  ownersPackaging={product.ownersPackaging}
                  productCondition={product.productCondition}
                  productExperience={product.productExperience}
                />
              </div>
            ) : (
              <p>This item has no reviews</p>
            )
          }
        </AccordionDetails>
      </Accordion>

      {product && product.articleTeaser ? (
        <div className="tw-my-12">
          <h3>This item is included in the following article</h3>
          <div className="tw-flex tw-flex-wrap tw-mx-0 tw-my-4">
            <ExperienceTile
              noOfTiles={1}
              article={product.articleTeaser}
              key={product.articleTeaser.id}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
