import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserInfo } from "../../actions";
import LayoutMain from "../layouts/LayoutMain";
import { getUser } from "../../reducers/authReducer";

class CustomerPaymentFailed extends Component {
  componentDidMount() {
    this.props.getUserInfo(
      decodeURIComponent(this.props.match.params.jwt),
      true,
      window.analytics
    );
  }

  render() {
    return (
      <LayoutMain withContainer>
        <div className="container my-5">
          <i className="fal fa-7x fa-envelope-open float-right text-color7 ml-5 mb-5" />
          <h2>Payment failed</h2>
          <p className="lead">Your renter&apos;s payment has failed</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu quam
            egestas, commodo turpis id, egestas velit. Vestibulum ante ipsum
            primis in faucibus orci luctus et ultrices posuere cubilia Curae;
            Suspendisse viverra ut arcu sed pharetra. Mauris tempus sodales
            metus, eu accumsan lorem auctor ut. In hac habitasse platea
            dictumst.
          </p>
        </div>
      </LayoutMain>
    );
  }
}
const mapStateToProps = (state) => ({
  user: getUser(state),
});
export default connect(mapStateToProps, { getUserInfo })(CustomerPaymentFailed);
