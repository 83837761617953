import React from "react";
import CheckIcon from "@mui/icons-material/Check";

const ProgressItem = ({ name, isStared, isComplete, id, lastItem }) => {
  const _nameClasses = `tw-font-bold ${
    !isStared && !isComplete ? "tw-text-secondary" : ""
  } ${isComplete ? "tw-text-black" : ""} ${
    isStared ? "tw-text-skiptiOrange" : ""
  }`;

  const _iconClasses = `tw-w-8 tw-rounded-full tw-text-center
  ${
    isComplete
      ? "tw-bg-skiptiOrange tw-text-white tw-border-skiptiOrange"
      : isStared
      ? "tw-bg-white tw-text-skiptiOrange tw-border-skiptiOrange"
      : "tw-bg-almostwhite tw-border-pastelGrey "
  } tw-border-2`;

  return (
    <div className="tw-flex  tw-flex-col tw-items-center marginOfProgressItems">
      <div
        className={`${!lastItem ? "stepper-item" : ""} ${
          isComplete ? "stepper-item__complete" : ""
        }`}
      >
        {isComplete ? (
          <CheckIcon className={_iconClasses} />
        ) : (
          <p className={_iconClasses}>{id}</p>
        )}
      </div>
      <p className={_nameClasses}>{name}</p>
    </div>
  );
};

export default ProgressItem;
