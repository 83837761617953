import React, { Component } from "react";
import { Form, Formik } from "formik";
import { Typography, FormControl } from "@material-ui/core";
import { connect } from "react-redux";
import queryString from "querystring";
import * as yup from "yup";
import SkiptiSpinner from "../skipti/Spinner";
import SkiptiButton from "../skipti/Button";
import {
  showSnackbar,
  resetPassword,
  checkTokenValidation,
} from "../../actions";
import LayoutMain from "../layouts/LayoutMain";
import PasswordTextField from "../skipti/PasswordTextField";
import { getValidationStatus } from "../../reducers/authReducer";

class ResetPassword extends Component {
  state = {
    code: "",
    email: "",
    showPassword: {
      new: false,
      old: false,
    },
  };

  componentDidMount() {
    const qString = queryString.parse(this.props.location.search.substring(1));
    if (!qString.userId || !qString.code) {
      this.props.showSnackbar("Invalid Link!");
    } else {
      this.props.checkTokenValidation({
        email: qString.userId,
        code: qString.code,
        history: this.props.history,
      });
      this.setState({
        email: qString.userId,
        code: qString.code,
      });
    }
  }

  handleClickShowPassword = (passwordType) => () => {
    this.setState(({ showPassword }) => ({
      showPassword: {
        ...showPassword,
        [passwordType]: !showPassword[passwordType],
      },
    }));
  };

  handleMouseDownPassword = () => (event) => {
    event.preventDefault();
  };

  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await this.props.resetPassword(values);
    setSubmitting(false);
  };

  render() {
    const { email, code } = this.state;
    const { validationStatus } = this.props;

    return (
      <LayoutMain withContainer>
        {validationStatus?.isLoading ? (
          <SkiptiSpinner
            loading={validationStatus?.isLoading}
            opacity="0"
            absolute
          />
        ) : (
          <div className="col-md-6 col-12">
            <Typography variant="h2" gutterBottom>
              Reset Password
            </Typography>
            <Formik
              enableReinitialize
              onSubmit={this.handleSubmit}
              initialValues={{
                email,
                code,
                password: "",
                confirmPassword: "",
              }}
              validationSchema={yup.object().shape({
                password: yup
                  .string()
                  .required("This field is required")
                  .min(6, "Your password is too short")
                  .matches(
                    /(?=.*[0-9])/,
                    "Your password must contain at least one number"
                  )
                  .matches(
                    /(?=.[!@#\$%\^&])/,
                    "Your password must contain at least one special character"
                  )
                  .matches(
                    /(?=.*[A-Z])/,
                    "Your password must contain at least one uppercase character"
                  )
                  .matches(
                    /(?=.*[a-z])/,
                    "Your password must contain at least one lowercase character"
                  )
                  .sameAs(yup.ref("confirmPassword"), "Passwords don't match"),
                confirmPassword: yup
                  .string()
                  .required("This field is required")
                  .min(6, "Your password is too short")
                  .sameAs(yup.ref("password"), "Passwords don't match"),
              })}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleBlur,
                handleChange,
                isSubmitting,
                isValid,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <SkiptiSpinner loading={isSubmitting} opacity="0" absolute />
                  <PasswordTextField
                    name="password"
                    label="Password"
                    value={values.password}
                    error={errors.password}
                    touched={touched.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <PasswordTextField
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <FormControl>
                    <SkiptiButton
                      disabled={!isValid}
                      style={{ marginTop: "16px" }}
                      primary
                      type="submit"
                    >
                      Submit
                    </SkiptiButton>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => ({
  validationStatus: getValidationStatus(state),
});

const mapDispatchToProps = {
  resetPassword,
  showSnackbar,
  checkTokenValidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
