import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import Link from "@mui/material/Link";
import MixpanelContext from "react-mixpanel";
import {
  getDateRange,
  getDeliveryOptions,
  getZip,
  getProductInStoreLocation,
} from "../../../reducers/itemsReducer";
import UpdatePaymentInfo from "./DialogsModals/UpdatePaymentInfo";
import {
  getDeliveryModal,
  getItemRentInfo,
} from "../../../reducers/itemRentReducer";

import { getRegion } from "../../../reducers/userReducer";
import {
  getCartItems,
  removeFromCart,
  getCartFromRedux,
  removeAddOnFromCart,
  removeDamageProtectionFromCart,
  getChosenProduct,
} from "../item_upsell/CartSlice";
import ItemListTile from "../../partials/ItemListTile";
import {
  selectPaymentCardData,
  updatePaymentInfoData,
  update_NewPaymentInfoData,
} from "../../paymentInfoSlice/paymentInfoSlice";
import { getUser, getDisplayName } from "../../../reducers/authReducer";
import { getAddressesByUserId } from "../../../reducers/addressesReducer";
import AddNewCardDialog from "./DialogsModals/AddNewCardDialog";
import AddNewBillingDialog from "./DialogsModals/AddNewBillingDialog";
import { fetchAddressesByUserId, fetchUserInfoById } from "../../../actions";
import OversizedDeliveryPopup from "../../partials/OversizedDeliveryPopup";
import {
  getAppointmentItem,
  removeAppointment,
} from "../../../reducers/itemDemoReducer";
import {
  ITEM_DELIVERY_MODEL,
  addressByMethod,
  deliveryMethods,
  fulfillmentTypes,
} from "../../../actions/utils";
import DateBlock from "./DateBlock";
import DateBlockWrapper from "./DateBlockWrapper";

function RentDetailsCart(props) {
  const {
    item,
    showPricingDetails = true,
    hideDates = false,
    hideRemoveButtons = false,
    hideQuantities = false,
    isBuy = false,
    showDeposit = false,
    disableChange,
    isBuyFlow = false,
    isAppointment = false,
  } = props;
  const [updatePayment, setUpdatePayment] = useState(false);
  const [addCard, setAddCart] = useState(false);
  const [addBilling, setAddBilling] = useState(false);
  const [userCards, setUserCards] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [popoverState, setPopoverState] = useState(null);
  const itemId = item ? item.resourceId : props.itemId;
  const dispatch = useDispatch();
  const currentPaymentCard = useSelector(selectPaymentCardData);
  const context = useContext(MixpanelContext);

  const cartItems = useSelector(getCartItems);
  const itemRentInfo = useSelector(getItemRentInfo);
  const displayName = useSelector(getDisplayName);
  const cart = useSelector(getCartFromRedux);
  const dateRange = useSelector(getDateRange);
  const region = useSelector(getRegion);
  const zip = useSelector(getZip) || itemRentInfo.zip;
  const deliveryOptions = useSelector(getDeliveryOptions);
  const user = useSelector(getUser);
  const address = useSelector(getAddressesByUserId);
  const appointmentItems = useSelector(getAppointmentItem);
  const chosenProduct = useSelector(getChosenProduct);
  const _getStoreLocation = useSelector(getProductInStoreLocation);
  const deliveryModel = useSelector(getDeliveryModal);

  const delivery = deliveryOptions?.invertDeliveryFetch
    ? deliveryOptions?.fetchOptions
    : deliveryOptions?.deliveryOptions || {};
  const fetch = deliveryOptions?.invertDeliveryFetch
    ? deliveryOptions?.deliveryOptions
    : deliveryOptions?.fetchOptions || {};

  const deliveryMethod = itemRentInfo.method?.split("/")[0];
  const fetchMethod = itemRentInfo.method?.split("/")[1];

  // @fixme: selectedAvail argument added because in case of return type we are updating only endAvail
  const getSelectedDeliveryOption = useCallback(
    (selectedAvail = null) => {
      let selectedDeliveryOption;

      if (delivery && delivery[deliveryMethod]?.length > 0) {
        selectedDeliveryOption = delivery[deliveryMethod].find(
          (deliveryWindow) => {
            if (props.isAvailability && props.deliveryTime) {
              return deliveryWindow.id === props.deliveryTime;
            }

            return (
              deliveryWindow.id === (selectedAvail || itemRentInfo.startAvail)
            );
          }
        );
      }

      return selectedDeliveryOption;
    },
    [delivery, itemRentInfo, props.isAvailability, props.deliveryTime]
  );

  const getSelectedFetchOption = useCallback(() => {
    let selectedFetchOption;

    if (fetch && fetch[fetchMethod]?.length > 0) {
      selectedFetchOption = fetch[fetchMethod].find(
        (fetchWindow) => fetchWindow.id === itemRentInfo.endAvail
      );
    }

    return selectedFetchOption;
  }, [fetch, itemRentInfo]);

  const deliveryEvent = useMemo(() => {
    return getSelectedDeliveryOption();
  }, [getSelectedDeliveryOption]);

  const fetchEvent = useMemo(() => {
    let event;

    if (
      itemRentInfo.fulfillmentType?.id ===
      fulfillmentTypes.CourierInboundOnly.id
    ) {
      event = getSelectedDeliveryOption(itemRentInfo.endAvail);
    } else {
      event = getSelectedFetchOption();
    }

    return event;
  }, [getSelectedFetchOption]);

  const _customerLocation =
    cart && cart.customerLocation
      ? cart.customerLocation.address
      : user?.defaultAddress;

  // const _itemRentInfo =
  //   itemRentInfo && itemRentInfo?.locationObject
  //     ? itemRentInfo?.locationObject?.address
  //     : user?.defaultAddress;

  // const selectedAddress = isBuyFlow
  //   ? _customerLocation
  //   : [
  //       fulfillmentTypes.Instore.id,
  //       fulfillmentTypes.HybridCourierInstore.id,
  //       fulfillmentTypes.HybridInstoreCourier.id,
  //     ].includes(itemRentInfo?.fulfillmentType?.id)
  //   ? _getStoreLocation.address
  //   : _itemRentInfo;

  const getStoreAddress = () => {
    if (cart?.productRequests?.length) {
      return cart?.productRequests[0]?.depotLocation?.address;
    }
    return _getStoreLocation?.address || {};
  };

  const storeAddress = getStoreAddress();

  const cardInfo =
    user &&
    user.paymentOptions &&
    user.paymentOptions[0] &&
    user.paymentOptions[0].creditCardsOverview
      ? user.paymentOptions[0].creditCardsOverview
      : "";

  const calcDepositAmount = useMemo(() => {
    let amount = 0;

    if (cartItems) {
      cartItems.forEach((product) => (amount += product?.depositCost.amount));
    }

    return amount;
  }, [cartItems]);

  const paymentInfo = useMemo(() => {
    let info = "";
    if (user.paymentOptions) {
      info = user.paymentOptions.find((el) => el.creditCardsOverview.isDefault);
      if (!info) {
        info = user.paymentOptions[0];
      }
    }

    return info;
  }, [user.paymentOptions]);

  useEffect(() => {
    if (address.length) {
      setUserAddresses(address);
    }
  }, [address]);

  useEffect(() => {
    if (user?.paymentOptions?.length) {
      setUserCards(user?.paymentOptions);
    }
  }, [user.paymentOptions]);

  const getAddressTitleMethod = () => {
    if (isAppointment) {
      if (isBuyFlow) {
        return addressByMethod.delivery_title;
      }
      return addressByMethod.demo_title;
    }
    if (
      [
        fulfillmentTypes.HybridCourierInstore.id,
        fulfillmentTypes.HybridInstoreCourier.id,
        fulfillmentTypes.CourierOutboundOnly.id,
        fulfillmentTypes.CourierInboundOnly.id,
      ].includes(itemRentInfo?.fulfillmentType?.id)
    ) {
      return addressByMethod.customer_title;
    }
    if (itemRentInfo?.fulfillmentType?.id === fulfillmentTypes?.Courier.id) {
      return addressByMethod.customer_title;
    }
    return addressByMethod.order_title;
  };

  const handleRemoveFromCart = (itemToRemove) => {
    context.track("Rent Details - Remove Item From Cart", {
      productResourceId: itemToRemove.id,
      productName: itemToRemove.productName,
      zip: itemRentInfo.zip,
    });

    if (isAppointment) {
      dispatch(removeAppointment(itemToRemove.resourceId));
    }

    dispatch(
      removeFromCart({
        id: itemToRemove.id,
      })
    );
  };
  const handleRemoveAddOnFromCart = (addOn, productId) => {
    context.track("Rent Details - Remove AddOn From Cart", {
      productResourceId: productId.id,
      productName: productId.productName,
      addOn,
      addOnName: addOn.name,
      isAccessory: addOn.isAccessory,

      zip: itemRentInfo.zip,
    });
    dispatch(
      removeAddOnFromCart({
        id: addOn.id,
        productId,
      })
    );
  };
  const handleRemoveDamageProtectionFromCart = (itemToRemove) => {
    context.track("Rent Details - Remove Damage Protection From Cart", {
      productResourceId: itemToRemove.productId,
      productName: itemToRemove.productName,
      id: itemToRemove.id,
      zip: itemRentInfo.zip,
    });
    dispatch(
      removeDamageProtectionFromCart({
        id: itemToRemove.id,
        productId: itemToRemove.productId,
      })
    );
  };

  const handleSubmitUpdate = async () => {
    setFetching(true);
    const mappedAddress = {
      availabilityCalendar: 0,
      externalId: "",
      isActive: true,
      locationType: 0,
      organizationId: user.resourceOrganization,
    };

    const obj = {
      accountName: cardInfo.name || `${displayName}'s credit card`,
      email: user.email,
      makeDefaultPayment: true,
      makeDefaultAddress: true,
    };
    if (currentPaymentCard.isNewCard) {
      await dispatch(
        update_NewPaymentInfoData({ mappedAddress, userId: user.userId, obj })
      );
    } else {
      await dispatch(updatePaymentInfoData(mappedAddress));
    }
    const addressResponse = await dispatch(fetchAddressesByUserId(null, false));
    const userResponse = await dispatch(fetchUserInfoById(null, false));

    context.track("Rent Details - Changed Card", {});

    setUserCards(userResponse.paymentOptions);
    setUserAddresses(addressResponse.resources);
    setFetching(false);
    setUpdatePayment(false);
  };

  const handleAddNewAddress = (newAddress) => {
    const _filteredArray = userAddresses.filter(
      (el) => el.resourceId !== "empty string"
    );
    setUserAddresses([..._filteredArray, newAddress]);
  };

  const handleAddNewCard = (newCard) => {
    const _filteredArray = userCards.filter(
      (el) => el.creditCardsOverview.id !== "new card"
    );
    setUserCards([..._filteredArray, newCard]);
  };

  const openDeliveryPopup = (event) => {
    if (event.currentTarget) {
      context.track("Rent Details - Open Oversize Popup", {});
    }
    setPopoverState(event.currentTarget);
  };
  const _tileItems =
    isAppointment && !isBuyFlow
      ? appointmentItems
      : cart && cart.productRequests && cart.productRequests.length > 0
      ? cartItems
      : chosenProduct;

  const _pricing =
    isAppointment && !isBuyFlow
      ? {
          total: props.pricingInfo?.total || 0,
          salesTax: props.pricingInfo?.salesTax || 0,
          subtotal: props.pricingInfo?.subtotal || 0,
          delivery: props.pricingInfo?.delivery || 0,
          perimeter: props.pricingInfo?.perimeter,
          // promoCode: props?.pricingInfo?.promoCode || "",
          discounts: props?.pricingInfo?.promoCode || 0,
        }
      : cart && cart.productRequests && cart.productRequests.length > 0
      ? {
          total: cart?.total?.amount || 0,
          salesTax: cart?.salesTax?.amount || 0,
          subtotal: cart?.subtotal?.amount || 0,
          delivery: cart?.delivery?.amount || 0,
          perimeter: cart?.perimeter?.amount || 0,
          discounts: cart?.discounts?.amount || "",
          promoCode: cart?.promoCode || "",
          courierCost: cart?.delivery?.amount || 0,
        }
      : {
          courierCost: cart?.delivery?.amount || 0,
          total:
            chosenProduct[0]?.basePerDayUsd + (cart?.delivery?.amount || 0),
          subtotal: chosenProduct[0]?.basePerDayUsd || 0,
          delivery: cart?.delivery?.amount || 0,
        };

  // const getShipingText = () => {
  //   let shipingText;
  //   if (isAppointment && isBuyFlow) {
  //     shipingText = "Shipping:";
  //   } else if (
  //     (isAppointment && !isBuyFlow) ||
  //     deliveryModel !== ITEM_DELIVERY_MODEL.IN_STORE_REGULAR
  //   ) {
  //     shipingText = "White Glove Courier:";
  //   } else {
  //     shipingText = "Roundtrip Courier:";
  //   }
  //   return shipingText;
  // };

  return (
    <div>
      <div className="rent-details tw-w-full">
        <div className=" tw-mt-5 tw-grid tw-grid-flow-row tw-auto-rows-max">
          {!hideDates ? (
            isAppointment ? (
              <DateBlock
                dateRange={dateRange?.startDate}
                title="DEMO DATE"
                events={deliveryEvent}
                styles={{ borderBottom: "none" }}
              />
            ) : (
              <DateBlockWrapper
                dateRange={dateRange}
                deliveryEvent={deliveryEvent}
                fetchEvent={fetchEvent}
                fulfillmentType={itemRentInfo?.fulfillmentType}
              />
            )
          ) : null}

          {[
            fulfillmentTypes.Instore.id,
            fulfillmentTypes.HybridCourierInstore.id,
            fulfillmentTypes.HybridInstoreCourier.id,
          ].includes(itemRentInfo?.fulfillmentType?.id) && (
            <div
              className="box tw-p-4"
              style={{
                borderBottom: `${
                  itemRentInfo?.fulfillmentType?.id !==
                  fulfillmentTypes.Instore.id
                    ? "none"
                    : ""
                }`,
              }}
            >
              <p className="title tw-pb-1">
                <strong>{addressByMethod.store_title}</strong>
              </p>
              <p>{storeAddress?.name}</p>
              <p className="address">{storeAddress?.street1 || ""}</p>
              <p className="address">{storeAddress?.street2 || ""}</p>
              <p className="address">
                {storeAddress
                  ? `${storeAddress?.city}, ${storeAddress?.stateProvince}, ${storeAddress?.zipPostal}`
                  : `${region?.state}${region?.state && ","} ${zip}`}
              </p>
            </div>
          )}
          {itemRentInfo?.fulfillmentType?.id !==
            fulfillmentTypes.Instore.id && (
            <div className="box tw-p-4">
              <p className="title tw-pb-1">
                <strong>{getAddressTitleMethod()}</strong>
              </p>
              <p className="address">{_customerLocation?.street1 || ""}</p>
              <p className="address">{_customerLocation?.street2 || ""}</p>
              <p className="address">
                {_customerLocation
                  ? `${_customerLocation?.city}, ${_customerLocation?.stateProvince}, ${_customerLocation?.zipPostal}`
                  : `${region?.state}${region?.state && ","} ${zip}`}
              </p>
            </div>
          )}
          {_tileItems && _tileItems.length > 0 && (
            <>
              <div className="box tw-p-4" style={{ borderTop: "none" }}>
                {_tileItems.map((itemInCart, idx) => (
                  <div key={`${itemInCart.resourceId}_${idx}`}>
                    <ItemListTile
                      key={itemInCart.productId}
                      itemInCart={itemInCart}
                      showRemove={
                        !hideRemoveButtons &&
                        itemId &&
                        itemInCart.resourceId !== itemId &&
                        !appointmentItems.some(
                          (el) => el.resourceId === itemInCart.resourceId
                        )
                      }
                      hideQuantities={hideQuantities}
                      handleRemoveFromCart={handleRemoveFromCart}
                      handleRemoveDamageProtection={
                        handleRemoveDamageProtectionFromCart
                      }
                      showRetailPrice={isBuy}
                      isCompletePage={props.isCompletePage}
                    />
                    {itemInCart &&
                      itemInCart.hasAddOns &&
                      itemInCart.addOns &&
                      itemInCart.addOns.length > 0 &&
                      itemInCart.addOns.map((addOn) => (
                        <ItemListTile
                          isAddOn
                          key={addOn.id}
                          itemInCart={addOn}
                          showRemove={!hideRemoveButtons && !addOn.isAccessory}
                          hideQuantities={hideQuantities}
                          showRetailPrice={isBuy}
                          handleRemoveFromCart={() =>
                            handleRemoveAddOnFromCart(
                              addOn,
                              itemInCart.productId
                            )
                          }
                        />
                      ))}
                  </div>
                ))}

                <div className="tw-border-t tw-mt-5 tw-mb-5 tw-border-pastelGrey" />

                <div className="tw-flex tw-flex-nowrap tw-justify-between productSubtotal bold">
                  <p>Subtotal:</p>
                  <p>${_pricing.subtotal.toFixed(2)}</p>
                </div>
                {showPricingDetails ? (
                  <>
                    {_pricing.salesTax > 0 && (
                      <div className="tw-flex tw-flex-nowrap tw-justify-between productSubtotal">
                        <p>Sales Tax:</p>
                        <p>${_pricing.salesTax?.toFixed(2)}</p>
                      </div>
                    )}

                    {_pricing?.perimeter > 0 && (
                      <div className="tw-flex tw-flex-nowrap tw-justify-between productSubtotal">
                        <p>Perimeter pricing:</p>
                        <p>${cart?.perimeter?.amount?.toFixed(2)}</p>
                      </div>
                    )}
                    {_pricing?.delivery > 0 && (
                      <div className="tw-flex tw-flex-nowrap tw-justify-between productSubtotal">
                        <OversizedDeliveryPopup
                          popoverState={popoverState}
                          setPopoverState={setPopoverState}
                        />
                        <p
                          onClick={
                            cart?.hasOversizedItems ? openDeliveryPopup : null
                          }
                          className={
                            cart?.hasOversizedItems
                              ? "tw-text-skiptiOrange tw-underline tw-cursor-pointer"
                              : ""
                          }
                        >
                          {itemRentInfo?.fulfillmentType?.shippingLabel}
                        </p>
                        <p>${_pricing?.delivery.toFixed(2)}</p>
                      </div>
                    )}
                    {_pricing?.discounts > 0 && (
                      <div className="tw-text-skiptiOrange tw-flex tw-flex-nowrap tw-justify-between ">
                        <p>Promo ({cart?.promoCode}):</p>
                        <p>-${_pricing?.discounts?.toFixed(2)}</p>
                      </div>
                    )}
                    {cart?.fees?.amount > 0 && (
                      <div className="tw-flex tw-flex-nowrap tw-justify-between ">
                        <p>Fees:</p>
                        <p>${cart?.fees?.amount?.toFixed(2)}</p>
                      </div>
                    )}

                    <div className="tw-flex tw-flex-nowrap tw-justify-between productSubtotal bold">
                      <p>Total:</p>
                      <p>${_pricing?.total.toFixed(2)}</p>
                    </div>
                  </>
                ) : (
                  <span className="Taxes-fees-not-yet">
                    Taxes & fees not yet calculated
                  </span>
                )}
              </div>
            </>
          )}
          {calcDepositAmount !== 0 && showDeposit && (
            <div className="tw-mt-4 box tw-p-4">
              A deposit hold of ${calcDepositAmount.toFixed(2)} will be
              temporarily placed on your account for the duration of the rental.
            </div>
          )}
          {cardInfo && paymentInfo ? (
            <div className="tw-mt-4 box tw-p-4 tw-leading-normal">
              {
                <>
                  <p className="title tw-pb-1">
                    <strong>CREDIT CARD</strong>
                  </p>
                  <p className="creditCard">{`${cardInfo.brand} ****${
                    cardInfo.lastFour
                  } (Exp. ${moment(cardInfo.expires).format("MM/YY")})`}</p>
                  {disableChange ? null : (
                    <Link
                      sx={{
                        color: "#f05623",
                        marginBottom: "20px",
                        textDecoration: "underline",
                      }}
                      className="tw-mb-5 tw-underline"
                      onClick={() => {
                        context.track(
                          "Rent Details - Click Change Credit Card",
                          {}
                        );
                        setUpdatePayment(true);
                      }}
                      component="button"
                    >
                      Change
                    </Link>
                  )}
                </>
              }

              {paymentInfo?.location && (
                <>
                  <p className="title tw-pb-1">
                    <strong>BILLING ADDRESS</strong>
                  </p>
                  <p className="address">
                    {paymentInfo?.location.address?.street1 || ""}
                  </p>
                  <p className="address">
                    {paymentInfo?.location.address?.street2 || ""}
                  </p>
                  <p className="address">
                    {paymentInfo
                      ? `${paymentInfo?.location?.address.city}, ${paymentInfo.location.address.stateProvince}, ${paymentInfo?.location.address.zipPostal}`
                      : `${region?.state}${region?.state && ","} ${zip}`}
                  </p>
                </>
              )}
              <UpdatePaymentInfo
                addresses={userAddresses}
                handleSubmitUpdate={handleSubmitUpdate}
                open={updatePayment}
                setUpdatePayment={setUpdatePayment}
                setAddCart={setAddCart}
                setAddBilling={setAddBilling}
                userCards={userCards}
                fetching={fetching}
              />
              <AddNewCardDialog
                open={addCard}
                setClose={setAddCart}
                handleAddNewCard={handleAddNewCard}
                isPaymentOptionsFlow={false}
              />
              <AddNewBillingDialog
                open={addBilling}
                setClose={setAddBilling}
                updateState={handleAddNewAddress}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
  // }
}

export default RentDetailsCart;
