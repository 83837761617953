import axios from "axios";
import mixpanelBrowser from "mixpanel-browser";
import uuid from "uuid/v4";
import * as TYPES from "../types";
import * as helpers from "../utils";
import {
  getToken,
  getReturnUrl,
  getVoteForProductAfterAuth,
  getSuggestProductAfterAuth,
  getLocationByIP,
} from "../reducers/authReducer";
import history from "../history";
import { showSnackbar } from "./snackbar";
import { addToken, removeToken } from "./utils";
import { setZipCode } from "./items";
import {
  getCartKey,
  resetCart,
  setCart,
  getCartItems,
  setWasCartReset,
} from "../components/items/item_upsell/CartSlice";
import { getZip } from "../reducers/itemsReducer";
import { getItemRentInfo } from "../reducers/itemRentReducer";
import { PartnerAdmin, SkiptiAssociateAdmin } from "../accessRoleChecks";

axios.defaults.withCredentials = true;

export const openSocialShareModal = (data) => (dispatch) => {
  dispatch({
    type: TYPES.SOCIAL_SHARE_MODAL_OPEN,
    payload: data,
  });
};

export const closeSocialShareModal = () => (dispatch) => {
  dispatch({
    type: TYPES.SOCIAL_SHARE_MODAL_CLOSE,
  });
};

export const openZipModal = (data) => (dispatch) => {
  dispatch({
    type: TYPES.ZIP_MODAL_OPEN,
    payload: data,
  });
};

export const closeZipModal = () => (dispatch) => {
  dispatch({
    type: TYPES.ZIP_MODAL_CLOSE,
  });
};

export const setSignInMessage = (message) => (dispatch) => {
  dispatch({
    type: TYPES.SET_SIGN_IN_MESSAGE,
    payload: message,
  });
};

export const convertCartForNewRegistration =
  (token) => async (dispatch, getState) => {
    const jwt = token || getToken(getState());
    const cartkey = getCartKey(getState());
    const cartItems = getCartItems(getState());
    const ip = getLocationByIP(getState()).data?.ip;
    const zip = getItemRentInfo(getState()).zip || getZip(getState());
    try {
      const res = await axios.put(
        `${helpers.serverUrl}/api/v1/cart/convert/?${
          cartkey && typeof cartkey !== "undefined"
            ? `cartkey=${cartkey}`
            : ip
            ? `ip=${ip}`
            : ""
        }${zip ? `&zipPostal=${zip}` : ""}
         `,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(setCart(res.data));
      if (
        cartItems?.length > 0 &&
        (!res.data.productRequests ||
          (res.data.productRequests && res.data.productRequests?.length < 1))
      ) {
        dispatch(
          showSnackbar(
            "The Cart has been reset. The checkout will be restarted.",
            "warning"
          )
        );
        dispatch(setWasCartReset(true));
      }
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  };

const requestAuthenticate = () => ({
  type: TYPES.LOGIN_REQUEST,
});

const failAuthenticate = (error) => ({
  type: TYPES.LOGIN_FAIL,
  error,
});

const successAuthenticate = (payload) => ({
  type: TYPES.LOGIN_SUCCESS,
  payload,
});

// eslint-disable-next-line
export const authenticate =
  (user, mixpanel, analytics, type) => async (dispatch, getState) => {
    // user contains email, password, rememberMe
    dispatch(requestAuthenticate());
    let deviceIdentifier = localStorage.getItem("skiptiDeviceId");
    if (!deviceIdentifier) {
      deviceIdentifier = uuid();
      localStorage.setItem("skiptiDeviceId", deviceIdentifier);
    }
    let req = {};
    try {
      if (type === "facebook") {
        req = await axios.post(
          `${helpers.serverUrl}/api/v1/user/auth/facebook`,
          {
            ...user,
            deviceIdentifier,
          }
        );
      } else if (type === "google") {
        req = await axios.post(`${helpers.serverUrl}/api/v1/user/auth/google`, {
          accessToken: user.tokenId,
          code: undefined,
          deviceIdentifier,
        });
      } else {
        req = await axios.post(`${helpers.serverUrl}/api/v1/user/login`, {
          ...user,
          deviceIdentifier,
        });
      }
      addToken({
        token: req.data.token,
        email: user.email,
        icon: req.data.icon || undefined,
        displayName: req.data.firstName,
        userId: req.data.userId,
        orgId: req.data.resourceOrganization,
        refreshToken: req.data.refreshToken,
      });

      dispatch(setSignInMessage(null));

      // MixPanel
      mixpanel.track("Log In", { userEmail: req.data.email, type });
      mixpanel.identify(req.data.userId);
      mixpanel.people.set({
        $first_name: req.data.firstName,
        $last_name: req.data.lastName,
        $email: req.data.email,
        $phone: req.data.defaultPhone,
        $credits: req.data.skiptiCredit && req.data.skiptiCredit.amount,
      });

      analytics.identify(req.data.userId, {
        name: `${req.data.firstName} ${req.data.lastName}`,
        email: req.data.email,
      });
      analytics.track("Log In", { userEmail: req.data.email, type });

      dispatch(convertCartForNewRegistration(req.data.token));

      dispatch(successAuthenticate(req.data));
      const state = getState();
      const returnUrl = getReturnUrl(state);
      const voteForProductAfterAuth = getVoteForProductAfterAuth(state);

      if (voteForProductAfterAuth) {
        const voteResult = await helpers.voteProduct(
          req.data.token,
          voteForProductAfterAuth,
          mixpanel,
          analytics
        );
        if (voteResult.status === 200) {
          dispatch(showSnackbar("Item voted successfully!", "success"));
        } else {
          dispatch(showSnackbar(voteResult.message, "error"));
        }
      }
      const suggestProductAfterAuth = getSuggestProductAfterAuth(state);
      if (suggestProductAfterAuth) {
        const suggestResult = await helpers.suggestProduct(
          req.data.token,
          suggestProductAfterAuth,
          mixpanel,
          analytics
        );

        if (suggestResult.status === 200) {
          dispatch(showSnackbar("Suggestion sent successfully!", "success"));
        } else {
          dispatch(showSnackbar(suggestResult.message, "error"));
        }
      }

      if (req.data.roles.some(PartnerAdmin)) {
        history.push("/dashboard/admin/admindepots");
        return true;
      }

      if (req.data.roles.some(SkiptiAssociateAdmin)) {
        history.push("/dashboard/admin/adminorders");
        return true;
      }

      if (req.data.pendingCustomerDeliveryReviews.length >= 1) {
        await history.push("/dashboard/reviews/driver");
        return true;
      }

      if (returnUrl) {
        history.push(returnUrl.pathname);
        return true;
      }

      await history.push("/");
      return true;
    } catch (error) {
      if (error.response.status === 401) {
        // we shouldn't refresh try to refresh the token if the email or password is incorrect
        dispatch(failAuthenticate({ message: "Incorrect email or password." }));
        return false;
      }
      if (error.response.status === 500) {
        // we shouldn't refresh try to refresh the token if the email or password is incorrect
        dispatch(
          failAuthenticate({
            message:
              "You can't login at this time. Please contact our support team.",
          })
        );
        return false;
      }
      if (error.response.status === 403) {
        dispatch(
          failAuthenticate({
            message:
              "This account has been blacklisted for activity on Skipti.com. Please contact our support team.",
          })
        );
        return false;
      }
      console.error(`error analytics track: ${error}`);
      dispatch(failAuthenticate(error));
      return false;
    }
  };

export const requestRegister = () => ({
  type: TYPES.USER_REGISTER_REQUEST,
});
export const registerSuccess = (payload) => ({
  type: TYPES.USER_REGISTER_SUCCESS,
  payload,
});
export const registerFailure = (error) => ({
  type: TYPES.USER_REGISTER_FAIL,
  error,
});

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_ERRORS",
  });
};

export const register =
  (user, kickoffId, mixpanel, analytics, isInRentWiz, isGuest = true) =>
  async (dispatch, getState) => {
    dispatch(requestRegister());
    let kickoff = kickoffId;
    let _sendingData = {};
    if (isGuest) {
      _sendingData.email = user.email;
      _sendingData.password = "";
      _sendingData.confirmpassword = "";
      _sendingData.promoCode = user.promoCode;
    } else {
      _sendingData = { ...user };
    }

    if (typeof kickoff === "undefined") {
      kickoff = "";
    }

    let promo = user.promoCode;
    if (typeof promo === "undefined") {
      promo = null;
    }
    let deviceIdentifier = localStorage.getItem("skiptiDeviceId");
    if (!deviceIdentifier) {
      deviceIdentifier = uuid();
      localStorage.setItem("skiptiDeviceId", deviceIdentifier);
    }
    try {
      const res = await axios.post(
        `${helpers.serverUrl}/api/v1/user/register`,
        { ..._sendingData, deviceIdentifier },
        {
          params: {
            kid: kickoff,
            promoCode: promo ?? "",
            isGuest,
          },
        }
      );
      addToken({
        token: res.data.token,
        email: res.data.email,
        icon: res.data.icon || undefined,
        displayName: res.data.firstName,
        userId: res.data.userId,
        orgId: res.data.resourceOrganization,
        refreshToken: res.data.refreshToken,
      });

      dispatch(setSignInMessage(null));

      // MixPanel
      mixpanel.track("Register", { userEmail: res.data.email });
      mixpanel.identify(res.data.userId);
      mixpanel.people.set({
        $first_name: res.data.firstName,
        $last_name: res.data.lastName,
        $created: res.data.created,
        $email: res.data.email,
        "Registration Method": "Email",
      });

      analytics.identify(res.data.userId, {
        name: `${res.data.firstName} ${res.data.lastName}`,
        email: res.data.email,
      });
      analytics.track("Register", { userEmail: res.data.email });
      // Doesn't show social share modal if register happened in rent an item wizard
      if (!isInRentWiz) dispatch(openSocialShareModal({ mode: "register" }));
      dispatch(registerSuccess(res.data));

      dispatch(convertCartForNewRegistration(res.data.token));

      const state = getState();
      const returnUrl = getReturnUrl(state);
      const voteForProductAfterAuth = getVoteForProductAfterAuth(state);

      if (voteForProductAfterAuth) {
        const voteResult = helpers.voteProduct(
          res.data.token,
          voteForProductAfterAuth,
          mixpanel,
          analytics
        );
        if (voteResult.status === 200) {
          dispatch(showSnackbar("Item voted successfully!", "success"));
        } else {
          dispatch(showSnackbar(voteResult.message, "error"));
        }
      }

      const suggestProductAfterAuth = getSuggestProductAfterAuth(state);
      if (suggestProductAfterAuth) {
        const suggestResult = await helpers.suggestProduct(
          res.data.token,
          suggestProductAfterAuth,
          mixpanel,
          analytics
        );
        if (suggestResult.status === 200) {
          dispatch(showSnackbar("Suggestion sent successfully!", "success"));
        } else {
          dispatch(showSnackbar(suggestResult.message, "error"));
        }
      }

      if (returnUrl && !isInRentWiz) {
        await history.push(returnUrl.pathname);
      }
    } catch (e) {
      if (e.response.status === 403) {
        dispatch(
          registerFailure({
            response: {
              data: "This account has been blacklisted for activity on Skipti.com. Please contact our support team.",
            },
          })
        );
        return false;
      }

      dispatch(showSnackbar(e.response.data[0]));
      dispatch(registerFailure(e));
    }
  };

export const requestAdminRegisterUser = () => ({
  type: TYPES.USER_ADMIN_REGISTER_USER_REQUEST,
});
export const adminRegisterUserSuccess = (payload) => ({
  type: TYPES.USER_ADMIN_REGISTER_USER_SUCCESS,
  payload,
});
export const adminRegisterUserFailure = (error) => ({
  type: TYPES.USER_ADMIN_REGISTER_USER_FAIL,
  error,
});

export const AdminRegisterUser =
  (user, returnUrl, isCopyAdminAccount) => async (dispatch, getState) => {
    dispatch(requestAdminRegisterUser());
    const jwt = getToken(getState());

    try {
      const res = await axios.post(
        `${helpers.serverUrl}/api/v1/admin/register?cloneAdminAccount=${isCopyAdminAccount}`,
        { ...user },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(adminRegisterUserSuccess(res.data));

      if (res.status === 200) {
        dispatch(showSnackbar("New user created successfully!", "success"));
      } else {
        dispatch(showSnackbar(res.message, "error"));
      }

      if (returnUrl) {
        await history.push(returnUrl);
      }
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e),
          JSON.stringify({ ...user })
        )
      );
      dispatch(adminRegisterUserFailure(e));
    }
  };

export const openLogInModal =
  (isRegister, returnUrl, showGuest = false) =>
  (dispatch) => {
    dispatch({
      type: TYPES.LOGIN_MODAL_OPEN,
      payload: { isRegister, returnUrl, showGuest },
    });
  };
const requestRefreshToken = () => ({
  type: TYPES.REFRESH_TOKEN_REQUEST,
});

const successRefreshToken = (payload) => ({
  type: TYPES.REFRESH_TOKEN_SUCCESS,
  payload,
});

const failRefreshToken = (error) => ({
  type: TYPES.REFRESH_TOKEN_FAIL,
  error,
});

export const refreshToken = (user) => async (dispatch) => {
  // user contains email, password, rememberMe
  dispatch(requestRefreshToken());
  let deviceId = localStorage.getItem("skiptiDeviceId");
  const token = localStorage.getItem("skiptiRefreshToken");
  if (!deviceId) {
    deviceId = uuid();
    localStorage.setItem("skiptiDeviceId", deviceId);
  }
  try {
    const req = await axios.post(
      `${helpers.serverUrl}/api/v1/user/refreshtoken`,
      {
        username: user.email,
        deviceIdentifier: deviceId,
        token,
      }
    );
    addToken({
      token: req.data.token,
      email: user.email,
      icon: req.data.icon || undefined,
      displayName: req.data.firstName,
      userId: req.data.userId,
      orgId: req.data.resourceOrganization,
      refreshToken: req.data.refreshToken,
    });
    dispatch(successRefreshToken(req.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failRefreshToken(e.message));
    removeToken();
    dispatch(openLogInModal(false, ""));
  }
};
export const getUserInfo =
  (jwtToken, noAuthRedirect, analytics) => async (dispatch, getState) => {
    const jwt = jwtToken || getToken(getState());
    let deviceId = localStorage.getItem("skiptiDeviceId");
    if (!deviceId) {
      deviceId = uuid();
      localStorage.setItem("skiptiDeviceId", deviceId);
    }
    if (jwt) {
      dispatch(requestAuthenticate());
      try {
        const userId = localStorage.getItem("skiptiUserId");
        const req = await axios.get(
          `${helpers.serverUrl}/api/v1/user/userprofile?deviceIdentifier=${deviceId}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        // MixPanel
        mixpanelBrowser.track("GetUserInfo", { userEmail: req.data.email });
        mixpanelBrowser.identify(req.data.userId);
        mixpanelBrowser.people.set({
          $first_name: req.data.firstName,
          $last_name: req.data.lastName,
          $email: req.data.email,
          $credits: req.data.skiptiCredit && req.data.skiptiCredit.amount,
        });
        // analytics.track("GetUserInfo", { userEmail: res.data.email });
        analytics.identify(req.data.userId, {
          name: `${req.data.firstName} ${req.data.lastName}`,
          email: req.data.email,
        });

        if (req.data === "") {
          dispatch(failAuthenticate("Invalid JWT"));
          await history.push("/");
          return;
        }

        const returnUrl = getReturnUrl(getState());
        await dispatch(successAuthenticate(req.data));
        addToken({
          token: req.data.token,
          email: req.data.email,
          icon: req.data.icon || undefined,
          displayName: req.data.firstName,
          userId: req.data.userId,
          orgId: req.data.resourceOrganization,
          refreshToken: req.data.refreshToken,
        });
        if (returnUrl && !noAuthRedirect) {
          await history.push(returnUrl.pathname);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const userEmail = localStorage.getItem("skiptiUserEmail");
          dispatch(refreshToken({ email: userEmail }));
          dispatch(
            failAuthenticate({
              message: "Session has expired. Please login again.",
            })
          );
        } else {
          dispatch(failAuthenticate(error));
          removeToken();
          await history.push("/");
        }
      }
    }
  };

const signOutSuccess = () => ({
  type: TYPES.SIGN_OUT_SUCCESS,
});

export const signOut = () => async (dispatch) => {
  removeToken();
  mixpanelBrowser.reset();
  dispatch(signOutSuccess());
  dispatch(resetCart());
  await history.push("/");
};

export const setReturnUrl = (returnUrl) => (dispatch) => {
  dispatch({
    type: TYPES.SET_RETURN_URL,
    payload: returnUrl,
  });
};

export const setVoteForProductAfterAuth = (product) => (dispatch) => {
  dispatch({
    type: TYPES.SET_VOTE_FOR_PRODUCT_AFTER_AUTH,
    payload: product,
  });
};

export const setSuggestProductAfterAuth = (suggestion) => (dispatch) => {
  dispatch({
    type: TYPES.SET_SUGGEST_PRODUCT_AFTER_AUTH,
    payload: suggestion,
  });
};

export const closeLogInModal = () => (dispatch) => {
  dispatch({
    type: TYPES.LOGIN_MODAL_CLOSE,
  });
};
const fetchUserInitializationFlagsRequest = () => ({
  type: TYPES.USER_INITFLAGS_REQUEST,
});

const fetchUserInitializationFlagsSuccess = (payload) => ({
  type: TYPES.USER_INITFLAGS_SUCCESS,
  payload,
});
const fetchUserInitializationFlagsFailure = (error) => ({
  type: TYPES.USER_INITFLAGS_FAIL,
  error,
});
export const fetchUserInitializationFlags =
  () => async (dispatch, getState) => {
    dispatch(fetchUserInitializationFlagsRequest());
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/user/initflags`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(fetchUserInitializationFlagsSuccess(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(fetchUserInitializationFlagsFailure(e));
    }
  };

export const setDefaultLocation = (defaultLocationId) => (dispatch) => {
  dispatch({
    type: TYPES.DEFAULT_LOCATION_SET,
    payload: defaultLocationId,
  });
};
export const setStripeFallbackLocation = (defaultLocation) => (dispatch) => {
  dispatch({
    type: TYPES.STRIPE_FALLBACK_LOCATION_SET,
    payload: defaultLocation,
  });
};

const fetchLocationByIPAddressRequest = () => ({
  type: TYPES.LOCATION_BYIP_REQUEST,
});

const fetchLocationByIPAddressSuccess = (payload) => ({
  type: TYPES.LOCATION_BYIP_SUCCESS,
  payload,
});
const fetchLocationByIPAddressFailure = (error) => ({
  type: TYPES.LOCATION_BYIP_FAIL,
  error,
});

const startCodeValidation = () => ({
  type: TYPES.SET_CODE_VALIDATION_START,
});

const setCodeValidationSuccess = () => ({
  type: TYPES.SET_CODE_VALIDATION_SUCCESS,
});

const setValidationEnd = () => ({
  type: TYPES.SET_VALIDATION_END,
});
export const fetchLocationByIPAddress = () => async (dispatch) => {
  dispatch(fetchLocationByIPAddressRequest());
  try {
    const res = await axios.get("https://ip.seeip.org/geoip", {
      withCredentials: false,
    });
    if (!localStorage.getItem("zipCode")) {
      setZipCode(res.data.postal_code);
    }
    dispatch(fetchLocationByIPAddressSuccess(res.data));
  } catch (e) {
    try {
      // const res = await axios.get("https://geolocation-db.com/json/", {
      // const res = await axios.get("https://ip.seeip.org/geoip", {
      const res = await axios.get(
        "http://api.ipapi.com/api/check?access_key=dad1a5d331f30d2acc916eace31885c7",
        {
          withCredentials: false,
        }
      );

      const mappedData = {
        ip: res.data.YourFuckingIPAddress,
        country: res.data.YourFuckingCountry,
        country_code: res.data.YourFuckingCountryCode,
        city: res.data.YourFuckingCity,
        organization: res.data.YourFuckingISP,
      };

      if (!localStorage.getItem("zipCode")) {
        setZipCode(res.data.postal_code);
      }
      dispatch(fetchLocationByIPAddressSuccess(res.data));
    } catch (e) {
      try {
        // const res = await axios.get("https://geolocation-db.com/json/", {
        // const res = await axios.get("https://ip.seeip.org/geoip", {
        const res = await axios.get("https://www.wtfismyip.com/json", {
          withCredentials: false,
        });

        const mappedData = {
          ip: res.data.ip,
          continent_code: res.data.continent_code,
          country: res.data.country_name,
          country_code: res.data.country_code,
          region: res.data.region_name,
          region_code: res.data.region_code,
          city: res.data.YourFuckingCity,
          postal_code: res.data.zip,
          latitude: res.data.latitude,
          longitude: res.data.longitude,
        };

        if (!localStorage.getItem("zipCode")) {
          setZipCode(mappedData.postal_code);
        }
        dispatch(fetchLocationByIPAddressSuccess(mappedData));
      } catch (e) {
        dispatch(
          showSnackbar(
            (e.response && e.response.data) || e.message,
            "error",
            null,
            null,
            JSON.stringify(e)
          )
        );

        dispatch(fetchLocationByIPAddressFailure(e));
      }
    }
  }
};

/**
 * Request to change password and return result response
 */
export const changePassword = (values) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    const response = await axios.post(
      `${helpers.serverUrl}/api/v1/user/changepassword`,
      {
        email: values.email,
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmPassword,
        deviceIdentifier: localStorage.getItem("skiptiDeviceId"),
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch(showSnackbar("Your password has been changed", "success"));

    return response;
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );

    return {
      error: (e.response && e.response.data) || e.message,
    };
  }
};

export const forgotPassword = (values) => async (dispatch) => {
  try {
    axios
      .post(`${helpers.serverUrl}/api/v1/user/forgotpassword`, {
        email: values.email,
      })
      .then(() => {
        dispatch(showSnackbar("An email has been sent", "success"));
      })
      .catch(() => {
        dispatch(showSnackbar("An email has been sent", "success"));
      });
  } catch (e) {
    dispatch(showSnackbar(e.message));
  }
};

export const resetPassword = (values) => async (dispatch) => {
  try {
    await axios.post(`${helpers.serverUrl}/api/v1/user/resetpassword`, {
      email: values.email,
      code: values.code,
      password: values.password,
      confirmPassword: values.confirmPassword,
    });

    dispatch(showSnackbar("Your password has been changed", "success"));
    history.push("/");
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
  }
};

export const checkTokenValidation = (values) => async (dispatch) => {
  dispatch(startCodeValidation());
  try {
    await axios.post(`${helpers.serverUrl}/api/v1/user/TokenValidation`, {
      email: values.email,
      code: values.code,
    });
    dispatch(setCodeValidationSuccess());
  } catch (e) {
    history.push("/");
    dispatch(showSnackbar(e.response.data ?? e.message));
  }
  dispatch(setValidationEnd());
};
