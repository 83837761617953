import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCategories } from "../../actions";
import { getCategories } from "../../reducers/categoriesReducer";
import { getUser, getIsLoggedIn, getToken } from "../../reducers/authReducer";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";

class LayoutPublic extends Component {
  // static displayName = 'LayoutPublic';
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
      PropTypes.shape({}).isRequired,
    ]).isRequired,
  };

  state = {
    // within_range: false
  };

  componentDidMount() {
    this.props.fetchCategories();
  }

  render() {
    const { isLoggedIn, children } = this.props;
    return (
      <>
        <Navbar showSearch={this.props.showSearch} />

        <div className="content">{children}</div>

        {/* <Sustainability /> */}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productCategories: getCategories(state),
  current_user: getUser(state),
  isLoggedIn: getIsLoggedIn(state),
  jwt: getToken(state),
});

export default connect(mapStateToProps, {
  fetchCategories,
})(LayoutPublic);
