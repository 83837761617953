import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setReturnUrl, openLogInModal, setSignInMessage } from "../../actions";
import {
  getIsLoggedIn,
  getToken,
  getUserRoles,
} from "../../reducers/authReducer";
import Spinner from "../skipti/Spinner";

const AuthRoute = ({
  component: Component,
  adminOnly,
  isSignedIn,
  isAdmin,
  roles,
  accessRoleCheck,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (
        (isSignedIn === undefined && roles === undefined) ||
        (isSignedIn === true && roles === undefined)
      ) {
        return <Spinner loading opacity="0" absolute />;
      }
      if (isSignedIn) {
        if (accessRoleCheck !== undefined) {
          if (roles.some(accessRoleCheck)) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: "/dashboard/profile",
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }
      rest.setReturnUrl(props.location);

      if (props.location.pathname.toString().includes("/items/rent/")) {
        rest.setSignInMessage(
          "Please create an account or use your login in order to complete your rental request."
        );
      }
      rest.openLogInModal(false, props.location);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
const mapStateToProps = (state) => ({
  isSignedIn: getIsLoggedIn(state) || !!getToken(state),
  roles: getUserRoles(state),
});

export default connect(mapStateToProps, {
  setReturnUrl,
  openLogInModal,
  setSignInMessage,
})(AuthRoute);

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  adminOnly: PropTypes.bool,
  isSignedIn: PropTypes.bool.isRequired,
};
