/* eslint-disable react/display-name */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchUserInitializationFlags, showSnackbar } from "../../actions";
import LayoutMain from "../layouts/LayoutMain";
import { getUser } from "../../reducers/authReducer";
import {
  getSkippedFlags,
  getCompleteProfileReturnUrl,
} from "../../reducers/userReducer";

import OwnerAvailabilityStepv2 from "./OwnerAvailabilityStepv2";

import AddressStep from "./AddressStep";
import StripeStep from "./StripeStep";

const flags = {
  hasSubmittedDefaultAddress: [
    {
      title: "Add an address",
      component: (props) => <AddressStep {...props} title="Add an address" />,
    },
  ],
  hasPaymentAccount: {
    title: "Billing details",
    component: (props) => <StripeStep {...props} title="Billing Details" />,
  },
  hasInitializedAvailabilityCalendar: {
    title: "",
    component: (props) => (
      <OwnerAvailabilityStepv2
        {...props}
        title="When are you generally available to answer the door for delivery?"
        subTitle="You can always edit your availability later"
      />
    ),
  },
};

class CompleteYourProfile extends Component {
  static propTypes = {
    fetchUserInitializationFlags: PropTypes.func.isRequired,
  };

  state = {
    currentStep: 1,
    steps: [],
    info: {},
  };

  async componentDidMount() {
    await this.props.fetchUserInitializationFlags();
    const { flagsOverride } = this.props;
    const { profileInitFlags } = this.props.user;
    let steps = [];
    let stepNo = 0;
    if (profileInitFlags) {
      _.forEach(Object.keys(flags), (key) => {
        if (
          !profileInitFlags[key] &&
          flags[key] &&
          (flagsOverride ? !flagsOverride[key] : true)
        ) {
          if (Array.isArray(flags[key])) {
            steps = [
              ...steps,
              ...flags[key].map((stp) => {
                const returnValue = { ...stp, step: stepNo };
                stepNo += 1;
                return returnValue;
              }),
            ];
          } else {
            steps = [...steps, { ...flags[key], step: stepNo }];
            stepNo += 1;
          }
        }
      });
      // eslint-disable-next-line
      this.setState({ steps });
    } else {
      this.props.showSnackbar("Something went wrong");
    }
  }

  stepInfo = (currentStep, info) => (
    <>
      <span className="tw-text-skiptiOrange" style={{ marginRight: "12px" }}>
        Step {currentStep}
      </span>

      <span className="tw-text-verylightgrey">{info}</span>
    </>
  );

  prevStep = () =>
    this.setState(({ currentStep }) => ({ currentStep: currentStep - 1 }));

  nextStep = (furtherInfo) => {
    const { steps } = this.state;
    if (this.state.currentStep === steps.length) {
      const { returnUrl, history } = this.props;

      history.push(returnUrl || "/");
    } else {
      this.setState(({ currentStep, info }) => ({
        currentStep: currentStep + 1,
        info: { ...info, ...furtherInfo },
      }));
    }
  };

  renderStep = () => {
    const { currentStep, steps, info } = this.state;
    return (
      steps[currentStep - 1] &&
      steps[currentStep - 1].component({
        step: currentStep,
        nextStep: this.nextStep,
        prevStep: this.prevStep,
        noSteps: steps.length,
        address: info.address,
        info,
      })
    );
  };

  render() {
    const { currentStep, steps } = this.state;
    return (
      <LayoutMain
        withContainer
        noFooter
        renderStep={() =>
          this.stepInfo(
            currentStep,
            steps[currentStep - 1] && steps[currentStep - 1].title
          )
        }
        showStep
        showProgress
        currentProgress={
          steps.length === 0 ? 0 : (currentStep / steps.length) * 100
        }
      >
        <div>{this.renderStep()}</div>
      </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  flagsOverride: getSkippedFlags(state),
  returnUrl: getCompleteProfileReturnUrl(state),
});

export default connect(mapStateToProps, {
  fetchUserInitializationFlags,
  showSnackbar,
})(CompleteYourProfile);
