import React, { useEffect, useState, useRef } from "react";
import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SkiptiDialog from "../../../skipti/SkiptiDialog";
import SkiptiButton from "../../../skipti/Button";

import {
  selectBillingData,
  selectFetchingPaymentOptions,
  selectPaymentCardData,
  setBillingData,
  setPaymentData,
} from "../../../paymentInfoSlice/paymentInfoSlice";
import Spinner from "../../../skipti/Spinner";

const UpdatePaymentInfo = ({
  open,
  setUpdatePayment,
  setAddCart,
  setAddBilling,
  paymentOptObtain,
  handleSubmitUpdate,
  addresses,
  userCards,
  fetching,
}) => {
  const firstRender = useRef(false);
  const [address, setAddress] = useState("");
  const [cardId, setCardId] = useState("");
  const dispatch = useDispatch();
  const currentCard = useSelector(selectPaymentCardData);
  const currentBilling = useSelector(selectBillingData);
  const isFetching = useSelector(selectFetchingPaymentOptions);

  useEffect(() => {
    if (!paymentOptObtain && !firstRender.current) {
      const defaultCard = userCards?.find(
        (card) => card?.creditCardsOverview.isDefault === true
      );
      if (defaultCard) {
        dispatch(setBillingData(defaultCard.location));
        dispatch(setPaymentData(defaultCard.creditCardsOverview));
        firstRender.current = true;
      }
    }
  }, [userCards, dispatch, paymentOptObtain]);

  useEffect(() => {
    if (currentBilling && currentBilling.resourceId !== address) {
      setAddress(currentBilling.resourceId);
    }
  }, [currentBilling]);

  useEffect(() => {
    if (currentCard && currentCard.id !== cardId) {
      setCardId(currentCard.id);
    }
  }, [currentCard]);

  const changeAddressHandler = (event) => {
    setAddress(event.target.value);

    const selectedObj = addresses.find(
      (el) => el.resourceId === event.target.value
    );

    if (selectedObj) {
      dispatch(setBillingData(selectedObj));
    }
  };

  const changeCardHandler = (event) => {
    setCardId(event.target.value);

    const selectedObj = userCards?.find(
      (el) => el.creditCardsOverview.id === event.target.value
    );

    if (selectedObj) {
      setAddress(selectedObj?.location?.resourceId || "");

      dispatch(setPaymentData(selectedObj?.creditCardsOverview));
      dispatch(setBillingData(selectedObj?.location));
    }
  };

  return (
    <SkiptiDialog
      maxWidth="sm"
      fullWidth
      open={open}
      toggle={() => {
        setUpdatePayment(false);
      }}
      withCloseIcon
      title="Update Payment Information"
      className="paymentStyleCenter"
    >
      {isFetching || fetching ? (
        <div className="tw-relative tw-p-10">
          <Spinner loading={isFetching || fetching} opacity="0" absolute />
        </div>
      ) : (
        <div className="tw-relative tw-pt-4">
          <div>
            <h4 className="tw-font-bold">Card Information</h4>
            {paymentOptObtain ? (
              <div className="tw-pointer-events-none">
                <TextField
                  fullWidth
                  value={`${currentCard?.brand} ***** ${
                    currentCard?.lastFour
                  }  (Exp.${moment(currentCard?.expires).format("MM/YY")})`}
                />
              </div>
            ) : (
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="paymentCard">Your cards</InputLabel>
                <Select
                  id="paymentCard"
                  label="Your cards"
                  fullWidth
                  onChange={changeCardHandler}
                  value={cardId}
                >
                  {userCards?.map((card) => {
                    return (
                      <MenuItem
                        key={card?.creditCardsOverview.id}
                        value={card?.creditCardsOverview.id}
                      >
                        {card?.creditCardsOverview.brand}
                        ***** {card?.creditCardsOverview.lastFour}
                        (Exp.
                        {moment(card.creditCardsOverview.expires).format(
                          "MM/YY"
                        )}
                        )
                      </MenuItem>
                    );
                  })}
                </Select>
                {paymentOptObtain ? null : (
                  <span
                    className="tw-text-skiptiOrange tw-underline tw-cursor-pointer tw-mt-2 tw-mb-3"
                    onClick={() => setAddCart(true)}
                  >
                    +Add new card
                  </span>
                )}
              </FormControl>
            )}
          </div>

          <div>
            <h4 className="tw-font-bold tw-mt-4">Billing Address</h4>

            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="paymetnBilling">Your addresses</InputLabel>
              <Select
                id="paymetnBilling"
                label="Your addresses"
                fullWidth
                onChange={changeAddressHandler}
                value={address}
              >
                {addresses?.map((el) => (
                  <MenuItem value={el?.resourceId} key={el?.resourceId}>
                    <div className=" tw-w-full tw-m-2 hover:tw-bg-slate-100">
                      <p>{el?.contactDisplayName}</p>
                      <p>{el?.address?.street1}</p>
                      <p>{el?.address?.street2}</p>
                      <p>{`${el?.address?.city}, ${el?.address?.stateProvince} ${el?.address?.zipPostal}`}</p>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              <span
                className="tw-text-skiptiOrange tw-cursor-pointer tw-underline tw-mt-2 tw-mb-3"
                onClick={() => setAddBilling(true)}
              >
                +Add new address
              </span>
            </FormControl>
          </div>

          <div className="tw-flex tw-justify-end tw-mt-12">
            <div className="tw-m-1 tw-bg-gray-300 tw-rounded-md tw-text-black">
              <SkiptiButton
                onClick={() => {
                  setUpdatePayment(false);
                }}
                design="secondary dialog"
              >
                CANCEL
              </SkiptiButton>
            </div>
            <div className="tw-m-1  tw-rounded-md">
              <SkiptiButton
                onClick={() => {
                  handleSubmitUpdate();
                  firstRender.current = false;
                }}
                design="primary dialog"
              >
                UPDATE
              </SkiptiButton>
            </div>
          </div>
        </div>
      )}
    </SkiptiDialog>
  );
};
export default UpdatePaymentInfo;
