import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TransportUnavailableOverlay from "./TransportUnavailableOverlay";

class ItemCarousel extends Component {
  static propTypes = {
    productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.string,
        caption: PropTypes.string,
      })
    ),
  };

  state = {};

  render() {
    const { productId, demoMode } = this.props;
    let { images } = this.props;
    const { transportOnly, hasInventory, isAppointment } = this.props;
    if (!images) {
      images = [];
    }
    const settings = {
      className: "item-tile-carousel",
      adaptiveHeight: false,
      lazyLoad: "ondemand",
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <Carousel {...settings}>
          {images &&
            images.map((photo, index) =>
              demoMode ? (
                <div key={index}>
                  {transportOnly || hasInventory || isAppointment ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        opacity: "0.05",
                        backgroundColor: "grey",
                        zIndex: "10",
                      }}
                    />
                  ) : (
                    <TransportUnavailableOverlay />
                  )}
                  <img
                    src={
                      photo.file !== ""
                        ? photo.file
                        : "https://dummyimage.com/500x500/ccc/fff&text=+"
                    }
                    alt={photo.caption}
                    className="img-fluid tw-mb-2"
                    style={{
                      maxHeight: "400px",
                    }}
                  />{" "}
                </div>
              ) : (
                <Link
                  to={`/items/${productId}`}
                  href={`/items/${productId}`}
                  key={index}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      opacity: "0.05",
                      backgroundColor: "grey",
                      zIndex: "10",
                    }}
                  />
                  <img
                    src={
                      photo.file !== ""
                        ? photo.file
                        : "https://dummyimage.com/500x500/ccc/fff&text=+"
                    }
                    alt={photo.caption}
                    className="img-fluid tw-mb-2"
                    style={{
                      maxHeight: "400px",
                    }}
                  />
                </Link>
              )
            )}
        </Carousel>
      </div>
    );
  }
}

export default ItemCarousel;
