import React, { Component } from "react";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import _ from "lodash";
import { connect } from "react-redux";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import SkiptiButton from "../skipti/Button";
import "../../css/stripe.css";
import MobileCheckoutBar from "../items/rent/MobileCheckoutBar";
import CreateAddressComponent from "../dashboard/CreateAddressComponent";
import { getToken, getUser } from "../../reducers/authReducer";
import { showSnackbar } from "../../actions";
import { getAddressById } from "../../reducers/addressesReducer";
import { getItemRentInfo } from "../../reducers/itemRentReducer";
import {
  setCardStripeInfo,
  setPaymentDataFromStripe,
} from "../paymentInfoSlice/paymentInfoSlice";
import Spinner from "../skipti/Spinner";
import { fulfillmentTypes } from "../../actions/utils";

const createOptions = (fontSize, padding) => ({
  style: {
    base: {
      color: "#424770",
      fontSize,
      padding,
      letterSpacing: "0.025em",
      fontFamily: "Source Code Pro, monospace",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
});

class StripeForm extends Component {
  state = {
    name: "",
    fieldsHelpers: {},
    isBillingSame: true,
    btnDisable: false,
    isLoadingBillingZip: false,
    stripeReadyElementsCount: 0,
    isLoadingStripeForm: true,
  };

  constructor() {
    super();
    this.state = {
      name: "",
      isBillingSame: true,
      billingAddress: null,
      deliveryAddress: null,
      btnDisable: false,
      resourceId: null,
      isInStore: false,
      stripeReadyElementsCount: 0,
      isLoadingStripeForm: true,
    };
  }

  /**
   * This method will call when stripe element is ready and can be used
   * This method should be added for every stripe element
   * Example: onReady={this.handleStripeElementReadyEvent}
   */
  handleStripeElementReadyEvent = () => {
    this.setState(
      (state) => ({
        stripeReadyElementsCount: ++state.stripeReadyElementsCount,
      }),
      () => {
        if (this.state.stripeReadyElementsCount === 3) {
          this.setState({
            stripeReadyElementsCount: 0,
            isLoadingStripeForm: false,
          });
        }
      }
    );
  };

  componentDidMount() {
    const _isInStore =
      this.props.itemRentInfo?.fulfillmentType?.id ===
      fulfillmentTypes.Instore.id;

    let _isBillingSame;

    if (_isInStore) {
      _isBillingSame = false;
    } else {
      _isBillingSame = this.props.newCardObtain
        ? false
        : this.state.isBillingSame;
    }

    if (this.props.cart) {
      this.setState((_prev) => ({
        ..._prev,
        billingAddress: this.props.cart.billingAddress ?? {
          addressType: 1,
          name: this.props.addressName || "",
          street1: "",
          street2: "",
          city: "",
          stateProvince: "",
          zipPostal: "",
          country: "USA",
          latitude: 44.45347,
          longitude: 26.0759051,
          altitude: 0.0,
          deliveryNotes: "",
        },
        deliveryAddress: this.props.cart.customerLocation ?? {
          addressType: 1,
          name: this.props.addressName || "",
          street1: "",
          street2: "",
          city: "",
          stateProvince: "",
          zipPostal: "",
          country: "USA",
          latitude: 44.45347,
          longitude: 26.0759051,
          altitude: 0.0,
          deliveryNotes: "",
        },
        isBillingSame: _isBillingSame,
        resourceId: this.props.newCardObtain
          ? this.props.current_user?.defaultLocation
          : _prev.resourceId,
        isInStore: _isInStore,
      }));
    } else {
      this.setState((_prev) => ({
        ..._prev,
        isBillingSame: _isBillingSame,
        resourceId: this.props.newCardObtain
          ? this.props.current_user?.defaultLocation
          : _prev.resourceId,
        isInStore: _isInStore,
      }));
    }
  }

  updateCardInfoFromStripe = (card) => {
    const _dateMonth =
      card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month;
    const cardInfo = {
      isDefault: true,
      name: card.name,
      brand: card.brand,
      expires: `${card.exp_year}-${_dateMonth}-01T00:00:00`,
      lastFour: card.last4,
      isNewCard: true,
      id: "new card",
    };
    if (this.props.dialogObtain) {
      this.props.handleAddNewCard({ creditCardsOverview: cardInfo });
    }
    this.props.setPaymentDataFromStripe(cardInfo);
  };

  getFormIsEmpty = (values) => {
    const { street1, city, zipPostal } = values;
    if (street1 && city && zipPostal) {
      return false;
    }
    return true;
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!this.props.dialogObtain) {
      this.props.isLoading(true);
    }

    if (!this.state.isBillingSame) {
      const isFormEmpty = this.getFormIsEmpty(this.formRef?.current?.values);
      if (Object.keys(this.formRef.current.errors).length || isFormEmpty) {
        this.props.showSnackbar("Invalid billing address", "error");
        this.props.isLoading(false);
        return;
      }
    }
    if (this.props.stripe) {
      this.props.stripe.createToken().then(async (payload) => {
        if (_.isEmpty(payload.error)) {
          if (this.props.dialogObtain) {
            this.updateCardInfoFromStripe(payload.token.card);
            this.props.setClose(false);
            this.props.setCardStripeInfo(payload);
          } else {
            this.updateCardInfoFromStripe(payload.token.card);
            this.props.setCardStripeInfo(payload);
            await this.props.handleSubmit({
              ...payload,
              name: this.state.name || "",
              location: {
                address: this.formRef.current?.values,
                resourceId: this.state.isBillingSame
                  ? this.props.current_user?.defaultLocation
                  : "00000000-0000-0000-0000-000000000000",
              },
            });
          }
        } else {
          if (!this.props.dialogObtain) {
            this.props.isLoading(false);
          }
          this.props.showSnackbar(payload.error.message, "error");
        }
      });
    }
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleBillingAddress = () => {
    this.setState((_prev) => ({
      ..._prev,
      isBillingSame: !_prev.isBillingSame,
    }));
  };

  setLoadingBillingZip = (value) => {
    this.setState({
      isLoadingBillingZip: value,
    });
  };

  render() {
    const { buttonText, isInCheckout, loading } = this.props;
    const { isLoadingStripeForm } = this.state;

    this.formRef = React.createRef(null);
    return (
      <>
        <form className="stripeFormElement" onSubmit={this.handleSubmit}>
          {this.props.dialogObtain ? null : (
            <h3 className="tw-mt-2">Card Information</h3>
          )}
          <div className="stripe-form-group">
            <Spinner
              loading={isLoadingStripeForm}
              absolute={false}
              opacity={0.01}
            />

            <div
              className={`stripe-elements-content ${
                isLoadingStripeForm ? "tw-hidden" : ""
              }`}
            >
              <div className="">
                <CardNumberElement
                  placeholder="Card Number"
                  className="stripeField cardnumber"
                  name="cardNumberElement"
                  onReady={this.handleStripeElementReadyEvent}
                  optional
                  {...createOptions(this.props.fontSize)}
                />
              </div>
              <div className="tw-flex">
                <div className="tw-w-1/2 tw-mr-2">
                  <CardExpiryElement
                    placeholder="Expiration Date(MM/YY)"
                    className="stripeField"
                    name="cardExpiryElement"
                    onReady={this.handleStripeElementReadyEvent}
                    {...createOptions(this.props.fontSize)}
                  />
                </div>
                <div className="tw-w-1/2 tw-ml-2">
                  <CardCVCElement
                    placeholder="CVC Number"
                    className="stripeField"
                    name="cardCVCElement"
                    onReady={this.handleStripeElementReadyEvent}
                    {...createOptions(this.props.fontSize)}
                  />
                </div>
              </div>
            </div>
          </div>

          {this.props.dialogObtain ? null : (
            <>
              <h3 className="tw-mt-2">Billing Address</h3>
              {this.props.newCardObtain || this.state.isInStore ? null : (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isBillingSame}
                        onChange={this.handleBillingAddress}
                      />
                    }
                    label="My billing and order address are the same"
                  />
                </FormGroup>
              )}
            </>
          )}
          {this.state.isBillingSame ? null : (
            <CreateAddressComponent
              formRef={this.formRef}
              getBillingErrors={this.getBillingErrors}
              isBilling
              handleSubmit={this.handleSubmit}
              address={this.state.billingAddress}
              isLoadingBillingZip={this.setLoadingBillingZip}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "40px",
            }}
          >
            {this.props.dialogObtain ? (
              <>
                <SkiptiButton
                  onClick={() => this.props.setClose(false)}
                  design="secondary dialog"
                >
                  CANCEL
                </SkiptiButton>
                <SkiptiButton type="submit" design="primary dialog">
                  {buttonText}
                </SkiptiButton>
              </>
            ) : (
              <SkiptiButton
                type="submit"
                disabled={
                  this.state.btnDisable ||
                  this.state.isLoadingBillingZip ||
                  loading
                }
                design="primary dialog"
              >
                {buttonText || "Review Order"}
              </SkiptiButton>
            )}
          </div>
          {isInCheckout ? (
            <MobileCheckoutBar
              item={this.props.item}
              isDisabled={false}
              handleNext={this.handleSubmitMyForm}
            />
          ) : null}
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jwt: getToken(state),
  current_user: getUser(state),
  address: getAddressById(state),
  itemRentInfo: getItemRentInfo(state),
});

export default connect(mapStateToProps, {
  showSnackbar,
  setCardStripeInfo,
  setPaymentDataFromStripe,
})(injectStripe(StripeForm));
