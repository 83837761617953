import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import { getIsLoggedIn, getUser, getToken } from "../../reducers/authReducer";
import { getArticles } from "../../reducers/articlesReducer";

class LayoutMain extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    current_user: PropTypes.shape({}).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
      PropTypes.shape({}).isRequired,
    ]).isRequired,
    articles: PropTypes.shape({
      isLoaded: PropTypes.bool.isRequired,
      isFetching: PropTypes.bool.isRequired,
      isError: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(),
    }),
  };

  static defaultProps = {
    articles: [],
  };

  state = {};

  render() {
    const {
      isLoggedIn,
      current_user,
      children,
      articles,
      noFooter,
      renderStep,
      showStep,
      currentProgress,
      showProgress,
      showSearch,
      jwt,
      partnerLogo,
      partner,
      withAdditionalLinks,
      withTopBar,
      navbarColor,
      withContainer = true,
      withSecondBar = false,
      isBuyFlow = true,
      isCheckoutFlow = false,
      isAppointment = false,
      hideLocationBar = false,
    } = this.props;
    const articlesData = (articles && articles.data) || [];
    return (
      <>
        <Navbar
          isLoggedIn={isLoggedIn || !!jwt}
          current_user={current_user}
          renderStep={renderStep}
          showStep={showStep}
          currentProgress={currentProgress}
          showProgress={showProgress}
          showSearch={showSearch}
          partnerLogo={partnerLogo}
          partner={partner}
          withAdditionalLinks={withAdditionalLinks}
          withTopBar={withTopBar}
          navbarColor={navbarColor}
          currentStep={currentProgress}
          isBuyFlow={isBuyFlow}
          isAppointment={isAppointment}
          hideLocationBar={hideLocationBar}
        />
        <div
          className={classnames("tw-w-full tw-mx-auto content", {
            "tw-my-40 md:tw-my-32": isCheckoutFlow,
            "tw-container tw-px-4 tw-my-12": withContainer,
            "tw-my-12": withSecondBar,
          })}
        >
          {children}
        </div>
        {!noFooter && <Footer articles={articlesData} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  articles: getArticles(state),
  isLoggedIn: getIsLoggedIn(state),
  current_user: getUser(state),
  jwt: getToken(state),
});

export default connect(mapStateToProps)(LayoutMain);
