import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { showSnackbar } from "../../../../actions/snackbar";
import { getToken } from "../../../../reducers/authReducer";
import { serverUrl } from "../../../../utils";

export const selectIsFetching = (state) => state.depositsSlice.isFetching;
export const selectSuggestions = (state) => state.depositsSlice.suggestions;

export const getDeposits = createAsyncThunk(
  "depositsSlice/getDeposits",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/admin/deposit/${values.orderId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      let subItems = [];
      const _data = res.data.transactions.map((deposit) => {
        let _status = "HELD";

        if (deposit.fullyRefunded) {
          _status = "RELEASED";
        } else if (deposit.isCaptured) {
          _status = "CAPTURED";
        } else if (!deposit.fullyRefunded && !deposit.isCaptured) {
          const _isParcialCaptured = deposit.items.some(
            (el) => el.itemCaptured
          );

          if (_isParcialCaptured) {
            _status = "PARCIAL-CAPTURED";
          } else {
            const _isParcialRelease = deposit.items.some(
              (el) => el.itemRefunded
            );
            if (_isParcialRelease) {
              _status = "PARCIAL-RELEASED";
            }
          }
        }

        subItems = [...subItems, ...deposit.items];
        return { ...deposit, _chosen: false, status: _status };
      });

      subItems = subItems.map((el) => {
        let _status = "HELD";

        if (el.itemRefunded) {
          _status = "RELEASED";
        } else if (el.itemCaptured) {
          _status = "CAPTURED";
        }

        return {
          ...el,
          _chosen: false,
          _indeterminate: el.itemCaptured || el.itemRefunded,
          status: _status,
        };
      });

      return {
        allDeposits: _data,
        subDeposits: subItems,
      };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const releaseDeposit = createAsyncThunk(
  "depositsSlice/releaseDeposit",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    const deposit = selectSuggestions(thunkAPI.getState()).allDeposits.find(
      (el) => el.id === values.depositId
    );
    const _itemDeposits = Object.values(values.depositItems).filter(
      (el) => !el.itemCaptured && !el.itemRefunded
    );
    const _params = {
      ...deposit,
      items: [..._itemDeposits],
    };

    try {
      await axios.post(
        `${serverUrl}/api/v1/admin/order/deposit/release/${values.orderId}`,
        {
          transactions: [_params],
          amountCents: _params.amountCents,
          description: _params.description,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return true;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const captureDeposit = createAsyncThunk(
  "depositsSlice/captureDeposit",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    const deposit = selectSuggestions(thunkAPI.getState()).allDeposits.find(
      (el) => el.id === values.depositId
    );
    const _itemDeposits = Object.values(values.depositItems).filter(
      (el) => !el.itemRefunded
    );
    const _params = {
      ...deposit,
      items: [..._itemDeposits],
    };

    try {
      await axios.post(
        `${serverUrl}/api/v1/admin/order/deposit/capture/${values.orderId}`,
        {
          transactions: [_params],
          amountCents: _params.amountCents,
          description: _params.description,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return true;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "depositsSlice",
  initialState: {
    suggestions: {
      allDeposits: [],
      subDeposits: [],
    },
    isFetching: true,
  },
  reducers: {
    setSuggestions: (state, { payload }) => ({
      ...state,
      suggestions: payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeposits.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getDeposits.rejected, (state) => {
        state.isFetching = false;
        state.deposits = [];
        state.depositsSubItems = [];
      })
      .addCase(getDeposits.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.suggestions = {
          allDeposits: payload.allDeposits,
          subDeposits: payload.subDeposits,
        };
      })
      .addCase(releaseDeposit.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(releaseDeposit.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(captureDeposit.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(captureDeposit.rejected, (state) => {
        state.isFetching = false;
      });
  },
});

export const { setSuggestions } = slice.actions;

export default slice.reducer;
