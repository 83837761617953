import * as TYPES from "../types";
import * as helpers from "../utils";

const articlesReducer = (
  state = {
    articles: helpers.makeInitial(),
    items: helpers.makeInitial(),
    byId: helpers.makeInitial(),
    adminArticles: helpers.makeInitial(),
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.ARTICLE_BYID_REQUEST:
      return { ...state, byId: helpers.makeStartLoading() };
    case TYPES.ARTICLES_ALL_REQUEST:
      return { ...state, items: helpers.makeStartLoading() };
    case TYPES.ARTICLE_BYID_SUCCESS:
      return { ...state, byId: helpers.makeLoadSuccess(payload) };
    case TYPES.ARTICLES_ALL_SUCCESS:
      return { ...state, items: helpers.makeLoadSuccess(payload) };
    case TYPES.ARTICLE_BYID_FAIL:
      return { ...state, byId: helpers.makeLoadFailed(error) };
    case TYPES.ARTICLES_ALL_FAIL:
      return { ...state, items: helpers.makeLoadFailed(error) };
    case TYPES.ARTICLES_TOP10_REQUEST:
      return { ...state, top10: helpers.makeStartLoading() };
    case TYPES.ARTICLES_TOP10_SUCCESS:
      return { ...state, top10: helpers.makeLoadSuccess(payload) };
    case TYPES.ARTICLES_TOP10_FAIL:
      return { ...state, top10: helpers.makeLoadFailed(error) };
    case TYPES.ARTICLES_BLOG_REQUEST:
      return { ...state, blog: helpers.makeStartLoading() };
    case TYPES.ARTICLES_BLOG_SUCCESS:
      return { ...state, blog: helpers.makeLoadSuccess(payload) };
    case TYPES.ARTICLES_BLOG_FAIL:
      return { ...state, blog: helpers.makeLoadFailed(error) };
    case TYPES.ARTICLES_IDEA_REQUEST:
      return { ...state, idea: helpers.makeStartLoading() };
    case TYPES.ARTICLES_IDEA_SUCCESS:
      return { ...state, idea: helpers.makeLoadSuccess(payload) };
    case TYPES.ARTICLES_IDEA_FAIL:
      return { ...state, idea: helpers.makeLoadFailed(error) };
    case TYPES.ARTICLES_ADMIN_TEASERS_FAIL:
      return { ...state, adminArticles: helpers.makeLoadFailed(error) };
    case TYPES.ARTICLES_ADMIN_TEASERS_REQUEST:
      return { ...state, adminArticles: helpers.makeStartLoading() };
    case TYPES.ARTICLES_ADMIN_TEASERS_SUCCESS:
      return { ...state, adminArticles: helpers.makeLoadSuccess(payload) };
    default:
      return state;
  }
};

export const getArticles = (state) => state.articles.items;
export const getArticle = (state) => state.articles.byId;
export const getTop10Articles = (state) => state.articles.top10;
export const getBlogArticles = (state) => state.articles.blog;
export const getIdeaArticles = (state) => state.articles.idea;
export const getAdminArticles = (state) => state.articles.adminArticles;

export default articlesReducer;
