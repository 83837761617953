import React, { Component } from "react";
import axios from "axios";
import { serverUrl } from "../../utils";

class Ping extends Component {
  async componentDidMount() {
    try {
      await axios.get(`${serverUrl}/api/v1/user/ping`);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return null;
  }
}
export default Ping;
