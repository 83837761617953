import React, { Component } from "react";
import { withRouter } from "react-router";
import uuid from "uuid/v4";
import { connect } from "react-redux";
import axios from "axios";
import {
  Typography,
  withWidth,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import MixpanelContext from "react-mixpanel";
import {
  setDefaultLocation,
  setStripeFallbackLocation,
  showSnackbar,
  setZipCode,
} from "../../actions";
import CreateAddressComponent from "../dashboard/CreateAddressComponent";
import { getUser, getToken, getCityName } from "../../reducers/authReducer";
import { serverUrl } from "../../utils";
import {
  getItemRentInfo,
  getSelectedDeliveryOption,
} from "../../reducers/itemRentReducer";
import RentDetails from "../items/rent/RentDetailsCart";
import { getItemPricingInfo } from "../../reducers/itemsReducer";
import SkiptiButton from "../skipti/Button";
import MobileCheckoutBar from "../items/rent/MobileCheckoutBar";
import {
  getCartKey,
  getCartFromRedux,
  setCurrentCartLocation,
} from "../items/item_upsell/CartSlice";
import { checkFulfillmentType, fulfillmentTypes } from "../../actions/utils";

const tempStyles = {
  marginTop: "48px",
  justifyContent: "center",
};

const tempStyles2 = {
  marginTop: "48px",
  justifyContent: "center",
};
class AddressStep extends Component {
  static contextType = MixpanelContext;

  submitMyForm = null;

  constructor(props) {
    super(props);
    this.state = {
      isAddressNotServiceable: false,
      isErrorModalOpen: false,
      isPerimeterModalOpen: false,
      perimeterPricingCost: null,
      wasCartReset: false,
      goToNextStep: false,
      isInstoreOrCurbside: false,
      isLoading: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.setId) {
      this.props.setId();
    }
    this.context.track("Rent an Item - Address Step", {
      productResourceId: this.props.item || "",
      productName: this.props.item?.title || "",
    });
    window.analytics.track("Checkout Step Viewed", {
      checkout_id: "",
      step: 4,
    });
    window.analytics.page("Checkout Address Step", {
      productResourceId: this.props.item || "",
      productName: this.props.item?.title || "",
    });

    const _isInstoreOrCurbside = checkFulfillmentType(
      this.props.itemRentInfo?.fulfillmentType?.id,
      [fulfillmentTypes.Instore.id]
    );

    this.setState((_prev) => ({
      ..._prev,
      isInstoreOrCurbside: _isInstoreOrCurbside,
    }));
  }

  handleBackToHomepage = () => {
    this.props.history.push("/");
  };

  handleModifyAddress = (ev, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    this.setState({
      isAddressNotServiceable: false,
      isErrorModalOpen: false,
      isPerimeterModalOpen: false,
      perimeterPricingCost: null,
    });
  };

  handleOpenCartResetDialog = (goNext) => {
    this.setState({
      wasCartReset: !goNext,
      goToNextStep: goNext,
    });
  };

  handleSubmit = async (values, { setSubmitting }) => {
    this.setState({ isLoading: true });
    let mappedAddress = {};

    const _skipaddendum =
      this.props.current_user.profileInitFlags.hasName ||
      this.props.current_user.profileInitFlags.hasPhoneNumber;

    const { firstName, lastName, phone, smsOptIn, ...rest } = values;
    mappedAddress = {
      address: rest,
      organizationId: this.props.current_user.resourceOrganization,
      availabilityCalendar: 0,
      externalId: "",
      isActive: true,
      locationType: values.locationType,
    };

    const mappedAddendum = {
      firstName,
      lastName,
      phone,
      smsOptIn,
    };

    const isInStoreOrCurbside = checkFulfillmentType(
      this.props.itemRentInfo?.fulfillmentType?.id,
      [fulfillmentTypes.Instore.id, fulfillmentTypes.Curbside.id]
    );

    setSubmitting(true);
    this.context.identify(this.props.current_user.userId);
    this.context.people.set({
      $first_name: values.firstName,
      $last_name: values.lastName,
      $phone: values.phone,
      "Registration Method": "Rent wizard",
    });

    this.context.track("Address Added", {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
      address: values,
      organizationId: this.props.current_user.resourceOrganization,
      availabilityCalendar: 0,
      externalId: "",
      isActive: true,
      locationType: values.locationType,
    });
    window.analytics.track("Address Added", {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
      address: values,
      organizationId: this.props.current_user.resourceOrganization,
      availabilityCalendar: 0,
      externalId: "",
      isActive: true,
      locationType: values.locationType,
    });

    if (isInStoreOrCurbside || !_skipaddendum) {
      try {
        await axios.post(`${serverUrl}/api/v1/user/addendum`, mappedAddendum, {
          headers: {
            Authorization: `Bearer ${this.props.jwt}`,
          },
        });
      } catch (e) {
        setSubmitting(false);
        return this.props.showSnackbar(e.message, "error");
      }
    }

    if (
      !(isInStoreOrCurbside && this.props.item && this.props.item.canSkipCC)
    ) {
      try {
        const res = await axios.post(
          `${serverUrl}/api/v1/resources/location-addendum`,
          { ...mappedAddendum, location: { ...mappedAddress } },
          {
            headers: {
              Authorization: `Bearer ${this.props.jwt}`,
            },
          }
        );
        this.props.setDefaultLocation(res.data.id);

        this.props.setStripeFallbackLocation(values);

        this.props.setZipCode(mappedAddress?.address?.zipPostal);

        const isAppropriateFulfillmentType = checkFulfillmentType(
          this.props.itemRentInfo?.fulfillmentType?.id,
          [
            fulfillmentTypes.Instore.id,
            fulfillmentTypes.Curbside.id,
            fulfillmentTypes.Shipping.id,
          ]
        );

        if (
          res.data.perimeterPricingApplies === true &&
          this.props.itemRentInfo &&
          !isAppropriateFulfillmentType
        ) {
          this.context.track("Show Perimeter Pricing Pop-up", {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            address: values,
            organizationId: this.props.current_user.resourceOrganization,
            availabilityCalendar: 0,
            externalId: "",
            isActive: true,
            locationType: values.locationType,
            isPerimeterModalOpen: true,
            perimeterPricingCost: res.data.perimeterPricingCost,
            mappedAddress,
          });
          window.analytics.track("Show Perimeter Pricing Pop-up", {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            address: values,
            organizationId: this.props.current_user.resourceOrganization,
            availabilityCalendar: 0,
            externalId: "",
            isActive: true,
            locationType: values.locationType,
            isPerimeterModalOpen: true,
            perimeterPricingCost: res.data.perimeterPricingCost,
            mappedAddress,
          });

          return this.setState((_prev) => ({
            ..._prev,
            isPerimeterModalOpen: true,
            perimeterPricingCost: res.data.perimeterPricingCost,
            isLoading: true,
          }));
        }
        if (
          this.props.cartkey &&
          this.props.cart &&
          this.props.cart.productRequests?.length > 0
        ) {
          this.props.setCurrentCartLocation({
            locationId: res.data.id,
            handleOpenCartResetDialog: this.handleOpenCartResetDialog,
            nextStep: this.props.nextStep,
            values,
          });
        } else {
          this.props.nextStep(values);
        }
        // setSubmitting(false);
      } catch (e) {
        setSubmitting(false);

        if (e?.response?.status === 406 || e?.response?.status === "406") {
          if (
            this.props.selectedDeliveryOption !== "shipping" ||
            !(
              isInStoreOrCurbside &&
              this.props.item &&
              this.props.item.canSkipCC &&
              this.props.pricingInfo.total === 0
            )
          ) {
            this.context.track("Address Not Valid Pop-up", {
              first_name: values.firstName,
              last_name: values.lastName,
              phone: values.phone,
              address: values,
              organizationId: this.props.current_user.resourceOrganization,
              availabilityCalendar: 0,
              externalId: "",
              isActive: true,
              locationType: values.locationType,
              mappedAddress,
            });
            window.analytics.track("Address Not Valid Pop-up", {
              first_name: values.firstName,
              last_name: values.lastName,
              phone: values.phone,
              address: values,
              organizationId: this.props.current_user.resourceOrganization,
              availabilityCalendar: 0,
              externalId: "",
              isActive: true,
              locationType: values.locationType,
              mappedAddress,
            });
            return this.setState((_prev) => ({
              ..._prev,
              isErrorModalOpen: true,
              errorOptions: e?.response.data.map((el) => ({
                _id: uuid(),
                ...el,
              })),
              isLoading: false,
            }));
          }
        }
        if (e?.response?.status === 404 || e?.response?.status === "404") {
          this.context.track("Address Not Serviced Pop-up", {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            address: values,
            organizationId: this.props.current_user.resourceOrganization,
            availabilityCalendar: 0,
            externalId: "",
            isActive: true,
            locationType: values.locationType,
            mappedAddress,
          });
          window.analytics.track("Address Not Serviced Pop-up", {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            address: values,
            organizationId: this.props.current_user.resourceOrganization,
            availabilityCalendar: 0,
            externalId: "",
            isActive: true,
            locationType: values.locationType,
            mappedAddress,
          });
          return this.setState((_prev) => ({
            ..._prev,
            isAddressNotServiceable: true,
            isLoading: false,
          }));
        }

        // eslint-disable-next-line no-else-return

        this.props.showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e),
          JSON.stringify(mappedAddress)
        );
      }
      this.setState({ isLoading: false });
    } else {
      this.props.nextStep(values);
    }
    // setSubmitting(false);

    // commenting this out as the button becomes enabled before going to the next step
    // this.setState({ isLoading: false });
    // if (this.state.goToNextStep) this.props.nextStep(values);
  };

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };

  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  render() {
    const { width, itemRentInfo } = this.props;

    const itemFulfillmentTypeId = itemRentInfo?.fulfillmentType?.id;

    const isInStore = itemFulfillmentTypeId === fulfillmentTypes.Instore.id;
    const isCurbside = itemFulfillmentTypeId === fulfillmentTypes.Curbside.id;
    const isDelivery = itemFulfillmentTypeId === fulfillmentTypes.Courier.id;

    const isInStoreOrCurbside = isInStore || isCurbside;

    const skipAddressValidFulfillmentType =
      (isInStore && !isDelivery) || isCurbside;

    return (
      <div className="tw-px-2" key="step2">
        <div className="tw-flex tw-flex-row tw-h-full">
          <div className="md:tw-w-8/12">
            <div className="md:tw-w-10/12">
              {width === "xs" ? (
                <h3 className="tw-mb-6 fw-light">
                  {isInStoreOrCurbside
                    ? "Enter your contact information"
                    : this.props.title}
                </h3>
              ) : (
                <h2 className="tw-mb-6 fw-light">
                  {isInStoreOrCurbside
                    ? "Enter your contact information"
                    : this.props.title}
                </h2>
              )}
              {this.state.isInstoreOrCurbside ? null : (
                <div className="tw-mb-4">
                  {!(
                    isInStoreOrCurbside &&
                    this.props.item &&
                    this.props.item.canSkipCC &&
                    this.props.pricingInfo.total === 0
                  ) && this.props.isAppointment ? (
                    <Typography variant="body2">
                      The courier will arrive at this address within your chosen
                      demo appointment window. Be sure to enter any special
                      delivery notes.
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      The driver will drop off and pick up your rental from this
                      address. Be sure to enter any special delivery notes.
                    </Typography>
                  )}
                </div>
              )}
              <CreateAddressComponent
                isInRentWiz
                addressName=""
                noHeader
                inWizard
                handleSubmit={this.handleSubmit}
                bindSubmitForm={this.bindSubmitForm}
                skipAddress={
                  skipAddressValidFulfillmentType &&
                  this.props.item &&
                  this.props.item.canSkipCC
                }
                formRef={this.formRef}
                isInstoreOrCurbside={this.state.isInstoreOrCurbside}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
          <div className="md:tw-w-4/12 tw-hidden md:tw-block tw-self-start skiptip">
            <RentDetails
              deliveryOnly={this.props.deliveryOnly}
              item={this.props.item}
              zip={this.props.itemRentInfo.zip}
              deliveryTime={this.props.itemRentInfo.startAvail}
              returnTime={this.props.itemRentInfo.endAvail}
              locationId={this.props.itemRentInfo.locationId}
              hideDates={this.props.hideDates || false}
              isAppointment={this.props.isAppointment}
            />
          </div>
          <MobileCheckoutBar
            item={this.props.item}
            isLoading={this.state.isLoading}
            isDisabled={
              this.formRef?.current?.isSubmitting || this.state.isLoading
            }
            handleNext={this.handleSubmitMyForm}
          />
        </div>
        <Dialog
          open={this.state.isErrorModalOpen}
          onClose={this.handleModifyAddress}
        >
          <div className="tw-p-10">
            <DialogTitle
              disableTypography
              className="tw-text-center tw-text-xl tw-font-bold paddingMarginRemover"
            >
              Invalid address
            </DialogTitle>
            <DialogContent className="paddingMarginRemover">
              <DialogContentText>
                <Typography
                  className="tw-text-center"
                  color="textPrimary"
                  variant="body1"
                >
                  Please choose one of possible addresses or modify address.
                </Typography>
                <div className="tw-px-2 tw-pt-2">
                  <FormControl component="fieldset" color="primary">
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      color="primary"
                      value={this.state?.currentOption}
                      onChange={({ target: { value } }) =>
                        this.setState((_prev) => ({
                          ..._prev,
                          currentOption: value,
                        }))
                      }
                    >
                      {this.state?.errorOptions?.map((el) => (
                        <FormControlLabel
                          color="primary"
                          className="tw-pb-2"
                          key={el._id}
                          value={el._id}
                          control={<Radio color="primary" />}
                          label={
                            <div className="tw-flex tw-flex-col">
                              <span>{el.street1}</span>
                              <span>{`${el.city} ${el.zipPostal}`}</span>
                            </div>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={tempStyles2}>
              <div className="tw-mr-3">
                <SkiptiButton
                  onClick={() => {
                    this.setState({ isLoading: false });
                    this.handleModifyAddress();
                  }}
                  design="primary dialog"
                >
                  {this.state?.currentOption === undefined
                    ? "Enter New Address"
                    : "Re-enter Address again"}
                </SkiptiButton>
              </div>
              <div>
                <SkiptiButton
                  onClick={() => {
                    const chosenOption = this.state?.errorOptions?.find(
                      (el) => el._id === this.state?.currentOption
                    );
                    this.formRef?.current?.setFieldValue(
                      "street1",
                      chosenOption.street1
                    );
                    this.formRef?.current?.setFieldValue(
                      "city",
                      chosenOption.city
                    );
                    this.formRef?.current?.setFieldValue(
                      "stateProvince",
                      chosenOption.stateProvince
                    );
                    this.formRef?.current?.setFieldValue(
                      "zipPostal",
                      chosenOption.zipPostal
                    );
                    this.handleModifyAddress();
                  }}
                  disabled={this.state?.currentOption === undefined}
                  design="primary dialog"
                  disableElevation
                >
                  Use This Address
                </SkiptiButton>
              </div>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={this.state.isAddressNotServiceable}
          onClose={this.handleModifyAddress}
        >
          <div className="tw-p-10">
            <DialogTitle
              disableTypography
              className="tw-text-center tw-text-xl tw-font-bold paddingMarginRemover"
            >
              Address not serviceable
            </DialogTitle>
            <DialogContent className="paddingMarginRemover">
              <DialogContentText>
                <Typography
                  className="tw-text-center"
                  color="textPrimary"
                  variant="body1"
                >
                  The address you entered is in an area currently not
                  serviceable by Skipti courier. All addresses in the US are
                  valid for shipping when it applies.
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={tempStyles}>
              <SkiptiButton
                onClick={this.handleModifyAddress}
                design="primary dialog"
                disableElevation
              >
                OK
              </SkiptiButton>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={this.state.isPerimeterModalOpen}
          onClose={this.handleModifyAddress}
        >
          <div className="tw-p-10">
            <DialogTitle
              disableTypography
              className="tw-text-center tw-text-xl tw-font-bold paddingMarginRemover"
            >
              Our drivers go to great lengths!
            </DialogTitle>
            <DialogContent className="paddingMarginRemover">
              <DialogContentText>
                <Typography
                  className="tw-text-center"
                  color="textPrimary"
                  variant="body1"
                >
                  Looks like your address is outside of our regular 10 mile
                  zone. No worries, our drivers can still deliver to you.
                </Typography>
                <br />
                <Typography
                  className="tw-text-center"
                  color="textPrimary"
                  variant="body1"
                >
                  Perimeter rates apply to your delivery address. A&nbsp;
                  {`$${this.state.perimeterPricingCost ?? "10"}`}
                  &nbsp;charge will be added to your order total.
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={tempStyles}>
              <div className="tw-mr-3">
                <SkiptiButton
                  design="primary dialog"
                  onClick={() => {
                    this.setState({ isLoading: false });
                    this.handleModifyAddress();
                  }}
                  style={{ width: "205px" }}
                >
                  Use Different Address
                </SkiptiButton>
              </div>
              <div>
                <SkiptiButton
                  style={{ width: "205px" }}
                  onClick={() => {
                    this.props.nextStep(this.formRef?.current?.values);
                    this.handleModifyAddress();
                  }}
                  design="primary dialog"
                  disableElevation
                >
                  Accept
                </SkiptiButton>
              </div>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={this.state.wasCartReset}
          onClose={() => window.location.reload()}
        >
          <div className="tw-p-10">
            <DialogTitle
              disableTypography
              className="tw-text-center tw-text-xl tw-font-bold paddingMarginRemover"
            >
              Restart checkout
            </DialogTitle>
            <DialogContent className="paddingMarginRemover">
              <DialogContentText>
                <Typography
                  className="tw-text-center"
                  color="textPrimary"
                  variant="body1"
                >
                  The address is in a different region than you previously
                  entered. You will need to restart the checkout.
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={tempStyles}>
              <SkiptiButton
                onClick={() => window.location.reload()}
                design="primary dialog"
                disableElevation
              >
                OK
              </SkiptiButton>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  current_user: getUser(state),
  jwt: getToken(state),
  city: getCityName(state),
  itemRentInfo: getItemRentInfo(state),
  selectedDeliveryOption: getSelectedDeliveryOption(state),
  pricingInfo: getItemPricingInfo(state),
  cartkey: getCartKey(state),
  cart: getCartFromRedux(state),
});

export default withRouter(
  connect(mapStateToProps, {
    setDefaultLocation,
    setStripeFallbackLocation,
    showSnackbar,
    setZipCode,
    setCurrentCartLocation,
  })(withWidth()(AddressStep))
);
