import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import CategoryList from "./CategoryList";
import ItemTile from "./ItemTile";
import { fetchProductsForAllCategoriesByOrg } from "../../actions";
import {
  getProductsForAllCategoriesByOrg,
  getZip,
} from "../../reducers/itemsReducer";

const PartnerItemsList = (props) => {
  const { org, noOfProducts, hideProductId } = props;
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const products = useSelector(getProductsForAllCategoriesByOrg);
  const zip = useSelector(getZip);

  const loadMore = useCallback(
    (orgId) => {
      dispatch(
        fetchProductsForAllCategoriesByOrg({
          id: orgId,
          pagesize: noOfProducts,
          pagenumber: pageNumber,
        })
      );

      // setPageNumber(pageNumber + 1);
    },
    [dispatch, noOfProducts, pageNumber]
  );

  useEffect(() => {
    if (org) {
      loadMore(org);
    }
  }, [org, zip]);

  useEffect(() => {
    const sortedItems = _(items)
      .keyBy((item) => item.category.id)
      .merge(
        _.keyBy(
          products && products.isLoaded ? products.data : [],
          (prod) => prod.category.id
        )
      )
      .sortBy((item) => item.category.displayOrder)
      .values()
      .value();
    const itemsOnlyNotEmpty = sortedItems?.filter(
      (category) =>
        category.resources.length !== 0 &&
        category.category.displayName !== "Fashion"
    );
    const filteredItems = itemsOnlyNotEmpty.map((i) => ({
      ...i,
      resources: i.resources.filter((r) => r.resourceId !== hideProductId),
    }));

    setItems(filteredItems);
  }, [products]);

  return (
    <div>
      {items && items.length > 1 && (
        <div className="sticky-categories" style={{ top: "80px" }}>
          <CategoryList categories={items} />
        </div>
      )}
      <div className="tw-mt-3 content">
        {items.length === 0 && (
          <div className="tw-w-full tw-px-4 tw-mx-auto tw-my-3 tw-p-4">
            <div className="tw-flex tw-flex-wrap tw-my-4">
              {Array(4)
                .fill({})
                .map((item, index) => (
                  <ItemTile
                    {...item}
                    index={index}
                    isDiscover
                    notResponsive={false}
                    noOfProducts={noOfProducts}
                    key={index}
                  />
                ))}
            </div>
          </div>
        )}
        {items.map((category) => (
          <React.Fragment key={category.category.id}>
            {category.resources && (
              <>
                <div
                  style={{
                    height: "1px",
                    position: "relative",
                    top: "-130px",
                  }}
                  id={`${category.category.displayName}`}
                />
                <div className="tw-w-full  tw-p-4 ">
                  <h3>
                    {category.category.displayName === "Photo&Video"
                      ? "Photo & Video"
                      : category.category.displayName}
                  </h3>

                  <div className="tw-flex tw-flex-wrap tw-my-4">
                    {category.resources.map((item, indexItems) => (
                      <ItemTile
                        {...item}
                        noOfProducts={noOfProducts}
                        index={indexItems}
                        key={item.resourceId}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PartnerItemsList;
