import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SkiptiButton from "../../../skipti/Button";
import CreateAddressComponent from "../../../dashboard/CreateAddressComponent";
import SkiptiDialog from "../../../skipti/SkiptiDialog";
import { getUser } from "../../../../reducers/authReducer";
import {
  setBillingData,
  setIsNewBilling,
} from "../../../paymentInfoSlice/paymentInfoSlice";
import { showSnackbar } from "../../../../actions";

const AddNewBillingDialog = ({ open, setClose, updateState }) => {
  const billingRef = useRef(null);
  const dispatch = useDispatch();
  const [isLoadingBillingZip, setLoadingBillingZip] = useState(false);
  const user = useSelector(getUser);

  const getFormIsEmpty = (values) => {
    const { street1, city, zipPostal } = values;
    if (street1 && city && zipPostal) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const isFormEmpty = getFormIsEmpty(billingRef?.current.values);
    const billingErrors = Object.keys(billingRef?.current.errors);
    if (billingRef?.current?.values && !billingErrors.length && !isFormEmpty) {
      const obj = {
        address: billingRef.current.values,
        availabilityCalendar: 0,
        externalId: "",
        isActive: true,
        locationType: 0,
        organizationId: user.resourceOrganization,
        resourceId: "empty string",
      };
      dispatch(setBillingData(obj));
      updateState(obj);
      dispatch(setIsNewBilling(true));
      setClose(false);
    } else {
      dispatch(showSnackbar("Invalid Billing Address", "error"));
    }
  };
  return (
    <SkiptiDialog
      open={open}
      maxWidth="sm"
      fullWidth
      toggle={() => setClose(false)}
      withCloseIcon
      title="Add New Billing Address"
      className="paymentStyleCenter"
    >
      <div>
        <div className="tw-w-full tw-relative tw-mt-4">
          <CreateAddressComponent
            formRef={billingRef}
            isBilling
            handleSubmit={handleSubmit}
            paymentObtain
            setClose={setClose}
            setLoadingBillingZip={setLoadingBillingZip}
          />
        </div>
        <div className="tw-mt-12 tw-flex tw-flex-row-reverse ">
          <div className="tw-m-1">
            <SkiptiButton
              disabled={isLoadingBillingZip}
              onClick={handleSubmit}
              design="primary dialog"
            >
              SAVE
            </SkiptiButton>
          </div>
          <div className="tw-m-1">
            <SkiptiButton
              design="secondary dialog"
              onClick={() => setClose(false)}
            >
              CANCEL
            </SkiptiButton>
          </div>
        </div>
      </div>
    </SkiptiDialog>
  );
};

export default AddNewBillingDialog;
