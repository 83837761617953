import * as TYPES from "../types";
import * as helpers from "../utils";

export default (state = {}, action) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.DASHBOARD_OVERVIEWDATA_REQUEST:
      return { ...state, overview: helpers.makeStartLoading() };
    case TYPES.DASHBOARD_OVERVIEWDATA_SUCCESS:
      return { ...state, overview: helpers.makeLoadSuccess(payload) };
    case TYPES.DASHBOARD_OVERVIEWDATA_FAIL:
      return { ...state, overview: helpers.makeLoadFailed(error) };

    default:
      return state;
  }
};

export const GetDashboardOverviewData = (state) => state.dashboard.overview;
