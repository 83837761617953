import React from "react";
import {
  Icon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

/**
 * @typedef {import("@material-ui/core").DialogProps & {
 * open?: boolean;
 * onExited: Function;
 * toggle: Function;
 * withCloseIcon?: boolean;
 * title?: string
 * loading?: boolean;
 * }} SkiptiDialogProps
 * @param {SkiptiDialogProps}
 */
const SkiptiDialog = ({
  open = false,
  toggle,
  children,
  title,
  withCloseIcon,
  loading = false,
  onExited,
  maxWidth,
  backgroundStyles = {},
  ...rest
}) => {
  return (
    <Dialog
      onClose={toggle}
      open={open}
      maxWidth={maxWidth}
      TransitionProps={{ onExited }}
      {...rest}
      className={`skipti-dialog ${loading ? "skipti-dialog-loading" : ""} ${
        rest.className || ""
      }`}
    >
      <div className="tw-p-10" style={backgroundStyles}>
        <DialogTitle disableTypography className="paddingMarginRemover">
          {title && (
            <h4
              className={`tw-text-2xl tw-font-bold tw-font-lato ${
                withCloseIcon ? " mod-title-with-close-icon" : ""
              }`}
            >
              {title}
            </h4>
          )}
          {withCloseIcon && (
            <IconButton
              color="secondary"
              onClick={toggle}
              className="paddingMarginRemover"
            >
              <Icon>close</Icon>
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent className="paddingMarginRemover">
          {children}
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default SkiptiDialog;
