import React from "react";
// import PropTypes from "prop-types";

// import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Rating from "../partials/Rating";
import ItemTile from "../partials/ItemTile";
// import iconVerifiedUser from "../../img/verified-icononly.png";
// import iconVerifiedUserWithText from "../../img/verifieduser3.png";
// import SocialShareButtons from "../partials/SocialShareButtons";
import { imageServerUrl } from "../../utils";

export default function ShowUser(props) {
  const { user, profilePicture } = props;
  const items = props.items !== null ? props.items : [];
  return (
    <>
      {user !== null ? (
        <>
          <div className="tw-flex" id="profile">
            {profilePicture || user.avatar ? (
              <div className="tw-px-4 md:tw-2/12">
                <img
                  src={profilePicture || user.avatar}
                  className="tw-w-full tw-mb-4"
                  alt=""
                  style={{ width: "130px" }}
                />
              </div>
            ) : null}
            <div
              className={user.avatar ? "tw-px-4 md:tw-w-10/12" : "tw-w-full"}
            >
              <ul className="top-right-actions tw-pl-0 tw-list-none">
                <li>
                  <a
                    href="#"
                    className="btn tw-text-skiptiOrange tw-float-right"
                    title="Add to favorites"
                  >
                    <i className="fas fa-heart" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="btn tw-text-skiptiOrange tw-float-right"
                    title="Share with friends"
                  >
                    <i className="fas fa-share-alt" />
                  </a>
                </li>
              </ul>

              <h1 className="tw-my-0 text-truncate">
                {user.firstName || "Anonymous"}
                &apos;s Profile
              </h1>

              {/* <div className="col-12"> */}
              {/* <div className="progress thick tw-text-xs tw-h-6 tw-my-4">
                <div
                  className="progress-bar tw-bg-skiptiOrange"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "50%" }}
                >
                  50% complete
                </div>
              </div> */}

              <div className="tw-flex tw-flex-wrap tw-mb-4">
                <div className="md:tw-w-4/12 tw-px-4">
                  <ul className="tw-pl-0 tw-list-none">
                    {/* <li className="mb-2">
                        Hometown <span className="float-right"></span>
                      </li> */}
                    <li className="tw-mb-2">
                      Credits:
                      <span className="tw-float-right tw-text-green-500">
                        ${user && user.skiptiCredit && user.skiptiCredit.amount}
                      </span>
                    </li>
                    <li className="tw-mb-2">
                      Joined{" "}
                      <span className="tw-float-right">
                        {moment(user.created).format("MM/DD/YYYY")}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="md:tw-w-4/12  tw-px-4">
                  <ul className="tw-pl-0 tw-list-none">
                    {/* <li className="mb-2">
                        Items for rent{" "}
                        <a href="#" className="float-right">
                          {items !== null && typeof items !== "undefined"
                            ? items.length
                            : 0}
                        </a>
                      </li>
                      <li className="mb-2">
                        Currently rented{" "}
                        <a href="#" className="float-right">
                          {" "}
                          2
                        </a>
                      </li>
                      <li className="mb-2">
                        Rented to date{" "}
                        <a href="#" className="float-right">
                          85
                        </a>
                      </li> */}
                    {/* ------------ */}
                    {/* <li className="mb-2">
                        Reviews as Owner
                        <div className="tw-text-skiptiOrange float-right">
                          <Rating
                            rating={user.ratingAsOwner}
                            readonly
                            // maxRating={5}
                          />
                        </div>
                      </li> */}
                    <li className="tw-mb-2">
                      Reviews as Renter
                      <div className="tw-text-skiptiOrange tw-float-right">
                        <Rating
                          rating={user.ratingAsRenter}
                          readonly
                          // maxRating={5}
                        />
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="md:tw-w-4/12 tw-px-4">
                  <ul className="list-unstyled" />
                </div>
              </div>
            </div>
          </div>
          {(user &&
            user.profileInitFlags &&
            user.profileInitFlags.isVerifiedUser) ||
          (user && user.badges && user.badges.length > 0) ? (
            <div className="tw-container tw-mx-auto tw-px-4 tw-w-full tw-my-6">
              <h3 className="tw-my-4">Badges</h3>
              <div className="tw-flex tw-flex-wrap">
                {user &&
                user.profileInitFlags &&
                user.profileInitFlags.isVerifiedUser ? (
                  <img
                    src={`${imageServerUrl}/verifieduser3.png`}
                    alt=""
                    className="user-avatar img-fluid "
                  />
                ) : (
                  ""
                )}
                {user &&
                  user.badges &&
                  user.badges.length > 0 &&
                  user.badges.map((badge, index) => (
                    <img
                      key={index}
                      src={badge.icon}
                      alt=""
                      className="user-avatar img-fluid "
                    />
                  ))}
              </div>
            </div>
          ) : null}

          <h3 className="tw-mb-4">Refer a friend and earn $20: </h3>
          {/* <SocialShareButtons whiteBG /> */}

          <div className="tw-flex tw-mx-auto tw-px-4 tw-w-full tw-container">
            <div className="tw-flex tw-flex-wrap">
              {items !== null && typeof items !== "undefined"
                ? items.length > 0
                  ? items.map((item) => (
                      <ItemTile
                        {...item}
                        noOfProducts="6"
                        key={item.resourceId}
                      />
                    ))
                  : null
                : null}
            </div>
          </div>
          {/* </div> */}
        </>
      ) : null}
    </>
  );
}
