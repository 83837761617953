import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { detect } from "detect-browser";
import MixpanelContext from "react-mixpanel";
import LayoutMain from "../layouts/LayoutMain";
import { CHUNK_LOAD_ERROR_TYPE } from "../../utils/constants";

class ErrorBoundary extends React.Component {
  static contextType = MixpanelContext;

  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  static getDerivedStateFromError(error) {
    if (error.message.length) {
      return { error: true };
    }
  }

  componentDidCatch(error, info) {
    // Add Error Log
    this.context.track("Error", {
      error,
      info,
    });

    // @todo: Temporary solution for reload page in case when found chunk load issue
    if (error && error.name === CHUNK_LOAD_ERROR_TYPE) {
      window.location.reload();
    }
  }

  render() {
    const browser = detect();

    if (this.state.error) {
      // Some error was thrown. Let's display something helpful to the user
      return (
        <LayoutMain withContainer>
          <Typography gutterBottom variant="h2">
            Something went wrong!
          </Typography>

          {browser && browser.name === "ie" ? (
            <Typography variant="body1">
              You are using Internet Explorer and it&apos;s not supporting some
              of our features. Please try with a more modern browser like Chrome
              or Firefox.
            </Typography>
          ) : (
            <Typography variant="body1">
              Please refresh the page. If the problem continues contact support.
            </Typography>
          )}
          {/* <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.info.componentStack}
          </details> */}
        </LayoutMain>
      );
    }
    // No errors were thrown. As you were.
    return this.props.children;
  }
}

export default ErrorBoundary;
