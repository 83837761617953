import React from "react";
import LayoutMain from "../layouts/LayoutMain";

export default function Unsubscribe() {
  return (
    <LayoutMain withContainer>
      <div className="tw-w-full tw-my-12">
        <i className="fal fa-7x fa-envelope-open tw-float-right tw-text-almostwhite tw-ml-12 tw-mb-12" />
        <h2>You&apos;ve been unsubscribed.</h2>
        <p className="lead">
          We&apos;re sorry to see you go, but no hard feelings.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu quam
          egestas, commodo turpis id, egestas velit. Vestibulum ante ipsum
          primis in faucibus orci luctus et ultrices posuere cubilia Curae;
          Suspendisse viverra ut arcu sed pharetra. Mauris tempus sodales metus,
          eu accumsan lorem auctor ut. In hac habitasse platea dictumst.{" "}
        </p>

        <div className="tw-mt-12">
          <a href="#" className="btn-skipti-primary tw-mr-2">
            Subscribe again
          </a>
          <a href="#" className="btn-skipti-secondary">
            Action
          </a>
        </div>
      </div>
    </LayoutMain>
  );
}
