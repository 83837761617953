import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import SignInForm from "./SignInForm";
import RegisterForm from "./RegisterForm";
import { getIsLoggingIn, getIsRegister } from "../../reducers/authReducer";
import SkiptiSpinner from "../skipti/Spinner";
import { showSnackbar } from "../../actions";
import ForgotPassword from "./ForgotPassword";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
  },
});

class SignInModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    isRegister: PropTypes.bool,
    classes: PropTypes.shape({
      paper: PropTypes.string.isRequired,
    }).isRequired,
    isPromotion: PropTypes.bool,
  };

  static defaultProps = {
    isRegister: false,
    isPromotion: false,
  };

  state = {
    formType: "signIn",
  };

  componentDidMount() {
    const { isRegister } = this.props;
    let formType = "signIn";
    if (isRegister) {
      formType = "register";
    }
    this.setState({
      formType,
    });
  }

  handleRegisterSwitch = () => {
    this.setState({ formType: "register" });
  };

  handleLogInSwitch = () => {
    this.setState({ formType: "signIn" });
  };

  handleForgotPasswordSwitch = () => {
    this.setState({ formType: "forgotPassword" });
  };

  render() {
    const { onClose, isFetching, classes, isPromotion } = this.props;
    const { formType } = this.state;
    return (
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%)`,
        }}
        tabIndex={-1}
        className={classes.paper}
      >
        <SkiptiSpinner loading={isFetching || false} opacity={0.4} />
        <div>
          {formType === "signIn" && (
            <SignInForm
              loading={isFetching || false}
              onClose={onClose}
              onRegisterClick={this.handleRegisterSwitch}
              onForgotPasswordClick={this.handleForgotPasswordSwitch}
            />
          )}
          {formType === "register" && (
            <RegisterForm
              loading={isFetching || false}
              onClose={onClose}
              onLogInClick={this.handleLogInSwitch}
              isPromotion={isPromotion}
            />
          )}
          {formType === "forgotPassword" && (
            <ForgotPassword
              loading={isFetching || false}
              onClose={onClose}
              onLogInClick={this.handleLogInSwitch}
              isPromotion={isPromotion}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: getIsLoggingIn(state),
  isRegister: getIsRegister(state),
});

export default connect(mapStateToProps, { showSnackbar })(
  withStyles(styles)(SignInModal)
);
