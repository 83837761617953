import * as TYPES from "../types";
import * as helpers from "../utils";

const organizationReducer = (state = {}, action) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.ORGANIZATION_INFO_BYID_REQUEST:
      return { ...state, infoById: helpers.makeStartLoading() };
    // ORGANIZATION_INFO_BYID_SUCCESS
    case TYPES.ORGANIZATION_INFO_BYID_SUCCESS:
      return { ...state, infoById: helpers.makeLoadSuccess(payload) };
    case TYPES.ORGANIZATION_INFO_BYID_FAIL:
      return { ...state, infoById: helpers.makeLoadFailed(error) };
    case TYPES.ORGANIZATION_INFO_BYNAME_REQUEST:
      return { ...state, infoByName: helpers.makeStartLoading() };
    // ORGANIZATION_INFO_BYNAME_SUCCESS
    case TYPES.ORGANIZATION_INFO_BYNAME_SUCCESS:
      return { ...state, infoByName: helpers.makeLoadSuccess(payload) };
    case TYPES.ORGANIZATION_INFO_BYNAME_FAIL:
      return { ...state, infoByName: helpers.makeLoadFailed(error) };
    case TYPES.ORGANIZATION_INFO_BYNAME_RESET:
      return { ...state, infoByName: null };
    default:
      return state;
  }
};

export const getOrganizationInfoById = (state) => state.organization.infoById;
export const getOrganizationInfoByName = (state) =>
  state.organization.infoByName;

export default organizationReducer;
