import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductsInRegion } from "../../actions";
import {
  getZip,
  getZipInfo,
  getProductsInRegion,
} from "../../reducers/itemsReducer";
import ItemTile from "./ItemTile";

export default function ProductsInRegion(props) {
  const dispatch = useDispatch();
  const { zip, noProducts, notResponsive, myItems } = props;
  const zipFromRedux = useSelector(getZip);
  const zipCode = zip || zipFromRedux;
  const products = useSelector(getProductsInRegion);
  const safeProducts = (products.data && products.data.resources) || [];

  useEffect(() => {
    if (zipCode) {
      dispatch(fetchProductsInRegion(zipCode));
    }

    // return () => {
    //   // cleanup
    // };
  }, [zipCode, dispatch]);
  return (
    <>
      {products &&
      products.isLoaded &&
      !products.isFetching &&
      safeProducts.length <= 0 ? (
        <div className="tw-mx-3">
          <h3>There are no products available in your region.</h3>
          <p>But you can browse products that are available in other regions</p>
        </div>
      ) : (
        <>
          {/* <div className="container-fluid my-5 px-3"> */}
          <h3 className="tw-my-4">Products in your region</h3>
          <div className="tw-flex tw-flex-wrap">
            {safeProducts.length > 0
              ? safeProducts.map((item, index) => (
                  <ItemTile
                    {...item}
                    index={index}
                    noOfProducts={noProducts}
                    key={`${item.resourceId}_${index}`}
                  />
                ))
              : Array(noProducts)
                  .fill({})
                  .map((item, index) => (
                    <ItemTile
                      {...item}
                      index={index}
                      notResponsive={notResponsive}
                      noOfProducts={noProducts}
                      myItems={myItems}
                      key={index}
                    />
                  ))}
          </div>
          {/* <h3>Here are some products that are available in other regions.</h3> */}
          {/* </div> */}
        </>
      )}
    </>
  );
}
