import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { stepsText, steps } from "../staticData";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import {
  getProductById,
  getProductsWithFilters,
} from "../../../../reducers/itemsReducer";
import { fetchProductsWithFilters } from "../../../../actions";
import Rating from "../../Rating";

const ProductsStep = ({
  changeHandler,
  currentStep = steps.unservicedAreaStep,
  isProcessing,
  values = {},
  errors = {},
  onSubmit,
  zip,
  toggle,
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const [stepProperties, setStepProperties] = useState(stepsText.zipCodeStep);
  const [resources, setResources] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [organizationName, setOrganizationName] = useState("");
  const productById = useSelector(getProductById);

  const history = useHistory();

  const products = useSelector(getProductsWithFilters);
  const dispatch = useDispatch();

  const getProducts = useCallback(() => {
    if (productById && productById.data) {
      if (
        products &&
        products.data &&
        products.data.totalPages === pageNumber
      ) {
        return null;
      }

      dispatch(
        fetchProductsWithFilters({
          organizationId: productById.data.organizationId,
          zipCode: zip,
          pagesize: 10,
          pagenumber: pageNumber + 1,
          isBundle: false,
          isBundled: false,
          ignoreReserved: false,
          includeCartItems: true,
          hasInventory: true,
        })
      );
      setPageNumber((_prev) => _prev + 1);
    }
  }, [productById, pageNumber, products]);

  useEffect(() => {
    setStepProperties(stepsText[currentStep]);
    getProducts();
  }, []);

  useEffect(() => {
    if (products && products.data && products.data.categories.length) {
      const _resources = products.data.categories
        .map((el) => el.resources)
        .flat();

      setResources((_prev) => [..._prev, ..._resources]);

      if (products.data.organizationName !== organizationName) {
        setOrganizationName(products.data.organizationName);
      }
    }
  }, [products]);

  const toggleEmail = () => {
    setShowEmail((_prev) => !_prev);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    console.log(bottom);
    if (bottom) {
      getProducts();
    }
  };

  const handleRedirect = (id) => {
    toggle(false);

    history.push(`/items/${id}`);
  };

  return (
    <div className="">
      <div className="tw-sticky">
        <div className="tw-w-full tw-border-pastelGrey tw-border-2 tw-bg-white tw-mb-5">
          <p className="tw-text-base tw-font-bold tw-p-3 tw-border-pastelGrey tw-border-b-2">
            Your product is not currently available
          </p>
          <div className="tw-p-3 tw-flex tw-items-center ">
            <img
              src={productById?.data.thumbnail}
              alt="Rog doctor pro"
              className="tw-w-20 tw-object-cover"
            />

            <p className="tw-self-start tw-font-bold">
              {productById?.data.productName}
              <br />
              <span className="tw-text-skiptiOrange tw-font-normal">
                Out of area
              </span>
            </p>
          </div>
        </div>

        {showEmail ? (
          <div className="tw-w-full tw-mb-5">
            <TextField
              placeholder={stepProperties.placeholder}
              onChange={changeHandler}
              fullWidth
              disabled={isProcessing}
              type={stepProperties.fieldType}
              name={stepProperties.fieldName}
              style={{
                marginBottom: "20px",
              }}
              value={values[stepProperties.fieldName]}
              error={errors[stepProperties.fieldName] !== undefined}
              helperText={errors[stepProperties.fieldName]}
            />

            <ButtonWithSpinner
              isDisabled={false}
              clickHandler={onSubmit}
              buttonText="NOTIFY ME WHEN AVAILABLE"
              isProcessed={isProcessing}
            />
          </div>
        ) : (
          <>
            <p
              className="tw-font-bold tw-text-skiptiOrange tw-underline tw-cursor-pointer tw-mb-5 tw-text-center"
              onClick={toggleEmail}
            >
              Email me when available
            </p>

            {!resources.length ? (
              <ButtonWithSpinner
                isDisabled={false}
                clickHandler={() => history.push(`/discover`)}
                buttonText="BROWSE OTHER PRODUCTS"
                isProcessed={isProcessing}
              />
            ) : null}
          </>
        )}
      </div>

      {resources.length ? (
        <div
          className="tw-overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 320px)" }}
          onScroll={handleScroll}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <p className="tw-text-base tw-mb-2 tw-text-center">
            Here are other popular {organizationName} rental options
          </p>
          <div className="tw-flex tw-justify-center tw-flex-wrap tw-max-w-5xl tw-w-full tw-overflow-scroll products_list tw-pb-8">
            {resources.map((el) => (
              <div
                key={el.resourceId}
                onClick={() => handleRedirect(el.resourceId)}
                className="tw-w-40 tw-cursor-pointer tw-flex tw-flex-col tw-justify-between "
              >
                <div className="tw-mb-3">
                  <img
                    src={el?.productPhotos[0]?.file ?? ""}
                    alt={el.productName}
                    className="tw-w-32 tw-object-cover "
                  />
                </div>
                <p className="tw-text-xl ">{el.productName}</p>
                <p className="tw-text-xl  tw-text-skiptiOrange">
                  {el.basePerDayUsd.toFixed(2)}
                </p>
                <Rating
                  rating={el.productRating}
                  readonly
                  className="tw-text-skiptiOrange"
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductsStep;
