import React from "react";
// import PropTypes from "prop-types";
import LayoutMain from "../layouts/LayoutMain";
import ExperienceRow from "../partials/ExperienceRow";

const experiences = [
  { id: "1", title: "Exp 1", cover: "" },
  { id: "2", title: "Exp 2", cover: "" },
];

export default function IndexExperience() {
  return (
    <LayoutMain withContainer>
      {experiences.map((experience) => (
        <ExperienceRow articleType={0} key={experience.id} {...experience} />
      ))}
    </LayoutMain>
  );
}
