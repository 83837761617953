import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import SearchIcon from "@mui/icons-material/Search";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useSelector } from "react-redux";
import {
  getIsLoggedIn,
  getUser,
  getUserRoles,
} from "../../reducers/authReducer";
import { userRoles } from "../../utils";

// Access checker

const superAdmin = {
  account: true,
  admin: true,
  dms: true,
  inventory: true,
  profile: true,
  changepassword: true,
  addresses: true,
  "payment-options": true,
  reviews: true,
  "admin-orders": true,
  "orders-for-today": true,
  "unified-calendar": true,
  customers: true,
  "admin-reports": true,
  overview: true,
  admin_articles: true,
  "dms-routes": true,
  "dms-drivers": true,
  "dms-teams": true,
  maps: true,
  quickInventory: true,
  "admin-depots": true,
  "service-records": true,
  // remove this after Inez says so
  "admin-drivers": true,
  policyPortal: true,
};

const admin = {
  account: true,
  admin: true,
  dms: true,
  inventory: true,
  profile: true,
  changepassword: true,
  addresses: true,
  "payment-options": true,
  reviews: true,
  "admin-orders": true,
  "orders-for-today": true,
  "unified-calendar": true,
  // partners: true,
  customers: true,
  "admin-reports": true,
  overview: false,
  "dms-routes": true,
  "dms-drivers": true,
  "dms-teams": true,
  maps: true,
  quickInventory: true,
  "admin-depots": true,
  "service-records": true,
  // remove this after Inez says so
  "admin-drivers": true,
  policyPortal: true,
};

const regionalManager = {
  account: true,
  admin: true,
  inventory: true,
  // myPartnerPage: true,
  profile: true,
  policyPortal: true,
  changepassword: true,
  addresses: true,
  reviews: true,
  "admin-orders": true,
  "orders-for-today": true,
  "unified-calendar": true,
  customers: true,
  overview: true,
  quickInventory: true,
  "admin-depots": true,
  "service-records": true,
  prodPortal: true,
  itemWizard: true,
};

const associate = {
  account: true,
  admin: true,
  inventory: true,
  sendLink: true,
  profile: true,
  accountProfile: true,
  policyPortal: true,
  changepassword: true,
  "admin-orders": true,
  "orders-for-today": true,
  "unified-calendar": true,
  customers: true,
  quickInventory: true,
  "admin-depots": true,
};

// TODO: this role is temporary to control access only for demos
// when we launch it, delete this role and put send-rental in all applicable other roles
const salesperson = {
  "send-rental": true,
  account: true,
  admin: true,
  dms: true,
  inventory: true,
  profile: true,
  changepassword: true,
  addresses: true,
  "payment-options": true,
  reviews: true,
  "admin-orders": true,
  "orders-for-today": true,
  "unified-calendar": true,
  customers: true,
  "admin-reports": true,
  overview: true,
  admin_articles: true,
  "dms-routes": true,
  "dms-drivers": true,
  "dms-teams": true,
  maps: true,
  quickInventory: true,
  "admin-depots": true,
  "service-records": true,
  // remove this after Inez says so
  "admin-drivers": true,
  policyPortal: true,
};

const useNavData = (
  handleSignOut,
  redirectForBusiness,
  accessChecker,
  setAccessChecker,
  openModal,
  openRegisterModal
) => {
  const history = useHistory();
  const roles = useSelector(getUserRoles);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const currentUser = useSelector(getUser);

  const mainNavData = useMemo(() => {
    const mainNavDataPrimary = [
      {
        id: 0,
        name: "marketplace",
        title: "Marketplace",
        class: "fas fa-briefcase",
        action: () => {
          history.push("/");
        },
        icon: () => <ShoppingBasketIcon sx={{ color: "white" }} />,
      },
      {
        id: 1,
        name: "discoverPage",
        title: "Discover",
        action: () => {
          history.push("/discover");
        },
        icon: () => <BusinessCenterIcon sx={{ color: "white" }} />,
      },
      {
        id: 2,
        name: "forBusiness",
        title: "For Business",
        icon: () => <SearchIcon sx={{ color: "white" }} />,
        action: () => {
          redirectForBusiness();
        },
      },
      {
        id: 3,
        name: "myDashboard",
        title: "My Dashboard",
        collapse: true,
        icon: () => <DashboardIcon sx={{ color: "white" }} />,
      },
      {
        id: 4,
        name: "auth",
        title: isLoggedIn ? "Sign Out" : "Log In",
        action: () => (isLoggedIn ? handleSignOut() : openModal()),
        icon: () =>
          isLoggedIn ? (
            <LogoutIcon sx={{ color: "white" }} />
          ) : (
            <LoginIcon sx={{ color: "white" }} />
          ),
      },
    ];

    if (!isLoggedIn) {
      return [
        ...mainNavDataPrimary.filter((item) => item.name !== "myDashboard"),
        {
          id: 5,
          name: "signUp",
          title: "Sign Up",
          action: () => openRegisterModal(),
          icon: () => <PersonAddAlt1Icon sx={{ color: "white" }} />,
        },
      ];
    }

    return mainNavDataPrimary;
  }, [isLoggedIn]);

  const getCurrentRole = () => {
    const _roles = roles.reduce(
      (acc, current) => ({
        ...acc,
        [current]: true,
      }),
      {}
    );
    const _current = userRoles.DefaultOwner;

    if (_roles[userRoles.Salesperson]) {
      return userRoles.Salesperson;
    }

    if (_roles[userRoles.SuperAdmin]) {
      return userRoles.SuperAdmin;
    }

    if (_roles[userRoles.SkiptiAdmin]) {
      return userRoles.SkiptiAdmin;
    }

    if (_roles[userRoles.PartnerAdmin]) {
      return userRoles.PartnerAdmin;
    }

    if (_roles[userRoles.Associate]) {
      return userRoles.Associate;
    }

    return _current;
  };

  const accessCheckerFunc = () => {
    const currentUserRole = getCurrentRole();
    switch (currentUserRole) {
      case userRoles.SuperAdmin:
        setAccessChecker((_prev) => ({
          ..._prev,
          sendLink: false,
          permissionsData: superAdmin,
        }));
        break;
      case userRoles.SkiptiAdmin:
        setAccessChecker((_prev) => ({
          ..._prev,
          sendLink: false,
          permissionsData: admin,
        }));
        break;
      case userRoles.PartnerAdmin:
        setAccessChecker((_prev) => ({
          ..._prev,
          dms: false,
          sendLink: false,
          permissionsData: regionalManager,
        }));
        break;
      case userRoles.Associate:
        setAccessChecker((_prev) => ({
          ..._prev,
          dms: false,
          permissionsData: associate,
        }));
        break;
      case userRoles.Salesperson:
        setAccessChecker((_prev) => ({
          ..._prev,
          permissionsData: salesperson,
        }));
        break;
      default:
        setAccessChecker((_prev) => ({
          ..._prev,
          dms: false,
          permissionsData: {},
        }));
    }
  };

  useEffect(() => {
    if (roles && roles.length) {
      accessCheckerFunc();
    }
  }, [roles]);

  // Routes

  const adminData = useMemo(
    () => [
      {
        id: 0,
        name: "admin-orders",
        title: "All Orders",
        action: () => {
          history.push("/dashboard/admin/adminorders");
        },
      },
      {
        id: 1,
        name: "orders-for-today",
        title: "Orders By Date",
        action: () => {
          history.push("/dashboard/admin/orders-for-today");
        },
      },
      {
        id: 2,
        name: "unified-calendar",
        title: "Order Calendar",
        action: () => {
          history.push("/dashboard/unified-calendar");
        },
      },
      // {
      //   id: 3,
      //   name: "partners",
      //   title: "Partners",
      //   action: () => null,
      // },
      {
        id: 4,
        name: "customers",
        title: "Customers",
        action: () => {
          history.push("/dashboard/admin/customers");
        },
      },
      {
        id: 5,
        name: "admin-reports",
        title: "Reports",
        action: () => {
          history.push("/dashboard/admin/reports");
        },
      },
      {
        id: 6,
        name: "overview",
        title: "Analytics",
        action: () => {
          history.push("/dashboard/");
        },
      },
      {
        id: 7,
        name: "admin_articles",
        title: "Articles",
        action: () => {
          history.push("/dashboard/articles");
        },
      },
    ],
    []
  );

  const dmsData = useMemo(
    () => [
      {
        id: 0,
        name: "dms-routes",
        title: "Routes",
        action: () => {
          history.push("/dashboard/admin/dms-routes");
        },
      },
      {
        id: 1,
        name: "dms-drivers",
        title: "Drivers",
        action: () => {
          history.push("/dashboard/admin/dms-drivers");
        },
      },
      {
        id: 2,
        name: "dms-teams",
        title: "Teams",
        action: () => {
          history.push("/dashboard/admin/dms-teams");
        },
      },
      // remove this after Inez says so
      {
        id: 3,
        name: "admin-drivers",
        title: "Zippy Drivers",
        action: () => {
          history.push("/dashboard/admin/admindrivers");
        },
      },
    ],
    []
  );
  const dashboardData = useMemo(
    () => [
      { id: 0, name: "account", title: "Account", isSingle: false },
      { id: 1, name: "admin", title: "Admin", isSingle: false },
      { id: 2, name: "dms", title: "DMS", isSingle: false },
      { id: 3, name: "inventory", title: "Inventory", isSingle: false },
      { id: 4, name: "sendLink", title: "Send a Link", isSingle: true },
    ],
    []
  );

  const accountData = useMemo(
    () => [
      // { id: 0, name: "myPartnerPage", title: "My Partner Page" },

      {
        id: 2,
        name: "upcomingOrder",
        title: "Upcoming Order",
        customer: true,
        disabled: !currentUser.upcomingOrderId,
        action: () => {
          history.push(`/dashboard/order/${currentUser.upcomingOrderId}`);
        },
      },
      {
        id: 3,
        name: "orders",
        title: "Order History",
        customer: true,
        action: () => {
          history.push("/dashboard/orders");
        },
      },
      {
        id: 4,
        name: "profile",
        title: "My Profile",
        customer: true,
        action: () => {
          history.push("/dashboard/profile");
        },
      },
      {
        id: 1,
        name: "policyPortal",
        title: "Policy Portal",
        action: () => {
          history.push("/dashboard/policyportal");
        },
      },
      {
        id: 5,
        name: "changepassword",
        title: "Change Password",
        customer: true,
        action: () => {
          history.push("/dashboard/changepassword");
        },
      },
      {
        id: 6,
        name: "addresses",
        title: "Addresses",
        customer: true,
        action: () => {
          history.push("/dashboard/addresses");
        },
      },
      {
        id: 7,
        name: "payment-options",
        title: "Payment Options",
        customer: true,
        action: () => {
          history.push("/dashboard/payment-options");
        },
      },
      {
        id: 8,
        name: "reviews",
        title: "Reviews",
        customer: true,
        action: () => {
          history.push("/dashboard/reviews/driver");
        },
      },
    ],
    [currentUser]
  );

  const inventoryData = useMemo(
    () => [
      {
        id: 1,
        name: "admin-depots",
        title: "Stores",
        action: () => {
          history.push("/dashboard/admin/admindepots");
        },
      },
      {
        id: 2,
        name: "service-records",
        title: "Store Service Records",
        action: () => {
          history.push("/dashboard/admin/service-records");
        },
      },
      {
        id: 3,
        name: "prodPortal",
        title: "Product Portal",
        action: () => {
          history.push("/dashboard/items");
        },
      },
      {
        id: 4,
        name: "itemWizard",
        title: "Item Wizard",
        action: () => {
          history.push("/items/new");
        },
      },
      {
        id: 5,
        name: "send-rental",
        title: "Send Rental",
        action: () => {
          history.push("/dashboard/send-rental");
        },
      },
    ],
    []
  );

  const orderOptionsData = useMemo(
    () => [
      {
        id: 1,
        name: "sendLink",
        title: "Send a Link",
        action: () => {
          history.push("/dashboard/admin/order-options");
        },
      },
    ],
    []
  );

  const innerDataGenerator = (dashboardName) => {
    switch (dashboardName) {
      case "account":
        return accountData.filter(
          (item) => accessChecker.permissionsData[item.name]
        );
      case "admin":
        return adminData.filter(
          (item) => accessChecker.permissionsData[item.name]
        );
      case "dms":
        return dmsData.filter(
          (item) => accessChecker.permissionsData[item.name]
        );
      case "inventory":
        return inventoryData.filter(
          (item) => accessChecker.permissionsData[item.name]
        );
      case "sendLink":
        const accessibleItems = orderOptionsData.filter(
          (item) => accessChecker.permissionsData[item.name]
        );

        return !!accessibleItems.length && orderOptionsData[0];

      default:
        return accountData.filter(
          (item) => accessChecker.permissionsData[item.name]
        );
    }
  };

  return {
    mainNavData,
    accountData,
    dashboardData,
    inventoryData,
    orderOptionsData,
    innerDataGenerator,
  };
};

export default useNavData;
