import moment from "moment";
import * as TYPES from "../types";
import * as helpers from "../utils";
import {
  ITEM_SERVICE_MODEL,
  ITEM_DATES_MODEL,
  experienceTypes,
} from "../actions/utils";

// const removeDeletedItem = (resourceId, productList) => {
//   if (productList && productList.resources)
//     productList.resources.filter((c) => c.resourceId !== resourceId);
// };

const initialState = {
  dateRange: { startDate: null, endDate: null },
  byCategory: helpers.makeInitial(),
  bySearch: helpers.makeInitial({ searchString: "" }),
  productsTrending: helpers.makeInitial(),
  productsByRecentlyUploaded: helpers.makeInitial(),
  productById: helpers.makeInitial(),
  byOwnerId: helpers.makeInitial(),
  productsVotable: helpers.makeInitial(),
  isAvailCalendarFetching: false,
  availCalendarById: [],
  availCalendarStartDate: null,
  availCalendarEndDate: null,
  selectedByOwner: undefined,
  isDeliveryWindowsFetching: false,
  isReturnWindowsFetching: false,
  size: "",
  lastRequestedSize: "",
  color: "",
  selectedAddOns: [],
  pricingInfo: {},
  zip: "",
  zipInfo: null,
  productsInRegion: helpers.makeInitial(),
  deliveryOptions: {},
  isFetchingDeliveryOptions: false,
  productsWithFilters: [],
  organizationProducts: [],
  organizationProductsCategories: [],
  productErrorMessage: "",
  isAvailabilityError: false,
  showProductsInPopup: false,
  zipAddress: null,
};

const mapDeliveryModels = (deliveryModels) => {
  let value = 2;
  // deliveryModels.some(d => ITEM_SERVICE_MODEL.REGULAR.includes(d));
  if (deliveryModels.some((d) => ITEM_SERVICE_MODEL.REGULAR.includes(d))) {
    value = 2;
  }
  if (deliveryModels.some((d) => ITEM_SERVICE_MODEL.REPAIR.includes(d))) {
    value = 3;
  }
  if (
    deliveryModels.some((d) => ITEM_SERVICE_MODEL.DELIVERY_ONLY.includes(d))
  ) {
    value = 4;
  }
  if (deliveryModels.some((d) => ITEM_SERVICE_MODEL.FETCH_ONLY.includes(d))) {
    value = 5;
  }
  if (deliveryModels.includes(6)) {
    value = 6;
  }
  // SUBSCRIPTION: [20, 21],
  if (deliveryModels.some((d) => ITEM_SERVICE_MODEL.SUBSCRIPTION.includes(d))) {
    value = 20;
  }
  // TRY_AND_BUY: [22],
  if (deliveryModels.includes(22)) {
    value = 22;
  }
  // TRANSPORT_ASSIST: [25],
  if (deliveryModels.includes(25)) {
    value = 25;
  }
  return value;
};

export default (state = initialState, action) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.SET_ITEM_PRICE:
      return { ...state, pricingInfo: payload };
    case TYPES.ADDON_SELECT:
      return {
        ...state,
        selectedAddOns: [...state.selectedAddOns, payload.id],
      };
    case TYPES.ADDON_REMOVE:
      return {
        ...state,
        selectedAddOns: [
          ...state.selectedAddOns.filter((addOnId) => addOnId !== payload.id),
        ],
      };
    case TYPES.ITEMS_NEEDS_SIZE:
      return { ...state, needsSize: payload };
    case TYPES.ITEMS_SIZE_CHANGE:
      return { ...state, size: payload };
    case TYPES.ITEMS_COLOR_CHANGE:
      return { ...state, color: payload };
    case TYPES.ITEMS_BYOWNERID_REQUEST:
      return { ...state, byOwnerId: helpers.makeStartLoading() };
    case TYPES.ITEM_DELETE_BYOWNER:
      return { ...state, selectedByOwner: undefined };
    case TYPES.ITEM_SELECT_BYOWNER:
      return { ...state, selectedByOwner: payload };
    case TYPES.PRODUCT_BYID_DELETE_SUCCESS:
      if (
        state.byOwnerId &&
        state.byOwnerId.data &&
        state.byOwnerId.data.resources
      ) {
        return {
          ...state,
          byOwnerId: {
            ...state.byOwnerId,
            data: {
              ...state.byOwnerId.data,
              // resources: removeDeletedItem(
              //   action.resourceId,
              //   state.byOwnerId.data
              // )
              resources: state.byOwnerId.data.resources.filter(
                (c) => c.resourceId !== action.resourceId
              ),
            },
          },
        };
      }
      return state;

    case TYPES.ITEMS_BYOWNERID_SUCCESS:
      return { ...state, byOwnerId: helpers.makeLoadSuccess(payload) };
    case TYPES.ITEMS_BYOWNERID_FAIL:
      return { ...state, byOwnerId: helpers.makeLoadFailed(error) };
    case TYPES.ITEMS_BYORGANIZATIONID_REQUEST:
      return { ...state, byOrganizationId: helpers.makeStartLoading() };
    case TYPES.ITEMS_BYORGANIZATIONID_SUCCESS:
      return { ...state, byOrganizationId: helpers.makeLoadSuccess(payload) };
    case TYPES.ITEMS_BYORGANIZATIONID_FAIL:
      return { ...state, byOrganizationId: helpers.makeLoadFailed(error) };

    // case TYPES.INVENTORY_BYPRODUCTID_REQUEST:
    //   return { ...state, productById: helpers.makeStartLoading() };
    // case TYPES.INVENTORY_BYPRODUCTID_SUCCESS:
    //   return {
    //     ...state,
    //     productById: {
    //       ...state.productById,
    //       data: {
    //         ...state.productById.data,
    //         inventory: payload,
    //       },
    //     },
    //   };
    // case TYPES.INVENTORY_BYPRODUCTID_FAIL:
    //   return { ...state, productById: helpers.makeLoadFailed(error) };

    case TYPES.PRODUCTS_BYCATEGORY_REQUEST:
      return { ...state, byCategory: helpers.makeStartLoading() };
    case TYPES.PRODUCTS_BYCATEGORY_SUCCESS:
      return { ...state, byCategory: helpers.makeLoadSuccess(payload) };
    case TYPES.PRODUCTS_BYCATEGORY_FAIL:
      return { ...state, byCategory: helpers.makeLoadFailed(error) };
    case TYPES.DATERANGE_SET_SUCCESS:
      return { ...state, dateRange: payload };
    case TYPES.DATERANGE_SET_START_SUCCESS:
      return {
        ...state,
        dateRange: { ...state.dateRange, startDate: payload },
      };
    case TYPES.DATERANGE_SET_END_SUCCESS:
      return { ...state, dateRange: { ...state.dateRange, endDate: payload } };
    case TYPES.CREATE_PRODUCT_REQUEST:
      return { ...state, createdProduct: helpers.makeStartLoading() };
    case TYPES.CREATE_PRODUCT_SUCCESS:
      return { ...state, createdProduct: helpers.makeLoadSuccess(payload) };
    case TYPES.CREATE_PRODUCT_FAIL:
      return { ...state, createdProduct: helpers.makeLoadFailed(error) };
    case TYPES.PRODUCT_BYID_REQUEST:
      return {
        ...state,
        productById: helpers.makeStartLoading(),
        availCalendarEndDate: null,
        availCalendarStartDate: null,
        availCalendarById: [],
        isAvailCalendarFetching: true,
        // dateRange: { startDate: null, endDate: null },
        color: "",
        size: "",
        lastRequestedSize: "",
        selectedAddOns: [],
      };
    case TYPES.PRODUCT_BYID_SUCCESS: {
      const lowerCaseProductName = payload.productName.toLowerCase();
      return {
        ...state,
        // productById: helpers.makeLoadSuccess(payload),
        productById: {
          ...helpers.makeLoadSuccess(payload),
          data: {
            ...payload,
            deliveryModel: mapDeliveryModels(payload.deliveryModels),
            // experienceTypeObject: Object.values(experienceTypes).find(
            //   (experienceType) =>
            //     experienceType.id ===
            //     (payload.experienceType || experienceTypes.Rental.id)
            // ),
          },
        },
        color: "",
        size: "",
        lastRequestedSize: "",
        pricingInfo: {
          basePerDayUsd: payload.basePerDayUsd,
          subsequentDayUsd: payload.subsequentDayUsd,
        },
      };
    }
    case TYPES.PRODUCT_BYID_ERROR: {
      return {
        ...state,
        productErrorMessage: payload,
      };
    }
    case TYPES.PRODUCT_BYID_FAIL:
      return { ...state, productById: helpers.makeLoadFailed(error) };
    case TYPES.PRODUCT_BYID_RESET:
      return {
        ...state,
        isAvailabilityError: false,
        productById: {
          ...state.productById,
          isFetching: false,
          isLoaded: false,
          isError: false,
          isInvalidated: false,
          data: null,
        },
      };
    case TYPES.AVAILABILITY_CALENDAR_FETCH_REQUEST:
      return {
        ...state,
        // availCalendarById: [], @todo: in second load going only one item, but it should be two
        isAvailCalendarFetching: payload.shouldShowLoading,
        availabilityError: null,
        isAvailabilityError: false,
      };
    case TYPES.AVAILABILITY_CALENDAR_FETCH_SUCCESS:
      return {
        ...state,
        availCalendarById:
          // payload.hasModifiedSize
          //   ? payload.events
          //   :[...state.availCalendarById, ...payload.events]
          [
            ...state.availCalendarById,
            ...payload.events.filter(
              (x) => !state.availCalendarById.includes(x)
            ),
          ],
        availCalendarStartDate: payload.startDate,
        availCalendarEndDate: payload.endDate,
        isAvailCalendarFetching: false,
        lastRequestedSize: payload.lastRequestedSize,
        lastRequestedZip: payload.lastRequestedZip,
        isAvailabilityError: false,
      };
    case TYPES.AVAILABILITY_CALENDAR_FETCH_FAIL:
      return {
        ...state,
        availCalendarById: [],
        isAvailabilityError: true,
        availabilityError: error,
        isAvailCalendarFetching: false,
      };
    case TYPES.AVAILABILITY_CALENDAR_FETCH_RESET:
      return {
        ...state,
        availCalendarById: [],

        availabilityError: null,
        isAvailabilityError: false,
      };

    case TYPES.PRODUCT_BYSEARCH_REQUEST:
      return {
        ...state,
        bySearch: helpers.makeStartLoading({
          searchString: action.searchString,
          searchCategory: action.searchCategory,
        }),
      };
    case TYPES.PRODUCT_BYSEARCH_SUCCESS:
      return {
        ...state,
        bySearch: helpers.makeLoadSuccess(payload, {
          searchString: action.searchString,
          searchCategory: action.searchCategory,
        }),
      };
    case TYPES.PRODUCT_BYSEARCH_FAIL:
      return {
        ...state,
        bySearch: helpers.makeLoadFailed(error, {
          searchString: action.searchString,
          searchCategory: action.searchCategory,
        }),
      };
    case TYPES.PRODUCTS_BYRECENTLYVIEWED_REQUEST:
      return { ...state, productsByRecentlyViewed: helpers.makeStartLoading() };
    case TYPES.PRODUCTS_BYRECENTLYVIEWED_SUCCESS:
      return {
        ...state,
        productsByRecentlyViewed: helpers.makeLoadSuccess(payload),
      };
    case TYPES.PRODUCTS_BYRECENTLYVIEWED_FAIL:
      return {
        ...state,
        productsByRecentlyViewed: helpers.makeLoadFailed(error),
      };
    case TYPES.PRODUCTS_TRENDING_REQUEST:
      return {
        ...state,
        productsTrending: {
          ...state.productsTrending,
          ...helpers.makeStartLoading(),
        },
      };
    case TYPES.PRODUCTS_TRENDING_SUCCESS:
      return { ...state, productsTrending: helpers.makeLoadSuccess(payload) };
    case TYPES.PRODUCTS_TRENDING_FAIL:
      return { ...state, productsTrending: helpers.makeLoadFailed(error) };

    case TYPES.PRODUCTS_FEATURED_REQUEST:
      return { ...state, productsFeatured: helpers.makeStartLoading() };
    case TYPES.PRODUCTS_FEATURED_SUCCESS:
      return { ...state, productsFeatured: helpers.makeLoadSuccess(payload) };
    case TYPES.PRODUCTS_FEATURED_FAIL:
      return { ...state, productsFeatured: helpers.makeLoadFailed(error) };

    case TYPES.PRODUCTS_BYRECENTLYUPLOADED_REQUEST:
      return {
        ...state,
        productsByRecentlyUploaded: helpers.makeStartLoading(),
      };
    case TYPES.PRODUCTS_BYRECENTLYUPLOADED_SUCCESS:
      return {
        ...state,
        productsByRecentlyUploaded: helpers.makeLoadSuccess(payload),
      };
    case TYPES.PRODUCTS_BYRECENTLYUPLOADED_FAIL:
      return {
        ...state,
        productsByRecentlyUploaded: helpers.makeLoadFailed(error),
      };
    case TYPES.PRODUCTS_FORALLCATEGORIES_REQUEST:
      return {
        ...state,
        productsForAllCategories: helpers.makeStartLoading(),
      };
    case TYPES.PRODUCTS_FORALLCATEGORIES_SUCCESS:
      return {
        ...state,
        productsForAllCategories: helpers.makeLoadSuccess(payload),
      };
    case TYPES.PRODUCTS_FORALLCATEGORIES_FAIL:
      return {
        ...state,
        productsForAllCategories: helpers.makeLoadFailed(error),
      };
    case TYPES.PRODUCTS_FORALLCATEGORIES_BYORG_REQUEST:
      return {
        ...state,
        productsForAllCategoriesByOrg: helpers.makeStartLoading(),
      };
    case TYPES.PRODUCTS_FORALLCATEGORIES_BYORG_SUCCESS:
      return {
        ...state,
        productsForAllCategoriesByOrg: helpers.makeLoadSuccess(payload),
      };
    case TYPES.PRODUCTS_FORALLCATEGORIES_BYORG_FAIL:
      return {
        ...state,
        productsForAllCategoriesByOrg: helpers.makeLoadFailed(error),
      };
    case TYPES.PRODUCTS_VOTABLE_REQUEST:
      return { ...state, productsVotable: helpers.makeStartLoading() };
    case TYPES.PRODUCTS_VOTABLE_SUCCESS:
      return { ...state, productsVotable: helpers.makeLoadSuccess(payload) };
    case TYPES.PRODUCTS_VOTABLE_FAIL:
      return { ...state, productsVotable: helpers.makeLoadFailed(error) };
    // case TYPES.DELIVERY_WINDOWS_REQUEST:
    //   return {
    //     ...state,
    //     deliveryWindows: helpers.makeStartLoading(),
    //     isDeliveryWindowsFetching: true
    //   };
    // case TYPES.DELIVERY_WINDOWS_SUCCESS:
    //   return {
    //     ...state,
    //     deliveryWindows: helpers.makeLoadSuccess(payload),
    //     isDeliveryWindowsFetching: false
    //   };
    // case TYPES.DELIVERY_WINDOWS_FAIL:
    //   return { ...state, deliveryWindows: helpers.makeLoadFailed(error) };
    // case TYPES.RETURN_WINDOWS_REQUEST:
    //   return {
    //     ...state,
    //     returnWindows: helpers.makeStartLoading(),
    //     isReturnWindowsFetching: true
    //   };
    // case TYPES.RETURN_WINDOWS_SUCCESS:
    //   return {
    //     ...state,
    //     returnWindows: helpers.makeLoadSuccess(payload),
    //     isReturnWindowsFetching: false
    //   };
    // case TYPES.RETURN_WINDOWS_FAIL:
    //   return { ...state, returnWindows: helpers.makeLoadFailed(error) };
    case TYPES.DELIVERY_OPTIONS_BY_ZIP_REQUEST:
      return {
        ...state,
        deliveryOptions: {},
        isFetchingDeliveryOptions: true,
        availabilityError: null,
        // isAvailabilityError: false,
      };
    case TYPES.DELIVERY_OPTIONS_BY_ZIP_SUCCESS:
      return {
        ...state,
        deliveryOptions: payload,
        isFetchingDeliveryOptions: false,
        isAvailabilityError: false,
      };
    case TYPES.DELIVERY_OPTIONS_BY_ZIP_FAIL:
      return {
        ...state,
        deliveryOptions: {},
        isFetchingDeliveryOptions: false,
        isAvailabilityError: true,
        availabilityError: error,
      };
    case TYPES.DELIVERY_OPTIONS_BY_ZIP_RESET:
      return {
        ...state,
        deliveryOptions: {},
        isFetchingDeliveryOptions: false,
        isAvailabilityError: false,
        availabilityError: null,
      };
    case TYPES.DELIVERY_OPTIONS_BY_GPS_REQUEST:
      return { ...state, isFetchingDeliveryOptions: true };
    case TYPES.DELIVERY_OPTIONS_BY_GPS_SUCCESS:
      return {
        ...state,
        deliveryOptions: payload,
        isFetchingDeliveryOptions: false,
      };
    case TYPES.DELIVERY_OPTIONS_BY_GPS_FAIL:
      return {
        ...state,
        deliveryOptions: {},
        isFetchingDeliveryOptions: false,
      };
    case TYPES.DELIVERY_WINDOWS_INVENTORY_BY_ZIP_REQUEST:
      return {
        ...state,
        unifiedDeliveryWindows: helpers.makeStartLoading(),
        deliveryWindows: helpers.makeStartLoading(),
        returnWindows: helpers.makeStartLoading(),
        isDeliveryWindowsFetching: true,
      };
    case TYPES.DELIVERY_WINDOWS_INVENTORY_BY_ZIP_SUCCESS:
      return {
        ...state,
        unifiedDeliveryWindows: helpers.makeLoadSuccess(payload),
        deliveryWindows: helpers.makeLoadSuccess(
          payload.deliveryWindows.delivery_windows
        ),
        returnWindows: helpers.makeLoadSuccess(
          payload.returnWindows ? payload.returnWindows.fetch_windows : []
        ),
        isDeliveryWindowsFetching: false,
        isRentable: payload.isRentable,
      };
    case TYPES.DELIVERY_WINDOWS_INVENTORY_BY_ZIP_FAIL:
      return {
        ...state,
        unifiedDeliveryWindows: helpers.makeLoadFailed(error),
        deliveryWindows: helpers.makeLoadFailed(error),
        returnWindows: helpers.makeLoadFailed(error),
      };
    case TYPES.DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_REQUEST:
      return {
        ...state,
        unifiedDeliveryWindows: helpers.makeStartLoading(),
        deliveryWindows: helpers.makeStartLoading(),
        returnWindows: helpers.makeStartLoading(),
        isDeliveryWindowsFetching: true,
      };
    case TYPES.DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_SUCCESS:
      return {
        ...state,
        zip: payload.zipPostal,
        unifiedDeliveryWindows: helpers.makeLoadSuccess(payload),
        deliveryWindows: helpers.makeLoadSuccess(
          payload.deliveryWindows.delivery_windows
        ),
        returnWindows: helpers.makeLoadSuccess(
          payload.returnWindows.fetch_windows
        ),
        isDeliveryWindowsFetching: false,
        isRentable: payload.isRentable,
      };
    case TYPES.DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_FAIL:
      return {
        ...state,
        unifiedDeliveryWindows: helpers.makeLoadFailed(error),
        deliveryWindows: helpers.makeLoadFailed(error),
        returnWindows: helpers.makeLoadFailed(error),
      };
    case TYPES.ALL_PRODUCT_TEASERS_REQUEST:
      return { ...state, productsFeatured: helpers.makeStartLoading() };
    case TYPES.ALL_PRODUCT_TEASERS_SUCCESS:
      return {
        ...state,
        productsFeatured: helpers.makeLoadSuccess(payload.productsFeatured),
        productsVotable: helpers.makeLoadSuccess(payload.productsVotable),
        productsByRecentlyUploaded: helpers.makeLoadSuccess(
          payload.productsByRecentlyUploaded
        ),
        productsTrending: helpers.makeLoadSuccess(payload.productsTrending),
        productsByRecentlyViewed: helpers.makeLoadSuccess(
          payload.productsByRecentlyViewed
        ),
      };
    case TYPES.ALL_PRODUCT_TEASERS_FAIL:
      return { ...state, productsFeatured: helpers.makeLoadFailed(error) };
    case TYPES.SET_ZIP:
      return { ...state, zip: payload };
    case TYPES.SET_ZIP_ADDRESS:
      return { ...state, zipAddress: payload };
    case TYPES.RESET_ZIP:
      return { ...state, zip: "" };
    case TYPES.RESET_ZIP_ADDRESS:
      return { ...state, zipAddress: null };
    case TYPES.ZIP_INFO_REQUEST:
      return {
        ...state,
        zipInfo: helpers.makeStartLoading(),
        availabilityError: null,
        // isAvailabilityError: false,
      };
    case TYPES.ZIP_INFO_SUCCESS:
      return {
        ...state,
        zipInfo: helpers.makeLoadSuccess(payload),
      };
    case TYPES.ZIP_INFO_FAIL:
      return {
        ...state,
        zipInfo: helpers.makeLoadFailed(error),
        isAvailabilityError: true,
        availabilityError: error,
      };
    case TYPES.PRODUCTS_IN_REGION_REQUEST:
      return { ...state, productsInRegion: helpers.makeStartLoading() };
    case TYPES.PRODUCTS_IN_REGION_SUCCESS:
      return { ...state, productsInRegion: helpers.makeLoadSuccess(payload) };
    case TYPES.PRODUCTS_IN_REGION_FAIL:
      return { ...state, productsInRegion: helpers.makeLoadFailed(error) };

    case TYPES.PRODUCT_ADD_ONS_BY_ZIP_REQUEST:
      return {
        ...state,
        productById: {
          ...state.productById,
          data: {
            ...state.productById.data,
            addOns: [],
          },
          isLoadedAddOns: false,
        },
      };
    case TYPES.PRODUCT_ADD_ONS_BY_ZIP_SUCCESS:
      return {
        ...state,
        productById: {
          ...state.productById,
          data: {
            ...state.productById.data,
            addOns: payload,
          },
          isLoadedAddOns: true,
        },
      };
    case TYPES.PRODUCT_ADD_ONS_BY_ZIP_FAIL:
      return {
        ...state,
        productById: {
          ...state.productById,
          data: {
            ...state.productById.data,
            addOns: [],
          },
          isLoadedAddOns: false,
        },
      };

    case TYPES.PROFILED_RESOURCES_FOR_PRODUCT_REQUEST:
      return {
        ...state,
        profiledResourcesForProduct: helpers.makeStartLoading(),
      };
    case TYPES.PROFILED_RESOURCES_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        profiledResourcesForProduct: helpers.makeLoadSuccess(payload),
      };
    case TYPES.PROFILED_RESOURCES_FOR_PRODUCT_FAIL:
      return {
        ...state,
        profiledResourcesForProduct: helpers.makeLoadFailed(error),
      };

    case TYPES.PRODUCTS_WITH_FILTERS_REQUEST:
      return {
        ...state,
        productsWithFilters: helpers.makeStartLoading(),
      };
    case TYPES.PRODUCTS_WITH_FILTERS_SUCCESS:
      return {
        ...state,
        productsWithFilters: helpers.makeLoadSuccess(payload),
      };
    case TYPES.PRODUCTS_WITH_FILTERS_FAIL:
      return {
        ...state,
        productsWithFilters: helpers.makeLoadFailed(error),
      };

    case TYPES.PRODUCTS_WITH_FILTERS_RESET:
      return {
        ...state,
        productsWithFilters: null,
      };

    // Organization products (SMS_BOOKING)
    case TYPES.ORGANIZATION_PRODUCTS_REQUEST:
      return {
        ...state,
        organizationProducts: helpers.makeStartLoading(),
      };
    case TYPES.ORGANIZATION_PRODUCTS_SUCCESS:
      return {
        ...state,
        organizationProducts: helpers.makeLoadSuccess(payload),
      };
    case TYPES.ORGANIZATION_PRODUCTS_FAIL:
      return {
        ...state,
        organizationProducts: helpers.makeLoadFailed(error),
      };
    case TYPES.ORGANIZATION_PRODUCTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        organizationProductsCategories: payload,
      };
    case TYPES.SHOW_PRODUCTS_IN_POPUP:
      return {
        ...state,
        showProductsInPopup: true,
      };
    case TYPES.HIDE_PRODUCTS_IN_POPUP:
      return {
        ...state,
        showProductsInPopup: false,
      };
    case TYPES.ORGANIZATION_PRODUCTS_RESET:
      return {
        ...state,
        organizationProducts: [],
        organizationProductsCategories: [],
      };
    default:
      return state;
  }
};

export const getItemPricingInfo = (state) => state.items.pricingInfo;
export const getDeliveryOptions = (state) => state.items.deliveryOptions;

export const getProductErrorMessage = (state) =>
  state.items.productErrorMessage;

export const getSearchString = (state) => state.items.bySearch.searchString;
export const getSearchCategory = (state) => state.items.bySearch.searchCategory;
export const getSelectedByOwner = (state) => state.items.selectedByOwner;
export const getIsAvailCalendarFetching = (state) =>
  state.items.isAvailCalendarFetching;
export const getAvailCalendarStartDate = (state) =>
  state.items.availCalendarStartDate;
export const getAvailCalendarEndDate = (state) =>
  state.items.availCalendarEndDate;
export const getAvailCalendar = (state) =>
  state.items.availCalendarById.filter((event) => event.entryType === 0); // deprecated
export const getBlackedOutEvents = (state) =>
  state.items.availCalendarById.filter((event) => event.entryType === 9); // reserved event
export const getSpannableCalendar = (state) =>
  state.items.availCalendarById.filter((event) => event.entryType === 10); // spannable not selectable events
export const getItems = (state) => state.items.byOwnerId;
export const getIsFetchingById = (state) => state.items.productById.isFetching;
export const getItemsBySearch = (state) => state.items.bySearch;
export const getProductsByCategory = (state) => state.items.byCategory;
export const getProductsByOrganization = (state) =>
  state.items.byOrganizationId;
export const getCreatedProduct = (state) => state.items.createdProduct;
export const getDateRange = (state) => state.items.dateRange;
export const getSize = (state) => state.items.size;
export const getLastRequestedSize = (state) => state.items.lastRequestedSize;
export const getLastRequestedZip = (state) => state.items.lastRequestedZip;
export const getColor = (state) => state.items.color;
export const getProductById = (state) => state.items.productById;
export const getProductsByRecentlyViewed = (state) =>
  state.items.productsByRecentlyViewed;
export const getProductsTrending = (state) => state.items.productsTrending;
export const getProductsFeatured = (state) => state.items.productsFeatured;
export const getProductsVotable = (state) => state.items.productsVotable;
export const getProductsByRecentlyUploaded = (state) =>
  state.items.productsByRecentlyUploaded;
export const getProductsForAllCategories = (state) =>
  state.items.productsForAllCategories;
export const getMappedItem = (state) =>
  state.items.productById.isLoaded && {
    ...state.items.productById.data,
    photos: state.items.productById.data.productPhotos.map((photo, index) => ({
      ...photo,
      isUploaded: true,
      // fromAmazon: true,
      src: photo.file,
      height: photo.heightPixels,
      width: photo.widthPixels,
      fullHeight: photo.heightPixels,
      fullWidth: photo.widthPixels,
      type: photo.photoType,
      name: photo.caption || `photo-${index}.jpg`,
      index,
    })),
    productCategory: state.items.productById.data.productCategories[0]
      ? state.items.productById.data.productCategories[0].id
      : null,
    rules: state.items.productById.data.rules.map((rule) => ({
      key: rule,
      value: rule,
      label: rule,
    })),
    included: state.items.productById.data.includes
      ? state.items.productById.data.includes.map((inc) => ({
          key: inc,
          value: inc,
          label: inc,
        }))
      : [],
    tags: state.items.productById.data.tags
      ? state.items.productById.data.tags.map((tag) => ({
          key: tag,
          value: tag,
          label: tag,
        }))
      : [],
    youtubeVideoUrl:
      state.items.productById.data.productVideos.length > 0
        ? state.items.productById.data.productVideos[0].file
        : "",
    age_num: state.items.productById.data.productNew
      ? moment().diff(state.items.productById.data.productNew, "years", false)
      : "",
    // we should display product inches not Centimeters
    // heightInches: Math.ceil(
    //   state.items.productById.data.heightCentimeters / 2.54
    // ),
    // widthInches: Math.ceil(
    //   state.items.productById.data.widthCentimeters / 2.54
    // ),
    // depthInches: Math.ceil(
    //   state.items.productById.data.depthCentimeters / 2.54
    // )
    heightInches: state.items.productById.data.heightInches,
    widthInches: state.items.productById.data.widthInches,
    depthInches: state.items.productById.data.depthInches,
  };
export const getDeliveryWindows = (state) => state.items.deliveryWindows;
export const getReturnWindows = (state) => state.items.returnWindows;
export const getIsFetchingDeliveryOptions = (state) =>
  state.items.isFetchingDeliveryOptions;
export const getZip = (state) => state.items.zip;
export const getZipAddress = (state) => state.items.zipAddress;
export const getIsDeliveryWindowsFetching = (state) =>
  state.items.isDeliveryWindowsFetching;
export const getIsReturnWindowsFetching = (state) =>
  state.items.isReturnWindowsFetching;
export const getUnifiedDeliveryWindows = (state) =>
  state.items.unifiedDeliveryWindows;
export const getIsRentable = (state) => state.items.isRentable;
export const shouldSelectSize = (state) =>
  state.items.productById &&
  state.items.productById.data &&
  state.items.productById.data.sizes.length > 0;
export const shouldSelectColor = (state) =>
  state.items.productById &&
  state.items.productById.data &&
  state.items.productById.data.colors.length > 0;
export const getItemNeedsSize = (state) =>
  state.items.productById.isLoaded &&
  state.items.productById.data.hasAttributes;
export const getProductByIdHasAttributes = (state) =>
  state.items.productById &&
  state.items.productById.isLoaded &&
  state.items.productById.data.hasAttributes;
export const getProductId = (state) =>
  state.items.productById &&
  state.items.productById.isLoaded &&
  state.items.productById.data.resourceId;
export const getSizeOptions = (state) => {
  return (
    state.items.productById &&
    state.items.productById.isLoaded &&
    state.items.productById.data &&
    state.items.productById.data.attributes
  );
};
export const getProductDeliveryModals = (state) => {
  if (state.items.productById && state.items.productById.data) {
    return state.items.productById.data.deliveryModels;
  }
  return [];
};
export const getInventoryForProductId = (state) =>
  state.items.productById.inventory;

export const getZipInfo = (state) => state.items.zipInfo;

export const getProductsInRegion = (state) => state.items.productsInRegion;

export const getIsLoadedAddons = (state) =>
  state.items.productById && state.items.productById.isLoadedAddOns;

export const getProfiledResourcesForProduct = (state) =>
  state.items.profiledResourcesForProduct;

export const getIsAvailabilityError = (state) =>
  state.items.isAvailabilityError;
export const getAvailabilityError = (state) => state.items.availabilityError;
export const getProductsForAllCategoriesByOrg = (state) =>
  state.items.productsForAllCategoriesByOrg;

export const getProductsWithFilters = (state) =>
  state.items.productsWithFilters;

export const getProductInStoreLocation = (state) =>
  state.items.productById && state.items.productById.data?.storeLocation
    ? state.items.productById.data?.storeLocation
    : {};
export const getOrganizationProducts = (state) =>
  state.items.organizationProducts;

export const getOrganizationProductsCategories = (state) =>
  state.items.organizationProductsCategories;

export const showProductsInPopup = (state) => state.items.showProductsInPopup;
