import React, { Component } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";

export default function ItemTileSkeleton(props) {
  const { isDiscover } = props;
  return (
    <>
      <Skeleton
        variant="rect"
        width="100%"
        height={isDiscover ? 200 : 150}
        style={{ borderRadius: "5px" }}
        // style={{
        //   minHeight: "149px",
        //   // minWidth: "143px",
        //   height: "100%"
        // }}
      />
      <div style={{ height: "8px" }} />
      <Skeleton
        variant="rect"
        width="30%"
        // style={{
        //   minHeight: "17px",
        //   height: "100%"
        // }}
      />
      <div style={{ height: "8px" }} />
      <Skeleton
        variant="rect"
        width="70%"
        // style={{
        //   minHeight: "24.55px",
        //   height: "100%"
        // }}
      />
      <div style={{ height: "8px" }} />
      <Skeleton
        variant="rect"
        width="50%"
        // style={{
        //   minHeight: "19px",
        //   height: "100%"
        // }}
      />
      <div style={{ height: "8px" }} />
      <Skeleton
        variant="rect"
        width="40%"
        // style={{
        //   minHeight: "15px",
        //   height: "100%"
        // }}
      />
    </>
  );
}
