import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withWidth } from "@material-ui/core";
import Carousel from "react-slick";
import { isWidthDown } from "@material-ui/core/withWidth";
import strings from "../../strings.json";
import { getCityName } from "../../reducers/authReducer";
import { imageServerUrl } from "../../utils";
import SocialShareButtons from "./SocialShareButtons";
import { getIsFromRugDoctor } from "../../reducers/userReducer";

class HeroLoggedIn extends Component {
  static getDerivedStateFromProps = (props, state) => ({
    box1: strings[props.city].mainLoggedIn.box1,
    box2: strings[props.city].mainLoggedIn.box2,
  });

  state = { box1: {}, box2: {} };

  render() {
    const { box1, box2 } = this.state;
    const { width } = this.props;
    const carouselSettings = {
      // arrows: true,
      infinite: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      // centerPadding: "60px",
      focusOnSelect: false,
      variableWidth: false,
      adaptiveHeight: false,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
      beforeChange: (current, next) =>
        this.setState({
          // oldSlide: current,
          // activeSlide: next,
        }),
      // afterChange: current => this.setState({ activeSlide2: current })
    };
    return (
      <div
        className={
          isWidthDown("sm", this.props.width)
            ? "tw-w-full tw-mx-auto skipti-hero tw-text-white tw-mb-12"
            : "tw-w-full tw-mx-auto skipti-hero tw-text-white tw-px-4 tw-mb-12"
        }
        style={{
          paddingLeft: isWidthDown("sm", this.props.width) ? "0px" : "1rem",
          paddingRight: isWidthDown("sm", this.props.width) ? "0px" : "1rem",
        }}
      >
        <div className="tw-flex tw-mx-0 tw-flex-wrap">
          <div className="md:tw-w-8/12 tw-w-full tw-px-0 md:tw-px-4 left-panel">
            <Carousel {...carouselSettings} className="">
              {box1.map((box, index) =>
                !this.props.isFromRugDoctor && index === 0 ? null : (
                  <div key={`box1-${index}`}>
                    <div
                      className="tw-p-12"
                      style={{
                        background: `url(${imageServerUrl}/${
                          width === "xs" ? box.imageMobile : box.image
                        }) bottom left /cover no-repeat`,
                        height: "507px",
                      }}
                    >
                      <div
                        className="tw-mb-4 tw-mt-12"
                        dangerouslySetInnerHTML={{ __html: box.text }}
                      />
                      {/* <p className="pb-5">Coastal summer, in a whole new way.</p> */}
                    </div>
                  </div>
                )
              )}
            </Carousel>
          </div>
          <div
            // style={{
            //   paddingLeft: isWidthDown("sm", this.props.width) ? "0px" : "15px",
            //   paddingRight: isWidthDown("sm", this.props.width)
            //     ? "0px"
            //     : "15px",
            // }}
            className="md:tw-w-4/12 tw-px-0 md:tw-px-4 right-panel"
          >
            <div className="tw-bg-skiptiOrange tw-text-white tw-p-12 tw-h-full">
              <h3 className="tw-mb-4 tw-mt-1 tw-text-2xl">{box2.text1}</h3>
              {box2.text2 !== "" ? (
                <h3 className="tw-mb-4 tw-mt-1">{box2.text2}</h3>
              ) : null}
              <p className="tw-mb-4">{box2.text3}</p>
              <SocialShareButtons whiteBG={false} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  city: getCityName(state),
  isFromRugDoctor: getIsFromRugDoctor(state),
});

export default withWidth()(connect(mapStateToProps)(HeroLoggedIn));
