import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import VideoCover from "react-video-cover";
import Modal from "@material-ui/core/Modal";
import WOW from "wowjs";
import { Player, ControlBar } from "video-react";
import "video-react/dist/video-react.css";

let wow = null;

// const image = "https://dummyimage.com/871x688/ccc/fff&text=+";
// const textHtml = `<h2 class="tw-text-skiptiOrange">Snap, post, earn.<br>No meetups required.</h2>
// <p class="text-color4">And sit quo. Aut voluptas tenetur and consequatur dolorem and sed occaecati fugit. Nobis nostrum and whoever who.</p>
// <a href="#" class="btn-skipti-primary mr-3">Primary</a>`;

class FeaturetteVideo extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    wow = new WOW.WOW();
    wow.init();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.video !== prevProps.video) {
      wow.sync();
    }
  }

  componentWillUnmount() {
    wow = null;
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      // type: 0 - image, 1-video
      <div className="tw-mx-auto xl:tw-my-12 tw-container">
        <div
          className={`featurette ${
            this.props.right ? "tw-text-right" : "tw-w-3/4"
          } xl:tw-mb-12 wow fadeIn`}
          data-wow-duration="2.5s"
        >
          {this.props.type === 1 && this.props.video ? (
            // <div onClick={this.openModal}>
            <Player playsInline src={this.props.video} autoPlay muted>
              <ControlBar disableCompletely />
            </Player>
          ) : // </div>
          null}

          <div
            className={`text-container ${this.props.left ? "left" : ""} ${
              this.props.right ? "right tw-text-left" : ""
            } tw-bg-white tw-w-1/4 tw-p-12 wow slideInUp`}
          >
            {this.props.children}
            {this.props.showPlayButton ? (
              <>
                <br />
                <button
                  type="button"
                  onClick={this.openModal}
                  className="btn-skipti-primary"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-play" />
                  &nbsp; Play
                </button>
              </>
            ) : null}
          </div>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.isModalOpen}
            onClose={this.closeModal}
            onClick={this.closeModal}
          >
            <div>
              <button
                type="button"
                className="float-right tw-text-lightgrey"
                style={{
                  // color: "white",
                  border: "none",
                  background: "none",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  zIndex: "500",
                  cursor: "pointer",
                }}
                onClick={this.closeModal}
              >
                <i className="fas fa-times" />
              </button>
              <Player
                playsInline
                src={this.props.videoHD}
                autoPlay
                muted={false}
              >
                <ControlBar disableCompletely />
              </Player>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
export default FeaturetteVideo;
