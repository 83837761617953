import React from "react";

const TransportUnavailableOverlay = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "84%",
        opacity: "0.85",
        backgroundColor: "white",
        border: "1px solid #ccc",
        zIndex: "10",
      }}
      className="tw-flex tw-justify-center tw-items-center "
    >
      <div className="tw-flex tw-justify-center tw-items-center">
        <div className="tw-grid tw-grid-cols-1">
          <img
            src="/images/transport-assist-unavailable.svg"
            alt="transport unavailable"
          />
          <span className="transport-unavailable-text">
            Transport Assist Unavailable
          </span>{" "}
        </div>
      </div>
    </div>
  );
};

export default TransportUnavailableOverlay;
