import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MLink from "@mui/material/Link";
import {
  Modal,
  Fab,
  Typography,
  Tooltip,
  TextField,
  MenuItem,
  Switch,
} from "@material-ui/core";
import _ from "lodash";

import { styled } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import SkiptiButton from "../skipti/Button";
import SkiptiRating from "./Rating";
import ItemCarousel from "./ItemCarousel";
import EditDeleteItemButtons from "../items/EditDeleteItemButtons";
import VoteItemButtons from "../items/VoteItemButtons";
import { imageServerUrl } from "../../utils";
import ItemTileSkeleton from "../items/skeleton/ItemTileSkeleton";
import "./css/ItemTile.css";
import EditProfileButtons from "./EditProfileButtons";
import OptionalServicePopper from "./OptionalServicePopper";
import OptionalServiceDisablePopper from "./OptionalServiceDisablePopper";

class ItemTile extends Component {
  static propTypes = {
    resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productName: PropTypes.string,
    basePerDayUsd: PropTypes.number,
    productPhotos: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        file: PropTypes.string,
      })
    ),
    isBundle: PropTypes.bool,
    editMode: PropTypes.bool,
    votingMode: PropTypes.bool,
    productRating: PropTypes.number,
    isFeatured: PropTypes.bool,
    productCategoryName: PropTypes.string,
    numberOfReviews: PropTypes.number,
    experienceId: PropTypes.number,
    notResponsive: PropTypes.bool,
    isDiscover: PropTypes.bool,
    disableSelectItems: PropTypes.bool,
  };

  static defaultProps = {
    editMode: false,
    votingMode: false,
    notResponsive: false,
    isDiscover: false,
  };

  state = {
    areYouSureModalOpen: false,
    optionalServiceDetails: false,
    optionalServicePrice:
      this.props.quantity * this.props.optionalService?.price,
    optionalServiceDisable: false,
  };

  setOptionalServiceDetails = () => {
    if (!this.state.optionalServiceDetails) {
      this.props.damageMixpanelEvent();
    }

    this.setState((state) => ({
      optionalServiceDetails: !state.optionalServiceDetails,
    }));
  };

  setOptionalServiceDisable = () => {
    this.setState((state) => ({
      optionalServiceDisable: !state.optionalServiceDisable,
    }));
  };

  handleDelete = () => () => {
    this.setState({
      areYouSureModalOpen: true,
    });
  };

  deleteItem = async () => {
    this.setState({
      areYouSureModalOpen: false,
    });
    this.props.deleteItem(this.props.resourceId);
  };

  closeModal = () => {
    this.setState({ areYouSureModalOpen: false });
  };

  voteItem = async () => {
    this.setState({
      areYouSureModalOpen: false,
    });
    this.props.castVote(
      this.props.resourceId,
      this.props.productName,
      this.props.productCategoryName,
      this.props.basePerDayUsd
    );
  };

  render() {
    const {
      id,
      noOfProducts,
      productName,
      resourceId,
      basePerDayUsd,
      productRating,
      productPhotos,
      isBundle,
      inBundle,
      editMode,
      votingMode,
      isFeatured,
      productCategoryName,
      numberOfReviews,
      experienceId,
      notResponsive,
      votesToAcquire,
      isDiscover,
      deliveryOnly,
      hidePrice,
      hideVoting,
      demoMode,
      onCheck,
      checkMode,
      delMode,
      openDetails,
      isActive,
      pricingLabel,
      basePerWeekUsd,
      basePerMonthUsd,
      editProfileMode,
      deleteProfile,
      handleEditProfile,
      duplicateProfile,
      mobileColumns,
      pageNumber,
      pageSize,
      hideSelectButton = false,
      showQuantity = false,
      maxAvailable,
      showRetailPrice = false,
      retailUsd,
      optionalService = null,
      transportOnly = true,
      hasInventory,
      isAppointment = false,
      disableSelectItems,
    } = this.props;

    const quantitiesArray = _.range(1, maxAvailable + 1);

    let colClass = "";
    switch (noOfProducts) {
      case 1:
        colClass = "tw-w-full";
        break;
      case 2:
        colClass = "tw-w-1/2";
        break;
      case 3:
        colClass = " xl:tw-w-1/3  sm:tw-w-1/2  px-4";
        break;
      case 4:
        colClass = "xl:tw-w-1/4 lg:tw-w-1/3 md:tw-w-1/3 sm:tw-w-1/2  px-4";
        break;
      case 6:
        colClass = "xl:tw-w-1/6 lg:tw-w-1/4 md:tw-w-1/3 sm:tw-w-1/2  px-4";
        break;
      default:
        colClass = "xl:tw-w-1/4 lg:tw-w-1/4 md:tw-w-1/3 sm:tw-w-1/2  px-4 ";

        break;
    }

    if (noOfProducts > 2 && mobileColumns === 1) {
      colClass = colClass.concat(" tw-w-full ");
    } else {
      colClass = colClass.concat(" tw-w-1/2 ");
    }

    const hideColClass = "";

    const AddFABButton = styled(Fab)({
      backgroundColor: "white",
      color: "#F05623",
      border: "2px solid #F05623",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    });

    return (
      <>
        <div
          className={`${
            notResponsive === false ? colClass : `col-${12 / noOfProducts}`
          } ${
            notResponsive === true && noOfProducts < 6 ? "" : hideColClass
          } tw-bg-white tw-my-2 tw-px-4`}
        >
          <div className="item-tile tw-relative">
            {!productName ? (
              <ItemTileSkeleton isDiscover={isDiscover} />
            ) : (
              <div>
                {(checkMode || delMode) &&
                  !hideSelectButton &&
                  (transportOnly || hasInventory || isAppointment) && (
                    <div
                      onClick={demoMode ? onCheck : () => {}}
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        zIndex: 1,
                      }}
                    >
                      {checkMode && !disableSelectItems && (
                        <AddFABButton size="small">
                          <AddIcon />
                        </AddFABButton>
                      )}
                      {delMode && (
                        <Fab size="small" color="primary">
                          <CloseIcon />
                        </Fab>
                      )}
                    </div>
                  )}
                <ItemCarousel
                  demoMode={demoMode}
                  productId={resourceId}
                  images={productPhotos}
                  transportOnly={transportOnly}
                  hasInventory={hasInventory}
                  isAppointment={isAppointment}
                />

                <ul className="tw-float-right tw-list-none tw-pl-0 tw-mt-3">
                  {experienceId !== null &&
                    experienceId !== 0 &&
                    typeof experienceId !== "undefined" && (
                      <li className="tw-inline-block">
                        <Tooltip placement="top" title="Experience">
                          <Link
                            to={`/articles/${experienceId}`}
                            href={`/articles/${experienceId}`}
                          >
                            <img
                              src={`${imageServerUrl}/tile-ico-experience.svg`}
                              className="experience tw-w-5 tw-h-5 tw-align-middle tw-border-none"
                              alt=""
                            />
                          </Link>
                        </Tooltip>
                      </li>
                    )}
                  {isBundle && isBundle === true && (
                    <li className="tw-inline-block">
                      <a href="#">
                        <img
                          src={`${imageServerUrl}/tile-ico-bundle.svg`}
                          className="bundle tw-w-5 tw-h-5 tw-align-middle tw-border-none"
                          alt=""
                        />
                      </a>
                    </li>
                  )}
                  {isFeatured && isFeatured === true && (
                    <li className="tw-inline-block">
                      <a href="#">
                        <img
                          src={`${imageServerUrl}/tile-ico-featured.svg`}
                          className="featured tw-w-5 tw-h-5 tw-align-middle tw-border-none"
                          alt=""
                        />
                      </a>
                    </li>
                  )}
                </ul>
                {demoMode ? (
                  <div>
                    <p
                      style={{ marginBottom: "0px", marginTop: "6px" }}
                      className="item-title tw-text-offblack"
                    >
                      <span title={productName}>{productName}</span>
                    </p>
                    {!hidePrice && (
                      <p className="tw-text-xl tw-font-hnRegular tw-mb-px tw-text-gray-600">
                        <span className="tw-text-orange-500">
                          {showRetailPrice && retailUsd
                            ? `$${retailUsd.toFixed(2)}`
                            : basePerDayUsd &&
                              typeof basePerDayUsd !== "undefined" &&
                              basePerDayUsd > 0
                            ? `$${basePerDayUsd.toFixed(2)}`
                            : ""}
                        </span>
                      </p>
                    )}

                    {editProfileMode ? (
                      <div>
                        <EditProfileButtons
                          resourceId={resourceId}
                          deleteProfile={deleteProfile}
                          handleEditProfile={handleEditProfile}
                          duplicateProfile={duplicateProfile}
                        />
                      </div>
                    ) : (
                      <div className="tw-flex tw-flex-nowrap">
                        <p className="item-title text-black mb-1 tw-w-1/2">
                          {openDetails && (
                            <Typography
                              variant="caption"
                              onClick={openDetails}
                              color="primary"
                              className="skipti-as-link"
                            >
                              Details
                            </Typography>
                          )}
                        </p>
                        {showQuantity && transportOnly && (
                          //  && maxAvailable > 1
                          <div className="tw-w-1/2">
                            <div
                              style={{
                                position: "relative",
                                // bottom: `${hidePrice ? "-25px" : "0px"}`,
                                right: "0",
                                float: "right",
                              }}
                            >
                              <TextField
                                fullWidth
                                style={{ width: "50px" }}
                                margin="none"
                                label="Qty"
                                placeholder="Qty"
                                name="quantity"
                                value={this.props.quantity}
                                select
                                onChange={(event) => {
                                  this.props.onQuantityChange(
                                    event.target.value,
                                    id
                                  );
                                  this.setState((_prev) => ({
                                    optionalServicePrice:
                                      event.target.value *
                                      this.props.optionalService?.price,
                                  }));
                                }}
                              >
                                {quantitiesArray &&
                                  quantitiesArray.map((loc) => (
                                    <MenuItem key={loc} value={loc}>
                                      {loc}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    to={`/items/${resourceId}`}
                    href={`/items/${resourceId}`}
                  >
                    <p className="tw-leading-6 tw-text-gray-500 tw-mt-4 tw-mb-2 tw-text-xs tw-tracking-wider tw-uppercase tw-font-normal tw-font-hnRegular">
                      {productCategoryName}
                    </p>
                    <p className="tw-text-offblack tw-text-xl tw-leading-5 tw-mb-1 tw-font-hnLight">
                      <span title={productName}>{productName}</span>
                    </p>
                    {!hidePrice && (
                      <p className="tw-text-xl tw-font-hnRegular tw-mb-px tw-text-gray-600">
                        <span className="tw-text-orange-500">
                          {showRetailPrice && retailUsd
                            ? `$${retailUsd.toFixed(2)}`
                            : basePerDayUsd &&
                              typeof basePerDayUsd !== "undefined" &&
                              basePerDayUsd > 0
                            ? `$${basePerDayUsd.toFixed(2)}`
                            : basePerWeekUsd && basePerWeekUsd > 0
                            ? `$${basePerWeekUsd.toFixed(2)}`
                            : basePerMonthUsd && basePerMonthUsd > 0
                            ? `$${basePerMonthUsd.toFixed(2)}`
                            : ""}
                        </span>{" "}
                        <span className="tw-text-sm">{pricingLabel}</span>
                      </p>
                    )}

                    {!hideVoting &&
                      (!votingMode && votingMode !== true ? (
                        <p className="tw-text-xs tw-text-orange-500">
                          <SkiptiRating rating={productRating} readonly />
                          <span className="tw-text-orange-500 tw-ml-2 tw-mt-px tw-relative">
                            ({numberOfReviews})
                          </span>
                        </p>
                      ) : (
                        <p className="tw-text-xl tw-mb-px tw-text-lightgrey">
                          <span>
                            {votesToAcquire === 1
                              ? "1 vote"
                              : `${votesToAcquire} votes`}{" "}
                          </span>
                        </p>
                      ))}
                  </Link>
                )}
                {/* {showQuantity && (
                  //  && maxAvailable > 1
                  <div className="tw-mt-3">
                    <div
                      style={{
                        position: "absolute",
                        bottom: `${hidePrice ? "-25px" : "0px"}`,
                        right: "0",
                      }}
                    >
                      <TextField
                        fullWidth
                        style={{ width: "50px" }}
                        margin="normal"
                        label="Qty"
                        placeholder="Qty"
                        name="quantity"
                        value={this.props.quantity}
                        select
                        onChange={(event) => {
                          this.props.onQuantityChange(event.target.value);
                          this.setState((_prev) => ({
                            optionalServicePrice:
                              event.target.value *
                              this.props.optionalService?.price,
                          }));
                        }}
                      >
                        {quantitiesArray &&
                          quantitiesArray.map((loc) => (
                            <MenuItem key={loc} value={loc}>
                              {loc}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                  </div>
                )} */}
              </div>
            )}
          </div>
          {editMode && editMode === true && (
            <EditDeleteItemButtons
              resourceId={resourceId}
              isActive={isActive}
              orderType={
                deliveryOnly
                  ? "deliver"
                  : isBundle || inBundle
                  ? "demo"
                  : "rent"
              }
            />
          )}
          {votingMode && votingMode === true && (
            <>
              <hr className="tw-my-4 tw-border-0 tw-border-t tw-border-solid" />
              <VoteItemButtons
                product={{
                  productId: resourceId,
                  productName,
                  productCategory: productCategoryName,
                  productPrice: basePerDayUsd,
                }}
                pageNumber={pageNumber}
                pageSize={pageSize}
                resourceId={resourceId}
                castVote={this.voteItem}
                short
              />
            </>
          )}
          {optionalService && optionalService.title && (
            <>
              <hr className="tw-my-1 tw-border-0 tw-border-t tw-border-solid" />
              <div className="tw-flex ">
                <div>
                  <MLink
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      textDecoration: "underline",
                      width: "136px",
                    }}
                    onClick={(event) => {
                      const { currentTarget } = event;

                      this.setState({
                        anchorEl: currentTarget,
                      });
                      this.setOptionalServiceDetails();
                    }}
                    component="button"
                  >
                    {optionalService.title}
                  </MLink>

                  <span className="tw-self-center">
                    ${this.state.optionalServicePrice.toFixed(2)} / night
                  </span>
                </div>
                <Switch
                  color={optionalService.selected ? "primary" : "secondary"}
                  checked={optionalService.selected}
                  className="tw-self-center"
                  onChange={(event) => {
                    this.props.onDamageProtectionChange(event.target.value);
                    if (!event.target.checked) {
                      this.setState({
                        anchorElDisable: event.currentTarget,
                      });
                      this.setOptionalServiceDisable();
                    } else if (this.state.optionalServiceDisable) {
                      this.setOptionalServiceDisable();
                    }
                  }}
                />
              </div>
              <OptionalServicePopper
                isOpen={this.state.optionalServiceDetails}
                anchorEl={this.state.anchorEl}
                handleChange={this.setOptionalServiceDetails}
                title={optionalService.title}
                description={optionalService.description}
              />
              <OptionalServiceDisablePopper
                isOpen={this.state.optionalServiceDisable}
                anchorEl={this.state.anchorElDisable}
                handleChange={this.setOptionalServiceDisable}
                title={optionalService.titleDisable}
                description={optionalService.descriptionDisable}
                onProceed={this.setOptionalServiceDisable}
                onCancel={() => {
                  this.setOptionalServiceDisable();
                  this.props.onDamageProtectionChange();
                }}
              />
            </>
          )}
        </div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.areYouSureModalOpen}
          onClose={this.closeModal}
        >
          <div
            className="tw-max-w-screen-md tw-relative tw-mx-auto tw-m-8"
            role="document"
            tabIndex={-1}
          >
            <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md">
              <div className="tw-relative tw-flex-1 tw-p-16">
                Are you sure you want to delete the item?
                <div className="tw-text-center tw-mt-4">
                  <SkiptiButton
                    design="secondary dialog"
                    className="tw-mr-2"
                    onClick={this.closeModal}
                    style={{ marginRight: "1rem" }}
                  >
                    No
                  </SkiptiButton>

                  <SkiptiButton
                    design="primary dialog"
                    onClick={this.deleteItem}
                  >
                    Yes
                  </SkiptiButton>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default ItemTile;
