import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LayoutCategory from "./layouts/LayoutCategory";
import { getCategories } from "../reducers/categoriesReducer";
import { fetchCategories, selectItemByOwner, openLogInModal } from "../actions";

// import imageTest from "../img/ownershero.jpg";
import HomeCalculator from "./partials/HomeCalculator";
import Featurette from "./partials/Featurette";
import CategoryTile from "./partials/CategoryTile";
import AmazonSearch from "./skipti/AmazonSearch";
import OwnersFAQ from "./partials/OwnersFAQ";
import { imageServerUrl } from "../utils";

class Owners extends Component {
  static propTypes = {
    openLogInModal: PropTypes.func.isRequired,
  };

  state = {
    money: 7000,
  };

  componentDidMount() {
    this.props.fetchCategories();
  }

  onChangeBundleImage(value) {
    this.setState({
      money: value,
    });
  }

  itemSelect = (item, isCustom) => {
    this.props.selectItemByOwner(item);
    this.props.history.push("/items/new");
  };

  render() {
    const categoryTiles = [
      {
        title: "Instruments",
        description:
          "Acoustic and electrics guitars, bass, keyboards, electronic drum sets",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0005_Layer 12.jpg`,
      },
      {
        title: "Recording & Accessories",
        description: "Microphones, effects pedals, mixers",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0006_Layer 11.jpg`,
      },
      {
        title: "Headphones and Speakers",
        description:
          "Studio and noise canceling headphones, large bluetooth speakers, amplifiers and speaker",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0007_Layer 10.jpg`,
      },
      {
        title: "Camping, Hiking, & Climbing Gear",
        description:
          "Tents, grills, sleeping bags, pads, coolers, hammocks, lanterns, power supplies",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0004_Layer 13.jpg`,
      },
      {
        title: "Water Sports",
        description:
          "Paddle boards, kayaks, surf boards, rafts, water skis, wakeboards",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0013_Layer 4.jpg`,
      },
      {
        title: "High End Sporting Goods",
        description:
          "Golf clubs, carbon fiber bikes, tennis racquets, hockey gear, lacrosse gear",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0003_Layer 14.jpg`,
      },
      {
        title: "Kitchen Appliences",
        description:
          "Kitchen aids, blenders, pasta makers, juicers, food processors, grinders, waffle makers, donut makers, ice cream makers, popcorn makers, espresso makers",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0008_Layer 9.jpg`,
      },
      {
        title: "Mechanic & Auto Tools",
        description:
          "Wrench sets, ratchet sets, hex sets, vises, lights, floor jackets, circuit testers, diagnostics tools",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0010_Layer 7.jpg`,
      },
      {
        title: "Home Tools",
        description:
          "Drills, dremels, saws, power washers, rototillers, gras seeder, gardening set, carpet cleaner, wet vac, shop vac, generators",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0009_Layer 8.jpg`,
      },
      {
        title: "Mens Wear",
        description: "Designer shoes, watches belts, sunglasses",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0016_Layer 1.jpg`,
      },
      {
        title: "Women's Fashion",
        description: "Designer shoes, jewelery, purses, and clothes",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0002_Layer 16.jpg`,
      },
      {
        title: "Baby & Travel",
        description: "Car seats, strollers, luggage, monitors, travel crib",
        image: `${imageServerUrl}/categorytiles/babyProducts.png`,
      },
      {
        title: "Cameras",
        description: "DSLR, SLR cameras and action cameras such as GoPros",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0011_Layer 6.jpg`,
      },
      {
        title: "Lenses, Mounts, and Stands",
        description:
          "Camera lenses, mounts, stands, carry cases, stabilizers, microphones, and filters",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0015_Layer 2.jpg`,
      },
      {
        title: "Aerial",
        description: "Drones and carry cases",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0012_Layer 5.jpg`,
      },
      {
        title: "Electric Transportation",
        description: "Electric bikes, skateboards, scooters",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0000_featurettessized_0002_Layer 8.jpg`,
      },
      {
        title: "Accesories",
        description: "Power banks",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0001_omnicharge-power-bank-charger-01.jpg`,
      },
      {
        title: "Ipads & Mobile Tech",
        description: "...",
        image: `${imageServerUrl}/categorytiles/owneritemcategories_0014_Layer 3.jpg`,
      },
    ];
    const bundleImagesA = [
      {
        title: "Tech Jetsetter BundleA",
        image: `${imageServerUrl}/bundles/TechA.jpg`,
        money: 7000,
      },

      {
        title: "Outdoorsy BundleA",
        image: `${imageServerUrl}/bundles/OutdoorsA.jpg`,
        money: 5000,
      },
    ];
    const bundleImagesB = [
      {
        title: "Tech Jetsetter BundleB",
        image: `${imageServerUrl}/bundles/TechB.jpg`,
      },
      {
        title: "Outdoorsy BundleB",
        image: `${imageServerUrl}/bundles/OutdoorsB.jpg`,
      },
    ];
    const settingsCategories = {
      arrows: true,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      focusOnSelect: false,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 2500,
          settings: {
            slidesToShow: 3,
            arrows: true,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            arrows: true,
          },
        },
        {
          breakpoint: 922,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };
    const settingsBundles = {
      arrows: true,
      beforeChange: (current, next) => {
        this.sliderA.slickGoTo(next);
        this.sliderB.slickGoTo(next);
        this.onChangeBundleImage(bundleImagesA[next].money);
      },
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      rows: 1,
      slidesPerRow: 1,
      centerPadding: "60px",
      focusOnSelect: false,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
          },
        },
      ],
    };
    return (
      <LayoutCategory showSearch>
        <Parallax
          blur={0}
          bgImage={`${imageServerUrl}/ownershero.jpg`}
          bgImageAlt="category.displayName"
          bgStyle={{ top: "-50%" }}
          strength={850}
          className="parallax-window p-5"
        >
          <div>
            <div
              className="row"
              style={{
                left: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <div className="col-xl-6 col-lg-6 col-sm-12 col-12 center-block text-center">
                <h1
                  className="display-1 text-uppercase text-white fw-bold"
                  style={{ marginTop: "200px" }}
                >
                  <strong>OWNERS</strong>
                </h1>

                <h3 className="pb-5 text-white">
                  Use your stuff when you need it,
                  <br />
                  earn money from it when you don&apos;t.
                </h3>
              </div>

              <HomeCalculator
                removeMarginTop
                title="Owners can earn an average of $7000+ per year"
              />
            </div>
          </div>
        </Parallax>

        <Featurette
          type={0}
          left
          image={`${imageServerUrl}/featurettes/featurettessized_0007_owneroncomp.jpg`}
        >
          <>
            <h2 className="text-color2">
              Become An Owner
              <br />
              Snap, Post, Earn.
            </h2>
            <p className="text-color4">
              Start making money by renting out the things you already own. All
              you have to do is name your price and tell us when it&apos;s
              available, we&apos;ll handle the rest. We know, pretty great,
              right?
            </p>
            <br />
            <button
              type="button"
              className="btn-skipti-secondary mr-3"
              ref={(button1) => {
                if (button1) {
                  button1.style.setProperty("color", "#007bff", "important");
                }
              }}
              style={{
                borderColor: "#007bff",
                backgroundColor: "#fff",
              }}
              onClick={() => this.props.openLogInModal(true)}
            >
              Sign Up
            </button>
          </>
        </Featurette>

        <Featurette
          type={0}
          right
          image={`${imageServerUrl}/featurettes/featurettessized_0006_Layer 3.jpg`}
        >
          <>
            <h2 className="text-color2">
              Door to door delivery <br />
              Fully insured
            </h2>
            <p className="text-color4">
              Skipti professionally delivers your items door to door so you
              never have to leave the comfort of your home. We insure against
              breakage, theft and injury so you can rest easy.
            </p>
            <br />
            <Link
              to="/items/new"
              href="/items/new"
              className="btn-skipti-secondary mr-3"
              innerRef={(button2) => {
                if (button2) {
                  button2.style.setProperty("color", "#007bff", "important");
                }
              }}
              style={{
                borderColor: "#007bff",
                backgroundColor: "#fff",
              }}
            >
              Try posting an item
            </Link>
          </>
        </Featurette>
        <div className="container my-5">
          <div className="center-block text-center my-3 px-5">
            <h3
              className="text-color2"
              style={{ cssFloat: "none", margin: "0 auto" }}
            >
              What should I post?
            </h3>
          </div>
          <div className="center-block text-center my-3 px-5">
            <p className="text-color4">
              The average Skipti item retails for $250, but there are no price
              restrictions. Items must be able to be carried by one person and
              generally should not be irreplaceble.
            </p>
          </div>
        </div>

        <div className="container my-5">
          <Carousel {...settingsCategories} className="">
            {categoryTiles.map((tile, index) => (
              <CategoryTile tile={tile} key={index} />
            ))}
          </Carousel>
        </div>
        <div className="container my-5">
          <div
            className=""
            style={{ position: "absolute", marginTop: "30px", zIndex: "-1" }}
          >
            <img
              src={`${imageServerUrl}/arrow-test-it-out-optimized.png`}
              alt="test it arrow"
            />
            <br />
            <span
              className="text-color2"
              style={{ fontFamily: "Pacifico, cursive", fontSize: "2em" }}
            >
              <img
                src={`${imageServerUrl}/test-it-out.png.png`}
                alt="test it out"
                style={{ width: "4em" }}
              />
            </span>
          </div>
          <div
            style={{ marginLeft: "80px", marginBottom: "80px", zIndex: "2" }}
          >
            <AmazonSearch
              label="Try typing in an item you own"
              itemSelect={this.itemSelect}
            />
          </div>
        </div>

        <div className="container my-5">
          <div className="center-block text-center my-3 px-5">
            <h3
              className="text-color2"
              style={{ cssFloat: "none", margin: "0 auto" }}
            >
              Bundle & Earn
            </h3>
          </div>
          <div className="center-block text-center my-3 px-5">
            <p className="text-color4">
              Things are better together. After you upload your first item,
              curate a bundle and start earning more per rental. No one knows
              how to use your stuff better than you.
            </p>
          </div>
        </div>

        <div className="container ">
          <Carousel
            ref={(c) => (this.sliderA = c)}
            {...settingsBundles}
            className=""
          >
            {bundleImagesA.map((tile, index) => (
              <img
                src={tile.image}
                key={`imageBundle-${index}`}
                alt={tile.title}
              />
            ))}
          </Carousel>
        </div>

        <div className="container mt-5">
          <div className="row justify-content-md-center text-color2">
            <div className="col col-md-5 col-sm-12 col-12">
              <div style={{ textAlign: "center" }}>
                <h2>
                  Owner Earns{" "}
                  <strong>
                    <u>${this.state.money}</u>
                  </strong>{" "}
                  <span style={{ fontSize: "24px" }}>/ year</span>
                </h2>
              </div>
            </div>
            <div
              className="col col-md-5 col-sm-12 col-12"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                marginTop: "10px",
              }}
            >
              <Link
                to="/items/new"
                href="/items/new"
                className="btn-skipti-inverse disabled"
                style={{
                  color: "#fff !important",
                  borderColor: "#007bff",
                  backgroundColor: "#007bff",
                }}
              >
                Post Your First Item
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <Carousel
            ref={(c) => (this.sliderB = c)}
            {...settingsBundles}
            className=""
          >
            {bundleImagesB.map((tile, index) => (
              <img
                src={tile.image}
                key={`imageBundle-${index}`}
                alt={tile.title}
              />
            ))}
          </Carousel>
        </div>

        <Featurette
          type={0}
          left
          image={`${imageServerUrl}/featurettes/featurettessized_0003_Layer 7.jpg`}
        >
          <>
            <h2 className="text-color2">Scheduling & Availability</h2>
            <p className="text-color4">
              You can always refuse an incoming request to rent your items and
              our drivers can even meet you at work. We optimize around your
              schedule.
            </p>
            <br />
            <button
              type="button"
              className="btn-skipti-secondary mr-3"
              ref={(node) => {
                if (node) {
                  node.style.setProperty("color", "#007bff", "important");
                }
              }}
              style={{
                borderColor: "#007bff",
                backgroundColor: "#fff",
              }}
              onClick={() => this.props.openLogInModal(true)}
            >
              Set Up Your account
            </button>
          </>
        </Featurette>

        <div className="container my-5 pt-5">
          <div className="center-block text-center my-3 px-5">
            <h3
              className="text-color2"
              style={{ cssFloat: "none", margin: "0 auto" }}
            >
              FAQ
            </h3>
          </div>
          <div className="center-block text-center my-3 px-5">
            <p className="text-color4">Still wondering how this all works?</p>
          </div>
          <OwnersFAQ />
        </div>
      </LayoutCategory>
    );
  }
}

export default connect(null, {
  fetchCategories,
  openLogInModal,
  selectItemByOwner,
})(Owners);
