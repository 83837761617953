export const SuperAdmin = (el) => /^((SUPER-ADMIN))$/.test(el);

export const PartnerAdmin = (el) => /^(PARTNER-ADMIN)$/.test(el);

export const SkiptiAdmin = (el) => /^((SKIPTI-ADMIN))$/.test(el);

export const AssociateAdmin = (el) => /^((DEPOT-TECH))$/.test(el);

export const SkiptiAndPartnerAdmin = (el) =>
  /^((PARTNER-ADMIN)|(SUPER-ADMIN)|(SKIPTI-ADMIN))$/.test(el);

export const SkiptiPartnerMaintenanceAdmin = (el) =>
  /^((PARTNER-ADMIN)|(SUPER-ADMIN)|(SKIPTI-ADMIN))$/.test(el);

export const SkiptiAssociateAdmin = (el) =>
  /^((DEPOT-TECH)|(SUPER-ADMIN)|(SKIPTI-ADMIN))$/.test(el);

export const SuperSkiptiAdmin = (el) =>
  /^((SUPER-ADMIN)|(SKIPTI-ADMIN))$/.test(el);

export const SuperPartnerAdmin = (el) =>
  /^((SUPER-ADMIN)|(PARTNER-ADMIN))$/.test(el);

export const AllPartnerAdmins = (el) =>
  /^((PARTNER-ADMIN)|(DEPOT-TECH))$/.test(el);

export const AllAdmins = (el) =>
  /^((SUPER-ADMIN)|(SKIPTI-ADMIN)|(PARTNER-ADMIN)|(DEPOT-TECH))$/.test(el);

export const Customer = (el) => /^((DEFAULT-OWNER)|(DEFAULT-RENTER))$/.test(el);
