import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import Input from "@material-ui/core/Input";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ThemeProvider, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import MixpanelContext from "react-mixpanel";
import { customTheme } from "./RegisterForm";
import {
  authenticate,
  setSignInMessage,
  fetchAddressesByUserId,
} from "../../actions";
import {
  getIsLoggingIn,
  getSignInErrors,
  getSignInMessage,
} from "../../reducers/authReducer";
import { imageServerUrl } from "../../utils";

import SocialLogins from "./SocialLogins";
import SkiptiModal from "../skipti/SkiptiModal";
import ForgotPassword from "./ForgotPassword";

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  }),
});

const removeRememberedUser = () => {
  localStorage.removeItem("skpitiJWT");
  localStorage.removeItem("skiptiUserEmail");
  localStorage.removeItem("skiptiUserAvatar");
};
const initialState = {
  avatar: "",
  email: "",
  displayName: "",
};

class SignInForm extends Component {
  static contextType = MixpanelContext;

  state = {
    avatar: "",
    email: "",
    displayName: "",
    showLoginForm: false,
    showPassword: false,
    openForgotPasswordModal: false,
  };

  componentDidMount() {
    this.setState({
      avatar: localStorage.getItem("skiptiUserAvatar"),
      email: localStorage.getItem("skiptiUserEmail"),
      displayName: localStorage.getItem("skiptiUserName"),
    });
  }

  handleResetRememberedUser = (resetForm) => () => {
    resetForm();
    removeRememberedUser();
    this.setState(initialState);
  };

  authenticate = async (values, source) => {
    const hasLoadingFunc = !!this.props.setLoading;
    if (hasLoadingFunc) {
      this.props.setLoading(true);
    }
    const success = await this.props.authenticate(
      values,
      this.context,
      window.analytics,
      source
    );
    if (success) {
      this.props.fetchAddressesByUserId();
      if (this.props.onClose) {
        this.props.onClose();
      }
      if (this.props.onSuccessLogin) {
        if (hasLoadingFunc) {
          this.props.setLoading(false);
        }
        this.props.onSuccessLogin();
      }
    } else if (hasLoadingFunc) {
      this.props.setLoading(false);
    }
  };

  handleSubmit = async (values) => {
    this.authenticate(values, "email");
  };

  handleClickShowPassword = () => {
    this.setState((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  closeModal = () => {
    this.setState({ openForgotPasswordModal: false });
  };

  openForgotModal = () => {
    this.setState({ openForgotPasswordModal: true });
  };

  render() {
    const { displayName, type, avatar, email } = this.state;
    const { onRegisterClick, classes, errors, isFetching } = this.props;
    return (
      <ThemeProvider theme={customTheme}>
        <Formik
          initialValues={{
            email: email || "",
            password: "",
          }}
          enableReinitialize
          onSubmit={this.handleSubmit}
        >
          {({ handleBlur, handleChange, values, resetForm }) => (
            <Form>
              <div className="tw-text-center">
                {!this.props.hideClose && (
                  <button
                    type="button"
                    className="tw-float-right close-button-modal"
                    onClick={this.props.onClose}
                  >
                    <i className="fas fa-times" />
                  </button>
                )}
                {!this.props.hideClose && (
                  <>
                    {avatar ? (
                      <>
                        <img
                          src={avatar}
                          alt={`${displayName}'s avatar`}
                          className="tw-mx-auto tw-h-auto"
                          style={{ maxHeight: "128px", maxWidth: "128px" }}
                        />
                        <h3 className="tw-mb-2">{displayName}</h3>
                      </>
                    ) : (
                      <img
                        src={`${imageServerUrl}/2020-1009 Skipto Website popup Logo-rev01.png`}
                        alt="skipti logo"
                        className="tw-mx-auto tw-h-auto"
                        style={{ maxHeight: "128px", maxWidth: "128px" }}
                      />
                    )}
                  </>
                )}
                {email || avatar || displayName ? (
                  <p className="small">
                    <a onClick={this.handleResetRememberedUser(resetForm)}>
                      Not you?
                    </a>
                  </p>
                ) : null}
              </div>
              {this.props.message ? (
                <p className="text-center">{this.props.message}</p>
              ) : null}
              {errors && (
                <Paper elevation={2} className={classes.paper}>
                  <Typography component="p" color="error">
                    {errors}
                  </Typography>
                </Paper>
              )}
              {this.state.showLoginForm && (
                <>
                  <div className="tw-mb-4">
                    <FormControl fullWidth>
                      <InputLabel color="secondary" htmlFor="email">
                        Email
                      </InputLabel>
                      <Input
                        id="email"
                        color="secondary"
                        name="email"
                        type="email"
                        disabled={isFetching}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </div>
                  <div className="tw-mb-4">
                    <FormControl fullWidth>
                      <InputLabel color="secondary" htmlFor="password">
                        Password
                      </InputLabel>
                      <Input
                        id="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={this.state.showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ tabIndex: "2" }}
                      />
                    </FormControl>
                  </div>

                  <div className="tw-flex tw-flex-wrap">
                    <div className="tw-flex-grow tw-max-w-full">
                      <div className="tw-mb-4">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="rememberMe"
                                color="primary"
                                checked={values.rememberMe}
                                onChange={this.handleChange}
                                value="rememberMe"
                              />
                            }
                            label="Remember me"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap">
                    <div className="tw-flex-grow tw-max-w-full">
                      <div className="tw-mb-4">
                        <a
                          className="tw-text-skiptiOrange fw-medium tw-cursor-pointer"
                          onClick={this.openForgotModal}
                        >
                          Forgot your password?
                        </a>
                      </div>
                    </div>
                  </div>
                  <SkiptiModal
                    onClose={this.closeModal}
                    isOpen={this.state.openForgotPasswordModal}
                  >
                    <ForgotPassword
                      hideCloseBtn
                      onLogInClick={this.closeModal}
                    />
                  </SkiptiModal>
                  <input type="hidden" name="type" value={type} />
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disableElevation
                  >
                    Log In
                  </Button>
                </>
              )}
              {this.state.showLoginForm && (
                <div className="tw-w-full tw-mt-4">
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    className="tw-w-full"
                    onClick={() =>
                      this.setState({
                        showLoginForm: false,
                      })
                    }
                  >
                    Other options
                  </Button>
                </div>
              )}
              {!this.state.showLoginForm && (
                <div className="tw-mt-4">
                  <SocialLogins authenticate={this.authenticate} />

                  <div className="tw-flex tw-justify-center tw-my-4">OR</div>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    className="tw-w-full"
                    onClick={() =>
                      this.setState({
                        showLoginForm: true,
                      })
                    }
                  >
                    Log in with email
                  </Button>
                </div>
              )}

              <hr />

              <Button
                type="button"
                onClick={onRegisterClick}
                variant="contained"
                color="secondary"
                fullWidth
                disableElevation
                className="btn btn-secondary btn-lg btn-block"
              >
                Create Account
              </Button>
            </Form>
          )}
        </Formik>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: getIsLoggingIn(state),
  errors: getSignInErrors(state),
  message: getSignInMessage(state),
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    authenticate,
    setSignInMessage,
    fetchAddressesByUserId,
  })(SignInForm)
);
