import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { setSize, setColor, fetchAvailabilityCalendar } from "../../../actions";
import {
  getSize,
  getItemNeedsSize,
  getSizeOptions,
} from "../../../reducers/itemsReducer";

class SizeAndColorPicker extends Component {
  static propTypes = {
    setSize: PropTypes.func.isRequired,
    setColor: PropTypes.func.isRequired,
    fetchAvailabilityCalendar: PropTypes.func.isRequired,
  };

  handleChange = (name) => (event) => {
    if (name === "size") {
      this.props.setSize(event.target.value);
    } else if (name === "color") {
      this.props.setColor(event.target.value);
    }
    this.props.fetchAvailabilityCalendar(
      null,
      moment().startOf("day"),
      moment().add(2, "month").endOf("month"),
      { size: event.target.value }
    );
  };

  render() {
    const { size, needsSize } = this.props;
    const productAttributes = this.props.sizes || [];
    return (
      <>
        {productAttributes.map((attribute, idx) => (
          <Grid key={`${attribute.label} - ${idx}`} spacing={2} container>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="size-select">{attribute.label}</InputLabel>
                <Select
                  id="size-select"
                  value={size}
                  onChange={this.handleChange("size")}
                >
                  {attribute.attributes.map((cs) => (
                    <MenuItem
                      key={cs.value}
                      value={cs.value}
                      disabled={cs.disabled}
                    >
                      {cs.label}{" "}
                      {!cs.available && (
                        <Typography
                          style={{ marginLeft: "12px" }}
                          variant="body2"
                        >
                          (Available in 5 days)
                        </Typography>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {needsSize && size === "" && (
              <Grid item xs={6} style={{ alignSelf: "flex-end", color: "red" }}>
                <Typography variant="body2">
                  Please select a {attribute.label}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  size: getSize(state),
  needsSize: getItemNeedsSize(state),
  sizes: getSizeOptions(state),
});

const mapDispatchToProps = {
  setSize,
  setColor,
  fetchAvailabilityCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SizeAndColorPicker);
