import React, { useState } from "react";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { steps } from "./staticData";

import HowDoesItWork from "./Steps/HowDoesItWork";
import EnterZipStep from "./Steps/EnterZipStep";
import ProductsStep from "./Steps/ProductsStep";
import BottomDialog from "../../skipti/BottomDialog";
import classnames from "classnames";

const LocationBarSlider = ({
  texts,
  toggle,
  isChangeDialogOpen,
  currentStep,
  onSubmit,
  values,
  changeHandler,
  errors,
  isProcessing,
  additionalStepClickHandler,
  openDialog,
  zip,
}) => {
  return (
    <>
      <div className="tw-w-full tw-bg-darkcharcoal tw-py-3">
        <div className="tw-flex tw-justify-start tw-items-center tw-text-white tw-pl-4">
          <MyLocationIcon fontSize="medium" />
          <div className="tw-ml-3">
            <p className="tw-text-sm">{texts.availableText}</p>
            <div className="tw-flex tw-items-center">
              <p className="tw-text-sm">
                <strong>{texts.address}</strong>
              </p>
              <button
                onClick={openDialog}
                type="button"
                className="tw-underline tw-text-sm tw-ml-3"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>

      <BottomDialog
        height={95}
        withCloseIcon
        swipeable
        open={isChangeDialogOpen}
        toggle={zip ? toggle : null}
        headerProps={{
          className: "tw-absolute tw-right-0",
          style: {
            padding: "14px",
          },
        }}
        contentProps={{
          style: { overflowX: "hidden" },
        }}
      >
        <div>
          {currentStep !== steps.howDoesItWork &&
          currentStep !== steps.withProductsStep ? (
            <div
              style={{
                backgroundImage: "url(/bitmap_map.svg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="tw-flex tw-justify-center tw-mb-10 tw-h-64"
            >
              <img
                className=" tw-w-32"
                src="/bitmap_icon.svg"
                alt="location_icon"
              />
            </div>
          ) : null}

          <div
            className={classnames(
              "tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-5",
              { "tw-mt-16": currentStep === steps.withProductsStep }
            )}
          >
            {currentStep === steps.howDoesItWork ? (
              <HowDoesItWork returnFunc={additionalStepClickHandler} />
            ) : currentStep !== steps.withProductsStep ? (
              <EnterZipStep
                changeHandler={changeHandler}
                isProcessing={isProcessing}
                additionalStepClickHandler={additionalStepClickHandler}
                onSubmit={onSubmit}
                errors={errors}
                currentStep={currentStep}
                values={values}
              />
            ) : (
              <ProductsStep
                changeHandler={changeHandler}
                isProcessing={isProcessing}
                onSubmit={onSubmit}
                errors={errors}
                currentStep={currentStep}
                values={values}
                toggle={toggle}
              />
            )}
          </div>
        </div>
      </BottomDialog>
    </>
  );
};

export default LocationBarSlider;
