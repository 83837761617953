import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
// import skiptiDefault from "../../img/skipti-shape-sm-default.svg";
// import skiptiHover from "../../img/skipti-shape-sm-hover.svg";
import { imageServerUrl } from "../../utils";

const styles = (theme) => ({
  primary: {
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
    },
    color: "#F05623",
    borderRadius: "5px",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  secondary: {
    "&:hover": {
      backgroundColor: "#ACACAC",
      color: "#fff",
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    borderRadius: "5px",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  button: {
    marginRight: "1rem",
  },
});

class SkiptiButton extends Component {
  static propTypes = {
    primary: PropTypes.bool,
    disabled: PropTypes.bool,
    secondary: PropTypes.bool,
    large: PropTypes.bool,
    inverse: PropTypes.bool,
    onClick: PropTypes.func,
    design: PropTypes.oneOf(["primary dialog", "secondary dialog"]),
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.element,
    ]),
    className: PropTypes.string,
    classes: PropTypes.shape({
      primary: PropTypes.string.isRequired,
      secondary: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    primary: false,
    disabled: false,
    secondary: false,
    large: false,
    inverse: false,
    onClick: null,
    children: "Button",
    className: "",
  };

  state = {};

  render() {
    const {
      design,
      primary,
      disabled,
      secondary,
      large,
      inverse,
      children,
      className,
      onClick,
      classes,
      complete,
      outline,
      orange,
      ...rest
    } = this.props;

    let cName = "";
    if ((primary || secondary) && (inverse || outline))
      throw new Error(
        "Can't define primary or secondary and inverse at the same time!"
      );
    if (primary) {
      cName += "btn-skipti-primary ";
    }
    if (secondary) {
      cName += "btn-skipti-secondary ";
    }
    if (inverse) {
      cName += "btn-skipti-inverse ";
    }
    if (outline) {
      cName += "btn-skipti-outline ";
    }
    if (large && primary) {
      cName += " tw-block tw-text-center tw-p-4 tw-m-4 ";
    }
    if (large && secondary) {
      cName += " tw-block tw-text-center text-center tw-p-4 ";
    }
    if (design?.includes("primary dialog")) {
      cName += "skipti-dialog-btn ";
      if (disabled) {
        cName += "skipti-dialogDisable-btn ";
      }
    }
    if (design?.includes("secondary dialog")) {
      cName += "skipti-dialog-secondary-btn ";
    }
    if (disabled) {
      cName += " disabled";
    }
    cName += " tw-m-4";
    return (
      <Button
        disabled={disabled}
        className={`${cName} ${className} ${
          complete ? "swap-skipti-icons" : ""
        } ${primary ? classes.primary : classes.secondary}`}
        fullWidth={large}
        onClick={disabled ? null : onClick}
        {...rest}
      >
        {complete && (
          <>
            <img
              alt="skipti-default"
              src={`${imageServerUrl}/skipti-shape-sm-default.svg`}
              className="ico-default"
            />
            <img
              alt="skipti-hover"
              src={`${imageServerUrl}/skipti-shape-sm-hover.svg`}
              className="ico-hover"
            />
          </>
        )}
        {children || "Button"}
      </Button>
    );
  }
}

export default withStyles(styles)(SkiptiButton);
