/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ItemShowCarousel(props) {
  const { product } = props;
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselSettings = {
    arrows: true,
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    focusOnSelect: false,
    variableWidth: false,
    adaptiveHeight: true,
    beforeChange: (current, next) => setActiveSlide(next),
  };
  return (
    <div className="tw-w-full tw-mx-0">
      <div className="tw-flex tw-flex-wraptw-mx-0 tw-mt-4">
        <div className="xl:tw-w-1/12" />
        <div className="xl:tw-w-10/12 tw-w-full tw-px-6">
          <div className="item-img-container">
            <div
              className="gradient-fade"
              style={{
                zIndex: "1",
                display: `${activeSlide === 0 ? "none" : "block"}`,
              }}
            />
            <Carousel {...carouselSettings}>
              {/* <!-- Youtube Video --> */}
              {product.productVideos &&
                product.productVideos.map((video, index) =>
                  video.file !== "" ? (
                    <div
                      key={`video-${index}`}
                      className="tw-embed-responsive tw-aspect-ratio-4/3"
                      style={{
                        maxHeight: "500px",
                        // maxWidth: "640px"
                      }}
                    >
                      {video.file.includes("vimeo.com") ? (
                        <iframe
                          src={video.file}
                          title="vimeo"
                          className="tw-embed-responsive-item"
                          frameBorder="0"
                          allowFullScreen
                          // allow="autoplay;"
                        />
                      ) : video.file.includes(".mp4") ? (
                        <video
                          title="vimeo"
                          preload="true"
                          autoPlay="autoplay"
                          muted="muted"
                          loop="loop"
                          controls="controls"
                          className="tw-embed-responsive-item"
                        >
                          <source src={video.file} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <iframe
                          title="youtube"
                          src={`https://www.youtube.com/embed/${
                            video.file.split("?v=")[1] ||
                            video.file.split("youtu.be/")[1] ||
                            video.file
                          }`}
                          className="tw-embed-responsive-item"
                          frameBorder="0"
                          allowFullScreen
                          // allow="autoplay;"
                        />
                      )}
                    </div>
                  ) : null
                )}
              {/* -------Product Photos---------*/}
              {product.productPhotos &&
                product.productPhotos.map((photo) => (
                  // <Link
                  //   to={`/items/${product.resourceId}`}
                  //   href={`/items/${product.resourceId}`}
                  //   key={product.resourceId}
                  // >
                  <div
                    key={photo.caption}
                    style={{
                      maxHeight: "500px",
                      // maxWidth: "640px"
                    }}
                  >
                    <img
                      src={
                        photo.file !== ""
                          ? photo.file
                          : "https://dummyimage.com/500x500/ccc/fff&text=+"
                      }
                      alt={photo.caption}
                      className="img-fluid tw-mb-2"
                      style={{
                        maxHeight: "500px",
                        // maxWidth: "640px"
                      }}
                    />
                  </div>
                ))}
            </Carousel>
            <div className="slideshow-controls" style={{ zIndex: "2" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
