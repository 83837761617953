import React, { Component } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import { showSnackbar } from "../../actions";
import { serverUrl } from "../../utils";

class OwnerInterest extends Component {
  state = { success: false };

  handleSubmit = async (values) => {
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/interest/owner`,
        values
      );
      this.setState({
        success: res.data,
      });
    } catch (e) {
      this.props.showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      );
    }
  };

  render() {
    const { success } = this.state;
    return (
      <Grid container style={{ backgroundColor: "#322e2e", color: "white" }}>
        <Grid item xs={false} sm={3} />
        {success ? (
          <Grid item xs={12} sm={6} justifyContent="center">
            <Typography variant="h5" align="center">
              Thank you!
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                justifyItems: "center",
                display: "flex",
                flexDirection: "column",
                padding: "24px",
              }}
            >
              <Typography variant="h5" align="center" gutterBottom>
                Want to earn money by renting out your items on Skipti?
              </Typography>
              <Formik
                initialValues={{ email: "", suggestion: "" }}
                onSubmit={this.handleSubmit}
                validationSchema={yup
                  .object()
                  .shape({ email: yup.string().required("Required field") })}
              >
                {({ values, errors, touched, handleChange, handleBlur }) => (
                  <Form>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography align="center">
                          Be one of the first to gain access!
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="normal"
                          fullWidth
                          variant="filled"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Email"
                          InputProps={{
                            style: { backgroundColor: "white" },
                          }}
                          helperText={
                            !!touched.email && !!errors.email && errors.email
                          }
                          FormHelperTextProps={{
                            error: true,
                          }}
                          value={values.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <button
                          type="submit"
                          className="btn btn-skipti-orange"
                          // onClick={handleSubmit}
                          style={{ width: "100%", marginTop: "16px" }}
                        >
                          Send me the details
                        </button>
                        {/* <SkiptiButton
                          style={{ marginTop: "16px" }}
                          fullWidth
                          orange
                          type="submit"
                        >
                          Submit
                        </SkiptiButton> */}
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </>
        )}
        <Grid item xs={false} sm={3} />
      </Grid>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerInterest);
