import React, { useEffect } from "react";
import { SvgIcon } from "@mui/material";

const FacebookLogin = ({ authenticate }) => {
  const responseFacebook = async (values) => {
    authenticate(values, "facebook");
  };

  const initFacebookSdk = () => {
    return (window.fbAsyncInit = () => {
      window.FB.init({
        appId: "242047607596246",
        cookie: true,
        xfbml: true,
        version: "v18.0",
      });
      window.FB.AppEvents.logPageView();
    });
  };

  useEffect(() => {
    initFacebookSdk()();
  }, []);

  const fbLogin = () => {
    return new Promise((resolve, reject) => {
      window.FB.login(
        (response) => {
          resolve(response);
        },
        { scope: "public_profile,email" }
      );
    });
  };

  function login() {
    fbLogin().then((response) => {
      if (response.status === "connected") {
        responseFacebook(response.authResponse);
      }
    });
  }

  return (
    <div
      id="fb-root"
      onClick={login}
      className="tw-w-full tw-text-center fbButton tw-p-2 tw-cursor-pointer"
    >
      <SvgIcon>
        <>
          <defs>
            <filter colorInterpolationFilters="auto" id="a">
              <feColorMatrix
                in="SourceGraphic"
                values="0 0 0 0 0.231373 0 0 0 0 0.349020 0 0 0 0 0.596078 0 0 0 1.000000 0"
              />
            </filter>
          </defs>
          <g filter="url(#a)" fill="none" fillRule="evenodd">
            <path
              d="M12.82 24H1.324A1.325 1.325 0 0 1 0 22.675V1.325C0 .593.593 0 1.325 0h21.35C23.407 0 24 .593 24 1.325v21.35c0 .732-.593 1.325-1.325 1.325H16.56v-9.294h3.12l.466-3.622H16.56V8.77c0-1.048.29-1.763 1.795-1.763h1.918v-3.24c-.332-.045-1.47-.143-2.795-.143-2.766 0-4.659 1.688-4.659 4.788v2.67H9.692v3.623h3.127V24z"
              fill="#000"
              fillRule="nonzero"
            />
          </g>
        </>
      </SvgIcon>
      &nbsp;&nbsp; Continue with Facebook
    </div>
  );
};

export default FacebookLogin;
