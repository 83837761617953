import axios from "axios";
import moment from "moment-timezone";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as TYPES from "../types";
import * as helpers from "../utils";
import { getToken, getUserRoles } from "../reducers/authReducer";
import { showSnackbar } from "./snackbar";
import { SuperSkiptiAdmin } from "../accessRoleChecks";
import { txTypesEnum } from "../utils";
import { subTransactionEnum } from "../components/skipti/ExpandableTable2";
import {
  ITEM_DELIVERY_MODEL,
  STATUS_CODES,
  validateUSAPhoneNumber,
  INTERNAL_ORDER_STATUSES,
} from "./utils";

const requestOrdersForCurrentUser = () => ({
  type: TYPES.ORDERS_FOR_CURRENT_USER_REQUEST,
});
const successOrdersForCurrentUser = (payload) => ({
  type: TYPES.ORDERS_FOR_CURRENT_USER_SUCCESS,
  payload,
});
const failOrdersForCurrentUser = (error) => ({
  type: TYPES.ORDERS_FOR_CURRENT_USER_FAIL,
  error,
});

export const fetchOrdersForCurrentUser =
  ({ pageSize = 50, pageNumber = 1 } = {}) =>
  async (dispatch, getState) => {
    dispatch(requestOrdersForCurrentUser());
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/orders/customer?pagesize=${pageSize}&pagenumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(successOrdersForCurrentUser(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failOrdersForCurrentUser(e));
    }
  };

const successCancelOrder = (payload) => ({
  type: TYPES.CANCEL_ORDER_SUCCESS,
  payload,
});

export const CancelOrder = (orderId, reason) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await axios.delete(
      `${helpers.serverUrl}/api/v1/order/cancel/${orderId}?reason=${reason}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch(successCancelOrder(orderId));
    dispatch(showSnackbar(`Order ${orderId} was cancelled.`, "success"));
    fetchOrdersForCurrentUser();
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
  }
};

export const sendBookingRequest =
  ({ phoneNumber, productId }) =>
  async (dispatch, getState) => {
    const jwt = getToken(getState());

    try {
      const response = await axios.post(
        `${helpers.serverUrl}/api/v1/order/booking/send`,
        { phoneNumber, productId },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return true;
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );

      return false;
    }
  };

export const wipeOrder = (orderId, email) => async (dispatch, getState) => {
  const jwt = getToken(getState());

  try {
    await axios.delete(
      `${
        helpers.serverUrl
      }/api/v1/admin/wipe/${orderId}?customerEmail=${encodeURIComponent(
        email
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
  }
};

/**
 * This is new endpoint for cancellation of order
 */
export const cancelOrderFromDetail =
  (orderId, reason) => async (dispatch, getState) => {
    const jwt = getToken(getState());

    try {
      await axios.delete(
        `${helpers.serverUrl}/api/v1/order/cancel/${orderId}?reason=${reason}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(successCancelOrder(orderId));
      dispatch(showSnackbar(`Order ${orderId} was cancelled.`, "success"));
      fetchOrdersForCurrentUser();
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  };

const successCompleteOrder = (payload) => ({
  type: TYPES.COMPLETE_ORDER_SUCCESS,
  payload,
});

export const CompleteOrder = (orderId) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await axios.put(
      `${helpers.serverUrl}/api/v1/partner/completed/${orderId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch(successCompleteOrder(orderId));
    dispatch(showSnackbar(`Order ${orderId} was marked COMPLETED.`, "success"));
    fetchOrdersForCurrentUser();
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
  }
};

export const completeInternalOrder =
  (orderId, status) => async (dispatch, getState) => {
    const jwt = getToken(getState());
    try {
      await axios.post(
        `${helpers.serverUrl}/api/v1/order/internal/order/status`,
        {
          orderId,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const statusText =
        status === INTERNAL_ORDER_STATUSES.PICKED_UP
          ? "Picked Up"
          : "Completed";
      dispatch(successCompleteOrder(orderId));
      dispatch(
        showSnackbar(`Order ${orderId} was marked ${statusText}.`, "success")
      );
      fetchOrdersForCurrentUser();
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  };

const setSubRoutesIsLoading = () => ({
  type: TYPES.SUB_ROUTES_IS_LOADING_BY_ORDER_ID,
});

const setSubRoutesLoadingSuccess = () => ({
  type: TYPES.SUB_ROUTES_IS_LOADING_SUCCESS_BY_ORDER_ID,
});

const requestOrderById = () => ({
  type: TYPES.ORDER_BY_ID_REQUEST,
});

export const requestOrderByIdCleanup = () => ({
  type: TYPES.ORDER_BY_ID_CLEANUP,
});

const successOrderById = (payload) => ({
  type: TYPES.ORDER_BY_ID_SUCCESS,
  payload,
});
const failOrderById = (error) => ({
  type: TYPES.ORDER_BY_ID_FAIL,
  error,
});

const successOrderStatusHistory = (payload) => ({
  type: TYPES.ORDER_STATUS_HISTORY_BY_ID,
  payload,
});

const updatePricing = (transaction) => {
  let _extraNights = 0;
  let _subTotal = 0;
  let _tax = 0;
  let _delivery = 0;
  let _additionalFees = 0;
  let _promoCode = 0;

  transaction.items.forEach((item) => {
    if (
      (transaction.txType === txTypesEnum.CCCapture ||
        transaction.txType === txTypesEnum.CCPreAuthForRental) &&
      item.type !== subTransactionEnum.Delivery_Pricing &&
      item.type !== subTransactionEnum.Discount
    ) {
      _subTotal += item.amountCents - Number(item.itemRefundedAmountCents);
    }

    if (transaction.txType === txTypesEnum.CCCharge) {
      if (item.type === subTransactionEnum.Extra_Night_Fee) {
        _extraNights += item.amountCents - Number(item.itemRefundedAmountCents);
      } else if (
        item.type === subTransactionEnum.Rescheduling_Fee ||
        item.type === subTransactionEnum.Custom_Charge
      ) {
        _additionalFees +=
          item.amountCents +
          item.salesTaxAmountCents -
          Number(item.itemRefundedAmountCents) +
          Number(item.itemRefundedSalesTaxAmountCents);
      }
    }

    if (item.type === subTransactionEnum.Discount) {
      _promoCode += item.amountCents - Number(item.itemRefundedAmountCents);
    }

    if (item.type === subTransactionEnum.Delivery_Pricing) {
      _delivery +=
        item.amountCents +
        item.salesTaxAmountCents -
        (Number(item.itemRefundedAmountCents) +
          Number(item.itemRefundedSalesTaxAmountCents));
    } else if (item.type !== subTransactionEnum.Discount) {
      _tax +=
        item.salesTaxAmountCents - Number(item.itemRefundedSalesTaxAmountCents);
    }
  });

  return {
    _extraNights,
    _subTotal: _subTotal + _extraNights,
    _tax,
    _delivery,
    _additionalFees,
    _promoCode,
  };
};

export const fetchOrderById =
  (
    data,
    needToRequest = true,
    subRoutesIsLoading = false,
    isTrackingPage = false
  ) =>
  async (dispatch, getState) => {
    if (needToRequest) {
      dispatch(requestOrderById());
    }
    if (subRoutesIsLoading) {
      dispatch(setSubRoutesIsLoading());
    }
    // andrei user id 3ca67992-d4b5-4f98-9ad9-aacc0c92decd
    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    // :TODO change webservice url
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/order/details/${data}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      res.data.serialsThisDepot = res.data.productRequests.map((product) => ({
        serialNumber: product.serialNumber,
        bin: product.bin,
      }));
      res.data.currentProduct = res.data.productRequests[0];

      if (isTrackingPage) {
        let transactionsItems = [];
        const hashedProducts = {};

        const isTypeTwo = res.data.paymentDetails.transactions.some(
          (el) => el.txType === txTypesEnum.CCCapture
        );
        const pricing = {
          _extraNights: 0,
          _subTotal: 0,
          _tax: 0,
          _delivery: 0,
          _additionalFees: 0,
          _promoCode: 0,
        };
        let newPricing = {
          _extraNights: 0,
          _subTotal: 0,
          _tax: 0,
          _delivery: 0,
          _additionalFees: 0,
          _promoCode: 0,
        };

        res.data.paymentDetails.transactions.forEach((el) => {
          if (isTypeTwo && el.txType !== txTypesEnum.CCPreAuthForRental) {
            const reversedArray =
              el.items[0].type === subTransactionEnum.Product
                ? el.items
                : el.items.reverse();
            transactionsItems =
              el.txType === txTypesEnum.CCCapture
                ? [...reversedArray, ...transactionsItems]
                : [...transactionsItems, ...el.items];

            if (
              el.txType !== txTypesEnum.PartialRefundPostCapture &&
              el.txType !== txTypesEnum.PartialRefundPreCapture &&
              el.txType !== txTypesEnum.CancelPreCapture &&
              el.txType !== txTypesEnum.PreAuthDeposit
            ) {
              newPricing = updatePricing(el);
            }
          } else if (!isTypeTwo) {
            const reversedArray =
              el.items[0].type === subTransactionEnum.Product
                ? el.items
                : el.items.reverse();
            transactionsItems =
              el.txType === txTypesEnum.CCPreAuthForRental
                ? [...reversedArray, ...transactionsItems]
                : [...transactionsItems, ...el.items];

            if (
              el.txType !== txTypesEnum.PartialRefundPostCapture &&
              el.txType !== txTypesEnum.PartialRefundPreCapture &&
              el.txType !== txTypesEnum.CancelPreCapture &&
              el.txType !== txTypesEnum.PreAuthDeposit
            ) {
              newPricing = updatePricing(el);
            }
          }
          pricing._extraNights += newPricing._extraNights;
          pricing._subTotal += newPricing._subTotal;

          pricing._tax += newPricing._tax;
          pricing._delivery += newPricing._delivery;
          pricing._additionalFees += newPricing._additionalFees;
          pricing._promoCode += newPricing._promoCode;
        });

        res.data.productRequests.forEach((product) => {
          hashedProducts[product.productId] = {
            id: product.id,
            productName: product.productName || product.name,
            imageUrl: product.thumbNailUrl || product.imageUrl,
            useAndCareManualUrl: product.useAndCareManualUrl,
            baseCost: product?.baseCost?.amount,
            unitPrice: product?.unitPrice?.amount,
            deliveryOnly: product.deliveryOnly,
            subsequentDayPrice: product?.subsequentDayPrice?.amount,
            hasBundledItems: product?.hasBundledItems,
            bundledItems: product?.bundledItems,
          };

          if (
            product?.hasDamageProtection &&
            product?.damageProtection &&
            !hashedProducts[product.damageProtection.externalId]
          ) {
            hashedProducts[product.damageProtection.externalId] = {
              imageUrl: product.damageProtection.imageUrl,
              title: product.damageProtection.title,
            };
          }

          if (product.addOns && product.addOns.length > 0) {
            product.addOns.forEach((addOn) => {
              hashedProducts[addOn.id] = addOn;
            });
          }
          // TO DO: at some point we'll want the BE to add the bundled items in the transactions
          // if (product.bundledItems && product.bundledItems.length > 0) {
          //   product.bundledItems.forEach((bundledItem) => {
          //     hashedProducts[bundledItem.id] = bundledItem;
          //   });
          // }
        });

        res.data.paymentDetails.detailed =
          helpers.consolidateTransactionsArray(transactionsItems);
        res.data.hashedProducts = hashedProducts;
        res.data.paymentDetails.pricing = pricing;
      }

      dispatch(successOrderById(res.data));
      dispatch(setSubRoutesLoadingSuccess());

      return res.data;
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      dispatch(failOrderById(e));
    }
  };

const requestOrderSubRoutesById = () => ({
  type: TYPES.ORDER_SUB_ROUTES_BY_ID_REQUEST,
});
const updateOrderSubRoutesById = () => ({
  type: TYPES.ORDER_SUB_ROUTES_BY_ID_UPDATE,
});
export const successOrderSubRoutesById = (payload) => ({
  type: TYPES.ORDER_SUB_ROUTES_BY_ID_SUCCESS,
  payload,
});
const failOrderSubRoutesById = (error) => ({
  type: TYPES.ORDER_SUB_ROUTES_BY_ID_FAIL,
  error,
});

export const dataDecoratorForSubRoutes = (data, order) => {
  const routesIds = [];
  const deliverRoutes = data?.map((route) => {
    routesIds.push(route.id);
    let _routeParnter;
    switch (order?.resourceRequest?.deliverPartner) {
      case 6: {
        _routeParnter = route.isDelivery
          ? "ZippyKind delivery"
          : "ZippyKind fetch";
        break;
      }
      case 7: {
        _routeParnter = route.isDelivery
          ? "Skipti Dms delivery"
          : "Skipti Dms fetch";
        break;
      }
      default: {
        _routeParnter = route.isDelivery ? "Delivery Window" : "Fetch Window";
        break;
      }
    }

    return {
      ...route,
      type: route.isDelivery ? "Delivery" : "Fetch",
      partner: _routeParnter,
      partnerId: order?.resourceRequest?.deliverPartner,
      cost: route.costUSD.amount,
    };
  });
  return deliverRoutes;
};

/**
 * @param {string} id
 * @param {boolean} [update=false]
 */
export const fetchOrderSubRoutesById =
  (id, update = false) =>
  async (dispatch, getState) => {
    try {
      if (update) {
        dispatch(updateOrderSubRoutesById());
      } else {
        dispatch(requestOrderSubRoutesById());
      }
      const jwt = getToken(getState());
      const { data } = await axios.get(
        `${helpers.serverUrl}/api/v1/route/orderroutes/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const _allRoutes = [...data.deliveryRoutes, ...data.fetchRoutes];
      dispatch(
        successOrderSubRoutesById(
          dataDecoratorForSubRoutes(
            _allRoutes.sort((a, b) => {
              if (moment(a.dtBeginsLocal).isAfter(moment(b.dtBeginsLocal))) {
                return 1;
              }
              return -1;
            }),
            getState().orders.orderById.data
          )
        )
      );
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      dispatch(failOrderSubRoutesById(e));
    }
  };

const requestRefreshGPSForOrder = () => ({
  type: TYPES.REFRESH_GPS_FOR_ORDER_REQUEST,
});
const successRefreshGPSForOrder = (payload) => ({
  type: TYPES.REFRESH_GPS_FOR_ORDER_SUCCESS,
  payload,
});
const failRefreshGPSForOrder = (error) => ({
  type: TYPES.REFRESH_GPS_FOR_ORDER_FAIL,
  error,
});

export const fetchRefreshGPSForOrder = (data) => async (dispatch, getState) => {
  dispatch(requestRefreshGPSForOrder());
  // andrei user id 3ca67992-d4b5-4f98-9ad9-aacc0c92decd
  if (!data) {
    data = {};
  }
  const jwt = getToken(getState());

  // :TODO change webservice url
  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/order/ordergps/${data}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    dispatch(successRefreshGPSForOrder(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failRefreshGPSForOrder(e));
  }
};

export const fetchOrderStatusHistory = createAsyncThunk(
  "order/status-history",
  async ({ orderId, deliveryModel }, { dispatch, getState }) => {
    try {
      const jwt = getToken(getState());

      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/order/status-history/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (res.data) {
        if (
          deliveryModel >= ITEM_DELIVERY_MODEL.SHIP_REGULAR &&
          deliveryModel <= ITEM_DELIVERY_MODEL.SHIP_FETCH_ONLY
        ) {
          res.data.history = res.data.history.map((el) => {
            if (
              STATUS_CODES[el.status] === helpers.orderStatusEnum.In_Transit
            ) {
              el.display = helpers.orderStatusEnum.Order_Shipped;
            } else if (
              STATUS_CODES[el.status] === helpers.orderStatusEnum.Active
            ) {
              el.display = helpers.orderStatusEnum.Order_Delivered;
            }
            return el;
          });
        }

        // sort by sequence
        const sortedBySequence = res.data.history.sort((first, second) =>
          first.sequence < second.sequence
            ? -1
            : first.sequence > second.sequence
            ? 1
            : 0
        );
        dispatch(
          successOrderStatusHistory({
            statusHistory: sortedBySequence,
            activeStatusDisplay: res.data.activeStatusDisplay,
            activeStatus: res.data.activeStatus,
          })
        );
      }
    } catch (e) {
      dispatch(showSnackbar(e.message));
    }
  }
);

export const triggerRecalculation = createAsyncThunk(
  "order/triggerRecalculation",
  async (values, { dispatch, getState }) => {
    try {
      const jwt = getToken(getState());

      await axios.get(`${helpers.serverUrl}/api/v1/jobs/reportsync/orders`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  }
);

const requestAllOrdersForAdminUser = () => ({
  type: TYPES.ALL_ORDERS_FOR_ADMIN_USER_REQUEST,
});
const successAllOrdersForAdminUser = (payload) => ({
  type: TYPES.ALL_ORDERS_FOR_ADMIN_USER_SUCCESS,
  payload,
});
const failAllOrdersForAdminUser = (error) => ({
  type: TYPES.ALL_ORDERS_FOR_ADMIN_USER_FAIL,
  error,
});

// ----------------------------------------
const requestAllOrdersByOrderId = () => ({
  type: TYPES.ALL_ORDERS_BY_ORDER_ID_REQUEST,
});

const successAllOrdersByOrderId = (payload) => ({
  type: TYPES.ALL_ORDERS_BY_ORDER_ID_SUCCESS,
  payload,
});

const failOrdersByOrderId = (error) => ({
  type: TYPES.ALL_ORDERS_BY_ORDER_ID_FAIL,
  error,
});

export const fetchAllOrdersForAdminUser =
  ({
    pageSize = 10,
    pageNumber = 1,
    startDateTimeUtc,
    endDateTimeUtc,
    region,
  } = {}) =>
  async (dispatch, getState) => {
    dispatch(requestAllOrdersForAdminUser());
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/admin/allorders?pagesize=${pageSize}&pagenumber=${pageNumber}&beginDt=${startDateTimeUtc}&endDt=${endDateTimeUtc}&region=${region}
      `,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(successAllOrdersForAdminUser(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failAllOrdersForAdminUser(e));
    }
  };

export const fetchAllOrdersByOrderId =
  ({ pageSize = 10, pageNumber = 1, region = 1, orderSearch } = {}) =>
  async (dispatch, getState) => {
    const jwt = getToken(getState());
    dispatch(requestAllOrdersByOrderId());
    try {
      const res = await axios.get(`${helpers.serverUrl}/api/v1/orders/all`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageSize,
          pageNumber,
          region: 1,
          orderSearch,
        },
      });
      dispatch(successAllOrdersByOrderId(res.data));
      console.log(res.data, "resdata");
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failOrdersByOrderId(e));
    }
  };

export const fetchAllOrdersWithEmailFilterForAdminUser =
  ({
    pageSize = 10,
    pageNumber = 1,
    startDateTimeUtc,
    endDateTimeUtc,
    region = 1,
    email: searchString = "",
  } = {}) =>
  async (dispatch, getState) => {
    dispatch(requestAllOrdersForAdminUser());
    const jwt = getToken(getState());
    const role = getUserRoles(getState());

    const _endpoint = role.some(SuperSkiptiAdmin)
      ? "/api/v1/orders/all"
      : "/api/v1/orders/partner";

    const searchValue =
      /^[0-9]+$/.test(searchString) && validateUSAPhoneNumber(searchString)
        ? { phoneSearch: searchString.replace(/\D/g, "") }
        : { emailSearch: encodeURIComponent(searchString) };
    try {
      const res = await axios.get(`${helpers.serverUrl}${_endpoint}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageSize,
          pageNumber,
          beginDt: startDateTimeUtc,
          endDt: endDateTimeUtc,
          region: 1,
          ...searchValue,
        },
      });

      const _composedObj = {};

      if (res.data.orders && res.data.orders.length > 0) {
        res.data.orders.forEach((order) => {
          if (!_composedObj[order.id]) {
            _composedObj[order.id] = order;
          } else {
            _composedObj[order.id].products = [
              ..._composedObj[order.id].products,
              ...order.products,
            ];
          }
        });
      }

      if (res.data === "") {
        res.data = {
          currentPageNumber: 1,
          pageSize: 25,
          totalItems: 0,
          totalPages: 0,
        };
      }

      res.data.orders = Object.values(_composedObj).reverse();
      dispatch(successAllOrdersForAdminUser(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failAllOrdersForAdminUser(e));
    }
  };
export const fetchOrdersForToday =
  ({ pageSize = 10, pageNumber = 1, dtlocal } = {}) =>
  async (dispatch, getState) => {
    dispatch(requestAllOrdersForAdminUser());
    const jwt = getToken(getState());
    const role = getUserRoles(getState());

    const _endpoint = role.some(SuperSkiptiAdmin)
      ? "/api/v1/orders/all"
      : "/api/v1/orders/partner";
    try {
      const res = await axios.get(`${helpers.serverUrl}${_endpoint}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageSize,
          pageNumber,
          beginDt: moment
            .tz(dtlocal, "UTC")
            .set({
              hour: 4,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          endDt: moment
            .tz(dtlocal, "UTC")
            .set({
              hour: 4,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .add(1, "day")
            .toISOString(),
        },
      });
      dispatch(successAllOrdersForAdminUser(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failAllOrdersForAdminUser(e));
    }
  };

export const setOrderPromoCode = (data) => (dispatch) => {
  dispatch({
    type: TYPES.ORDER_PROMO_CODE,
    payload: data,
  });
};
