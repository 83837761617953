import axios from "axios";
import * as TYPES from "../types";
import * as helpers from "../utils";
import { getToken } from "../reducers/authReducer";
import { showSnackbar } from "./snackbar";
import { getZip } from "../reducers/itemsReducer";
import { getZipFromLatLong } from "../reducers/userReducer";
import { getDefaultAddressZip } from "../reducers/addressesReducer";

// fetch Users By Organization Id
const requestFetchOrganizationInfoById = () => ({
  type: TYPES.ORGANIZATION_INFO_BYID_REQUEST,
});
const successFetchOrganizationInfoById = (payload) => ({
  type: TYPES.ORGANIZATION_INFO_BYID_SUCCESS,
  payload,
});
const failFetchOrganizationInfoById = (error) => ({
  type: TYPES.ORGANIZATION_INFO_BYID_FAIL,
  error,
});
export const fetchOrganizationInfoById =
  (data) => async (dispatch, getState) => {
    dispatch(requestFetchOrganizationInfoById());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/resources/organization/${data}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      // const res = await axios.get(
      //   `${helpers.serverUrl}/api/v1/resources/products/${
      //     data.userid
      //   }?pagesize=${data.pagesize || 10}&pagenumber=${data.pagenumber || 1}`
      // );

      dispatch(successFetchOrganizationInfoById(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failFetchOrganizationInfoById(e));
    }
  };

// fetch Users By Organization Id
const requestFetchOrganizationInfoByName = () => ({
  type: TYPES.ORGANIZATION_INFO_BYNAME_REQUEST,
});
const successFetchOrganizationInfoByName = (payload) => ({
  type: TYPES.ORGANIZATION_INFO_BYNAME_SUCCESS,
  payload,
});
const failFetchOrganizationInfoByName = (error) => ({
  type: TYPES.ORGANIZATION_INFO_BYNAME_FAIL,
  error,
});
export const fetchOrganizationInfoByName =
  (data, queryZip = null) => async (dispatch, getState) => {
    dispatch(requestFetchOrganizationInfoByName());

    if (!data) {
      data = {};
    }

    const zipCode =
      queryZip ||
      getZip(getState()) ||
      getDefaultAddressZip(getState()) ||
      getZipFromLatLong(getState()) ||
      localStorage.getItem("zipCode");

    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/partners/${data}?zipCode=${zipCode}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      // const res = await axios.get(
      //   `${helpers.serverUrl}/api/v1/resources/products/${
      //     data.userid
      //   }?pagesize=${data.pagesize || 10}&pagenumber=${data.pagenumber || 1}`
      // );

      dispatch(successFetchOrganizationInfoByName(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failFetchOrganizationInfoByName(e));
    }
  };

export const resetOrganizationInfoByName = () => ({
  type: TYPES.ORGANIZATION_INFO_BYNAME_RESET,
});
