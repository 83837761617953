export const setItemRentZipCode = (payload) => ({
  type: "SET_ITEM_RENT_ZIP",
  payload,
});

export const setClient = (payload) => ({
  type: "SET_CLIENT",
  payload,
});

export const setGetItNow = (payload) => ({
  type: "SET_GET_IT_NOW",
  payload,
});

export const setStartAvail = (payload) => ({
  type: "SET_START_AVAIL",
  payload,
});

export const setEndAvail = (payload) => ({
  type: "SET_END_AVAIL",
  payload,
});

export const setStartAvailWindowId = (payload) => ({
  type: "SET_START_AVAIL_WINDOW_ID",
  payload,
});

export const setEndAvailWindowId = (payload) => ({
  type: "SET_END_AVAIL_WINDOW_ID",
  payload,
});

export const setDeliveryMethod = (payload) => ({
  type: "SET_DELIVERY_METHOD",
  payload,
});

export const setLocation = (payload) => ({
  type: "SET_LOCATION_ID",
  payload,
});
export const setSelectedLocation = (payload) => ({
  type: "SET_LOCATION",
  payload,
});

export const setDeliveryOption = (payload) => ({
  type: "SET_DELIVERY_OPTION",
  payload,
});

export const setSelectedAddons = (payload) => ({
  type: "SET_ADDONS",
  payload,
});

export const setQuantity = (payload) => ({
  type: "SET_QUANTITY",
  payload,
});

export const setSelectedItemProfiles = (payload) => ({
  type: "SET_ITEM_PROFILES",
  payload,
});

export const setCheckoutAttributes = (payload) => ({
  type: "SET_CHECKOUT_ATTRIBUTES",
  payload,
});

export const setSelectedItemsInCart = (items) => (dispatch) => {
  dispatch({
    type: "SET_CART_ITEMS",
    payload: items,
  });
};
export const setSelectedItemIdsInCart = (items) => (dispatch) => {
  dispatch({
    type: "SET_CART_ITEMS_IDS",
    payload: items,
  });
};
export const setDeliveryModel = (payload) => ({
  type: "SET_DELIVERY_MODEL",
  payload,
});

export const setExperienceType = (payload) => ({
  type: "SET_EXPERIENCE_TYPE",
  payload,
});

export const setFulfillmentType = (payload) => ({
  type: "SET_FULFILLMENT_TYPE",
  payload,
});

export const setStoreId = (payload) => ({
  type: "SET_STORE_ID",
  payload,
});
