import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import WOW from "wowjs";
import { FullsizePicture } from "react-responsive-picture";
import "animate.css/animate.min.css";

import strings from "../../strings.json";
import { getCityName } from "../../reducers/authReducer";
import { imageServerUrl } from "../../utils";

let interval = null;
let timeout = null;
let wow = null;

class Hero extends Component {
  state = {
    currentIndex: 0,
    animate: true,
    animateWord: true,
  };

  static getDerivedStateFromProps = (props) => ({
    maxSlides: strings[props.city].hero.slides.length,
    slides: strings[props.city].hero.slides,
  });

  componentDidMount() {
    wow = new WOW.WOW();
    wow.init();
    this.animate();
  }

  componentWillUnmount() {
    clearTimeout(timeout);
    clearInterval(interval);
  }

  animate() {
    // console.log("-----ANIMATE------");
    interval = setInterval(() => {
      this.setState({ animate: false, animateWord: false });
      const index = this.state.currentIndex;
      let nextIndex = index + 1;
      if (nextIndex >= this.state.maxSlides) {
        nextIndex = 0;
      }
      timeout = setTimeout(
        () =>
          this.setState({
            currentIndex: nextIndex,
            animate: true,
          }),
        300
      );
      timeout = setTimeout(
        () =>
          this.setState({
            animateWord: true,
          }),
        800
      );
      wow.sync();
    }, 10000);
  }

  render() {
    const { animate, animateWord, currentIndex, slides } = this.state;

    return (
      <div>
        {this.props.city === "albuquerque" ? (
          <img
            src={`${`${imageServerUrl}/2018-1010 Transparent Ribbon.png`}`}
            alt=""
            className=""
            style={{
              position: "absolute",
              top: "80px",
              left: "0",
              height: "40%",
              zIndex: "4",
            }}
          />
        ) : null}

        <div className="tw-mx-auto tw-relative skipti-hero tw-overflow-hidden tw-bg-black tw-p-0 tw-text-white tw-h-screen tw-w-full text-white tw-flex overlay">
          <div
            className={`hero-slideshow ${animate ? "wow fadeIn" : "invisible"}`}
            data-wow-duration="1s"
          >
            <FullsizePicture
              className="slide-img"
              sources={[
                {
                  srcSet: `${imageServerUrl}/${slides[currentIndex].imageSM}, ${imageServerUrl}/${slides[currentIndex].imageSM} 3x`,
                  media: "(max-width: 420px)",
                },

                {
                  srcSet: `${imageServerUrl}/${slides[currentIndex].image}`,
                },
              ]}
            />
          </div>

          <div
            className="tw-mx-auto search-container fw-bold tw-self-center"
            data-wow-duration="1s"
          >
            <h1 className="tw-mb-0 hero-headline">
              Freedom to &nbsp;
              <span
                className={`rotating-word ${
                  animateWord ? "wow fadeIn" : "invisible"
                }`}
                data-wow-duration="1s"
              >
                {slides[currentIndex].text}
              </span>
            </h1>

            <div className="owner-msg tw-mt-2">
              {slides[currentIndex].belowSearchText}{" "}
            </div>
            <Link
              to={slides[currentIndex].buttonURL}
              href={slides[currentIndex].buttonURL}
              className="btn btn-skipti-primary tw-my-4 tw-inline-block"
              style={{ backgroundColor: "#F05623", color: "#ffffff" }}
            >
              {slides[currentIndex].buttonText}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  city: getCityName(state),
});

export default withRouter(connect(mapStateToProps)(Hero));
