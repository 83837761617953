import React from "react";
// import PropTypes from "prop-types";
import LayoutMain from "../layouts/LayoutMain";
import ExperienceForm from "./ExperienceForm";

export default function EditExperience() {
  const handleSubmit = () => {};
  return (
    <LayoutMain withContainer>
      <ExperienceForm
        initialValues={{}}
        handleSubmit={handleSubmit}
        items={[]}
      />
    </LayoutMain>
  );
}
