import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment-timezone";
import { showSnackbar } from "../../actions/snackbar";
import { getToken } from "../../reducers/authReducer";
import { serverUrl } from "../../utils";

export const selectDrivers = (state) => state.drivers.drivers;
export const selectDriversMeta = (state) => state.drivers.meta;
export const selectDriverById = (state) => state.drivers.driverById;
export const selectDriversFetching = (state) => state.drivers.fetching;
export const selectDriversError = (state) => state.drivers.error;
export const selectKeyedDriverPaymentNotes = (state) => {
  const withWeekAndYear = state.drivers.paymentNotes.map((pn) => ({
    ...pn,
    weekAndYear: `${pn.weekNumber}-${pn.year}`,
  }));
  return withWeekAndYear.reduce((acc, cv) => {
    return {
      ...acc,
      [cv.weekAndYear]: acc[cv.weekAndYear]
        ? [...acc[cv.weekAndYear], cv]
        : [cv],
    };
  }, {});
};
export const selectDriverPaymentNotes = (state) => state.drivers.paymentNotes;
export const selectCSReps = (state) => state.drivers.CSReps;
export const selectDriverNotes = (state) => state.drivers.notes;
export const getCSRep = createAsyncThunk(
  "drivers/getCSRep",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(`${serverUrl}/api/v1/driver/csreps`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDriverNotes = createAsyncThunk(
  "drivers/getNotes",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/Note/drivernotes/${values.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDriverPaymentNotes = createAsyncThunk(
  "drivers/getPaymentNotes",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/driver/${values.id}/paymentnotes`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const createDriverNote = createAsyncThunk(
  "drivers/createNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/Note/drivernotes`,
        {
          sourceId: values.fkDriverId,
          note: values.note,
          created: moment().utc().toISOString(false).slice(0, -1),
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getDriverNotes({ id: values.fkDriverId }));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const createDriverPaymentNote = createAsyncThunk(
  "drivers/createPaymentNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/paymentnotes`,
        {
          ...values,
          dtDateAndTime: moment(values.dtDateAndTime)
            .tz("America/New_York", true)
            .toISOString(false)
            .slice(0, -1),
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(showSnackbar("Payment note has been added", "success"));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const payAllInWeek = createAsyncThunk(
  "drivers/payAllPaymenNotes",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const results = [];
    values.paymentNotes.forEach((val) => {
      if (!val.isPayed) {
        results.push(
          axios.put(
            `${serverUrl}/api/v1/driver/paymentnotes`,
            {
              ...val,
              isPayed: true,
            },
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          )
        );
      }
    });
    try {
      await Promise.all(results);
      thunkAPI.dispatch(getDriverPaymentNotes({ id: values.driverId }));
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const updatePaymentNote = createAsyncThunk(
  "drivers/updatePaymentNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/driver/paymentnotes`,
        {
          ...values.note,
          dtDateAndTime: moment(values.note.dtDateAndTime)
            .tz("America/New_york", true)
            .toISOString(false)
            .slice(0, -1),
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      await thunkAPI.dispatch(getDriverPaymentNotes({ id: values.driverId }));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const deletePaymentNote = createAsyncThunk(
  "drivers/deletePaymentNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.delete(
        `${serverUrl}/api/v1/driver/paymentnotes/${values.note.id}`,

        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getDriverPaymentNotes({ id: values.driverId }));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const activateDriver = createAsyncThunk(
  "drivers/activate",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/${values.driverId}/activate`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const deactivateDriver = createAsyncThunk(
  "drivers/deactivate",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/${values.driverId}/deactivate`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const saveDriverInfo = createAsyncThunk(
  "drivers/save",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const sendData = {
      fkRegionId: values.fkRegionId,
      id: values.id,
      photoUrl: values.photoUrl,
      dl: values.dl,
      dob: moment(values.dob).toISOString(true).substring(0, 10),
      dlExpiration: values.dlExpiration
        ? moment(values.dlExpiration).toISOString(true).substring(0, 10)
        : null,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressLine3: values.addressLine3,
    };
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/driver/update`,
        {
          ...sendData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDrivers = createAsyncThunk(
  "drivers/get",
  async (value, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    const _sendParams = {};
    if (value === "all") {
      _sendParams.isActive = true;
    }

    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/admin/drivers/${value}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: _sendParams,
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDriverById = createAsyncThunk(
  "drivers/getById",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/admin/getdriver/${values.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "drivers",
  initialState: {
    drivers: [],
    driverById: {},
    fetching: false,
    error: false,
    meta: {
      pageNumber: 1,
      pageSize: 25,
      totalPages: 0,
    },
    CSReps: [],
    paymentNotes: [],
    notes: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDrivers.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(getDrivers.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.meta.pageNumber = 1;
        state.meta.pageSize = 25;
        state.meta.totalPages = 0;
        state.drivers = [];
      })
      .addCase(getDrivers.fulfilled, (state, { payload }) => {
        state.fetching = false;
        state.error = false;
        state.drivers = payload;
      })
      .addCase(getDriverById.pending, (state) => {
        state.fetching = true;
        state.error = false;
        state.driverById = {};
      })
      .addCase(getDriverById.fulfilled, (state, { payload }) => {
        state.fetching = false;
        state.error = false;
        state.driverById = payload;
      })
      .addCase(getDriverNotes.fulfilled, (state, { payload }) => {
        state.notes = payload;
      })
      .addCase(updatePaymentNote.pending, (state) => {
        state.fetching = true;
      })
      .addCase(updatePaymentNote.rejected, (state) => {
        state.fetching = false;
      })
      .addCase(getDriverPaymentNotes.fulfilled, (state, { payload }) => {
        state.paymentNotes = payload;
        state.fetching = false;
      })
      .addCase(getCSRep.fulfilled, (state, { payload }) => {
        state.CSReps = payload;
      });
  },
});

export default slice.reducer;
