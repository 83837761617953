import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import WOW from "wowjs";
import { Link } from "react-router-dom";
import ItemTile from "../partials/ItemTile";
import "animate.css/animate.min.css";
import LayoutBlog from "../layouts/LayoutBlog";
import Featurette from "../partials/Featurette";
import { fetchArticleById } from "../../actions";
import { getArticle } from "../../reducers/articlesReducer";
import SocialButtons from "../partials/SocialButtons";
import SkiptiSpinner from "../skipti/Spinner";
import ItemCarousel from "../partials/ItemCarousel";

let wow = null;

class ShowArticleV2 extends Component {
  state = {
    isFetching: false,
  };

  async componentDidMount() {
    const { match } = this.props;
    wow = new WOW.WOW();
    wow.init();
    this.setState({ isFetching: true });
    await this.props.fetchArticleById(match.params.id);

    return this.setState({ isFetching: false });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      await this.props.fetchArticleById(nextProps.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.article.isLoaded &&
      this.props.article !== prevProps.article
    ) {
      wow.sync();
    }
  }

  render() {
    const { props } = this;
    const { isFetching } = this.state;
    const article = props.article
      ? props.article.isLoaded
        ? props.article.data
          ? props.article.data
          : {}
        : {}
      : {};
    const body = article.htmlArticle;

    const featured_items = article.productTeasers;
    return (
      <LayoutBlog isLoggedIn={false}>
        <SkiptiSpinner loading={isFetching} opacity="0" absolute={false} />
        {article ? (
          <>
            <div className="skipti-blog">
              <div className="tw-container tw-mx-auto tw-px-4 tw-w-full tw-my-12">
                <Featurette type={0} right image={article.pathBannerPhoto}>
                  <>
                    <h4 className="tw-text-skiptiOrange">EXPERIENCE</h4>
                    <h1 className="tw-text-mediumgrey">{article.title}</h1>
                  </>
                </Featurette>

                <SocialButtons />
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </div>

              {featured_items && featured_items.length > 1 ? (
                <div className="tw-container tw-mx-auto tw-px-4 tw-w-full">
                  <hr />
                  <h3>Check out these other related items</h3>

                  <div className="tw-flex tw-flex-wrap">
                    {_.takeRight(featured_items, featured_items.length - 1).map(
                      (featured_item) => (
                        <ItemTile
                          noOfProducts="3"
                          {...featured_item}
                          key={featured_item.resourceId}
                        />
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {featured_items && featured_items.length > 0 ? (
                <>
                  <div
                    className=" tw-ml-12 tw-mt-4 tw-shadow tw-p-4 tw-bg-white tw-hidden md:tw-block tw-fixed tw-overflow-hidden"
                    style={{
                      zIndex: "1",
                      maxWidth: "225px",
                      maxHeight: "400px",
                      bottom: "15px",
                      right: "15px",
                    }}
                  >
                    <h5 className="center-block tw-text-center">
                      Featured items
                    </h5>
                    <hr />
                    {_.take(featured_items, 1).map((featured_item, index) => (
                      <>
                        <ItemTile
                          noOfProducts={1}
                          {...featured_item}
                          key={featured_item.resourceId}
                        />
                        {index < _.take(featured_items, 1).length - 1 ? (
                          <hr />
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </div>
                  <div
                    className="md:tw-hidden tw-bg-white tw-text-white tw-p-2 tw-fixed tw-w-full tw-overflow-hidden tw-bottom-0 tw-right-0"
                    style={{
                      maxHeight: "250px",
                      zIndex: "3",
                    }}
                  >
                    <h5 className="tw-text-skiptiOrange center-block tw-text-center">
                      Featured items
                    </h5>
                    <hr />
                    {_.take(featured_items, 1).map((featured_item, index) => (
                      <div key={index} className="tw-flex tw-flex-wrap">
                        <div className="tw-w-4/12 tw-self-center">
                          <ItemCarousel
                            productId={featured_item.resourceId}
                            images={featured_item.productPhotos}
                          />
                        </div>
                        <div className="tw-w-8/12 tw-self-center">
                          <span className="fw-light tw-text-right tw-text-white">
                            <Link
                              to={`/items/${featured_item.resourceId}`}
                              href={`/items/${featured_item.resourceId}`}
                            >
                              {featured_item.productName}
                            </Link>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </LayoutBlog>
    );
  }
}

const mapStateToProps = (state) => ({
  article: getArticle(state),
});

export default connect(mapStateToProps, { fetchArticleById })(ShowArticleV2);
