import React from "react";
import { StepsKeysEnum } from "../../utils/constants";
import ProgressItem from "../partials/ProgressItem";

const ProgressSteps = [
  {
    key: 1,
    name: "Booking",
    startStep: StepsKeysEnum.DateSelection,
    endStep: StepsKeysEnum.DateSelection,
  },
  {
    key: 2,
    name: "Options",
    startStep: StepsKeysEnum.UpsellPage,
    endStep: StepsKeysEnum.BuildYourDreamRide,
  },
  {
    key: 3,
    name: "Checkout",
    startStep: StepsKeysEnum.DeliveryAddress,
    endStep: StepsKeysEnum.PaymentOption,
  },
  {
    key: 4,
    name: "Complete",
    startStep: StepsKeysEnum.OrderSummary,
    endStep: StepsKeysEnum.OrderSummary,
  },
];
const AppointmentSteps = [
  {
    key: 1,
    name: "Booking",
    startStep: StepsKeysEnum.DateSelection,
    endStep: StepsKeysEnum.DateSelection,
  },
  {
    key: 2,
    name: "Address",
    startStep: StepsKeysEnum.DeliveryAddress,
    endStep: StepsKeysEnum.PaymentOption,
  },
  {
    key: 3,
    name: "Complete",
    startStep: StepsKeysEnum.OrderSummary,
    endStep: StepsKeysEnum.OrderSummary,
  },
];

const CheckoutProgressBar = ({ currentKey, isAppointment }) => {
  const steps = isAppointment ? AppointmentSteps : ProgressSteps;
  return (
    <div className="tw-w-full tw-p-3 tw-flex tw-justify-center veryLightBlack">
      <div className="tw-flex tw-items-center tw-justify-between">
        {steps.map((element, idx) => (
          <ProgressItem
            key={element.key}
            name={element.name}
            id={element.key}
            isComplete={currentKey > element.endStep}
            isStared={
              currentKey >= element.startStep && currentKey <= element.endStep
            }
            lastItem={idx === steps.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckoutProgressBar;
