import React from "react";
import { Link } from "react-router-dom";
import { imageServerUrl } from "../../utils";

export default function ExperienceTile(props) {
  const article = props.article ? props.article : {};
  let colClass = "";
  switch (props.noOfTiles) {
    case 1:
      colClass = "tw-w-full";
      break;
    case 2:
      colClass = "tw-w-1/2";
      break;
    case 3:
      colClass = "xl:tw-w-4/12 lg:tw-w-4/12 md:tw-w-5/12 sm:tw-w-6/12";
      break;
    case 4:
      colClass = "xl:tw-w-3/12 lg:tw-w-4/12 md:tw-w-4/12 sm:tw-w-6/12";
      break;
    case 6:
      colClass = "xl:tw-w-2/12 lg:tw-w-4/12 md:tw-w-3/12 sm:tw-w-6/12";
      break;
    default:
      colClass = "xl:tw-w-3/12 lg:tw-w-4/12 md:tw-w-4/12 sm:tw-w-6/12";
      break;
  }
  return (
    <div className={`${colClass} tw-bg-white tw-mb-6 tw-px-4`}>
      <div className="item-tile">
        <Link
          to={`/articles/${article.id}`}
          href={`/articles/${article.id}`}
          className="item-link"
        >
          <div className="item-tile-carousel">
            <img
              alt="item carousel"
              src={
                article.pathThumbnailMedium ||
                "http://thisisatest.imgix.net/sample/img1-original.jpg?fit=crop&w=543&h=362"
              }
              className="img-fluid mb-2"
            />
          </div>

          <ul className="tw-float-right tw-list-none tw-pl-0 tw-mt-1">
            <li className="tw-inline-block">
              <img
                src={`${imageServerUrl}/tile-ico-experience.svg`}
                className="experience tw-w-5 tw-h-5 tw-align-middle tw-border-none"
                alt=""
              />
            </li>
          </ul>
          <p className="item-title tw-text-bl tw-mb-1">{article.title}</p>
          <p className="item-title tw-text-verylightgrey fw-medium tw-mb-0">
            {article.teaserSmall}
          </p>
        </Link>
      </div>
    </div>
  );
}
