import React from "react";
import { IconButton, Icon } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import SkiptiButton from "./Button";

export default function SkiptiModal({
  isOpen,
  width,
  onClose,
  onSubmit,
  isConfirmModal,
  hideOK,
  children,
}) {
  return (
    <Modal open={isOpen || false} onClose={onClose}>
      <div
        style={{
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: `translate(-50%, -50%)`,
          backgroundColor: "white",
        }}
        tabIndex={-1}
      >
        <div className="modal-dialog modal-md" role="document" tabIndex={-1}>
          <div className="modal-content">
            <div className="modal-body" style={{ width }}>
              <IconButton
                className="paddingMarginRemover"
                color="secondary"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "5px",
                }}
                onClick={onClose}
              >
                <Icon>close</Icon>
              </IconButton>

              {children}

              {isConfirmModal &&
                (!hideOK ? (
                  <div className="tw-flex tw-justify-between tw-mt-12">
                    <div>
                      <SkiptiButton onClick={onSubmit} design="primary dialog">
                        OK
                      </SkiptiButton>
                    </div>
                    <div>
                      <SkiptiButton onClick={onClose} design="secondary dialog">
                        Cancel
                      </SkiptiButton>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: "-2.5rem",
                      cssFloat: "right",
                    }}
                  >
                    <SkiptiButton onClick={onClose} primary>
                      Cancel
                    </SkiptiButton>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
