import React, { Component } from "react";
import PropTypes from "prop-types";
import { Parallax } from "react-parallax";
import { connect } from "react-redux";
import { fetchProductsByRecentlyUploaded } from "../../actions";
// import imageTest from "../../img/parallax-img1.jpg";
import LayoutCategory from "../layouts/LayoutCategory";
import ItemTile from "../partials/ItemTile";
import SkiptiButton from "../skipti/Button";
import { getProductsByRecentlyUploaded } from "../../reducers/itemsReducer";
import { imageServerUrl } from "../../utils";

class NewArrivalsList extends Component {
  state = {
    pageSize: 12,
    pageNumber: 0,
    products: [],
  };

  static getDerivedStateFromProps = (props, state) => {
    const newProducts =
      props.items && props.items.isLoaded ? props.items.data.resources : [];
    return {
      products: [...state.products, ...newProducts],
    };
  };

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const { pageSize, pageNumber } = this.state;
    this.props.fetchProductsByRecentlyUploaded({
      pageSize,
      pageNumber: pageNumber + 1,
    });
    this.setState({
      pageNumber: pageNumber + 1,
    });
  };

  render() {
    const image = undefined;
    const { products, pageNumber } = this.state;
    const { items } = this.props;
    return (
      <LayoutCategory showSearch>
        <Parallax
          blur={0}
          bgImage={image || `${imageServerUrl}/parallax-img1.jpg`}
          bgImageAlt="category.displayName"
          bgStyle={{ top: "-50%" }}
          strength={850}
          className="parallax-window p-5"
        >
          {/* {this.props.title} */}
          <div className="" style={{ height: "200px" }}>
            <div
              className="container"
              style={{
                position: "absolute",
                bottom: "15px",
                left: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <h1 className="display-1 text-uppercase text-white my-0">
                New Arrivals
              </h1>
            </div>
          </div>
        </Parallax>
        <div className="container-fluid my-5 px-5">
          <div className="row">
            {products.map((item) => (
              <ItemTile {...item} noOfProducts="6" key={item.resourceId} />
            ))}
          </div>
          {items.data &&
            items.isLoaded &&
            pageNumber !== items.data.totalPages && (
              <div className="row justify-content-center m-5">
                <SkiptiButton onClick={this.handleLoad} primary>
                  Load more
                </SkiptiButton>
              </div>
            )}
        </div>
      </LayoutCategory>
    );
  }
}

const mapStateToProps = (state) => ({
  items: getProductsByRecentlyUploaded(state),
});

export default connect(mapStateToProps, { fetchProductsByRecentlyUploaded })(
  NewArrivalsList
);
