import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { showSnackbar } from "../../actions/snackbar";
import { getToken } from "../../reducers/authReducer";
import { serverUrl } from "../../utils";

export const selectCustomersRecords = (state) =>
  state.customers.customersRecord;
export const selectCustomersFetching = (state) => state.customers.fetching;
export const selectCustomersError = (state) => state.customers.error;
export const selectCustomersMeta = (state) => state.customers.meta;

export const getCustomers = createAsyncThunk(
  "customers/get",
  async (values, thunkAPI) => {
    const state = thunkAPI.getState();
    const { pageNumber, searchString } = values || {};
    const jwt = getToken(state);
    const { pageSize } = selectCustomersMeta(state);
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/partner/search/customers`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            pageSize,
            pageNumber,
            searchString,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const blockCustomer = createAsyncThunk(
  "customers/blockCustomer",
  async (userId, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/admin/blacklist/${userId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customers/deleteCustomer",
  async (userId, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    try {
      const res = await axios.delete(
        `${serverUrl}/api/v1/admin/delete/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(showSnackbar("Customer Deleted", "success"));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const unblockCustomer = createAsyncThunk(
  "customers/unblockCustomer",
  async (userId, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/admin/UnblockUser/${userId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const addPromotionalCredits = createAsyncThunk(
  "customers/addPromotionalCredits",
  async ({ email, promotionalCredit }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    const _promotionalCredit = promotionalCredit;
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/admin/setcredit?email=${email}&amountUsd=${_promotionalCredit}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "customers",
  initialState: {
    customersRecord: [],
    fetching: false,
    error: false,
    meta: {
      pageNumber: 1,
      pageSize: 25,
      totalPages: 0,
      totalItems: 0,
    },
  },
  reducers: {
    changeCustomersPageSize: (state, { payload }) => ({
      ...state,
      meta: { ...state.meta, pageSize: payload, pageNumber: 1 },
    }),
    updateCustomersList: (
      state,
      { payload: { displayName, defaultPhoneNumber, email, id } }
    ) => ({
      ...state,
      customersRecord: state.customersRecord.map((el) =>
        el.id === id ? { ...el, displayName, defaultPhoneNumber, email } : el
      ),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(getCustomers.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.meta.pageNumber = 1;
        state.meta.pageSize = 25;
        state.meta.totalPages = 0;
        state.meta.totalItems = 0;
        state.customersRecord = [];
      })
      .addCase(getCustomers.fulfilled, (state, { payload }) => {
        state.error = false;
        state.fetching = false;
        state.meta.pageNumber = payload.pageNumber - 1;
        state.meta.pageSize = payload.pageSize;
        state.meta.totalPages = payload.totalPages;
        state.meta.totalItems = payload.totalItems;
        state.customersRecord = payload ? payload.profiles : [];
      })
      .addCase(blockCustomer.pending, (state) => {
        state.fetching = true;
      })
      .addCase(blockCustomer.rejected, (state) => {
        state.fetching = false;
      })
      .addCase(unblockCustomer.pending, (state) => {
        state.fetching = true;
      })
      .addCase(unblockCustomer.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const { changeCustomersPageSize, updateCustomersList } = slice.actions;

export default slice.reducer;
