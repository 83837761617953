import Axios from "axios";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { setZipCode } from "./items";
import { openZipModal } from "./auth";

export const regionChange = (region) => ({
  type: TYPES.CHANGE_REGION,
  payload: region,
});

export const changeRegion = (region) => (dispatch) => {
  dispatch(regionChange(region));
  localStorage.setItem("region", JSON.stringify(region));
  dispatch(setZipCode(region.defaultZipPostal, false));
  dispatch(openZipModal());
};

export const fetchRegions = () => async (dispatch) => {
  dispatch({
    type: TYPES.FETCH_REGIONS_REQUEST,
  });
  try {
    const res = await Axios.get(
      `${serverUrl}/api/v1/resourcerequest/servicedregions`
    );
    dispatch({
      type: TYPES.FETCH_REGIONS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    showSnackbar(e.message, "error");
  }
};
