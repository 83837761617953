import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import SkiptiButton from "./Button";
import * as helpers from "../../utils";
import SkiptiSpinner from "./Spinner";
import { showSnackbar } from "../../actions";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
  },
});

const mapResponseToDropdown = (items, handleClick) =>
  items.map((item) => ({
    content: (
      <div className="col-4 mb-4" key={item.asin}>
        <div
          className="four-by-three b-3 mb-2"
          onClick={handleClick(item.asin)}
          style={{ cursor: "pointer" }}
        >
          <img src={item.image} className="content w-100" alt={item.title} />
        </div>
        <p className="title mb-1">{item.title}</p>
        <p className="title tw-text-skiptiOrange mb-1">{item.usListPrice}</p>
      </div>
    ),
  }));
const mapItemFromAmazon = (item) => ({
  ...item,
  fromAmazon: true,
  retailUsd:
    item.listPriceCents && item.listPriceCents !== 0
      ? item.listPriceCents / 100
      : "",
  descriptionHtml: item.productDescription || "",
  photos: item.productPhotos.map((photo) => ({
    src: photo.url,
    height: photo.heightPixels,
    fullHeight: photo.heightPixels,
    width: photo.widthPixels,
    fullWidth: photo.widthPixels,
    photoType: photo.photoType,
    fromAmazon: true,
  })),
  productCategory: item.skiptiCategoryId.toString(),
  productCategories: [
    {
      id: item.skiptiCategoryId.toString(),
      parentId: 0,
    },
  ],
  heightInches: (item.heightCentimeters / 2.54).toPrecision(2),
  widthInches: (item.widthCentimeters / 2.54).toPrecision(2),
  depthInches: (item.depthCentimeters / 2.54).toPrecision(2),
  hasDimensions:
    item.heightCentimeters !== 0 ||
    item.widthCentimeters !== 0 ||
    item.depthCentimeters !== 0,
});

class AmazonSearch extends Component {
  static propTypes = {
    itemSelect: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
  };

  state = {
    items: [],
    options: [],
    selectedOption: {},
    searchVal: "",
    modalOpen: false,
    isFetching: false,
  };

  // eslint-disable-next-line react/sort-comp
  handleSearchChange = async (value) => {
    if (value === "") {
      this.setState({
        options: [],
        items: [],
      });
      return;
    }
    let res = { data: [] };
    this.setState({
      isFetching: true,
    });
    try {
      res = await axios.get(
        `${helpers.serverUrl}/api/v1/search/products/amazon`,
        {
          params: {
            searchstr: value,
          },
        }
      );
    } catch (e) {
      this.props.showSnackbar(e.message);
    }
    this.setState(({ items }) => ({
      options: [
        {
          content: (
            <div className="tw-w-4/12 tw-mb-6" key="manual">
              <div
                className="tw-border-4 tw-border-dotted tw-text-center four-by-three tw-mb-2 tw-cursor-pointer tw-relative tw-overflow-hidden"
                onClick={this.handleClick()}
              >
                <i
                  className="fal fa-plus fa-3x tw-text-muted"
                  style={{
                    left: "50%",
                    top: "50%",
                  }}
                />
              </div>
              <h6 className="text-uppercase tw-text-muted">Custom</h6>
              <p className="title">List your own item</p>
            </div>
          ),
        },
        ...mapResponseToDropdown(res.data, this.handleClick),
      ],
      items: [...items.filter((i) => i.manual === "true"), ...res.data],
      isFetching: false,
    }));
  };

  debouncedSearch = _.debounce(this.handleSearchChange, 500);

  componentDidMount() {
    const { items, options } = this.props;
    this.setState({
      items: items || [],
      options: options || [],
    });
    this.scrollToButton = this.scrollToButton.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      searchVal: e.target.value,
    });
    this.debouncedSearch(e.target.value);
  };

  handleClick = (asin) => (e) => {
    if (!asin) {
      this.props.itemSelect(
        {
          productName: this.state.searchVal,
          manufacturerName: "",
          productModel: "",
          descriptionHtml: "",
          photos: [],
          productPhotos: [],
          keywords: [],
          heightCentimeters: 0,
          widthCentimeters: 0,
          depthCentimeters: 0,
          shippingWeightGrams: 0,
          asin: "",
          upc: "",
          ean: "",
          listPriceCents: 0,
          annualDepreciation: 0,
          minimumRentalDurationDays: 4,
          maximumRentalDurationDays: 60,
          images: [],
          productCategory: 0,
          youTubeID: "https://youtube.com/watch?v=6mh3cFpKFhM",
          basePerDayUsd: 0,
          retailUsd: "",
          productNew: moment().subtract(1, "years"),
          condition: 5,
          age_num: 1,
          age_period: "months", // default months
          included: [],
          rules: [],
          owner: null,
          type: "",
          fromAmazon: false,
          skiptiToteSize: "",
        },
        true
      );
    } else {
      this.setState(
        (state) => ({
          modalOpen: true,
          selectedOption: state.items.find((i) => i.asin === asin),
        }),
        () => this.scrollToButton("scrollTo")
      );
    }
  };

  acceptItem = async () => {
    try {
      const resItemByAsin = await axios.get(
        `${helpers.serverUrl}/api/v1/search/products/asinwphoto/${this.state.selectedOption.asin}`
      );
      this.setState(
        {
          modalOpen: false,
        },
        () => {
          const item = resItemByAsin.data;
          const { skiptiTotes } = this.props;
          let skiptiToteSize = 0;
          if (
            item.heightCentimeters &&
            item.heightCentimeters !== 0 &&
            item.widthCentimeters &&
            item.widthCentimeters !== 0 &&
            item.depthCentimeters &&
            item.depthCentimeters !== 0
          ) {
            for (let i = 1; i < skiptiTotes.length; i += 1) {
              if (
                item.heightCentimeters < skiptiTotes[i].heightCm &&
                item.widthCentimeters < skiptiTotes[i].lengthCm &&
                item.depthCentimeters < skiptiTotes[i].depthCm
              ) {
                skiptiToteSize = i;
                break;
              }
            }
          }
          this.props.itemSelect(
            {
              ...mapItemFromAmazon({ ...resItemByAsin.data, skiptiToteSize }),
            },
            false
          );
        }
      );
    } catch (e) {
      this.props.showSnackbar(e.message);
    }
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  scrollToButton() {
    if (this.state.modalOpen) {
      const node = document.getElementById("scrollTo");
      if (node) node.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const { options, selectedOption, searchVal, modalOpen, isFetching } =
      this.state;
    const { classes, label } = this.props;
    return (
      <>
        <div className="tw-shadow tw-bg-white tw-p-2">
          <TextField
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            id="itemSearch"
            name="itemSearch"
            label={label}
            value={searchVal}
            onChange={this.handleChange}
            margin="none"
          />
          {(options.length !== 0 || isFetching) && (
            <div
              className="tw-flex tw-flex-wrap tw-my-12 tw-overflow-auto"
              style={{ maxHeight: "700px" }}
            >
              <SkiptiSpinner loading={isFetching} opacity="0" />
              {options.map((opt) => opt.content)}
            </div>
          )}
        </div>
        <Modal
          open={modalOpen}
          onClose={this.handleClose}
          onRendered={this.scrollToButton}
        >
          <div
            style={{
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%)`,
              overflow: "auto",
              maxHeight: "80%",
            }}
            tabIndex={-1}
            className={classes.paper}
          >
            <div className="four-by-three tw-border-3 tw-mb-2">
              <img
                src={selectedOption.image}
                className="content tw-w-full"
                alt={selectedOption.title}
              />
            </div>
            <p className="title tw-mb-1">{selectedOption.title}</p>
            <p className="tw-text-skiptiOrange title tw-mb-1">
              {selectedOption.usListPrice}
            </p>
            <hr />
            <p className="title">Does this item look like yours?</p>

            <div className="tw-text-center tw-mt-12">
              <SkiptiButton
                design="secondary dialog"
                className="tw-mr-2"
                onClick={this.closeModal}
              >
                No
              </SkiptiButton>
              <SkiptiButton design="primary dialog" onClick={this.acceptItem}>
                Yes
              </SkiptiButton>
              <div id="scrollTo" />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default connect(null, { showSnackbar })(
  withStyles(styles)(AmazonSearch)
);
