import React, { Component } from "react";
import {
  Grid,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class HowDoRentalsWork extends Component {
  state = {};

  render() {
    return (
      <div className="tw-container tw-mx-auto tw-my-12">
        <Accordion className="tw-bg-darkgrey tw-text-white">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="tw-text-white" />}
          >
            <Typography variant="h5">How do rentals work on Skipti?</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              backgroundImage: `url("https://skipti.com/wp-content/uploads/2019/03/howitworks.jpg?id=1890")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "white",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    background: "rgba(34,34,34,0.4)",
                    margin: "40px 0",
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "50px",
                      textAlign: "center",
                    }}
                  >
                    <i className="far fa-calendar" />
                  </div>
                  <Typography gutterBottom variant="h6" align="center">
                    Select Your Rental Dates
                  </Typography>
                  <Typography>
                    Simply choose the dates you&apos;d like to use the item from
                    the date picker. You will be able to select precise delivery
                    windows in the following steps.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    background: "rgba(34,34,34,0.4)",
                    margin: "40px 0",
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "50px",
                      textAlign: "center",
                    }}
                  >
                    <i className="fas fa-truck" />
                  </div>
                  <Typography gutterBottom variant="h6" align="center">
                    We Deliver And Pick Up
                  </Typography>
                  <Typography>
                    Your items arrive as quickly as same-day. When your rental
                    period is over, we pick it back up. No shipping labels
                    required. Enjoy live GPS and up-to-the-minute SMS alerts.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    background: "rgba(34,34,34,0.4)",
                    margin: "40px 0",
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "50px",
                      textAlign: "center",
                    }}
                  >
                    <i className="fas fa-shopping-cart" />
                  </div>
                  <Typography gutterBottom variant="h6" align="center">
                    Deals And Discounts
                  </Typography>
                  <Typography>
                    If you want to buy your rental item, you may be eligible to
                    keep it. Please chat with us to learn more.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default HowDoRentalsWork;
