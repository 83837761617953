import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

export default function ItemShowHelmet(props) {
  const { product, currentUser } = props;
  const safeProduct = product || {
    descriptionHtml: "",
    productPhotos: [],
  };
  const safeUser = currentUser || {};
  return (
    <Helmet>
      <title>{`Skipti - Rent ${safeProduct && safeProduct.productName}`}</title>

      {/* <!-- For Google --> */}
      <meta
        name="description"
        content={`${
          safeProduct.descriptionHtml &&
          safeProduct.descriptionHtml.substr(0, 150).replace(/<[^>]+>/g, " ")
        }`}
      />
      <meta
        name="keywords"
        content="Skipti, rent, rent cool things, rental platform"
      />
      <meta name="author" content="" />
      <meta name="copyright" content="" />
      <meta name="application-name" content="" />

      {/* <!-- For Facebook --> */}
      <meta
        property="og:title"
        content={`Skipti - Rent ${safeProduct.productName}`}
      />
      <meta property="og:type" content="article" />

      <meta
        property="og:image"
        content={`http://${
          safeProduct.productPhotos &&
          safeProduct.productPhotos[0] &&
          safeProduct.productPhotos[0].file.split("https://")[1]
        }`}
      />

      <meta
        property="og:image:secure_url"
        content={`${
          safeProduct.productPhotos &&
          safeProduct.productPhotos[0] &&
          safeProduct.productPhotos[0].file
        }`}
      />
      <meta
        property="og:image:type"
        content={`image/${
          safeProduct.productPhotos &&
          safeProduct.productPhotos[0] &&
          safeProduct.productPhotos[0].file.split(`.`).pop()
        }`}
      />
      <meta property="fb:app_id" content="232980713801350" />
      {/*          
  <meta property="og:image:width" content="484">
  <meta property="og:image:height" content="252"> */}
      <meta
        property="og:url"
        content={`${window.location.href}${
          safeUser.referralSocialId ? `?kid=${safeUser.referralSocialId}` : ""
        }`}
      />
      <meta
        property="og:description"
        content={`${
          safeProduct.descriptionHtml &&
          safeProduct.descriptionHtml.substr(0, 150).replace(/<[^>]+>/g, " ")
        }`}
      />

      {/* <!-- For Twitter --> */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={`Skipti - Rent ${safeProduct.productName}`}
      />
      <meta
        name="twitter:description"
        content={`${
          safeProduct.descriptionHtml &&
          safeProduct.descriptionHtml.substr(0, 150)
        }`}
      />
      <meta
        name="twitter:image"
        content={`${
          safeProduct.productPhotos &&
          safeProduct.productPhotos[0] &&
          safeProduct.productPhotos[0].file
        }`}
      />
    </Helmet>
  );
}

ItemShowHelmet.propTypes = {
  product: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}),
};
