import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class SmallArticleFeaturette extends Component {
  static propTypes = {
    article: PropTypes.shape({}).isRequired,
  };

  state = {};

  render() {
    const { article } = this.props;
    return (
      <>
        <div className="col-sm-6 col-12">
          <Link to={`/articles/${article.id}`} href={`/articles/${article.id}`}>
            <div className="featurette  my-5">
              <img
                src={article.pathThumbnailSmall}
                alt=""
                className="blog-img-sm wow slideInUp"
                data-wow-duration="2.5s"
              />

              <div className="text-container bottom bg-white w-25 p-5 wow slideInUp text-left">
                <h2 className="tw-text-skiptiOrange mb-4">{article.title}</h2>
                <p className="text-color4 fw-light">{article.lead}</p>
              </div>
            </div>
          </Link>
        </div>

        {/* <div className= "col-md-4" key={index}>
                                      <ArticleTile article={article} />
                                  </div> */}
      </>
    );
  }
}

export default SmallArticleFeaturette;
