import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import axios from "axios";
import LayoutDashboard from "../layouts/LayoutDashboard";
import * as helpers from "../../utils";
import { getToken } from "../../reducers/authReducer";
import { showSnackbar } from "../../actions/snackbar";
import SkiptiDialog from "../skipti/SkiptiDialog";
import SkiptiButton from "../skipti/Button";

export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const imageServerUrl = process.env.REACT_APP_IMAGE_SERVER_URL;

function SendRental() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [items, setItems] = useState([]);

  const jwt = useSelector(getToken);
  useEffect(() => {
    try {
      axios
        .get(`${helpers.serverUrl}/api/v1/phonetree/inventory`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((res) => {
          if (res !== undefined && res.data !== undefined) {
            setItems(res.data.categories);
          }
        });
    } catch (e) {
      showSnackbar(e.message);
    }
  }, [items, jwt]);

  const onUnMount = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  function openModal(id) {
    setShowModal(true);
    setProductId(id);
  }

  const handleSubmit = async (values) => {
    try {
      await axios.post(
        `${serverUrl}/api/v1/phonetree/rental?phone=${values.phoneNumber}&productId=${productId}`,
        JSON.stringify(""),
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      showSnackbar(e.message);
    }
    handleClose();
  };

  return (
    <LayoutDashboard showSearch current_page="send-rentals">
      <h2>Send Rental</h2>
      <br />

      <TableContainer>
        <Table padding="default">
          <TableBody>
            {items.map((io, ioIndex) => (io.resources.map((i, index) => (
                <TableRow
                style={{ cursor: "pointer" }}
                key={i.productResourceId}
                onClick={() => openModal(i.productResourceId)}
              >
                <TableCell>
                  <div className="tw-w-24 tw-h-24">
                    <img
                      src={i?.productPhotos[0]?.file}
                      alt={`rental-${index + 1}`}
                    />
                  </div>
                </TableCell>
                <TableCell>{i?.productName}</TableCell>
              </TableRow>
              ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SkiptiDialog
        fullWidth
        scroll="body"
        maxWidth="xs"
        open={showModal}
        toggle={handleClose}
        TransitionProps={{ onExited: onUnMount }}
      >
        <Formik
          initialValues={{
            phoneNumber: "",
          }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form className="tw-text-center tw-text-xl">
              Enter Phone Number
              <br />
              <br />
              <br />
              <TextField
                name="phoneNumber"
                variant="outlined"
                size="small"
                className="tw-w-10/12 tw-text-left"
                label="Phone Number"
                value={values.phoneNumber}
                onChange={handleChange}
                text
              />
              <div className="tw-flex tw-justify-between tw-flex-wrap tw-pt-12">
                <div className="tw-mr-3">
                  <SkiptiButton design="secondary dialog" onClick={handleClose}>
                    Cancel
                  </SkiptiButton>
                </div>
                <div>
                  <SkiptiButton
                    disabled={
                      values.phoneNumber === "" || values.phoneNumber === null
                    }
                    type="submit"
                    design="primary dialog"
                  >
                    Send
                  </SkiptiButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </SkiptiDialog>
    </LayoutDashboard>
  );
};

export default SendRental;
