import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Parallax } from "react-parallax";
import _ from "lodash";
import MixpanelContext from "react-mixpanel";
import { fetchCategoryById, fetchProductsByCategoryName } from "../../actions";
import { getCategory } from "../../reducers/categoriesReducer";
import LayoutCategory from "../layouts/LayoutCategory";
import ItemTile from "../partials/ItemTile";
import { getProductsByCategory } from "../../reducers/itemsReducer";
// import imageTest from "../../img/empty.jpg";
import Button from "../skipti/Button";
import { imageServerUrl } from "../../utils";

class ShowCategory extends Component {
  static propTypes = {
    fetchCategoryById: PropTypes.func.isRequired,
    fetchProductsByCategoryName: PropTypes.func.isRequired,
  };

  static contextType = MixpanelContext;

  state = {
    pageNumber: 1,
    pageSize: 24,
    totalPages: 1,
    products: [],
  };

  static getDerivedStateFromProps = (props, state) => {
    const newProducts =
      props.items && props.items.isLoaded ? props.items.data.resources : [];

    return {
      pageNumber:
        state.searchCategory !== props.match.params.categoryName
          ? 1
          : state.pageNumber,
      totalPages: props.items.isLoaded
        ? props.items.data.totalPages
        : state.totalPages,
      products:
        state.searchCategory === props.match.params.categoryName
          ? _(state.products)
              .keyBy("resourceId")
              .merge(_.keyBy(newProducts, "resourceId"))
              .values()
              .value()
          : [],
      searchCategory: props.match.params.categoryName,
    };
  };

  componentDidMount() {
    const { match } = this.props;
    this.props.fetchCategoryById(match.params.categoryName);
    this.fetchData();

    this.context.track("View Category", {
      productCategory: match.params.categoryName,
    });
    window.analytics.page(`View Category ${match.params.categoryName}`);
  }

  fetchData = () => {
    const { pageSize, pageNumber } = this.state;
    const { match } = this.props;
    this.props.fetchProductsByCategoryName(match.params.categoryName, {
      pageSize,
      pageNumber,
    });
    this.setState({
      pageNumber: pageNumber + 1,
    });
  };

  render() {
    const { props } = this;
    const { pageNumber, totalPages, products } = this.state;
    const category =
      props.category && props.category.isLoaded && props.category.data
        ? props.category.data
        : {};
    const items =
      props.items && props.items.isLoaded && props.items.data
        ? props.items.data
        : {};
    return (
      <LayoutCategory showSearch>
        {/* <div className="mb-5" style={{ position: "relative" }}>
          <div
            style={{
              background:
                "http://s14.directupload.net/images/111129/44ga9qid.png",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 1
            }}
          />
          
          <img
            src={category.image || "https://picsum.photos/1920/300?image=327"}
            className="img-fluid"
            alt=""
          />
          
          <div
            className="container"
            style={{
              position: "absolute",
              bottom: "15px",
              left: 0,
              right: 0,
              zIndex: 2
            }}
          >
            <h1 className="display-1 text-uppercase text-white my-0">
              {category.displayName}
            </h1>
           
            <p className="my-0 lead text-white">{category.description}</p>
          </div>
        </div> */}

        <Parallax
          blur={0}
          bgImage={category.icon || `${imageServerUrl}/empty.jpg`}
          bgImageAlt="category.displayName"
          bgStyle={{ top: "-40%" }}
          strength={850}
          className="parallax-window p-5"
        >
          {/* {this.props.title} */}
          <div className="" style={{ height: "300px" }}>
            <div
              className="container"
              style={{
                position: "absolute",
                bottom: "15px",
                left: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <h1 className="text-uppercase text-white my-0">
                {/* {category.displayName} */}
                {category.displayName === "Photo&Video"
                  ? "Photo & Video"
                  : category.displayName}
              </h1>
            </div>
          </div>
        </Parallax>

        <div className="container-fluid my-5 px-5">
          <div className="row">
            {products.map((item, index) => (
              <ItemTile {...item} noOfProducts="6" key={item.resourceId} />
            ))}
          </div>
          {items.resources &&
            totalPages !== 0 &&
            pageNumber - 1 !== totalPages && (
              <div className="row justify-content-center m-5">
                <Button
                  primary
                  onClick={this.fetchData}
                  disabled={pageNumber - 1 === totalPages}
                >
                  Load more
                </Button>
              </div>
            )}
        </div>
      </LayoutCategory>
    );
  }
}

const mapStateToProps = (state) => ({
  category: getCategory(state),
  items: getProductsByCategory(state),
});

export default connect(mapStateToProps, {
  fetchCategoryById,
  fetchProductsByCategoryName,
})(ShowCategory);
