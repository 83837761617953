import React, { Component } from "react";
import { connect } from "react-redux";
import { Parallax } from "react-parallax";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withWidth } from "@material-ui/core";
import MixpanelContext from "react-mixpanel";
import LayoutCategory from "./layouts/LayoutCategory";
import { openLogInModal, fetchExperiencesAll } from "../actions";

import Featurette from "./partials/Featurette";
import HowItWorksFAQ from "./partials/HowItWorks/HowItWorksFAQ";
import { imageServerUrl } from "../utils";

import "../css/renters.css";

class HowItWorks extends Component {
  static propTypes = {};

  static contextType = MixpanelContext;

  state = {
    imageParallax: `${imageServerUrl}/howitworks/howitworkswhite-tiny.jpg`,
    imageParallaxMobile: `${imageServerUrl}/howitworks/trysomethingnew2.jpg`,
  };

  componentDidMount() {
    this.context.track("How It Works");
    window.analytics.page("How it works");

    this.props.fetchExperiencesAll();

    this.changeParallaxImage = this.changeParallaxImage.bind(this);
  }

  changeParallaxImage(image) {
    this.setState({ imageParallax: image });
  }

  render() {
    const { width } = this.props;

    const imageFeaturette1 = imageServerUrl.includes("marketplace.skipti.com")
      ? "https://skiptiprodblobprod.blob.core.windows.net/images/howitworks/step1.png"
      : `https://skiptiblobdev.blob.core.windows.net/images/howitworks/step1.png`;

    const imageFeaturette2 = imageServerUrl.includes("marketplace.skipti.com")
      ? "https://skiptiprodblobprod.blob.core.windows.net/images/howitworks/step2.png"
      : `https://skiptiblobdev.blob.core.windows.net/images/howitworks/step2.png`;

    const imageFeaturette3 = imageServerUrl.includes("marketplace.skipti.com")
      ? "https://skiptiprodblobprod.blob.core.windows.net/images/howitworks/step3.png"
      : `https://skiptiblobdev.blob.core.windows.net/images/howitworks/step3.png`;

    const imageFeaturette4 = imageServerUrl.includes("marketplace.skipti.com")
      ? "https://skiptiprodblobprod.blob.core.windows.net/images/howitworks/step5.jpg"
      : "https://skiptiblobdev.blob.core.windows.net/images/howitworks/step5.jpg";

    const imageFeaturette5 = imageServerUrl.includes("marketplace.skipti.com")
      ? "https://skiptiprodblobprod.blob.core.windows.net/images/howitworks/step4.jpg"
      : "https://skiptiblobdev.blob.core.windows.net/images/howitworks/step4.jpg";
    return (
      <LayoutCategory showSearch>
        <Parallax
          blur={0}
          style={{
            maxWidth: "none",
          }}
          bgImage={
            width === "xs"
              ? this.state.imageParallaxMobile
              : this.state.imageParallax
          }
          bgImageAlt="category.displayName"
          bgStyle={{ top: "-50%" }}
          strength={850}
          className="parallax-window tw-p-12 tw-max-w-none"
        >
          <div>
            <div
              className="tw-flex tw-flex-wrap"
              style={{
                left: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <div className="lg:tw-w-6/12 tw-w-full tw-text-left">
                <h2 className="sm:tw-text-black tw-text-white tw-uppercase tw-mt-48">
                  HOW IT WORKS
                </h2>
                <p className="tw-pb-5 sm:tw-text-black tw-text-white">
                  Experience more without the cost <br /> of ownership or burden
                  of storage.
                </p>
              </div>
            </div>
          </div>
        </Parallax>

        <Featurette
          type={0}
          right
          image={imageFeaturette1}
          imageMaxWidth="tw-max-w-4xl tw-ml-64"
        >
          <>
            <h3 className="tw-text-skiptiOrange ">1. Enter Your Zip Code: </h3>
            <p className="tw-text-mediumgrey">
              Enter your zip code to take a look at what Skipti has to offer in
              your area.
            </p>
          </>
        </Featurette>

        <Featurette
          type={0}
          left
          image={imageFeaturette2}
          imageMaxWidth="tw-max-w-4xl "
        >
          <>
            <h3 className="tw-text-skiptiOrange ">
              2. Select Your Rental Item{" "}
            </h3>
            <p className="tw-text-mediumgrey">
              Browse our Discover page to find rentals in a growing list of
              locations across the country.
            </p>
          </>
        </Featurette>

        <Featurette
          type={0}
          right
          image={imageFeaturette3}
          imageMaxWidth="tw-max-w-4xl tw-ml-64"
        >
          <>
            <h3 className="tw-text-skiptiOrange">
              {" "}
              3. Select Your Rental Dates
            </h3>
            <p className="tw-text-mediumgrey">
              Use our calendar to choose your rental dates based on availability
              as well as required advanced booking time and rental durations.
            </p>
          </>
        </Featurette>
        <Featurette
          type={0}
          left
          image={imageFeaturette4}
          imageMaxWidth="tw-max-w-4xl "
        >
          <>
            <h3 className="tw-text-skiptiOrange"> 4. Delivered To Your Door</h3>
            <p className="tw-text-mediumgrey">
              A professional courier will hand deliver rental items to your door
              and pick them up when you’re finished. Select from availability
              windows (drop-off and/or pick-up) at checkout.
            </p>
          </>
        </Featurette>

        <Featurette
          type={0}
          right
          image={imageFeaturette5}
          imageMaxWidth="w-max-w-4xl tw-ml-64"
        >
          <>
            <h3 className="tw-text-skiptiOrange">5. Rate And Review </h3>
            <p className="tw-text-mediumgrey">
              Let us know all about your experience! Reviews matter to us. So be
              sure to fill out our survey when it’s emailed to you or leave one
              on our Google business page.
            </p>
          </>
        </Featurette>

        <div className="tw-container tw-mx-auto tw-my-12">
          <div className="center-block tw-text-center tw-my-4 tw-px-12">
            <h3 className="tw-text-skiptiOrange tw-my-0 tw-mx-auto">FAQ</h3>
          </div>
          <div className="center-block tw-text-center tw-my-4 tw-px-12">
            <p className="tw-text-mediumgrey">
              Still wondering how this all works?
            </p>
          </div>
          <HowItWorksFAQ />
        </div>
      </LayoutCategory>
    );
  }
}

export default connect(null, {
  openLogInModal,
  fetchExperiencesAll,
})(withWidth()(HowItWorks));
