import React, { useEffect } from "react";

const GoogleLogin = ({ authenticate }) => {
  const responseGoogle = async (values) => {
    authenticate({ tokenId: values.credential }, "google");
  };

  const initClient = async () => {
    await window.google?.accounts?.id.initialize({
      client_id:
        "787757672783-d3olqqn60k4e55pi7s5ntc6359hm3moi.apps.googleusercontent.com",
      auto_select: false,
      callback: responseGoogle,
    });
  };

  useEffect(() => {
    if (window && window.google && window.google.accounts) {
      initClient();
      window?.google?.accounts?.id.renderButton(
        document.getElementById("googleLogin"),
        {
          type: "standard",
          logo_alignment: "left",
          width: "304px",
        }
      );
    }
  }, [window.google]);

  return (
    <div
      className="tw-mb-2 tw-mt-2 tw-w-full"
      id="googleLogin"
      style={{ height: "42px" }}
    />
  );
};

export default GoogleLogin;
