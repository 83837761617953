import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SkiptiButton from "../skipti/Button";
import {
  openCustomerReviewModal,
  openMerchantReviewModal,
} from "../../actions";
import ItemAverageRating from "./ItemAverageRating";

class ItemReview extends Component {
  static propTypes = {
    openCustomerReviewModal: PropTypes.func.isRequired,
    openMerchantReviewModal: PropTypes.func.isRequired,
    type: PropTypes.string,
    review: PropTypes.shape({}),
    pending: PropTypes.bool,
  };

  static defaultProps = {
    pending: false,
  };

  openReviewModal = () => {
    const { type, review, pending } = this.props;
    if (type === "customer") {
      this.props.openCustomerReviewModal({ ...review, editing: !pending });
    } else if (type === "merchant") {
      this.props.openMerchantReviewModal({ ...review, editing: !pending });
    }
  };

  computeTitle = () => {
    const { review, type } = this.props;
    let title = review.title || "Pending review";
    if (type === "customer") {
      title += ` - Renter ${review.customerDisplayName}`;
    } else {
      title += ` - Reviewer ${review.authorDisplayName}`;
    }
    if (
      review.raterUserId !== "00000000-0000-0000-0000-000000000000" &&
      review.resourceRequestId !== "undefined" &&
      review.resourceRequestId !== null &&
      review.resourceRequestId !== 0
    ) {
      title += ` - Order: ${review.resourceRequestId}`;
    }
    return title;
  };

  render() {
    const { review, pending } = this.props;
    return (
      <div className="review tw-mb-12">
        <ItemAverageRating vectors={review.vectors} />

        <p className="fw-medium tw-text-mediumgrey tw-mb-0">
          {this.computeTitle()}
        </p>
        <div className="tw-flex tw-flex-wrap tw-px-0 tw-mb-6">
          <div className="tw-w-6/12 tw-pt-1 tw-pl-0">
            {pending && (
              <SkiptiButton primary onClick={this.openReviewModal}>
                Open Review Modal
              </SkiptiButton>
            )}
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: review.reviewHtml,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
  openMerchantReviewModal,
  openCustomerReviewModal,
})(ItemReview);
