/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { IconButton, Modal, Switch, Tooltip } from "@material-ui/core";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import * as helpers from "../../utils";
import SkiptiButton from "../skipti/Button";
import Spinner from "../skipti/Spinner";
import {
  deleteProduct,
  showSnackbar,
  fetchItemsByOwnerId,
} from "../../actions";
import { getUser, getToken } from "../../reducers/authReducer";

class EditDeleteItemButtons extends Component {
  static propTypes = {
    resourceId: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
  };

  state = {
    areYouSureModalOpen: false,
    isLoading: false,
  };

  handleDelete = () => () => {
    this.setState((_prev) => ({
      ..._prev,
      areYouSureModalOpen: true,
    }));
  };

  deleteItem = async () => {
    this.setState((_prev) => ({
      ..._prev,
      areYouSureModalOpen: false,
    }));
    if (!this.props.isArticle) {
      this.props.deleteProduct(this.props.resourceId);
      this.props.history.push("/dashboard/items");
    } else {
      console.log(`Deleting article with id ${this.props.resourceId}`);
      // alert("Article not deleted");
      this.props.history.push("/dashboard/articles");
    }
  };

  closeModal = () => {
    this.setState((_prev) => ({
      ..._prev,
      areYouSureModalOpen: false,
    }));
  };
  // createRatingStars = (rating) => {
  //   const stars = null;

  //   for (let i = 0; i < rating; i++) {
  //     stars.push({<i className="fal fa-star" />})
  //   }
  // }

  handleItemAvailabilityChange = async (resourceId, isActive) => {
    this.setState((_prev) => ({
      ..._prev,
      isLoading: true,
    }));
    try {
      // /api/v1/resources/setactiveproduct/{id}?isActive={isActive}

      const res = await axios.put(
        `${helpers.serverUrl}/api/v1/resources/setactiveproduct/${resourceId}?isActive=${isActive}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${this.props.jwt}`,
          },
        }
      );
      if (res.status === 200) {
        this.props.showSnackbar("Successfully Set Availability.", "success");
      }

      if (this.props.location.pathname === "/dashboard/items") {
        this.props.fetchItemsByOwnerId(
          {
            pagesize: 9,
            pagenumber: 1,
          },
          "REFRESH"
        );
      } else {
        window.location.reload();
      }
    } catch (e) {
      this.props.showSnackbar(
        "Availability not set!",
        "error",
        null,
        null,
        JSON.stringify(e)
      );
      console.log(e);
    } finally {
      this.setState((_prev) => ({
        ..._prev,
        isLoading: true,
      }));
    }
  };

  render() {
    const { resourceId, isArticle, isActive, orderType } = this.props;
    return (
      <>
        <hr className="tw-mb-2" />
        <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-center">
          <div className="">
            <Tooltip placement="top" title="Edit">
              <Link
                to={
                  isArticle
                    ? `/articles/edit/${resourceId}`
                    : `/items/edit/${resourceId}`
                }
                href={
                  isArticle
                    ? `/articles/edit${resourceId}`
                    : `/items/edit/${resourceId}`
                }
                // className="btn-skipti-primary"
              >
                <EditIcon />
                {/* Edit */}
              </Link>
            </Tooltip>
          </div>
          <div className="">
            <Tooltip placement="top" title="Order For Another User">
              <Link
                to={`/dashboard/orderforotheruser/${resourceId}?orderType=${orderType}`}
                href={`/dashboard/orderforotheruser/${resourceId}?orderType=${orderType}`}
                // className="btn-skipti-primary"
              >
                <AddShoppingCartIcon />
                {/* Edit */}
              </Link>
            </Tooltip>
          </div>
          <div className="">
            {this.state.isLoading ? (
              <Spinner
                loading={this.state.isLoading}
                absolute={false}
                opacity="0"
                size={25}
              />
            ) : (
              <Switch
                color={isActive ? "primary" : "secondary"}
                checked={isActive}
                onChange={(e) =>
                  this.handleItemAvailabilityChange(
                    resourceId,
                    e.target.checked
                  )
                }
              />
            )}
          </div>

          <div className="">
            <IconButton onClick={this.handleDelete(resourceId)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.areYouSureModalOpen}
          onClose={this.closeModal}
        >
          <div
            className="tw-max-w-screen-md tw-relative tw-mx-auto tw-m-8"
            role="document"
            tabIndex={-1}
          >
            <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md">
              <div className="tw-relative tw-flex-1 tw-p-16">
                Are you sure you want to delete the{" "}
                {isArticle ? "article" : "item"}?
                <div className="tw-text-center tw-mt-4">
                  <SkiptiButton
                    design="secondary dialog"
                    className="tw-mr-2"
                    onClick={this.closeModal}
                  >
                    No
                  </SkiptiButton>
                  <SkiptiButton
                    design="primary dialog"
                    onClick={this.deleteItem}
                  >
                    Yes
                  </SkiptiButton>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  current_user: getUser(state),
  jwt: getToken(state),
});

export default connect(mapStateToProps, {
  deleteProduct,
  showSnackbar,
  fetchItemsByOwnerId,
})(withRouter(EditDeleteItemButtons));
