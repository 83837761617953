import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { connect } from "react-redux";
import { getUser, getIsLoggedIn } from "../../reducers/authReducer";
import { webServerUrl } from "../../utils";

const styles = {
  input: {
    color: "white",
  },
};

class SocialShareButtons extends Component {
  static propTypes = {
    current_user: PropTypes.shape({
      avatar: PropTypes.string,
      first_name: PropTypes.string,
      _id: PropTypes.string,
    }).isRequired,
    whiteBG: PropTypes.bool,
  };

  static defaultProps = { whiteBG: true };

  state = {};

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");
    e.target.focus();
  };

  render() {
    const { current_user, classes } = this.props;

    const link = `${this.props.link ? this.props.link : webServerUrl}${
      current_user.referralSocialId
        ? `?kid=${current_user.referralSocialId}`
        : ""
    }`;
    const title =
      this.props.productName != null
        ? `Check Out This ${this.props.productName} That I Found On Skipti`
        : `Skipti - Freedom To Experience More`;
    return (
      <>
        <div>
          <div className="tw-mx-0 tw-flex tw-flex-wrap tw-justify-center">
            <div className="tw-w-1/12 tw-ml-2" title="Facebook">
              <FacebookShareButton
                url={link}
                quote={title}
                style={{ cursor: "pointer" }}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>
            <div className="tw-w-1/12 tw-ml-2" title="Twitter">
              <TwitterShareButton
                url={link}
                title={title}
                style={{ cursor: "pointer" }}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>

            <div className="tw-w-1/12 tw-ml-2" title="Whatsapp">
              <WhatsappShareButton
                url={link}
                title={title}
                separator=":: "
                style={{ cursor: "pointer" }}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>

              <div style={{ marginTop: "3px", fontSize: "12px" }}>&nbsp;</div>
            </div>

            <div className="tw-w-1/12 tw-ml-2" title="LinkedIn">
              <LinkedinShareButton
                url={link}
                title={title}
                windowWidth={750}
                windowHeight={600}
                style={{ cursor: "pointer" }}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>

            <div className="tw-w-1/12 tw-ml-2" title="Email">
              <EmailShareButton
                url={link}
                subject={title}
                body="body"
                style={{ cursor: "pointer" }}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
          <p className="tw-text-center tw-mb-6">
            Copy and paste the link below:
          </p>
          <div>
            <form>
              <div className="tw-mb-6">
                <TextField
                  margin="dense"
                  ref={this.textArea}
                  fullWidth
                  value={link}
                  InputProps={{
                    className: this.props.whiteBG ? null : classes.input,
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  current_user: getUser(state),

  isLoggedIn: getIsLoggedIn(state),
});
export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SocialShareButtons));
