import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { getUserInfo } from "../../actions";
import LayoutMain from "../layouts/LayoutMain";

class ItemReject extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        jwt: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    getUserInfo: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getUserInfo(
      decodeURIComponent(this.props.match.params.jwt),
      true,
      window.analytics
    );
  }

  render() {
    return (
      <LayoutMain withContainer noFooter>
        <Typography variant="h2">Item reject screen</Typography>
        <Typography variant="h4" className="mb-2">
          Rental has been successfully denied.
        </Typography>
        <Typography variant="body2" />
      </LayoutMain>
    );
  }
}
export default connect(null, { getUserInfo })(ItemReject);
