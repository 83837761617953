import React, { Component } from "react";
import MixpanelContext from "react-mixpanel";
import { imageServerUrl } from "../../utils";
import LayoutMain from "../layouts/LayoutMain";

class NoMatch extends Component {
  static contextType = MixpanelContext;

  componentDidMount() {
    this.context.track("Error 404", {
      url: this.props.location,
    });
  }

  render() {
    return (
      <LayoutMain withContainer>
        <div>
          <img
            src={`${imageServerUrl}/404.png`}
            alt="page-not-found"
            style={{ width: "100%" }}
          />
        </div>
      </LayoutMain>
    );
  }
}

export default NoMatch;
