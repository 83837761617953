import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchExperiencesAll,
  fetchBlogTeasers,
  fetchTop10Teasers,
  fetchIdeaTeasers,
} from "../../actions";
import { getExperiences } from "../../reducers/experiencesReducer";
import {
  getBlogArticles,
  getTop10Articles,
  getIdeaArticles,
} from "../../reducers/articlesReducer";
import ExperienceTile from "./ExperienceTile";
import SkiptiSpinner from "../skipti/Spinner";

class ExperienceRow extends Component {
  static propTypes = {};

  componentDidMount() {
    switch (this.props.articleType) {
      case 0:
        break;
      case 1:
        this.props.fetchTop10Teasers();
        break;
      case 2:
        this.props.fetchIdeaTeasers();
        break;
      case 3:
        this.props.fetchBlogTeasers();
        break;
      default:
        break;
    }
  }

  render() {
    const { props } = this;
    let articles = [];
    let isFetching = false;
    switch (this.props.articleType) {
      case 0:
        articles =
          props.experiences &&
          props.experiences.isLoaded &&
          props.experiences.data
            ? props.experiences.data.teasers
            : [];
        isFetching = props.experiences && props.experiences.isFetching;
        break;
      case 1:
        articles =
          props.top10 && props.top10.isLoaded && props.top10.data
            ? props.top10.data.teasers
            : [];
        isFetching = props.top10 && props.experiences.isFetching;
        break;
      case 2:
        articles =
          props.ideas && props.ideas.isLoaded && props.ideas.data
            ? props.ideas.data.teasers
            : [];
        isFetching = props.ideas && props.experiences.isFetching;
        break;
      case 3:
        articles =
          props.blog && props.blog.isLoaded && props.blog.data
            ? props.blog.data.teasers
            : [];
        isFetching = props.blog && props.experiences.isFetching;
        break;
      default:
        articles = [];
    }

    return (
      <>
        <SkiptiSpinner loading={isFetching} opacity="0" absolute={false} />
        {articles.map((article, index) =>
          index <= 2 ? (
            <ExperienceTile noOfTiles={3} article={article} key={article.id} />
          ) : null
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  experiences: getExperiences(state),
  blog: getBlogArticles(state),
  top10: getTop10Articles(state),
  ideas: getIdeaArticles(state),
});

export default connect(mapStateToProps, {
  fetchExperiencesAll,
  fetchBlogTeasers,
  fetchTop10Teasers,
  fetchIdeaTeasers,
})(ExperienceRow);
