/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Sticky from "react-stickynode";
import { Grid } from "@material-ui/core";
import SkiptiButton from "../../skipti/Button";
import SkiptiSpinner from "../../skipti/Spinner";
import * as helpers from "../../../utils";
import {
  setDateRange,
  showSnackbar,
  fetchAvailabilityCalendar,
  computePrice,
} from "../../../actions";
import DateRangePickerWrapper from "../../partials/DateRangePickerWrapper";
import PriceInfoPopper from "../../partials/PriceInfoPopper";
import EditDeleteItemButtons from "../EditDeleteItemButtons";
import SizeAndColorPicker from "./SizeAndColorPicker";
import VoteItemButtons from "../VoteItemButtons";
import SingleDatePickerWrapper from "../../partials/SingleDatePickerWrapper";
import {
  getDateRange,
  getAvailCalendarStartDate,
  getAvailCalendarEndDate,
  getSize,
  getItemNeedsSize,
  getBlackedOutEvents,
  getSpannableCalendar,
  getZip,
  getIsAvailabilityError,
  getAvailabilityError,
} from "../../../reducers/itemsReducer";
import { getRegion } from "../../../reducers/userReducer";
import { experienceTypes, ITEM_DELIVERY_MODEL } from "../../../actions/utils";
import {
  getExperienceType,
  getStoreId,
} from "../../../reducers/itemRentReducer";

export default function ItemShowCard(props) {
  const {
    product,
    pageMode,
    pricingInfo,
    editMode,
    rentDuration,
    handleRent,
    handleDeliver,
    isAvailCalendarFetching,
    pId,
  } = props;
  const spannableEvents = useSelector(getSpannableCalendar);
  const blackedOutEvents = useSelector(getBlackedOutEvents);
  const needsSize = useSelector(getItemNeedsSize);
  const size = useSelector(getSize);
  const dateRange = useSelector(getDateRange);
  const availCalendarStartDate = useSelector(getAvailCalendarStartDate);
  const availCalendarEndDate = useSelector(getAvailCalendarEndDate);
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [keepOpenOnDateSelect, setKeepOpenOnDateSelect] = useState(false);
  const storeId = useSelector(getStoreId);
  const experienceTypeObject = useSelector(getExperienceType);

  const [isExpanded, setIsExpanded] = useState(true);
  const zipFromRedux = useSelector(getZip);
  const region = useSelector(getRegion);
  const isAvailabilityError = useSelector(getIsAvailabilityError);
  const availabilityError = useSelector(getAvailabilityError);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    productTotal,
    salesTax,
    subtotal,
    delivery,
    productCosts,
    skiptiCredit,
    insurance,
    productNames,
    perimeter,
    baseCost,
  } = pricingInfo;

  useEffect(() => {
    setErrorMessage(availabilityError);
    setIsError(isAvailabilityError);
  }, [isAvailabilityError, availabilityError]);

  useEffect(() => {
    dispatch(
      computePrice({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        sourceZip: zipFromRedux,
        destinationZip: zipFromRedux,
      })
    );
  }, [dispatch, zipFromRedux, dateRange.startDate, dateRange.endDate]);

  const handleFocusChange = (focusedInput) => {
    if (focusedInput) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  };
  const isDayBlocked = (day) => {
    if (
      moment(day).isSame(moment(), "day") &&
      moment().isAfter(moment().hour(18).minutes(30))
    ) {
      return true;
    }

    if (
      blackedOutEvents.some((day2) =>
        moment(day).isSame(moment(day2.beginDt), "day")
      )
    ) {
      return true;
    }

    return false;
  };

  const checkForBlockedDates = (start, end) => {
    const diff = moment(end).diff(start, "days") + 1;
    for (let i = 0; i < diff; i += 1) {
      const checkDate = moment(start).add(i, "d");
      if (isDayBlocked(checkDate)) {
        return true;
      }
    }
    return false;
  };

  const isDayNotSpannable = (day) => {
    const spannableDates = spannableEvents.map((s) =>
      moment(s.beginDt).hour(10)
    );
    if (
      spannableDates.some((day2) => moment(day).isSame(moment(day2), "day"))
    ) {
      return true;
    }
    return false;
  };

  const isDayHighlighted = (day) => {
    if (isDayBlocked(day)) {
      return false;
    }
    if (isDayNotSpannable(day)) {
      return false;
    }
    return true;
  };
  const handleDateRangeChange = (startDate, endDate) => {
    if (
      product.deliveryOnly ||
      product.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY ||
      product.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY
    ) {
      setIsError(false);

      if (!!startDate && !isDayHighlighted(startDate)) {
        setIsError(true);
        setKeepOpenOnDateSelect(true);
        setErrorMessage("You must start and end your rental on a yellow date");
        return false;
      }

      return dispatch(setDateRange(startDate, endDate));
    }

    if (
      (!!startDate && !isDayHighlighted(startDate)) ||
      (!!endDate && !isDayHighlighted(endDate))
    ) {
      setIsError(true);
      setKeepOpenOnDateSelect(true);
      setErrorMessage("You must start and end your rental on a yellow date");
      return dispatch(setDateRange(startDate, endDate));
    }

    if (endDate) {
      if (!props.isAppointment && moment(startDate) >= moment(endDate)) {
        setIsError(true);
        setErrorMessage("Invalid period selected.");
      }

      const duration = endDate
        ? moment
            .duration(
              endDate.diff(
                startDate?.hour(10).minute(0).second(0).millisecond(0)
              )
            )
            .asDays()
        : 0;

      const durationFloored = Math.floor(duration);

      if (
        durationFloored <= product.maximumRentalDurationDays &&
        durationFloored >= product.minimumRentalDurationDays
      ) {
        dispatch(
          computePrice({
            startDate,
            endDate,
            sourceZip: zipFromRedux,
            destinationZip: region && region.defaultZipPostal,
          })
        );
      } else if (!props.isAppointment) {
        return dispatch(setDateRange(startDate, null));
      }
    }

    if (!!startDate && !!endDate) {
      if (checkForBlockedDates(startDate, endDate)) {
        dispatch(setDateRange(null, null));
        return dispatch(
          showSnackbar("Selected range contains blocked dates", "error")
        );
      }

      setIsError(false);
      setKeepOpenOnDateSelect(false);
      setErrorMessage("");
    }

    dispatch(setDateRange(startDate, endDate));
    return null;
  };

  const onPrevMonthClick = async (day) => {
    const startDate = moment(day).subtract(1, "month").startOf("month");
    const endDate = moment(day).endOf("month");
    let reqStartDate = startDate;
    let reqEndDate = endDate;
    if (availCalendarStartDate) {
      reqStartDate = moment.min(moment(availCalendarStartDate), startDate);
    }
    if (availCalendarEndDate) {
      reqEndDate = moment.max(moment(availCalendarEndDate), endDate);
    }
    dispatch(
      fetchAvailabilityCalendar(
        pId,
        reqStartDate,
        reqEndDate,
        null,
        null,
        null,
        true
      )
    );
  };

  const onNextMonthClick = async (day) => {
    const startDate = moment(day).startOf("day");
    const endDate = moment(day).add(1, "month").endOf("month");
    let reqStartDate = startDate;
    let reqEndDate = endDate;

    if (availCalendarStartDate) {
      reqStartDate = moment.min(moment(availCalendarStartDate), startDate);
    }
    if (availCalendarEndDate) {
      reqEndDate = moment.max(moment(availCalendarEndDate), endDate);
    }
    dispatch(
      fetchAvailabilityCalendar(
        pId,
        reqStartDate,
        reqEndDate,
        null,
        null,
        null,
        true
      )
    );
  };

  const renderPickerAndButton = () => ({
    rent: (
      <>
        <SkiptiSpinner
          loading={isAvailCalendarFetching}
          opacity="0"
          absolute={false}
        />

        {!isAvailCalendarFetching &&
          (props.isAppointment ? (
            <SingleDatePickerWrapper
              isError={isError}
              checkForBlockedDates={checkForBlockedDates}
              className={keepOpenOnDateSelect ? "drp-error tw-mb-4" : "tw-mb-4"}
              isDayHighlighted={isDayHighlighted}
              placeholder="Demo Date"
              numberOfMonths={1}
              orientation="horizontal"
              date={dateRange.startDate}
              onDateChange={(date) => handleDateRangeChange(date, date)}
            />
          ) : (
            <DateRangePickerWrapper
              onFocusChange={handleFocusChange}
              checkForBlockedDates={checkForBlockedDates}
              disabled={(needsSize && size === "") || isAvailabilityError}
              className={keepOpenOnDateSelect ? "drp-error tw-mb-4" : "tw-mb-4"}
              isError={isError}
              startDate={dateRange.startDate}
              isDayHighlighted={isDayHighlighted}
              minimumNights={product.minimumRentalDurationDays}
              maximumNights={product.maximumRentalDurationDays}
              startDateId="startDate"
              numberOfMonths={1}
              orientation="horizontal"
              onPrevMonthClick={onPrevMonthClick}
              onNextMonthClick={onNextMonthClick}
              endDate={dateRange.endDate}
              isDayBlocked={isDayBlocked}
              endDateId="endDate"
              onDatesChange={({ startDate, endDate }) =>
                handleDateRangeChange(startDate, endDate)
              }
              focusedInput={null}
              isBSTDemo={
                (product &&
                  product.isBundle &&
                  product.id === "0aa71dda-9b2a-4f12-a85d-3c5fb45a7d3a") ||
                (product &&
                  product.inBundle &&
                  product.bundleParent ===
                    "0aa71dda-9b2a-4f12-a85d-3c5fb45a7d3a")
              }
            />
          ))}
        <SkiptiButton
          fullWidth
          primary
          className="btn-skipti-primary tw-block rent-it-custom-bttn"
          disabled={
            isError
              ? false
              : dateRange.startDate === null || dateRange.endDate === null
          }
          onClick={handleRent}
        >
          {region.IsServiced && !isError
            ? product.deliveryModel === ITEM_DELIVERY_MODEL.REGULAR &&
              !product.isBundle &&
              !product.inBundle
              ? "Rent it!"
              : "SCHEDULE"
            : "Check Delivery Area"}
        </SkiptiButton>
        {product.deliveryModel === ITEM_DELIVERY_MODEL.REGULAR ? (
          <div className="tw-flex tw-flex-wrap tw-mx-0 other-item-info small tw-text-lightgrey">
            <div className="tw-w-8/12">
              <Grid container>
                <Grid item xs={2} className="tw-self-center">
                  <img
                    alt="quick-reply"
                    src={`${helpers.imageServerUrl}/ico-stats-quick-reply.svg`}
                    className="tw-mr-2"
                  />
                </Grid>
                <Grid item xs={10}>
                  {product.votesToAcquire > 0
                    ? product.votesToAcquire === 1
                      ? "1 person waiting "
                      : `${product.votesToAcquire} people waiting `
                    : `Minimum nights: ${product.minimumRentalDurationDays}
      Maximum nights: ${product.maximumRentalDurationDays}`}
                </Grid>
              </Grid>
            </div>
            {product && product.urlToBuy && (
              <div className="tw-w-4/12 tw-px-0">
                <i className="fas fa-shopping-bag tw-text-skiptiOrange" />{" "}
                <a href={product.urlToBuy}>Buy this item</a>
              </div>
            )}
          </div>
        ) : null}
      </>
    ),
    deliveryOnly: (
      <>
        <SkiptiSpinner
          loading={isAvailCalendarFetching}
          opacity="0"
          absolute={false}
        />

        {!isAvailCalendarFetching && (
          <SingleDatePickerWrapper
            className={keepOpenOnDateSelect ? "drp-error tw-mb-4" : "tw-mb-4"}
            isError={isError}
            disabled={isAvailabilityError}
            onFocusChange={handleFocusChange}
            date={dateRange.startDate}
            isDayHighlighted={isDayHighlighted}
            numberOfMonths={1}
            orientation="horizontal"
            onPrevMonthClick={onPrevMonthClick}
            onNextMonthClick={onNextMonthClick}
            isDayBlocked={isDayBlocked}
            onDateChange={(startDate) =>
              handleDateRangeChange(startDate, moment().add(7, "days"))
            }
          />
        )}
        <SkiptiButton
          fullWidth
          primary
          className="btn-skipti-primary tw-block rent-it-custom-bttn"
          disabled={isError}
          onClick={handleDeliver}
        >
          SCHEDULE
        </SkiptiButton>
      </>
    ),
    vote: (
      <>
        <VoteItemButtons
          product={{
            productId: product.resourceId,
            productName: product.productName,
            productCategory: product?.productCategories[0]?.name,
            productPrice: product.basePerDayUsd,
          }}
          resourceId={product.resourceId}
        />
      </>
    ),
    download: (
      <>
        <a
          href={product.urlToBuy}
          className="btn-skipti-primary tw-block rent-it-custom-bttn"
        >
          GET IT!
        </a>
      </>
    ),
    subscription: (
      <>
        <a
          href={`/items/buy/${product.resourceId}`}
          className="btn-skipti-primary tw-block rent-it-custom-bttn"
        >
          BUY IT!
        </a>
      </>
    ),
  });

  return (
    <div className="md:tw-w-5/12 lg:tw-w-4/12 tw-px-4 tw-hidden md:tw-block tw-z-40">
      <Sticky top={108} bottomBoundary="#item-description">
        <div id="sticky_item">
          <div className="b-shadow rent-it-box">
            <div className="tw-text-6xl tw-text-right tw-text-skiptiOrange">
              $
              {baseCost && typeof baseCost !== "undefined"
                ? baseCost.toFixed(2)
                : product.basePerDayUsd &&
                  typeof product.basePerDayUsd !== "undefined" &&
                  product.basePerDayUsd > 0
                ? product.basePerDayUsd.toFixed(2)
                : product.renterCostPerDayUsd &&
                  typeof product.renterCostPerDayUsd !== "undefined" &&
                  product.renterCostPerDayUsd > 0
                ? product.renterCostPerDayUsd.toFixed(2)
                : product.basePerWeekUsd && product.basePerWeekUsd > 0
                ? product.basePerWeekUsd.toFixed(2)
                : product.basePerMonthUsd && product.basePerMonthUsd > 0
                ? product.basePerMonthUsd.toFixed(2)
                : "0"}
              <input
                id="daily_price"
                type="hidden"
                value={product.basePerDayUsd}
              />
            </div>
            <div className="tw-text-right tw-text-lightgrey tw-mb-6">
              {(baseCost !== null && typeof baseCost !== "undefined") ||
              product.deliveryModel === ITEM_DELIVERY_MODEL.REPAIR ||
              product.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY ||
              product.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY
                ? "total"
                : product.subsequentDayUsd && product.subsequentDayUsd > 0
                ? "first night"
                : product.basePerDayUsd > 0
                ? " / night"
                : product.basePerWeekUsd && product.basePerWeekUsd > 0
                ? " / week"
                : product.basePerMonthUsd && product.basePerMonthUsd > 0
                ? " / month"
                : ""}
              <PriceInfoPopper
                product={product}
                productNames={productNames}
                productCosts={productCosts}
                rentDuration={rentDuration}
                total={productTotal}
                salesTax={salesTax}
                subtotal={subtotal}
                delivery={delivery}
                skiptiCredit={skiptiCredit}
                insurance={insurance}
                perimeter={perimeter}
              />
            </div>
            {needsSize && (
              <div className="tw-mb-6">
                <SizeAndColorPicker />
              </div>
            )}

            {renderPickerAndButton()[pageMode]}

            {editMode && editMode === true ? (
              <>
                <EditDeleteItemButtons
                  resourceId={product.resourceId}
                  isActive={product.isActive}
                  orderType={
                    product && product.deliveryOnly
                      ? "deliver"
                      : product && (product.isBundle || product.inBundle)
                      ? "demo"
                      : "rent"
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </Sticky>
    </div>
  );
}
