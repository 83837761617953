import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment-timezone";

import { showSnackbar } from "../../actions/snackbar";
import { getToken } from "../../reducers/authReducer";
import { serverUrl } from "../../utils";

export const selectTeams = (state) => state.dmsSlice.teams;
export const selectTeamsMeta = (state) => state.dmsSlice.teamMeta;

export const selectTeamsFetching = (state) => state.dmsSlice.teamFetching;
export const selectTeamsError = (state) => state.dmsSlice.teamError;
export const selectRegionTeams = (state) => state.dmsSlice.regionTeams;
export const selectRegionTeamsFetching = (state) =>
  state.dmsSlice.regionTeamsFetching;

export const selectOrders = (state) => state.dmsSlice.orders;
export const selectOrdersMeta = (state) => state.dmsSlice.ordersMeta;

export const selectDmsRoutes = (state) => state.dmsSlice.dmsRoutes;
export const selectDmsRoutesMeta = (state) => state.dmsSlice.routesMeta;
export const selectRoutesFetching = (state) => state.dmsSlice.routesFetching;

export const selectCurrentTeamDrivers = (state) =>
  state.dmsSlice.currentTeamDrivers;

export const selectIndividualTeam = (state) => state.dmsSlice.individualTeam;
export const selectTeamsDriversEvents = (state) =>
  state.dmsSlice.teamsDriversEvent;

export const deactivateTeam = createAsyncThunk(
  "dmsDriver/deactivateTeam",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/team/deactivate`,
        {
          id: values.teamId,
          reason: values.disableReason,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const activateTeam = createAsyncThunk(
  "dmsDriver/deactivateTeam",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/team/activate`,
        {
          id: values.teamId,
          reason: values.disableReason,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getTeams = createAsyncThunk(
  "dmsDrivers/getTeams",
  async ({ isActiveTeams, pageNumber = 1, pageSize = 25 }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(`${serverUrl}/api/v1/team`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageNumber,
          pageSize,
          isActive: isActiveTeams,
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getTeam = createAsyncThunk(
  "dmsDrivers/getTeam",
  async ({ teamId, isActiveTeam }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const params = {
        id: teamId,
      };

      // argument can be undefined
      // if team is inactive should send flag isActive=false
      if (isActiveTeam === false) {
        params.isActive = false;
      }

      const res = await axios.get(`${serverUrl}/api/v1/team`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params,
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);
export const createTeam = createAsyncThunk(
  "dmsDrivers/createTeam",
  async (value, thunkAPI) => {
    const {
      accessHoursEnd,
      accessHoursStart,
      skiptiHoursEnd,
      skiptiHoursStart,
      ...rest
    } = value;

    let _accessHourStart;
    let _accessHourEnd;
    let _accessMinuteStart;
    let _accessMinuteEnd;

    let _skiptiHourStart;
    let _skiptiHourEnd;
    let _skiptiMinuteStart;
    let _skiptiMinuteEnd;

    Object.keys(value).forEach((key) => {
      if (key === "accessHoursEnd") {
        [_accessHourEnd, _accessMinuteEnd] = value[key].split(":").slice(0, 2);
      }
      if (key === "skiptiHoursEnd") {
        [_accessHourStart, _accessMinuteStart] = value[key]
          .split(":")
          .slice(0, 2);
      }
      if (key === "skiptiHoursEnd") {
        [_skiptiHourEnd, _skiptiMinuteEnd] = value[key].split(":").slice(0, 2);
      }
      if (key === "skiptiHoursStart") {
        [_skiptiHourStart, _skiptiMinuteStart] = value[key]
          .split(":")
          .slice(0, 2);
      }
    });

    const _sendingData = {
      ...rest,
      skiptiStartHour: +_skiptiHourStart,
      skiptiStartMinute: +_skiptiMinuteStart,
      skiptiEndHour: +_skiptiHourEnd,
      skiptiEndMinute: +_skiptiMinuteEnd,
      accessStartHour: +_accessHourStart,
      accessStartMinute: +_accessMinuteStart,
      accessEndHour: +_accessHourEnd,
      accessEndMinute: +_accessMinuteEnd,
    };
    const { id, ...restData } = _sendingData;

    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(`${serverUrl}/api/v1/team`, restData, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const updateTeam = createAsyncThunk(
  "dmsDrivers/updateTeam",
  async (value, thunkAPI) => {
    const {
      accessHoursEnd,
      accessHoursStart,
      skiptiHoursEnd,
      skiptiHoursStart,
      ...rest
    } = value;

    let _accessHourStart;
    let _accessHourEnd;
    let _accessMinuteStart;
    let _accessMinuteEnd;

    let _skiptiHourStart;
    let _skiptiHourEnd;
    let _skiptiMinuteStart;
    let _skiptiMinuteEnd;

    Object.keys(value).forEach((key) => {
      if (key === "accessHoursEnd") {
        [_accessHourEnd, _accessMinuteEnd] = value[key].split(":").slice(0, 2);
      }
      if (key === "skiptiHoursEnd") {
        [_accessHourStart, _accessMinuteStart] = value[key]
          .split(":")
          .slice(0, 2);
      }
      if (key === "skiptiHoursEnd") {
        [_skiptiHourEnd, _skiptiMinuteEnd] = value[key].split(":").slice(0, 2);
      }
      if (key === "skiptiHoursStart") {
        [_skiptiHourStart, _skiptiMinuteStart] = value[key]
          .split(":")
          .slice(0, 2);
      }
    });
    const _sendingData = {
      ...rest,
      skiptiStartHour: +_skiptiHourStart,
      skiptiStartMinute: +_skiptiMinuteStart,
      skiptiEndHour: +_skiptiHourEnd,
      skiptiEndMinute: +_skiptiMinuteEnd,
      accessStartHour: +_accessHourStart,
      accessStartMinute: +_accessMinuteStart,
      accessEndHour: +_accessHourEnd,
      accessEndMinute: +_accessMinuteEnd,
    };

    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.put(`${serverUrl}/api/v1/team`, _sendingData, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getRegionTeams = createAsyncThunk(
  "dmsDrivers/getRegionTeams",
  async (value, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(`${serverUrl}/api/v1/team/regionteams`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const changeStatus = createAsyncThunk(
  "dmsDriver/changeStatus",
  async ({ routeId, status, driverId }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    const _params = {
      status,
    };

    if (status === 100) {
      _params.driverId = driverId;
    }
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/driver/dmsticket/${routeId}/status `,
        _params,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getCurrentTeamDrivers = createAsyncThunk(
  "dmsDrivers/getCurrentTeamDrivers",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        // `${serverUrl}/api/v1/driver/${values.id}/notes`,
        `${serverUrl}/api/v1/team/members/${values.teamId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getTeamDriversEvents = createAsyncThunk(
  "dmsDrivers/getTeamDriversEvents",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const params = {
      beginUtc: values.beginUtc,
      endUtc: values.endUtc,
      region: values.region,
    };

    if (values.entryType) {
      params.entryType = values.entryType;
    }

    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/calendar/team/${values.teamId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params,
        }
      );

      const _data = res.data.map((el, index) => {
        const _eventDate = `${moment
          .utc(el.localBeginDt)
          .format("MM/DD/YYYY LT")} - ${moment
          .utc(el.localEndDt)
          .format("LT")}`;
        return {
          ...el,
          eventDates: _eventDate,
          start: moment(el.localBeginDt).format("MM/DD/YYYY hh:mm a"),
          end: moment(el.localEndDt).format("MM/DD/YYYY hh:mm a"),
          index,
          title: `${el.display}: ${_eventDate}`,
        };
      });
      return _data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  }
);

export const slice = createSlice({
  name: "dmsSlice",
  initialState: {
    teams: [],
    teamsDriversEvent: [],
    teamFetching: false,
    teamError: null,
    dmsRoutes: [],
    routesMeta: {
      totalItems: 0,
      totalPages: 0,
      pageNumber: 1,
    },
    routesFetching: false,
    teamMeta: {
      pageNumber: 1,
      totalPages: 0,
      totalItems: 0,
    },
    regionTeamsFetching: false,
    regionTeams: [],
    currentTeamDrivers: [],
    individualTeam: {},
    isReportsFetching: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        state.teamFetching = true;
        state.teamError = false;
      })
      .addCase(getTeams.fulfilled, (state, { payload }) => {
        state.teamFetching = false;
        state.teamError = false;
        state.teams = payload.teams.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        state.teamMeta.pageNumber = payload.pageNumber;
        state.teamMeta.totalPages = payload.totalPages;
        state.teamMeta.totalItems = payload.totalItems;
      })
      .addCase(getTeams.rejected, (state, { payload }) => {
        state.teamFetching = false;
        state.teamError = payload;
        state.teams = [];
        state.teamMeta.pageNumber = 1;
        state.teamMeta.totalPages = 1;
      })
      .addCase(getTeam.pending, (state) => {
        state.individualTeam = {};
        state.teamFetching = true;
      })
      .addCase(getTeam.rejected, (state, { payload }) => {
        state.teamError = payload;
        state.individualTeam = {};
        state.teamFetching = false;
      })
      .addCase(getTeam.fulfilled, (state, { payload }) => {
        state.individualTeam = payload.teams[0] || {};
        state.teamFetching = false;
      })
      .addCase(createTeam.pending, (state) => {
        state.teamFetching = true;
      })
      .addCase(createTeam.rejected, (state) => {
        state.teamFetching = false;
      })
      .addCase(createTeam.fulfilled, (state) => {
        state.teamFetching = false;
      })
      .addCase(updateTeam.pending, (state) => {
        state.teamFetching = true;
      })
      .addCase(updateTeam.rejected, (state) => {
        state.teamFetching = false;
      })
      .addCase(updateTeam.fulfilled, (state) => {
        state.teamFetching = false;
      })

      .addCase(getRegionTeams.fulfilled, (state, { payload }) => {
        state.regionTeams = payload;
        state.regionTeamsFetching = false;
      })
      .addCase(getRegionTeams.rejected, (state) => {
        state.regionTeams = [];
        state.regionTeamsFetching = false;
      })
      .addCase(getRegionTeams.pending, (state) => {
        state.regionTeams = [];
        state.regionTeamsFetching = true;
      })
      .addCase(getCurrentTeamDrivers.fulfilled, (state, { payload }) => {
        state.currentTeamDrivers = payload;
      })
      .addCase(getTeamDriversEvents.rejected, (state) => {
        state.teamsDriversEvent = [];
      })
      .addCase(getTeamDriversEvents.fulfilled, (state, { payload }) => {
        state.teamsDriversEvent = payload;
      });
  },
});

// export const {} = slice.actions;

export default slice.reducer;
