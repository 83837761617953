import * as TYPES from "../types";
import * as helpers from "../utils";

const authReducer = (
  state = {
    isLoggedIn: false,
    isError: false,
    isLoaded: false,
    isFetching: false,
    logInModalOpen: false,
    shareReferralModalOpen: false,
    returnUrl: undefined,
    voteForProductAfterAuth: undefined,
    signInMessage: undefined,
    error: undefined,
    stripeFallbackLocation: "",
    user: {},
    zipModalOpen: true,
    adminRegisterUser: {},
    adminRegisterUserError: undefined,
    passwordValidationStatus: {},
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.LOGIN_REQUEST:
    case TYPES.USER_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        isLoaded: false,
        signInError: undefined,
        registerError: undefined,
        shareReferralModalOpen: false,
      };
    case TYPES.USER_INITFLAGS_SUCCESS:
      return {
        ...state,
        user: { ...state.user, profileInitFlags: { ...payload } },
        isFetching: false,
      };
    case TYPES.LOGIN_SUCCESS:
    case TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...payload,
          isAdmin: payload.roles.includes("SKIPTI-ADMIN"),
        },
        // user: payload,
        token: payload.token,
        isFetching: false,
        isError: false,
        isLoaded: true,
        isLoggedIn: true,
        signInError: undefined,
        registerError: undefined,
        logInModalOpen: false,
        shareReferralModalOpen: false,
      };
    case TYPES.USER_REGISTER_SUCCESS:
      return {
        ...state,
        user: payload,
        token: payload.token,
        isFetching: false,
        isError: false,
        isLoaded: true,
        isLoggedIn: true,
        signInError: undefined,
        registerError: undefined,
        logInModalOpen: false,
      };
    case TYPES.SET_RETURN_URL:
      return { ...state, returnUrl: { pathname: payload } };
    case TYPES.SET_VOTE_FOR_PRODUCT_AFTER_AUTH:
      return { ...state, voteForProductAfterAuth: payload };
    case TYPES.SET_SUGGEST_PRODUCT_AFTER_AUTH:
      return { ...state, suggestProductAfterAuth: payload };
    // SET_SIGN_IN_MESSAGE
    case TYPES.SET_SIGN_IN_MESSAGE:
      return { ...state, signInMessage: payload };
    case TYPES.LOGIN_MODAL_OPEN:
      return {
        ...state,
        logInModalOpen: true,
        showGuest: payload.showGuest,
        isRegister: payload.isRegister,
        returnUrl: { pathname: payload.returnUrl },
      };
    case TYPES.LOGIN_MODAL_CLOSE:
      return { ...state, logInModalOpen: false, showGuest: false };
    case TYPES.SOCIAL_SHARE_MODAL_OPEN:
      return {
        ...state,
        shareReferralModalOpen: true,
        shareReferralModalMode: payload,
      };
    case TYPES.SOCIAL_SHARE_MODAL_CLOSE:
      return { ...state, shareReferralModalOpen: false };
    case TYPES.ZIP_MODAL_OPEN:
      return {
        ...state,
        zipModalOpen: true,
        // shareReferralModalMode: payload
      };
    case TYPES.ZIP_MODAL_CLOSE:
      return { ...state, zipModalOpen: false };
    case TYPES.SIGN_OUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        isError: false,
        isLoaded: false,
        isFetching: false,
        user: {},
        token: "",
      };
    case "SKIP_STRIPE":
      return {
        ...state,
        user: {
          ...state.user,
          skipStripe: true,
        },
      };
    case TYPES.LOGIN_FAIL:
      return {
        ...state,
        user: {},
        isLoggedIn: false,
        signInError: error.message,
        isFetching: false,
        isError: false,
        isLoaded: true,
      };
    case TYPES.USER_REGISTER_FAIL:
      return {
        ...state,
        user: {},
        registerError: error.response.data,
        isFetching: false,
        isError: false,
        isLoaded: true,
        shareReferralModalOpen: false,
      };
    case TYPES.USER_ADMIN_REGISTER_USER_REQUEST:
      return {
        ...state,

        adminRegisterUser: {},
        adminRegisterUserError: undefined,
      };
    case TYPES.USER_ADMIN_REGISTER_USER_SUCCESS:
      return {
        ...state,

        adminRegisterUser: payload,
        adminRegisterUserError: undefined,
      };
    case TYPES.USER_ADMIN_REGISTER_USER_FAIL:
      return {
        ...state,
        adminRegisterUser: {},
        adminRegisterUserError: error.response.data,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        signInError: undefined,
        registerError: undefined,
      };
    case TYPES.STRIPE_FALLBACK_LOCATION_SET:
      return {
        ...state,
        stripeFallbackLocation: payload,
      };
    case TYPES.DEFAULT_LOCATION_SET:
      return {
        ...state,
        user: { ...state.user, defaultLocation: payload },
      };
    case TYPES.CUSTOMER_REVIEW_MODAL_CLOSE:
      return {
        ...state,
        user: {
          ...state.user,
          pendingCustomerReviews: action.review
            ? state.user.pendingCustomerReviews.filter(
                (r) => r.resourceRequestId !== action.review.resourceRequestId
              )
            : state.user.pendingCustomerReviews,
        },
      };
    case TYPES.USERS_INFO_BYOWNERID_SUCCESS:
      if (state.user.humanResourceId) {
        return {
          ...state,
          user: { ...state.user, ...payload },
        };
      }
      return state;

    case TYPES.MERCHANT_REVIEW_MODAL_CLOSE:
      return {
        ...state,
        user: {
          ...state.user,
          pendingMerchantReviews: action.review
            ? state.user.pendingMerchantReviews.filter(
                (r) => r.resourceRequestId !== action.review.resourceRequestId
              )
            : state.user.pendingMerchantReviews,
        },
      };

    case TYPES.LOCATION_BYIP_REQUEST:
      return { ...state, locationByIP: helpers.makeStartLoading() };
    case TYPES.LOCATION_BYIP_SUCCESS:
      return { ...state, locationByIP: helpers.makeLoadSuccess(payload) };
    case TYPES.LOCATION_BYIP_FAIL:
      return { ...state, locationByIP: helpers.makeLoadFailed(error) };
    case TYPES.SKIPPED_FLAGS_SET:
      return { ...state, returnUrl: payload.returnUrl };
    case TYPES.SET_CODE_VALIDATION_START:
      return {
        ...state,
        passwordValidationStatus: helpers.makeValidationStart(),
      };
    case TYPES.SET_CODE_VALIDATION_SUCCESS:
      return {
        ...state,
        passwordValidationStatus: helpers.makeValidationSuccess(),
      };
    case TYPES.SET_VALIDATION_END:
      return {
        ...state,
        passwordValidationStatus: helpers.makeValidationEnd(),
      };
    // case TYPES.CONVERT_CART_FOR_NEW_REGISTRATION_REQUEST:
    //   return { ...state, locationByIP: helpers.makeStartLoading() };
    // case TYPES.CONVERT_CART_FOR_NEW_REGISTRATION_SUCCESS:
    //   return { ...state, locationByIP: helpers.makeLoadSuccess(payload) };
    // case TYPES.CONVERT_CART_FOR_NEW_REGISTRATION_FAIL:
    //   return { ...state, locationByIP: helpers.makeLoadFailed(error) };
    default:
      return state;
  }
};

export const getDefaultLocation = (state) => state.auth.defaultLocation;
export const getDefaultLocationOld = (state) => state.auth.user.defaultLocation;
export const getPendingCustomerReviews = (state) =>
  state.auth.user.pendingCustomerReviews;
export const getPendingMerchantReviews = (state) =>
  state.auth.user.pendingMerchantReviews;
export const getPendingDriverReviews = (state) =>
  state.auth.user.pendingCustomerDeliveryReviews;
export const getIsAdmin = (state) => state.auth.user.isAdmin;
export const getUserOrganizationId = (state) =>
  state.auth.user.resourceOrganization;
export const getUser = (state) => state.auth.user;
export const getUserID = (state) => state.auth.user.userId;
export const getToken = (state) => state.auth.token;
export const getIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getUserStatus = (state) => ({
  isFetching: state.auth.isFetching,
  isError: state.auth.isError,
  isLoaded: state.auth.isLoaded,
});
export const getIsLogInModalOpen = (state) => state.auth.logInModalOpen;
export const getShowGuestOption = (state) => state.auth.showGuest;
export const getReturnUrl = (state) => state.auth.returnUrl;
export const getIsLoggingIn = (state) => state.auth.isFetching;
export const getIsRegister = (state) => state.auth.isRegister;
export const getRegisterErrors = (state) => state.auth.registerError;
export const getSignInErrors = (state) => state.auth.signInError;
export const getCityName = (state) => state.auth.city;
export const getDisplayName = (state) => state.auth.user.displayName;
export const getUserRoles = (state) => state.auth.user.roles;
export const getLocationByIP = (state) => state.auth.locationByIP;
export const getShareReferralModalOpen = (state) =>
  state.auth.shareReferralModalOpen;
export const getShareReferralModalMode = (state) =>
  state.auth.shareReferralModalMode;
export const getStripeFallbackLocation = (state) =>
  state.auth.stripeFallbackLocation;
export const getVoteForProductAfterAuth = (state) =>
  state.auth.voteForProductAfterAuth;
export const getSuggestProductAfterAuth = (state) =>
  state.auth.suggestProductAfterAuth;

export const getSignInMessage = (state) => state.auth.signInMessage;

export const getZipModalOpen = (state) => state.auth.zipModalOpen;

export const getAdminRegisterUser = (state) => state.auth.adminRegisterUser;
export const getAdminRegisterUserError = (state) =>
  state.auth.adminRegisterUserError;

export const getValidationStatus = (state) =>
  state.auth.passwordValidationStatus;

export default authReducer;
