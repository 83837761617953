import moment from "moment";

export const weeks = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const generateSelectOptions = () => {
  const momentDate = moment();
  const options = [];
  const step = 60;

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j <= 59; j += step) {
      options.push(
        momentDate
          .set({ hour: i, minute: j, second: 0, millisecond: 0 })
          .format("hh:mm A")
      );
    }
  }

  options.push("Closed");

  return options;
};

export const hoursSelectedList = () => {
  const selectedList = {};

  for (const week of weeks) {
    selectedList[week] = {
      date: "",
      opens: "",
      closes: "",
      isTouched: false,
    };
  }

  return selectedList;
};

export const modifyBusinessHours = (hoursResponse) => {
  const modifiedList = [];
  const neededFields = {
    monday: hoursResponse.mondayLocal,
    tuesday: hoursResponse.tuesdayLocal,
    wednesday: hoursResponse.wednesdayLocal,
    thursday: hoursResponse.thursdayLocal,
    friday: hoursResponse.fridayLocal,
    saturday: hoursResponse.saturdayLocal,
    sunday: hoursResponse.sundayLocal,
  };

  for (const weekName of Object.keys(neededFields)) {
    if (!neededFields[weekName]) {
      const capitalizedWeekName =
        weekName.charAt(0).toUpperCase() + weekName.slice(1);

      modifiedList.push({
        day: capitalizedWeekName,
        opens: null,
        closes: null,
      });

      continue;
    }

    modifiedList.push(neededFields[weekName]);
  }

  return modifiedList;
};

export const formatMomentDate = (date, format = "hh:mm A") =>
  moment(date).isValid() ? moment(date).format(format) : date;
