import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { withRouter } from "react-router";
// import { connect } from "react-redux";

import WOW from "wowjs";

import "animate.css/animate.min.css";
import { imageServerUrl } from "../../utils";
import SearchBar from "./SearchBar";

let wow = null;

class MainAlbuquerqueBox extends Component {
  componentDidMount() {
    wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="bg-color1 mb-5 text-white">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-md-6 p-5">
              <h3 className="mt-3">
                <strong>*Beta Event</strong>
              </h3>
              <h1>Skipti Comes To ABQ This Halloween</h1>
              <p className="my-3">
                Skipti&apos;s peer-to-peer rental platform is getting spooky
                this Halloween. Rent all your costumes and decorations in one
                place and have them delivered right to your door. Already own a
                costume? Make extra cash by renting out your unused Halloween
                and Dia de los Muertos gear this year.
              </p>
              <div className="skipti-hero" style={{ height: "100px" }}>
                <div className="search-hero mx-auto">
                  <SearchBar
                    placeholderText={`Try searching for "Costumes"`}
                    showIcon
                    inputClassName="w-100 my-3 rotating-placeholder"
                    iconClassName="tw-text-skiptiOrange"
                  />
                </div>
              </div>
              <p>OR</p>
              <div className="owner-msg mt-2">
                <Link
                  to="/items/new"
                  href="/items/new"
                  className="btn-skipti-inverse"
                >
                  List an item
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 p-5">
              <h1 className="my-5">How It Works:</h1>

              <div className="row mb-3">
                <div className="col-2 col-md-3 col-lg-2">
                  <img
                    src={`${imageServerUrl}/main/albuquerque/STEP 1.png`}
                    alt="STEP 1"
                    width="100%"
                  />
                </div>
                <h3 className="col-10 col-md-9 col-lg-10 align-self-center">
                  Search Costumes Or Decor To Rent
                </h3>
              </div>
              <div className="row mb-3">
                <div className="col-2 col-md-3 col-lg-2">
                  <img
                    src={`${imageServerUrl}/main/albuquerque/STEP 2.png`}
                    alt="STEP 2"
                    width="100%"
                  />
                </div>
                <h3 className="col-10 col-md-9 col-lg-10 align-self-center">
                  Select Available Rental Dates
                </h3>
              </div>
              <div className="row mb-3">
                <div className="col-2 col-md-3 col-lg-2">
                  <img
                    src={`${imageServerUrl}/main/albuquerque/STEP 3.png`}
                    alt="STEP 3"
                    width="100%"
                  />
                </div>
                <h3 className="col-10 col-md-9 col-lg-10 align-self-center">
                  Enjoy Door-To-Door Delivery
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainAlbuquerqueBox;
