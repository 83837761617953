import { useTheme, useMediaQuery } from "@material-ui/core";

export const addToken = ({
  token,
  email,
  icon,
  displayName,
  userId,
  orgId,
  refreshToken,
}) => {
  localStorage.setItem("skiptiJWT", token);
  localStorage.setItem("skiptiUserEmail", email);
  localStorage.setItem("skiptiUserAvatar", icon);
  localStorage.setItem("skiptiUserName", displayName);
  localStorage.setItem("skiptiUserId", userId);
  localStorage.setItem("skiptiOrgId", orgId);
  if (refreshToken) localStorage.setItem("skiptiRefreshToken", refreshToken);
};

export const removeToken = () => {
  localStorage.removeItem("skiptiJWT");
  localStorage.removeItem("skiptiUserEmail");
  localStorage.removeItem("skiptiUserAvatar");
  localStorage.removeItem("skiptiUserName");
  localStorage.removeItem("skiptiUserId");
  localStorage.removeItem("skiptiOrgId");
  localStorage.removeItem("skiptiRefreshToken");
};
export const INTERNAL_ORDER_STATUSES = {
  PICKED_UP: 5,
  COMPLETED: 10,
};

export const ITEM_DELIVERY_MODEL = {
  IN_HOUSE: 1,
  REGULAR: 2,
  REPAIR: 3,
  DELIVERY_ONLY: 4,
  FETCH_ONLY: 5,

  // Electronic
  ELECTRONIC_ONLY: 6, // (Replaces current flag, CanDeliveryElectronically)

  // Shipping Options
  SHIP_REGULAR: 7,
  SHIP_REPAIR: 8, // (Replaces current flag, IsShippable)
  SHIP_DELIVER_ONLY: 9,
  SHIP_FETCH_ONLY: 10,

  // In-store Options
  IN_STORE_REGULAR: 11,
  IN_STORE_REPAIR: 12,
  IN_STORE_DELIVER_ONLY: 13, // (i.e.give customer all options for Delivery then Fetch)
  IN_STORE_FETCH_ONLY: 14, // (i.e.give customer all options for Fetch then Deliver)

  // Curbside
  CURBSIDE_REGULAR: 15,
  CURBSIDE_REPAIR: 16,
  CURBSIDE_DELIVER_ONLY: 17,
  CURBSIDE_FETCH_ONLY: 18,

  // Subscription
  SHIP_SUBSCRIPTION: 20,
  COURIER_SUBSCRIPTION: 21,

  // Try & Buy
  COURIER_TRY_AND_BUY: 22,

  // Hybrid
  RENTAL_IN_STORE_COURIER: 23,
  RENTAL_COURIER_IN_STORE: 24,

  // TransportAssist
  TWO_WAY_COURIER_ONLY: 25,
};
export const ITEM_SERVICE_MODEL = {
  REGULAR: [2, 7, 11, 15],
  REPAIR: [3, 8, 12, 16],
  DELIVERY_ONLY: [4, 9, 13, 17],
  FETCH_ONLY: [5, 10, 14, 18],
  ELECTRONIC_ONLY: [6], // (Replaces current flag, CanDeliveryElectronically)
  DEMO: [],
  SUBSCRIPTION: [20, 21],
  TRY_AND_BUY: [22],
  TRANSPORT_ASSIST: [25],
};

export const ITEM_DATES_MODEL = {
  TWO_DATES: [2, 7, 11, 15, 3, 8, 12, 16, 25],
  ONE_DATE: [4, 9, 13, 17, 5, 10, 14, 18, 22],
  // NO_DATES: [],
  // USER_SELECTED: [],
};

export const ITEM_DELIVERY_METHOD = {
  COURIER: 0,
  SHIPPING: 1,
  IN_STORE: 2,
  CURBSIDE: 3,
  ROOMSERVICE: 4,
  ELECTRONIC_ONLY: 6, // (Replaces current flag, CanDeliveryElectronically)
};

export const deliveryMethods = {
  DELIVERY: "delivery",
  IN_STORE: "instore",
  SHIPING: "shiping",
};

export const addressByMethod = {
  store_title: "STORE ADDRESS",
  customer_title: "CUSTOMER ADDRESS",
  order_title: "ORDER ADDRESS",
  delivery_title: "DELIVERY ADDRESS",
  demo_title: "DEMO ADDRESS",
};

export function transformDeliveryModel(
  oldDeliveryModel,
  originalDeliveryMethod
) {
  let deliveryMethod = "";
  if (originalDeliveryMethod.includes("delivery")) {
    deliveryMethod = "COURIER";
  }
  if (originalDeliveryMethod.includes("curbside")) {
    deliveryMethod = "CURBSIDE";
  }
  if (originalDeliveryMethod.includes("instore")) {
    deliveryMethod = "IN_STORE";
  }
  if (originalDeliveryMethod.includes("shipping")) {
    deliveryMethod = "SHIPPING";
  }
  if (originalDeliveryMethod.includes("roomservice")) {
    deliveryMethod = "ROOMSERVICE";
  }

  if (ITEM_SERVICE_MODEL.REGULAR.includes(oldDeliveryModel))
    return ITEM_SERVICE_MODEL.REGULAR[
      ITEM_DELIVERY_METHOD[deliveryMethod.toUpperCase()]
    ];
  if (ITEM_SERVICE_MODEL.REPAIR.includes(oldDeliveryModel))
    return ITEM_SERVICE_MODEL.REPAIR[
      ITEM_DELIVERY_METHOD[deliveryMethod.toUpperCase()]
    ];
  if (ITEM_SERVICE_MODEL.DELIVERY_ONLY.includes(oldDeliveryModel))
    return ITEM_SERVICE_MODEL.DELIVERY_ONLY[
      ITEM_DELIVERY_METHOD[deliveryMethod.toUpperCase()]
    ];
  if (ITEM_SERVICE_MODEL.FETCH_ONLY.includes(oldDeliveryModel))
    return ITEM_SERVICE_MODEL.FETCH_ONLY[
      ITEM_DELIVERY_METHOD[deliveryMethod.toUpperCase()]
    ];

  if (ITEM_SERVICE_MODEL.ELECTRONIC_ONLY === oldDeliveryModel)
    return oldDeliveryModel;
}

export const orderSources = [
  {
    id: 1,
    name: "Skipti",
  },
  {
    id: 2,
    name: "RugDoctor",
  },
  {
    id: 3,
    name: "Home Depot",
  },
];
// fulfillmentType int                     0=Courier;1=Shipping;2=Instore;3=Curbside;4=RoomService
// public enum FulfillmentType
//     {
//         Unknown,Courier,Shipping,Instore,Curbside,RoomService,
//         HybridCourierInstore=100,
//         HybridInstoreCourier=101
//     }

export const fulfillmentTypes = {
  Unknown: {
    id: 0,
    name: "Unknown",
    method: "unknown",
    deliveryDateText: "Delivery",
    returnDateText: "Return",
    addressLabel: "Your address",
    signatureText: "Signature Required",
    shippingLabel: "Delivery:",
    price: 0,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    fetchFulfillmentTypeLabel: "Select your return method",
    deliveryWindowLabel: "Delivery time window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change delivery time",
    changeFetchTimeText: "Change return time",
    routesCount: 2,
  },
  Courier: {
    id: 1,
    name: "Courier",
    method: "delivery",
    deliveryDateText: "Start",
    returnDateText: "End",
    addressLabel: "Your delivery address",
    signatureText:
      "This item requires a photo as proof of delivery. By clicking this box, you agree that you will be available to meet the driver outside upon drop off and pickup.",
    signatureTextPorchDropOff:
      "This item does not require you to be present during drop-off or pickup. By clicking this box, you agree to take appropriate action to secure your rental after it has been delivered.",
    shippingLabel: "Roundtrip Courier:",
    price: 30,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    fetchFulfillmentTypeLabel: "Select your return method",
    deliveryWindowLabel: "Delivery time window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change delivery time",
    changeFetchTimeText: "Change return time",
    thankYouText:
      "You'll receive a text message when your order is on its way. You can track your order status in your dashboard ",
    routesCount: 2,
  },
  Shipping: {
    id: 2,
    name: "Shipping",
    method: "shipping",
    deliveryDateText: "Delivery",
    returnDateText: "Return",
    addressLabel: "Your delivery address",
    signatureText:
      "All orders arrive via 2-day shipping. By clicking this box, you agree that your order's start date accounts for minimum shipment time and possible shipping partner delays which may occur after package posting.",
    shippingLabel: "Shipping:",
    price: 0,
    deliveryFulfillmentTypeLabel: "Delivery shipment details:",
    fetchFulfillmentTypeLabel: "Return shipment details:",
    deliveryWindowLabel: "Delivery time window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change delivery time",
    changeFetchTimeText: "Change return time",
    routesCount: 2,
  },
  Instore: {
    id: 3,
    name: "Instore",
    method: "instore",
    deliveryDateText: "Start",
    returnDateText: "End",
    addressLabel: "Your address",
    signatureText:
      "You have selected in-store for rental pick-up and return. By clicking this box you agree to arrive in a timely manner for your selected windows.",
    shippingLabel: "",
    price: 0,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    fetchFulfillmentTypeLabel: "Select your return method",
    deliveryWindowLabel: "Pick up window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change pick up time",
    changeFetchTimeText: "Change return time",
    thankYouText:
      "You'll receive a text when your order is ready for pick up and when its time for your In-Store return. You can track your order status in your dashboard ",
    routesCount: 2,
  },
  Curbside: {
    id: 4,
    name: "Curbside",
    method: "curbside",
    deliveryDateText: "Delivery",
    returnDateText: "Return",
    addressLabel: "Your address",
    signatureText:
      "You have selected curbside for rental pick-up and return. By clicking this box you agree to arrive in a timely manner for your selected windows.",
    shippingLabel: "",
    price: 0,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    fetchFulfillmentTypeLabel: "Select your return method",
    deliveryWindowLabel: "Pick up window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change pick up time",
    changeFetchTimeText: "Change return time",
    routesCount: 2,
  },
  RoomService: {
    id: 5,
    name: "RoomService",
    method: "roomService",
    deliveryDateText: "Delivery",
    returnDateText: "Return",
    addressLabel: "Your address",
    signatureText: "Signature Required",
    shippingLabel: "Delivery:",
    price: 0,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    fetchFulfillmentTypeLabel: "Select your return method",
    deliveryWindowLabel: "Delivery time window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change delivery time",
    changeFetchTimeText: "Change return time",
    routesCount: 2,
  },
  HybridCourierInstore: {
    id: 100,
    name: "HybridCourierInstore",
    method: "delivery/instore",
    deliveryDateText: "Start",
    returnDateText: "End",
    addressLabel: "Your address",
    signatureText:
      "You have selected partial-courier service. By clicking this box you agree to arrive on time for any selected store pick-up or return windows. You also agree to secure delivered rental goods and/or to place them in an accessible location for courier pick-up.",
    shippingLabel: "White Glove Courier:",
    price: 15,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    fetchFulfillmentTypeLabel: "Select your return method",
    deliveryWindowLabel: "Delivery time window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change delivery time",
    changeFetchTimeText: "Change return time",
    thankYouText:
      "You'll receive a text when your order is on its way and when its time complete your In-Store return. You can track your order status in your dashboard ",
    routesCount: 2,
  },
  HybridInstoreCourier: {
    id: 101,
    name: "HybridInstoreCourier",
    method: "instore/delivery",
    deliveryDateText: "Start",
    returnDateText: "End",
    addressLabel: "Your address",
    signatureText:
      "You have selected partial-courier service. By clicking this box you agree to arrive on time for any selected store pick-up or return windows. You also agree to secure delivered rental goods and/or to place them in an accessible location for courier pick-up.",
    shippingLabel: "White Glove Courier:",
    price: 15,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    fetchFulfillmentTypeLabel: "Select your return method",
    deliveryWindowLabel: "Pick up window:",
    fetchWindowLabel: "Return time window:",
    changeDeliveryTimeText: "Change pick up time",
    changeFetchTimeText: "Change return time",
    datePickerLabel: "Select your rental dates:",
    datePickerLabelSelected: "Your selected rental dates:",
    thankYouText:
      "You'll receive a text when your order is ready for pick up and when our driver is on their way to pick up your return. You can track your order status in your dashboard ",
    routesCount: 2,
  },
  //   FulfillmentType.CourierOutboundOnly=200
  CourierOutboundOnly: {
    id: 200,
    name: "CourierOutboundOnly",
    method: "delivery",
    deliveryDateText: "Delivery",
    returnDateText: "Return",
    addressLabel: "Your address",
    signatureText:
      "This item requires a photo as proof of delivery. By clicking this box, you agree that you will be available to meet the driver outside upon drop off and pickup.",
    signatureTextPorchDropOff:
      "This item does not require you to be present during drop-off or pickup. By clicking this box, you agree to take appropriate action to secure your rental after it has been delivered.",
    shippingLabel: "White Glove Courier:",
    price: 15,
    deliveryFulfillmentTypeLabel: "Select your delivery method",
    deliveryWindowLabel: "Delivery time window:",
    changeDeliveryTimeText: "Change delivery time",
    datePickerLabel: "Select your delivery date:",
    datePickerLabelSelected: "Your selected delivery date:",
    routesCount: 1,
  },
  // FulfillmentType.CourierInboundOnly=201
  CourierInboundOnly: {
    id: 201,
    name: "CourierInboundOnly",
    method: "delivery",
    deliveryDateText: "Delivery",
    returnDateText: "Return",
    addressLabel: "Your address",
    signatureText:
      "This item requires a photo as proof of delivery. By clicking this box, you agree that you will be available to meet the driver outside upon drop off and pickup.",
    signatureTextPorchDropOff:
      "This item does not require you to be present during drop-off or pickup. By clicking this box, you agree to take appropriate action to secure your rental after it has been delivered.",
    shippingLabel: "White Glove Courier:",
    price: 15,
    fetchFulfillmentTypeLabel: "Select your return method",
    fetchWindowLabel: "Return time window:",
    deliveryWindowLabel: "Return time window:",
    changeFetchTimeText: "Change return time",
    changeDeliveryTimeText: "Change return time",
    datePickerLabel: "Select your return date:",
    datePickerLabelSelected: "Your selected return date:",
    routesCount: 1,
  },
};

export const findFulfillmentObject = (id) => {
  const _object = Object.values(fulfillmentTypes).find(
    (experienceType) => experienceType.id === id
  );
  return _object;
};

/**
 * Check fulfillment type
 * @param {number} fulfillmentType base fulfillment type
 * @param {number | Array<number>} includes list of fulfillment type
 * @return {boolean}
 */
export const checkFulfillmentType = (fulfillmentType, includes) => {
  if (!fulfillmentType) {
    return false;
  }

  const fulfillmentTypeIncludes = Array.isArray(includes)
    ? includes
    : [includes];

  return fulfillmentTypeIncludes.includes(fulfillmentType);
};

export const setFulfillmentTypeByProperty = (
  byProperty,
  deliveryFulfillment,
  fetchFulfillment = null
) => {
  if (typeof deliveryFulfillment === "undefined") deliveryFulfillment = null;
  if (typeof fetchFulfillment === "undefined") fetchFulfillment = null;

  let selectedFulfillmentTypeObject = fulfillmentTypes.Courier;
  const fulfillmentTypesArray = Object.values(fulfillmentTypes);
  const deliveryFulfillmentTypeObject = fulfillmentTypesArray.find(
    (fulfillmentType) => fulfillmentType[byProperty] === deliveryFulfillment
  );
  const fetchFulfillmentTypeObject = fulfillmentTypesArray.find(
    (fulfillmentType) => fulfillmentType[byProperty] === fetchFulfillment
  );
  if (deliveryFulfillmentTypeObject && fetchFulfillmentTypeObject) {
    if (deliveryFulfillmentTypeObject.id === fetchFulfillmentTypeObject.id) {
      selectedFulfillmentTypeObject = deliveryFulfillmentTypeObject;
    } else if (
      deliveryFulfillmentTypeObject.id === fulfillmentTypes.Courier.id &&
      fetchFulfillmentTypeObject.id === fulfillmentTypes.Instore.id
    ) {
      selectedFulfillmentTypeObject = fulfillmentTypes.HybridCourierInstore;
    } else if (
      deliveryFulfillmentTypeObject.id === fulfillmentTypes.Instore.id &&
      fetchFulfillmentTypeObject.id === fulfillmentTypes.Courier.id
    ) {
      selectedFulfillmentTypeObject = fulfillmentTypes.HybridInstoreCourier;
    }
  } else if (deliveryFulfillmentTypeObject) {
    selectedFulfillmentTypeObject = deliveryFulfillmentTypeObject;
  } else if (fetchFulfillmentTypeObject) {
    selectedFulfillmentTypeObject = fetchFulfillmentTypeObject;
  }
  return selectedFulfillmentTypeObject;
};

// experienceType int                   0=Rental; 1=Repair,2=Demo; 3=Retail; 4=Return
// updated list:  Unknown,    //0
// Rental,     //1
// Repair,     //2
// Demo,       //3
// Retail,     //4
// Return,     //5
// Subscription,   //6
// TryAndBuy  //7
export const experienceTypes = {
  Unknown: {
    id: 0,
    name: "Unknown",
    oldName: "UNKNOWN",
  },
  Rental: {
    id: 1,
    name: "Rental",
    oldName: "REGULAR",
  },
  Repair: {
    id: 2,
    name: "Repair",
    oldName: "REPAIR",
  },
  Demo: {
    id: 3,
    name: "Demo",
    oldName: "DEMO",
  },
  Retail: {
    id: 4,
    name: "Retail",
    oldName: "DELIVERY_ONLY",
  },
  Return: {
    id: 5,
    name: "Return",
    oldName: "FETCH_ONLY",
  },
  Subscription: {
    id: 6,
    name: "Subscription",
    oldName: "SUBSCRIPTION",
  },
  TryAndBuy: {
    id: 7,
    name: "Try And Buy",
    oldName: "TRY_AND_BUY",
  },
  // TransportAssist=8
  TransportAssist: {
    id: 8,
    name: "Transport Assist",
    oldName: "TRANSPORT_ASSIST",
  },
};

export const getExperienceTypeFromModel = (model) => {
  const x = Object.keys(ITEM_SERVICE_MODEL);
  const y = x.find((key) => ITEM_SERVICE_MODEL[key].includes(model));

  return experienceTypes.find((e) => e.oldName === y).id;
};

export const DELIVERY_PARTNER = {
  DELIVCO: 0,
  WORKWAVESKIPTI: 1,
  POSTMATES: 2,
  LOCALINHOUSE: 3,
  NONE: 4,
  EASYPOST: 5,
};

export function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

export const phoneNumRegex =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const validateUSAPhoneNumber = (phoneNumber) => {
  const regex =
    /^(\d{10}|\d{3}[-\s]?\d{3}[-\s]?\d{4}|\(\d{3}\)\s?\d{3}[-\s]?\d{4})$/;

  return regex.test(phoneNumber);
};

export const phoneNumberValidator = (values) => {
  const { defaultPhoneNumber } = values;
  const error = {};
  if (!/[0-9]/.test(defaultPhoneNumber)) {
    error.defaultPhoneNumber = "Phone field is required.";
    return error;
  }

  if (
    /[0-9]/.test(defaultPhoneNumber) &&
    (defaultPhoneNumber.length === 10 || defaultPhoneNumber.length === 11)
  ) {
    return error;
  }

  if (
    !phoneNumRegex.test(defaultPhoneNumber) &&
    defaultPhoneNumber.length > 0
  ) {
    error.defaultPhoneNumber = "Incorrect phone number";
  }
  return error;
};

export const DEMO_ORDER_STATUS_CODES = {
  15: "Demo Scheduled",
  45: "Demo Live",
  46: "Demo Completed",
};

export const BUY_ORDER_STATUS_CODES = {
  40: "Delivered",
  100: "Canceled",
  200: "Interrupted",
};

export const STATUS_CODES = {
  10: "Placed",
  15: "Demo Scheduled",
  20: "Pickup Started",
  30: "In Transit",
  35: "Pick Up Started",
  40: "Active",
  45: "Demo Live",
  46: "Demo Completed",
  50: "Return Started",
  60: "In Transit",
  70: "Complete", // Returned
  100: "Canceled",
  200: "Interrupted",
  500: "Head To Store",
  501: "Head To Store",
};

/** [BE] Original Statuses Structure  */
export const ORIGINAL_STATUSES = {
  ORDER_PLACED: 10,
  DEMO_SCHEDULED: 15,
  PICK_UP_STARTED: 20,
  IN_TRANSIT: 30,
  DRIVER_ARRIVED: 35,
  ORDER_ACTIVE: 40,
  DEMO_LIVE: 45,
  DEMO_COMPLETED: 46,
  RETURN_STARTED: 50,
  IN_TRANSIT_RETURN: 60,
  RETURNED: 70,
  CANCELED: 100,
  INTERRUPTED: 200,
  HEAD_TO_STORE: 500,
  HEAD_TO_STORE_2: 501,
};

export function isValidZipCode(zipCode) {
  return /^(?!00000)\d{5}(?:[-\s]\d{4})?$/.test(zipCode);
}
export const getFulfillmetTypeRoutesCount = (id) => {
  switch (id) {
    case fulfillmentTypes.Courier.id: {
      return fulfillmentTypes.Courier;
    }
    case fulfillmentTypes.CourierInboundOnly.id: {
      return fulfillmentTypes.CourierInboundOnly;
    }
    case fulfillmentTypes.CourierOutboundOnly.id: {
      return fulfillmentTypes.CourierOutboundOnly;
    }
    case fulfillmentTypes.Curbside.id: {
      return fulfillmentTypes.Curbside;
    }
    case fulfillmentTypes.HybridCourierInstore.id: {
      return fulfillmentTypes.HybridCourierInstore;
    }
    case fulfillmentTypes.HybridInstoreCourier.id: {
      return fulfillmentTypes.HybridInstoreCourier;
    }
    case fulfillmentTypes.Instore.id: {
      return fulfillmentTypes.Instore;
    }
    case fulfillmentTypes.RoomService.id: {
      return fulfillmentTypes.RoomService;
    }
    case fulfillmentTypes.Shipping.id: {
      return fulfillmentTypes.Shipping;
    }
    default:
      return fulfillmentTypes.Unknown;
  }
};
