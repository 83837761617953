import React, { Component } from "react";
import "animate.css/animate.min.css";
import WOW from "wowjs";

let wow = null;
// const image = "https://dummyimage.com/871x688/ccc/fff&text=+";
// const textHtml = `<h2 class="tw-text-skiptiOrange">Snap, post, earn.<br>No meetups required.</h2>
// <p class="text-color4">And sit quo. Aut voluptas tenetur and consequatur dolorem and sed occaecati fugit. Nobis nostrum and whoever who.</p>
// <a href="#" class="btn-skipti-primary mr-3">Primary</a>`;
class Featurette extends Component {
  componentDidMount() {
    wow = new WOW.WOW();
    wow.init();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.image !== prevProps.image) {
      wow.sync();
    }
  }

  render() {
    const { props } = this;
    return (
      // type: 0 - image, 1-video
      <div className="tw-mx-auto xl:tw-my-12 tw-container">
        <div
          className={`featurette ${
            props.right ? "tw-text-right" : "tw-w-3/4"
          }   wow fadeIn`}
          data-wow-duration="2.5s"
        >
          {props.type === 0 && props.image ? (
            <img
              src={props.image}
              alt=""
              className={`"img-fluid ${
                props.imageMaxWidth ? props.imageMaxWidth : "tw-max-w-full"
              } tw-h-auto wow slideInUp tw-inline"`}
              data-wow-duration="2.5s"
            />
          ) : null}
          {props.type === 1 && props.video ? (
            <iframe
              title="video"
              className="embed-responsive-item"
              src={props.video}
              allowFullScreen
            />
          ) : null}
          {/* <ScrollAnimation 
          animateIn={`${props.left ? "bounceInRight" : ""} ${
            props.right ? "bounceInLeft" : ""
          }`} */}
          <div
            className={`text-container ${props.left ? "left" : ""} ${
              props.right ? "right tw-text-left" : ""
            } tw-bg-white tw-w-1/4 tw-p-12 wow slideInUp`}
          >
            {props.children}
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Featurette;
