import React, { useState } from "react";

import OptionalServiceDisablePopper from "./OptionalServiceDisablePopper";
import { imageServerUrl } from "../../utils";

const ItemListTile = ({
  itemInCart,
  handleRemoveFromCart,
  showRemove,
  isAddOn,
  hideQuantities,
  showRetailPrice,
  handleRemoveDamageProtection,
  isCompletePage,
  showPrice = true,
  showDamageProtection = true,
}) => {
  const [optionalServiceDisable, setOptionalServiceDisable] = useState(false);
  const [anchorElDisable, setAnchorElDisable] = useState(null);

  const image = isAddOn
    ? itemInCart.imageUrl || `${imageServerUrl}/no-image.png`
    : itemInCart.thumbNailUrl || itemInCart?.productPhotos[0]?.file;

  return (
    <React.Fragment key={itemInCart.resourceId}>
      <div className="tw-flex tw-flex-nowrap tw-justify-between tw-mb-5">
        <div className="tw-flex tw-flex-nowrap tw-justify-self-start">
          <div className="thumbnail tw-grid tw-content-center">
            <img
              src={image}
              alt={`${isAddOn ? itemInCart.name : itemInCart.productName}`}
              className="thumbnail"
            />
          </div>
          <div className="tw-grid tw-content-center">
            <div className="productName">
              {isAddOn ? itemInCart.name : itemInCart.productName}
            </div>
            <div className="tw-flex tw-flex-nowrap productQuantity">
              {!hideQuantities && (
                <div className="">Quantity: {itemInCart.quantity}</div>
              )}{" "}
              {!hideQuantities && showRemove && (
                <div className="text-style-1 tw-mx-1"> | </div>
              )}
              {showRemove && (
                <>
                  <div
                    className="text-style-2"
                    onClick={() => handleRemoveFromCart(itemInCart)}
                  >
                    Remove
                  </div>
                </>
              )}
            </div>
            {isCompletePage && itemInCart.useAndCareManualUrl ? (
              <div className="tw-flex tw-flex-nowrap productQuantity">
                <a
                  className="tw-text-skiptiOrange"
                  href={itemInCart.useAndCareManualUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Use and Care Manual
                </a>
              </div>
            ) : null}
          </div>
        </div>
        {showPrice ? (
          <div className="tw-grid tw-content-center tw-float-right tw-ml-2">
            <div className="productPrice">
              $
              {showRetailPrice
                ? itemInCart.retailUsd
                : isAddOn
                ? itemInCart.unitPrice?.amount.toFixed(2)
                : itemInCart.baseCost?.amount?.toFixed(2) ||
                  itemInCart.costPerUnitUsd?.toFixed(2) ||
                  itemInCart.basePerDayUsd?.toFixed(2)}
            </div>
          </div>
        ) : null}
      </div>
      {showDamageProtection &&
        itemInCart.hasDamageProtection &&
        itemInCart.damageProtection && (
          <div className="tw-flex tw-flex-nowrap tw-justify-between tw-mb-5">
            <div className="tw-flex tw-flex-nowrap tw-justify-self-start">
              <div className="thumbnail tw-grid tw-content-center">
                <img
                  src={`${itemInCart.damageProtection.imageUrl}`}
                  alt={`${itemInCart.productName}-damage-protection`}
                  className="thumbnail"
                />
              </div>
              <div className="tw-grid tw-content-center">
                <div className="productName">
                  {itemInCart.damageProtection.title}
                </div>
                <div className="tw-flex tw-flex-nowrap productQuantity">
                  {!hideQuantities && (
                    <div className="">Quantity: {itemInCart.quantity}</div>
                  )}{" "}
                  {!hideQuantities && (
                    <div className="text-style-1 tw-mx-1"> | </div>
                  )}
                  {!hideQuantities && (
                    <>
                      <div
                        className="text-style-2"
                        onClick={(event) => {
                          setAnchorElDisable(event.currentTarget);
                          setOptionalServiceDisable(true);
                        }}
                      >
                        Remove
                      </div>
                      <OptionalServiceDisablePopper
                        isOpen={optionalServiceDisable}
                        anchorEl={anchorElDisable}
                        handleClose={() => {
                          setOptionalServiceDisable(false);
                        }}
                        title={itemInCart.damageProtection.disablePopupTitle}
                        description={
                          itemInCart.damageProtection.disablePopupText
                        }
                        onProceed={() => {
                          handleRemoveDamageProtection(itemInCart);
                          setOptionalServiceDisable(false);
                        }}
                        onCancel={() => {
                          setOptionalServiceDisable(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="tw-grid tw-content-center tw-float-right tw-ml-2">
              <div className="productPrice">
                ${itemInCart.damageProtection?.rentalPrice?.amount.toFixed(2)}
              </div>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default ItemListTile;
