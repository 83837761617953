export const stepsText = {
  zipCodeStep: {
    mainText: "Please enter your zipcode to see if you are in a serviced area.",
    additionalText: "",
    fieldName: "zipCode",
    buttonText: "CONTINUE",
    fieldType: "number",
    placeholder: "Your ZIP code",
  },
  unservicedAreaStep: {
    mainText: "Skipti doesn’t deliver to your area yet.",
    additionalText: "Want Skipti delivery? Drop your email below!",
    fieldName: "email",
    buttonText: "CONTINUE",
    fieldType: "email",
    placeholder: "Your email address",
  },
  thankYouStep: {
    mainText: "Thanks for the submission",
    buttonText: "TRY NEW ZIP CODE",
  },
  withProducts: {
    fieldName: "email",
    buttonText: "NOTIFY ME WHEN AVAILABLE",
    fieldType: "email",
    placeholder: "Your email address",
  },
  withProductsStep: {
    mainText: "Skipti doesn’t deliver to your area yet.",
    additionalText: "Want Skipti delivery? Drop your email below!",
    fieldName: "email",
    buttonText: "CONTINUE",
    fieldType: "email",
    placeholder: "Your email address",
  },
};

export const steps = {
  zipCodeStep: "zipCodeStep",
  unservicedAreaStep: "unservicedAreaStep",
  withProductsStep: "withProductsStep",
  thankYouStep: "thankYouStep",
  howDoesItWork: "howDoesItWork",
};

export const additionalTexts = {
  zipCodeStep: "How does this work?",
  unservicedAreaStep: "Try different Zip code",
  thankYouStep: "Browse out of area products",
  emailMeWhenAvailable: "Email me when available",
};
