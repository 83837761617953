import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useHistory } from "react-router";
import moment from "moment";
import WOW from "wowjs";
import { CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { getUser, getIsLoggedIn } from "../../../reducers/authReducer";
import {
  getProductById,
  getItemPricingInfo,
  getDateRange,
  getZip,
  getIsAvailabilityError,
} from "../../../reducers/itemsReducer";
import LayoutItem from "../../layouts/LayoutItem";
import ItemShowCarousel from "./ItemShowCarousel";
import MobilePricebar from "./MobilePricebar";
import {
  setDateRange,
  fetchProductById,
  fetchAvailabilityCalendar,
  openLogInModal,
  computePrice,
  resetProductById,
} from "../../../actions";
import ItemShowDescription from "./ItemShowDescription";
import ItemShowCard from "./ItemShowCard";
import ItemTile from "../../partials/ItemTile";
import HowDoRentalsWork from "../../skipti/HowDoRentalsWork";
import OwnerInterest from "../../partials/OwnerInterest";
import ItemShowHelmet from "./ItemShowHelmet";
import { ITEM_DELIVERY_MODEL } from "../../../actions/utils";
import RegisterInterestAlert from "../../utils/RegisterInterestAlert";
import { rudDoctorReplacementList } from "../../../utils/constants";
import { setChosenProduct } from "../item_upsell/CartSlice";
import { getRegion } from "../../../reducers/userReducer";
import { SHOW_PRODUCTS_IN_POPUP, ZIP_MODAL_OPEN } from "../../../types";

let timer;

export default function ItemShow(props) {
  const { mixpanel } = props;
  const [pageMode, setPageMode] = useState("rent");
  const [pId, setPid] = useState(null);
  const router = useHistory();
  const [voted, setVoted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isOpenInterestAlert, setOpenInterestAlert] = useState(false);
  const [interestData, setInterestData] = useState();

  const currentUser = useSelector(getUser);
  const product = useSelector(getProductById);
  const dateRange = useSelector(getDateRange);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const currentRegion = useSelector(getRegion);

  const history = useHistory();
  const location = useLocation();

  const pricingInfo = useSelector(getItemPricingInfo);
  const {
    total,
    salesTax,
    subtotal,
    delivery,
    productCosts,
    skiptiCredit,
    insurance,
    productNames,
  } = pricingInfo;
  const dispatch = useDispatch();
  const isAvailabilityError = useSelector(getIsAvailabilityError);
  const match = useRouteMatch();
  const safeProduct = product.data || {
    includes: [],
    rules: [],
    reviews: [],
    renterCostPerDayUsd: 0,
    productCategories: [{}],
    associatedProducts: [],
    similarProducts: [],
  };

  // const pId = match.params.id;
  const wow = new WOW.WOW();
  const zip = useSelector(getZip);

  const checkPageMode = useCallback(() => {
    if (
      safeProduct.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY ||
      safeProduct.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY
    ) {
      dispatch(
        computePrice({
          startDate: moment(new Date()).utc(),
          endDate: moment(new Date()).add(1, "days").utc(),
        })
      );
      setPageMode("deliveryOnly");
    }

    // check for hardcoded resourceId
    if (safeProduct.resourceId === "6a5e40eb-7b61-4843-b63e-25f88c653a4d") {
      setPageMode("hardcoded");
    }

    // check if it's votable
    if (safeProduct.votesToAcquire > 0) {
      setPageMode("vote");
    }

    if (safeProduct.canDeliverElectronically === true) {
      dispatch(
        computePrice({
          startDate: moment(new Date()).utc(),
          endDate: moment(new Date()).add(1, "days").utc(),
        })
      );
      setPageMode("download");
    }
    if (safeProduct.deliveryModel === ITEM_DELIVERY_MODEL.ELECTRONIC_ONLY) {
      setPageMode("subscription");
    }
  }, [
    dispatch,
    safeProduct.canDeliverElectronically,
    safeProduct.deliveryModel,
    safeProduct.resourceId,
    safeProduct.votesToAcquire,
  ]);

  useEffect(() => {
    const _lowerCaseId = match.params.id.toLowerCase();

    if (rudDoctorReplacementList.hasOwnProperty(_lowerCaseId)) {
      const replacementProductId = rudDoctorReplacementList[_lowerCaseId];

      setPid(replacementProductId);

      router.replace(`/items/${replacementProductId}`);

      return;
    }

    setPid(_lowerCaseId);
  }, [match.params.id]);

  useEffect(() => {
    if (safeProduct.productName) {
      mixpanel.track("Entered View an Item", {
        productResourceId: match.params.id,
        productCategory: safeProduct.productCategories
          ? safeProduct?.productCategories[0]?.name
          : "",
        productName: safeProduct.productName,
        price: safeProduct.basePerDayUsd,
      });
      setEditMode(
        !!(
          safeProduct &&
          safeProduct.owners &&
          safeProduct.owners[0] &&
          safeProduct.owners[0].userId === currentUser.userId
        )
      );
      window.analytics.page("Product Details", {
        productResourceId: match.params.id,
        productCategory: safeProduct.productCategories
          ? safeProduct?.productCategories[0]?.name
          : "",
        productName: safeProduct.productName,
        price: safeProduct.basePerDayUsd,
        organizationId: safeProduct.organizationId,
      });
      window.analytics.track(`Product Viewed`, {
        productResourceId: match.params.id,
        productCategory: safeProduct.productCategories
          ? safeProduct?.productCategories[0]?.name
          : "",
        productName: safeProduct.productName,
        price: safeProduct.basePerDayUsd,
        organizationId: safeProduct.organizationId,
      });
    }
  }, [
    safeProduct.productName,
    currentUser.userId,
    match.params.id,
    mixpanel,
    safeProduct,
  ]);

  useEffect(() => {
    if (pId && safeProduct.resourceId !== pId) {
      dispatch({ type: SHOW_PRODUCTS_IN_POPUP });
      dispatch(setDateRange(null, null));
      setVoted(localStorage.getItem("votedAnItem"));
      dispatch(fetchProductById(pId, false, zip));
    }
  }, [pId, dispatch, safeProduct.resourceId, zip]);

  useEffect(() => {
    if (product.isLoaded) {
      const queryString = new URLSearchParams(location.search);

      if (product.data) {
        setInterestData({
          popupLogo: product.data.popupLogo || "",
          bodyText: product.data.similarProductAvailableHtml || "",
        });

        if (queryString.has("alternative")) {
          setOpenInterestAlert(true);
        }
      }
    }
  }, [product]);

  // --- Unmount
  useEffect(() => {
    return () => {
      dispatch(resetProductById());
    };
  }, []);

  useEffect(() => {
    if (pId) {
      dispatch(
        fetchAvailabilityCalendar(
          pId,
          moment().startOf("day"),
          moment().add(2, "month").endOf("month")
        )
      );
    }
  }, [pId, dispatch, zip]);

  useEffect(() => {
    wow.init();

    timer = setTimeout(() => {
      if (window.innerWidth < 765) {
        const tidioChatIframe = document.getElementById("tidio-chat-iframe");
        //
        if (tidioChatIframe) {
          tidioChatIframe.classList.add("tidio-chat-button-mobile");
        }
      }
    }, 1500);
    return () => {
      //   cleanup;
      clearTimeout(timer);
      const tidioElement = document.getElementById("tidio-chat-iframe");
      if (tidioElement)
        tidioElement.classList.remove("tidio-chat-button-mobile");
    };
  }, [wow]);

  useEffect(() => {
    if (safeProduct.resourceId === match.params.id) {
      checkPageMode();
    }
  }, [
    product.isLoaded,
    match.params.id,
    checkPageMode,
    safeProduct.resourceId,
  ]);

  const onCloseInterestAlert = () => {
    setOpenInterestAlert(false);
  };

  const handleRent = () => {
    if (!currentRegion.isServiced || isAvailabilityError) {
      dispatch({ type: SHOW_PRODUCTS_IN_POPUP });
      dispatch({ type: ZIP_MODAL_OPEN });
      return;
    }

    const { startDate, endDate } = dateRange;
    const duration = endDate
      ? moment.duration(endDate.diff(startDate)).asDays()
      : 0;

    const durationFloored = Math.floor(duration);
    dispatch(setChosenProduct([safeProduct]));
    if (durationFloored <= safeProduct.maximumRentalDurationDays) {
      mixpanel.track("Clicked on Rent It Button", {
        productResourceId: match.params.id,
      });
      let redirectUrl = "";
      if (safeProduct.isBundle) {
        if (safeProduct.productName.toLowerCase().includes("subscription")) {
          redirectUrl = `/items/subscription/${safeProduct.resourceId}`;
        } else if (
          safeProduct.deliveryModels.includes(
            ITEM_DELIVERY_MODEL.COURIER_TRY_AND_BUY
          )
        ) {
          redirectUrl = `/items/appointment/${safeProduct.resourceId}`;
        } else {
          redirectUrl = `/items/demo/${safeProduct.resourceId}`;
        }
      } else if (
        safeProduct.inBundle &&
        safeProduct.bundleParent !== "00000000-0000-0000-0000-000000000000"
      ) {
        if (
          safeProduct.deliveryModels.includes(
            ITEM_DELIVERY_MODEL.COURIER_TRY_AND_BUY
          )
        ) {
          redirectUrl = `/items/appointment/${safeProduct.resourceId}`;
        } else {
          redirectUrl = `/items/demo/${
            safeProduct.bundleParent
          }/?selectedItem=${safeProduct.resourceId}&startDate=${moment(
            dateRange.startDate
          )
            .utc()
            .unix()}&endDate=${moment(dateRange.endDate).utc().unix()}`;
        }
      } else if (
        safeProduct.deliveryModel === ITEM_DELIVERY_MODEL.ELECTRONIC_ONLY
      ) {
        redirectUrl = `/items/buy/${safeProduct.resourceId}`;
      } else {
        redirectUrl = `/items/rent/${safeProduct.resourceId}`;
      }
      if (isLoggedIn) {
        history.push(redirectUrl);
      } else {
        const isRugDoctorProduct =
          safeProduct.organizationId ===
          process.env.REACT_APP_RUG_DOCTOR_ORG_ID;
        dispatch(openLogInModal(true, redirectUrl, isRugDoctorProduct));
      }
    }
  };

  const handleDeliver = () => {
    mixpanel.track("Clicked on Buy It Button", {
      productResourceId: match.params.id,
    });
    if (isLoggedIn) {
      history.push(`/items/deliver/${safeProduct.resourceId}`);
    } else {
      dispatch(
        openLogInModal(true, `/items/deliver/${safeProduct.resourceId}`)
      );
    }
  };
  const rentDuration =
    moment(dateRange.startDate).isValid() &&
    moment(dateRange.endDate).isValid() &&
    Math.round(
      moment
        .duration(moment(dateRange.endDate).diff(moment(dateRange.startDate)))
        .asDays()
    );

  return (
    <div>
      <ItemShowHelmet product={safeProduct} current_user={currentUser} />
      <LayoutItem showSearch>
        {product && product.isFetching ? (
          <div className="tw-text-center tw-mt-16">
            <div className="tw-flex tw-justify-center">
              <CircularProgress color="primary" />
            </div>
            <h3>Loading...</h3>
          </div>
        ) : (
          <div className="item-details" key="1">
            <ItemShowCarousel product={safeProduct} />

            <div
              id="item-description"
              className="tw-container tw-mx-auto tw-my-12"
            >
              <div className="tw-flex tw-mx-0 tw-flex-wrap">
                <ItemShowDescription
                  product={safeProduct}
                  pricingInfo={pricingInfo}
                  rentDuration={rentDuration}
                  pageMode={pageMode}
                  voted={voted}
                />

                <ItemShowCard
                  product={safeProduct}
                  pageMode={pageMode}
                  pricingInfo={pricingInfo}
                  editMode={editMode}
                  rentDuration={rentDuration}
                  handleRent={handleRent}
                  handleDeliver={handleDeliver}
                  pId={pId}
                  isAppointment={
                    safeProduct &&
                    safeProduct.deliveryModels &&
                    safeProduct.deliveryModels.includes(
                      ITEM_DELIVERY_MODEL.COURIER_TRY_AND_BUY
                    )
                  }
                />
              </div>
            </div>
            <div className="tw-my-6 tw-px-4 tw-container tw-mx-auto">
              <MobilePricebar
                product={safeProduct}
                productNames={productNames}
                productCosts={productCosts}
                rentDuration={rentDuration}
                total={total}
                salesTax={salesTax}
                subtotal={subtotal}
                delivery={delivery}
                skiptiCredit={skiptiCredit}
                insurance={insurance}
                voted={voted}
                handleRent={handleRent}
                handleDeliver={handleDeliver}
                isServiced={currentRegion.isServiced}
              />
            </div>
            {safeProduct.associatedProducts.length > 0 ? (
              <div className="tw-container tw-my-12">
                <hr />
                <h3>This Item goes well with:</h3>
                <div className="tw-flex tw-flex-wrap tw-mx-0 tw-my-4">
                  {safeProduct.associatedProducts.map((item, index) => (
                    <ItemTile
                      {...item}
                      index={index}
                      noOfProducts="6"
                      key={item.resourceId}
                    />
                  ))}
                </div>
              </div>
            ) : null}

            <HowDoRentalsWork />

            {safeProduct.similarProducts.length > 0 ? (
              <div className="tw-container tw-mx-auto tw-my-5">
                <hr />
                <h3>Items from the same category:</h3>
                <div className="tw-flex tw-flex-wrap tw-mx-0 tw-my-4">
                  {safeProduct.similarProducts
                    // .slice(0, -2)
                    .map((item, index) => (
                      <ItemTile
                        {...item}
                        index={index}
                        noOfProducts="6"
                        key={`similar-${index}-${item.resourceId}`}
                      />
                    ))}
                </div>
              </div>
            ) : null}
            <MobilePricebar
              isFixedPriceBar
              product={safeProduct}
              rentDuration={rentDuration}
              total={total}
              salesTax={salesTax}
              subtotal={subtotal}
              delivery={delivery}
              productCosts={productCosts}
              skiptiCredit={skiptiCredit}
              insurance={insurance}
              handleRent={handleRent}
              handleDeliver={handleDeliver}
              isServiced={currentRegion.isServiced}
            />

            {isOpenInterestAlert && (
              <RegisterInterestAlert
                onClose={onCloseInterestAlert}
                from="checkout"
                data={interestData}
                isLoaded={product.isLoaded}
              />
            )}
          </div>
        )}
      </LayoutItem>
    </div>
  );
}
