import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import MixpanelContext from "react-mixpanel";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import {
  IconButton,
  Tooltip,
  withWidth,
  withStyles,
  Hidden,
} from "@material-ui/core";
import { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import WOW from "wowjs";
import "animate.css/animate.min.css";
import moment from "moment-timezone";
import {
  getUser,
  getIsLoggedIn,
  getIsAdmin,
  getIsLogInModalOpen,
  getLocationByIP,
  getShareReferralModalOpen,
  getShareReferralModalMode,
  getCityName,
  getZipModalOpen,
} from "../../reducers/authReducer";
import {
  signOut,
  closeLogInModal,
  openLogInModal,
  setReturnUrl,
  fetchItemsBySearchStringAndCategory,
  openSocialShareModal,
  closeSocialShareModal,
  setSignInMessage,
  openZipModal,
  closeZipModal,
  fetchZipFromLatLong,
  fetchZipInfo,
  changeRegion,
  fetchRegions,
  setZipCode,
} from "../../actions";
import SignInModal from "./SignInModal";
import {
  getItemsBySearch,
  getZip,
  getZipInfo,
} from "../../reducers/itemsReducer";

import SkiptiProgressBar from "../skipti/SkiptiProgressBar";
// import SearchBar from "./SearchBar";
import TopBar from "./TopBar";
import { checkZipCode, imageServerUrl } from "../../utils";
// import ChangeZipModal from "./ChangeZipModal";
import { getRegion, getRegions } from "../../reducers/userReducer";
import DrawerNav from "../Drawer";

import {
  getCartFromRedux,
  // getCartItems,
  getCartSelectedItemIds,
  getCart,
} from "../items/item_upsell/CartSlice";
import { getItemRentQueryStringOptions } from "../../reducers/itemRentReducer";
import CheckoutProgressBar from "../skipti/CheckoutProgressBar";
import { ITEM_DELIVERY_MODEL } from "../../actions/utils";
import LocationBar from "./LocationBar";

let wow = null;
let timer = null;
const drawerWidth = 240;
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
  },
  menuIcon: {
    marginRight: 11,
    marginLeft: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#F05623",
    color: "white",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "white",
  },
  cityInput: {
    "& .Mui-focused": {
      color: "white",
      borderColor: "white",
    },
    "& .MuiInput-underline": {
      color: "white",
      "&:before": {
        borderColor: "white",
      },
      "&:after": {
        borderColor: "white",
      },
    },
  },
});

class Navbar extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    current_user: PropTypes.shape({
      avatar: PropTypes.string,
      first_name: PropTypes.string,
      _id: PropTypes.string,
    }).isRequired,
    isAdmin: PropTypes.bool,
    openLogInModal: PropTypes.func.isRequired,
    closeLogInModal: PropTypes.func.isRequired,
    setReturnUrl: PropTypes.func.isRequired,
    isLogInModalOpen: PropTypes.bool,
    fetchItemsBySearchStringAndCategory: PropTypes.func.isRequired,
    showSearch: PropTypes.bool,
    showListItem: PropTypes.bool,
    showStep: PropTypes.bool,
    renderStep: PropTypes.func,
    setZipCode: PropTypes.func,
    fetchRegions: PropTypes.func,
    showProgress: PropTypes.bool,
    currentProgress: PropTypes.number,
    locationByIP: PropTypes.shape({}),
  };

  static defaultProps = {
    isLogInModalOpen: false,
    isAdmin: false,
    locationByIP: {},
    showSearch: false,
    showListItem: false,
    showStep: false,
    renderStep: () => {},
    showProgress: false,
    currentProgress: 0,
  };

  static contextType = MixpanelContext;

  constructor(props) {
    super(props);
    this.anchorPopper = React.createRef();
    this.arrowRef = React.createRef();
    this.menuPopperAnchor = React.createRef();
  }

  state = {
    isModalOpen: false,
    isPopperOpen: false,
    isMenuPopperOpen: false,
    drawerOpen: false,
    openMyProfile: false,
    redirectionPopup: false,
  };

  async componentDidMount() {
    wow = new WOW.WOW();
    wow.init();

    const queryString = new URLSearchParams(this.props.location.search);

    if (this.props.regions.length === 0) {
      await this.props.fetchRegions();
    }

    const queryZipInfo = this.getZipFromURL();

    if (
      !this.props.isLoggedIn &&
      (this.props.location.pathname === "/" ||
        this.props.location.pathname.includes("/partners/") ||
        this.props.location.pathname.includes("/discover") ||
        // (
        this.props.location.pathname.includes("/items/")) &&
      // &&
      // !this.props.location.pathname.includes("/items/rent/") &&
      // !this.props.location.pathname.includes("/items/demo/") &&
      // !this.props.location.pathname.includes("/items/appointment/") &&
      // !this.props.location.pathname.includes("/items/buyAppointment/") &&
      // !this.props.location.pathname.includes("/items/deliver/"))
      (!this.props.zip || typeof this.props.zip !== "undefined")
    ) {
      await this.props.fetchZipFromLatLong();

      if (!queryZipInfo.isValid) {
        timer = setTimeout(() => {
          this.openZipModal();
        }, 1000);
      }
    }

    this.setupZip();

    if (
      (!this.props.cartItemIds || this.props.cartItemIds?.length < 1) &&
      !this.props.location.pathname.includes("/complete?") &&
      !this.props.location.pathname.includes("/items/")
    ) {
      this.props.getCart({
        Promocode: this.props.queryStringOptions.promoCode,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  setupZip = async () => {
    const { regions } = this.props;
    const queryZipInfo = this.getZipFromURL();

    if (regions.length === 0) await this.props.fetchRegions();

    // if (
    //   !this.props.zip &&
    //   this.props.region &&
    //   this.props.region.defaultZipPostal
    // ) {
    //   this.props.setZipCode(
    //     queryZipInfo.isValid
    //       ? queryZipInfo.value
    //       : this.props.region.defaultZipPostal,
    //     true
    //   );
    // }
  };

  getZipFromURL = () => {
    const queryString = new URLSearchParams(this.props.location.search);
    const queryZipCode = queryString.get("zip");

    return {
      value: queryZipCode,
      isValid: checkZipCode(queryZipCode),
    };
  };

  toggleDrawer = () =>
    this.setState(({ drawerOpen }) => ({ drawerOpen: !drawerOpen }));

  handleOpenMyProfile = () =>
    this.setState(({ openMyProfile }) => ({ openMyProfile: !openMyProfile }));

  openModal = () => {
    this.props.setReturnUrl(this.props.location);
    this.props.openLogInModal(false);
  };

  openRegisterModal = () => {
    this.props.setReturnUrl(this.props.location);
    this.props.openLogInModal(true);
  };

  closePopper = () => {
    this.setState({ isPopperOpen: false });
  };

  closeMenuPopper = () => {
    this.setState({ isMenuPopperOpen: false });
  };

  closeModal = () => {
    this.props.setSignInMessage("");
    this.props.closeLogInModal();
  };

  setMenuPopperOpen = () => {
    this.setState(({ isMenuPopperOpen }) => ({
      isMenuPopperOpen: !isMenuPopperOpen,
    }));
  };

  openShareReferralModal = (mode) => {
    this.props.openSocialShareModal(mode);
  };

  closeShareReferralModal = () => {
    this.props.closeSocialShareModal();
  };

  openZipModal = () => {
    this.props.openZipModal();
  };

  closeZipModal = () => {
    this.props.closeZipModal();
  };

  handleSignOut = () => {
    this.context.track("Log Out", {
      userEmail: this.props.current_user.email,
    });
    window.analytics.track("Log Out", {
      userEmail: this.props.current_user.email,
    });
    this.props.signOut().then(() => {
      this.props.history.push("/");
    });
  };

  handleSubmit = async (values) => {
    if (values.searchString) {
      this.props.fetchItemsBySearchStringAndCategory(values);
      this.props.history.push("/items/search");
    }
  };

  handleRedirect = () => {
    if (window.location.host === "qa.skipti.com") {
      const isUserAgree = window.confirm(
        "you are going to leave QA enviroment"
      );
      if (isUserAgree) {
        window.location.href = "http://skipti.com";
      }
    } else {
      window.location.href = "http://skipti.com";
    }
  };

  goToCart = () => {
    if (this.props.cart && this.props.cart.productRequests?.length > 0) {
      if (
        this.props.cart.productRequests[0].deliveryModel ===
        ITEM_DELIVERY_MODEL.REGULAR
      ) {
        this.props.history.push(`/items/rent/${this.props.cartItemIds[0]}`);
      } else if (
        this.props.cart.productRequests[0].deliveryModel ===
        ITEM_DELIVERY_MODEL.SHIP_DELIVER_ONLY
      ) {
        this.props.history.push(
          `/items/buyAppointment/${this.props.cart?.key}`
        );
      }
    }
  };

  isShowCartIcon = () => {
    return (
      this.props.cart &&
      this.props.cartItemIds?.length > 0 &&
      moment().isBefore(moment.utc(this.props.cart.utcActiveUntil).local()) &&
      this.props.location.pathname.search(
        /\/items\/rent\/[A-Za-z\d-]+\/complete/g
      ) === -1
    );
  };

  isShowingReminderText = () => {
    return !!(
      this.props.cart &&
      this.props.cart.productRequests &&
      this.props.cart.productRequests.length > 0 &&
      this.props.cart.productRequests[0].deliveryModel ===
        ITEM_DELIVERY_MODEL.REGULAR
    );
  };

  render() {
    const {
      isLoggedIn,
      isLogInModalOpen,
      showSearch,
      showStep,
      renderStep,
      showProgress,
      currentProgress,
      isAdmin,
      zipModalOpen,
      partnerLogo,
      partner,
      withAdditionalLinks = true,
      withTopBar = false,
      navbarColor = "#ffffff",
      isBuyFlow,
      isAppointment = false,
      hideLocationBar = false,
    } = this.props;
    const { isModalOpen } = this.state;

    const _logoPartner =
      partnerLogo && partnerLogo.includes("http")
        ? partnerLogo
        : `${imageServerUrl}/partners/${
            partnerLogo?.split(".png")[0]
          }-forheader.png`;
    return (
      <>
        {withTopBar && <TopBar />}
        <nav
          className="skipti-nav tw-h-20 tw-p-0 tw-m-0 tw-fixed tw-right-0 tw-left-0 tw-z-50 tw-bg-white tw-w-full"
          style={{
            top: `${withTopBar ? "28px" : "0px"}`,
            backgroundColor: navbarColor,
          }}
        >
          <div
            className="tw-h-full tw-flex tw-pr-0 tw-mx-auto tw-flex-wrap tw-justify-center"
            style={{ marginRight: "auto", marginLeft: "auto" }}
            ref={(ref) => (this.anchorPopper = ref)}
          >
            <div className="xl:tw-w-2/12 lg:tw-w-2/12 md:tw-w-3/12 sm:tw-w-2/12 tw-w-3/12 tw-pl-4 tw-pr-4 tw-h-full tw-items-center tw-flex">
              {!partnerLogo ? (
                <>
                  <Hidden xsDown>
                    <Link
                      className="skipti-logo d-none d-md-block p-0"
                      to="/"
                      href="/"
                    >
                      <img
                        src={`${imageServerUrl}/2020-1009 Skipto Website popup Logo-rev01.png`}
                        alt=""
                        className="tw-h-10 tw-max-w-none"
                        style={{ zIndex: "2" }}
                      />
                    </Link>
                  </Hidden>
                  <Hidden smUp>
                    <Link className="tw-block" to="/" href="/">
                      <img
                        src={`${imageServerUrl}/2019-0731 Monogram - rev00.png`}
                        className="tw-w-10 tw-h-10 tw-max-w-none"
                        alt=""
                      />
                    </Link>
                  </Hidden>
                </>
              ) : (
                <>
                  {/* <Hidden smDown> */}
                  <Link
                    className="skipti-logo d-none d-md-block p-0"
                    to={`/partners/${partner}`}
                    href={`/partners/${partner}`}
                  >
                    {/* Partner Logo is the logo for the Organization that has -forheader in the ending of the name */}
                    <img
                      src={_logoPartner}
                      alt="partner logo"
                      style={{ zIndex: "2", width: "120px", height: "100%" }}
                    />
                  </Link>
                </>
              )}
            </div>
            <div className="xl:tw-w-5/12 lg:tw-w-5/12 md:tw-w-6/12 sm:tw-w-7/12 tw-w-3/12 tw-text-base tw-justify-items-center tw-self-center">
              {showStep &&
                isWidthUp("sm", this.props.width, false) &&
                renderStep()}
              {/* {showSearch && isWidthUp("sm", this.props.width, false) && (
                <SearchBar showButton />
              )} */}
            </div>

            <div className="xl:tw-w-5/12 lg:tw-w-5/12 md:tw-w-3/12 sm:tw-w-3/12 tw-w-6/12 tw-text-right tw-self-center sm:tw-pr-4 tw-justify-end tw-flex tw-items-center lg:tw-content-between ">
              {!showStep ? (
                <>
                  {!isLoggedIn && withAdditionalLinks && (
                    <>
                      <Link
                        className="toplink xl:tw-inline tw-text-base tw-hidden tw-text-gray-600 tw-py-2 tw-px-2 tw-font-hnRegular"
                        to="/howitworks"
                        href="/howitworks"
                      >
                        How It Works
                      </Link>
                    </>
                  )}
                  {withAdditionalLinks && (
                    <Link
                      className="toplink xl:tw-inline tw-text-base tw-hidden tw-text-gray-600 tw-py-2 tw-px-2 tw-font-hnRegular"
                      to="/discover"
                      href="/discover"
                    >
                      Discover
                    </Link>
                  )}
                  {withAdditionalLinks &&
                    (!isLoggedIn ? (
                      <Hidden mdDown>
                        <span
                          className="toplink xl:tw-inline tw-text-base tw-hidden tw-text-gray-600 tw-py-2 tw-px-2 tw-font-hnRegular tw-cursor-pointer"
                          onClick={this.handleRedirect}
                          rel="noopener noreferrer"
                        >
                          For Business
                        </span>
                      </Hidden>
                    ) : (
                      <span
                        className="toplink xl:tw-inline tw-text-base tw-hidden tw-text-gray-600 tw-py-2 tw-px-2 tw-font-hnRegular tw-cursor-pointer"
                        onClick={this.handleRedirect}
                        rel="noopener noreferrer"
                      >
                        For Business
                      </span>
                    ))}

                  {/* Mobile Discover button */}
                  {withAdditionalLinks && (
                    <Link
                      className="btn-skipti-primary tw-mr-1 tw-inline xl:tw-hidden fw-regular"
                      to="/discover"
                      href="/discover"
                    >
                      Discover
                    </Link>
                  )}
                  {isLoggedIn ? (
                    <>
                      {isWidthUp("md", this.props.width) && (
                        <>
                          <IconButton
                            ref={(ref) => (this.menuPopperAnchor = ref)}
                            onClick={this.toggleDrawer}
                            style={{
                              marginRight: "0.25rem",
                              color: "#4D4D4D",
                            }}
                          >
                            <PersonOutlineOutlinedIcon
                              fontSize={
                                isWidthDown("md", this.props.width)
                                  ? "medium"
                                  : "large"
                              }
                            />
                          </IconButton>
                          {this.isShowCartIcon() && (
                            <IconButton
                              onClick={this.goToCart}
                              style={{
                                marginRight: "0.25rem",
                                color: "#4D4D4D",
                              }}
                            >
                              <ShoppingCartOutlinedIcon
                                fontSize={
                                  isWidthDown("md", this.props.width)
                                    ? "medium"
                                    : "large"
                                }
                              />
                            </IconButton>
                          )}
                        </>
                      )}
                      {isWidthDown("xs", this.props.width) && (
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={this.toggleDrawer}
                          edge={false}
                        >
                          <MenuIcon />
                        </IconButton>
                      )}
                      {/* <Hidden mdDown>
                        <Tooltip
                          title="Credits will be applied automatically at checkout"
                          placement="bottom"
                        >
                          <p
                            className="toplink d-none d-lg-inline fw-small"
                            style={{ color: "green" }}
                          >
                            Credits: $
                            {this.props.current_user &&
                              this.props.current_user.skiptiCredit &&
                              this.props.current_user.skiptiCredit.amount}
                          </p>
                        </Tooltip>
                      </Hidden> */}
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={this.openModal}
                        className="btn-skipti-primary xl:tw-inline tw-hidden"
                      >
                        Log In
                      </button>
                      <button
                        type="button"
                        onClick={this.openRegisterModal}
                        className="btn-skipti-secondary tw-m-1 tw-hidden  xl:tw-inline"
                      >
                        Sign Up
                      </button>
                      <div className="tw-m-1 tw-inline xl:tw-hidden">
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={this.toggleDrawer}
                          classes={{ root: this.props.classes.menuIcon }}
                          edge={false}
                        >
                          <MenuIcon />
                        </IconButton>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {partnerLogo && (
                    <Link
                      className="skipti-logo d-none d-md-block p-0"
                      to="/"
                      href="/"
                    >
                      <img
                        src={`${imageServerUrl}/partners/2019-0731 Powered by - rev00.png`}
                        alt=""
                        style={{ zIndex: "2", width: "120px", height: "100%" }}
                      />
                    </Link>
                  )}
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.toggleDrawer}
                    edge={false}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
              )}
            </div>

            <Modal
              open={isModalOpen || isLogInModalOpen}
              onClose={this.closeModal}
            >
              <SignInModal
                onClose={this.closeModal}
                isPromotion={this.state.isPromo}
                locationByIP={this.props.locationByIP}
              />
            </Modal>
            {/*
            <Modal open={zipModalOpen || false} onClose={this.closeZipModal}>
              <ChangeZipModal onClose={this.closeZipModal} />
            </Modal> */}
          </div>
          {showProgress ? (
            isBuyFlow ? (
              <SkiptiProgressBar currentProgress={currentProgress} />
            ) : (
              <CheckoutProgressBar
                currentKey={currentProgress}
                isAppointment={isAppointment}
              />
            )
          ) : null}
          <DrawerNav
            toggleDrawer={this.toggleDrawer}
            drawerOpen={this.state.drawerOpen}
            handleSignOut={this.handleSignOut}
            onClose={this.toggleDrawer}
            redirectForBusiness={this.handleRedirect}
            openModal={this.openModal}
            openRegisterModal={this.openRegisterModal}
            goToCart={this.goToCart}
            cart={this.props.cart}
            cartItemIds={this.props.cartItemIds}
            isShowCartIcon={this.isShowCartIcon()}
            isShowingReminderText={this.isShowingReminderText()}
          />
          {!hideLocationBar && <LocationBar />}
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  current_user: getUser(state),
  isAdmin: getIsAdmin(state),
  isLoggedIn: getIsLoggedIn(state),
  isLogInModalOpen: getIsLogInModalOpen(state),
  searchString: getItemsBySearch(state).searchString,
  locationByIP: getLocationByIP(state),
  shareReferralModalOpen: getShareReferralModalOpen(state),
  shareRefferalModalMode: getShareReferralModalMode(state),
  zipModalOpen: getZipModalOpen(state),
  city: getCityName(state),
  zip: getZip(state),
  zipInfo: getZipInfo(state),
  region: getRegion(state),
  regions: getRegions(state),
  cartItemIds: getCartSelectedItemIds(state),
  cart: getCartFromRedux(state),
  queryStringOptions: getItemRentQueryStringOptions(state),
});

export default withStyles(styles)(
  withWidth()(
    withRouter(
      connect(mapStateToProps, {
        signOut,
        closeLogInModal,
        openLogInModal,
        fetchItemsBySearchStringAndCategory,
        openSocialShareModal,
        closeSocialShareModal,
        setReturnUrl,
        setSignInMessage,
        openZipModal,
        closeZipModal,
        fetchZipInfo,
        fetchRegions,
        changeRegion,
        fetchZipFromLatLong,
        setZipCode,
        getCart,
      })(Navbar)
    )
  )
);
