import React from "react";
import LayoutMain from "../layouts/LayoutMain";

export default function Blacklist() {
  return (
    <LayoutMain withContainer>
      <div>
        This account has been blacklisted for activity on Skipti.com
        <br />
        If you feel that the account has been blacklisted erroneously, please
        contact customer support at{" "}
        <a href="mailto:skiptiteam@skipti.com">skiptiteam@skipti.com</a>
      </div>
    </LayoutMain>
  );
}
