import React, { Component } from "react";
import PropTypes from "prop-types";
import LayoutMain from "../layouts/LayoutMain";
import ExperienceForm from "./ExperienceForm";
// region props
// const articles = [
//   {
//     id: 1,
//     category: 1,
//     title: "Lorem ipsum dolor sit amet",
//     body:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non dolor in mauris sollicitudin mollis. Suspendisse malesuada efficitur condimentum. Praesent faucibus libero ut mauris pretium porttitor. Sed condimentum diam velit, et malesuada tellus maximus et. Praesent quis mollis nulla, vitae feugiat nisl. Curabitur vel eros erat. Vestibulum commodo, elit a tincidunt fermentum, dui felis molestie metus, non porttitor tortor justo id quam. Vestibulum nec elementum magna. Pellentesque porta enim vitae ipsum mollis, vel interdum urna tincidunt. Etiam non elementum est, vitae tincidunt nunc. Phasellus sodales tortor vel odio posuere egestas. Duis semper dolor tortor. Nulla semper finibus sapien, et porta nisl suscipit euismod.",
//     lead: "Lorem ipsum dolor sit amet",
//     quote: "Lorem ipsum dolor sit amet",
//     quote_author: "Andy",
//     video: _.sample(["yy7MH9TyZck", "q8shRsyeGr0", "LrX-IMzP4Wc"]),
//     cover: `https://picsum.photos/1920x250?image=${_.random(0, 800)}`,
//     thumbnail: `https://dummyimage.com/640x360/ccc/fff&text=+`,
//     author: current_user,
//     createdAt: "01.01.2018"
//   },
//   {
//     id: 2,
//     category: 2,
//     title: "Lorem ipsum dolor sit amet",
//     body:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non dolor in mauris sollicitudin mollis. Suspendisse malesuada efficitur condimentum. Praesent faucibus libero ut mauris pretium porttitor. Sed condimentum diam velit, et malesuada tellus maximus et. Praesent quis mollis nulla, vitae feugiat nisl. Curabitur vel eros erat. Vestibulum commodo, elit a tincidunt fermentum, dui felis molestie metus, non porttitor tortor justo id quam. Vestibulum nec elementum magna. Pellentesque porta enim vitae ipsum mollis, vel interdum urna tincidunt. Etiam non elementum est, vitae tincidunt nunc. Phasellus sodales tortor vel odio posuere egestas. Duis semper dolor tortor. Nulla semper finibus sapien, et porta nisl suscipit euismod.",
//     lead: "Lorem ipsum dolor sit amet",
//     quote: "Lorem ipsum dolor sit amet",
//     quote_author: "Andy",
//     video: _.sample(["yy7MH9TyZck", "q8shRsyeGr0", "LrX-IMzP4Wc"]),
//     cover: `https://picsum.photos/1920x250?image=${_.random(0, 800)}`,
//     thumbnail: `https://dummyimage.com/640x360/ccc/fff&text=+`,
//     author: current_user,
//     createdAt: "01.01.2018"
//   }
// ];
const items = [
  {
    _id: "5ac29a2ab10e502e2c2d796d",
    updatedAt: "2018-04-02T21:01:32.454Z",
    createdAt: "2018-04-02T21:01:31.320Z",
    owner: "5ac29a29b10e502e2c2d7916",
    title: "Licensed Metal Hat",
    category: "5ac29a29b10e502e2c2d7951",
    condition: "Usable",
    type: "Bundle",
    description:
      "Qui magni sint sit magnam dolore ut aut optio quibusdam. Ut velit ut. Autem delectus in soluta sint maiores et ducimus suscipit. Earum quia in sequi aliquam architecto consequuntur. Adipisci fugiat modi rerum et aliquid. Aspernatur et dolor est et error debitis autem consequatur. Hic assumenda dignissimos. Aut qui aspernatur porro ex voluptatem dolorem labore eligendi.",
    price: 926,
    age: "5 years",
    availability: [
      {
        from: "2018-04-01T21:01:30.875Z",
        to: "2018-04-09T21:01:30.875Z",
        _id: "5ac29a2ab10e502e2c2d796e",
      },
    ],
    bundle: ["5ac29a2bb10e502e2c2d7c75"],
    photos: [
      {
        url: "https://picsum.photos/1024/576?image=289",
        _id: "5ac29a2ab10e502e2c2d7976",
      },
      {
        url: "https://picsum.photos/1024/576?image=468",
        _id: "5ac29a2ab10e502e2c2d7975",
      },
      {
        url: "https://picsum.photos/1024/576?image=153",
        _id: "5ac29a2ab10e502e2c2d7974",
      },
      {
        url: "https://picsum.photos/1024/576?image=183",
        _id: "5ac29a2ab10e502e2c2d7973",
      },
      {
        url: "https://picsum.photos/1024/576?image=471",
        _id: "5ac29a2ab10e502e2c2d7972",
      },
      {
        url: "https://picsum.photos/1024/576?image=34",
        _id: "5ac29a2ab10e502e2c2d7971",
      },
      {
        url: "https://picsum.photos/1024/576?image=77",
        _id: "5ac29a2ab10e502e2c2d7970",
      },
      {
        url: "https://picsum.photos/1024/576?image=398",
        _id: "5ac29a2ab10e502e2c2d796f",
      },
    ],
    rating: { condition: 0, accuracy: 2, experience: 3, delivery: 0 },
    rules: [
      "Est et ea quos ipsa molestiae voluptatibus explicabo.",
      "Nesciunt laborum et dolor temporibus quisquam voluptates.",
      "In deleniti quisquam eligendi expedita ad nulla quaerat iusto illum.",
      "Nam quam sed sed blanditiis animi quisquam soluta quo voluptatem.",
      "Voluptas omnis possimus culpa atque est est praesentium. Esse enim enim asperiores non natus sint.",
      "Debitis ea quod.",
      "Id autem cumque perferendis sunt eum perferendis porro voluptas. Modi voluptatibus autem.",
      "Earum quia quam totam quia rerum. Perferendis ut doloremque sint sit et necessitatibus.",
      "Amet delectus ullam quis nihil. Molestiae sit voluptatem officia.",
      "Deleniti necessitatibus eos veniam ea.",
    ],
    included: [],
    questions: [
      {
        posted_on: "2017-10-24T20:13:47.219Z",
        avatar: "https://randomuser.me/api/portraits/women/58.jpg",
        user: "Niko",
        question:
          "Neque voluptatem dolorum in. Error porro quis ducimus eos est fugit ipsa facere. Doloribus sint et enim quia rerum atque labore. Iste in vel labore est ratione suscipit et. Necessitatibus sint sunt ipsa repellendus labore magnam porro.",
        answer:
          "At hic consequuntur odit et voluptatibus nam. Totam sed quod dolor odio. Est a qui nam. Error dolorem recusandae nam maxime qui id velit. Enim error accusamus sit maiores quo dolorem vel magnam sed. Aliquam maxime est aut expedita. Rerum et quasi cumque incidunt iste voluptates autem eligendi ex.",
        _id: "5ac29a2ab10e502e2c2d7977",
      },
    ],
    reviews: [
      {
        posted_on: "2017-05-22T12:33:53.284Z",
        title: "Et molestiae ea magnam sequi doloribus voluptas.",
        avatar: "https://randomuser.me/api/portraits/women/5.jpg",
        user: "5ac29a29b10e502e2c2d7940",
        stars: 0,
        description:
          "Fuga quis quisquam enim et voluptatem et officiis eos perferendis. Saepe modi exercitationem perspiciatis sed corporis nisi esse quaerat. Expedita rem pariatur hic rem voluptas dolorem veniam fugiat. Fugiat voluptatem enim quo occaecati laboriosam est provident. Et voluptas officiis iste qui nemo consectetur. Doloribus velit deserunt nostrum qui ab voluptatem aut. Quod est non sit voluptas sequi voluptate. Natus mollitia non qui rerum quaerat sed exercitationem aut. Aspernatur omnis facere. Exercitationem quibusdam ut. Aperiam earum ea perferendis aut. Porro impedit cum neque eaque. Qui aperiam voluptatum ut. At illum rerum qui voluptatem dignissimos eius ducimus magnam. A occaecati soluta fuga voluptatem harum corporis.",
        _id: "5ac29a2ab10e502e2c2d797b",
      },
      {
        posted_on: "2017-07-01T21:03:33.946Z",
        title: "Magni fugit atque voluptates.",
        avatar: "https://randomuser.me/api/portraits/women/15.jpg",
        user: "5ac29a29b10e502e2c2d7913",
        stars: 0,
        description:
          "Quae et id ipsum. Excepturi ea harum cupiditate praesentium inventore. Unde reprehenderit harum minus enim dolor a totam adipisci. Nam non eos labore autem soluta dolores laborum pariatur. Assumenda dolore repellendus beatae voluptas. Esse repudiandae et quidem earum officiis odit vel neque officia. Quo consequatur ut ut ad rerum. Eveniet recusandae minima fugit itaque deleniti aspernatur quis. Quae officia ea possimus et. Architecto saepe rerum corrupti quibusdam quae eum. Voluptate aut fuga amet culpa. Id debitis doloribus voluptatum reiciendis quo est vel necessitatibus. Non aut natus labore reprehenderit. Sit esse aut cumque enim voluptatem voluptatem quia aut illum. Ipsum quos ut. Ut odio sint quia libero reprehenderit occaecati dolor. Sunt distinctio inventore deserunt est hic cupiditate dolorem itaque voluptatem. Ratione aut sit et libero sed amet.",
        _id: "5ac29a2ab10e502e2c2d797a",
      },
      {
        posted_on: "2017-04-30T14:35:36.235Z",
        title: "Rerum qui qui sint sed.",
        avatar: "https://randomuser.me/api/portraits/men/46.jpg",
        user: "5ac29a29b10e502e2c2d7931",
        stars: 2,
        description:
          "Earum voluptate ipsa laudantium. Officia commodi nihil tenetur suscipit non voluptatem amet consequatur. Quo et dolor qui. Rerum quaerat non repudiandae. Dolores deleniti enim consequuntur optio rem. Odio laudantium ut beatae. Sunt aut neque possimus odio ipsum reprehenderit nulla occaecati. Omnis repudiandae a. Omnis sunt aperiam. Maxime eligendi expedita. Quis sint laudantium odit repellat excepturi ipsam qui. Adipisci debitis sed aliquid quisquam excepturi totam ipsa. Voluptas natus facere animi nisi molestiae consectetur sit. Eveniet eos praesentium quia. Et aut necessitatibus voluptatem quia dolore. Dignissimos et non non aspernatur nihil quis facere. Modi quis ipsum. Necessitatibus ipsa sapiente nisi aut facilis. Vitae necessitatibus minima esse dolore sed nesciunt magni quaerat. Nam sunt dolorem occaecati et quaerat.",
        _id: "5ac29a2ab10e502e2c2d7979",
      },
      {
        posted_on: "2017-06-01T03:37:44.047Z",
        title: "Est aliquam est quasi et dolore quia quod quo.",
        avatar: "https://randomuser.me/api/portraits/men/94.jpg",
        user: "5ac29a29b10e502e2c2d7934",
        stars: 1,
        description:
          "Dolorem aspernatur numquam non iure. Quasi sit unde a aut quam est dolores ut facere. Voluptatum cumque nisi eum incidunt. Doloribus perspiciatis voluptas vel. Voluptatem in aut ipsam voluptas. Nobis in repellat id adipisci. Maxime vel sed debitis et velit voluptatem. Veniam qui cupiditate quos dolorum quis laudantium. Odit similique et voluptate perferendis voluptas delectus magnam officia.",
        _id: "5ac29a2ab10e502e2c2d7978",
      },
    ],
    __v: 0,
  },
  {
    _id: "5ac29a2ab10e502e2c2d797c",
    updatedAt: "2018-04-02T21:01:31.324Z",
    createdAt: "2018-04-02T21:01:31.324Z",
    owner: "5ac29a29b10e502e2c2d791c",
    title: "Handcrafted Concrete Towels",
    category: "5ac29a29b10e502e2c2d794c",
    condition: "Great",
    type: "Single",
    description:
      "Enim quia iusto. Et ipsa tempore. Magnam debitis et illo vitae et officia dolorem voluptates dolores. Consequuntur quia assumenda placeat recusandae error est doloremque magni quos. Distinctio odio sunt quod eum laborum quo eius quia. Quos cum totam exercitationem dolorem. Fugit inventore illo. Et at fugit ut. Accusantium ipsam neque sed blanditiis sint earum reprehenderit repellendus. Deserunt quasi cumque asperiores. Nesciunt dolore veniam. Sint aliquid distinctio soluta. Eius totam explicabo mollitia est perspiciatis. Dolor sint quo et voluptatem temporibus. Aut eius laborum doloribus voluptas aliquam.",
    price: 217,
    age: "3 months",
    availability: [
      {
        from: "2018-04-01T21:01:30.875Z",
        to: "2018-04-06T21:01:30.875Z",
        _id: "5ac29a2ab10e502e2c2d797d",
      },
    ],
    bundle: [],
    photos: [
      {
        url: "https://picsum.photos/1024/576?image=143",
        _id: "5ac29a2ab10e502e2c2d7982",
      },
      {
        url: "https://picsum.photos/1024/576?image=357",
        _id: "5ac29a2ab10e502e2c2d7981",
      },
      {
        url: "https://picsum.photos/1024/576?image=295",
        _id: "5ac29a2ab10e502e2c2d7980",
      },
      {
        url: "https://picsum.photos/1024/576?image=618",
        _id: "5ac29a2ab10e502e2c2d797f",
      },
      {
        url: "https://picsum.photos/1024/576?image=148",
        _id: "5ac29a2ab10e502e2c2d797e",
      },
    ],
    rating: { condition: 2, accuracy: 2, experience: 4, delivery: 2 },
    rules: [
      "Perferendis eum tempore impedit assumenda quaerat autem ullam quod consequatur. Suscipit non ea fugit laudantium voluptatibus culpa repellat qui est.",
      "Qui iusto omnis veniam voluptatem ullam in qui quia earum.",
      "Illo modi quas dolor saepe beatae vitae mollitia. Numquam error voluptatem ea voluptatem alias maxime doloremque.",
      "Qui temporibus beatae repellendus adipisci sint et libero dolores. Voluptatum et dolores.",
      "Est quia debitis ducimus quia iure ab quis quaerat explicabo.",
    ],
    included: [],
    questions: [
      {
        posted_on: "2017-05-01T15:11:19.459Z",
        avatar: "https://randomuser.me/api/portraits/men/37.jpg",
        user: "Edgardo",
        question:
          "Omnis aliquid hic nulla dolorem repudiandae voluptates ratione. Et blanditiis quia sint et officia est. Qui nihil aut mollitia quidem eius unde minima laboriosam quia. Sit et accusantium cum enim non perferendis ut. Error rerum quidem facilis pariatur. Quo molestiae consequuntur ab iste. Recusandae ad at maxime consequatur ab quam et sed explicabo. Dicta tempore adipisci natus.",
        answer:
          "Nobis maxime veniam placeat facilis est debitis ea aut. Aut vero enim inventore eum ut explicabo vel est nihil. Dignissimos quaerat totam. Pariatur totam ad voluptatum at voluptas. Placeat optio tempora eos dolor et quae ducimus maiores. Et ipsum quidem consectetur nesciunt nihil et blanditiis. Ipsam deleniti qui quisquam. Asperiores nihil beatae illo laudantium aperiam omnis. Officiis quae ut. Reiciendis repudiandae quo iste saepe et nesciunt laborum enim.",
        _id: "5ac29a2ab10e502e2c2d7983",
      },
    ],
    reviews: [
      {
        posted_on: "2017-11-11T19:17:03.038Z",
        title: "Culpa et eum deserunt nesciunt deleniti nesciunt sit.",
        avatar: "https://randomuser.me/api/portraits/women/57.jpg",
        user: "5ac29a29b10e502e2c2d792e",
        stars: 5,
        description:
          "Ut mollitia reiciendis sunt cum. Vel tempore animi hic. Dolorum aliquid voluptas. Modi occaecati consequatur sunt ipsa excepturi. Optio quam ullam est quis deserunt sequi at omnis quia. Voluptates quasi dolorum non voluptatem iste. Qui maxime non aut minima ut cupiditate. Quidem debitis veritatis et cumque fugit possimus. Rerum dolorem consequuntur repellat qui.",
        _id: "5ac29a2ab10e502e2c2d7985",
      },
      {
        posted_on: "2018-03-25T02:55:45.055Z",
        title:
          "Et voluptas harum soluta nesciunt aliquam tenetur necessitatibus.",
        avatar: "https://randomuser.me/api/portraits/men/52.jpg",
        user: "5ac29a29b10e502e2c2d7946",
        stars: 3,
        description:
          "Voluptas occaecati molestiae pariatur nesciunt aut hic nesciunt eum. Dolorum quis consequuntur. Qui quisquam vel doloribus magnam magni pariatur provident autem. Dolores quis et delectus quo libero temporibus nobis. Et similique enim ut voluptatum repudiandae quis laborum. Quos deserunt quis sapiente molestiae eveniet suscipit. Exercitationem eligendi et qui sit velit optio. Delectus illo qui sunt aut ullam. Et quia quo. Deleniti occaecati sit nihil aut similique. Fuga nostrum quis officia aut qui rerum. Et sapiente blanditiis sed et molestias libero inventore nihil. Similique alias veritatis voluptatem. Voluptates consequatur est ipsam qui suscipit similique. Aut repudiandae dicta omnis et. Dolores animi maxime repellat. Qui deleniti blanditiis qui soluta aliquam eum. Ipsam est ipsa.",
        _id: "5ac29a2ab10e502e2c2d7984",
      },
    ],
    __v: 0,
  },
];
// endregion props
class NewExperience extends Component {
  static propTypes = {
    current_user: PropTypes.shape({}),
  };

  state = {
    defaultValues: {
      type: "blog",
      title: "",
      lead: "",
      body: "",
      quote: "",
      quote_author: "",
      video: "9_lKMTXVk64",
      cover: "https://picsum.photos/1920/576?image=123",
      thumbnail: "https://dummyimage.com/640x360/ccc/fff&text=+",
      items: [],
    },
  };

  handleSubmit = () => {
    // console.log(values);
  };

  render() {
    const { defaultValues } = this.state;
    return (
      <LayoutMain withContainer>
        <div className="tw-container tw-w-full tw-mx-auto tw-px-4 tw-my-6">
          <h2>New Experience</h2>
          <hr />
          <ExperienceForm
            initialValues={defaultValues}
            items={items}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </LayoutMain>
    );
  }
}

export default NewExperience;
