import React, { Component } from "react";
import WOW from "wowjs";
import "animate.css/animate.min.css";
import { connect } from "react-redux";
import LayoutBlog from "../layouts/LayoutBlog";
import { fetchArticleTeasers } from "../../actions";
import { getArticles } from "../../reducers/articlesReducer";
import BigArticleFeaturette from "./BigArticleFeaturette";
import SmallArticleFeaturette from "./SmallArticleFeaturette";

let wow = null;
class ArticleList extends Component {
  componentDidMount() {
    wow = new WOW.WOW();
    wow.init();

    this.props.fetchArticleTeasers();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.articles !== prevProps.articles) {
      wow.sync();
    }
  }

  render() {
    const { props } = this;
    const articles =
      props.articles.isLoaded &&
      props.articles.data &&
      props.articles.data.teasers
        ? props.articles.data.teasers
        : {};

    const articlesArray = Object.keys(articles).map((key) => articles[key]);

    return (
      <LayoutBlog isLoggedIn={false}>
        <div className="blog-index container my-5">
          <div className="row">
            {articlesArray.map((article, index) => [
              index % 3 === 0 ? (
                <BigArticleFeaturette article={article} key={article.id} />
              ) : (
                <SmallArticleFeaturette article={article} key={article.id} />
              ),
            ])}
          </div>
        </div>
      </LayoutBlog>
    );
  }
}

const mapStateToProps = (state) => ({
  articles: getArticles(state),
});

export default connect(mapStateToProps, { fetchArticleTeasers })(ArticleList);
