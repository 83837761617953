import React from "react";
import ButtonWithSpinner from "../../ButtonWithSpinner";

const HowDoesItWork = ({ returnFunc }) => {
  return (
    <>
      <div className="tw-py-5 tw-max-w-md tw-w-full tw-text-center tw-mb-8 tw-mt-8">
        <p className="tw-text-base tw-mb-5">
          Welcome to Skipti! We work with partners big and small to deliver
          great rental experiences right to your door.
        </p>

        <p>Enter your zip code to see products we can deliver to you</p>
      </div>

      <ButtonWithSpinner clickHandler={returnFunc} buttonText="DONE" />
    </>
  );
};

export default HowDoesItWork;
