import React from "react";

export default function ItemRules(props) {
  return (
    <ul style={{ wordBreak: "break-word" }}>
      {props.rules.map((rule, index) => (
        <li key={index}>{rule}</li>
      ))}
    </ul>
  );
}
