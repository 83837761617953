import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// import { fetchCategories } from "../../actions";
// import { getCategories } from "../../reducers/categoriesReducer";
import {
  // getUser,
  getIsLoggedIn,
  getToken,
} from "../../reducers/authReducer";
import Navbar from "../partials/Navbar";
import Hero from "../partials/Hero";
import Footer from "../partials/Footer";
import HeroLoggedIn from "../partials/HeroLoggedIn";

export default function LayoutHome(props) {
  // const currentUser = useSelector(getUser);
  // const productCategories = useSelector(getCategories);
  const { children, showSearch } = props;
  const jwt = useSelector(getToken);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const maybeLoggedIn = isLoggedIn || !!jwt;
  return (
    <>
      <Navbar showSearch={showSearch} />

      {maybeLoggedIn === false ? (
        <Hero isLoggedIn={maybeLoggedIn} />
      ) : (
        <>
          {/* <CategoryList categories={productCategories} /> */}
          <HeroLoggedIn />
        </>
      )}

      <div>{children}</div>

      {/* <Sustainability /> */}
      <Footer />
    </>
  );
}

LayoutHome.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
    PropTypes.shape({}).isRequired,
  ]).isRequired,
  showSearch: PropTypes.bool,
};
LayoutHome.defaultProps = {
  showSearch: false,
};
