import axios from "axios";
import { getToken } from "../reducers/authReducer";
import * as TYPES from "../types";
import * as helpers from "../utils";
import { showSnackbar } from "./snackbar";

const requestExperiencesAll = () => ({
  type: TYPES.EXPERIENCES_ALL_REQUEST,
});
const requestExperiencesById = () => ({
  type: TYPES.EXPERIENCES_BYID_REQUEST,
});

const successExperiencesAll = (payload) => ({
  type: TYPES.EXPERIENCES_ALL_SUCCESS,
  payload,
});
const successExperiencesById = (payload) => ({
  type: TYPES.EXPERIENCES_BYID_SUCCESS,
  payload,
});

const failExperiencesAll = (error) => ({
  type: TYPES.EXPERIENCES_ALL_FAIL,
  error,
});

const failExperiencesById = (error) => ({
  type: TYPES.EXPERIENCES_BYID_FAIL,
  error,
});

export const fetchExperiencesAll = () => async (dispatch, getState) => {
  dispatch(requestExperiencesAll());
  const jwt = getToken(getState());
  // const typeId = 0;
  try {
    const res = await axios.get(`${helpers.serverUrl}/api/v1/teasers/type/0`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    dispatch(successExperiencesAll(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failExperiencesAll(e));
  }
};

export const fetchExperiencesById = (id) => async (dispatch, getState) => {
  dispatch(requestExperiencesById());
  const jwt = getToken(getState());
  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/experiences/${id}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch(successExperiencesById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failExperiencesById(e));
  }
};
