import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setZipCode } from "../actions";
import { checkZipCode, regIntServerURL } from "../utils";
import {
  getProductErrorMessage,
  getZip,
  getZipAddress,
} from "../reducers/itemsReducer";
import { isValidZipCode } from "../actions/utils";

function useZip() {
  const [zip, setZip] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [zipErrors, setZipErrors] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isServiced, setIsServiced] = useState(true);
  const dispatch = useDispatch();
  const productErrorMessage = useSelector(getProductErrorMessage);
  const zipInStore = useSelector(getZip);
  const zipAddressInStore = useSelector(getZipAddress);

  const getZipFromURL = useCallback(() => {
    const queryString = new URLSearchParams(window.location.search);
    const queryZipCode = queryString.get("zip");
    return {
      value: queryZipCode,
      isValid: checkZipCode(queryZipCode),
    };
  }, []);

  const updateZipCode = useCallback(async (zipCode) => {
    setIsProcessing(true);
    if (isValidZipCode(zipCode)) {
      const res = await dispatch(setZipCode(zipCode));
      setZip(res.zipPostal);

      const _address = `${res.name ? `${res.name}, ` : ""} ${res.state} ${
        res.zipPostal
      }`;

      localStorage.setItem(
        "zipAddress",
        JSON.stringify({
          address: _address,
          isServicedZip: res.isServiced,
        })
      );

      setCurrentAddress(_address);
      setIsServiced(res.isServiced);

      setIsProcessing(false);
      return res.isServiced;
    }
    setIsProcessing(false);
  }, []);

  const updateErrorMessages = useCallback((message = "") => {
    setZipErrors(message);
  }, []);

  const addEmailInInterestList = useCallback(async (requestBody) => {
    setIsProcessing(true);
    try {
      const res = await axios.post(
        `${regIntServerURL}/api/v1/reg`,
        requestBody
      );

      window.analytics.track("Update zip code - Sent Email", {
        url: window.location.href,
        referrer: document.referrer,
        email: requestBody?.email,
        zip: requestBody?.manualZip,
        // ipAddress: null,
        organizationId: requestBody?.organizationId,
        productId: requestBody?.productId,
        response: res.data,
      });
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      setIsProcessing(false);
    }
  }, []);

  const getZipCode = useCallback(() => {
    if (!zip || zipInStore !== zip) {
      const queryZipInfo = getZipFromURL();
      if (queryZipInfo.isValid) {
        updateZipCode(queryZipInfo.value);
        return;
      }

      const zipFromlocal = localStorage.getItem("zipCode");
      let zipAddress = localStorage.getItem("zipAddress");
      if (zipAddressInStore && zipAddressInStore !== zipAddress) {
        zipAddress = zipAddressInStore;
      }

      if (zipFromlocal && zipAddress) {
        zipAddress = JSON.parse(zipAddress);
        dispatch(setZipCode(zipFromlocal, false, false));
        setCurrentAddress(zipAddress.address);
        setIsServiced(zipAddress.isServicedZip);
        setZip(zipFromlocal);
        return;
      }

      if (zipFromlocal) {
        updateZipCode(zipFromlocal);
      }
    }
  }, [zip, zipAddressInStore]);

  useEffect(() => {
    getZipCode();
  }, [zipAddressInStore]);

  useEffect(() => {
    if (productErrorMessage) {
      setZipErrors(productErrorMessage);
    }
  }, [productErrorMessage]);

  return {
    zip,
    currentAddress,
    isValidZipCode,
    isServiced,
    zipErrors,
    updateZipCode,
    isProcessing,
    addEmailInInterestList,
    updateErrorMessages,
  };
}

export default useZip;
