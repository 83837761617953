import React from "react";
import { Link } from "react-router-dom";
import { imageServerUrl } from "../../utils";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="skipti-footer tw-mb-12">
      <div className="tw-mx-auto tw-container tw-mt-12 xl:tw-px-12 tw-px-5">
        <div className="tw-flex tw-flex-wrap xl:tw-px-12" id="template-parent">
          <div className="lg:tw-w-3/12 tw-w-6/12">
            <h4 className="fw-medium tw-mb-6">Links</h4>
            <ul className="list-unstyled left-links">
              <li className="">
                <a href="https://skipti.com/about/">About</a>
              </li>
              <li className="">
                <a
                  target="_blank"
                  href="https://skipti.com/customer-support"
                  rel="noreferrer"
                >
                  Support
                </a>
              </li>
              <li className="">
                <a href="https://skipti.com/investors/">Investors</a>
              </li>
              <li>
                <a href="https://skipti.com/contact-us/">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tw-flex flex-wrap xl:tw-px-12">
          <div className="tw-w-full tw-my-3">
            <hr />
          </div>
        </div>
        <div className="tw-flex flex-wrap xl:tw-px-12">
          <div className="lg:tw-w-4/12 tw-w-3/12 ">
            <img
              className="skipti-logo-footer tw-inline"
              src={`${imageServerUrl}/skipti-logo-horizontal-greyscale.svg`}
              alt=""
            />
            <span className="copyright-text tw-text-lightgrey tw-pl-3 tw-hidden lg:tw-inline">
              &copy; {currentYear} Skipti
            </span>
          </div>
          <div className="lg:tw-w-4/12 tw-w-9/12 tw-flex tw-justify-center">
            <ul className="list-inline legal-links">
              <li className="tw-inline-block ">
                <Link
                  className="lg:tw-mx-5"
                  to="/termsandconditions"
                  href="/termsofservice"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li className="tw-inline-block">
                <Link
                  className="lg:tw-mx-5"
                  to="/privacypolicy"
                  href="/privacypolicy"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:tw-w-4/12 tw-hidden lg:tw-block tw-text-right">
            <img
              className="tw-inline"
              src={`${imageServerUrl}/sectigo_trust_seal_sm_82x32.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
