import React, { Component } from "react";
import Slider from "rc-slider";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

class HomeCalculator extends Component {
  state = {
    retail_price: "$400",
    rental_days: 12,
    rental_price: "$64",
    rental_days_label: 12,
    nrItems: 5,
    nrItemsLabel: 5,
    income: 8004,
  };

  componentDidMount() {
    this.calculate = this.calculate.bind(this);
  }

  handleChangeRetailPrice(event) {
    this.setState({ retail_price: event.target.value });
    this.calculate(
      event.target.value,
      this.state.rental_price,
      this.state.rental_days,
      this.state.nrItems
    );
  }

  handleChangeRentalPrice(event) {
    this.setState({ rental_price: event.target.value });
    this.calculate(
      this.state.retail_price,
      event.target.value,
      this.state.rental_days,
      this.state.nrItems
    );
  }

  handleChangeRentalDays(value) {
    this.setState({ rental_days: value, rental_days_label: value });
    this.calculate(
      this.state.retail_price,
      this.state.rental_price,
      value,
      this.state.nrItems
    );
  }

  handleChangeNrItems(value) {
    this.setState({ nrItems: value, nrItemsLabel: value });
    this.calculate(
      this.state.retail_price,
      this.state.rental_price,
      this.state.rental_days,
      value
    );
  }

  calculate(retail_price, rental_price, rental_days, nrItems) {
    let val1 = 0;
    const { length } = retail_price;
    if (retail_price[0] === "$") {
      val1 = retail_price.substr(1);
      val1 = val1.replace(",");
    } else if (retail_price[length - 1] === "$") {
      val1 = retail_price.replace("$", "");
      val1 = val1.replace(",", "");
    } else {
      val1 = retail_price;
      val1 = val1.replace(",", "");
    }

    const coefretailtodaily = 0.04;
    const valdaily = val1 * coefretailtodaily;
    const val_rental_days = rental_days;
    const s = Number(val_rental_days * valdaily).toFixed(2);
    const val_transaction_fee = Number(((30 * Number(s)) / 100) * -1).toFixed(
      2
    );
    const val_upload_fee = Number(0).toFixed(2);
    const val_insurance_fee = Number(-1).toFixed(2);
    const val_income = Number(
      (Number(s) +
        Number(val_transaction_fee) +
        Number(val_upload_fee) +
        Number(val_insurance_fee)) *
        nrItems *
        12
    ).toFixed(2);

    this.setState({
      rental_price: `$${Number(valdaily * 4)
        .toFixed(2)
        .toString()}`,
      income: `${Number(val_income).toFixed(0)}`,
    });
  }

  render() {
    return (
      <ScrollAnimation
        animateIn="bounceInRight"
        className="col-xl-6 col-lg-6 col-sm-12 col-12"
      >
        <div
          className="b-shadow p-5 calculator bg-white"
          style={
            this.props.removeMarginTop
              ? { margin: "0 auto", maxWidth: "450px" }
              : { maxWidth: "450px" }
          }
        >
          {this.props.title ? (
            <h3 className="text-color2">{this.props.title}</h3>
          ) : null}
          <label
            htmlFor="retail_price"
            className="d-block tw-text-verylightgrey mt-4"
          >
            Estimated retail price of your item
          </label>
          <input
            type="text"
            id="retail_price"
            value={this.state.retail_price}
            onChange={this.handleChangeRetailPrice.bind(this)}
            className="mb-4"
          />
          <label
            htmlFor="rental_price"
            className="d-block tw-text-verylightgrey"
          >
            Our suggested 4 day rental price
          </label>
          <input
            type="text"
            id="rental_price"
            value={this.state.rental_price}
            onChange={this.handleChangeRentalPrice.bind(this)}
            className="mb-4"
          />
          <label
            htmlFor="rental_days"
            className="d-block tw-text-verylightgrey"
          >
            Avg rental days per month{" "}
            <span className="float-right tw-text-verylightgrey">
              {this.state.rental_days_label}
            </span>
          </label>

          <Slider
            min={4}
            max={30}
            className="slider display-4 tw-font-hairline text-color2"
            name="rental_days"
            value={this.state.rental_days}
            onChange={this.handleChangeRentalDays}
            trackStyle={{
              backgroundColor: "#0064FF",
              height: "10px",
            }}
            handleStyle={{
              borderColor: "#0064FF",
              height: "20px",
              width: "20px",
              backgroundColor: "#0064FF",
            }}
            railStyle={{ backgroundColor: "#E0E0E0", height: "10px" }}
          />

          <label
            htmlFor="rental_days"
            className="d-block tw-text-verylightgrey"
          >
            Number of items listed
            <span className="float-right tw-text-verylightgrey">
              {this.state.nrItemsLabel}
            </span>
          </label>

          <Slider
            min={1}
            max={30}
            className="slider display-4 tw-font-hairline text-color2"
            name="rental_days"
            value={this.state.nrItems}
            onChange={this.handleChangeNrItems}
            trackStyle={{
              backgroundColor: "#0064FF",
              height: "10px",
            }}
            handleStyle={{
              borderColor: "#0064FF",
              height: "20px",
              width: "20px",
              backgroundColor: "#0064FF",
            }}
            railStyle={{ backgroundColor: "#E0E0E0", height: "10px" }}
          />

          <div className="mt-2 mb-4">
            <span className="display-4 fw-bold text-color2">$</span>
            <span id="priceDisplay" className="display-4 fw-bold text-color2">
              {this.state.income}
            </span>
            <span className="ml-2 text-secondary lead tw-text-muted">
              / year
            </span>
          </div>
        </div>
      </ScrollAnimation>
    );
  }
}

export default HomeCalculator;
