import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import uuid from "uuid/v4";
import axios from "axios";
import moment from "moment";
import { showSnackbar } from "../../actions/snackbar";
import { getToken } from "../../reducers/authReducer";
import { serverUrl } from "../../utils";

export const selectServiceRecords = (state) =>
  state.serviceRecords.serviceRecords;
export const selectServiceRecordsMeta = (state) => state.serviceRecords.meta;
export const selectServiceRecordsError = (state) => state.serviceRecords.error;
export const selectServiceRecordsFetching = (state) =>
  state.serviceRecords.fetching;

export const getServiceRecords = createAsyncThunk(
  "serviceRecords/get",
  async (
    {
      startDate: utcBegins = moment().subtract(1, "month"),
      endDate: utcEnds = moment(),
      pageNumber,
    },
    thunkAPI
  ) => {
    const jwt = getToken(thunkAPI.getState());
    const { pageSize } = selectServiceRecordsMeta(thunkAPI.getState());

    const _utcBegins = utcBegins.utc().startOf("day").toISOString();
    const _utcEnds = utcEnds.utc().add(1, "day").startOf("day").toISOString();

    try {
      const res = await axios.get(`${serverUrl}/api/v1/admin/servicehistory`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageSize,
          pageNumber,
          utcBegins: _utcBegins,
          utcEnds: _utcEnds,
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "serviceRecords",
  initialState: {
    serviceRecords: [],
    meta: {
      pageNumber: 1,
      pageSize: 25,
      totalPages: 0,
      totalItems: 0,
    },
    fetching: false,
    error: false,
  },
  reducers: {
    changeServiceRecordsPageSize: (state, { payload }) => ({
      ...state,
      meta: { ...state.meta, pageSize: payload, pageNumber: 1 },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceRecords.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(getServiceRecords.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.meta.pageNumber = 1;
        state.meta.pageSize = 25;
        state.meta.totalPages = 0;
        state.meta.totalItems = 0;
        state.serviceRecords = [];
      })
      .addCase(getServiceRecords.fulfilled, (state, { payload }) => {
        state.error = false;
        state.fetching = false;
        state.meta.pageNumber = payload.pageNumber - 1;
        state.meta.pageSize = payload.pageSize;
        state.meta.totalPages = payload.totalPages;
        state.meta.totalItems = payload.totalItems;
        state.serviceRecords = payload
          ? payload.records.map((el) => ({ ...el, id: uuid() }))
          : [];
      });
  },
});

export const { changeServiceRecordsPageSize } = slice.actions;

export default slice.reducer;
