import axios from "axios";
import toLower from "lodash/toLower";
import * as TYPES from "../types";
import * as helpers from "../utils";
import { getCategories } from "../reducers/categoriesReducer";
import { showSnackbar } from "./snackbar";

const requestCategoriesAll = () => ({
  type: TYPES.CATEGORY_ALL_REQUEST,
});

const requestCategoryById = () => ({
  type: TYPES.CATEGORY_BYID_REQUEST,
});

const failCategoriesAll = (error) => ({
  type: TYPES.CATEGORY_ALL_FAIL,
  error,
});

const failCategoryById = (error) => ({
  type: TYPES.CATEGORY_BYID_FAIL,
  error,
});

const successCategoriesAll = (payload) => ({
  type: TYPES.CATEGORY_ALL_SUCCESS,
  payload,
});

const successCategoryById = (payload) => ({
  type: TYPES.CATEGORY_BYID_SUCCESS,
  payload,
});

export const fetchCategories = () => async (dispatch) => {
  dispatch(requestCategoriesAll());
  try {
    const res = await axios.get(`${helpers.serverUrl}/api/v1/categories`);
    dispatch(successCategoriesAll(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failCategoriesAll(e));
  }
};

export const fetchCategoryById = (id) => async (dispatch, getState) => {
  let productCategories = getCategories(getState());
  if (!productCategories.isLoaded) {
    await dispatch(fetchCategories());
  }
  productCategories = getCategories(getState());
  const categoryId = productCategories.data.find(
    (category) => toLower(category.displayName) === toLower(id)
  ).id;
  dispatch(requestCategoryById());
  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/category/${categoryId}`
    );
    dispatch(successCategoryById(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failCategoryById(e));
  }
};
