import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { useSnackbar, withSnackbar } from "notistack";
import { hideSnackbar, removeSnackbar } from "../../actions";

const NotifierCloseButton = (props) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      onClick={() => {
        closeSnackbar(props.id);
        props.removeSnackbar();
      }}
    >
      <Close />
    </IconButton>
  );
};

const mapDispatchToProps = { hideSnackbar, removeSnackbar };

export default connect(null, mapDispatchToProps)(NotifierCloseButton);
