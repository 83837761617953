import React, { Component } from "react";
import { CHUNK_LOAD_ERROR_TYPE } from "../../utils/constants";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    RETRY_TO_LOAD_COUNT = 0;

    MAX_RETRY_COUNT = 3;

    constructor(props) {
      super(props);

      this.state = {
        component: null,
        failedLoad: false,
      };

      this.loadComponent = this.loadComponent.bind(this);
    }

    async componentDidMount() {
      this.loadComponent();
    }

    async loadComponent() {
      try {
        const { default: component } = await importComponent();

        this.setState({
          component: component,
          failedLoad: false,
        });

        this.RETRY_TO_LOAD_COUNT = 0;
      } catch (e) {
        if (this.RETRY_TO_LOAD_COUNT !== this.MAX_RETRY_COUNT) {
          this.RETRY_TO_LOAD_COUNT += 1;

          this.loadComponent();
          return;
        }

        this.setState({
          component: null,
          failedLoad: true,
        });
      }
    }

    render() {
      const { component: LoadedComponent, failedLoad } = this.state;

      if (failedLoad) {
        const error = new Error();
        error.name = CHUNK_LOAD_ERROR_TYPE;
        error.message = "Failed to load chunk";

        throw error;
      }

      return LoadedComponent ? <LoadedComponent {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
