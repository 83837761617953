import React, { Component } from "react";
import { Paper, isWidthUp, withWidth } from "@material-ui/core";
import PriceInfoPopper from "../../partials/PriceInfoPopper";
import VoteItemButtons from "../VoteItemButtons";
import { ITEM_DELIVERY_MODEL } from "../../../actions/utils";

class MobilePricebar extends Component {
  render() {
    const {
      product,
      rentDuration,
      total,
      salesTax,
      subtotal,
      delivery,
      productCosts,
      skiptiCredit,
      insurance,
      isFixedPriceBar,
    } = this.props;
    if (isWidthUp("md", this.props.width, false)) return null;
    if (isFixedPriceBar) {
      return (
        <div
          className={
            isFixedPriceBar
              ? "md:tw-hidden tw-block tw-bg-white tw-text-white tw-p-5 mobile-fixed-pricebar tw-border-t"
              : "tw-bg-skiptiOrange tw-text-white tw-p-2 md:tw-hidden tw-block"
          }
          style={{ zIndex: "3" }}
        >
          <div className="tw-flex tw-flex-row">
            <div className="tw-w-full tw-self-center">
              {product.votesToAcquire > 0 ? (
                <VoteItemButtons
                  resourceId={product.resourceId}
                  castVote={this.voteItem}
                  short
                  inverse
                />
              ) : product.deliveryModel === ITEM_DELIVERY_MODEL.REGULAR ||
                product.deliveryModel === ITEM_DELIVERY_MODEL.REPAIR ? (
                // &&
                // !product.isBundle &&
                // !product.inBundle
                <button
                  type="button"
                  className="btn-skipti-orange tw-block tw-text-center tw-w-full tw-h-12 tw-px-2 tw-py-2"
                  style={{
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "0.5px",
                    textAlign: "center",
                    color: "#fff",
                  }}
                  onClick={this.props.handleRent}
                >
                  {`${
                    this.props.isServiced
                      ? "RESERVE NOW"
                      : "Check Delivery Area"
                  } $${
                    subtotal && typeof subtotal !== "undefined"
                      ? subtotal.toFixed(2)
                      : product.basePerDayUsd &&
                        typeof product.basePerDayUsd !== "undefined" &&
                        product.basePerDayUsd > 0
                      ? product.basePerDayUsd.toFixed(2)
                      : product.renterCostPerDayUsd &&
                        typeof product.renterCostPerDayUsd !== "undefined" &&
                        product.renterCostPerDayUsd > 0
                      ? product.renterCostPerDayUsd.toFixed(2)
                      : product.basePerWeekUsd && product.basePerWeekUsd > 0
                      ? product.basePerWeekUsd.toFixed(2)
                      : product.basePerMonthUsd && product.basePerMonthUsd > 0
                      ? product.basePerMonthUsd.toFixed(2)
                      : "0"
                  }`}
                </button>
              ) : product.canDeliverElectronically === true ? (
                <a
                  href={product.urlToBuy}
                  // className="btn-skipti-primary tw-block tw-text-center"
                  className="btn-skipti-orange tw-block tw-text-center tw-w-full tw-h-12 tw-px-2 tw-py-2"
                >
                  {/* GET IT! */}
                  {`GET IT! $${
                    subtotal && typeof subtotal !== "undefined"
                      ? subtotal.toFixed(2)
                      : product.basePerDayUsd &&
                        typeof product.basePerDayUsd !== "undefined" &&
                        product.basePerDayUsd > 0
                      ? product.basePerDayUsd.toFixed(2)
                      : product.renterCostPerDayUsd &&
                        typeof product.renterCostPerDayUsd !== "undefined" &&
                        product.renterCostPerDayUsd > 0
                      ? product.renterCostPerDayUsd.toFixed(2)
                      : product.basePerWeekUsd && product.basePerWeekUsd > 0
                      ? product.basePerWeekUsd.toFixed(2)
                      : product.basePerMonthUsd && product.basePerMonthUsd > 0
                      ? product.basePerMonthUsd.toFixed(2)
                      : "0"
                  }`}
                </a>
              ) : product.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY ||
                product.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY ? (
                <button
                  type="button"
                  className="btn-skipti-orange tw-block tw-text-center tw-w-full tw-h-12 tw-px-2 tw-py-2"
                  // className="btn-skipti-inverse tw-block tw-text-center tw-w-full tw-px-2 tw-py-2"
                  style={{ width: "100%", padding: "9px 8px" }}
                  onClick={this.props.handleDeliver}
                >
                  {/*  
                  SCHEDULE */}
                  {`${
                    this.props.isServiced
                      ? "RESERVE NOW"
                      : "Check Delivery Area"
                  } $${
                    subtotal && typeof subtotal !== "undefined"
                      ? subtotal.toFixed(2)
                      : product.basePerDayUsd &&
                        typeof product.basePerDayUsd !== "undefined" &&
                        product.basePerDayUsd > 0
                      ? product.basePerDayUsd.toFixed(2)
                      : product.renterCostPerDayUsd &&
                        typeof product.renterCostPerDayUsd !== "undefined" &&
                        product.renterCostPerDayUsd > 0
                      ? product.renterCostPerDayUsd.toFixed(2)
                      : product.basePerWeekUsd && product.basePerWeekUsd > 0
                      ? product.basePerWeekUsd.toFixed(2)
                      : product.basePerMonthUsd && product.basePerMonthUsd > 0
                      ? product.basePerMonthUsd.toFixed(2)
                      : "0"
                  }`}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Paper elevation={2}>
          <div className="tw-flex tw-flex-row tw-py-3 tw-px-2">
            <div className="tw-w-7/12 tw-self-center">
              <span className="display-4 fw-light tw-text-right">
                $
                {/* {product.basePerDayUsd !== null && product.basePerDayUsd >= 0
                  ? product.basePerDayUsd
                  : "0"} */}
                {subtotal && typeof subtotal !== "undefined"
                  ? subtotal.toFixed(2)
                  : product.basePerDayUsd &&
                    typeof product.basePerDayUsd !== "undefined" &&
                    product.basePerDayUsd > 0
                  ? product.basePerDayUsd.toFixed(2)
                  : product.renterCostPerDayUsd &&
                    typeof product.renterCostPerDayUsd !== "undefined" &&
                    product.renterCostPerDayUsd > 0
                  ? product.renterCostPerDayUsd.toFixed(2)
                  : product.basePerWeekUsd && product.basePerWeekUsd > 0
                  ? product.basePerWeekUsd.toFixed(2)
                  : product.basePerMonthUsd && product.basePerMonthUsd > 0
                  ? product.basePerMonthUsd.toFixed(2)
                  : "0"}
                <input
                  id="daily_price"
                  type="hidden"
                  value={product.basePerDayUsd}
                />
              </span>
              {product.canDeliverElectronically === true ? null : (
                <span className="tw-text-right tw-mb-6">
                  {(subtotal !== null && typeof subtotal !== "undefined") ||
                  product.deliveryModel === 3 ||
                  product.deliveryModel === 4 ||
                  product.deliveryModel === 5
                    ? " total"
                    : product.subsequentDayUsd && product.subsequentDayUsd > 0
                    ? " first night"
                    : product.basePerDayUsd > 0
                    ? " / night"
                    : product.basePerWeekUsd && product.basePerWeekUsd > 0
                    ? " / week"
                    : product.basePerMonthUsd && product.basePerMonthUsd > 0
                    ? " / month"
                    : ""}
                </span>
              )}
              {product &&
              product.subsequentDayUsd &&
              product.subsequentDayUsd > 0 ? (
                <span className="tw-text-skiptiOrange">
                  <PriceInfoPopper
                    black
                    product={product}
                    rentDuration={rentDuration}
                    total={total}
                    salesTax={salesTax}
                    subtotal={subtotal}
                    delivery={delivery}
                    productCosts={productCosts}
                    skiptiCredit={skiptiCredit}
                    insurance={insurance}
                  />
                </span>
              ) : null}
            </div>

            <div className="tw-w-5/12 tw-self-center">
              {product.votesToAcquire > 0 ? (
                <VoteItemButtons
                  resourceId={product.resourceId}
                  castVote={this.voteItem}
                  short
                  inverse
                />
              ) : product.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY ||
                product.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY ? (
                <button
                  type="button"
                  className="btn-skipti-primary tw-block tw-text-center tw-w-full tw-py-2"
                  onClick={this.props.handleDeliver}
                >
                  SCHEDULE
                </button>
              ) : product.canDeliverElectronically === true ? (
                <a
                  href={product.urlToBuy}
                  className="btn-skipti-primary tw-block tw-text-center"
                >
                  GET IT!
                </a>
              ) : (
                <button
                  type="button"
                  className="btn-skipti-primary tw-block tw-text-center tw-w-full"
                  onClick={this.props.handleRent}
                >
                  {this.props.isServiced
                    ? product.deliveryModel === ITEM_DELIVERY_MODEL.REPAIR
                      ? "SCHEDULE"
                      : "Rent it!"
                    : "Check Delivery Area"}
                </button>
              )}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withWidth()(MobilePricebar);
