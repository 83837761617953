import React from "react";
// import iconExperience from "../../img/tile-ico-experience.svg";
// import { imageServerUrl } from "../../utils";

export default function CategoryTile({ tile = {} }) {
  return (
    <div className="md:tw-w-full tw-mb-6">
      <div className="item-tile">
        {/* <Link
                    to={`/categories/${category.id}`}
                    href={`/categories/${category.id}`}
                    className="item-link"
                  > */}
        <div className="item-tile-carousel">
          <img alt=" " src={tile.image} className="img-fluid tw-mb-2" />
        </div>

        <p className="item-title tw-text-black fw-medium tw-mb-2">
          {tile.title}
        </p>
        <p className="item-title tw-text-verylightgrey tw-mb-0">
          {tile.description}
        </p>
        {/* </Link> */}
      </div>
    </div>
  );
}
