import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Parallax } from "react-parallax";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import { getIsLoggedIn, getUser, getToken } from "../../reducers/authReducer";
import { getArticles } from "../../reducers/articlesReducer";
// import imageTest from "../../img/SkiptiGraphic1.png";
import { imageServerUrl } from "../../utils";

class LayoutStaticPages extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    current_user: PropTypes.shape({}).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
      PropTypes.shape({}).isRequired,
    ]).isRequired,
    articles: PropTypes.shape({
      isLoaded: PropTypes.bool.isRequired,
      isFetching: PropTypes.bool.isRequired,
      isError: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  };

  static defaultProps = {
    articles: [],
  };

  state = {};

  render() {
    const {
      isLoggedIn,
      current_user,
      children,
      articles,
      noFooter,
      renderStep,
      showStep,
      currentProgress,
      showProgress,
      showSearch,
      jwt,
    } = this.props;
    const articlesData = (articles && articles.data) || [];
    return (
      <>
        <Navbar
          isLoggedIn={isLoggedIn || !!jwt}
          current_user={current_user}
          renderStep={renderStep}
          showStep={showStep}
          currentProgress={currentProgress}
          showProgress={showProgress}
          showSearch={showSearch}
        />
        <Parallax
          blur={0}
          bgImage={
            this.props.headerImage || `${imageServerUrl}/SkiptiGraphic1.png`
          }
          bgImageAlt="the cat"
          bgStyle={{ top: "-50%" }}
          strength={350}
          className="parallax-window p-5"
        >
          {/* {this.props.title} */}
          <div style={{ height: "300px" }} />
        </Parallax>

        {/* <div className="parallax-window p-5" /> */}
        {/* </div> */}
        <div className="container my-5">{children}</div>
        {!noFooter && <Footer articles={articlesData} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  articles: getArticles(state),
  isLoggedIn: getIsLoggedIn(state),
  current_user: getUser(state),
  jwt: getToken(state),
});

export default connect(mapStateToProps)(LayoutStaticPages);
