import React from "react";
// import PropTypes from "prop-types";
// import "semantic-ui-css/components/rating.min.css";
// import { Rating } from "semantic-ui-react";
import Rating from "react-rating";

export default function SkiptiRating(props) {
  const { rating, fractions, className, readonly } = props;
  return (
    <Rating
      {...props}
      readonly={readonly}
      initialRating={parseFloat(rating)}
      stop={5}
      fractions={fractions || 2}
      emptySymbol="fal fa-star"
      fullSymbol="fas fa-star"
      // quiet={quiet}
      className={className}
    />
  );
}
SkiptiRating.defaultProps = {
  readonly: false,
};
