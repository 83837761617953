import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseButton from "@material-ui/icons/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import classnames from "classnames";

const MAX_SWIPE_DISTANCE = 30;

const DialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @typedef {import("@material-ui/core").DialogProps & {
 * open?: boolean;
 * onExited: Function;
 * toggle: Function;
 * withCloseIcon?: boolean;
 * title?: string
 * loading?: boolean;
 * headerProps?: any;
 * contentProps?: any;
 * swipeable?: boolean;
 * height?: number;
 * }} SkiptiDialogProps
 * @param {SkiptiDialogProps}
 */
function BottomDialog({
  open = false,
  swipeable = false,
  toggle,
  children,
  title,
  withCloseIcon,
  loading = false,
  onExited,
  maxWidth,
  headerProps,
  contentProps,
  height,
  ...rest
}) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const onTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientY);
    setTouchEnd(null);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchEnd - touchStart;

    if (touchStart < touchEnd && distance >= MAX_SWIPE_DISTANCE && toggle) {
      toggle();
    }
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  return (
    <Dialog
      onClose={toggle}
      open={open}
      maxWidth={maxWidth}
      TransitionProps={{ onExited }}
      TransitionComponent={DialogTransition}
      transitionDuration={500}
      {...rest}
      className={classnames("tw-relative skipti-dialog", {
        "skipti-dialog-loading": loading,
        [rest.className]: rest.className,
      })}
      style={{
        width: "100%",
      }}
      {...(swipeable && {
        onTouchStart,
        onTouchMove,
        onTouchEnd,
      })}
    >
      <div
        className="tw-bg-white tw-fixed tw-bottom-0 tw-left-0 tw-w-full"
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          height: `${height}%`,
          // maxHeight: "calc(90vh)",
          overflow: "auto",
        }}
      >
        <DialogTitle
          disableTypography
          className="paddingMarginRemover"
          {...headerProps}
        >
          {title && (
            <h4
              className={classnames("tw-text-2xl tw-font-bold tw-font-lato", {
                "mod-title-with-close-icon": withCloseIcon,
              })}
            >
              {title}
            </h4>
          )}
          {withCloseIcon && (
            <IconButton
              color="secondary"
              onClick={toggle}
              className="paddingMarginRemover"
            >
              <CloseButton />
            </IconButton>
          )}
        </DialogTitle>

        <DialogContent className="paddingMarginRemover" {...contentProps}>
          {children}
        </DialogContent>
      </div>
    </Dialog>
  );
}

BottomDialog.defaultProps = {
  height: 90,
};

export default BottomDialog;
