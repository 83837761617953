import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Tooltip } from "@material-ui/core";
import MixpanelContext from "react-mixpanel";
import SkiptiButton from "../skipti/Button";
import {
  getUser,
  getIsLoggedIn,
  getIsAdmin,
  getIsLogInModalOpen,
  getToken,
} from "../../reducers/authReducer";
import * as helpers from "../../utils";
import {
  closeLogInModal,
  openLogInModal,
  setVoteForProductAfterAuth,
  showSnackbar,
  setSignInMessage,
  fetchProductById,
  fetchVotableProducts,
} from "../../actions";

class VoteItemButtons extends Component {
  static propTypes = {
    resourceId: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
  };

  static contextType = MixpanelContext;

  state = {
    areYouSureModalOpen: false,
    voted: localStorage.getItem("votedAnItem"),
    isFetching: false,
  };

  handleVote = (resourceId) => () => {
    if (this.props)
      if (!this.state.voted || this.state.voted === "false") {
        if (this.props.isLoggedIn) {
          this.setState({
            areYouSureModalOpen: true,
          });
        } else {
          this.voteItem(
            resourceId,
            this.props.productName,
            this.props.productCategoryName,
            this.props.basePerDayUsd
          );
        }
      }
  };

  voteItem = async () => {
    const {
      product: { productName, productCategory, productPrice, productId },
      current_user: { email },
      pageNumber,
      pageSize,
    } = this.props;
    const voteThisProduct = {
      productId,
      productName,
      productCategory,
      productPrice,
    };
    if (this.props.isLoggedIn) {
      this.setState((_prev) => ({
        ..._prev,
        isFetching: true,
      }));
      const voteResult = await helpers.voteProduct(
        this.props.jwt,
        voteThisProduct,
        this.context,
        window.analytics,
        email
      );
      if (voteResult.message && voteResult.status !== 200) {
        this.props.showSnackbar(voteResult.message, "error");
      } else {
        this.props.showSnackbar("Item voted successfully!", "success");
        this.props.fetchProductById(productId);
      }
      this.setState();
    } else {
      this.props.setSignInMessage(
        "Thanks for voting. Please create an account or use your login so we can notify you when this item becomes available. Accounts are free and you will not be required to rent."
      );
      this.props.setVoteForProductAfterAuth(voteThisProduct);
      this.props.openLogInModal();
    }
    await this.props.fetchVotableProducts({ pageNumber, pageSize }, true);
    this.setState((_prev) => ({
      ..._prev,
      isFetching: false,
      areYouSureModalOpen: false,
    }));
  };

  closeModal = () => {
    this.setState({ areYouSureModalOpen: false });
  };

  render() {
    const { resourceId } = this.props;
    return (
      <>
        <div className="tw-flex tw-flex-wrap">
          <div className="tw-w-full tw-text-center">
            <Tooltip title="Vote" placement="top">
              <button
                className={`${
                  this.props.inverse
                    ? "btn-skipti-inverse tw-block tw-text-center"
                    : "btn-skipti-primary rent-it-custom-bttn md:tw-px-0 lg:tw-px-3 xl:tw-px-4"
                }`}
                onClick={this.handleVote(resourceId)}
                type="button"
                style={{ width: "100%", fontSize: "0.8em" }}
              >
                {this.props.short ? (
                  <>
                    <i className="fas fa-plus mr-2" />
                    WAITLIST
                  </>
                ) : (
                  "ADD ME TO THE WAITLIST"
                )}
              </button>
            </Tooltip>
          </div>
        </div>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.areYouSureModalOpen}
          onClose={this.closeModal}
        >
          <div className="tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full">
            <div className="tw-bg-white tw-max-w-screen-md tw-mx-auto">
              <div className="tw-border tw-border-opacity-25 tw-border-black tw-rounded-sm">
                <div className="tw-p-4">
                  Are you sure you want to vote this item?
                  <div className="tw-text-center tw-mt-4">
                    <div className="tw-mr-2 tw-inline">
                      <SkiptiButton
                        design="secondary dialog"
                        onClick={this.closeModal}
                        disabled={this.state.isFetching}
                      >
                        No
                      </SkiptiButton>
                    </div>
                    <SkiptiButton
                      design="primary dialog"
                      onClick={this.voteItem}
                      disabled={this.state.isFetching}
                    >
                      Yes
                    </SkiptiButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  current_user: getUser(state),
  isAdmin: getIsAdmin(state),
  isLoggedIn: getIsLoggedIn(state),
  isLogInModalOpen: getIsLogInModalOpen(state),
  jwt: getToken(state),
});

export default connect(mapStateToProps, {
  openLogInModal,
  closeLogInModal,
  setVoteForProductAfterAuth,
  showSnackbar,
  setSignInMessage,
  fetchProductById,
  fetchVotableProducts,
})(withRouter(VoteItemButtons));
