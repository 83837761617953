import React, { Component } from "react";
// import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

class MaskedTextInput extends Component {
  render() {
    const { inputRef, ...other } = this.props;
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        guide={false}
        mask={[
          "(",
          /[2-9]/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholderChar={"\u2000"}
        showMask
      />
    );
  }
}

export default MaskedTextInput;
