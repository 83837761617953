import React from "react";
import LayoutStaticPages from "./layouts/LayoutStaticPages";

export default function About() {
  return (
    <LayoutStaticPages showSearch>
      <div>
        <h3>
          Skipti was founded with one goal in mind: to give people more freedom.
        </h3>
        <p>
          We believe it should be fast and easy to get on-demand access to all
          the physical things that empower your life. Up until now, temporary
          access to goods has always been inefficient and dysfunctional. With
          Skipti’s online scheduling, payment, and same-day delivery, anything
          is possible. Be an outdoor adventurer today and a chef tomorrow. Try
          all the latest tech with no commitment and live life on your own
          terms.
        </p>
        <p>
          We get the most joy out of the experiences we have, the people we
          meet, and the places we go. Bigger homes and more “stuff” has always
          left us feeling more empty and more burdened. We value quality over
          quantity and that’s why we’re making it easy to rent the things you
          need temporarily and to only buy the things you truly fall in love
          with. As Mary Kondo says, “keep the things that spark joy.”
        </p>
        <p>
          We hope Skipti sparks joy in your life and that by using our platform
          you discover new hobbies, take new adventures, learn new skills, and
          live a more efficient, more fulfilling life.
        </p>
      </div>
    </LayoutStaticPages>
  );
}
