import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverUrl } from "../../../utils";
import { showSnackbar } from "../../../actions/snackbar";

export const selectFetching = (state) => state.dmsDriverManifest.isFetching;
export const selectDriverManifest = (state) =>
  state.dmsDriverManifest.driverManifest;
export const selectIsError = (state) => state.dmsDriverManifest.isError;
export const selectErrorMessage = (state) =>
  state.dmsDriverManifest.errorMessage;

export const selectCallingAssistant = (state) =>
  state.dmsDriverManifest.isCalling;

export const getDriverManifest = createAsyncThunk(
  "dmsDriverManifest/postAttachments",
  async ({ secTok, isDMS = false, orderId }, thunkAPI) => {
    try {
      const res = await axios.get(`${serverUrl}/api/v1/manifest/o/${orderId}`, {
        params: {
          secTok,
          isDMS,
        },
      });
      return res.data;
    } catch (e) {
      let _message = "";
      if (e.response && e.response.status === 400) {
        _message = "Order not found";
      } else {
        _message = (e.response && e.response.data) || e.message;
      }

      thunkAPI.dispatch(
        showSnackbar(_message, "error", null, null, JSON.stringify(e))
      );
      return thunkAPI.rejectWithValue(_message);
    }
  }
);

export const callToAssistance = createAsyncThunk(
  "dmsDriverManifest/callToAssistance",
  async ({ orderId, secTok }, thunkAPI) => {
    try {
      const isFetch = orderId.slice(-1).toLowerCase() === "f";
      const id = orderId.slice(0, -1);
      const res = await axios.post(
        `${serverUrl}/api/v1/manifest/assistance/${id}`,
        {},
        {
          params: {
            secTok,
            isFetch,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  }
);

export const slice = createSlice({
  name: "dmsDriverManifest",
  initialState: {
    isFetching: true,
    driverManifest: null,
    errorMessage: null,
    isError: false,
    isCalling: false,
  },
  reducers: {
    cleanManifest(state) {
      state.driverManifest = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriverManifest.pending, (state) => {
        state.isFetching = true;
        state.errorMessage = null;
        state.isError = false;
      })
      .addCase(getDriverManifest.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload ?? "Route is not more available";
        state.isError = true;
      })
      .addCase(getDriverManifest.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.driverManifest = payload;
        state.errorMessage = null;
        state.isError = false;
      })
      .addCase(callToAssistance.pending, (state) => {
        state.isCalling = true;
      })
      .addCase(callToAssistance.rejected, (state) => {
        state.isCalling = false;
      })
      .addCase(callToAssistance.fulfilled, (state) => {
        state.isCalling = false;
      });
  },
});

export const { cleanManifest } = slice.actions;

export default slice.reducer;
