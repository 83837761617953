import React from "react";
import HowItWorksAccordion from "./HowItWorksAccordion";

export default function HowItWorksFAQ() {
  return (
    <>
      <div className="tw-container tw-flex tw-flex-col tw-mx-auto">
        <HowItWorksAccordion
          summary="Do I have to be home to receive and return my item?"
          details="This varies based on the item chosen. Some items 
          will require you to be home as they require signature upon delivery while other items do not need you to be home 
          and will be left at your front door. In both cases, you are responsible for the item once it is out of the hands of the Skipti driver. 
          To understand what is required, please see the item description page at checkout. "
        />

        <HowItWorksAccordion
          summary="Am I responsible for cleaning or charging my item before I
        return it?"
          details="You are responsible for returning your item in the same condition it was in when you received it.
           Please charge batteries, empty filters, wipe down dirt, and treat items as if they were your own. Renters who fail to do so may be charged a cleaning or 
           restocking fee and may not be able to rent from Skipti in the future. Typical wear and tear is expected and will not be counted against you."
        />

        <HowItWorksAccordion
          summary="What happens if I break or damage a rented item?"
          details="You are fully liable for loss or damages caused to the item. You must, therefore, make sure you have the necessary funds available to replace or repair the item in the event that you are unable to return it in the condition it was given to you. Skipti reserves the right to charge you the full amount for item repair or replacement in the event of damages."
        />

        <HowItWorksAccordion
          summary="What happens if I return my rental late?"
          details={
            <>
              If you are unable to return your item on time, you must notify
              Skipti as soon as you are able: 617-383-4235 phone or text, click
              the chat button found in the lower right hand corner of the page
              at{" "}
              <a href="https://skipti.com/">
                Skipti - Automating Circular Experience
              </a>{" "}
              or email{" "}
              <a href="mailto:pleasehelpme@skipti.com">
                pleasehelpme@skipti.com.
              </a>{" "}
              <br />
              <strong>
                {" "}
                Fees and penalties may apply, see Skipti{" "}
                <a href="https://marketplace.skipti.com/termsandconditions">
                  terms & conditions,
                </a>{" "}
                Appendix B to know your exact item's Late Returns policy and
                fees.
              </strong>
              <br /> 1. If it is possible to reschedule your pickup for the same
              day, Customer Service will do so.
              <br />
              2. If you are not able to reschedule for the same day, Customer
              Service will schedule a courier in the next available window.
            </>
          }
        />
        <HowItWorksAccordion
          summary="What is your cancellation policy?"
          details={
            <>
              There is no cancellation fee if you cancel online up to two hours
              prior to the delivery window. Cancellation online two hours before
              and within the delivery window will result in the total cost of
              the rental duration as well as courier fees. See Skipti{" "}
              <a href="https://marketplace.skipti.com/termsandconditions">
                terms & conditions,
              </a>{" "}
              <a href="http://localhost:3000/termsandconditions#appendixB">
                Appendix B
              </a>{" "}
              to know your exact item's cancellation policy and fees.
            </>
          }
        />

        <HowItWorksAccordion
          summary="What are the minimum and maximum days I can reserve items?"
          details="Each item has a set minimum trial period. You can find this setting in the item detail page under the booking calendar as well as on the item page itself."
        />

        <HowItWorksAccordion
          summary="What happens if my item arrives damaged?"
          details={
            <>
              You must notify us within 2 (two) hours of the item’s arrival of
              any damage. Visit the Skipti{" "}
              <a href="https://skipti.com/help/">help page</a> or contact us
              through chat on the website with a description and photo of your
              damaged item. If there is a mechanical issue please include video
              with your email. It&apos;s your responsibility as the renter to
              check that the item is compatible for its intended purpose prior
              to the rental (ex. researching which mount fits your camera).
            </>
          }
        />

        <HowItWorksAccordion
          summary="If I like my rental item, can I keep it?"
          details={
            <>
              We have included “buy this item” links on all of the item detail
              pages if your selected item is applicable for this program. Often
              our partners will provide discount codes so keep an eye out for
              follow up emails from Skipti after your rental. If you are
              considering purchasing your rental item and do not see the “buy
              this item” button, contact us at{" "}
              <a href="mailto:PleaseHelpMe@Skipti.com">
                PleaseHelpMe@Skipti.com
              </a>{" "}
              to learn more.
            </>
          }
        />

        <HowItWorksAccordion
          summary="Whom do I contact with questions?"
          details={
            <>
              You can reach us anytime at{" "}
              <a href="mailto:PleaseHelpMe@Skipti.com">
                PleaseHelpMe@Skipti.com
              </a>{" "}
              or via our live chat service on{" "}
              <a href="marketplace.skipti.com.">marketplace.skipti.com.</a> Our
              customer service team is available to answer questions between 7
              AM and 9 PM in all time zones and will get you to the appropriate
              party based on your needs.
            </>
          }
        />

        <HowItWorksAccordion
          summary="Can I rent more than one thing at a time?"
          details="Adding more than one item to an order depends on the product you are trying to order. Some items may be combined with additional goods during check out, while others require separate order placement at this time."
        />
      </div>
    </>
  );
}
