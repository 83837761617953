import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import omit from "lodash/omit";
import moment from "moment";

import { SingleDatePicker } from "react-dates";

const propTypes = {};

const defaultProps = {
  onFocusChange: () => {},
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  date: null,
  // input related props
  // startDateId: "START_DATE",
  // datePlaceholderText: "Date",
  className: "",
  isError: false,
};

class SingleDatePickerWrapper extends React.Component {
  static propTypes = {
    // example props for the demo
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    date: momentPropTypes.momentObj,
    onDateChange: PropTypes.func.isRequired,
    // startDateId: PropTypes.string,
    // endDateId: PropTypes.string,
    className: PropTypes.string,
    dateType: PropTypes.string,
    withOutFooter: PropTypes.bool,
    isError: PropTypes.bool,
    onFocusChange: PropTypes.func,
    // checkForBlockedDates: PropTypes.func
  };

  static defaultProps = {
    onFocusChange: () => {},
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,
    date: null,
    // input related props
    // startDateId: "START_DATE",
    // datePlaceholderText: "Date",
    className: "",
    isError: false,
    withOutFooter: false,
  };
  // constructor(props) {
  //   super(props);

  //   const focusedInput = null;
  //   if (props.autoFocus) {
  //     //   focusedInput = START_DATE;
  //   } else if (props.autoFocusEndDate) {
  //     //   focusedInput = END_DATE;
  //   }
  // }
  state = {
    focusedInput: null,
  };

  onFocusChange = ({ focused }) => {
    this.props.onFocusChange(focused);
    this.setState({ focused });
    document.activeElement.blur();
  };

  renderCalendarInfo = () => {
    const { minimumNights, maximumNights, dateType = "Delivery" } = this.props;
    if (this.props.isError) {
      return (
        <div
          style={{
            display: "flex",
            paddingBottom: "22px",
            justifyContent: "center",
          }}
        >
          <ErrorOutlineIcon style={{ color: "red" }} />
          <span style={{ color: "red", paddingLeft: "5px" }}>Error</span>
        </div>
      );
    }
    return (
      <div
        style={
          this.props.withPortal
            ? {
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "22px",
                position: "absolute",
                bottom: "70px",
              }
            : {
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "22px",
              }
        }
      >
        {/* <div
          style={{ textAlign: "center", width: "100%", marginBottom: "12px" }}
        >
          Choose rental start and end dates
        </div> */}
        {!this.props.withOutFooter && (
          <>
            <div
              style={{
                display: "inline-flex",
                flex: 1,
                padding: "0 13px 0 23px",
              }}
            >
              <div
                style={{
                  width: "39px",
                  height: "38px",
                  flexShrink: 0,
                }}
                className="CalendarDay CalendarDay_1 CalendarDay__defaultCursor CalendarDay__defaultCursor_2 CalendarDay__default CalendarDay__default_3 CalendarDay__blocked_calendar CalendarDay__blocked_calendar_5"
              >
                {/* 1 */}
              </div>
              <span
                style={{
                  top: "-5px",
                  position: "relative",
                  paddingLeft: "10px",
                }}
              >
                {dateType} not available
              </span>
            </div>
            <div
              style={{
                display: "inline-flex",
                flex: 1,
                padding: "0 13px 0 3px",
              }}
            >
              <div
                style={{
                  width: "39px",
                  height: "38px",
                  cursor: "default",
                  flexShrink: 0,
                }}
                className="CustomCalendarDay CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2 CalendarDay__highlighted_calendar CalendarDay__highlighted_calendar_3"
              >
                {/* 1 */}
              </div>
              <span
                style={{
                  top: "-5px",
                  position: "relative",
                  paddingLeft: "10px",
                }}
              >
                Choose the {dateType.toLowerCase()} date
              </span>
            </div>
          </>
        )}
        {/* <div
          style={{
            width: "100%",
            padding: "0 13px 0 23px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>Minimum rental days: {minimumNights}</span>
          <span>Maximum rental days: {maximumNights}</span>
        </div> */}
      </div>
    );
  };

  isOutsideRange = (day) => {
    const { startDate, maximumNights } = this.props;
    const { focusedInput } = this.state;
    const maxDate = moment(startDate).add(maximumNights, "day");
    const today = moment();

    if (
      moment(day).isSame(moment(), "day") &&
      moment().isAfter(moment().hour(18).minutes(30))
    ) {
      return true;
    }

    if (moment(day).isBefore(moment(), "day")) {
      return true;
    }
    return false;
  };

  render() {
    const { focused } = this.state;
    const { date, className } = this.props;
    const props = omit(this.props, [
      "dateType",
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "stateDateWrapper",
      "className",
      "isError",
      "maximumNights",
      "checkForBlockedDates",
      "date",
      "focused",
      "onDatesChange",
      "startDateId",
      "startDatePlaceholderText",
      "withOutFooter",
    ]);
    return (
      <div className={className}>
        <SingleDatePicker
          isOutsideRange={this.isOutsideRange}
          {...props}
          block
          hideKeyboardShortcutsPanel
          onDateChange={props.onDateChange}
          onFocusChange={this.onFocusChange}
          id="date-input"
          focused={focused}
          date={date}
          // onPrevMonthClick={props.onPrevMonthClick}
          // onNextMonthClick={props.onNextMonthClick}
          isDayBlocked={props.isDayBlocked}
          initialVisibleMonth={() => {
            if (
              moment().isSame(moment().endOf("month"), "day") &&
              moment().isAfter(moment().hour(18).minutes(30))
            ) {
              return moment().add(1, "months").startOf("month");
            }
            if (date) {
              return moment(date);
            }
            return moment();
          }}
          // orientation={props.orientation}
          // withPortal={props.withPortal}
          placeholder={this.props.placeholder || "Pick your date"}
          isDayHighlighted={this.props.isDayHighlighted}
          renderCalendarInfo={this.renderCalendarInfo}
        />
      </div>
    );
  }
}

export default SingleDatePickerWrapper;
