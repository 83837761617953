import * as TYPES from "../types";
import * as helpers from "../utils";

const experiencesReducer = (
  state = { experiences: helpers.makeInitial() },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.EXPERIENCES_ALL_REQUEST:
      return { ...state, items: helpers.makeStartLoading() };
    case TYPES.EXPERIENCES_BYID_REQUEST:
      return { ...state, byId: helpers.makeStartLoading() };
    case TYPES.EXPERIENCES_ALL_SUCCESS:
      return { ...state, items: helpers.makeLoadSuccess(payload) };
    case TYPES.EXPERIENCES_BYID_SUCCESS:
      return { ...state, byId: helpers.makeLoadSuccess(payload) };
    case TYPES.EXPERIENCES_ALL_FAIL:
      return { ...state, items: helpers.makeLoadFailed(error) };
    case TYPES.EXPERIENCES_BYID_FAIL:
      return { ...state, byId: helpers.makeLoadFailed(error) };
    default:
      return state;
  }
};

export const getExperiences = (state) => state.experiences.items;

export default experiencesReducer;
