import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import {
  getIsLoggedIn,
  getUser,
  getUserRoles,
} from "../../reducers/authReducer";
import { getArticles } from "../../reducers/articlesReducer";
import Alerts from "../partials/Alerts";
import { fetchProductsTrending } from "../../actions";
import { getProductsTrending } from "../../reducers/itemsReducer";
import useNavData from "../Drawer/statData";
import { getCollapses, setCollapses } from "./layoutDashboardSlice";

const LayoutDashboard = ({
  isLoggedIn,
  current_user,
  children,
  articles,
  current_page,
  flash,
  withAdditionalLinks = true,
  isRedesigned = false,
  showSearch,
}) => {
  const [accessChecker, setAccessChecker] = useState({
    account: true,
    admin: true,
    dms: true,
    inventory: true,
    sendLink: true,
    permissionsData: {},
  });
  const dispatch = useDispatch();
  const roles = useSelector(getUserRoles);
  const navData = useNavData(
    undefined,
    undefined,
    accessChecker,
    setAccessChecker,
    undefined,
    undefined
  );
  const collapsesState = useSelector(getCollapses);

  const { dashboardData, accountData } = navData;

  const articlesData =
    (articles && articles.data && articles.data.teasers) || [];

  const toggleParent = (id) => {
    let newCollapsesState = [];
    const isExistIndex = collapsesState.indexOf(id);

    if (isExistIndex === -1) {
      newCollapsesState = [...collapsesState, id];
    } else {
      newCollapsesState = collapsesState.filter((item) => item !== id);
    }
    dispatch(setCollapses(newCollapsesState));
  };

  useEffect(() => {
    if (roles && roles.length) {
      dashboardData.forEach((item) => {
        const endData = navData.innerDataGenerator(item.name);
        const defaultExpand = Array.isArray(endData)
          ? endData.some((el) => el.name === current_page)
          : endData;

        if (defaultExpand && !collapsesState.length) {
          toggleParent(item.id);
        }
      });
    }
  }, [roles]);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "none",
      border: "none",
      minHeight: 20,
      padding: 0,
      width: "60%",
      "&$expanded": {
        minHeight: 20,
      },
    },
    content: {
      margin: 0,
      color: "#838383",
      fontWeight: 600,
      "&$expanded": {
        margin: "0",
      },
    },
    expanded: {},
    expandIcon: {
      padding: 0,
    },
  })(MuiAccordionSummary);

  const Accordion = withStyles({
    root: {
      border: "none",
      boxShadow: "none",
      minHeight: 15,
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "0",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionDetails = withStyles({
    root: {
      padding: "8px 16px 0",
    },
  })(MuiAccordionDetails);

  const collapseItem = (innerTab) => {
    if (innerTab.action) {
      innerTab.action();
    }
  };

  const renderAccordionItem = (innerTab, item) => (
    <AccordionDetails
      key={innerTab.name}
      className={`tw-truncate tw-font-semibold tw-mb-2 tw-cursor-pointer ${
        current_page === innerTab.name
          ? "tw-text-skiptiOrange"
          : innerTab.disabled
          ? "tw-pointer-events-none tw-text-verylightgrey"
          : "tw-text-lightgrey"
      }
                            `}
      onClick={(e) => {
        e.stopPropagation();
        collapseItem(innerTab, item);
      }}
    >
      {innerTab.title}
    </AccordionDetails>
  );

  const renderOrdinaryItem = (item) => (
    <div
      key={item.name}
      className={`tw-font-semibold tw-cursor-pointer ${
        current_page === item.name
          ? "tw-text-skiptiOrange"
          : item.disabled
          ? "tw-pointer-events-none tw-text-verylightgrey"
          : "tw-text-lightgrey"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        collapseItem(item);
      }}
    >
      {item.title}
    </div>
  );

  const renderMenuItem = (item, key) => {
    if (!accessChecker[item.name]) {
      return null;
    }

    const endData = navData.innerDataGenerator(item.name);

    return Array.isArray(endData) ? (
      <Accordion
        key={item.name}
        expanded={collapsesState.includes(item.id)}
        className="tw-mb-4"
        onClick={() => toggleParent(item.id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />}>
          {item.title}
        </AccordionSummary>

        {endData.map((innerTab) => renderAccordionItem(innerTab, item))}
      </Accordion>
    ) : (
      renderOrdinaryItem(endData)
    );
  };

  return (
    <>
      <Navbar
        showSearch={showSearch}
        isLoggedIn={isLoggedIn}
        current_user={current_user}
        withAdditionalLinks={withAdditionalLinks}
      />
      <div
        className={`tw-w-full tw-mx-auto ${
          isRedesigned ? "tw-pl-4 lg:tw-pl-8" : "tw-px-4 lg:tw-px-8"
        } tw-my-8`}
      >
        <div className="tw-flex tw-flex-wrap tw-mt-3">
          <div className="sm:tw-w-3/12 md:tw-w-2/12 tw-hidden sm:tw-block">
            <ul className="list-unstyled left-nav tw-pl-0 tw-list-none">
              <li className="tw-mb-4">
                <NavLink
                  to="/"
                  className="tw-truncate tw-font-hnMedium tw-text-lightgrey"
                >
                  Marketplace
                </NavLink>
              </li>
              {!roles || roles.every((el) => el.includes("DEFAULT")) ? (
                accountData.map((accountTab) => {
                  if (accountTab.customer) {
                    return (
                      <li
                        key={accountTab.id}
                        onClick={() => {
                          accountTab.action();
                        }}
                        className={` tw-mb-4 tw-truncate tw-font-semibold tw-cursor-pointer ${
                          current_page === accountTab.name
                            ? "tw-text-skiptiOrange"
                            : accountTab.disabled
                            ? "tw-pointer-events-none tw-text-verylightgrey"
                            : "tw-text-lightgrey"
                        }
                            `}
                      >
                        {accountTab.title}
                      </li>
                    );
                  }
                  return null;
                })
              ) : (
                <li className="tw-mb-4">{dashboardData.map(renderMenuItem)}</li>
              )}
            </ul>
          </div>

          <div
            className={`tw-w-full sm:tw-w-9/12 md:tw-w-10/12 tw-h-full ${
              isRedesigned
                ? "tw-px-8 md:tw-px-10 tw-pt-8 tw-bg-smoke"
                : "md:tw-px-4"
            }`}
          >
            <Alerts flash={flash} />

            {children}
          </div>
        </div>
      </div>

      <Footer articles={articlesData} />
    </>
  );
};

const mapStateToProps = (state) => ({
  articles: getArticles(state),
  isLoggedIn: getIsLoggedIn(state),
  current_user: getUser(state),
  trendingProducts: getProductsTrending(state),
});

export default connect(mapStateToProps, { fetchProductsTrending })(
  LayoutDashboard
);
