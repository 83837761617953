import axios from "axios";
import * as TYPES from "../types";
import * as helpers from "../utils";
import { getToken, getUser } from "../reducers/authReducer";
import { showSnackbar } from "./snackbar";

const requestFetchAddressesByUserId = () => ({
  type: TYPES.ADDRESSES_BYUSERID_REQUEST,
});
const successFetchAddressesByUserId = (payload) => ({
  type: TYPES.ADDRESSES_BYUSERID_SUCCESS,
  payload,
});
const failFetchAddressesByUserId = (error) => ({
  type: TYPES.ADDRESSES_BYUSERID_FAIL,
  error,
});

export const fetchAddressesByUserId =
  (data, withLoading = true) =>
  async (dispatch, getState) => {
    if (withLoading) {
      dispatch(requestFetchAddressesByUserId());
    }

    const jwt = getToken(getState());
    const { userId } = getUser(getState());
    const userIdToSend = data ? data.userId : userId;

    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/resources/locations/${userIdToSend}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      dispatch(successFetchAddressesByUserId(res.data));
      return res.data;
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failFetchAddressesByUserId(e));
    }
  };

const requestFetchAddressById = () => ({
  type: TYPES.ADDRESS_BYID_REQUEST,
});
const successFetchAddressById = (payload) => ({
  type: TYPES.ADDRESS_BYID_SUCCESS,
  payload,
});
const failFetchAddressById = (error) => ({
  type: TYPES.ADDRESS_BYID_FAIL,
  error,
});

export const fetchAddressById = (data) => async (dispatch, getState) => {
  dispatch(requestFetchAddressById());

  const jwt = getToken(getState());

  try {
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/resources/location/${data}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    dispatch(successFetchAddressById(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failFetchAddressById(e));
  }
};

const requestCreateAddress = () => ({
  type: TYPES.CREATE_ADDRESS_REQUEST,
});
const successCreateAddress = (payload) => ({
  type: TYPES.CREATE_ADDRESS_SUCCESS,
  payload,
});
const failCreateAddress = (error) => ({
  type: TYPES.CREATE_ADDRESS_FAIL,
  error,
});

export const CreateAddress = (data) => async (dispatch, getState) => {
  dispatch(requestCreateAddress());

  const jwt = getToken(getState());

  try {
    const res = await axios.post(
      `${helpers.serverUrl}/api/v1/resources/location/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch(successCreateAddress(res.data));
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e),
        JSON.stringify(data)
      )
    );
    dispatch(failCreateAddress(e));
  }
};

const deleteAddressRequest = (addressResourceId) => ({
  type: TYPES.ADDRESS_BYID_DELETE_REQUEST,
  addressResourceId,
});
const deleteAddressSuccess = (addressResourceId) => ({
  type: TYPES.ADDRESS_BYID_DELETE_SUCCESS,
  addressResourceId,
});
const deleteAddressFail = (addressResourceId, error) => ({
  type: TYPES.ADDRESS_BYID_DELETE_FAIL,
  addressResourceId,
  error,
});

export const deleteAddress =
  (addressResourceId) => async (dispatch, getState) => {
    dispatch(deleteAddressRequest(addressResourceId));
    const jwt = getToken(getState());
    try {
      await axios.delete(
        `${helpers.serverUrl}/api/v1/resources/location/${addressResourceId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(deleteAddressSuccess(addressResourceId));
    } catch (e) {
      dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e),
          JSON.stringify(addressResourceId)
        )
      );
      dispatch(deleteAddressFail(addressResourceId, e));
    }
  };
