import React from "react";
import { Elements } from "react-stripe-elements";
import poweredByStripe from "../../../complete_profile/powered_by_stripe.svg";
import StripeForm from "../../../complete_profile/StripeForm";
import SkiptiDialog from "../../../skipti/SkiptiDialog";

const AddNewCardDialog = ({
  open,
  setClose,
  handleSubmitCard,
  handleAddNewCard,
}) => {
  return (
    <SkiptiDialog
      maxWidth="sm"
      fullWidth
      open={open}
      toggle={() => setClose(false)}
      withCloseIcon
      title="Add New Card"
      className="paymentStyleCenter"
    >
      <div className="tw-w-full tw-relative">
        <div className="tw-p-4">
          <div className="tw-flex tw-justify-end tw-right-0">
            <img src={poweredByStripe} alt="powered_by_stripe" />
          </div>
          <Elements>
            <StripeForm
              buttonText="Save"
              handleSubmitCard={handleSubmitCard}
              showSnackbar={false}
              isLoading={false}
              showIsDefaultCheckbox
              dialogObtain
              setClose={setClose}
              handleAddNewCard={handleAddNewCard}
            />
          </Elements>
        </div>
      </div>
    </SkiptiDialog>
  );
};

export default AddNewCardDialog;
