import React from "react";
// import iconPlus from "../../img/ico-plus.svg";
import { imageServerUrl } from "../../utils";

export default function OwnersFAQ() {
  return (
    <div
      className="panel-group"
      id="accordion"
      role="tablist"
      aria-multiselectable="false"
    >
      <div className="panel panel-default">
        <div className="panel-heading" role="tab" id="headingOne">
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <img
                src={`${imageServerUrl}/ico-plus.svg`}
                className="more-less"
                alt=""
              />
              Owner Question 1
            </a>
          </h4>
        </div>
        <div
          id="collapseOne"
          className="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingOne"
        >
          <div className="panel-body">Answer 1</div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading" role="tab" id="headingTwo">
          <h4 className="panel-title">
            <a
              className="collapsed"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <img
                src={`${imageServerUrl}/ico-plus.svg`}
                className="more-less"
                alt=""
              />
              Question 2
            </a>
          </h4>
        </div>
        <div
          id="collapseTwo"
          className="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingTwo"
        >
          <div className="panel-body">Answer 2</div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading" role="tab" id="headingThree">
          <h4 className="panel-title">
            <a
              className="collapsed"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <img
                src={`${imageServerUrl}/ico-plus.svg`}
                className="more-less"
                alt=""
              />
              Question 3
            </a>
          </h4>
        </div>
        <div
          id="collapseThree"
          className="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingThree"
        >
          <div className="panel-body">Ansewr 3</div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-heading" role="tab" id="headingFour">
          <h4 className="panel-title">
            <a
              className="collapsed"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <img
                src={`${imageServerUrl}/ico-plus.svg`}
                className="more-less"
                alt=""
              />
              Question 4
            </a>
          </h4>
        </div>
        <div
          id="collapseFour"
          className="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingFour"
        >
          <div className="panel-body">Answer 4</div>
        </div>
      </div>
    </div>
  );
}
