// import _ from "lodash";
import React, { Component } from "react";
import SkiptiRating from "./Rating";

class ItemAverageRating extends Component {
  state = {};

  onChange = () => {};

  computeAverageRating = () => {
    const { vectors } = this.props;
    let ratingSum = 0;
    if (vectors && vectors.length > 0) {
      ratingSum = vectors.reduce((acc, vector) => {
        acc += vector.rating;
        return acc;
      }, 0);
      return ratingSum / vectors.length;
    }
  };

  render() {
    return (
      <div className="tw-text-skiptiOrange review-stats">
        <SkiptiRating rating={this.computeAverageRating()} readonly />
      </div>
    );
  }
}
export default ItemAverageRating;
