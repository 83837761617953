import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  button: {
    width: "100%",
    height: "40px",
    borderRadius: "3.2px",
    boxShadow: "none",
    fontWeight: "bold",
    backgroundColor: "#333",
    color: "#fff",

    "&:hover": {
      background: "rgba(0, 0, 0, 1)",
    },
  },
  paper: {
    width: "375px",
  },
});

const ButtonWithSpinner = ({
  isDisabled,
  clickHandler,
  buttonText,
  isProcessed,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disabled={isDisabled}
      onClick={clickHandler}
      className={classes.button}
    >
      {!isProcessed ? (
        <span>{buttonText}</span>
      ) : (
        <CircularProgress sx={{ color: "white" }} size={24} />
      )}
    </Button>
  );
};

export default ButtonWithSpinner;
