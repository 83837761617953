import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Parallax } from "react-parallax";
import LayoutCategory from "../layouts/LayoutCategory";
import { fetchProductsTrending } from "../../actions";
// import imageTest from "../../img/parallax-img1.jpg";
import { getProductsTrending } from "../../reducers/itemsReducer";
import ItemTile from "../partials/ItemTile";
import Button from "../skipti/Button";
import { imageServerUrl } from "../../utils";

class TrendingList extends Component {
  state = {
    products: [],
    pageSize: 12,
    pageNumber: 0,
  };

  static getDerivedStateFromProps = (props, state) => {
    const newProducts =
      props.items && props.items.isLoaded ? props.items.data : [];
    return {
      products: [...state.products, ...newProducts],
    };
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { pageSize, pageNumber } = this.state;
    this.props.fetchProductsTrending({
      pageSize,
      pageNumber: pageNumber + 1,
    });
    this.setState({
      pageNumber: pageNumber + 1,
    });
  };

  render() {
    const image = undefined;
    const { products } = this.state;
    const { items } = this.props;
    return (
      <LayoutCategory showSearch>
        <Parallax
          blur={0}
          bgImage={image || `${imageServerUrl}/parallax-img1.jpg`}
          bgImageAlt="category.displayName"
          bgStyle={{ top: "-50%" }}
          strength={850}
          className="parallax-window p-5"
        >
          {/* {this.props.title} */}
          <div className="" style={{ height: "200px" }}>
            <div
              className="container"
              style={{
                position: "absolute",
                bottom: "15px",
                left: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <h1 className="display-1 text-uppercase text-white my-0">
                Trending
              </h1>
            </div>
          </div>
        </Parallax>
        <div className="container-fluid my-5 px-5">
          <div className="row">
            {products.map((item) => (
              <ItemTile {...item} noOfProducts="6" key={item.resourceId} />
            ))}
          </div>
          {items.isLoaded && (
            <div className="row justify-content-center m-5">
              <Button primary onClick={this.fetchData}>
                Load more
              </Button>
            </div>
          )}
        </div>
      </LayoutCategory>
    );
  }
}

const mapStateToProps = (state) => ({
  items: getProductsTrending(state),
});

export default connect(mapStateToProps, { fetchProductsTrending })(
  TrendingList
);
