import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { TextField, Grid } from "@material-ui/core";
import SkiptiButton from "../skipti/Button";
import useZip from "../../hooks/useZip";
import { isValidZipCode } from "../../actions/utils";
import { debounce } from "lodash";

function ChangeZip(props) {
  const {
    className,
    style,
    withSubmitButton,
    inputProps,
    fullWidth,
    InputLabelProps,
    outlined,
    margin,
  } = props;

  const { updateZipCode, zip } = useZip();

  const formik = useFormik({
    initialValues: {
      zipCode: zip && zip !== "00000" ? zip : "",
    },
    enableReinitialize: true,
    validate: ({ zipCode }) => {
      const errors = {};
      if (zipCode.length > 5) {
        errors.zipCode = "Zipcode length should be 5 digits";
      } else if (!isValidZipCode(zipCode) || zipCode === "00000") {
        errors.zipCode = "You added an invalid Zip Code!";
      }

      return errors;
    },
    onSubmit: (values) => {
      updateZipCode(values.zipCode);
    },
  });

  useEffect(() => {
    if (zip && zip !== "00000") {
      formik.setFieldValue("zipCode", zip);
    } else {
      formik.setFieldValue("zipCode", "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={withSubmitButton ? 12 : 12}>
          <TextField
            fullWidth={fullWidth || !!withSubmitButton}
            id="zipCode"
            name="zipCode"
            label="Zip Code"
            FormHelperTextProps={{ error: true }}
            variant={outlined ? "outlined" : "standard"}
            margin={margin || "normal"}
            autoComplete="off"
            style={style}
            className={className}
            InputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
            }}
            value={formik.values.zipCode}
            onChange={(event) => {
              if (event.target.value.length === 5) {
                updateZipCode(event.target.value);
              }
              formik.handleChange(event);
            }}
            error={!!formik.touched.zipCode && !!formik.errors.zipCode}
            helperText={
              !!formik.touched.zipCode &&
              !!formik.errors.zipCode &&
              formik.errors.zipCode
            }
          />
        </Grid>
        {withSubmitButton && (
          <Grid item xs={withSubmitButton ? 12 : 4}>
            <div className="tw-flex tw-justify-center">
              <SkiptiButton
                primary
                type="submit"
                // disabled={isSubmitting || this.props.isLoading || isError}
              >
                Submit
              </SkiptiButton>
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default ChangeZip;
