import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, TextField } from "@material-ui/core";
import { Formik, Form } from "formik";
import SkiptiDialog from "../../../skipti/SkiptiDialog";
import SkiptiButton from "../../../skipti/Button";
import {
  changeDecommissionStatus,
  changeDecommissionStatusOfAddOn,
} from "../../depotsSlice";

export const addonOrInventActReasons = {
  inActivationOptions: [
    { label: "Too dirty", value: 2 },
    { label: "Broken", value: 3 },
    { label: "Missing parts", value: 4 },
    { label: "Repair/maintenance", value: 5 },
    { label: "Missing/stolen", value: 6 },
    { label: "Moved Out", value: 7 },
    { label: "Maximum Rentals", value: 8 },
    { label: "Not Entered", value: 30 },
    { label: "Removed by Client", value: 31 },
    { label: "Other", value: 50 },
  ],
  activationOptions: [
    { label: "No longer dirty", value: 12 },
    { label: "No longer broken", value: 13 },
    { label: "Missing parts replaced", value: 14 },
    { label: "Returned after repair/service", value: 15 },
    { label: "No longer missing", value: 16 },
    { label: "Moved In", value: 17 },
    { label: "Other", value: 50 },
  ],
};

const ConfirmDialog = ({
  setState,
  modal: { type = "", data = {}, open = false },
  getDepotById,
}) => {
  const dispatch = useDispatch();

  const condition =
    type === "Inactivate add-on unit" || type === "Activate add-on unit";

  const onUnMount = useCallback(() => {
    setState((_prev) => ({ ..._prev, data: {}, type: "" }));
  }, [setState]);

  const handleClose = useCallback(
    () => setState((_prev) => ({ ..._prev, open: false })),
    [setState]
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (condition) {
        await dispatch(
          changeDecommissionStatusOfAddOn({
            item: data,
            checked: !data.isActive,
            values,
          })
        );
      } else {
        await dispatch(
          changeDecommissionStatus({
            item: data,
            checked: !data.inService,
            values,
          })
        );
      }

      getDepotById();

      handleClose();
    },
    [condition, data, dispatch, handleClose]
  );

  return (
    <SkiptiDialog
      fullWidth
      scroll="body"
      maxWidth="xs"
      open={open}
      toggle={handleClose}
      TransitionProps={{ onExited: onUnMount }}
    >
      <Formik
        initialValues={{
          reason: null,
          reasonText: "",
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, handleChange }) => (
          <Form className="tw-text-center tw-text-xl">
            Are you sure?
            <br />
            {`${type} ${condition ? data.serialNumber : data.sku}`}
            <br />
            <br />
            <TextField
              name="reason"
              variant="outlined"
              size="small"
              className="tw-w-10/12 tw-text-left"
              label="Option"
              value={values.reason}
              onChange={handleChange}
              select
            >
              {addonOrInventActReasons[
                data.isActive || data.inService
                  ? "inActivationOptions"
                  : "activationOptions"
              ].map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {values.reason === 50 && (
              <>
                <br />
                <br />
                <TextField
                  variant="outlined"
                  size="small"
                  className="tw-w-10/12 tw-text-left"
                  label="Reason"
                  name="reasonText"
                  value={values.reasonText}
                  onChange={handleChange}
                />
              </>
            )}
            <div className="tw-flex tw-justify-between tw-flex-wrap tw-pt-12">
              <div className="tw-mr-3">
                <SkiptiButton design="secondary dialog" onClick={handleClose}>
                  No
                </SkiptiButton>
              </div>
              <div>
                <SkiptiButton
                  disabled={
                    (values.reason === 50 && !values.reasonText) ||
                    values.reason === null
                  }
                  type="submit"
                  design="primary dialog"
                >
                  Yes
                </SkiptiButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </SkiptiDialog>
  );
};

export default ConfirmDialog;
