import React from "react";

export default function TopBar() {
  return (
    <div
      id="top-bar"
      style={{
        backgroundColor: "#F05623",
        //   backgroundColor: "#332E2E",
        position: "fixed",
        top: "0px",
        width: "100%",
        padding: "3px 0",
        color: "white",
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      <span
        style={{
          margin: "2px 0",
        }}
      >
        <i
          className="fas fa-truck"
          style={{ color: "white", marginRight: "6px" }}
        />
        {/* Free Delivery & Pickup On All Local Skipti Rentals */}
        New Experiences Delivered
      </span>
    </div>
  );
}
