import axios from "axios";
import * as TYPES from "../types";
import * as helpers from "../utils";
import {
  getToken,
  getPendingCustomerReviews,
  getPendingMerchantReviews,
  getPendingDriverReviews,
} from "../reducers/authReducer";
import { showSnackbar } from "./snackbar";
import { getIsCurrentLocationServiceable } from "../reducers/userReducer";

const requestGetZipFromLatLong = () => ({
  type: TYPES.USER_GET_ZIP_FROM_LATLONG_REQUEST,
});

const successGetZipFromLatLong = (payload) => ({
  type: TYPES.USER_GET_ZIP_FROM_LATLONG_SUCCESS,
  payload,
});

const failGetZipFromLatLong = (payload, error) => ({
  type: TYPES.USER_GET_ZIP_FROM_LATLONG_FAIL,
  payload,
  error,
});

export const fetchZipFromLatLong = () => async (dispatch, getState) => {
  // only try to get zip from lat/long if we didn't check the current location yet
  if (getIsCurrentLocationServiceable(getState) === "") {
    dispatch(requestGetZipFromLatLong());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const res = await axios.get(
            `${helpers.serverUrl}/api/v1/verify/serviceable/gps?lat=${position.coords.latitude}&lon=${position.coords.longitude}`
          );
          const { serviced, zipPostal, city } = res.data;
          if (!serviced) {
            dispatch(failGetZipFromLatLong({ serviced }, null));
            return dispatch(
              showSnackbar(
                "Your current location is outside the serviceable area.",
                "warning"
              )
            );
          }
          dispatch({
            type: "SET_ZIP",
            payload: zipPostal,
          });
          return dispatch(
            successGetZipFromLatLong({
              serviced,
              zipPostal,
              city,
            })
          );
        },
        (error) => {
          dispatch(
            failGetZipFromLatLong({ serviced: false }, "Failed to get location")
          );
          // dispatch(
          //   showSnackbar("Access to location was not granted", "warning")
          // );
        }
      );
    } else {
      dispatch(
        failGetZipFromLatLong(
          { serviced: false },
          "Access to location was not granted"
        )
      );
      dispatch(showSnackbar("Automatic location detection failed"));
    }
  }
};

export const updateSmsOptIn = (values) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await axios.post(`${helpers.serverUrl}/api/v1/user/addendum`, values, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  } catch (e) {
    dispatch(
      showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      )
    );
  }
};

const requestFetchUserInfoById = () => ({
  type: TYPES.USERS_INFO_BYOWNERID_REQUEST,
});
const successFetchUserInfoById = (payload) => ({
  type: TYPES.USERS_INFO_BYOWNERID_SUCCESS,
  payload,
});
const failFetchUserInfoById = (error) => ({
  type: TYPES.USERS_INFO_BYOWNERID_FAIL,
  error,
});
export const fetchZipIsServiceableForProduct =
  (data) => async (dispatch, getState) => {
    const jwt = getToken(getState());
    try {
      let res = null;
      if (!data) {
        res = await axios.get(
          `${helpers.serverUrl}/api/v1/verify/serviceable-product/${data.zipcode}?productId=${data.productId}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
      } else {
        // res = await axios.get(
        //   `${helpers.serverUrl}/api/v1/user/profile/${data}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${jwt}`,
        //     },
        //   }
        // );
      }

      dispatch(successFetchUserInfoById(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failFetchUserInfoById(e));
    }
  };

// fetch User Reviews By Id
const requestUserReviewsAsRenter = () => ({
  type: TYPES.USER_REVIEWS_ASRENTER_REQUEST,
});
const successUserReviewsAsRenter = (payload) => ({
  type: TYPES.USER_REVIEWS_ASRENTER_SUCCESS,
  payload,
});
const failUserReviewsAsRenter = (error) => ({
  type: TYPES.USER_REVIEWS_ASRENTER_FAIL,
  error,
});

export const fetchUserInfoById =
  (data, withLoading = true) =>
  async (dispatch, getState) => {
    if (withLoading) {
      dispatch(requestFetchUserInfoById());
    }

    const jwt = getToken(getState());
    try {
      let res = null;
      if (!data) {
        res = await axios.get(`${helpers.serverUrl}/api/v1/user/userprofile/`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      } else {
        res = await axios.get(
          `${helpers.serverUrl}/api/v1/user/profile/${data}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
      }

      dispatch(successFetchUserInfoById(res.data));
      if (!withLoading) {
        return res.data;
      }
      return res.data.smsOptIn;
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failFetchUserInfoById(e));
    }
  };

export const requestCustomerReviewsAsAuthor = () => ({
  type: TYPES.CUSTOMER_REVIEW_ASAUTHOR_REQUEST,
});
export const successCustomerReviewsAsAuthor = (payload) => ({
  type: TYPES.CUSTOMER_REVIEW_ASAUTHOR_SUCCESS,
  payload,
});
export const failCustomerReviewsAsAuthor = (error) => ({
  type: TYPES.CUSTOMER_REVIEW_ASAUTHOR_FAILURE,
  error,
});
export const fetchCustomerReviewsAsAuthor =
  ({ pageNumber = 1, pageSize = 5 }) =>
  async (dispatch, getState) => {
    dispatch(requestCustomerReviewsAsAuthor());
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/review/rater/customer?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(successCustomerReviewsAsAuthor(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message, "error"));
      dispatch(failCustomerReviewsAsAuthor(e));
    }
  };

export const requestMerchantReviewsAsAuthor = () => ({
  type: TYPES.MERCHANT_REVIEW_ASAUTHOR_REQUEST,
});
export const successMerchantReviewsAsAuthor = (payload) => ({
  type: TYPES.MERCHANT_REVIEW_ASAUTHOR_SUCCESS,
  payload,
});
export const failMerchantReviewsAsAuthor = (error) => ({
  type: TYPES.MERCHANT_REVIEW_ASAUTHOR_FAILURE,
  error,
});
export const fetchMerchantReviewsAsAuthor =
  ({ pageNumber = 1, pageSize = 5 }) =>
  async (dispatch, getState) => {
    dispatch(requestMerchantReviewsAsAuthor());
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/review/rater/merchant?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(successMerchantReviewsAsAuthor(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message, "error"));
      dispatch(failMerchantReviewsAsAuthor(e));
    }
  };

export const requestDriverReviewsAsAuthor = () => ({
  type: TYPES.DRIVER_REVIEW_ASAUTHOR_REQUEST,
});
export const successDriverReviewsAsAuthor = (payload) => ({
  type: TYPES.DRIVER_REVIEW_ASAUTHOR_SUCCESS,
  payload,
});
export const failDriverReviewsAsAuthor = (error) => ({
  type: TYPES.DRIVER_REVIEW_ASAUTHOR_FAILURE,
  error,
});
export const fetchDriverReviewsAsAuthor =
  ({ pageNumber = 1, pageSize = 5 }) =>
  async (dispatch, getState) => {
    dispatch(requestDriverReviewsAsAuthor());
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/review/rater/driver?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(successDriverReviewsAsAuthor(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message, "error"));
      dispatch(failDriverReviewsAsAuthor(e));
    }
  };

export const fetchUserReviewsAsRenter =
  ({ pageNumber = 1, pageSize = 5 }) =>
  async (dispatch, getState) => {
    dispatch(requestUserReviewsAsRenter());
    // andrei user id 3ca67992-d4b5-4f98-9ad9-aacc0c92decd
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/review/mine/customer?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      dispatch(successUserReviewsAsRenter(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message, "error"));
      dispatch(failUserReviewsAsRenter(e));
    }
  };
export const requestUserReviewsAsOwner = () => ({
  type: TYPES.USER_REVIEWS_ASOWNER_REQUEST,
});
export const successUserReviewsAsOwner = (payload) => ({
  type: TYPES.USER_REVIEWS_ASOWNER_SUCCESS,
  payload,
});
export const failUserReviewsAsOwner = (error) => ({
  type: TYPES.USER_REVIEWS_ASOWNER_FAIL,
  error,
});

export const fetchUserReviewsAsOwner =
  ({ pageNumber = 1, pageSize = 5 }) =>
  async (dispatch, getState) => {
    dispatch(requestUserReviewsAsOwner());
    // andrei user id 3ca67992-d4b5-4f98-9ad9-aacc0c92decd
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/review/mine/merchant?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      // const res = await axios.get(
      //   `${helpers.serverUrl}/api/v1/resources/products/${
      //     data.userid
      //   }?pagesize=${data.pagesize || 10}&pagenumber=${data.pagenumber || 1}`
      // );
      dispatch(successUserReviewsAsOwner(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message, "error"));
      dispatch(failUserReviewsAsOwner(e));
    }
  };

// fetch Users By Organization Id
const requestFetchUsersByOrganizationId = () => ({
  type: TYPES.USERS_BYORGANIZATIONID_REQUEST,
});
const successFetchUsersByOrganizationId = (payload) => ({
  type: TYPES.USERS_BYORGANIZATIONID_SUCCESS,
  payload,
});
const failFetchUsersByOrganizationId = (error) => ({
  type: TYPES.USERS_BYORGANIZATIONID_FAIL,
  error,
});
export const fetchUsersByOrganizationId =
  (data) => async (dispatch, getState) => {
    dispatch(requestFetchUsersByOrganizationId());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/user/org/${data}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      // const res = await axios.get(
      //   `${helpers.serverUrl}/api/v1/resources/products/${
      //     data.userid
      //   }?pagesize=${data.pagesize || 10}&pagenumber=${data.pagenumber || 1}`
      // );
      dispatch(successFetchUsersByOrganizationId(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failFetchUsersByOrganizationId(e));
    }
  };

export const skipStripe = () => async (dispatch) => {
  dispatch({
    type: "SKIP_STRIPE",
  });
};

export const skipFlags = (skippedFlags, returnUrl) => async (dispatch) => {
  dispatch({
    type: TYPES.SKIPPED_FLAGS_SET,
    payload: { skippedFlags, returnUrl },
  });
};

export const openCustomerReviewModal =
  (review) => async (dispatch, getState) => {
    let payload;
    if (!review) {
      [payload] = getPendingCustomerReviews(getState());
    } else {
      payload = review;
    }
    dispatch({
      type: TYPES.CUSTOMER_REVIEW_MODAL_OPEN,
      payload,
    });
  };
export const closeCustomerReviewModal = (review) => async (dispatch) => {
  dispatch({
    type: TYPES.CUSTOMER_REVIEW_MODAL_CLOSE,
    review,
  });
};

export const openMerchantReviewModal =
  (review) => async (dispatch, getState) => {
    let payload;
    if (!review) {
      [payload] = getPendingMerchantReviews(getState());
    } else {
      payload = review;
    }
    dispatch({
      type: TYPES.MERCHANT_REVIEW_MODAL_OPEN,
      payload,
    });
  };
export const closeMerchantReviewModal = (review) => async (dispatch) => {
  dispatch({
    type: TYPES.MERCHANT_REVIEW_MODAL_CLOSE,
    review,
  });
};

export const setSocialId = (data) => (dispatch) => {
  dispatch({
    type: TYPES.SET_SOCIALID,
    payload: data,
  });
};

export const setIsFromRugDoctor = (data) => (dispatch) => {
  dispatch({
    type: TYPES.SET_IS_FROM_RUGDOCTOR,
    payload: data,
  });
};

export const openDriverReviewModal = (review) => async (dispatch, getState) => {
  let payload;
  if (!review) {
    [payload] = getPendingDriverReviews(getState());
  } else {
    payload = review;
  }
  dispatch({
    type: TYPES.CUSTOMER_REVIEW_MODAL_OPEN,
    payload,
  });
};
export const closeDriverReviewModal = (review) => async (dispatch) => {
  dispatch({
    type: TYPES.CUSTOMER_REVIEW_MODAL_CLOSE,
    review,
  });
};

// fetchProfiledProductsByCategoryId

const requestProfiledProductsByCategoryId = () => ({
  type: TYPES.PROFILED_PRODUCTS_BY_CATEGORYID_REQUEST,
});
const successProfiledProductsByCategoryId = (payload) => ({
  type: TYPES.PROFILED_PRODUCTS_BY_CATEGORYID_SUCCESS,
  payload,
});
const failProfiledProductsByCategoryId = (error) => ({
  type: TYPES.PROFILED_PRODUCTS_BY_CATEGORYID_FAIL,
  error,
});
// eslint-disable-next-line
export const fetchProfiledProductsByCategoryId =
  (data) => async (dispatch, getState) => {
    dispatch(requestProfiledProductsByCategoryId());

    if (!data) {
      data = {};
    }
    const jwt = getToken(getState());
    try {
      // no need to pass the userID as it will get the current user from the JWT token
      // /api/v1/profiled/products/{categoryId}
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/profiled/products/${data.categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          // params: {
          //   maxResults: data.pageSize || 6,
          // },
        }
      );

      dispatch(successProfiledProductsByCategoryId(res.data));
    } catch (e) {
      dispatch(showSnackbar(e.message));
      dispatch(failProfiledProductsByCategoryId(e));
    }
  };
