import React from "react";

export default function SkiptiProgressBar({
  className,
  currentProgress,
  style,
}) {
  return (
    <div className="progress thin d-none d-lg-block" style={{ height: "7px" }}>
      <div
        className={className || "progress-bar bg-color1"}
        role="progressbar"
        aria-valuenow={currentProgress || 0}
        style={
          style || {
            width: `${currentProgress || 0}%`,
            height: "7px",
          }
        }
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
}
