import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import * as yup from "yup";
import {
  FormHelperText,
  Input,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import _ from "lodash";
import axios from "axios";
import MixpanelContext from "react-mixpanel";
import {
  fetchAddressById,
  showSnackbar,
  regionChange,
  fetchRegions,
  setZipCode,
} from "../../actions";
import { getUser, getToken } from "../../reducers/authReducer";
import SkiptiButton from "../skipti/Button";
import SkiptiSpinner from "../skipti/Spinner";
import { getAddressById } from "../../reducers/addressesReducer";
import MaskedTextInput from "../skipti/MaskedTextInput";
import { getProductDeliveryModals, getZip } from "../../reducers/itemsReducer";
import { getRegion, getRegions } from "../../reducers/userReducer";
import * as helpers from "../../utils";
import { getItemRentInfo } from "../../reducers/itemRentReducer";
import { SmsToggle } from "../sms_toggle";
import { ScrollToErrorField } from "../utils/forms/ScrollToErrorField";
import { ITEM_DELIVERY_MODEL } from "../../actions/utils";

const addressNames = [
  {
    key: 0,
    text: "Home",
    value: 0,
  },
  {
    key: 1,
    text: "Office",
    value: 1,
  },
  {
    key: 2,
    text: "Other",
    value: 2,
  },
];

class CreateAddressComponent extends Component {
  static propTypes = {
    fetchAddressById: PropTypes.func.isRequired,
    addressId: PropTypes.string,
    address: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
      .isRequired,
    handleSubmit: PropTypes.func.isRequired,
    noHeader: PropTypes.bool,
  };

  static defaultProps = {
    noHeader: false,
  };

  static contextType = MixpanelContext;

  constructor(props) {
    super(props);
    this.delayedCallback = _.debounce(this.getZipInfo, 1000);
    this.delayedBillingCallback = _.debounce(this.getBillingState, 1000);
  }

  state = {
    stateProvince: "",
    isEdit: false,
    isSmsOptIn: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.addressOverwrite) {
      return { ...state, isEdit: true };
    }
    return null;
  }

  async componentDidMount() {
    if (this.props.addressId) {
      this.props.fetchAddressById(this.props.addressId);
      this.setState({
        isEdit: true,
      });
    }

    if (this.props.regions.length === 0) await this.props.fetchRegions();

    if (this.props.isInRentWiz) {
      this.getZipInfo({ zip: this.props.zip });
    }
  }

  handleZipChange(e, setFieldValue) {
    if (e.target.value !== "00000") {
      this.delayedCallback({ zip: e.target.value, setFieldValue });
    }
  }

  async getBillingState(zipCode) {
    try {
      if (this.props.setLoadingBillingZip) {
        this.props.setLoadingBillingZip(true);
      }

      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/resourcerequest/zip-region/${zipCode}`
      );
      if (res.status === 200) {
        this.props.formRef.current.setFieldValue(
          "stateProvince",
          res.data.state
        );
        if (res.data.isServiced && res.data.isActive) {
          this.props.showSnackbar(
            `You have added a valid zip code for the ${res.data.name} region`,
            "success"
          );
        } else {
          this.props.showSnackbar(
            `You have added a valid zip code for the ${res.data.name} region`,
            "success"
          );
        }
      }
    } catch (e) {
      this.props.showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      );
    }
    if (this.props.setLoadingBillingZip) {
      this.props.setLoadingBillingZip(false);
    }
  }

  getStateProvince(zip) {
    if (zip !== "00000" && zip.length === 5) {
      this.delayedBillingCallback(zip);
    }
  }

  async getZipInfo({ zip, setFieldValue }) {
    try {
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/resourcerequest/zip-region/${
          zip || this.props.itemRentInfo?.zip
        }`
      );

      if (res.status === 200) {
        if (setFieldValue) {
          setFieldValue("stateProvince", res.data.state);
          if (res.data.isServiced) {
            this.props.showSnackbar(
              `You have added a valid zip code for the ${res.data.name} region`,
              "success"
            );
          } else {
            this.props.showSnackbar(
              `The zip code you added is not serviced`,
              "error"
            );
          }
        } else {
          this.setState({
            stateProvince: res.data.state,
          });

          const region = this.props.regions.find((x) => x.id === res.data.id);

          if (region) {
            this.props.regionChange(region);
          }
        }
      }
    } catch (e) {
      this.props.showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      );
    }
  }

  onStartOver = () => {
    this.context.track("Clicked Start Over Rent", {
      step: "Create Address",
    });
    window.location.reload();
  };

  toggleChangeHandler = () => {
    if (this.state.isSmsOptIn) {
      this.context.track("Address Step - SMS Opt In", {});
    } else {
      this.context.track("Address Step - SMS Opt Out", {});
    }
    this.setState((prev) => ({
      ...prev,
      isSmsOptIn: !prev.isSmsOptIn,
    }));
  };

  zipCodeHandleChange = (
    event,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    touched,
    formName
  ) => {
    const { value } = event.target;
    if (/^\d{0,5}$/.test(value)) {
      const maxFiveNumbers = new RegExp(/^[0-9]{5}$/);
      const trimmedValue = value.replace(/\s+/g, "");
      if (!value) {
        setFieldValue("zipPostal", trimmedValue);
        setFieldError("zipPostal", "Required", true);
        setFieldTouched("zipPostal", true);
      }

      if (value === "00000") {
        setFieldError("zipPostal", "Please fill zip code");
      } else if (maxFiveNumbers.test(value)) {
        if (formName === "billing") {
          this.getStateProvince(value);
        } else if (formName === "delivery") {
          this.handleZipChange(event, setFieldValue);
        }
        setFieldError("zipPostal", undefined);
      } else {
        const error =
          value === "00000"
            ? "Please fill zip code"
            : "Zip code must contain exactly 5 digits";
        setFieldError("zipPostal", error);
      }

      setFieldValue("zipPostal", trimmedValue);

      if (!touched.zipPostal) setFieldTouched("zipPostal", true);
    }
  };

  checkValidations = (errors, touched) => {
    const errorsArr = Object.keys(errors);
    return errorsArr.some((errorName) => touched[errorName]);
  };

  formChangeHandler = (isValid) => {
    if (this.props.setLoadingBillingZip) {
      this.props.setLoadingBillingZip(!isValid);
    }
  };

  render() {
    const {
      noHeader,
      isInRentWiz,
      zip,
      region,
      skipAddress,
      isBilling,
      productDeliveryModals,
      isFastRegister = false,
      email = "",
    } = this.props;
    const { isEdit } = this.state;
    const address =
      (this.props.addressId || this.props.addressOverwrite) &&
      this.props.address &&
      this.props.address.isLoaded &&
      this.props.address.data
        ? this.props.address.data.address
        : {
            addressType: 1,
            name: this.props.addressName || "",
            street1: "",
            street2: "",
            city: "",
            stateProvince: "",
            zipPostal: "",
            country: "USA",
            latitude: 44.45347,
            longitude: 26.0759051,
            altitude: 0.0,
            deliveryNotes: "",
          };
    const { smsOptIn, defaultPhone, firstName, lastName } =
      this.props.current_user;

    if (isBilling) {
      return (
        <Formik
          validateOnChange
          validateOnBlur
          enableReinitialize
          initialValues={{
            addressType: address.addressType,
            name: address.name || "Home", //
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            stateProvince: this.state.newStateProvince || "",
            zipPostal: "",
            country: address.country ?? "USA",
            locationType:
              typeof address.locationType === "undefined"
                ? 0
                : address.locationType,
            deliveryNotes: address.deliveryNotes || "",
            isBilling,
          }}
          validationSchema={yup.object().shape({
            name: yup.string().required("Required"),
            street1: yup.string().required("Required"),
            city: yup.string().required("Required"),
            stateProvince: yup
              .string()
              .typeError("Please select a State")
              .required("Required"),
            zipPostal: yup
              .string()
              .required("Required")
              .matches(/^[0-9]{5}$/, "Zip code must have 5 digits")
              .test(
                "00000",
                "Not a valid Zip Code",
                (value) => value !== "00000"
              ),
            country: yup.string().required("Required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (this.props.handleSubmit) {
              this.props.handleSubmit(values, { setSubmitting });
            }
          }}
          innerRef={this.props.formRef}
        >
          {(props) => {
            const {
              isValid,
              handleChange,
              values,
              touched,
              errors,
              isSubmitting,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            } = props;
            return (
              <Form onChange={() => this.formChangeHandler(isValid)}>
                <ScrollToErrorField />
                <SkiptiSpinner
                  loading={isSubmitting || this.props.address.isFetching}
                  opacity="0"
                  absolute
                />
                <>
                  <div className="tw-flex tw-flex-wrap">
                    <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                      <div className="form-group tw-mb-12">
                        <TextField
                          label="Street Address"
                          name="street1"
                          autoComplete="address-line1"
                          fullWidth
                          multiline
                          onChange={handleChange}
                          value={values.street1 || ""}
                          error={!!errors.street1}
                          helperText={errors.street1}
                        />
                      </div>
                    </div>
                    <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                      <div className="form-group tw-mb-12">
                        <TextField
                          label="Apartment, Suite, etc."
                          name="street2"
                          autoComplete="address-line2"
                          fullWidth
                          onChange={handleChange}
                          value={values.street2 || ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap">
                    <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                      <div className="form-group tw-mb-12">
                        <TextField
                          label="City"
                          name="city"
                          fullWidth
                          onChange={handleChange}
                          value={values.city || ""}
                          error={!!errors.city}
                          helperText={errors.city}
                        />
                      </div>
                    </div>
                    <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                      <div className="form-group tw-mb-12">
                        <TextField
                          select
                          fullWidth
                          name="stateProvince"
                          label="State / Province"
                          onChange={handleChange}
                          value={values.stateProvince || ""}
                          error={
                            !!touched.stateProvince && !!errors.stateProvince
                          }
                          helperText={errors.stateProvince}
                        >
                          {helpers.statesList.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap">
                    <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                      <div className="form-group tw-mb-12">
                        <TextField
                          label="Zip / Postal Code"
                          name="zipPostal"
                          autoComplete="postal-code"
                          fullWidth
                          onChange={(e) => {
                            this.zipCodeHandleChange(
                              e,
                              setFieldValue,
                              setFieldError,
                              setFieldTouched,
                              touched,
                              "billing"
                            );
                          }}
                          value={values.zipPostal}
                          error={!!errors.zipPostal}
                          helperText={!!errors.zipPostal && errors.zipPostal}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </Form>
            );
          }}
        </Formik>
      );
    }
    return (
      <>
        {!noHeader && (
          <>
            <h4 className="tw-text-2xl tw-font-bold tw-font-lato">
              {this.props.addressId ? "Edit Address" : "Create Address"}
            </h4>
            <hr />
          </>
        )}
        <Formik
          enableReinitialize
          initialValues={
            isInRentWiz
              ? !skipAddress
                ? !isFastRegister
                  ? {
                      firstName: firstName || "",
                      lastName: lastName || "",
                      phone: defaultPhone || "",

                      smsOptIn,
                      addressType: address.addressType,
                      name: address.name || "Home", //
                      street1: address.street1,
                      street2: address.street2,
                      city: address.city,
                      stateProvince:
                        address.stateProvince ||
                        this.state.stateProvince ||
                        (region && region.state) ||
                        "",
                      zipPostal:
                        address.zipPostal ||
                        zip ||
                        this.props.itemRentInfo.zip ||
                        "",
                      country: address.country || "USA",
                      locationType:
                        typeof address.locationType === "undefined"
                          ? 0
                          : address.locationType,
                      deliveryNotes:
                        address.deliveryNotes ||
                        "By default we will text your mobile phone upon arrival",
                    }
                  : {
                      // fast register
                      firstName: firstName || "",
                      lastName: lastName || "",
                      phone: defaultPhone || "",
                      email: email || "",
                      smsOptIn,
                      addressType: address.addressType,
                      name: address.name || "Home", //
                      street1: address.street1,
                      street2: address.street2,
                      city: address.city,
                      stateProvince:
                        address.stateProvince ||
                        this.state.stateProvince ||
                        (region && region.state) ||
                        "",
                      zipPostal:
                        address.zipPostal ||
                        zip ||
                        this.props.itemRentInfo.zip ||
                        "",
                      country: address.country || "USA",
                      locationType:
                        typeof address.locationType === "undefined"
                          ? 0
                          : address.locationType,
                      deliveryNotes:
                        address.deliveryNotes ||
                        "By default we will text your mobile phone upon arrival",
                    }
                : {
                    firstName: "",
                    lastName: "",
                    phone: defaultPhone || "",
                    smsOptIn,
                  }
              : {
                  phone: "(   )    -    ",
                  addressType: address.addressType,
                  name: address.name || "Home", //
                  street1: address.street1,
                  street2: address.street2,
                  city: address.city,
                  stateProvince:
                    address.stateProvince || (region && region.state) || "",
                  zipPostal: address.zipPostal || zip || "",
                  country: address.country || "USA",
                  locationType:
                    typeof address.locationType === "undefined"
                      ? 0
                      : address.locationType,
                  deliveryNotes:
                    address.deliveryNotes ||
                    "By default we will text your mobile phone upon arrival",
                }
          }
          validationSchema={
            isInRentWiz
              ? !skipAddress
                ? !isFastRegister
                  ? yup.object().shape({
                      firstName: yup.string().required("Required"),
                      lastName: yup.string().required("Required"),
                      phone: yup
                        .string()
                        .required("Required")
                        .test("phone", "Required", (value) => {
                          const strippedValue =
                            value && value.replace(/[^0-9]/g, "");
                          if (strippedValue && strippedValue.length === 10) {
                            return true;
                          }
                          return false;
                        }),
                      addressType: yup.number().required("Required"),
                      name: yup.string().required("Required"),
                      street1: yup.string().required("Required"),
                      city: yup.string().required("Required"),
                      stateProvince: yup
                        .string()
                        .typeError("Please select a State")

                        .required("Required"),
                      zipPostal: yup
                        .string()
                        .required("Required")
                        .matches(/^[0-9]{5}$/, "Zip code must have 5 digits")
                        .test(
                          "00000",
                          "Not a valid Zip Code",
                          (value) => value !== "00000"
                        ),
                      country: yup.string().required("Required"),
                      locationType: yup.number().required("Required"),
                    })
                  : yup.object().shape({
                      firstName: yup.string().required("Required"),
                      lastName: yup.string().required("Required"),
                      email: yup
                        .string()
                        .matches(
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          "Invalid email address"
                        ),
                      phone: yup
                        .string()
                        // .required("Required")
                        .test("phone", "Required", (value) => {
                          const strippedValue =
                            value && value.replace(/[^0-9]/g, "");
                          if (strippedValue && strippedValue.length === 10) {
                            return true;
                          }
                          return false;
                        }),
                      addressType: yup.number().required("Required"),
                      name: yup.string().required("Required"),
                      street1: yup.string().required("Required"),
                      city: yup.string().required("Required"),
                      stateProvince: yup
                        .string()
                        .typeError("Please select a State")

                        .required("Required"),
                      zipPostal: yup
                        .string()
                        .required("Required")
                        .matches(/^[0-9]{5}$/, "Zip code must have 5 digits")
                        .test(
                          "00000",
                          "Not a valid Zip Code",
                          (value) => value !== "00000"
                        ),
                      country: yup.string().required("Required"),
                      locationType: yup.number().required("Required"),
                    })
                : yup.object().shape({
                    firstName: yup.string().required("Required"),
                    lastName: yup.string().required("Required"),
                    phone: yup
                      .string()
                      .required("Required")
                      .test("phone", "Required", (value) => {
                        const strippedValue = value.replace(/[^0-9]/g, "");
                        if (strippedValue.length === 10) {
                          return true;
                        }
                        return false;
                      }),
                  })
              : yup.object().shape({
                  addressType: yup.number().required("Required"),
                  name: yup.string().required("Required"),
                  street1: yup.string().required("Required"),
                  city: yup.string().required("Required"),
                  stateProvince: yup
                    .string()
                    .typeError("Please select a State")
                    .required("Required"),
                  zipPostal: yup
                    .string()
                    .required("Required")
                    .matches(/^[0-9]{5}$/, "Zip code must have 5 digits")
                    .test(
                      "00000",
                      "Not a valid Zip Code",
                      (value) => value !== "00000"
                    ),
                  country: yup.string().required("Required"),
                  locationType: yup.number().required("Required"),
                })
          }
          validateOnChange
          validateOnBlur
          onSubmit={(values, { setSubmitting }) => {
            this.props.handleSubmit(values, { setSubmitting });
          }}
          innerRef={this.props.formRef}
        >
          {(props) => {
            const {
              isValid,
              handleChange,
              values,
              handleSubmit,
              handleBlur,
              touched,
              errors,
              isSubmitting,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              submitForm,
            } = props;
            // bind the submission handler remotely
            if (this.props.bindSubmitForm) {
              this.props.bindSubmitForm(submitForm);
            }
            return (
              <Form onSubmit={handleSubmit}>
                <ScrollToErrorField />

                <SkiptiSpinner
                  loading={isSubmitting || this.props.address.isFetching}
                  opacity="0"
                  absolute
                />
                {isInRentWiz && (
                  <>
                    <div>
                      <div className="tw-flex tw-flex-wrap">
                        <div className="md:tw-w-6/12 tw-w-full tw-px-4">
                          <div className="tw-mb-12">
                            <TextField
                              label="First Name"
                              name="firstName"
                              autoComplete="given-name"
                              fullWidth
                              onChange={handleChange}
                              onFocus={handleBlur}
                              value={values.firstName || ""}
                              error={!!touched.firstName && !!errors.firstName}
                              helperText={
                                !!touched.firstName &&
                                !!errors.firstName &&
                                errors.firstName
                              }
                            />
                          </div>
                        </div>
                        <div className="md:tw-w-6/12 tw-w-full tw-px-4">
                          <div className="tw-mb-12">
                            <TextField
                              label="Last Name"
                              name="lastName"
                              autoComplete="family-name"
                              fullWidth
                              onChange={handleChange}
                              onFocus={handleBlur}
                              value={values.lastName || ""}
                              error={!!touched.lastName && !!errors.lastName}
                              helperText={
                                !!touched.lastName &&
                                !!errors.lastName &&
                                errors.lastName
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {isFastRegister && (
                        <div className="tw-flex tw-flex-wrap">
                          <div className="tw-w-full  tw-px-4">
                            <div className="form-group tw-mb-12">
                              <FormControl
                                fullWidth
                                style={{ marginBottom: "5px" }}
                              >
                                <InputLabel
                                  htmlFor="email"
                                  shrink={
                                    values.email?.length ? true : undefined
                                  }
                                >
                                  <span
                                    className={errors.email ? "error-text" : ""}
                                  >
                                    Email
                                  </span>
                                </InputLabel>
                                <Input
                                  value={values.email}
                                  onChange={handleChange}
                                  id="email"
                                  name="email"
                                  autoComplete="email"
                                  error={!!touched.email && !!errors.email}
                                  // inputComponent={MaskedTextInput}
                                />
                                {!!touched.email && !!errors.email && (
                                  <FormHelperText error>
                                    {errors.email}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="tw-flex tw-flex-wrap">
                        <div className="tw-w-full  tw-px-4">
                          <div className="form-group tw-mb-12">
                            <FormControl
                              fullWidth
                              style={{ marginBottom: "5px" }}
                            >
                              <InputLabel
                                htmlFor="phone"
                                shrink={values.phone?.length ? true : undefined}
                              >
                                <span
                                  className={errors.phone ? "error-text" : ""}
                                >
                                  Mobile Phone
                                </span>
                              </InputLabel>
                              <Input
                                value={values.phone}
                                onChange={handleChange}
                                id="phone"
                                name="phone"
                                autoComplete="tel"
                                error={!!touched.phone && !!errors.phone}
                                inputComponent={MaskedTextInput}
                              />
                              {!!touched.phone && !!errors.phone && (
                                <FormHelperText error>
                                  {errors.phone}
                                </FormHelperText>
                              )}
                            </FormControl>
                            {!this.props.isInstoreOrCurbside &&
                              !productDeliveryModals.includes(
                                ITEM_DELIVERY_MODEL.COURIER_TRY_AND_BUY
                              ) && (
                                <div className="tw-flex tw-items-center">
                                  <SmsToggle
                                    name="smsOptIn"
                                    value={values.smsOptIn}
                                    changeHandler={(e) => {
                                      this.toggleChangeHandler();
                                      handleChange(e);
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!skipAddress && (
                  <>
                    {/* <div className="tw-flex tw-flex-wrap">
                      <div className="tw-w-full tw-px-4">
                        <div className="form-group tw-mb-12">
                          <TextField
                            select={!isEdit}
                            autoComplete="address-name"
                            label="Address name"
                            name="name"
                            fullWidth
                            onChange={handleChange}
                            onFocus={handleBlur}
                            value={values.name}
                            error={!!touched.name && !!errors.name}
                            helperText={
                              !!touched.name && !!errors.name
                                ? errors.name
                                : `Give it a friendly name, like "Home"`
                            }
                          >
                            {!isEdit
                              ? addressNames.map((option) => (
                                  <MenuItem
                                    key={option.key}
                                    value={option.text}
                                  >
                                    {option.text}
                                  </MenuItem>
                                ))
                              : null}
                          </TextField>
                        </div>
                      </div>
                    </div> */}
                    <div className="tw-flex tw-flex-wrap">
                      <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                        <div className="form-group tw-mb-12">
                          <TextField
                            label="Street Address"
                            name="street1"
                            autoComplete="address-line1"
                            fullWidth
                            multiline
                            onChange={handleChange}
                            onFocus={handleBlur}
                            value={values.street1 || ""}
                            error={!!touched.street1 && !!errors.street1}
                            helperText={
                              !!touched.street1 &&
                              !!errors.street1 &&
                              errors.street1
                            }
                          />
                        </div>
                      </div>
                      <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                        <div className="form-group tw-mb-12">
                          <TextField
                            label="Apartment, Suite, etc."
                            name="street2"
                            autoComplete="address-line2"
                            fullWidth
                            onChange={handleChange}
                            value={values.street2 || ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tw-flex tw-flex-wrap">
                      <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                        <div className="form-group tw-mb-12">
                          <TextField
                            label="City"
                            name="city"
                            fullWidth
                            onChange={handleChange}
                            onFocus={handleBlur}
                            value={values.city || ""}
                            error={!!touched.city && !!errors.city}
                            helperText={
                              !!touched.city && !!errors.city && errors.city
                            }
                          />
                        </div>
                      </div>
                      <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                        <div className="form-group tw-mb-12">
                          <TextField
                            select
                            fullWidth
                            name="stateProvince"
                            label="State / Province"
                            onChange={handleChange}
                            value={values.stateProvince || ""}
                            error={
                              !!touched.stateProvince && !!errors.stateProvince
                            }
                            helperText={
                              !!touched.stateProvince &&
                              !!errors.stateProvince &&
                              errors.stateProvince
                            }
                          >
                            {helpers.statesList.map((state) => (
                              <MenuItem key={state.id} value={state.id}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </div>
                    <div className="tw-flex tw-flex-wrap">
                      <div className="tw-w-full md:tw-w-6/12 tw-px-4">
                        <div className="form-group tw-mb-12">
                          <TextField
                            label="Zip / Postal Code"
                            name="zipPostal"
                            autoComplete="postal-code"
                            fullWidth
                            onChange={(e) => {
                              this.zipCodeHandleChange(
                                e,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                touched,
                                "delivery"
                              );
                            }}
                            value={values.zipPostal}
                            error={!!touched.zipPostal && !!errors.zipPostal}
                            helperText={
                              !!touched.zipPostal &&
                              !!errors.zipPostal &&
                              errors.zipPostal
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tw-flex tw-flex-wrap">
                      <div className="tw-w-full tw-px-4">
                        <div className="form-group tw-mb-12">
                          <div className="tw-text-skiptiOrange tw-mb-4">
                            Be sure to include all necessary drop-off / pickup
                            instructions in the &quot;Delivery Notes&quot; field
                            below to ensure a seamless transaction.
                          </div>
                          <TextField
                            label="Delivery Notes"
                            name="deliveryNotes"
                            fullWidth
                            multiline
                            onChange={handleChange}
                            value={values.deliveryNotes || ""}
                            error={
                              !!touched.deliveryNotes && !!errors.deliveryNotes
                            }
                            helperText={
                              !!touched.deliveryNotes && !!errors.deliveryNotes
                                ? errors.deliveryNotes
                                : "eg: Buzzer doesn't work, call my cell."
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.props.addressOverwrite ? (
                  <div className="tw-flex tw-justify-end">
                    <SkiptiButton
                      onClick={handleSubmit}
                      design="primary dialog"
                      disabled={isSubmitting || !isValid}
                    >
                      Update
                    </SkiptiButton>
                  </div>
                ) : !this.props.inWizard ? (
                  <SkiptiButton
                    onClick={handleSubmit}
                    design="primary dialog"
                    disabled={this.props.isLoading}
                  >
                    {this.props.addressId ? "Update" : "Create"}
                  </SkiptiButton>
                ) : (
                  <div className="tw-flex tw-justify-end">
                    <SkiptiButton
                      design="secondary dialog"
                      onClick={this.onStartOver}
                      style={{ marginRight: "10px" }}
                    >
                      Start Over
                    </SkiptiButton>

                    <SkiptiButton
                      onClick={handleSubmit}
                      design="primary dialog"
                      disabled={this.props.isLoading}
                    >
                      Next
                    </SkiptiButton>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  current_user: getUser(state),
  jwt: getToken(state),
  address: ownProps.addressOverwrite
    ? { data: { address: ownProps.address }, isLoaded: true }
    : getAddressById(state),
  zip: getZip(state),
  region: getRegion(state),
  regions: getRegions(state),
  itemRentInfo: getItemRentInfo(state),
  productDeliveryModals: getProductDeliveryModals(state),
});

export default connect(mapStateToProps, {
  fetchAddressById,
  showSnackbar,
  regionChange,
  fetchRegions,
  setZipCode,
})(CreateAddressComponent);
