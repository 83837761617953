import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Typography from "@material-ui/core/Typography";
import { financial, txTypesEnum } from "../../utils";

export const subTransactionTypes = {
  // 0: "Product",
  // 1: "Bundled Item",
  2: "Profiled Item",
  3: "Add-On Consumable",
  4: "Add-On Non-Consumable",
  0: "Product Sales Tax Required",
  1: "Product Sales Tax Not Required",
  10: "Bundled Item",
  11: "Profiled Item",
  12: "Add On Consumable",
  13: "Add On Non Consumable",
  14: "Damage Protection",
  20: "Perimeter Pricing",
  21: "Delivery Pricing",
  30: "Convenience Fee",
  31: "Extra Night Fee",
  40: "Discount",
  50: "Custom Charge",
  51: "Driver Tip",
  100: "Release",
  101: "Refund",
  150: "Deposit",
  151: "Release",
  152: "Deposit Capture",
  200: "PreAuth Release",
};

export const subTransactionEnum = {
  Product: 0,
  Bundled_Item: 1,
  Profiled_Item: 2,
  Add_On_Consumable: 3,
  Add_On_Non_Consumable: 4,
  Add_On_Consumable_2: 12,
  Add_On_Non_Consumable_2: 13,
  Damage_Protection: 14,
  Perimeter_Pricing: 20,
  Delivery_Pricing: 21,
  Rescheduling_Fee: 30,
  Extra_Night_Fee: 31,
  Discount: 40,
  Custom_Charge: 50,
  Driver_Tip: 51,
  Release: 100,
  Release_2: 101,
  Deposit: 150,
  PreAuth_Release: 200,
};

/** @type {import("@mui/x-data-grid-pro").GridColumns} */
export const subColumnsModel = [
  {
    field: "display",
    headerName: "Item",
    flex: 1,
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (
        params.row.parentType === txTypesEnum.PartialRefundPreCapture &&
        params.value === 101
      ) {
        return subTransactionTypes[subTransactionEnum.Release];
      }
      return subTransactionTypes[params.value];
    },
  },
  {
    field: "createdDateLocal",
    headerName: "Date",
    flex: 1,
    sortable: false,
    renderCell: (params) =>
      moment(params.value)
        // .tz(params.value, "UTC")
        // .add(offSet, "hours")
        .format("MM/DD/YYYY hh:mm a"),
  },
  {
    field: "amountCents",
    headerName: "Price",
    flex: 1,
    sortable: false,
    cellClassName: "tw-d-flex",
    // align: "right",
    renderCell: (params) => `$${financial(params.value / 100)}`,
  },
  {
    field: "salesTaxAmountCents",
    headerName: "Sales Tax",
    flex: 1,
    sortable: false,
    cellClassName: "tw-d-flex",
    // align: "right",
    renderCell: (params) => `$${financial(params.value / 100)}`,
  },
];

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row({ row, isExpanded, columns, subColumns = [], subRows }) {
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        {columns.map((el, index) => {
          if (typeof el.renderCell === "function") {
            const InnerComp = el.renderCell;
            return (
              <TableCell className={el.className} key={`${row.id}${index}`}>
                <InnerComp row={row} value={row[el.field]} />
              </TableCell>
            );
          }
          return (
            <TableCell className={el.className} key={`${row.id}${index}`}>
              {row[el.field]}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell colSpan={columns.length + 1}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {/* //Todo mock data show  */}
            <DataGridPro
              style={{ border: "none" }}
              className="data-grid-table data-grid-table-bold-header data-grid tw-mb-5"
              rows={subRows}
              autoHeight
              columns={subColumns}
              disableSelectionOnClick
              hideFooter
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const useStyles = makeStyles({
  container: {
    maxHeight: 600,
  },
  root: {
    fontWeight: "bold",
    backgroundColor: "#fff",
  },
});

// eslint-disable-next-line import/prefer-default-export
export const ExpandableTable = React.memo(
  ({ rows = [], columns = [], isExpandable = false, isExpanded = false }) => {
    const classes = useStyles();
    return (
      <>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {isExpandable && <TableCell className={classes.root} />}
                {columns.map((el) => (
                  <TableCell
                    className={`${classes.root} ${el.className ?? ""}`}
                    key={el.field}
                  >
                    {el.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  columns={columns}
                  isExpandable={isExpandable}
                  isExpanded={isExpanded}
                  subColumns={subColumnsModel}
                  subRows={row.items}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
