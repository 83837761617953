import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Elements } from "react-stripe-elements";
import axios from "axios";
import {
  Icon,
  IconButton,
  Popover,
  Typography,
  withWidth,
} from "@material-ui/core";
import MixpanelContext from "react-mixpanel";
// import { truncate } from "lodash";
import StripeForm from "./StripeForm";
import SkiptiSpinner from "../skipti/Spinner";
import {
  skipStripe,
  fetchAddressesByUserId,
  showSnackbar,
  getUserInfo,
} from "../../actions";
// import skiptip from "../../img/skiptip.svg";
import poweredByStripe from "./powered_by_stripe.svg";
import {
  getUser,
  getToken,
  getDefaultLocation,
  getDisplayName,
  getStripeFallbackLocation,
} from "../../reducers/authReducer";
import {
  serverUrl,
  //  imageServerUrl
} from "../../utils";
import { getAddressesByUserId } from "../../reducers/addressesReducer";
import { getItemRentInfo } from "../../reducers/itemRentReducer";
import RentDetails from "../items/rent/RentDetailsCart";

class StripeStep extends Component {
  static propTypes = {
    skipStripe: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    fetchAddressesByUserId: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
  };

  static contextType = MixpanelContext;

  state = {
    isLoading: false,
    isExplainerOpened: null,
  };

  static getDerivedStateFromProps = (
    props
    //  state
  ) => ({
    address:
      props.defaultLocation ||
      (props.addresses[0] && props.addresses[0].address),
  });

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.setId) {
      this.props.setId();
    }
    this.props.getUserInfo(null, false, window.analytics);
    this.props.fetchAddressesByUserId();

    this.context.track("Rent an Item - Stripe Step", {
      productResourceId: this.props.item || "",
      productName: this.props.item?.title || "",
    });
    window.analytics.track("Checkout Step Viewed", {
      checkout_id: "",
      step: 5,
      // shipping_method: 'Fedex',
      // payment_method: 'Visa'
    });
    window.analytics.page("Checkout Stripe Step", {
      productResourceId: this.props.item || "",
      productName: this.props.item?.title || "",
      // shipping_method: 'Fedex',
      // payment_method: 'Visa'
    });
  }

  handleSkipStripe = () => {
    this.props.skipStripe();
    this.props.nextStep();
  };

  handleSubmit = async ({ name, token, location }) => {
    const { user, jwt } = this.props;
    const { addresses } = this.props;
    let obj = {};
    if (addresses[0])
      obj = {
        accountName: name || `${this.props.displayName}'s credit card`,
        email: user.email,
        makeDefaultPayment: true,
        makeDefaultAddress: true,
      };
    else {
      obj = {
        accountName: name || `${this.props.displayName}'s credit card`,
        email: user.email,
        makeDefaultPayment: true,
        makeDefaultAddress: true,
      };
    }

    if (location) {
      obj.location = {
        availabilityCalendar: 0,
        externalId: "",
        isActive: true,
        locationType: 0,
        organizationId: this.props.user.resourceOrganization,
        ...location,
      };
    }

    try {
      await axios.post(
        `${serverUrl}/api/v1/payment/account/${user.userId}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            token: token.id,
            isBillingAddress: true,
          },
        }
      );
      this.isLoading(false);
      this.props.nextStep();
    } catch (e) {
      this.isLoading(false);
      if (e?.response?.status === 406 || e?.response?.status === "406") {
        this.props.showSnackbar("Not valid Address!");
        return;
      }
      this.props.showSnackbar(
        (e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      );
    }
    await this.props.getUserInfo(null, true, window.analytics);
  };

  isLoading = (isLoading) => {
    this.setState({ isLoading });
  };

  openExplainer = (event) => {
    this.setState({
      isExplainerOpened: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      isExplainerOpened: null,
    });
  };

  render() {
    const {
      //  step,
      //   titlel,
      width,
    } = this.props;
    const { isLoading } = this.state;
    const isOpen = Boolean(this.state.isExplainerOpened);
    const id = isOpen ? "simple-popover" : undefined;

    // HACK FOR SW-6986 -- CHANGE THIS WHEN THE BACKEND SENDS THE INFO
    // ALONG WITH WHERE isJR IS USED BELOW.
    const isJR = [
      "97982211-790C-4D97-BC17-F37176211F89",
      "E0009AAA-8E04-4DCC-8086-360F46E886D6",
      "A9F9E570-BE54-4B83-B196-05184D96FCF5",
    ].some((e) => window.location.pathname.toUpperCase().includes(e));

    return (
      <div className="tw-px-2" key="step2">
        <div className="tw-flex tw-flex-row tw-h-full">
          <div className="md:tw-w-8/12">
            <div className="md:tw-w-10/12">
              {width === "xs" ? (
                <h3 className="tw-mb-6 fw-light">{this.props.title}</h3>
              ) : (
                <h2 className="tw-mb-6 fw-light">{this.props.title}</h2>
              )}
              <div className="tw-mb-6">
                <Typography variant="body2">
                  <strong>This will not charge your card.</strong> Payment
                  information will be used to complete your order on the
                  following page. You will be able to review and edit these
                  details before you finalize your rental.
                </Typography>
              </div>
              {this.props.isAppointment ? (
                <div onClick={this.openExplainer}>
                  <p
                    aria-describedby={id}
                    className="tw-text-sm tw-text-skiptiOrange tw-cursor-pointer"
                  >
                    Why do you need my payment information?
                  </p>
                </div>
              ) : null}
              <div className="tw-p-4">
                <div
                  className="tw-flex tw-justify-end"
                  style={{
                    right: "48px",
                  }}
                >
                  <img src={poweredByStripe} alt="powered_by_stripe" />
                </div>
                <SkiptiSpinner loading={isLoading || false} opacity={0.001} />
                <Elements>
                  <StripeForm
                    handleSubmit={this.handleSubmit}
                    showSnackbar={this.props.showSnackbar}
                    isLoading={this.isLoading}
                    item={this.props.item}
                  />
                </Elements>
                {this.props.user.isGuest && (
                  <p className="tw-text-xs tw-pt-4">
                    Credit card information will be used for payment and will be
                    held on file for six days after completion of order to allow
                    addition of gratuity
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="md:tw-w-4/12 tw-hidden md:tw-block tw-self-start skiptip">
            <RentDetails
              deliveryOnly={this.props.deliveryOnly}
              item={this.props.item}
              zip={this.props.itemRentInfo.zip}
              deliveryTime={this.props.itemRentInfo.startAvail}
              returnTime={this.props.itemRentInfo.endAvail}
              locationId={this.props.itemRentInfo.locationId}
              hideDates={this.props.hideDates || false}
              isAppointment={this.props.isAppointment}
            />
          </div>
        </div>
        <p className="tw-text-sm tw-max-w-lg tw-text-gray-700 ">
          Skipti uses Stripe, a PCI Service Provider Level 1, which is the
          highest grade of payment processing security to process all credit
          card payments. You can rest assured your information is safe and
          secure.
        </p>

        <Popover
          id={id}
          open={isOpen}
          anchorEl={this.state.isExplainerOpened}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="tw-max-w-md tw-w-full tw-px-5 tw-pb-5">
            <div className="tw-text-right tw-mb-3 tw-pt-3">
              <IconButton
                color="secondary"
                onClick={this.handleClose}
                className="paddingMarginRemover"
              >
                <Icon>close</Icon>
              </IconButton>
            </div>

            {isJR ? (
              <p className="tw-text-center tw-text-sm">
                <strong>Why do you need my credit card?</strong>
                <br />
                <br />
                Don't worry, your demo ride is completely free! We collect
                payment information in case you miss your appointment or fail to
                return the JackRabbit demo bike.
              </p>
            ) : (
              <p className="tw-text-center tw-text-sm">
                While free bookings require no charge, payment information is
                necessary in case missed connection or return failure fees are
                incurred by the customer. Your billing information will also be
                used to ready your purchase page if you decide to buy your
                selected demo items.
                <span className="tw-italic tw-inline-block tw-mt-5">
                  All payment information will be stored via Stripe, a Level 1 PCI
                  Service Provider.
                </span>
              </p>
            )}
          </div>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  defaultLocation: getDefaultLocation(state),
  addresses: getAddressesByUserId(state),
  addr: getStripeFallbackLocation(state),
  user: getUser(state),
  jwt: getToken(state),
  displayName: getDisplayName(state),
  itemRentInfo: getItemRentInfo(state),
});

export default connect(mapStateToProps, {
  skipStripe,
  fetchAddressesByUserId,
  showSnackbar,
  getUserInfo,
})(withRouter(withWidth()(StripeStep)));
