import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../actions";
import LayoutMain from "../layouts/LayoutMain";

class ConfirmEmail extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        jwt: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    getUserInfo: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getUserInfo(
      decodeURIComponent(this.props.match.params.jwt),
      true,
      window.analytics
    );
  }

  render() {
    return (
      <LayoutMain withContainer>
        <div className="container tw-my-5">
          <i className="fal fa-7x fa-envelope-open float-right text-color7 tw-ml-5 tw-mb-5" />
          <h2>Email confirmed.</h2>
          <p className="lead">
            Thank you for confirming your email! We are happy to have you here.
          </p>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu quam
            egestas, commodo turpis id, egestas velit. Vestibulum ante ipsum
            primis in faucibus orci luctus et ultrices posuere cubilia Curae;
            Suspendisse viverra ut arcu sed pharetra. Mauris tempus sodales
            metus, eu accumsan lorem auctor ut. In hac habitasse platea
            dictumst.{" "}
          </p> */}

          <div className="tw-mt-5">
            <Link
              to="/discover"
              href="/discover"
              className="btn-skipti-primary tw-mr-2"
            >
              Rent an item
            </Link>
            <Link
              to="/items/new"
              href="/items/new"
              className="btn-skipti-secondary"
            >
              List an item
            </Link>
          </div>
        </div>
      </LayoutMain>
    );
  }
}
export default connect(null, { getUserInfo })(ConfirmEmail);
