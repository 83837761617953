import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";

class ExperienceForm extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    initialValues: PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      lead: PropTypes.string,
      body: PropTypes.string,
      quote: PropTypes.string,
      quote_author: PropTypes.string,
      video: PropTypes.string,
      cover: PropTypes.string,
      thumbnail: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValues: {
      type: "blog",
      title: "",
      lead: "",
      body: "",
      quote: "",
      quote_author: "",
      video: "9_lKMTXVk64",
      cover: "https://picsum.photos/1920/576?image=123",
      thumbnail: "https://dummyimage.com/640x360/ccc/fff&text=+",
      items: [],
    },
  };

  state = {};

  render() {
    const { items, initialValues, handleSubmit } = this.props;
    return (
      <Formik onSubmit={handleSubmit} initialValues={{ ...initialValues }}>
        {({ values, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <div className="form-group row">
              <label htmlFor="type" className="col-3 text-right">
                Type
              </label>
              <div className="col-9">
                <select
                  name="type"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.type}
                >
                  <option value="blog">Blog</option>
                  <option value="top10">Top 10</option>
                  <option value="idea">Idea</option>
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="title" className="col-3 text-right">
                Title
              </label>
              <div className="col-9">
                <input
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="lead" className="col-3 text-right">
                Lead
              </label>
              <div className="col-9">
                <textarea
                  name="lead"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lead}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="body" className="col-3 text-right">
                Body
              </label>
              <div className="col-9">
                <textarea
                  name="body"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.body}
                  className="form-control"
                  rows="20"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="quote" className="col-3 text-right">
                Quote
              </label>
              <div className="col-9">
                <textarea
                  name="quote"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.quote}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="quote_author" className="col-3 text-right">
                Quote Author
              </label>
              <div className="col-9">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.quote_author}
                  name="quote_author"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="video" className="col-3 text-right">
                Video ID
              </label>
              <div className="col-9">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.video}
                  name="video"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="cover" className="col-3 text-right">
                Cover photo URL
              </label>
              <div className="col-9">
                <input
                  name="cover"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cover}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="thumbnail" className="col-3 text-right">
                Thumbnail URL
              </label>
              <div className="col-9">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.thumbnail}
                  name="thumbnail"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="items" className="col-3 text-right">
                Promoted Items
              </label>
              <div className="col-9">
                <select
                  name="items"
                  onChange={(event) =>
                    setFieldValue(
                      event.target.name,
                      [...event.target.options]
                        .filter((o) => o.selected)
                        .map((o) => o.value)
                    )
                  }
                  onBlur={handleBlur}
                  value={values.items}
                  className="form-control"
                  multiple
                >
                  {items.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-3 text-right">&nbsp;</label>
              <div className="col-9">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ExperienceForm;
