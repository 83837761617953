import React, { Component } from "react";
// import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import {
  FormControl,
  InputLabel,
  Input,
  withStyles,
  ThemeProvider,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { forgotPassword } from "../../actions";
import SkiptiProgress from "../skipti/Spinner";
import { imageServerUrl } from "../../utils";
import { customTheme } from "./RegisterForm";

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
});

class ForgotPassword extends Component {
  static propTypes = {};

  state = {};

  handleSubmit = async (values) => {
    await this.props.forgotPassword(values);
    this.props.onClose();
  };

  render() {
    const {
      classes,
      // errors,
      // isPromotion,
      // hideClose,
      // isInRentWiz,
      isFetching,
      onClose,
      hideCloseBtn = false,
    } = this.props;
    const {
      // showForm, isModalOpen,
      isSubmitting,
    } = this.state;
    return (
      <ThemeProvider theme={customTheme}>
        <SkiptiProgress loading={isSubmitting} opacity="0.01" />
        {hideCloseBtn ? null : (
          <button
            type="button"
            className="tw-float-right close-button-modal"
            onClick={onClose}
          >
            <i className="fas fa-times" />
          </button>
        )}
        <>
          <div className="tw-text-center">
            <img
              src={`${imageServerUrl}/2020-1009 Skipto Website popup Logo-rev01.png`}
              alt="skipti logo"
              className="tw-mx-auto"
              style={{ maxHeight: "128px", maxWidth: "128px" }}
            />
          </div>
        </>
        <Formik
          initialValues={{
            // first_name: "",
            email: "",
            password: "",
            confirmpassword: "",
            acceptedTandC: false,
            promoCode: "",
          }}
          onSubmit={this.handleSubmit}
        >
          {({ handleBlur, handleChange, values, handleSubmit }) => (
            <Form>
              {/* {errors && (
                    <Paper elevation={2} className={`${classes.paper} mb-4`}>
                      <Typography component="p" color="error">
                        {errors}
                      </Typography>
                    </Paper>
                  )} */}
              <div className="tw-mb-4">
                <FormControl color="secondary" fullWidth>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    disabled={isFetching}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // startAdornment={
                    //   <InputAdornment position="start">
                    //     <div className={classes.root}>
                    //       <AccountCircle color="secondary" />
                    //     </div>
                    //   </InputAdornment>
                    // }
                  />
                </FormControl>
              </div>
              <Button
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                disableElevation
                fullWidth
              >
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
        <>
          <hr />
          <p className="tw-text-center tw-my-0">
            <a
              onClick={this.props.onLogInClick}
              href="#"
              className="tw-mt-4 tw-text-skiptiOrange fw-medium tw-cursor-pointer"
            >
              Back To Login
            </a>
          </p>
        </>
      </ThemeProvider>
    );
  }
}

// const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  forgotPassword,
};

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(ForgotPassword)
);
