import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as helpers from "../../utils";
import {
  fetchOrganizationInfoById,
  fetchOrganizationInfoByName,
  fetchItemsByOrganizationId,
} from "../../actions";
import {
  getOrganizationInfoById,
  getOrganizationInfoByName,
} from "../../reducers/organizationReducer";
import { getProductsByOrganization, getZip } from "../../reducers/itemsReducer";
import LayoutCategory from "../layouts/LayoutCategory";
import ItemTile from "./ItemTile";
import Featurette from "./Featurette";
import RegisterInterestAlert from "../utils/RegisterInterestAlert";
import Spinner from "../skipti/Spinner";
import { toQueryObject } from "../../utils";

class OrganizationProfile extends Component {
  state = {
    isOpenInterestAlert: false,
    interestData: undefined,
  };

  componentDidMount() {
    this.loadOrganizationData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.zip !== prevProps.zip) {
      this.loadOrganizationData();
    }

    if (
      !prevProps.organizationByName?.data &&
      this.props.organizationByName?.data &&
      !this.state.isOpenInterestAlert
    ) {
      if (this.props.organizationByName?.data) {
        this.setState({
          interestData: {
            popupLogo: this.props.organizationByName.data.popupLogo || "",
            bodyText:
              this.props.organizationByName.data.productNotAvailableHtml || "",
          },
        });
      }

      if (window.location.search.includes("alternative")) {
        this.setState({ isOpenInterestAlert: true });
      }
    }
  }

  loadOrganizationData = () => {
    const organizationId = this.props.match.params.id;
    const organizationName = this.props.match.params.name;

    if (organizationId != null) {
      this.props.fetchOrganizationInfoById(organizationId);
      this.props.fetchItemsByOrganizationId({ organizationId });
    } else if (organizationName != null) {
      const queryZip = toQueryObject(window.location.search).get("zip");

      this.props.fetchOrganizationInfoByName(organizationName, queryZip);
    }
  };

  onCloseInterestAlert = () => {
    this.setState({
      isOpenInterestAlert: false,
    });
  };

  render() {
    const { props } = this;
    const organizationByName =
      props.organizationByName &&
      props.organizationByName.isLoaded &&
      props.organizationByName.data
        ? props.organizationByName.data
        : {};

    const organization =
      props.organization &&
      props.organization.isLoaded &&
      props.organization.data
        ? props.organization.data
        : organizationByName;

    const itemsByName =
      props.organizationByName &&
      props.organizationByName.isLoaded &&
      props.organizationByName.data.resources
        ? props.organizationByName.data.resources
        : [];
    let items =
      props.items && props.items.isLoaded && props.items.data.resources
        ? props.items.data.resources
        : itemsByName;
    items = items.filter(
      (x) =>
        x.resourceId !== "a6ab66a2-26d5-4601-aa57-27ba84f85202" ||
        x.resourceId !== "0AA71DDA-9B2A-4F12-A85D-3C5FB45A7D3A"
    );

    const _logo =
      organization && organization.logo?.includes("http")
        ? organization.logo
        : `${helpers.imageServerUrl}/partners/${organization.logo}`;
    const _headerImage =
      organization && organization.headerImage?.includes("http")
        ? organization.headerImage
        : `${helpers.imageServerUrl}/partners/${organization.headerImage}`;

    const contentFetching =
      props.organization?.isFetching || props.organizationByName?.isFetching;

    return (
      <LayoutCategory showSearch>
        {contentFetching ? (
          <div className="tw-py-16">
            <Spinner loading={true} absolute={false} opacity="0" />
          </div>
        ) : organization != null ? (
          <>
            <div
              className="tw-mb-5"
              style={{
                backgroundImage: `url(${_headerImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "350px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {organization && organization.logo && (
                <img
                  alt="organization logo"
                  src={_logo}
                  style={{
                    position: "absolute",
                    margin: "0 auto",
                    maxWidth: "100%",
                    maxHeight: "300px",
                  }}
                />
              )}
            </div>

            {organization.organizationId ===
            "e8752e03-05a2-4d81-b858-10df7c7b8c7e" ? (
              <>
                <Featurette
                  type={0}
                  left
                  image={`${helpers.imageServerUrl}/partners/bst/BSTfeaturettedark.jpg`}
                >
                  <>
                    <p className="text-color4">
                      The BST demo program is the easiest way to try before you
                      buy. It even includes a live virtual conference to help
                      you select the perfect racquet and 75% of the demo fee
                      will be credited toward your purchase if you decide to buy
                      with us.
                    </p>
                    <br />
                    <Link
                      to="/items/a6ab66a2-26d5-4601-aa57-27ba84f85202"
                      href="/items/a6ab66a2-26d5-4601-aa57-27ba84f85202"
                      className="btn-skipti-primary tw-mr-3"
                    >
                      Demo Program
                    </Link>
                  </>
                </Featurette>
              </>
            ) : null}

            {organization.organizationId ===
            "ab45aed9-3cf7-46bf-b485-25f11c5da77a" ? (
              <>
                <Featurette
                  type={0}
                  left
                  image={`${helpers.imageServerUrl}/partners/bst/BSTfeaturettedark.jpg`}
                >
                  <>
                    <p className="text-color4">
                      The BST demo program is the easiest way to try before you
                      buy. It even includes a live virtual conference to help
                      you select the perfect racquet and 75% of the demo fee
                      will be credited toward your purchase if you decide to buy
                      with us.
                    </p>
                    <br />
                    <Link
                      to="/items/0aa71dda-9b2a-4f12-a85d-3c5fb45a7d3a"
                      href="/items/0aa71dda-9b2a-4f12-a85d-3c5fb45a7d3a"
                      className="btn-skipti-primary tw-mr-3"
                    >
                      Demo Program
                    </Link>
                  </>
                </Featurette>
              </>
            ) : null}

            <div className="container">
              <div className="tw-flex tw-flex-wrap">
                {items.length > 0
                  ? items.map((item) => (
                      <ItemTile
                        {...item}
                        noOfProducts="6"
                        key={item.resourceId}
                      />
                    ))
                  : null}
              </div>
            </div>
          </>
        ) : null}

        {this.state.isOpenInterestAlert && (
          <RegisterInterestAlert
            onClose={this.onCloseInterestAlert}
            from="partner"
            data={this.state.interestData}
            isLoaded={organizationByName.isLoaded}
          />
        )}
      </LayoutCategory>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: getOrganizationInfoById(state),
  organizationByName: getOrganizationInfoByName(state),
  items: getProductsByOrganization(state),
  zip: getZip(state),
});

export default withRouter(
  connect(mapStateToProps, {
    fetchOrganizationInfoById,
    fetchOrganizationInfoByName,
    fetchItemsByOrganizationId,
  })(OrganizationProfile)
);
