import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import SearchIcon from "@material-ui/icons/Search";
import {
  Select,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  withStyles,
} from "@material-ui/core";
import {
  fetchItemsBySearchStringAndCategory,
  changeRegion,
  fetchRegions,
  fetchZipInfo,
  setZipCode,
  openZipModal,
} from "../../actions";
import { getItemsBySearch, getZip } from "../../reducers/itemsReducer";
import { getRegion, getRegions } from "../../reducers/userReducer";
import ChangeZip from "./ChangeZip";

const styles = () => ({
  cityInput: {
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
  },
});

class SearchBar extends Component {
  handleSubmit = async (values) => {
    const searchValues = {
      ...values,
      searchString: values.searchString,
    };
    if (
      (values.searchCity === null || values.searchCity < 2) &&
      this.props.zip === "00000"
    ) {
      this.props.openZipModal();
    } else {
      this.props.fetchItemsBySearchStringAndCategory(searchValues);
      this.props.history.push("/items/search");
    }
  };

  render() {
    const { searchString, region, regions } = this.props;

    return (
      <>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={9}>
            <Formik
              onSubmit={this.handleSubmit}
              enableReinitialize
              validate={(_values) => {
                const errors = {};
                if (!_values.searchString) {
                  errors.reason = "This field is required";
                }
                return errors;
              }}
              initialValues={{
                searchCategory: -1,
                searchString: searchString || "",
                searchCity: region ? region.id : "",
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <TextField
                      className="text-color7"
                      style={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      }}
                      variant="outlined"
                      fullWidth
                      placeholder="What would you like to rent today?"
                      name="searchString"
                      value={
                        values.searchString.match(/%2f/g) != null
                          ? null
                          : values.searchString
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        style: { height: "40px", backgroundColor: "white" },
                        className: "text-color7",
                        endAdornment: (
                          <IconButton
                            disabled={!values.searchCity}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            <SearchIcon />
                          </IconButton>
                        ),
                        startAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginRight: "12px" }}
                          >
                            <Select
                              margin="dense"
                              style={{ width: "120px" }}
                              size="small"
                              fullWidth
                              label="City"
                              value={values.searchCity}
                              name="searchCity"
                              onChange={(e) => {
                                const { value } = e.target;
                                setFieldValue("searchCity", value);
                                this.props.changeRegion(
                                  regions.find((x) => x.id === value)
                                );
                              }}
                            >
                              {regions.map((item) => (
                                <MenuItem value={item.id} key={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </Grid>
          <Grid item xs={3}>
            <ChangeZip
              outlined
              margin="normal"
              style={{
                width: "90px",
                marginTop: "7px",
                backgroundColor: "white",
                height: "40px",
              }}
              InputLabelProps={{
                style: { top: "-8px" },
              }}
              inputProps={{
                style: { height: "40px" },
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  searchString: getItemsBySearch(state).searchString,
  region: getRegion(state),
  regions: getRegions(state),
  zip: getZip(state),
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    fetchItemsBySearchStringAndCategory,
    changeRegion,
    fetchRegions,
    fetchZipInfo,
    setZipCode,
    openZipModal,
  })(withRouter(SearchBar))
);
