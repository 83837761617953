import * as TYPES from "../types";
import * as helpers from "../utils";
import { SET_ITEM_RENT_QUERY_STRING_OPTIONS } from "../types";

const initialState = {
  endAvail: "",
  startAvail: "",
  startAvailWindowId: "",
  endAvailWindowId: "",
  location: "",
  locationObject: null,
  deliveryOption: "",
  zip: localStorage.getItem("zipCode") || "",
  addons: [],
  quantity: 1,
  cartItems: [],
  cartItemIds: [],
  method: "",
  getItNow: false,
  client: null,
  promoCode: null,
  source: null,
  storeId: null,
};

const itemRentReducer = (state = initialState, action) => {
  const { payload, error } = action;
  switch (action.type) {
    case "SET_ADDONS":
      return { ...state, addons: payload };
    case "SET_DELIVERY_OPTION":
      return { ...state, deliveryOption: payload };
    case "SET_CLIENT":
      return { ...state, client: payload };
    case "SET_GET_IT_NOW":
      return { ...state, getItNow: payload };
    case "SET_ITEM_RENT_ZIP":
      return { ...state, zip: payload };
    case "SET_START_AVAIL":
      return { ...state, startAvail: payload };
    case "SET_END_AVAIL":
      return { ...state, endAvail: payload };
    case "SET_START_AVAIL_WINDOW_ID":
      return { ...state, startAvailWindowId: payload };
    case "SET_END_AVAIL_WINDOW_ID":
      return { ...state, endAvailWindowId: payload };
    case "SET_DELIVERY_METHOD":
      return { ...state, method: payload };
    case "SET_LOCATION_ID":
      return { ...state, location: payload };
    case "SET_LOCATION":
      return { ...state, locationObject: payload };
    case TYPES.SET_ITEM_RENT_QUERY_STRING_OPTIONS:
      return { ...state, ...payload };
    case TYPES.RESET_ITEM_RENT_QUERY_STRING_OPTIONS:
      return {
        ...state,
        promoCode: null,
        source: null,
      };
    case TYPES.DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_SUCCESS:
      return { ...state, zip: payload.zipPostal };
    case TYPES.SIGN_OUT_SUCCESS:
      return {
        ...state,
        zip: "",
        endAvail: "",
        startAvail: "",
        startAvailWindowId: "",
        endAvailWindowId: "",
        location: "",
        locationObject: null,
        method: "",
        quantity: 1,
        itemProfiles: null,
        checkoutAttributes: null,
        addons: null,
        deliveryOption: null,
        client: null,
        promoCode: null,
        source: null,
        getItNow: false,
        deliveryModel: null,
        storeId: null,
      };
    case "SET_QUANTITY":
      return { ...state, quantity: payload };
    case "SET_ITEM_PROFILES":
      return { ...state, itemProfiles: payload };
    case "SET_CHECKOUT_ATTRIBUTES":
      return { ...state, checkoutAttributes: payload };

    case "SET_CART_ITEMS":
      return { ...state, cartItems: payload };

    case "SET_CART_ITEMS_IDS":
      return { ...state, cartItemIds: payload };

    case "SET_DELIVERY_MODEL":
      return { ...state, deliveryModel: payload };
    case "SET_EXPERIENCE_TYPE":
      return { ...state, experienceType: payload };
    case "SET_FULFILLMENT_TYPE":
      return { ...state, fulfillmentType: payload };
    case "SET_STORE_ID":
      return { ...state, storeId: payload };
    default:
      return state;
  }
};

export const getItemRentQueryStringOptions = (state) => ({
  promoCode: state.itemRentReducer.promoCode,
  client: state.itemRentReducer.client,
  source: state.itemRentReducer.source,
});

export const getSelectedAddons = (state) => state.itemRentReducer.addons;
export const getItemRentInfo = (state) => state.itemRentReducer;
export const getSelectedDeliveryOption = (state) =>
  state.itemRentReducer.deliveryOption;
export const getSelectedItemProfiles = (state) =>
  state.itemRentReducer.itemProfiles;
export const getCheckoutAttributes = (state) =>
  state.itemRentReducer.checkoutAttributes;
export const getCartItems = (state) => state.itemRentReducer.cartItems;
export const getCartItemIds = (state) => state.itemRentReducer.cartItemIds;
export const getDeliveryModal = (state) => state.itemRentReducer.deliveryModel;
export const getFulFillmentType = (state) =>
  state.itemRentReducer.fulfillmentType;
export const getExperienceType = (state) =>
  state.itemRentReducer.experienceType;
export const getStoreId = (state) => state.itemRentReducer.storeId;
export default itemRentReducer;
