import * as TYPES from "../types";
import * as helpers from "../utils";

const userReducer = (
  state = {
    region: null,
    regions: [],
    skippedFlags: [],
    usersByOrganizationId: helpers.makeInitial(),
    reviewsAsOwner: helpers.makeInitial(),
    reviewsAsRenter: helpers.makeInitial(),
    customerReviewsAsAuthor: helpers.makeInitial(),
    merchantReviewsAsAuthor: helpers.makeInitial(),
    isCustomerReviewModalOpen: false,
    isMerchantReviewModalOpen: false,
    isDriverReviewModalOpen: false,
    customerReview: {},
    merchantReview: {},
    isFromRugdoctor: false,
    zipFromService: "",
    isCurrentLocationServiceable: "",
    profiledProductsByCategoryId: helpers.makeInitial(),
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.FETCH_REGIONS_SUCCESS:
      return { ...state, regions: payload };
    case TYPES.CHANGE_REGION:
      return { ...state, region: payload };
    case TYPES.USER_GET_ZIP_FROM_LATLONG_SUCCESS:
      return {
        ...state,
        zipFromService: payload.zipPostal,
        isCurrentLocationServiceable: payload.serviced,
      };
    case TYPES.USER_GET_ZIP_FROM_LATLONG_FAIL:
      return {
        ...state,
        isCurrentLocationServiceable: payload ? payload.serviced : "",
      };
    case TYPES.USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_SUCCESS:
      return {
        ...state,

        isZipServicedByProduct: payload,
      };
    case TYPES.USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_FAIL:
      return {
        ...state,
        isZipServicedByProduct: payload || "",
      };
    case TYPES.CUSTOMER_REVIEW_ASAUTHOR_REQUEST:
      return {
        ...state,
        customerReviewsAsAuthor: helpers.makeStartLoading(),
      };
    case TYPES.CUSTOMER_REVIEW_ASAUTHOR_FAILURE:
      return {
        ...state,
        customerReviewsAsAuthor: helpers.makeLoadFailed(),
      };
    case TYPES.CUSTOMER_REVIEW_ASAUTHOR_SUCCESS:
      return {
        ...state,
        customerReviewsAsAuthor: helpers.makeLoadSuccess(payload),
      };
    case TYPES.MERCHANT_REVIEW_ASAUTHOR_FAILURE:
      return {
        ...state,
        merchantReviewsAsAuthor: helpers.makeLoadFailed(),
      };
    case TYPES.MERCHANT_REVIEW_ASAUTHOR_REQUEST:
      return {
        ...state,
        merchantReviewsAsAuthor: helpers.makeStartLoading(),
      };
    case TYPES.MERCHANT_REVIEW_ASAUTHOR_SUCCESS:
      return {
        ...state,
        merchantReviewsAsAuthor: helpers.makeLoadSuccess(payload),
      };
    case TYPES.CUSTOMER_REVIEW_MODAL_CLOSE:
      return { ...state, isCustomerReviewModalOpen: false, customerReview: {} };
    case TYPES.CUSTOMER_REVIEW_MODAL_OPEN:
      return {
        ...state,
        isCustomerReviewModalOpen: true,
        customerReview: payload,
      };
    case TYPES.MERCHANT_REVIEW_MODAL_CLOSE:
      return { ...state, isMerchantReviewModalOpen: false, merchantReview: {} };
    case TYPES.MERCHANT_REVIEW_MODAL_OPEN:
      return {
        ...state,
        isMerchantReviewModalOpen: true,
        merchantReview: payload,
      };

    case TYPES.DRIVER_REVIEW_MODAL_CLOSE:
      return { ...state, isDriverReviewModalOpen: false, driverReview: {} };
    case TYPES.DRIVER_REVIEW_MODAL_OPEN:
      return {
        ...state,
        isDriverReviewModalOpen: true,
        driverReview: payload,
      };

    case TYPES.CURRENT_USER_REQUEST:
      return { ...state };
    case TYPES.CURRENT_USER_SUCCESS:
      return { ...state };
    case TYPES.CURRENT_USER_FAIL:
      return { ...state };
    case TYPES.SKIPPED_FLAGS_SET:
      return {
        ...state,
        skippedFlags: payload.skippedFlags,
        returnUrl: payload.returnUrl,
      };
    case TYPES.USERS_INFO_BYOWNERID_REQUEST:
      return { ...state, infoById: helpers.makeStartLoading() };
    case TYPES.USERS_INFO_BYOWNERID_SUCCESS:
      return { ...state, infoById: helpers.makeLoadSuccess(payload) };
    case TYPES.USERS_INFO_BYOWNERID_FAIL:
      return { ...state, infoById: helpers.makeLoadFailed(error) };
    case TYPES.USER_REVIEWS_ASOWNER_REQUEST:
      return { ...state, reviewsAsOwner: helpers.makeStartLoading() };
    case TYPES.USER_REVIEWS_ASOWNER_SUCCESS:
      return { ...state, reviewsAsOwner: helpers.makeLoadSuccess(payload) };
    case TYPES.USER_REVIEWS_ASOWNER_FAIL:
      return { ...state, reviewsAsOwner: helpers.makeLoadFailed(error) };
    case TYPES.USER_REVIEWS_ASRENTER_REQUEST:
      return { ...state, reviewsAsRenter: helpers.makeStartLoading() };
    case TYPES.USER_REVIEWS_ASRENTER_SUCCESS:
      return { ...state, reviewsAsRenter: helpers.makeLoadSuccess(payload) };
    case TYPES.USER_REVIEWS_ASRENTER_FAIL:
      return { ...state, reviewsAsRenter: helpers.makeLoadFailed(error) };
    case TYPES.USERS_BYORGANIZATIONID_REQUEST:
      return { ...state, usersByOrganizationId: helpers.makeStartLoading() };
    case TYPES.USERS_BYORGANIZATIONID_SUCCESS:
      return {
        ...state,
        usersByOrganizationId: helpers.makeLoadSuccess(payload),
      };
    case TYPES.USERS_BYORGANIZATIONID_FAIL:
      return { ...state, usersByOrganizationId: helpers.makeLoadFailed(error) };
    // SET_SOCIALID
    case TYPES.SET_SOCIALID:
      return { ...state, socialId: payload };
    case TYPES.SET_IS_FROM_RUGDOCTOR:
      return { ...state, isFromRugDoctor: payload };
    case TYPES.PROFILED_PRODUCTS_BY_CATEGORYID_REQUEST:
      return {
        ...state,
        profiledProductsByCategoryId: helpers.makeStartLoading(),
      };
    case TYPES.PROFILED_PRODUCTS_BY_CATEGORYID_SUCCESS:
      return {
        ...state,
        profiledProductsByCategoryId: helpers.makeLoadSuccess(payload),
      };
    case TYPES.PROFILED_PRODUCTS_BY_CATEGORYID_FAIL:
      return {
        ...state,
        profiledProductsByCategoryId: helpers.makeLoadFailed(error),
      };
    default:
      return state;
  }
};

export const getRegion = (state) => state.user.region;
export const getRegions = (state) => state.user.regions;
export const getZipFromLatLong = (state) =>
  (state.user && state.user.zipFromService) || "";

// isZipServicedByProduct
export const getIsZipServicedByProduct = (state) =>
  (state.user && state.user.isZipServicedByProduct) || "";

export const getIsCurrentLocationServiceable = (state) =>
  (state.user && state.user.isCurrentLocationServiceable) || "";
export const getCustomerReviewsAsAuthor = (state) =>
  state.user.customerReviewsAsAuthor &&
  state.user.customerReviewsAsAuthor.isLoaded
    ? state.user.customerReviewsAsAuthor.data.items
    : [];
export const getMerchantReviewsAsAuthor = (state) =>
  state.user.merchantReviewsAsAuthor &&
  state.user.merchantReviewsAsAuthor.isLoaded
    ? state.user.merchantReviewsAsAuthor.data.items
    : [];
export const getDriverReviewsAsAuthor = (state) =>
  state.user.driverReviewsAsAuthor && state.user.driverReviewsAsAuthor.isLoaded
    ? state.user.driverReviewsAsAuthor.data.items
    : [];
export const getCurrentCustomerReview = (state) => state.user.customerReview;
export const getCurrentMerchantReview = (state) => state.user.merchantReview;
export const getCurrentDriverReview = (state) => state.user.driverReview;

export const getCustomerReviewModalState = (state) =>
  state.user.isCustomerReviewModalOpen;
export const getMerchantReviewModalState = (state) =>
  state.user.isMerchantReviewModalOpen;
export const getDriverReviewModalState = (state) =>
  state.user.isDriverReviewModalOpen;

export const getUserInfoById = (state) => state.user.infoById;
export const getUserReviewsAsOwner = (state) => state.user.reviewsAsOwner;
export const getUserReviewsAsRenter = (state) => state.user.reviewsAsRenter;
export const getUsersByOrganizationId = (state) =>
  state.user.usersByOrganizationId;
export const getSkippedFlags = (state) => state.user.skippedFlags;
export const getCompleteProfileReturnUrl = (state) => state.user.returnUrl;
export const getSocialId = (state) => state.user.socialId;
export const getIsFromRugDoctor = (state) => state.user.isFromRugDoctor;

export const getProfiledProductsByCategoryId = (state) =>
  state.user.profiledProductsByCategoryId;

export default userReducer;
