import * as TYPES from "../types";
import * as helpers from "../utils";

export default (
  state = {
    myorders: helpers.makeInitial(),
    orderById: helpers.makeInitial(),
    allorders: helpers.makeInitial(),
    subRoutes: helpers.makeInitial([]),
    subRoutesIsLoading: false,
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.ORDERS_FOR_CURRENT_USER_REQUEST:
      return { ...state, myorders: helpers.makeStartLoading() };
    case TYPES.ORDERS_FOR_CURRENT_USER_SUCCESS:
      return { ...state, myorders: helpers.makeLoadSuccess(payload) };
    case TYPES.ORDERS_FOR_CURRENT_USER_FAIL:
      return { ...state, myorders: helpers.makeLoadFailed(error) };
    //-----------
    case TYPES.ALL_ORDERS_FOR_ADMIN_USER_REQUEST:
      return { ...state, allorders: helpers.makeStartLoading() };
    case TYPES.ALL_ORDERS_FOR_ADMIN_USER_SUCCESS:
      return { ...state, allorders: helpers.makeLoadSuccess(payload) };
    case TYPES.ALL_ORDERS_FOR_ADMIN_USER_FAIL:
      return { ...state, allorders: helpers.makeLoadFailed(error) };
    //-----------
    case TYPES.ALL_ORDERS_BY_ORDER_ID_REQUEST:
      return { ...state, allorders: helpers.makeStartLoading() };
    case TYPES.ALL_ORDERS_BY_ORDER_ID_SUCCESS:
      return { ...state, allorders: helpers.makeLoadSuccess(payload) };
    case TYPES.ALL_ORDERS_BY_ORDER_ID_FAIL:
      return { ...state, allorders: helpers.makeLoadFailed(error) };
    //-----------
    case TYPES.CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        myorders: { ...state.myorders, isInvalidated: true },
        allorders: { ...state.allorders, isInvalidated: true },
      };
    // -----------------------
    case TYPES.COMPLETE_ORDER_SUCCESS:
      return {
        ...state,
        myorders: { ...state.myorders, isInvalidated: true },
        allorders: { ...state.allorders, isInvalidated: true },
      };
    // -----------------
    case TYPES.ORDER_BY_ID_REQUEST:
      return { ...state, orderById: helpers.makeStartLoading() };
    case TYPES.ORDER_BY_ID_CLEANUP:
      return { ...state, orderById: helpers.makeInitial() };
    // ----------------
    case TYPES.SUB_ROUTES_IS_LOADING_BY_ORDER_ID:
      return { ...state, subRoutesIsLoading: true };
    case TYPES.SUB_ROUTES_IS_LOADING_SUCCESS_BY_ORDER_ID:
      return { ...state, subRoutesIsLoading: false };
    // ----------------
    case TYPES.ORDER_BY_ID_SUCCESS:
      return { ...state, orderById: helpers.makeLoadSuccess(payload) };
    case TYPES.ORDER_BY_ID_FAIL:
      return { ...state, orderById: helpers.makeLoadFailed(error) };
    // -----------------
    case TYPES.REFRESH_GPS_FOR_ORDER_REQUEST:
      return { ...state, refreshedGPS: helpers.makeStartLoading() };
    case TYPES.REFRESH_GPS_FOR_ORDER_SUCCESS:
      return { ...state, refreshedGPS: helpers.makeLoadSuccess(payload) };
    case TYPES.REFRESH_GPS_FOR_ORDER_FAIL:
      return { ...state, refreshedGPS: helpers.makeLoadFailed(error) };
    // Orders Sub Routes ---
    case TYPES.ORDER_SUB_ROUTES_BY_ID_REQUEST:
      return { ...state, subRoutes: helpers.makeStartLoading() };
    case TYPES.ORDER_SUB_ROUTES_BY_ID_UPDATE:
      return {
        ...state,
        subRoutes: helpers.makeStartLoading({ data: state.subRoutes.data }),
      };
    case TYPES.ORDER_SUB_ROUTES_BY_ID_SUCCESS:
      return { ...state, subRoutes: helpers.makeLoadSuccess(payload) };
    case TYPES.ORDER_SUB_ROUTES_BY_ID_FAIL:
      return { ...state, subRoutes: helpers.makeLoadFailed(error) };
    case TYPES.ORDER_STATUS_HISTORY_BY_ID:
      return {
        ...state,
        orderById: {
          ...state.orderById,
          data: {
            ...state.orderById.data,
            statusHistory: payload.statusHistory || [],
            activeStatusDisplay: payload.activeStatusDisplay,
            activeStatus: payload.activeStatus,
          },
        },
      };

    // ORDER_PROMO_CODE
    case TYPES.ORDER_PROMO_CODE:
      return { ...state, orderPromoCode: payload };
    default:
      return state;
  }
};

export const getOrdersForCurrentUser = (state) => state.orders.myorders;
export const getAllOrdersForAdminUser = (state) => state.orders.allorders;
export const getOrderById = (state) => state.orders.orderById;
export const getRefreshedGPSForOrder = (state) => state.orders.refreshedGPS;
export const getOrderPromoCode = (state) => state.orders.orderPromoCode;
export const getOrderSubRoutes = (state) => state.orders.subRoutes?.data;
export const getOrderStatusHistory = (state) => ({
  statusHistory: state.orders.orderById?.data?.statusHistory || [],
  activeStatusDisplay: state.orders.orderById?.data?.activeStatusDisplay || "",
  activeStatus: state.orders.orderById?.data?.activeStatus || 10,
});
export const getSubRoutesIsLoading = (state) =>
  state?.orders.subRoutesIsLoading;
export const routesIsFetching = (state) => state.orders.subRoutes.isFetching;
