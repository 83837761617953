import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { imageServerUrl } from "../../../utils";

const useStyles = makeStyles({
  detailsRoot: {
    display: "block",
  },
  expanded: {
    color: "#F05623",
  },
});

export default function HowItWorksAccordion({ summary, details }) {
  const styles = useStyles();
  return (
    <Accordion>
      <AccordionSummary
        classes={{
          expanded: styles.expanded,
        }}
        expandIcon={
          <img
            src={`${imageServerUrl}/ico-plus.svg`}
            className="more-less"
            alt=""
          />
        }
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.detailsRoot,
        }}
      >
        {details}
      </AccordionDetails>
    </Accordion>
  );
}
