import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment-timezone";

import { showSnackbar } from "../../../actions/snackbar";
import { getToken } from "../../../reducers/authReducer";
import { getRegions } from "../../../reducers/userReducer";
import { serverUrl } from "../../../utils";

export const selectDrivers = (state) => state.dmsDrivers.drivers;
export const selectAllDrivers = (state) => state.dmsDrivers.allDrivers;
export const selectDriverMeta = (state) => state.dmsDrivers.driverMeta;
export const selectDriversFetching = (state) => state.dmsDrivers.driverFetching;
export const selectDriversError = (state) => state.dmsDrivers.driverError;
export const selectDriverById = (state) => state.dmsDrivers.driverById;
export const reportsEmailTemplate = (state) => state.dmsDrivers.emailTemplates;
export const selectDriverEvents = (state) => state.dmsDrivers.driverEvents;

export const selectIsCreatingEvent = (state) =>
  state.dmsDrivers.isCreatingEvent;

export const selectKeyedDriverPaymentNotes = (state) => {
  const withWeekAndYear = state.dmsDrivers.paymentNotes.map((pn) => ({
    ...pn,
    weekAndYear: `${pn.weekNumber}-${pn.year}`,
  }));
  return withWeekAndYear.reduce((acc, cv) => {
    return {
      ...acc,
      [cv.weekAndYear]: acc[cv.weekAndYear]
        ? [...acc[cv.weekAndYear], cv]
        : [cv],
    };
  }, {});
};
export const selectDriverPaymentNotes = (state) =>
  state.dmsDrivers.paymentNotes;

export const selectDriverNotes = (state) => state.dmsDrivers.notes;

export const selectSentItems = (state) => state.dmsDrivers.sentItems;
export const selectGeneratedEmail = (state) => state.dmsDrivers.generatedEmail;
export const isGeneratedEmailFetching = (state) =>
  state.dmsDrivers.isGeneratedEmailFetching;

export const selectDriverReports = (state) => state.dmsDrivers.driverReports;
export const selectDriversReportsMeta = (state) =>
  state.dmsDrivers.driverReportsMeta;

export const selectChosenDriversReports = (state) =>
  state.dmsDrivers.chosenDriversReports;

export const selectsReportsFetching = (state) =>
  state.dmsDrivers.isReportsFetching;

export const selectNotesFetching = (state) => state.dmsDrivers.isNoteFetching;

export const sortRegAndTeams = (
  arrayForSort = [],
  searchingArray = [],
  type = "region"
) => {
  return arrayForSort
    .map((el) => {
      const _foundedElement = searchingArray.find((_element) => {
        if (type === "region") {
          return el === _element.id;
        }
        return null;
      });
      return {
        name: _foundedElement ? _foundedElement.name : "",
        regionId: el,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getDrivers = createAsyncThunk(
  "dmsDrivers/getDrivers",
  async (
    {
      isActiveDrivers,
      pageNumber = 1,
      pageSize = 25,
      chosenTeam = 0,
      searchByName = "",
    },
    thunkAPI
  ) => {
    const jwt = getToken(thunkAPI.getState());
    const params = {
      pageNumber,
      pageSize,
    };
    if (isActiveDrivers !== undefined) {
      params.isActive = isActiveDrivers;
    }

    if (chosenTeam) {
      params.regTeamId = chosenTeam;
    }

    if (searchByName) {
      params.search = searchByName;
    }

    try {
      const res = await axios.get(`${serverUrl}/api/v1/driver/drivers`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params,
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      );
      throw e;
    }
  }
);

export const getAllDrivers = createAsyncThunk(
  "dmsDrivers/getAllDrivers",
  async ({ type = null }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const params = {};
    if (type !== null) {
      params.isActive = type;
    }

    try {
      const res = await axios.get(`${serverUrl}/api/v1/driver/all`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params,
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(e.response && e.response.data) || e.message,
        "error",
        null,
        null,
        JSON.stringify(e)
      );
      throw e;
    }
  }
);

export const getDriverById = createAsyncThunk(
  "dmsDrivers/getById",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/driver/profile/${values.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const _regions = getRegions(thunkAPI.getState());

      res.data.regionIds = sortRegAndTeams(
        res.data.regionIds,
        _regions,
        "region"
      );

      res.data.hourlyRateCents = res.data.hourlyRateCents
        ? res.data.hourlyRateCents / 100
        : null;

      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const createDriver = createAsyncThunk(
  "dmsDrivers/createDriver",
  async (value, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const regionTeamIds = value.extTeamIds;
    const { id, extDriverId, regionIds, extTeamIds, phone, ...rest } = {
      ...value,
    };
    const hourlyRateCents = !value.hourlyRateCents
      ? null
      : value.hourlyRateCents * 100;

    const matches = phone.replace(/[^0-9]/g, "");

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/profile`,
        {
          ...rest,
          phone: matches,
          dob: moment(rest.dob).toISOString(true).substring(0, 10),
          dlExpiration: rest.dlExpiration
            ? moment(rest.dlExpiration).toISOString(true).substring(0, 10)
            : null,
          regionTeamIds,
          hourlyRateCents,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      const exception = {
        ...e,
        meta: {
          ...e.meta,
          arg: {
            ...value,
          },
        },
      };
      throw exception;
    }
  }
);

export const updateDriver = createAsyncThunk(
  "dmsDrivers/updateDriver",
  async (value, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const { regionIds, extTeamIds, phone, ...rest } = { ...value };
    const matches = phone.replace(/[^0-9]/g, "");
    const regionTeamIds = extTeamIds;

    const hourlyRateCents = !value.hourlyRateCents
      ? null
      : value.hourlyRateCents * 100;

    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/driver/profile`,
        {
          ...rest,
          phone: matches,
          dob: rest.dob
            ? moment(rest.dob).toISOString(true).substring(0, 10)
            : null,
          dlExpiration: rest.dlExpiration
            ? moment(rest.dlExpiration).toISOString(true).substring(0, 10)
            : null,
          regionTeamIds,
          hourlyRateCents,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      const exception = {
        ...e,
        meta: {
          ...e.meta,
          arg: {
            ...value,
          },
        },
      };
      throw exception;
    }
  }
);

export const createDriverEvent = createAsyncThunk(
  "dmsDrivers/createDriverEvent",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/calendar/driver/${values.driverId}`,
        values.params,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  }
);

export const getDriverEvents = createAsyncThunk(
  "dmsDrivers/getDriverEvents",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const params = {
      beginUtc: values.beginDt,
      endUtc: values.endDt,
      region: values.region,
    };

    if (values.entryType) {
      params.entryType = values.entryType;
    }

    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/calendar/driver/${values.calendarId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params,
        }
      );
      const _data = res.data.map((el) => ({
        ...el,
        eventType: `${el.entryType === 11 ? "GIG" : "PRIMARY"}`,
        eventDates: ` ${moment
          .utc(el.localBeginDt)
          .format("MM/DD/YYYY LT")} - ${moment
          .utc(el.localEndDt)
          .format("LT")}`,
        start: moment(el.localBeginDt).format("MM/DD/YYYY hh:mm a"),
        end: moment(el.localEndDt).format("MM/DD/YYYY hh:mm a"),
      }));
      return _data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "dmsDrivers/deleteEvent",
  async ({ eventId, deleteThisOnly, dt }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.delete(
        `${serverUrl}/api/v1/calendar/driver/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            deleteThisOnly,
            dt,
          },
        }
      );

      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
    }
  }
);

export const activateDriver = createAsyncThunk(
  "dmsDriver/activate",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/activate`,
        {
          driversIds: values.driverIds,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const deactivateDriver = createAsyncThunk(
  "dmsDriver/deactivate",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/deactivate`,
        {
          driversIds: values.driverIds,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDriverNotes = createAsyncThunk(
  "drivers/getNotes",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/Note/drivernotes/${values.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);
export const getDriverPaymentNotes = createAsyncThunk(
  "dmsDrivers/getPaymentNotes",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/driver/${values.id}/paymentnotes`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);
export const createDriverPaymentNote = createAsyncThunk(
  "drivers/createPaymentNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    values.fkOrderId = !values.fkOrderId ? null : Number(values.fkOrderId);

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/paymentnotes`,
        {
          ...values,
          isDelivery: values.isDelivery === "other" ? null : values.isDelivery,
          dtDateAndTime: moment(values.dtDateAndTime)
            .tz("America/New_York", true)
            .toISOString(false)
            .slice(0, -1),
          fkResourceRequestId: null,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(showSnackbar("Payment note has been added", "success"));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const updatePaymentNote = createAsyncThunk(
  "dmsDrivers/updatePaymentNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/driver/paymentnotes`,
        {
          ...values.note,
          fkResourceRequestId: null,
          dtDateAndTime: moment(values.note.dtDateAndTime)
            .tz("EST", true)
            .toISOString(false)
            .slice(0, -1),
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      await thunkAPI.dispatch(getDriverPaymentNotes({ id: values.driverId }));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const createDriverNote = createAsyncThunk(
  "dmsDrivers/updatePaymentNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/Note/drivernotes`,
        {
          sourceId: values.fkDriverId,
          note: values.note,
          created: moment().utc().toISOString(false).slice(0, -1),
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getDriverNotes({ id: values.fkDriverId }));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const getSentItems = createAsyncThunk(
  "dmsDrivers/getSentItems",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/driver/payouts/${values.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const getSentItemEmail = createAsyncThunk(
  "dmsDrivers/getSentItemEmail",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/driver/weeklypayout/${values.id}/regenerate`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const resendSentItem = createAsyncThunk(
  "dmsDrivers/resendSentItem",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/weeklypayout/resend`,
        {
          id: values.id,
          email: values.email,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const getDriverReports = createAsyncThunk(
  "dmsDrivers/getDriverReports",
  async (
    { pageNumber = 1, pageSize = 25, chosenTeam = 0, from, to, search = "" },
    thunkAPI
  ) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(`${serverUrl}/api/v1/driver/report`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageNumber,
          pageSize,
          chosenTeam,
          from: moment(from).utc().startOf("day").toISOString(),
          to: moment(to).utc().startOf("day").toISOString(),
          search,
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const getChosenDriverReports = createAsyncThunk(
  "dmsDrivers/getChosenDriverReports",
  async ({ driverIds, from, to }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/driver/payouts/generate`,
        {
          driverIds,
          from: moment(from).utc().startOf("day").toISOString(),
          to: moment(to).utc().startOf("day").toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const sendChosenDriversReports = createAsyncThunk(
  "dmsDrivers/sendChosenDriversReports",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      await axios.post(
        `${serverUrl}/api/v1/driver/payouts/send`,
        {
          reports: values.reports,
          templateHtml: values.templateHtml,
          subjectTemplate: values.subjectTemplate,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
    } catch (e) {
      thunkAPI.dispatch(showSnackbar(e.message, "error"));
      throw e;
    }
  }
);

export const createNoteByDate = createAsyncThunk(
  "dmsDrivers/createNoteByDate",
  async (values, thunkAPI) => {
    console.log(values, "createNoteByDate");
    const jwt = getToken(thunkAPI.getState());
    try {
      await axios.get(`${serverUrl}/api/v1/jobs/primaryblockpayments`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          securityToken: "ef9e2cd8-f9dd-4ac6-8058-2ed22b95522a",
          _utcDay: moment(values.date.value).format("YYYY-MM-DD"),
        },
      });
      values.setOpenDateDialog(false);
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const deletePaymentNote = createAsyncThunk(
  "drivers/deletePaymentNote",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.delete(
        `${serverUrl}/api/v1/driver/paymentnotes/${values.note.id}`,

        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getDriverPaymentNotes({ id: values.driverId }));
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "dmsDrivers",
  initialState: {
    drivers: [],
    driverEvents: [],
    allDrivers: [],
    driverFetching: true,
    isNoteFetching: false,
    driverError: null,
    driverMeta: {
      pageNumber: 1,
      pageSize: 25,
      totalPages: 0,
      totalItems: 0,
    },
    driverById: {},
    paymentNotes: [],
    notes: [],
    sentItems: [],
    generatedEmail: {},
    isGeneratedEmailFetching: false,
    driverReports: [],
    emailTemplates: {
      emailBodyTemplate: "",
      emailSubjectTemplate: "",
    },
    driverReportsMeta: {
      pageNumber: 1,
      totalPages: 0,
      pageSize: 25,
      totalItems: 0,
    },
    chosenDriversReports: [],
    isCreatingEvent: false,
  },
  reducers: {
    cleanGeneratedEmail(state) {
      state.generatedEmail = {};
      state.isGeneratedEmailFetching = false;
    },
    disableFetching(state) {
      state.driverFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrivers.pending, (state) => {
        state.driverFetching = true;
        state.driverError = null;
      })
      .addCase(getDrivers.fulfilled, (state, { payload }) => {
        state.driverFetching = false;
        state.driverError = null;
        state.drivers = payload.drivers;
        state.driverMeta.pageNumber = payload.pageNumber;
        state.driverMeta.pageSize = payload.pageSize;
        state.driverMeta.totalPages = payload.totalPages;
        state.driverMeta.totalItems = payload.totalItems;
      })
      .addCase(getDrivers.rejected, (state, { payload }) => {
        state.driverFetching = false;
        state.driverError = payload;
        state.drivers = [];
        state.driverMeta.pageNumber = 1;
        state.driverMeta.pageSize = 25;
        state.driverMeta.totalPages = 0;
        state.driverMeta.totalItems = 0;
      })
      .addCase(getAllDrivers.fulfilled, (state, { payload }) => {
        state.allDrivers = payload;
      })
      .addCase(getAllDrivers.rejected, (state) => {
        state.allDrivers = [];
      })
      .addCase(getDriverById.pending, (state) => {
        state.driverFetching = true;
        state.error = false;
        state.driverById = {};
      })
      .addCase(getDriverById.fulfilled, (state, { payload }) => {
        state.driverFetching = false;
        state.error = false;
        // state.meta.pageNumber = payload.pageNumber;
        // state.meta.pageSize = payload.pageSize;
        // state.meta.totalPages = payload.totalPages;
        state.driverById = payload;
      })
      .addCase(getDriverNotes.pending, (state) => {
        state.isNoteFetching = true;
      })
      .addCase(getDriverNotes.fulfilled, (state, { payload }) => {
        state.notes = payload;
        state.isNoteFetching = false;
      })
      .addCase(getDriverNotes.rejected, (state) => {
        state.isNoteFetching = false;
      })
      .addCase(updatePaymentNote.pending, (state) => {
        state.driverFetching = true;
      })
      .addCase(updatePaymentNote.rejected, (state) => {
        state.driverFetching = false;
      })
      .addCase(updatePaymentNote.fulfilled, (state) => {
        state.driverFetching = false;
      })
      .addCase(getDriverPaymentNotes.fulfilled, (state, { payload }) => {
        state.paymentNotes = payload;
        // state.driverFetching = false;
      })
      .addCase(getSentItems.fulfilled, (state, { payload }) => {
        state.sentItems = payload;
      })
      .addCase(getSentItemEmail.pending, (state) => {
        state.isGeneratedEmailFetching = true;
      })
      .addCase(getSentItemEmail.fulfilled, (state, { payload }) => {
        state.generatedEmail = payload;
        state.isGeneratedEmailFetching = false;
      })
      .addCase(resendSentItem.pending, (state) => {
        state.isGeneratedEmailFetching = true;
      })
      .addCase(resendSentItem.fulfilled, (state) => {
        state.isGeneratedEmailFetching = false;
      })
      .addCase(getDriverReports.pending, (state) => {
        state.isReportsFetching = true;
      })
      .addCase(getDriverReports.rejected, (state) => {
        state.isReportsFetching = false;
        state.driverReports = [];
        state.driverReportsMeta.pageNumber = 1;
        state.driverReportsMeta.totalItems = 0;
        state.driverReportsMeta.totalPages = 0;
        state.driverReportsMeta.pageSize = 25;
      })
      .addCase(getDriverReports.fulfilled, (state, { payload }) => {
        state.driverReports = payload.drivers;
        state.driverReportsMeta.pageNumber = payload.pageNumber;
        state.driverReportsMeta.totalItems = payload.totalItems;
        state.driverReportsMeta.pageSize = payload.pageSize;
        state.driverReportsMeta.totalPages = payload.totalPages;
        state.isReportsFetching = false;
      })
      .addCase(getChosenDriverReports.fulfilled, (state, { payload }) => {
        state.isReportsFetching = false;
        state.chosenDriversReports = payload.reports;
        state.emailTemplates = {
          emailBodyTemplate: payload.templateHtml,
          emailSubjectTemplate: payload.subjectTemplate,
        };
      })
      .addCase(getChosenDriverReports.pending, (state) => {
        state.isReportsFetching = true;
      })
      .addCase(sendChosenDriversReports.pending, (state) => {
        state.isReportsFetching = true;
      })
      .addCase(sendChosenDriversReports.fulfilled, (state) => {
        state.isReportsFetching = false;
        state.chosenDriversReports = [];
        state.emailTemplates = {
          emailBodyTemplate: "",
          emailSubjectTemplate: "",
        };
      })
      .addCase(sendChosenDriversReports.rejected, (state) => {
        state.isReportsFetching = false;
      })
      .addCase(createDriver.pending, (state) => {
        state.driverFetching = true;
      })
      .addCase(createNoteByDate.pending, (state) => {
        state.isNoteFetching = true;
      })
      .addCase(createNoteByDate.rejected, (state) => {
        state.isNoteFetching = false;
      })
      .addCase(createNoteByDate.fulfilled, (state) => {
        state.isNoteFetching = false;
      })
      .addCase(createDriver.fulfilled, (state) => {
        state.driverFetching = false;
      })
      .addCase(createDriver.rejected, (state) => {
        state.driverFetching = true;
      })
      .addCase(updateDriver.pending, (state) => {
        state.driverFetching = true;
      })
      .addCase(updateDriver.fulfilled, (state) => {
        state.driverFetching = false;
      })
      .addCase(updateDriver.rejected, (state) => {
        state.driverFetching = true;
      })
      .addCase(createDriverEvent.pending, (state) => {
        state.isCreatingEvent = true;
      })
      .addCase(createDriverEvent.rejected, (state) => {
        state.isCreatingEvent = false;
      })
      .addCase(createDriverEvent.fulfilled, (state) => {
        state.isCreatingEvent = false;
      })
      .addCase(getDriverEvents.fulfilled, (state, { payload }) => {
        state.driverEvents = payload;
      })
      .addCase(getDriverEvents.rejected, (state) => {
        state.driverEvents = [];
      });
  },
});

export const { cleanGeneratedEmail, disableFetching } = slice.actions;

export default slice.reducer;
