import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { connect } from "react-redux";
import { getUser, getIsLoggedIn } from "../../reducers/authReducer";
import { imageServerUrl } from "../../utils";
import SocialShareButtons from "./SocialShareButtons";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    overflow: "auto",
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(45),
      padding: theme.spacing(2),
      maxHeight: "100%",
      overflow: "scroll",
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(50),
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(69),
      padding: theme.spacing(5),
    },
  },
});

class SocialShare extends Component {
  static propTypes = {
    current_user: PropTypes.shape({
      avatar: PropTypes.string,
      first_name: PropTypes.string,
      _id: PropTypes.string,
    }).isRequired,
    params: PropTypes.shape({}),
  };

  static defaultProps = {
    params: {
      mode: "invite",
      link: "https://marketplace.skipti.com",
      productName: "",
    },
  };

  state = {};

  render() {
    const { classes, current_user, params } = this.props;
    const mode = params && params.mode;
    const { link } = params;
    const { productName } = params;

    return (
      <>
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%)`,
          }}
          tabIndex={-1}
          className={classes.paper}
        >
          <button
            type="button"
            className="float-right close-button-modal"
            onClick={this.props.onClose}
          >
            <i className="fas fa-times" />
          </button>
          <div>
            <div className="tw-flex tw-justify-center">
              <img
                src={`${imageServerUrl}/logo-skipti-vertical.jpg`}
                alt="skipti logo"
                className="m_6073652373047506906fr-fic m_6073652373047506906fr-dib CToWUd"
              />
            </div>
            {mode === "register" ? (
              <>
                <p style={{ textAlign: "center" }}>
                  Thanks for signing up for Skipti! &nbsp;
                </p>
                {current_user.skiptiCredit &&
                current_user.skiptiCredit.amount > 0 ? (
                  <p style={{ textAlign: "center" }}>
                    <strong>
                      <span style={{ fontSize: "20px" }}>
                        WE&apos;VE ADDED A ${current_user.skiptiCredit.amount}{" "}
                        CREDIT TO YOUR ACCOUNT
                      </span>
                    </strong>
                  </p>
                ) : null}
              </>
            ) : null}
            <p style={{ textAlign: "center" }}>
              <strong>
                <span style={{ fontSize: "20px" }}>
                  <span style={{ color: "rgb(226,80,65)" }}>
                    <u>SEND $20, EARN $20&nbsp;</u>
                  </span>
                </span>
              </strong>
            </p>
            <p className="small" style={{ textAlign: "center" }}>
              <em>
                {`Send a friend $20 to rent  ${
                  mode === "product" ? "this item" : "an item"
                }. Your account will be credited after they complete their first rental with Skipti.`}
              </em>
            </p>
            <p style={{ textAlign: "center" }}>
              Use your personal share links:
            </p>

            <SocialShareButtons link={link} productName={productName} />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  current_user: getUser(state),

  isLoggedIn: getIsLoggedIn(state),
});
export default connect(mapStateToProps, null)(withStyles(styles)(SocialShare));
