import React from "react";
import GoogleLogin from "./GoogleLogin";
import FacebookLogin from "./FacebookLogin";

export default function SocialLogins(props) {
  const { authenticate } = props;

  return (
    <div className="tw-mt-4 tw-mx-auto" style={{ width: "304px" }}>
      <FacebookLogin authenticate={authenticate} />
      <GoogleLogin authenticate={authenticate} />
    </div>
  );
}
