import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import MixpanelContext from "react-mixpanel";
import { getIsLoggedIn } from "../../reducers/authReducer";
import { getCategories } from "../../reducers/categoriesReducer";
import {
  getProductsForAllCategories,
  getProductsVotable,
} from "../../reducers/itemsReducer";
import LayoutPublic from "../layouts/LayoutPublic";
import ItemTile from "./ItemTile";
import HeroDiscover from "./HeroDiscover";
import {
  fetchCategories,
  fetchProductsForAllCategories,
  fetchExperiencesAll,
} from "../../actions";
import { getExperiences } from "../../reducers/experiencesReducer";
import ExperienceTile from "./ExperienceTile";
import VotingRow from "../voting/VoteItems";
import CategoryList from "./CategoryList";
import InventoryInterest from "./InventoryInterest";
import ProductsInRegion from "./ProductsInRegion";
import { getRegion } from "../../reducers/userReducer";

const noOfProducts = 100;

// endregion props
class Discover extends Component {
  static propTypes = {
    fetchCategories: PropTypes.func.isRequired,
    fetchExperiencesAll: PropTypes.func.isRequired,
    fetchProductsForAllCategories: PropTypes.func.isRequired,
    productCategories: PropTypes.shape({}).isRequired,
    experiences: PropTypes.shape({
      data: PropTypes.shape({
        resources: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      isLoaded: PropTypes.bool,
    }),
    products: PropTypes.shape({}),
  };

  static defaultProps = {
    experiences: {},
    products: {},
  };

  static contextType = MixpanelContext;

  state = {
    pagenumber: 0,
    items: [],
    votingRowPopulated: false,
  };

  static getDerivedStateFromProps = (props, state) => ({
    items: _(state.items)
      .keyBy((item) => item.category.id)
      .merge(
        _.keyBy(
          props.products && props.products.isLoaded ? props.products.data : [],
          (prod) => prod.category.id
        )
      )
      .sortBy((item) => item.category.displayOrder)
      .values()
      .value(),
    // items: [
    //   ...state.items,
    //   ...(props.products && props.products.isLoaded
    //     ? props.products.data
    //     : [])
    // ]
  });

  async componentDidMount() {
    this.context.track("Discover");
    window.analytics.page("Discover");

    if (!this.props.productCategories) {
      this.props.fetchCategories();
    }
    this.loadMore();
    if (
      !this.props.experiences ||
      (this.props.experiences && !this.props.experiences.data)
    ) {
      this.props.fetchExperiencesAll();
    }
  }

  setVotingRowPopulated = (populated) => {
    this.setState({ votingRowPopulated: populated });
  };

  loadMore = () => {
    const { pagenumber } = this.state;
    this.props.fetchProductsForAllCategories({
      pagesize: noOfProducts,
      pagenumber: pagenumber + 1,
    });
    this.setState({
      pagenumber: pagenumber + 1,
    });
  };

  handleShowMoreClick = (categoryName) => {
    this.context.track("Discover>clicked Show all", {
      showAllCategory: categoryName,
    });
    window.analytics.track("Discover clicked Show all", {
      showAllCategory: categoryName,
    });
  };

  render() {
    const { experiences } = this.props;
    const experiencesData =
      experiences && experiences.isLoaded ? experiences.data.teasers : [];
    const { items, votingRowPopulated } = this.state;
    //
    const itemsOnlyNotEmpty = items.filter(
      (category) =>
        category.resources.length !== 0 &&
        category.category.displayName !== "Fashion"
    );

    return (
      <LayoutPublic showSearch>
        <>
          {/* {productCategories.isLoaded ? (
            <CategoryList categories={productCategories} />
          ) : null} */}

          <div className="sticky-categories">
            <CategoryList categories={itemsOnlyNotEmpty} />
          </div>
          <HeroDiscover />

          <div className="tw-mt-12 content">
            {this.props.region && this.props.region.id !== 2 ? (
              <>
                <div className="tw-w-full tw-px-4 tw-mx-auto tw-mt-12 tw-p-4">
                  <ProductsInRegion noProducts={6} />
                </div>
                {/* <h2>
                  Here are some products that are available in other regions.
                </h2> */}
              </>
            ) : null}

            {itemsOnlyNotEmpty.length === 0 && (
              <div className="tw-w-full tw-px-4 tw-mx-auto tw-my-12 tw-p-4">
                <div className="tw-flex tw-flex-wrap tw-my-4">
                  {Array(4)
                    .fill({})
                    .map((item, index) => (
                      <ItemTile
                        {...item}
                        index={index}
                        isDiscover
                        notResponsive={false}
                        noOfProducts={noOfProducts}
                        // myItems={this.props.myItems}
                        key={index}
                      />
                    ))}
                </div>
              </div>
            )}
            {/* Products for categories */}
            {itemsOnlyNotEmpty.map((category, index) => (
              <React.Fragment key={category.category.id}>
                {category.resources && (
                  <>
                    <div
                      style={{
                        height: "1px",
                        position: "relative",
                        top: "-130px",
                      }}
                      id={`${category.category.displayName}`}
                    />
                    <div className="tw-w-full tw-px-4 tw-p-4 tw-my-12">
                      <h3>
                        {category.category.displayName === "Photo&Video"
                          ? "Photo & Video"
                          : category.category.displayName}
                      </h3>

                      <div className="tw-flex tw-flex-wrap tw-my-4">
                        {category.resources.map((item, indexItems) => (
                          <ItemTile
                            {...item}
                            noOfProducts={noOfProducts}
                            index={indexItems}
                            key={item.resourceId}
                          />
                        ))}
                      </div>
                      {/* <Link
                        to={`/categories/${category.category.displayName}`}
                        href={`/categories/${category.category.displayName}`}
                        // className="float-right tw-text-skiptiOrange show-all"
                        className="btn btn-skipti-orange float-left show-all"
                        onClick={() =>
                          this.handleShowMoreClick(
                            category.category.displayName
                          )
                        }
                      >
                        Show All <i className="fal fa-angle-down ml-2" />
                      </Link> */}
                    </div>
                    {/* {(index + 1) % 3 === 0 && index !== 0 && (
                      <div className="tw-w-full tw-my-12 tw-p-4">
                        <h3>Experiences</h3>
                        <div className="tw-flex tw-flex-wrap tw-my-4">
                        
                          {experiencesData
                            .slice((index + 1) / 3, (index + 1) / 3 + 3)
                            .map((article) => (
                              <ExperienceTile
                                noOfTiles={3}
                                article={article}
                                key={article.id}
                              />
                            ))}
                        </div>
                      </div>
                    )} */}
                  </>
                )}
              </React.Fragment>
            ))}
            {/* {this.props.votableItems && this.props.votableItems.length > 0 ? ( */}
            {/* <div className="container-fluid my-5 px-3"> */}
            {/* {votingRowPopulated && <h3 className="p-3">Coming soon</h3>}
            <div className="tw-mx-0 tw-my-3 tw-flex tw-flex-wrap">
            
              <VotingRow setVotingRowPopulated={this.setVotingRowPopulated} />
            </div> */}
          </div>
          {itemsOnlyNotEmpty.length !== 0 && <InventoryInterest />}
          {/* ) : null} */}
          {/* </div> */}
          {/* <div className="row justify-content-center m-5">
            <SkiptiButton onClick={this.loadMore} primary>
              Load more
            </SkiptiButton>
          </div> */}
        </>
      </LayoutPublic>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
  productCategories: getCategories(state) || [],
  products: getProductsForAllCategories(state),
  experiences: getExperiences(state),
  votableItems: getProductsVotable(state),
  region: getRegion(state),
});

export default connect(mapStateToProps, {
  fetchCategories,
  fetchProductsForAllCategories,
  fetchExperiencesAll,
})(Discover);
