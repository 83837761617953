import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { showSnackbar } from "../../actions/snackbar";
import { getToken } from "../../reducers/authReducer";
import { serverUrl } from "../../utils";

export const selectTeamMembersRecords = (state) =>
  state.teamMembers.teamMembersRecord;
export const selectTeamMembersFetching = (state) => state.teamMembers.fetching;
export const selectTeamMembersError = (state) => state.teamMembers.error;
export const selectTeamMembersMeta = (state) => state.teamMembers.meta;
export const selectUpdateMemberFetching = (state) =>
  state.teamMembers.updateUserPending;

export const updateTeamMember = createAsyncThunk(
  "teamMembers/update_team_member",
  async (
    { firstName, lastName, displayName, defaultPhoneNumber, email, id },
    thunkAPI
  ) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/user/admin-profile/${id}`,
        {
          displayName,
          defaultPhoneNumber,
          email,
          firstName,
          lastName,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getTeamMembers = createAsyncThunk(
  "teamMembers/get",
  async (values, thunkAPI) => {
    const state = thunkAPI.getState();
    const { pageNumber, searchString } = values || {};
    const jwt = getToken(state);
    const { pageSize } = selectTeamMembersMeta(state);
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/partner/search/teammates`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            pageSize,
            pageNumber,
            searchString,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "teamMembers",
  initialState: {
    teamMembersRecord: [],
    fetching: false,
    error: false,
    meta: {
      pageNumber: 1,
      pageSize: 25,
      totalPages: 0,
      totalItems: 0,
    },
    updateUserPending: false,
  },
  reducers: {
    changeTeamMembersPageSize: (state, { payload }) => ({
      ...state,
      meta: { ...state.meta, pageSize: payload, pageNumber: 1 },
    }),
    updateTeamMemberList: (
      state,
      { payload: { displayName, defaultPhoneNumber, email, id } }
    ) => ({
      ...state,
      teamMembersRecord: state.teamMembersRecord.map((el) =>
        el.id === id ? { ...el, displayName, defaultPhoneNumber, email } : el
      ),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamMembers.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(getTeamMembers.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.meta.pageNumber = 1;
        state.meta.pageSize = 25;
        state.meta.totalPages = 0;
        state.meta.totalItems = 0;
        state.teamMembersRecord = [];
      })
      .addCase(getTeamMembers.fulfilled, (state, { payload }) => {
        state.error = false;
        state.fetching = false;
        state.meta.pageNumber = payload.pageNumber - 1;
        state.meta.pageSize = payload.pageSize;
        state.meta.totalPages = payload.totalPages;
        state.meta.totalItems = payload.totalItems;
        state.teamMembersRecord = payload ? payload.profiles : [];
      })
      .addCase(updateTeamMember.fulfilled, (state) => ({
        ...state,
        updateUserPending: false,
      }))
      .addCase(updateTeamMember.rejected, (state) => ({
        ...state,
        updateUserPending: false,
      }))
      .addCase(updateTeamMember.pending, (state) => ({
        ...state,
        updateUserPending: true,
      }));
  },
});

export const { changeTeamMembersPageSize, updateTeamMemberList } =
  slice.actions;

export default slice.reducer;
