import _ from "lodash";
import React, { Component } from "react";
// import moment from "moment";
import ItemReview from "./ItemReview";

class ItemReviews extends Component {
  // static displayName = 'ItemReviews';
  onChange = () => {};

  render() {
    const productReviews =
      this.props.reviews && this.props.reviews.length > 0
        ? _.take(this.props.reviews, 5)
        : [
            // {
            //   id: 1,
            //   title: "I loved this thing!!",
            //   productId: "c82669bf-367c-47a3-82e8-074410eb367f",
            //   raterUserId: "f0e95abc-2717-4038-86d7-f1d58c6792b5",
            //   ratingType: 1,
            //   rating: 4,
            //   reviewHtml:
            //     "Just about the best widget I've <i>ever</i> come across!!"
            // }
          ];
    // const pagedReviews = _.chunk(productReviews, 3);
    const moreReviews =
      this.props.reviews &&
      this.props.reviews.length > 0 &&
      this.props.reviews.length > 5
        ? _.takeRight(this.props.reviews, this.props.reviews.length - 5)
        : [];

    return productReviews != null && productReviews.length > 0 ? (
      <div>
        <div>
          {productReviews.map((review) => (
            <ItemReview key={review.id} review={review} />
          ))}
        </div>
        {moreReviews && moreReviews.length > 0 ? (
          <>
            <a
              className="d-block my-2"
              data-toggle="collapse"
              href="#more-reviews"
              aria-expanded="false"
              aria-controls="more-reviews"
            >
              Show more
            </a>

            <div className="collapse mt-4" id="more-reviews">
              {moreReviews.map((review) => (
                <ItemReview key={review.id} review={review} />
              ))}
            </div>
          </>
        ) : null}
      </div>
    ) : (
      <div>
        <p>
          <strong>This product has no reviews.</strong>
        </p>
        <p>Rent it and you can be the first to review it.</p>
      </div>
    );
  }
}
export default ItemReviews;
