import * as React from "react";
import { FormControl, Switch } from "@material-ui/core";
import AnnouncementIcon from "@mui/icons-material/Announcement";

export function SmsToggle({ value, changeHandler, name }) {
  return (
    <div className="tw-flex tw-items-center tw-w-full">
      <div className="tw-float-left">
        <FormControl component="fieldset">
          <Switch
            color="primary"
            name={name}
            checked={value}
            onChange={changeHandler}
          />
        </FormControl>
      </div>
      <div className="tw-flex tw-flex-nowrap  tw-float-left">
        {!value && (
          <div className="tw-h-5 tw-w-5 tw-rounded-full tw-bg-red-500 tw-mr-1 tw-relative">
            <AnnouncementIcon
              style={{
                color: "white",
                width: "67%",
                position: "absolute",
                top: "-1px",
                left: "3px",
              }}
            />
          </div>
        )}
        <span
          className={`${
            value ? "tw-text-gray-500 " : "tw-text-red-500"
          } tw-text-xs tw-w-full`}
        >
          {value
            ? "SMS alerts are on. You will receive real-time driver text updates for your orders."
            : "Don't miss your driver! Click the toggle to turn on SMS alerts."}
        </span>
      </div>
    </div>
  );
}
