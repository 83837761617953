import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

import RentDetails from "./RentDetailsCart";
import Spinner from "../../skipti/Spinner";
import { getCartFromRedux, getChosenProduct } from "../item_upsell/CartSlice";
import { getAppointmentItem } from "../../../reducers/itemDemoReducer";

const MobileCheckoutBar = ({
  buttonText = "NEXT",
  isAppointment = false,
  isHidden = false,
  isBuyFlow = false,
  ...props
}) => {
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const cart = useSelector(getCartFromRedux);
  const chosenProduct = useSelector(getChosenProduct);
  const selectedAppointments = useSelector(getAppointmentItem);
  const countDamageProtections =
    cart && cart.productRequests && cart.productRequests.length > 0
      ? cart.productRequests.filter((item) => item.hasDamageProtection === true)
          .length
      : 0;
  const countAddOns =
    cart && cart.productRequests && cart.productRequests.length > 0
      ? cart.productRequests.reduce(
          (total, item) =>
            total +
            (item?.addOns && item.addOns?.length > 0
              ? item?.addOns?.length
              : 0),
          0
        )
      : 0;

  const countProducts =
    isAppointment && !isBuyFlow
      ? selectedAppointments?.length
      : cart && cart.productRequests && cart.productRequests.length > 0
      ? cart.productRequests.length + countDamageProtections + countAddOns
      : 1;
  const _total =
    isAppointment && !isBuyFlow
      ? {
          total: props.pricingInfo?.total || 0,
          salesTax: props.pricingInfo?.salesTax || 0,
          subtotal: props.pricingInfo?.subtotal || 0,
          delivery: props.pricingInfo?.delivery || 0,
          perimeter: props.pricingInfo?.perimeter,
          // promoCode: props?.pricingInfo?.promoCode || "",
          discounts: props?.pricingInfo?.promoCode || 0,
        }
      : cart && cart.productRequests && cart.productRequests.length > 0
      ? {
          total: cart?.total?.amount || 0,
          salesTax: cart?.salesTax?.amount || 0,
          subtotal: cart?.subtotal?.amount || 0,
          delivery: cart?.delivery?.amount || 0,
          perimeter: cart?.perimeter?.amount || 0,
          discounts: cart?.discounts?.amount || "",
          promoCode: cart?.promoCode || "",
        }
      : {
          total: chosenProduct[0]?.basePerDayUsd || 0,
          subtotal: chosenProduct[0]?.basePerDayUsd || 0,
        };

  return (
    <div style={{ position: "relative" }}>
      <div
        className="md:tw-hidden tw-block tw-text-skiptiOrange tw-bg-white tw-p-4 mobile-fixed-pricebar tw-border-t tw-border-pastelGrey"
        style={{
          zIndex: "3",
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
        }}
      >
        <div className="tw-flex tw-flex-row tw-gap-x-4">
          <div className="tw-w-2/6 tw-self-center">
            <button
              type="button"
              className="tw-relative btn-skipti-secondary tw-block tw-text-center tw-w-full tw-h-12 tw-px-1 tw-py-1"
              style={{
                width: "100%",
                color: "#000000 !important",
                border: "1px solid #000",
                padding: 0,
              }}
              onClick={() => setIsCartModalOpen(true)}
            >
              <div className="tw-flex tw-flex-col" style={{ color: "#000" }}>
                <div>
                  <ShoppingBagOutlinedIcon />
                </div>
                <div
                  style={{
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontWeight: "bold",

                    letterSpacing: "0.38px",
                  }}
                >
                  {/* <strong>CART</strong> */}
                  <strong>${_total?.subtotal.toFixed(2)}</strong>
                </div>
              </div>
              {countProducts > 0 && (
                <>
                  {/* <span className="tw-sr-only">Notifications</span> */}
                  <div
                    style={{
                      top: "-0.3rem",
                      right: "-0.3rem",
                      backgroundColor: "#f05623",
                      // width: "14px",
                      // height: "13px",
                      // font-family: Lato;
                      fontSize: "11px",
                      fontWeight: "bold",
                      // fontStretch: "normal",
                      // fontStyle: "normal",
                      // lineHeight: "normal",
                      // letterSpacing: "-0.5px",
                      // textAlign: "center",
                      // verticalAlign: "middle",
                    }}
                    className="tw-absolute tw-inline-flex tw-items-center tw-justify-center tw-w-4 tw-h-4 tw-text-[11px] tw-text-white   tw-rounded-full "
                  >
                    {countProducts}
                  </div>
                </>
              )}
            </button>
          </div>

          <div className="tw-w-4/6 tw-self-center tw-relative">
            <button
              type="submit"
              className="btn-skipti-orange tw-block tw-text-center tw-w-full tw-h-12 tw-px-2 tw-py-2"
              style={{
                width: "100%",
                padding: "9px 8px",
                fontFamily: "Lato",
                fontSize: buttonText.length > 20 ? "12px" : "16px",
                fontWeight: "bold",

                letterSpacing: "0.5px",
                textAlign: "center",
              }}
              onClick={props.handleNext}
              disabled={props.isDisabled}
            >
              {buttonText}
            </button>

            {props.isLoading && (
              <div className="tw-absolute tw-top-0 tw-w-full">
                <Spinner
                  isWhiteBackground
                  loading={props.isLoading}
                  size={17}
                  absolute={false}
                  opacity={0.8}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="tw-relative"
        style={{
          width: "100%",
          // maxWidth: "100vw",
          // maxHeight: "90%",
          // position: "fixed",
          // top: "30%",
          // left: "0",
        }}
        open={isCartModalOpen}
        onClose={() => setIsCartModalOpen(false)}
      >
        <div
          className="tw-bg-white tw-pt-5 tw-fixed tw-bottom-0 tw-left-0 tw-w-full"
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            height: "calc(100vh - 100px)",
          }}
        >
          <div className="tw-text-center tw-text-xl tw-font-bold">
            <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
              <div>
                <ShoppingCartOutlinedIcon />
              </div>
              <div
                style={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontWeight: "bold",
                  letterSpacing: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  textAlign: "center",
                  color: "#333",
                }}
              >
                Cart
              </div>
            </div>
          </div>
          <button
            type="button"
            className="float-right"
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              top: "23px",
              right: "23px",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => setIsCartModalOpen(false)}
          >
            <i className="fas fa-times" />
          </button>

          <div style={{ height: "calc(100vh - 110px)", overflowY: "auto" }}>
            <RentDetails
              item={props.item}
              isAppointment={isAppointment}
              isBuyFlow={isBuyFlow}
              hideDates={isHidden}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

MobileCheckoutBar.propTypes = {
  isLoading: PropTypes.bool,
};

MobileCheckoutBar.defaultProps = {
  isLoading: false,
};

export default MobileCheckoutBar;
