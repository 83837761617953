import React from "react";

const SocialButtons = () => (
  <ul className="social-media tw-pl-0 tw-list-none">
    <li className="tw-mr-2 tw-mb-4 tw-inline-block">
      <a href="#">
        <i className="fab fa-facebook" />
      </a>
    </li>
    <li className="tw-mr-2 tw-mb-4 tw-inline-block">
      <a href="#">
        <i className="fab fa-twitter" />
      </a>
    </li>
    <li className="tw-mr-2 tw-mb-4 tw-inline-block">
      <a href="#">
        <i className="fab fa-instagram" />
      </a>
    </li>
  </ul>
);
export default SocialButtons;
