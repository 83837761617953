import axios from "axios";
import _ from "lodash";
import * as helpers from "../utils";
import * as TYPES from "../types";
import { showSnackbar } from "./snackbar";

const requestArticlesAll = () => ({
  type: TYPES.ARTICLES_ALL_REQUEST,
});
const successArticlesAll = (payload) => ({
  type: TYPES.ARTICLES_ALL_SUCCESS,
  payload,
});
const failArticlesAll = (error) => ({
  type: TYPES.ARTICLES_ALL_FAIL,
  error,
});

const requestArticleById = () => ({
  type: TYPES.ARTICLE_BYID_REQUEST,
});
const successArticleById = (payload) => ({
  type: TYPES.ARTICLE_BYID_SUCCESS,
  payload,
});
const failArticleById = (error) => ({
  type: TYPES.ARTICLE_BYID_FAIL,
  error,
});
const requestAdminArticleTeasers = () => ({
  type: TYPES.ARTICLES_ADMIN_TEASERS_REQUEST,
});
const successAdminArticleTeasers = (payload) => ({
  type: TYPES.ARTICLES_ADMIN_TEASERS_SUCCESS,
  payload,
});
const failAdminArticleTeasers = (error) => ({
  type: TYPES.ARTICLES_ADMIN_TEASERS_FAIL,
  error,
});

export const fetchAdminArticleTeasers =
  ({ pageNumber, pageSize }) =>
  async (dispatch) => {
    dispatch(requestAdminArticleTeasers());
    try {
      const typeId = 0;
      const res = await axios.get(
        `${helpers.serverUrl}/api/v1/teasers/all?pageSize=${pageSize}&pageNumber=${pageNumber}`
      );
      dispatch(successAdminArticleTeasers(res.data));
    } catch (e) {
      dispatch(failAdminArticleTeasers(e));
      showSnackbar(e.message, "error");
    }
  };

// eslint-disable-next-line
export const fetchArticleTeasers = () => async (dispatch) => {
  dispatch(requestArticlesAll());
  try {
    //  const typeId = _.random(0, 1, false);
    const typeId = 0;
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/teasers/type/${typeId}`
    );
    dispatch(successArticlesAll(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failArticlesAll(e));
  }
};

export const fetchArticleById = (id) => async (dispatch) => {
  dispatch(requestArticleById());
  try {
    const res = await axios.get(`${helpers.serverUrl}/api/v1/article/${id}`);
    dispatch(successArticleById(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failArticleById(e));
  }
};

const requestArticlesTop10 = () => ({
  type: TYPES.ARTICLES_TOP10_REQUEST,
});
const successArticlesTop10 = (payload) => ({
  type: TYPES.ARTICLES_TOP10_SUCCESS,
  payload,
});
const failArticlesTop10 = (error) => ({
  type: TYPES.ARTICLES_TOP10_FAIL,
  error,
});
export const fetchTop10Teasers = () => async (dispatch) => {
  dispatch(requestArticlesTop10());
  try {
    //  const typeId = _.random(0, 1, false);
    const typeId = 1;
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/teasers/type/${typeId}`
    );
    dispatch(successArticlesTop10(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failArticlesTop10(e));
  }
};

const requestArticlesBlog = () => ({
  type: TYPES.ARTICLES_BLOG_REQUEST,
});
const successArticlesBlog = (payload) => ({
  type: TYPES.ARTICLES_BLOG_SUCCESS,
  payload,
});
const failArticlesBlog = (error) => ({
  type: TYPES.ARTICLES_BLOG_FAIL,
  error,
});
export const fetchBlogTeasers = () => async (dispatch) => {
  dispatch(requestArticlesBlog());
  try {
    //  const typeId = _.random(0, 1, false);
    const typeId = 1;
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/teasers/type/${typeId}`
    );
    dispatch(successArticlesBlog(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failArticlesBlog(e));
  }
};

const requestArticlesIdea = () => ({
  type: TYPES.ARTICLES_IDEA_REQUEST,
});
const successArticlesIdea = (payload) => ({
  type: TYPES.ARTICLES_IDEA_SUCCESS,
  payload,
});
const failArticlesIdea = (error) => ({
  type: TYPES.ARTICLES_IDEA_FAIL,
  error,
});
export const fetchIdeaTeasers = () => async (dispatch) => {
  dispatch(requestArticlesIdea());
  try {
    //  const typeId = _.random(0, 1, false);
    const typeId = 1;
    const res = await axios.get(
      `${helpers.serverUrl}/api/v1/teasers/type/${typeId}`
    );
    dispatch(successArticlesIdea(res.data));
  } catch (e) {
    dispatch(showSnackbar(e.message));
    dispatch(failArticlesIdea(e));
  }
};
