import React from "react";

export default function ItemIncluded(props) {
  return (
    <ul>
      {props.included.map((item, index) => (
        <li key={index}> {item} </li>
      ))}
    </ul>
  );
}
