import { combineReducers } from "redux";
import PNLReducer from "./reducer_pnl_data";
import authReducer from "./authReducer";
import categoriesReducer from "./categoriesReducer";
import articlesReducer from "./articlesReducer";
import userReducer from "./userReducer";
import itemsReducer from "./itemsReducer";
import dashboardReducer from "./dashboardReducer";
import organizationReducer from "./organizationReducer";
import addressesReducer from "./addressesReducer";
import snackbarReducer from "./snackbarReducer";
import experiencesReducer from "./experiencesReducer";
import ordersReducer from "./ordersReducer";
import itemRentReducer from "./itemRentReducer";
import itemDemoReducer from "./itemDemoReducer";
import depotsSlice from "../components/admin_depots/depotsSlice";
import driversSlice from "../components/admin_drivers/driversSlice";
import dmsRoutesSlice from "../components/admin_dms/Routes/routesSlice";
import dmsSlice from "../components/admin_dms/dmsSlice";
import serviceRecordsSlice from "../components/admin_serviceRecords/serviceRecordSlice";
import teamMembersSlice from "../components/admin_teamMembers/teamMembersSlice";
import customersSlice from "../components/admin_customers/customersSlice";
import orderPayments from "../actions/orderPaymentsSlice";
import dmsDrivers from "../components/admin_dms/Drivers/driversSlice";
import CartSlice from "../components/items/item_upsell/CartSlice";
import layoutDashboardSlice from "../components/layouts/layoutDashboardSlice";
import paymentInfoSlice from "../components/paymentInfoSlice/paymentInfoSlice";
import policyPortalSlice from "../components/admin_policyPortal/policyPortalSlice";
import depositsSlice from "../components/dashboard/admin/AdminDeposits/depositsSlice";
import dmsDriverManifest from "../components/admin_dms/DriverManifest/driverManifestSlice";

const reducers = combineReducers({
  chartData: PNLReducer,
  // form: formReducer,
  auth: authReducer,
  categories: categoriesReducer,
  articles: articlesReducer,
  user: userReducer,
  items: itemsReducer,
  dashboard: dashboardReducer,
  organization: organizationReducer,
  addresses: addressesReducer,
  snackbar: snackbarReducer,
  experiences: experiencesReducer,
  orders: ordersReducer,
  itemRentReducer,
  itemDemoReducer,
  depots: depotsSlice,
  drivers: driversSlice,
  serviceRecords: serviceRecordsSlice,
  teamMembers: teamMembersSlice,
  customers: customersSlice,
  orderPayments,
  dmsRoutes: dmsRoutesSlice,
  dmsSlice,
  dmsDrivers,
  cart: CartSlice,
  collapses: layoutDashboardSlice,
  paymentInfo: paymentInfoSlice,
  policyPortal: policyPortalSlice,
  depositsSlice,
  dmsDriverManifest,
});

export default reducers;
