import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  createTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { StripeProvider } from "react-stripe-elements";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";
// import ReactGA from "react-ga";
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
// import "bootstrap/dist/css/bootstrap.css";
import "./tailwind.output.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SnackbarProvider } from "notistack";
import { unregister } from "./registerServiceWorker";
import "./sass/app.scss";
import store from "./store/reduxStore";
import App from "./App";
import {
  mixpanelKey,
  // googleAnalyticsTrackingID,
  stripeKey,
} from "./utils/index";
import "./canvasToBlobPolyfill";
import NotifierCloseButton from "./components/skipti/NotifierCloseButton";

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: { main: "#F05623", light: "#F05623", dark: "#F05623" },
      secondary: { main: "#ACACAC", light: "#ACACAC", dark: "#ACACAC" },
      textColor2: { main: "#0064FF", light: "#0064FF", dark: "#0064FF" },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          fontWeight: 700,
          color: "#666666",
          fontSize: "0.875rem",
          padding: "0.5rem 1rem",
          lineHeight: "1.75",
        },
        contained: {
          color: "#666666",
        },
        outlinedPrimary: {
          borderWidth: "2px",
          borderColor: "#F05623",
          "&:hover": {
            borderWidth: "2px",
            backgroundColor: "#F05623",
            color: "#fff",
            borderColor: "#F05623",
          },
        },
        outlinedSecondary: {
          borderWidth: "2px",
          borderColor: "#ACACAC",
          "&:hover": {
            borderWidth: "2px",
            backgroundColor: "#ACACAC",
            color: "#fff",
            borderColor: "#ACACAC",
          },
        },
        outlined: {
          padding: "0.375rem 1rem",
        },
        // outlinedPrimary: {
        //   borderWidth: "1px",
        // },
        // outlinedSecondary: {
        //   borderWidth: "1px",
        // },
      },
    },
    typography: {
      fontFamily: ['"Lato"'],
      useNextVariants: true,
    },
  })
);

mixpanel.init(mixpanelKey);
// ReactGA.initialize(googleAnalyticsTrackingID);

LicenseInfo.setLicenseKey(
  "ee7d37ec68c9232d1df8075e4d091f47T1JERVI6MjIxMzgsRVhQSVJZPTE2NDY1OTU5MDMwMDAsS0VZVkVSU0lPTj0x"
);

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider
      apiKey={stripeKey}
      // apiKey="pk_test_ENhZPBJ14tfTw9pGsZB4aUZF" test key
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <MixpanelProvider mixpanel={mixpanel}>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              action={(key) => <NotifierCloseButton id={key} />}
            >
              <App />
            </SnackbarProvider>
          </MixpanelProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StripeProvider>
  </Provider>,
  document.getElementById("root")
);

// registerServiceWorker();
unregister();
