import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { showSnackbar } from "./snackbar";
import { getToken } from "../reducers/authReducer";

import { serverUrl } from "../utils";

export const orderPaymentsListSelector = (id) => (state) =>
  state.orderPayments?.[id]?.data ?? [];

export const getRoutePayments = createAsyncThunk(
  "orderPayments/getPayments",
  async (routeId, thunkAPI) => {
    try {
      const jwt = getToken(thunkAPI.getState());
      const res = await axios.get(`${serverUrl}/api/v1/route/paymentnotes`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          OrderId:
            thunkAPI.getState().orders.orderById?.data?.resourceRequest?.id,
          RouteId: routeId,
        },
      });
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(showSnackbar(error.message, "error"));
      return error;
    }
  }
);

export const addRoutePayments = createAsyncThunk(
  "orderPayments/addRoutePayments",
  async ({ id, values }, thunkAPI) => {
    try {
      const routes = thunkAPI.getState().orders?.subRoutes?.data ?? [];
      const currentRoute = routes.find((el) => el.id === id);
      const jwt = getToken(thunkAPI.getState());
      const res = await axios.post(
        `${serverUrl}/api/v1/route/paymentnotes`,
        {
          DateAndTime: moment().utc().toISOString(),
          amount: values.cost,
          note: values.reason,
          OrderId: currentRoute.fkResourceRequestId,
          isDelivery: currentRoute.isDelivery,
          ExternalTicketId: currentRoute.ticketId,
          DriverId: values.driverId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getRoutePayments(id));
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(showSnackbar(error.message, "error"));
      return error;
    }
  }
);

export const editRoutePayments = createAsyncThunk(
  "orderPayments/editRoutePayments",
  async ({ values, feeData }, thunkAPI) => {
    try {
      const routes = thunkAPI.getState().orders?.subRoutes?.data ?? [];
      const currentRoute = routes.find((el) => el.id === feeData.routeId);
      const jwt = getToken(thunkAPI.getState());
      const res = await axios.put(
        `${serverUrl}/api/v1/route/paymentnotes`,
        {
          DateAndTime: moment().utc().toISOString(),
          amount: values.cost,
          note: values.reason,
          OrderId: currentRoute.fkResourceRequestId,
          isDelivery: currentRoute.isDelivery,
          ExternalTicketId: currentRoute.ticketId,
          DriverId: values.driverId,
          id: feeData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getRoutePayments(feeData.routeId));
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(showSnackbar(error.message, "error"));
      return error;
    }
  }
);

export const deleteRoutePayments = createAsyncThunk(
  "orderPayments/deleteRoutePayments",
  async (feeData, thunkAPI) => {
    try {
      // const routes = thunkAPI.getState().orders?.subRoutes?.data ?? [];
      // const currentRoute = routes.find((el) => el.id === feeData.routeId);
      const jwt = getToken(thunkAPI.getState());
      const res = await axios.delete(
        `${serverUrl}/api/v1/route/paymentnotes/${feeData.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getRoutePayments(feeData.routeId));
      return res.data;
    } catch (error) {
      thunkAPI.dispatch(showSnackbar(error.message, "error"));
      return error;
    }
  }
);

const slice = createSlice({
  name: "orderPayments",
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoutePayments.pending, (state, action) => ({
        ...state,
        [action.meta.arg]: {
          ...state[action.meta.arg],
          fetching: true,
          error: false,
        },
      }))
      .addCase(getRoutePayments.rejected, (state, action) => ({
        ...state,
        [action.meta.arg]: {
          ...state[action.meta.arg],
          fetching: false,
          error: true,
        },
      }))
      .addCase(getRoutePayments.fulfilled, (state, action) => ({
        ...state,
        [action.meta.arg]: {
          ...state[action.meta.arg],
          fetching: false,
          error: false,
          data: action.payload,
        },
      }));
  },
});

export default slice.reducer;
