import React, { Component } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
});

class SkiptiSpinner extends Component {
  static propTypes = {
    opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    absolute: PropTypes.bool,
    loading: PropTypes.bool,
    size: PropTypes.number,
  };

  static defaultProps = {
    absolute: true,
    loading: false,
    opacity: 0,
    size: 40,
  };

  state = {};

  render() {
    const {
      loading,
      opacity,
      absolute,
      classes,
      inPaper,
      size,
      isWhiteBackground,
      zIndex,
    } = this.props;
    return loading ? (
      <div
        style={{
          backgroundColor: `rgba(${
            isWhiteBackground ? "255,255,255" : "0,0,0"
          },${!opacity ? 0.5 : opacity})`,
          height: "100%",
          width: "100%",
          zIndex: zIndex || "999",
          position: absolute ? "absolute" : "",
          flex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "0px",
          left: "0px",
          borderRadius: inPaper ? "4px" : "0",
        }}
      >
        <CircularProgress className={classes.progress} size={size} />
      </div>
    ) : null;
  }
}

export default withStyles(styles)(SkiptiSpinner);
