import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MixpanelContext from "react-mixpanel";
import { getIsLoggedIn } from "../../reducers/authReducer";
import { getCategories } from "../../reducers/categoriesReducer";
import {
  getProductsForAllCategoriesByOrg,
  getProductsVotable,
} from "../../reducers/itemsReducer";
import LayoutMain from "../layouts/LayoutMain";
import HeroDiscoverPartner from "./HeroDiscoverPartner";
import HeroDiscoverEvents from "./HeroDiscoverEvents";
import {
  fetchCategories,
  fetchProductsForAllCategories,
  fetchProductsForAllCategoriesByOrg,
  fetchExperiencesAll,
  fetchOrganizationInfoById,
  fetchOrganizationInfoByName,
  fetchItemsByOrganizationId,
} from "../../actions";
import { getExperiences } from "../../reducers/experiencesReducer";

import { getRegion } from "../../reducers/userReducer";
import { imageServerUrl } from "../../utils";
import {
  getOrganizationInfoById,
  getOrganizationInfoByName,
} from "../../reducers/organizationReducer";
import PartnerItemsList from "./PartnerItemsList";

const noOfProducts = 100;

class DiscoverPartner extends Component {
  static propTypes = {
    fetchCategories: PropTypes.func.isRequired,
    fetchExperiencesAll: PropTypes.func.isRequired,
    fetchProductsForAllCategories: PropTypes.func.isRequired,
    productCategories: PropTypes.shape({}).isRequired,
    experiences: PropTypes.shape({
      data: PropTypes.shape({
        resources: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      isLoaded: PropTypes.bool,
    }),
    products: PropTypes.shape({}),
    events: PropTypes.bool,
  };

  static defaultProps = {
    experiences: {},
    products: {},
    events: false,
  };

  static contextType = MixpanelContext;

  state = {
    pagenumber: 0,
    items: [],
    votingRowPopulated: false,
    orgId: "",
  };

  async componentDidMount() {
    this.context.track("DiscoverPartner");
    window.analytics.page("DiscoverPartner");

    let orgId = this.props.events ? "CCB06300-DC6A-40A9-80A0-BEDDDDEFE7D4" : "";

    if (!this.props.productCategories) {
      this.props.fetchCategories();
    }
    if (this.props.match?.params?.id != null) {
      orgId = this.props.match?.params?.id;

      await this.props.fetchOrganizationInfoById(this.props.match?.params?.id);
      this.props.fetchItemsByOrganizationId({
        organizationId: this.props.match?.params?.id,
      });
    } else if (this.props.match?.params?.name != null) {
      await this.props.fetchOrganizationInfoByName(
        this.props.match?.params?.name
      );
      orgId = this.props.match?.params?.name;
    }
    this.setState({ orgId });
    if (
      !this.props.experiences ||
      (this.props.experiences && !this.props.experiences.data)
    ) {
      this.props.fetchExperiencesAll();
    }
  }

  setVotingRowPopulated = (populated) => {
    this.setState({ votingRowPopulated: populated });
  };

  loadMore = (orgId) => {
    const { pagenumber } = this.state;
    this.props.fetchProductsForAllCategoriesByOrg({
      id: orgId,
      pagesize: noOfProducts,
      pagenumber: pagenumber + 1,
    });
    this.setState({
      pagenumber: pagenumber + 1,
    });
  };

  handleShowMoreClick = (categoryName) => {
    this.context.track("DiscoverPartner>clicked Show all", {
      showAllCategory: categoryName,
    });
    window.analytics.track("DiscoverPartner clicked Show all", {
      showAllCategory: categoryName,
    });
  };

  render() {
    const { events } = this.props;

    const organizationByName =
      this.props.organizationByName &&
      this.props.organizationByName.isLoaded &&
      this.props.organizationByName.data
        ? this.props.organizationByName.data
        : {};
    const organization =
      this.props.organization &&
      this.props.organization.isLoaded &&
      this.props.organization.data
        ? this.props.organization.data
        : organizationByName;
    //

    return (
      <LayoutMain
        noFooter
        withAdditionalLinks={false}
        withTopBar={false}
        withContainer={false}
        withSecondBar={!!this.props.events}
        showSearch
      >
        <>
          {events ? (
            <>
              <div
                style={{
                  position: "absolute",
                  left: "0px",
                  top: "80px",
                  paddingLeft: "7px",
                  paddingTop: "7px",
                }}
              >
                <img
                  src={`${imageServerUrl}/partners/events/erentlogo.png`}
                  alt=""
                  style={{ zIndex: "2", width: "120px", height: "100%" }}
                />
              </div>
              <HeroDiscoverEvents />
            </>
          ) : (
            <HeroDiscoverPartner organization={organization} />
          )}

          <PartnerItemsList
            org={this.state.orgId}
            noOfProducts={noOfProducts}
          />
        </>
      </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
  productCategories: getCategories(state) || [],
  products: getProductsForAllCategoriesByOrg(state),
  experiences: getExperiences(state),
  votableItems: getProductsVotable(state),
  region: getRegion(state),
  organization: getOrganizationInfoById(state),
  organizationByName: getOrganizationInfoByName(state),
});

export default connect(mapStateToProps, {
  fetchCategories,
  fetchProductsForAllCategories,
  fetchProductsForAllCategoriesByOrg,
  fetchExperiencesAll,
  fetchOrganizationInfoById,
  fetchOrganizationInfoByName,
  fetchItemsByOrganizationId,
})(DiscoverPartner);
