import React, { Component } from "react";
import { connect } from "react-redux";
import { withWidth } from "@material-ui/core";
import strings from "../../strings.json";
import { getCityName } from "../../reducers/authReducer";
import { imageServerUrl } from "../../utils";

class HeroDiscover extends Component {
  state = {};

  static getDerivedStateFromProps = (props) => ({
    heroImage: strings[props.city].herodiscover.heroImage,
    heroText: strings[props.city].herodiscover.heroText,
    heroImageMobile: strings[props.city].herodiscover.heroImageMobile,
  });

  render() {
    const { heroImage, heroText, heroImageMobile } = this.state;
    const { width } = this.props;
    return (
      <div className="tw-m-0 tw-mb-12 tw-p-0 skipti-hero tw-text-white discover fade-bottom">
        <div className="tw-w-full tw-h-full">
          <div
            className="tw-p-12 tw-flex tw-h-full tw-text-white sm:tw-text-black"
            style={{
              background: `url(${imageServerUrl}${
                width === "xs" ? heroImageMobile : heroImage
              }) bottom left /cover no-repeat`,
            }}
          >
            <div
              className="tw-self-center"
              dangerouslySetInnerHTML={{ __html: heroText }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  city: getCityName(state),
});

export default connect(mapStateToProps)(withWidth()(HeroDiscover));
