import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useContext } from "react";
import axios from "axios";
// import MixpanelContext from "react-mixpanel";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { showSnackbar } from "../../../actions/snackbar";
import { getToken, getLocationByIP } from "../../../reducers/authReducer";
import { serverUrl } from "../../../utils";
import {
  orderSources,
  fulfillmentTypes,
  experienceTypes,
  getExperienceTypeFromModel,
  findFulfillmentObject,
} from "../../../actions/utils";

import { getItemRentInfo, getStoreId } from "../../../reducers/itemRentReducer";
import {
  getDateRange,
  getProductById,
  getZip,
  getDeliveryOptions,
} from "../../../reducers/itemsReducer";
import {
  setOrderPromoCode,
  setDateRange,
  setStartAvail,
  setEndAvail,
} from "../../../actions";

export const getCartKey = (state) => state.cart?.cartkey;
export const getCartSelectedItemIds = (state) =>
  state.cart?.cartSelectedItemIds;
export const getCartItems = (state) => state.cart?.cart?.productRequests;
export const getCartFromRedux = (state) => state.cart?.cart;
export const isCartFetching = (state) => state.cart?.fetching;
export const getCartAddOns = (state) => state.cart?.addOns;
export const getCartAddOnMenu = (state) => state.cart?.allAddOns;
export const getIsCartAddOnMenuFetching = (state) =>
  state.cart?.addOnsMenuFetching;
export const getWasCartReset = (state) => state.cart?.wasCartReset;
export const getPromoCodeFromCart = (state) => state.cart?.cart?.promoCode;
export const getChosenProduct = (state) => state.cart?.chosenProduct;

const consolidateItemsArray = (arr) => {
  // Create an object to store unique items and their quantities
  const consolidatedObj = arr.reduce((acc, item) => {
    if (!acc[item.productId]) {
      // If the item does not exist in the accumulator, add it
      acc[item.productId] = item;
      acc[item.productId].productTotal = item.baseCost?.amount;
      acc[item.productId].damageProtectionTotal =
        item.damageProtection?.price?.amount;
    } else {
      // If the item already exists, add the quantity to the existing item
      acc[item.productId].quantity += item.quantity;
      acc[item.productId].productTotal += item.baseCost?.amount;
      acc[item.productId].damageProtectionTotal +=
        item.damageProtection?.price?.amount;
    }
    return acc;
  }, {});
  // Convert the consolidated object back to an array
  const consolidatedArray = Object.values(consolidatedObj);
  return consolidatedArray;
};

export const getCart = createAsyncThunk(
  "cart/getCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    let cartkey = data.cartKey ? data.cartKey : getCartKey(thunkAPI.getState());
    const storeId = getStoreId(thunkAPI.getState());
    const zipCode = getZip(thunkAPI.getState());
    const deliveryOptions = getDeliveryOptions(thunkAPI.getState());
    const delivery = deliveryOptions?.deliveryOptions;
    const fetch = deliveryOptions?.fetchOptions;

    if (
      typeof cartkey === "undefined" ||
      cartkey === "undefined" ||
      !cartkey ||
      cartkey === "null"
    ) {
      cartkey = null;
    }

    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;
    const zip = data?.zip || zipCode;
    try {
      if (cartkey || ip) {
        const res = await axios.get(`${serverUrl}/api/v1/cart/current`, {
          params: {
            ...(cartkey ? { cartkey } : ip && { ip }),
            ...(storeId && { forceDepotID: storeId }),
            promocode: data.Promocode,
            zipPostal: zip,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (
          res &&
          res.data &&
          res.data.productRequests &&
          res.data.productRequests.length > 0
        ) {
          const consolidatedProductRequests = consolidateItemsArray(
            res.data.productRequests
          );

          // thunkAPI.dispatch(
          //   setDateRange(
          //     moment.utc(
          //       res.data.productRequests[0].dtLocalDeliverBegins ||
          //         res.data.productRequests[0].utcBegins
          //     ),
          //     moment.utc(
          //       res.data.productRequests[0].dtLocalReturnBegins ||
          //         res.data.productRequests[0].utcEnds
          //     )
          //   )
          // );
          // console.log("delivery in getCart", delivery);
          // if (delivery?.length > 0 && fetch?.length > 0) {
          //   const fulfillmentObject = findFulfillmentObject(
          //     res.data.fulfillmentType
          //   );

          //   const deliveryWindowInCart = delivery[
          //     fulfillmentObject.method.split[0]
          //   ]?.find((p) =>
          //     moment
          //       .utc(p.starts_at)
          //       .isSame(this.props.cart.productRequests[0].dtLocalDeliverBegins)
          //   );
          //   console.log("deliveryWindowInCart", deliveryWindowInCart);
          //   if (deliveryWindowInCart) {

          //     thunkAPI.dispatch(setStartAvail(deliveryWindowInCart?.id));
          //   }

          //   const fetchWindowInCart = fetch[
          //     fulfillmentObject.method.split[1] ||
          //       fulfillmentObject.method.split[0]
          //   ]?.find((p) =>
          //     moment
          //       .utc(p.starts_at)
          //       .isSame(this.props.cart.productRequests[0].dtLocalReturnBegins)
          //   );

          //   if (fetchWindowInCart) {

          //     thunkAPI.dispatch(setEndAvail(fetchWindowInCart?.id));
          //   }
          // }

          return { ...res.data, productRequests: consolidatedProductRequests };
        }
        return res.data;
      }
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);
export const deleteCart = createAsyncThunk(
  "cart/deleteCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;

    try {
      const res = await axios.delete(`${serverUrl}/api/v1/cart/current`, {
        params: {
          ...(cartkey ? { cartkey } : ip && { ip }),
        },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;

    try {
      const res = await axios.delete(
        `${serverUrl}/api/v1/cart/request/${data.id}`,
        {
          params: {
            ...(cartkey ? { cartkey } : ip && { ip }),
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const removeAddOnFromCart = createAsyncThunk(
  "cart/removeAddOnFromCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;

    try {
      const res = await axios.delete(
        `${serverUrl}/api/v1/cart/addon/${data.id}`,
        {
          params: {
            ...(cartkey ? { cartkey } : ip && { ip }),
            ...(data.productRequestId
              ? { productRequestId: data.productRequestId }
              : data.productId && { ProductId: data.productId }),
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

// removeDamageProtectionFromCart
export const removeDamageProtectionFromCart = createAsyncThunk(
  "cart/removeDamageProtectionFromCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;
    const zip = data?.zip || getZip(thunkAPI.getState());

    try {
      // [FromQuery] Guid productId
      // , [FromQuery] string ip = ""
      // , [FromQuery] string cartKey = ""
      // , [FromQuery] string zipPostal = ""   //Can be used if cart does not already exist
      const res = await axios.delete(
        `${serverUrl}/api/v1/cart/damageprotection`,
        {
          params: {
            ...(cartkey ? { cartKey: cartkey } : ip && { ip }),
            zipPostal: zip,
            productId: data.productId,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const storeId = getStoreId(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;
    const cart = getCartFromRedux(thunkAPI.getState());

    const itemRentInfo = getItemRentInfo(thunkAPI.getState());
    const item = getProductById(thunkAPI.getState()).data;
    const sourceType =
      orderSources.find((c) => c.name === itemRentInfo.client) || "0";
    const fulfillmentType =
      itemRentInfo?.fulfillmentType?.id || fulfillmentTypes.Courier.id;
    const experienceType =
      itemRentInfo?.experienceType?.id || item.experienceTypeObject?.id;

    // console.log("addToCart fulfillmentType", fulfillmentType);

    const dateRange = getDateRange(thunkAPI.getState());
    const productInCart =
      cart?.productRequests?.find((p) => p.productId === data.ProductId) ||
      null;
    const addOns = productInCart?.addOns || null;
    const deliveryModel = null; //      data.DeliveryModel || itemRentInfo.deliveryModel || item.deliveryModel;

    const mappedData = {
      ProductId: data.ProductId,
      // DeliveryModel: deliveryModel,
      utcBegins: data.utcBegins || dateRange.startDate,
      utcEnds: data.utcEnds || dateRange.endDate,
      Quantity: data.Quantity,
      PromoCode: data.PromoCode,
      AddOns: data.AddOns || addOns || null,
      BundledItems: data.BundledItems,
      NativeAttributes: data.NativeAttributes,
      ProfiledAttributes: data.ProfiledAttributes,
      CheckoutAttributes: data.CheckoutAttributes,
      ...(storeId && { forceDepotID: storeId }),
    };

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/cart/product`,
        mappedData,
        {
          params: {
            ...(cartkey ? { cartkey } : ip && { ip }),
            promocode: data.PromoCode,
            zipPostal: data.zip,
            sourceType,
            fulfillmentType,
            experienceType,
            forceNewCart: data.forceNewCart,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (res.status === 200) {
        if (
          res &&
          res.data &&
          res.data.productRequests &&
          res.data.productRequests.length > 0
        ) {
          const consolidatedProductRequests = consolidateItemsArray(
            res.data.productRequests
          );

          res.data = {
            ...res.data,
            productRequests: consolidatedProductRequests,
          };
        }
        mixpanel.track("Product Added", {
          total: res.data?.total?.amount,
          revenue: res.data?.subtotal?.amount,
          shipping: res.data?.delivery?.amount,
          tax: res.data?.salesTax?.amount,
          discount: res.data?.discounts?.amount,
          currencyType: res.data?.currencyType,
          fees: res.data?.fees,
          // client: fromClient,
          customerEmail: res.data?.customerEmail,
          hasAccessories: res.data?.hasAccessories,
          hasAvailableAddOns: res.data?.hasAvailableAddOns,
          hasOptionalAddOns: res.data?.hasOptionalAddOns,
          hasOversizedItems: res.data?.hasOversizedItems,
          hasTermsAndCondition: res.data?.hasTermsAndCondition,
          promoCode: res.data?.promoCode,
          userId: res.data?.userId,
          zipPostal: res.data?.zipPostal,
          products: [
            {
              product_id: res.data?.productRequests?.productId,
              id: res.data?.productRequests?.id,
              name: res.data?.productRequests?.productName,
              price: res.data?.productRequests?.baseCost?.amount,
              unitPrice: res.data?.productRequests?.unitPrice,
              quantity: res.data?.productRequests?.quantity,
              nights: res.data?.productRequests?.nights,
              // category: product.data.productCategories[0].name,
              url: `https://marketplace.skipti.com/items/${res.data?.productRequests?.productId}`,
              image_url: res.data?.productRequests?.thumbNailUrl,
              organizationId: res.data?.productRequests?.organizationId,
              organizationName: res.data?.productRequests?.organizationName,
              inventoryId: res.data?.productRequests?.inventoryId,
              region: res.data?.productRequests?.region,
              serialNumber: res.data?.productRequests?.serialNumber,
              subsequentDayPrice: res.data?.productRequests?.subsequentDayPrice,
              deliveryModel: res.data?.productRequests?.deliveryModel,
              // bundledItems: res.data?.productRequests?.bundledItems,
              addOns: res.data?.productRequests?.addOns,
              bin: res.data?.productRequests?.bin,
              depotLocationId: res.data?.productRequests?.depotLocationId,
              dtLocalDeliverBegins:
                res.data?.productRequests?.dtLocalDeliverBegins,
              dtLocalDeliverEnds: res.data?.productRequests?.dtLocalDeliverEnds,
              dtLocalReturnBegins:
                res.data?.productRequests?.dtLocalReturnBegins,
              dtLocalReturnEnds: res.data?.productRequests?.dtLocalReturnEnds,
              dtUtcDeliverBegins: res.data?.productRequests?.dtUtcDeliverBegins,
              dtUtcDeliverEnds: res.data?.productRequests?.dtUtcDeliverEnds,
              dtUtcReturnBegins: res.data?.productRequests?.dtUtcReturnBegins,
              dtUtcReturnEnds: res.data?.productRequests?.dtUtcReturnEnds,
              hasAddOns: res.data?.productRequests?.hasAddOns,
              hasBundledItems: res.data?.productRequests?.hasBundledItems,
              hasCheckoutAttributes:
                res.data?.productRequests?.hasCheckoutAttributes,
              hasDamageProtection:
                res.data?.productRequests?.hasDamageProtection,
              hasNativeAttributes:
                res.data?.productRequests?.hasNativeAttributes,
              hasProfiledItems: res.data?.productRequests?.hasProfiledItems,
              utcOffset: res.data?.productRequests?.utcOffset,
            },
          ],
          // username: (user && user.email) || "anonymous",

          // startDate: moment(dateRange.startDate).format("MM/DD/YYYY HH:mm:ss"),
          // endDate: moment(dateRange.endDate).format("MM/DD/YYYY HH:mm:ss"),
          // region: region && region.id,
          // regionName: region && region.name,

          type: "Rent",
          method: itemRentInfo.method,
        });

        window.analytics.track("Product Added", {
          total: res.data?.total?.amount,
          revenue: res.data?.subtotal?.amount,
          shipping: res.data?.delivery?.amount,
          tax: res.data?.salesTax?.amount,
          discount: res.data?.discounts?.amount,
          currencyType: res.data?.currencyType,
          fees: res.data?.fees,
          // client: fromClient,
          customerEmail: res.data?.customerEmail,
          hasAccessories: res.data?.hasAccessories,
          hasAvailableAddOns: res.data?.hasAvailableAddOns,
          hasOptionalAddOns: res.data?.hasOptionalAddOns,
          hasOversizedItems: res.data?.hasOversizedItems,
          hasTermsAndCondition: res.data?.hasTermsAndCondition,
          promoCode: res.data?.promoCode,
          userId: res.data?.userId,
          zipPostal: res.data?.zipPostal,
          products: [
            {
              product_id: res.data?.productRequests?.productId,
              id: res.data?.productRequests?.id,
              name: res.data?.productRequests?.productName,
              price: res.data?.productRequests?.baseCost?.amount,
              unitPrice: res.data?.productRequests?.unitPrice,
              quantity: res.data?.productRequests?.quantity,
              nights: res.data?.productRequests?.nights,
              // category: product.data.productCategories[0].name,
              url: `https://marketplace.skipti.com/items/${res.data?.productRequests?.productId}`,
              image_url: res.data?.productRequests?.thumbNailUrl,
              organizationId: res.data?.productRequests?.organizationId,
              organizationName: res.data?.productRequests?.organizationName,
              inventoryId: res.data?.productRequests?.inventoryId,
              region: res.data?.productRequests?.region,
              serialNumber: res.data?.productRequests?.serialNumber,
              subsequentDayPrice: res.data?.productRequests?.subsequentDayPrice,
              deliveryModel: res.data?.productRequests?.deliveryModel,
              // bundledItems: res.data?.productRequests?.bundledItems,
              addOns: res.data?.productRequests?.addOns,
              bin: res.data?.productRequests?.bin,
              depotLocationId: res.data?.productRequests?.depotLocationId,
              dtLocalDeliverBegins:
                res.data?.productRequests?.dtLocalDeliverBegins,
              dtLocalDeliverEnds: res.data?.productRequests?.dtLocalDeliverEnds,
              dtLocalReturnBegins:
                res.data?.productRequests?.dtLocalReturnBegins,
              dtLocalReturnEnds: res.data?.productRequests?.dtLocalReturnEnds,
              dtUtcDeliverBegins: res.data?.productRequests?.dtUtcDeliverBegins,
              dtUtcDeliverEnds: res.data?.productRequests?.dtUtcDeliverEnds,
              dtUtcReturnBegins: res.data?.productRequests?.dtUtcReturnBegins,
              dtUtcReturnEnds: res.data?.productRequests?.dtUtcReturnEnds,
              hasAddOns: res.data?.productRequests?.hasAddOns,
              hasBundledItems: res.data?.productRequests?.hasBundledItems,
              hasCheckoutAttributes:
                res.data?.productRequests?.hasCheckoutAttributes,
              hasDamageProtection:
                res.data?.productRequests?.hasDamageProtection,
              hasNativeAttributes:
                res.data?.productRequests?.hasNativeAttributes,
              hasProfiledItems: res.data?.productRequests?.hasProfiledItems,
              utcOffset: res.data?.productRequests?.utcOffset,
            },
          ],
          // username: (user && user.email) || "anonymous",

          // startDate: moment(dateRange.startDate).format("MM/DD/YYYY HH:mm:ss"),
          // endDate: moment(dateRange.endDate).format("MM/DD/YYYY HH:mm:ss"),
          // region: region && region.id,
          // regionName: region && region.name,

          type: "Rent",
          method: itemRentInfo.method,
        });

        return res.data;
      }
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      console.log("error", e);
      throw e;
    }
  }
);

// Add multiple products to the cart
export const addMultipleToCart = createAsyncThunk(
  "cart/addMultipleToCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const storeId = getStoreId(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;
    const cart = getCartFromRedux(thunkAPI.getState());
    const itemRentInfo = getItemRentInfo(thunkAPI.getState());
    const item = getProductById(thunkAPI.getState()).data;

    const sourceType =
      orderSources.find((c) => c.name === itemRentInfo.client) || "0";

    const fulfillmentType =
      data.fulfillmentType ||
      itemRentInfo?.fulfillmentType?.id ||
      fulfillmentTypes.Courier.id;
    const experienceType =
      data.experienceType ||
      itemRentInfo?.experienceType?.id ||
      item.experienceTypeObject?.id;
    const deliveryModel = null; // data.DeliveryModel || itemRentInfo.deliveryModel || item.deliveryModel;

    const dateRange = getDateRange(thunkAPI.getState());

    const mappedData = data.Products.map((product) => {
      const productInCart =
        cart?.productRequests?.find((p) => p.productId === product.productId) ||
        null;
      const addOns = productInCart?.addOns || null;
      return {
        ProductId: product.resourceId || product.productId,
        // DeliveryModel: deliveryModel,
        utcBegins: data.utcBegins || dateRange.startDate,
        utcEnds: data.utcEnds || dateRange.endDate,
        Quantity: product.quantity,
        PromoCode: data.PromoCode,
        AddOns: product.AddOns || addOns || [],
        BundledItems: data.BundledItems,
        NativeAttributes: data.NativeAttributes,
        ProfiledAttributes: data.ProfiledAttributes,
        CheckoutAttributes: data.CheckoutAttributes,
        hasDamageProtection: product.hasDamageProtection,
        ...(storeId && { forceDepotID: storeId }),
      };
    });

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/cart/products`,
        mappedData,
        {
          params: {
            ...(cartkey ? { cartkey } : ip && { ip }),
            ...(data?.forceNewCart && { forceNewCart: data.forceNewCart }),
            promocode: data.PromoCode,
            zipPostal: data.zip,
            sourceType,
            fulfillmentType,
            experienceType,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      console.log("error", e);
      throw e;
    }
  }
);

export const addMultipleToCartAppointment = createAsyncThunk(
  "cart/addMultipleToCartAppointment",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;
    const cart = getCartFromRedux(thunkAPI.getState());
    const itemRentInfo = getItemRentInfo(thunkAPI.getState());
    const sourceType =
      orderSources.find((c) => c.name === itemRentInfo.client) || "0";
    const fulfillmentType =
      itemRentInfo?.fulfillmentType?.id || fulfillmentTypes.Courier.id;
    const experienceType =
      itemRentInfo?.experienceType?.id || experienceTypes.TryAndBuy.id;
    const deliveryModel = data?.deliveryModel
      ? data.deliveryModel
      : data.Products[0]
      ? data.Products[0]?.deliveryModel
      : 9;

    const _utcBegins =
      cart && cart.productRequests[0]
        ? cart.productRequests[0].utcBegins
        : moment().add(1, "day").toISOString();

    const _utcEnds =
      cart && cart.productRequests[0]
        ? cart.productRequests[0].utcEnds
        : moment().add(1, "day").toISOString();

    // const dateRange = getDateRange(thunkAPI.getState());
    const mappedData = data.Products.map((product) => {
      const productInCart =
        cart?.productRequests?.find((p) => p.productId === product.productId) ||
        null;
      const addOns = productInCart?.addOns || null;
      return {
        ProductId: product.resourceId || product.productId,
        DeliveryModel: deliveryModel,
        utcBegins: _utcBegins,
        utcEnds: _utcEnds,
        Quantity: product.quantity,
        PromoCode: data.PromoCode,
        AddOns: product.AddOns || addOns || [],
        BundledItems: data.BundledItems,
        NativeAttributes: data.NativeAttributes,
        ProfiledAttributes: data.ProfiledAttributes,
        CheckoutAttributes: data.CheckoutAttributes,
        hasDamageProtection: product.hasDamageProtection,
        deliveryModel,
      };
    });

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/cart/products`,
        mappedData,
        {
          params: {
            ...(cartkey ? { cartkey } : ip && { ip }),
            promocode: data.PromoCode,
            zipPostal: data.zip,
            sourceType,
            fulfillmentType,
            experienceType,
            forceNewCart: data.forceNewCart,
            // deliveryType: deliveryModel || data?.Products[0]?.deliveryModel,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      console.log("error", e);
      throw e;
    }
  }
);

export const fetchCartAddOnMenu = createAsyncThunk(
  "cart/fetchCartAddOnMenu",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const ip = getLocationByIP(thunkAPI.getState()).data?.ip;
    if (typeof data.accessories === "undefined") data.accessories = null;
    try {
      const res = await axios.get(`${serverUrl}/api/v1/cart/addonmenu`, {
        params: {
          ...(cartkey ? { cartkey } : ip && { ip }),
          accessories: data.accessories,
        },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (res.data?.products?.length > 0) {
        res.data.products = res.data?.products?.filter(
          (p) => p.groups.length > 0
        );
        // @todo: filter unavailable addons (can be happen in backend side too)
        res.data.products = res.data.products?.map((product) => {
          if (product?.groups?.length > 0) {
            product.groups = product.groups?.map((group) => {
              group.addOns = group.addOns?.filter(
                (addOn) => addOn.maxAvailable !== 0
              );
              return group;
            });
            return product;
          }
        });
      }
      if (res.data?.products?.length > 0) {
        return res.data;
      }
      data.nextStep();
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const setCurrentCartLocation = createAsyncThunk(
  "cart/setCurrentCartLocation",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const cartItems = getCartItems(thunkAPI.getState());

    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/cart/location`,
        // mappedData,
        null,
        {
          params: {
            cartkey,
            locationId: data.locationId,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      let goToNext = null;
      if (
        cartItems?.length > 0 &&
        (!res.data.productRequests ||
          (res.data.productRequests && res.data.productRequests?.length < 1))
      ) {
        goToNext = false;
      } else {
        goToNext = true;
      }
      if (goToNext && data.nextStep) {
        data.nextStep(data.values);
      } else if (data.handleOpenCartResetDialog) {
        data.handleOpenCartResetDialog(goToNext);
      }
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      console.log("error", e);
      throw e;
    }
  }
);

export const addOnsToCart = createAsyncThunk(
  "cart/addOnsToCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const productRequests = getCartItems(thunkAPI.getState());

    const mappedData = {
      cartKey: cartkey,
      addons: data.AddOns,
      productRequestId: productRequests[0].id ?? null,
    };

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/cart/addons`,
        mappedData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (
        res &&
        res.data &&
        res.data.productRequests &&
        res.data.productRequests.length > 0
      ) {
        const consolidatedProductRequests = consolidateItemsArray(
          res.data.productRequests
        );

        return { ...res.data, productRequests: consolidatedProductRequests };
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      console.log("error", e);
      throw e;
    }
  }
);

export const addPromoCodeToCart = createAsyncThunk(
  "cart/addPromoCodeToCart",
  async (data, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const cartkey = getCartKey(thunkAPI.getState());
    const cart = getCartFromRedux(thunkAPI.getState());

    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/cart/promo/${data.promoCode}`,
        null,
        {
          params: {
            cartKey: cartkey,
          },
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (
        data?.promoCode &&
        (!cart.promoCredit || cart.promoCredit === "") &&
        cart.discounts?.amount === 0 &&
        (!res.data.promoCredit || res.data.promoCredit === "") &&
        res.data.discounts?.amount === 0
      ) {
        data?.callback?.((_prev) => _prev + 1);
        // dispatch(showSnackbar(`Promo Code "${promoCode}" Not Valid!`, "error"));
        // dispatch(setOrderPromoCode(null));
        thunkAPI.dispatch(
          showSnackbar(`Promo Code "${data?.promoCode}" Not Valid!`, "error")
        );
        thunkAPI.dispatch(setOrderPromoCode(null));
      }
      if (
        data?.promoCode &&
        (!cart.promoCredit || cart.promoCredit === "") &&
        cart.discounts?.amount === 0 &&
        (res.data.promoCredit || res.data.promoCredit !== "") &&
        res.data.discounts?.amount > 0
      ) {
        data?.callback?.((_prev) => -1);
        // dispatch(showSnackbar("Promo Code Successfully Applied!", "success"));
        thunkAPI.dispatch(
          showSnackbar("Promo Code Successfully Applied!", "success")
        );
      }

      if (res.status === 200) {
        if (
          res &&
          res.data &&
          res.data.productRequests &&
          res.data.productRequests.length > 0
        ) {
          const consolidatedProductRequests = consolidateItemsArray(
            res.data.productRequests
          );

          return { ...res.data, productRequests: consolidatedProductRequests };
        }
        return res.data;
      }
    } catch (e) {
      data?.callback?.((_prev) => _prev + 1);
      thunkAPI.dispatch(setOrderPromoCode(null));
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e),
          JSON.stringify(`promo=${data.promoCode}&cartKey=${cartkey}`)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "cart",
  initialState: {
    cart: {},
    cartSelectedItemIds: [],
    addOns: [],
    allAddOns: {},
    chosenProduct: [],
    cartkey: localStorage.getItem("cartkey") || "",
    fetching: false,
    error: false,
    meta: {
      pageNumber: 1,
      pageSize: 25,
      totalPages: 0,
    },
    wasCartReset: false,
  },
  reducers: {
    setCartItems(state, action) {
      if (state.cart) {
        state.cart.productRequests = action.payload;
      }

      state.cartSelectedItemIds = action.payload.map(
        (p) => p.productId || p.resourceId
      );
    },

    setCartAddOns(state, action) {
      state.addOns = action.payload;
    },
    setCartTotals(state, action) {
      state.cart = {
        ...state.cart,
        subtotal: { amount: action.payload.subtotal || 0 },
        salesTax: { amount: action.payload.salesTax || 0 },
        delivery: { amount: action.payload.delivery || 0 },
        total: { amount: action.payload.total || 0 },
      };
    },
    resetCart(state) {
      state.cart = null;
      state.cartkey = null;
      state.cartSelectedItemIds = [];
      state.addOns = [];
      state.allAddOns = [];
      state.fetching = false;
      state.error = false;
      localStorage.setItem("cartkey", "");
    },
    setClosedCart(state, action) {
      state.cart = action.payload;
      state.cartkey = "";
      state.cartSelectedItemIds = action.payload?.productRequests?.map(
        (p) => p.productId
      );
      state.addOns = [].concat.apply(
        [],
        action.payload?.productRequests?.map((p) => p.addOns)
      );
      state.fetching = false;
      state.error = false;
      localStorage.setItem("cartkey", "");
    },
    setCart(state, action) {
      state.cart = action.payload;
      state.cartkey = action.payload.key;
      state.cartSelectedItemIds = action.payload?.productRequests?.map(
        (p) => p.productId
      );
      state.addOns = [].concat.apply(
        [],
        action.payload?.productRequests?.map((p) => p.addOns)
      );
      state.fetching = false;
      state.error = false;
      localStorage.setItem("cartkey", action.payload.key);
    },
    setWasCartReset(state, action) {
      state.wasCartReset = action.payload;
    },
    setChosenProduct(state, action) {
      state.chosenProduct = action.payload;
      state.cartSelectedItemIds = action.payload.map(
        (p) => p.productId || p.resourceId
      );
    },
    setCourierTotal(state, action) {
      state.cart = {
        ...state.cart,
        delivery: { ...state.cart?.delivery, amount: action.payload || 0 },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(getCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.meta.pageNumber = 1;
        state.meta.pageSize = 25;
        state.meta.totalPages = 0;
        state.cart = {};
        state.cartSelectedItemIds = [];
        state.addOns = [];
      })
      .addCase(getCart.fulfilled, (state, { payload }) => {
        state.fetching = false;
        state.error = false;
        state.cart = payload;
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.addOns = [].concat.apply(
          [],
          payload?.productRequests?.map((p) => p.addOns)
        );
        state.cartkey = payload?.key || "";
        localStorage.setItem("cartkey", payload?.key || "");
        // state.wasCartReset = false;
      })

      .addCase(addToCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(addToCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(addToCart.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload?.key || "";
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.addOns = [].concat.apply(
          [],
          payload?.productRequests?.map((p) => p.addOns)
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload?.key || "");
      })
      .addCase(addMultipleToCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(addMultipleToCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(addMultipleToCart.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload?.key || "";
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.addOns = [].concat.apply(
          [],
          payload?.productRequests?.map((p) => p.addOns)
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload?.key || "");
      })
      .addCase(deleteCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(deleteCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(deleteCart.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload.key;
        state.cartSelectedItemIds = [];
        state.addOns = [];
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload.key);
      })
      .addCase(removeFromCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(removeFromCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(removeFromCart.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload.key;
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload.key);
      })
      .addCase(removeAddOnFromCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(removeAddOnFromCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(removeAddOnFromCart.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload.key;
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload.key);
      })
      // removeDamageProtectionFromCart
      .addCase(removeDamageProtectionFromCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(removeDamageProtectionFromCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(
        removeDamageProtectionFromCart.fulfilled,
        (state, { payload }) => {
          state.cart = payload;
          state.cartkey = payload.key;
          state.cartSelectedItemIds = payload?.productRequests?.map(
            (p) => p.productId
          );
          state.fetching = false;
          state.error = false;
          localStorage.setItem("cartkey", payload.key);
        }
      )
      .addCase(fetchCartAddOnMenu.pending, (state) => {
        state.addOnsMenuFetching = true;
        state.error = false;
      })
      .addCase(fetchCartAddOnMenu.rejected, (state) => {
        state.addOnsMenuFetching = false;
        state.error = true;
        state.allAddOns = [];
      })
      .addCase(fetchCartAddOnMenu.fulfilled, (state, { payload }) => {
        state.allAddOns = payload;

        state.addOnsMenuFetching = false;
        state.error = false;
      })
      .addCase(addOnsToCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(addOnsToCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(addOnsToCart.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload?.key || "";
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.addOns = [].concat.apply(
          [],
          payload?.productRequests?.map((p) => p.addOns)
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload?.key);
      })
      .addCase(setCurrentCartLocation.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(setCurrentCartLocation.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(setCurrentCartLocation.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload?.key || "";
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.addOns = [].concat.apply(
          [],
          payload?.productRequests?.map((p) => p.addOns)
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload?.key);
      })
      .addCase(addPromoCodeToCart.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(addPromoCodeToCart.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(addPromoCodeToCart.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload?.key || "";
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.addOns = [].concat.apply(
          [],
          payload?.productRequests?.map((p) => p.addOns)
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload?.key);
      })
      .addCase(addMultipleToCartAppointment.pending, (state) => {
        state.fetching = true;
      })
      .addCase(addMultipleToCartAppointment.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(addMultipleToCartAppointment.fulfilled, (state, { payload }) => {
        state.cart = payload;
        state.cartkey = payload?.key || "";
        state.cartSelectedItemIds = payload?.productRequests?.map(
          (p) => p.productId
        );
        state.addOns = [].concat.apply(
          [],
          payload?.productRequests?.map((p) => p.addOns)
        );
        state.fetching = false;
        state.error = false;
        localStorage.setItem("cartkey", payload?.key || "");
      });
  },
});

export const {
  setCartAddOns,
  setCartItems,
  setCartTotals,
  resetCart,
  setClosedCart,
  setCart,
  setWasCartReset,
  setChosenProduct,
  setCourierTotal,
} = slice.actions;
export default slice.reducer;
