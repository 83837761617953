import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ItemTile from "./ItemTile";
import { fetchProductsTrending } from "../../actions";
import { getProductsTrending } from "../../reducers/itemsReducer";
import SkiptiSpinner from "../skipti/Spinner";

export default function ProductsTrending({
  noProducts,
  notResponsive,
  myItems,
}) {
  const recentProductsData = useSelector(getProductsTrending, (l, r) => {
    return _.isEqual(l, r);
  });
  const dispatch = useDispatch();
  const rDataB = !!recentProductsData.data;
  useEffect(() => {
    if (
      !recentProductsData.data &&
      !recentProductsData.isFetching &&
      !recentProductsData.isError
    ) {
      dispatch(fetchProductsTrending());
    }
  }, [dispatch, recentProductsData.isFetching, rDataB, recentProductsData]);
  const recentProducts =
    recentProductsData && recentProductsData.isLoaded && recentProductsData.data
      ? recentProductsData.data
      : [];
  return (
    <>
      {recentProducts.length !== 0
        ? recentProducts.map((item, index) => (
            <ItemTile
              {...item}
              index={index}
              notResponsive={notResponsive}
              noOfProducts={noProducts}
              myItems={myItems}
              key={item.resourceId}
            />
          ))
        : Array(noProducts)
            .fill({})
            .map((item, index) => (
              <ItemTile
                {...item}
                index={index}
                notResponsive={notResponsive}
                noOfProducts={noProducts}
                myItems={myItems}
                key={index}
              />
            ))}
    </>
  );
}
ProductsTrending.propTypes = {
  noProducts: PropTypes.number,
};
ProductsTrending.defaultProps = {
  noProducts: 6,
};

// class ProductsTrending extends Component {
//   static defaultProps = {
//     noProducts: 6,
//   };

//   componentDidMount() {
//     if (
//       this.props.recentProducts &&
//       // this.props.recentProducts.isLoaded &&
//       !this.props.recentProducts.data
//     ) {
//       this.props.fetchProductsTrending();
//     }
//   }

//   render() {
//     const recentProducts =
//       this.props.recentProducts &&
//       this.props.recentProducts.isLoaded &&
//       this.props.recentProducts.data
//         ? this.props.recentProducts.data
//         : [];
//     const { noProducts } = this.props;
//     return (
//       <>
//         {/* <SkiptiSpinner
//           loading={this.props.recentProducts.isFetching}
//           opacity="0"
//           absolute={false}
//         /> */}
//         {recentProducts.length !== 0
//           ? recentProducts.map((item, index) => (
//               <ItemTile
//                 {...item}
//                 index={index}
//                 notResponsive={this.props.notResponsive}
//                 noOfProducts={this.props.noProducts}
//                 myItems={this.props.myItems}
//                 key={item.resourceId}
//               />
//             ))
//           : Array(noProducts)
//               .fill({})
//               .map((item, index) => (
//                 <ItemTile
//                   {...item}
//                   index={index}
//                   notResponsive={this.props.notResponsive}
//                   noOfProducts={this.props.noProducts}
//                   myItems={this.props.myItems}
//                   key={index}
//                 />
//               ))}
//         {/* ))} */}
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   recentProducts: getProductsTrending(state),
// });

// export default connect(mapStateToProps, { fetchProductsTrending })(
//   ProductsTrending
// );
