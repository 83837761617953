export const contentTypes = [
  {
    id: 0,
    name: "Experience",
  },
  {
    id: 1,
    name: "TopTens",
  },
  {
    id: 2,
    name: "Ideas",
  },
  {
    id: 3,
    name: "Blogs",
  },
];

export const SET_ITEM_PRICE = "SET_ITEM_PRICE";
export const SET_IS_FROM_CLIENT = "SET_IS_FROM_CLIENT";
export const PRODUCT_BYID_ERROR = "PRODUCT_BYID_ERROR";

export const SHOW_PRODUCTS_IN_POPUP = "SHOW_PRODUCTS_IN_POPUP";
export const HIDE_PRODUCTS_IN_POPUP = "HIDE_PRODUCTS_IN_POPUP";

export const ADDON_SELECT = "ADDON_SELECT";
export const ADDON_REMOVE = "ADDON_REMOVE";

export const CHANGE_REGION = "CHANGE_REGION";
export const SET_ITEM_RENT_QUERY_STRING_OPTIONS =
  "SET_ITEM_RENT_QUERY_STRING_OPTIONS";
export const RESET_ITEM_RENT_QUERY_STRING_OPTIONS =
  "RESET_ITEM_RENT_QUERY_STRING_OPTIONS";
export const FETCH_REGIONS_REQUEST = "FETCH_REGIONS_REQUEST";
export const FETCH_REGIONS_SUCCESS = "FETCH_REGIONS_SUCCESS";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const STRIPE_FALLBACK_LOCATION_SET = "STRIPE_FALLBACK_LOCATION_SET";

export const CATEGORY_BYID_REQUEST = "CATEGORY_BYID_REQUEST";
export const CATEGORY_BYID_SUCCESS = "CATEGORY_BYID_SUCCESS";
export const CATEGORY_BYID_FAIL = "CATEGORY_BYID_FAIL";

export const CATEGORY_ALL_REQUEST = "CATEGORY_ALL_REQUEST";
export const CATEGORY_ALL_SUCCESS = "CATEGORY_ALL_SUCCESS";
export const CATEGORY_ALL_FAIL = "CATEGORY_ALL_FAIL";

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAIL = "CURRENT_USER_FAIL";

export const ARTICLE_BYID_REQUEST = "ARTICLE_BYID_REQUEST";
export const ARTICLE_BYID_SUCCESS = "ARTICLE_BYID_SUCCESS";
export const ARTICLE_BYID_FAIL = "ARTICLE_BYID_FAIL";

export const ARTICLES_ALL_REQUEST = "ARTICLES_ALL_REQUEST";
export const ARTICLES_ALL_SUCCESS = "ARTICLES_ALL_SUCCESS";
export const ARTICLES_ALL_FAIL = "ARTICLES_ALL_FAIL";
export const ARTICLES_ADMIN_TEASERS_REQUEST = "ARTICLES_ADMIN_TEASERS_REQUEST";
export const ARTICLES_ADMIN_TEASERS_SUCCESS = "ARTICLES_ADMIN_TEASERS_SUCCESS";
export const ARTICLES_ADMIN_TEASERS_FAIL = "ARTICLES_ADMIN_TEASERS_FAIL";

export const EXPERIENCES_ALL_REQUEST = "EXPERIENCES_ALL_REQUEST";
export const EXPERIENCES_ALL_SUCCESS = "EXPERIENCES_ALL_SUCCESS";
export const EXPERIENCES_ALL_FAIL = "EXPERIENCES_ALL_FAIL";

export const EXPERIENCES_BYID_REQUEST = "EXPERIENCES_BYID_REQUEST";
export const EXPERIENCES_BYID_SUCCESS = "EXPERIENCES_BYID_SUCCESS";
export const EXPERIENCES_BYID_FAIL = "EXPERIENCES_BYID_FAIL";

export const ITEMS_NEEDS_SIZE = "ITEMS_NEEDS_SIZE";
export const ITEMS_SIZE_CHANGE = "ITEMS_SIZE_CHANGE";
export const ITEMS_COLOR_CHANGE = "ITEMS_COLOR_CHANGE";

export const ITEMS_BYOWNERID_REQUEST = "ITEMS_BYOWNERID_REQUEST";
export const ITEMS_BYOWNERID_SUCCESS = "ITEMS_BYOWNERID_SUCCESS";
export const ITEMS_BYOWNERID_FAIL = "ITEMS_BYOWNERID_FAIL";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";

export const DASHBOARD_OVERVIEWDATA_REQUEST = "DASHBOARD_OVERVIEWDATA_REQUEST";
export const DASHBOARD_OVERVIEWDATA_SUCCESS = "DASHBOARD_OVERVIEWDATA_SUCCESS";
export const DASHBOARD_OVERVIEWDATA_FAIL = "DASHBOARD_OVERVIEWDATA_FAIL";

export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

// USERS_INFO_BYOWNERID_REQUEST
export const USERS_INFO_BYOWNERID_REQUEST = "USERS_INFO_BYOWNERID_REQUEST";
export const USERS_INFO_BYOWNERID_SUCCESS = "USERS_INFO_BYOWNERID_SUCCESS";
export const USERS_INFO_BYOWNERID_FAIL = "USERS_INFO_BYOWNERID_FAIL";

// TYPES.USERS_REVIEWS_BYOWNERID_REQUEST
export const USER_REVIEWS_ASRENTER_REQUEST = "USER_REVIEWS_ASRENTER_REQUEST";
export const USER_REVIEWS_ASRENTER_SUCCESS = "USER_REVIEWS_ASRENTER_SUCCESS";
export const USER_REVIEWS_ASRENTER_FAIL = "USER_REVIEWS_ASRENTER_FAIL";
export const USER_REVIEWS_ASOWNER_REQUEST = "USER_REVIEWS_ASOWNER_REQUEST";
export const USER_REVIEWS_ASOWNER_SUCCESS = "USER_REVIEWS_ASOWNER_SUCCESS";
export const USER_REVIEWS_ASOWNER_FAIL = "USER_REVIEWS_ASOWNER_FAIL";

export const DATERANGE_SET_SUCCESS = "DATERANGE_SET_SUCCESS";
export const DATERANGE_SET_START_SUCCESS = "DATERANGE_SET_START_SUCCESS";
export const DATERANGE_SET_END_SUCCESS = "DATERANGE_SET_END_SUCCESS";

// PRODUCT_BYCATEGORY
export const PRODUCTS_BYCATEGORY_REQUEST = "PRODUCTS_BYCATEGORY_REQUEST";
export const PRODUCTS_BYCATEGORY_SUCCESS = "PRODUCTS_BYCATEGORY_SUCCESS";
export const PRODUCTS_BYCATEGORY_FAIL = "PRODUCTS_BYCATEGORY_FAIL";

// PRODUCT_BYID
export const PRODUCT_BYID_REQUEST = "PRODUCT_BYID_REQUEST";
export const PRODUCT_BYID_SUCCESS = "PRODUCT_BYID_SUCCESS";
export const PRODUCT_BYID_FAIL = "PRODUCT_BYID_FAIL";
export const PRODUCT_BYID_RESET = "PRODUCT_BYID_RESET";
// AUTH_RETURN_URL

export const SET_RETURN_URL = "SET_RETURN_URL";

// LOGIN_MODAL_OPEN
export const LOGIN_MODAL_OPEN = "LOGIN_MODAL_OPEN";
export const LOGIN_MODAL_CLOSE = "LOGIN_MODAL_CLOSE";

export const PRODUCT_BYSEARCH_REQUEST = "PRODUCT_BYSEARCH_REQUEST";
export const PRODUCT_BYSEARCH_SUCCESS = "PRODUCT_BYSEARCH_SUCCESS";
export const PRODUCT_BYSEARCH_FAIL = "PRODUCT_BYSEARCH_FAIL";

// USERS_BYORGANIZATIONID
export const USERS_BYORGANIZATIONID_REQUEST = "USERS_BYORGANIZATIONID_REQUEST";
export const USERS_BYORGANIZATIONID_SUCCESS = "USERS_BYORGANIZATIONID_SUCCESS";
export const USERS_BYORGANIZATIONID_FAIL = "USERS_BYORGANIZATIONID_FAIL";

// ORGANIZATION_INFO_BYID
export const ORGANIZATION_INFO_BYID_REQUEST = "ORGANIZATION_INFO_BYID_REQUEST";
export const ORGANIZATION_INFO_BYID_SUCCESS = "ORGANIZATION_INFO_BYID_SUCCESS";
export const ORGANIZATION_INFO_BYID_FAIL = "ORGANIZATION_INFO_BYID_FAIL";
// ORGANIZATION_INFO_BYNAME
export const ORGANIZATION_INFO_BYNAME_REQUEST =
  "ORGANIZATION_INFO_BYNAME_REQUEST";
export const ORGANIZATION_INFO_BYNAME_SUCCESS =
  "ORGANIZATION_INFO_BYNAME_SUCCESS";
export const ORGANIZATION_INFO_BYNAME_FAIL = "ORGANIZATION_INFO_BYNAME_FAIL";

// ORGANIZATION_INFO_BYNAME_RESET
export const ORGANIZATION_INFO_BYNAME_RESET = "ORGANIZATION_INFO_BYNAME_RESET";

// ITEMS_BYORGANIZATIONID
export const ITEMS_BYORGANIZATIONID_REQUEST = "ITEMS_BYORGANIZATIONID_REQUEST";
export const ITEMS_BYORGANIZATIONID_SUCCESS = "ITEMS_BYORGANIZATIONID_SUCCESS";
export const ITEMS_BYORGANIZATIONID_FAIL = "ITEMS_BYORGANIZATIONID_FAIL";

// // INVENTORY_BYPRODUCTID
// export const INVENTORY_BYPRODUCTID_REQUEST = "INVENTORY_BYPRODUCTID_REQUEST";
// export const INVENTORY_BYPRODUCTID_SUCCESS = "INVENTORY_BYPRODUCTID_SUCCESS";
// export const INVENTORY_BYPRODUCTID_FAIL = "INVENTORY_BYPRODUCTID_FAIL";

// PRODUCTS_BYRECENTLYVIEWED
export const PRODUCTS_BYRECENTLYVIEWED_REQUEST =
  "PRODUCTS_BYRECENTLYVIEWED_REQUEST";
export const PRODUCTS_BYRECENTLYVIEWED_SUCCESS =
  "PRODUCTS_BYRECENTLYVIEWED_SUCCESS";
export const PRODUCTS_BYRECENTLYVIEWED_FAIL = "PRODUCTS_BYRECENTLYVIEWED_FAIL";

// PRODUCTS_TRENDING
export const PRODUCTS_TRENDING_REQUEST = "PRODUCTS_TRENDING_REQUEST";
export const PRODUCTS_TRENDING_SUCCESS = "PRODUCTS_TRENDING_SUCCESS";
export const PRODUCTS_TRENDING_FAIL = "PRODUCTS_TRENDING_FAIL";

// PRODUCTS_FEATURED
export const PRODUCTS_FEATURED_REQUEST = "PRODUCTS_FEATURED_REQUEST";
export const PRODUCTS_FEATURED_SUCCESS = "PRODUCTS_FEATURED_SUCCESS";
export const PRODUCTS_FEATURED_FAIL = "PRODUCTS_FEATURED_FAIL";

// PRODUCTS_BYRECENTLYUPLOADED
export const PRODUCTS_BYRECENTLYUPLOADED_REQUEST =
  "PRODUCTS_BYRECENTLYUPLOADED_REQUEST";
export const PRODUCTS_BYRECENTLYUPLOADED_SUCCESS =
  "PRODUCTS_BYRECENTLYUPLOADED_SUCCESS";
export const PRODUCTS_BYRECENTLYUPLOADED_FAIL =
  "PRODUCTS_BYRECENTLYUPLOADED_FAIL";

export const ADDRESSES_BYUSERID_REQUEST = "ADDRESSES_BYUSERID_REQUEST";
export const ADDRESSES_BYUSERID_SUCCESS = "ADDRESSES_BYUSERID_SUCCESS";
export const ADDRESSES_BYUSERID_FAIL = "ADDRESSES_BYUSERID_FAIL";

export const PRODUCT_BYID_DELETE_REQUEST = "PRODUCT_BYID_DELETE_REQUEST";
export const PRODUCT_BYID_DELETE_SUCCESS = "PRODUCT_BYID_DELETE_SUCCESS";
export const PRODUCT_BYID_DELETE_FAIL = "PRODUCT_BYID_DELETE_FAIL";

export const CREATE_ADDRESS_REQUEST = "CREATE_ADDRESS_REQUEST";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAIL = "CREATE_ADDRESS_FAIL";

export const ADDRESS_BYID_DELETE_REQUEST = "ADDRESS_BYID_DELETE_REQUEST";
export const ADDRESS_BYID_DELETE_SUCCESS = "ADDRESS_BYID_DELETE_SUCCESS";
export const ADDRESS_BYID_DELETE_FAIL = "ADDRESS_BYID_DELETE_FAIL";

export const ADDRESS_BYID_REQUEST = "ADDRESS_BYID_REQUEST";
export const ADDRESS_BYID_SUCCESS = "ADDRESS_BYID_SUCCESS";
export const ADDRESS_BYID_FAIL = "ADDRESS_BYID_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_ADMIN_REGISTER_USER_REQUEST =
  "USER_ADMIN_REGISTER_USER_REQUEST";
export const USER_ADMIN_REGISTER_USER_SUCCESS =
  "USER_ADMIN_REGISTER_USER_SUCCESS";
export const USER_ADMIN_REGISTER_USER_FAIL = "USER_ADMIN_REGISTER_USER_FAIL";

export const SNACK_SHOW = "SNACK_SHOW";
export const SNACK_HIDE = "SNACK_HIDE";

export const USER_INITFLAGS_REQUEST = "USER_INITFLAGS_REQUEST";
export const USER_INITFLAGS_SUCCESS = "USER_INITFLAGS_SUCCESS";
export const USER_INITFLAGS_FAIL = "USER_INITFLAGS_FAIL";

export const AVAILABILITY_CALENDAR_FETCH_REQUEST =
  "AVAILABILITY_CALENDAR_FETCH_REQUEST";
export const AVAILABILITY_CALENDAR_FETCH_SUCCESS =
  "AVAILABILITY_CALENDAR_FETCH_SUCCESS";
export const AVAILABILITY_CALENDAR_FETCH_FAIL =
  "AVAILABILITY_CALENDAR_FETCH_FAIL";
export const AVAILABILITY_CALENDAR_FETCH_RESET =
  "AVAILABILITY_CALENDAR_FETCH_RESET";

export const SKIPPED_FLAGS_SET = "SKIPPED_FLAGS_SET";

export const DEFAULT_LOCATION_SET = "DEFAULT_LOCATION_SET";

// PRODUCTS_FORALLCATEGORIES
export const PRODUCTS_FORALLCATEGORIES_REQUEST =
  "PRODUCTS_FORALLCATEGORIES_REQUEST";
export const PRODUCTS_FORALLCATEGORIES_SUCCESS =
  "PRODUCTS_FORALLCATEGORIES_SUCCESS";
export const PRODUCTS_FORALLCATEGORIES_FAIL = "PRODUCTS_FORALLCATEGORIES_FAIL";
// PRODUCTS_FORALLCATEGORIES
export const PRODUCTS_FORALLCATEGORIES_BYORG_REQUEST =
  "PRODUCTS_FORALLCATEGORIES_BYORG_REQUEST";
export const PRODUCTS_FORALLCATEGORIES_BYORG_SUCCESS =
  "PRODUCTS_FORALLCATEGORIES_BYORG_SUCCESS";
export const PRODUCTS_FORALLCATEGORIES_BYORG_FAIL =
  "PRODUCTS_FORALLCATEGORIES_BYORG_FAIL";

// OWNER_SELECT_ITEM

export const ITEM_SELECT_BYOWNER = "ITEM_SELECT_BYOWNER";
export const ITEM_DELETE_BYOWNER = "ITEM_DELETE_BYOWNER";

export const ARTICLES_IDEA_REQUEST = "ARTICLES_IDEA_REQUEST";
export const ARTICLES_IDEA_SUCCESS = "ARTICLES_IDEA_SUCCESS";
export const ARTICLES_IDEA_FAIL = "ARTICLES_IDEA_FAIL";

export const ARTICLES_BLOG_REQUEST = "ARTICLES_BLOG_REQUEST";
export const ARTICLES_BLOG_SUCCESS = "ARTICLES_BLOG_SUCCESS";
export const ARTICLES_BLOG_FAIL = "ARTICLES_BLOG_FAIL";

export const ARTICLES_TOP10_REQUEST = "ARTICLES_TOP10_REQUEST";
export const ARTICLES_TOP10_SUCCESS = "ARTICLES_TOP10_SUCCESS";
export const ARTICLES_TOP10_FAIL = "ARTICLES_TOP10_FAIL";

// CUSTOMER_REVIEW_MODAL
export const CUSTOMER_REVIEW_MODAL_OPEN = "CUSTOMER_REVIEW_MODAL_OPEN";
export const CUSTOMER_REVIEW_MODAL_CLOSE = "CUSTOMER_REVIEW_MODAL_CLOSE";

// MERCHANT_REVIEW_MODAL
export const MERCHANT_REVIEW_MODAL_OPEN = "MERCHANT_REVIEW_MODAL_OPEN";
export const MERCHANT_REVIEW_MODAL_CLOSE = "MERCHANT_REVIEW_MODAL_CLOSE";

// DRIVER_REVIEW_MODAL
export const DRIVER_REVIEW_MODAL_OPEN = "DRIVER_REVIEW_MODAL_OPEN";
export const DRIVER_REVIEW_MODAL_CLOSE = "DRIVER_REVIEW_MODAL_CLOSE";

//
export const CUSTOMER_REVIEW_ASAUTHOR_REQUEST =
  "CUSTOMER_REVIEW_ASOWNER_REQUEST";
export const CUSTOMER_REVIEW_ASAUTHOR_SUCCESS =
  "CUSTOMER_REVIEW_ASOWNER_SUCCESS";
export const CUSTOMER_REVIEW_ASAUTHOR_FAILURE =
  "CUSTOMER_REVIEW_ASOWNER_FAILURE";

//
export const MERCHANT_REVIEW_ASAUTHOR_REQUEST =
  "MERCHANT_REVIEW_ASOWNER_REQUEST";
export const MERCHANT_REVIEW_ASAUTHOR_SUCCESS =
  "MERCHANT_REVIEW_ASOWNER_SUCCESS";
export const MERCHANT_REVIEW_ASAUTHOR_FAILURE =
  "MERCHANT_REVIEW_ASOWNER_FAILURE";

export const DRIVER_REVIEW_ASAUTHOR_REQUEST = "DRIVER_REVIEW_ASOWNER_REQUEST";
export const DRIVER_REVIEW_ASAUTHOR_SUCCESS = "DRIVER_REVIEW_ASOWNER_SUCCESS";
export const DRIVER_REVIEW_ASAUTHOR_FAILURE = "DRIVER_REVIEW_ASOWNER_FAILURE";

// PRODUCTS_VOTABLE
export const PRODUCTS_VOTABLE_REQUEST = "PRODUCTS_VOTABLE_REQUEST";
export const PRODUCTS_VOTABLE_SUCCESS = "PRODUCTS_VOTABLE_SUCCESS";
export const PRODUCTS_VOTABLE_FAIL = "PRODUCTS_VOTABLE_FAIL";

// DELIVERY_WINDOWS
export const DELIVERY_WINDOWS_REQUEST = "DELIVERY_WINDOWS_REQUEST";
export const DELIVERY_WINDOWS_SUCCESS = "DELIVERY_WINDOWS_SUCCESS";
export const DELIVERY_WINDOWS_FAIL = "DELIVERY_WINDOWS_FAIL";

// RETURN_WINDOWS
export const RETURN_WINDOWS_REQUEST = "RETURN_WINDOWS_REQUEST";
export const RETURN_WINDOWS_SUCCESS = "RETURN_WINDOWS_SUCCESS";
export const RETURN_WINDOWS_FAIL = "RETURN_WINDOWS_FAIL";

// ORDERS_FOR_CURRENT_USER
export const ORDERS_FOR_CURRENT_USER_REQUEST =
  "ORDERS_FOR_CURRENT_USER_REQUEST";
export const ORDERS_FOR_CURRENT_USER_SUCCESS =
  "ORDERS_FOR_CURRENT_USER_SUCCESS";
export const ORDERS_FOR_CURRENT_USER_FAIL = "ORDERS_FOR_CURRENT_USER_FAIL";

// ALL_ORDERS_FOR_ADMIN_USER
export const ALL_ORDERS_FOR_ADMIN_USER_REQUEST =
  "ALL_ORDERS_FOR_ADMIN_USER_REQUEST";
export const ALL_ORDERS_FOR_ADMIN_USER_SUCCESS =
  "ALL_ORDERS_FOR_ADMIN_USER_SUCCESS";
export const ALL_ORDERS_FOR_ADMIN_USER_FAIL = "ALL_ORDERS_FOR_ADMIN_USER_FAIL";

export const LOCATION_BYIP_REQUEST = "LOCATION_BYIP_REQUEST";
export const LOCATION_BYIP_SUCCESS = "LOCATION_BYIP_SUCCESS";
export const LOCATION_BYIP_FAIL = "LOCATION_BYIP_FAIL";

// ALL_ORDERS_FOR_ASSOCIATE_USER
export const ALL_ORDERS_BY_ORDER_ID_REQUEST = "ALL_ORDERS_BY_ORDER_ID_REQUEST";
export const ALL_ORDERS_BY_ORDER_ID_SUCCESS = "ALL_ORDERS_BY_ORDER_ID_SUCCESS";
export const ALL_ORDERS_BY_ORDER_ID_FAIL = "ALL_ORDERS_BY_ORDER_ID_FAIL";

// validation password code
export const SET_CODE_VALIDATION_START = "SET_CODE_VALIDATION_START";
export const SET_CODE_VALIDATION_SUCCESS = "SET_CODE_VALIDATION_SUCCESS";
export const SET_VALIDATION_END = "SET_VALIDATION_END";

// ORDERS_FOR_CURRENT_USER
// export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
// export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

// COMPLETE_ORDER_SUCCESS
export const COMPLETE_ORDER_SUCCESS = "COMPLETE_ORDER_SUCCESS";

export const SOCIAL_SHARE_MODAL_OPEN = "SOCIAL_SHARE_MODAL_OPEN";
export const SOCIAL_SHARE_MODAL_CLOSE = "SOCIAL_SHARE_MODAL_CLOSE";

export const SET_SOCIALID = "SET_SOCIALID";
export const SET_VOTE_FOR_PRODUCT_AFTER_AUTH =
  "SET_VOTE_FOR_PRODUCT_AFTER_AUTH";
export const SET_SIGN_IN_MESSAGE = "SET_SIGN_IN_MESSAGE";
export const SET_SUGGEST_PRODUCT_AFTER_AUTH = "SET_SUGGEST_PRODUCT_AFTER_AUTH";

// ORDER_BY_ID
export const ORDER_BY_ID_REQUEST = "ORDER_BY_ID_REQUEST";
export const ORDER_BY_ID_SUCCESS = "ORDER_BY_ID_SUCCESS";
export const ORDER_BY_ID_FAIL = "ORDER_BY_ID_FAIL";
export const ORDER_STATUS_HISTORY_BY_ID = "ORDER_STATUS_HISTORY_BY_ID";
export const ORDER_BY_ID_CLEANUP = "ORDER_BY_ID_CLEANUP";

// ORDER_SUB_ROUTES_BY_ID
export const ORDER_SUB_ROUTES_BY_ID_REQUEST = "ORDER_SUB_ROUTES_BY_ID_REQUEST";
export const ORDER_SUB_ROUTES_BY_ID_UPDATE = "ORDER_SUB_ROUTES_BY_ID_UPDATE";
export const ORDER_SUB_ROUTES_BY_ID_SUCCESS = "ORDER_SUB_ROUTES_BY_ID_SUCCESS";
export const ORDER_SUB_ROUTES_BY_ID_FAIL = "ORDER_SUB_ROUTES_BY_ID_FAIL";

// SUB_ROUTES_LOADING_BY_ORDER_ID
export const SUB_ROUTES_IS_LOADING_BY_ORDER_ID =
  "SUB_ROUTES_IS_LOADING_BY_ORDER_ID";
export const SUB_ROUTES_IS_LOADING_SUCCESS_BY_ORDER_ID =
  "SUB_ROUTES_IS_LOADING_SUCCESS_BY_ORDER_ID";

// REFRESH_GPS_FOR_ORDER
export const REFRESH_GPS_FOR_ORDER_REQUEST = "REFRESH_GPS_FOR_ORDER_REQUEST";
export const REFRESH_GPS_FOR_ORDER_SUCCESS = "REFRESH_GPS_FOR_ORDER_SUCCESS";
export const REFRESH_GPS_FOR_ORDER_FAIL = "REFRESH_GPS_FOR_ORDER_FAIL";

export const DELIVERY_WINDOWS_INVENTORY_BY_ZIP_REQUEST =
  "DELIVERY_WINDOWS_INVENTORY_BY_ZIP_REQUEST";
export const DELIVERY_WINDOWS_INVENTORY_BY_ZIP_SUCCESS =
  "DELIVERY_WINDOWS_INVENTORY_BY_ZIP_SUCCESS";
export const DELIVERY_WINDOWS_INVENTORY_BY_ZIP_FAIL =
  "DELIVERY_WINDOWS_INVENTORY_BY_ZIP_FAIL";

export const DELIVERY_OPTIONS_BY_ZIP_REQUEST =
  "DELIVERY_OPTIONS_BY_ZIP_REQUEST";
export const DELIVERY_OPTIONS_BY_ZIP_SUCCESS =
  "DELIVERY_OPTIONS_BY_ZIP_SUCCESS";
export const DELIVERY_OPTIONS_BY_ZIP_FAIL = "DELIVERY_OPTIONS_BY_ZIP_FAIL";
export const DELIVERY_OPTIONS_BY_ZIP_RESET = "DELIVERY_OPTIONS_BY_ZIP_RESET";

export const DELIVERY_OPTIONS_BY_GPS_REQUEST =
  "DELIVERY_OPTIONS_BY_GPS_REQUEST";
export const DELIVERY_OPTIONS_BY_GPS_SUCCESS =
  "DELIVERY_OPTIONS_BY_GPS_SUCCESS";
export const DELIVERY_OPTIONS_BY_GPS_FAIL = "DELIVERY_OPTIONS_BY_GPS_FAIL";

export const DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_REQUEST =
  "DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_REQUEST";
export const DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_SUCCESS =
  "DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_SUCCESS";
export const DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_FAIL =
  "DELIVERY_WINDOWS_INVENTORY_BY_LAT_LONG_FAIL";

export const SET_IS_FROM_RUGDOCTOR = "SET_IS_FROM_RUGDOCTOR";

// LL_PRODUCT_TEASERS
export const ALL_PRODUCT_TEASERS_REQUEST = "ALL_PRODUCT_TEASERS_REQUEST";
export const ALL_PRODUCT_TEASERS_SUCCESS = "ALL_PRODUCT_TEASERS_SUCCESS";
export const ALL_PRODUCT_TEASERS_FAIL = "ALL_PRODUCT_TEASERS_FAIL";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const USER_GET_ZIP_FROM_LATLONG_REQUEST =
  "USER_GET_ZIP_FROM_LATLONG_REQUEST";
export const USER_GET_ZIP_FROM_LATLONG_SUCCESS =
  "USER_GET_ZIP_FROM_LATLONG_SUCCESS";
export const USER_GET_ZIP_FROM_LATLONG_FAIL = "USER_GET_ZIP_FROM_LATLONG_FAIL";

export const USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_REQUEST =
  "USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_REQUEST";
export const USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_SUCCESS =
  "USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_SUCCESS";
export const USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_FAIL =
  "USER_ZIP_IS_SERVICEABLE_FOR_PRODUCT_FAIL";

export const ZIP_MODAL_OPEN = "ZIP_MODAL_OPEN";
export const ZIP_MODAL_CLOSE = "ZIP_MODAL_CLOSE";
// SET_ZIP
export const SET_ZIP = "SET_ZIP";
export const SET_ZIP_ADDRESS = "SET_ZIP_ADDRESS";

export const ZIP_INFO_REQUEST = "ZIP_INFO_REQUEST";
export const ZIP_INFO_SUCCESS = "ZIP_INFO_SUCCESS";
export const ZIP_INFO_FAIL = "ZIP_INFO_FAIL";

export const RESET_ZIP = "RESET_ZIP";
export const RESET_ZIP_ADDRESS = "RESET_ZIP_ADDRESS";

// PRODUCTS_IN_REGION
export const PRODUCTS_IN_REGION_REQUEST = "PRODUCTS_IN_REGION_REQUEST";
export const PRODUCTS_IN_REGION_SUCCESS = "PRODUCTS_IN_REGION_SUCCESS";
export const PRODUCTS_IN_REGION_FAIL = "PRODUCTS_IN_REGION_FAIL";

export const ORDER_PROMO_CODE = "ORDER_PROMO_CODE";

// PRODUCTS_FEATURED
export const PRODUCT_ADD_ONS_BY_ZIP_REQUEST = "PRODUCT_ADD_ONS_BY_ZIPREQUEST";
export const PRODUCT_ADD_ONS_BY_ZIP_SUCCESS = "PRODUCT_ADD_ONS_BY_ZIPSUCCESS";
export const PRODUCT_ADD_ONS_BY_ZIP_FAIL = "PRODUCT_ADD_ONS_BY_ZIPFAIL";

export const PROFILED_PRODUCTS_BY_CATEGORYID_REQUEST =
  "PROFILED_PRODUCTS_BY_CATEGORYID_REQUEST";
export const PROFILED_PRODUCTS_BY_CATEGORYID_SUCCESS =
  "PROFILED_PRODUCTS_BY_CATEGORYID_SUCCESS";
export const PROFILED_PRODUCTS_BY_CATEGORYID_FAIL =
  "PROFILED_PRODUCTS_BY_CATEGORYID_FAIL";

export const PROFILED_RESOURCES_FOR_PRODUCT_REQUEST =
  "PROFILED_RESOURCES_FOR_PRODUCT_REQUEST";
export const PROFILED_RESOURCES_FOR_PRODUCT_SUCCESS =
  "PROFILED_RESOURCES_FOR_PRODUCT_SUCCESS";
export const PROFILED_RESOURCES_FOR_PRODUCT_FAIL =
  "PROFILED_RESOURCES_FOR_PRODUCT_FAIL";

export const PRODUCTS_WITH_FILTERS_REQUEST = "PRODUCTS_WITH_FILTERS_REQUEST";
export const PRODUCTS_WITH_FILTERS_SUCCESS = "PRODUCTS_WITH_FILTERS_SUCCESS";
export const PRODUCTS_WITH_FILTERS_FAIL = "PRODUCTS_WITH_FILTERS_FAIL";
export const PRODUCTS_WITH_FILTERS_RESET = "PRODUCTS_WITH_FILTERS_RESET";

// Organization products (SMS_BOOKING)
export const ORGANIZATION_PRODUCTS_REQUEST = "ORGANIZATION_PRODUCTS_REQUEST";
export const ORGANIZATION_PRODUCTS_SUCCESS = "ORGANIZATION_PRODUCTS_SUCCESS";
export const ORGANIZATION_PRODUCTS_FAIL = "ORGANIZATION_PRODUCTS_FAIL";
export const ORGANIZATION_PRODUCTS_CATEGORIES_SUCCESS =
  "ORGANIZATION_PRODUCTS_CATEGORIES_SUCCESS";
export const ORGANIZATION_PRODUCTS_RESET = "ORGANIZATION_PRODUCTS_RESET";
