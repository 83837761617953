const transformErrorsToList = (errors, prefix = "", result = []) => {
  Object.keys(errors).forEach((key) => {
    const value = errors[key];

    if (!value) return;

    const nextKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "object") {
      transformErrorsToList(value, nextKey, result);
    } else {
      result.push(nextKey);
    }
  });

  return result;
};

export const getFieldErrorNames = (formikErrors) => {
  return transformErrorsToList(formikErrors);
};
