import React, { Component } from "react";
// import PropTypes from "prop-types";

import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";

export default function LayoutCategory(props) {
  return (
    <>
      <Navbar showSearch={props.showSearch} />
      <div className="tw-w-full tw-mx-auto tw-px-0">{props.children}</div>
      <Footer />
    </>
  );
}
