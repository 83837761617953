import React, { Fragment, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { getUserRoles } from "../../reducers/authReducer";
import "./index.scss";
import useNavData from "./statData";
import ChangeZip from "../partials/ChangeZip";
import { addCollapseItem } from "../layouts/layoutDashboardSlice";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: "red",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#333333",
    color: "white",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  cityInput: {
    "& .Mui-focused": {
      color: "white",
      borderColor: "white",
    },
    "& .MuiInput-underline": {
      color: "white",
      "&:before": {
        borderColor: "white",
      },
      "&:after": {
        borderColor: "white",
      },
      "&:hover": {
        borderColor: "white",
      },
    },
  },
}));

const DrawerNav = ({
  toggleDrawer,
  drawerOpen,
  handleSignOut,
  redirectForBusiness,
  openModal,
  openRegisterModal,
  goToCart,
  cart,
  cartItemIds,
  isShowCartIcon,
  isShowingReminderText,
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [accessChecker, setAccessChecker] = useState({
    account: true,
    admin: true,
    dms: true,
    inventory: true,
    sendLink: true,
    permissionsData: {},
  });
  const [openCollapseTitle, setOpenCollapseTitle] = useState(null);
  const [layoutNavCollapse, setLayoutNavCollapse] = useState(null);

  const navData = useNavData(
    handleSignOut,
    redirectForBusiness,
    accessChecker,
    setAccessChecker,
    openModal,
    openRegisterModal
  );
  const dispatch = useDispatch();
  const { mainNavData, accountData, dashboardData } = navData;
  const roles = useSelector(getUserRoles);

  const classes = useStyles();

  const customerDashboardCollapse = () => {
    return (
      <List style={{ paddingLeft: "60px" }}>
        {accountData.map((accountTab, key) => {
          if (accountTab.customer) {
            return (
              <ListItem
                key={`${accountTab.id}-${key}`}
                onClick={() => {
                  accountTab.action();
                  toggleDrawer();
                }}
                disabled={accountTab.disabled}
                className={`tw-cursor-pointer ${
                  accountTab.disabled ? "tw-pointer-events-none" : ""
                }`}
              >
                <ListItemText>{accountTab.title}</ListItemText>
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    );
  };

  const renderSingleMenuItem = (itemName) => {
    const menuItem = navData.innerDataGenerator(itemName);

    return (
      <ListItem
        key={`${menuItem.id}-${menuItem.name}`}
        onClick={() => {
          menuItem.action();
          toggleDrawer();
        }}
        disabled={menuItem.disabled}
        className={`tw-cursor-pointer ${
          menuItem.disabled ? "tw-pointer-events-none" : ""
        }`}
      >
        <ListItemText>{menuItem.title}</ListItemText>
      </ListItem>
    );
  };

  const innerCollapse = (dashboardName) => {
    const dashboardInnerData = navData.innerDataGenerator(dashboardName);

    return (
      <List style={{ paddingLeft: "20px" }}>
        {dashboardInnerData.map((innerTab, key) => (
          <ListItem
            key={`${innerTab.id}-${key}`}
            className="tw-cursor-pointer"
            onClick={() => {
              innerTab.action();
              toggleDrawer();
              dispatch(addCollapseItem(layoutNavCollapse));
            }}
          >
            <ListItemText>{innerTab.title}</ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };

  const dashboardCollapse = () => {
    return (
      <List style={{ paddingLeft: "60px" }}>
        {dashboardData.map((dashboardTab, key) => {
          if (!accessChecker[dashboardTab.name]) {
            return null;
          }

          if (dashboardTab.isSingle) {
            return renderSingleMenuItem(dashboardTab.name);
          }

          return (
            <Fragment key={`${dashboardTab.id}-${key}`}>
              <ListItem
                button
                onClick={() => {
                  setOpenCollapseTitle((_prev) =>
                    dashboardTab.name === _prev ? null : dashboardTab.name
                  );
                  setLayoutNavCollapse(dashboardTab.id);
                }}
                className="tw-cursor-pointer"
              >
                <ListItemText>{dashboardTab.title}</ListItemText>

                {openCollapseTitle === dashboardTab.name ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={openCollapseTitle === dashboardTab.name}
                timeout="auto"
                unmountOnExit
              >
                {innerCollapse(dashboardTab.name)}
              </Collapse>
            </Fragment>
          );
        })}
      </List>
    );
  };

  const clickHandler = (mainTab) => {
    if (mainTab.name === "myDashboard") {
      setOpenCollapse(!openCollapse);
    } else {
      mainTab.action();
      toggleDrawer();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List>
        <ListItem className="listItem tw-cursor-pointer tw-mt-3">
          <ListItemIcon className="listItem">
            <LocationOnIcon sx={{ color: "white" }} />
          </ListItemIcon>

          <ChangeZip
            fullWidth
            style={{
              width: "70px",
              marginTop: "-5px",
              color: "white",
            }}
            className={classes.cityInput}
            inputProps={{
              style: { color: "#fff" },
            }}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
        </ListItem>

        <div className="tw-border-t tw-border-white tw-w-full tw-mt-5 tw-mb-3" />

        {mainNavData.map((mainTab, key) => (
          <Fragment key={`${mainTab.id}-${key}`}>
            <ListItem
              button={mainTab.collapse}
              onClick={() => clickHandler(mainTab)}
              className="listItem tw-cursor-pointer"
            >
              <ListItemIcon className="listItem">{mainTab.icon()}</ListItemIcon>
              <ListItemText primary={mainTab.title} className="listItem" />
              {mainTab.collapse ? (
                openCollapse ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {openCollapse && mainTab.collapse && (
              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                {!roles || roles.every((el) => el.includes("DEFAULT"))
                  ? customerDashboardCollapse()
                  : dashboardCollapse()}
              </Collapse>
            )}
          </Fragment>
        ))}
        {isShowCartIcon && (
          <>
            <div className="tw-border-t  tw-border-white tw-w-5/6 tw-ml-5 tw-mt-5 tw-mb-3 " />

            <ListItem onClick={goToCart} className="listItem tw-cursor-pointer">
              <ListItemIcon className="listItem">
                <ShoppingCartIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Cart" className="listItem" />
            </ListItem>
            {isShowingReminderText ? (
              <div className="tw-ml-16 tw-px-2 tw-text-sm  tw-italic">
                Items will remain in your cart for 20 minutes.
              </div>
            ) : null}
          </>
        )}
      </List>
    </Drawer>
  );
};

export default DrawerNav;
