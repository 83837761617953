import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Parallax } from "react-parallax";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LayoutCategory from "./layouts/LayoutCategory";
import { getCategories } from "../reducers/categoriesReducer";
import {
  fetchCategories,
  openLogInModal,
  fetchExperiencesAll,
} from "../actions";
import { imageServerUrl } from "../utils";
import { getExperiences } from "../reducers/experiencesReducer";

import Featurette from "./partials/Featurette";

import ProductsTrending from "./partials/ProductsTrending";
import ExperienceTile from "./partials/ExperienceTile";

import "../css/renters.css";

class Renters extends Component {
  static propTypes = {
    openLogInModal: PropTypes.func.isRequired,
  };

  state = {
    imageParallax: `${imageServerUrl}/rentersherotent.jpg`,
  };

  componentDidMount() {
    window.analytics.page("Renters");

    this.props.fetchCategories();
    this.props.fetchExperiencesAll();

    this.changeParallaxImage = this.changeParallaxImage.bind(this);
  }

  changeParallaxImage(image) {
    this.setState({ imageParallax: image });
  }

  render() {
    const { props } = this;
    const experiences =
      props.experiences &&
      props.experiences.isLoaded &&
      props.experiences.data &&
      props.experiences.data.teasers
        ? props.experiences.data.teasers
        : [];
    const bundles = [
      {
        title: "Tech Jetsetter BundleB",
        image: `${imageServerUrl}/bundles/TechA.jpg`,
      },
      {
        title: "Outdoorsy BundleB",
        image: `${imageServerUrl}/bundles/OutdoorsB.jpg`,
      },
    ];

    const settingsExperiences = {
      arrows: true,

      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      centerPadding: "60px",
      focusOnSelect: false,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
          },
        },
      ],
    };
    const settingsBundles = {
      arrows: true,

      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      rows: 1,
      slidesPerRow: 1,
      centerPadding: "60px",
      focusOnSelect: false,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
          },
        },
      ],
    };
    return (
      <LayoutCategory showSearch>
        <Parallax
          blur={0}
          bgImage={this.state.imageParallax}
          bgImageAlt="category.displayName"
          bgStyle={{ top: "-50%" }}
          strength={850}
          className="parallax-window p-5"
        >
          <div className="">
            <div
              className="row"
              style={{
                left: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <div className="col-xl-6 col-lg-6 col-sm-12 col-12 center-block text-center">
                <h1
                  className="display-1 text-uppercase text-white fw-bold"
                  style={{ marginTop: "200px" }}
                >
                  <strong>RENTERS</strong>
                </h1>

                <h3 className="pb-5 text-white">
                  Experience more without the cost <br /> of ownership or burden
                  of storage.
                </h3>
              </div>
              <div className="col-xl-6 col-lg-6 col-sm-12 col-12 center-block text-center" />
            </div>
          </div>
        </Parallax>

        <Featurette
          type={0}
          left
          image={`${imageServerUrl}/featurettes/featurettessized_0008_Layer 1.jpg`}
        >
          <>
            <h2 className="tw-text-skiptiOrange">Experience More For Less</h2>
            <p className="text-color4">
              Rent nearly anything online and have it delivered right to your
              door. Start trying new things, taking more adventures, learning
              new skills, and experiencing more - for less.
            </p>
            <br />
            <button
              type="button"
              className="btn-skipti-primary mr-3"
              onClick={() => this.props.openLogInModal(true)}
            >
              Sign Up
            </button>
          </>
        </Featurette>

        <Featurette
          type={0}
          right
          image={`${imageServerUrl}/featurettes/featurettessized_0005_Layer 6.jpg`}
        >
          <>
            <h2 className="tw-text-skiptiOrange">Save Space</h2>
            <p className="text-color4">
              Rent nearly anything online and have it delivered right to your
              door. Start trying new things, taking more adventures, learning
              new skills, and experiencing more - for less.
            </p>
            <br />
          </>
        </Featurette>
        <div className="container my-5">
          <div className="center-block text-center">
            <h3
              className="tw-text-skiptiOrange"
              style={{ cssFloat: "none", margin: "0 auto" }}
            >
              Featured Experiences
            </h3>
          </div>

          <div className="center-block text-center my-3 px-5">
            <p className="text-color4">
              The average Skipti item retails for $250, but there are no price
              restrictions. Items must be able to be carried by one person and
              generally should not be irreplaceble.
            </p>
          </div>
        </div>
        <div className="container ">
          <Carousel {...settingsExperiences} className="">
            {experiences &&
              experiences.map((tile) => (
                <ExperienceTile noOfTiles={1} article={tile} key={tile.id} />
              ))}
          </Carousel>
        </div>

        <div className="container my-5">
          <div className="center-block text-center my-3 px-5">
            <h3
              className="tw-text-skiptiOrange"
              style={{ cssFloat: "none", margin: "0 auto" }}
            >
              Featured Bundles
            </h3>
          </div>
          <div className="center-block text-center my-3 px-5">
            <p className="text-color4">
              Things are better together. After you upload your first item,
              curate a bundle and start earning more per rental. No one knows
              how to use your stuff better than you.
            </p>
          </div>
        </div>

        <div className="container ">
          <Carousel {...settingsBundles} className="">
            {bundles.map((_, index) => (
              <div key={index} className="container-fluid">
                <div className="row my-3">
                  <ProductsTrending myItems noProducts={3} notResponsive />
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <Featurette
          type={0}
          right
          image={`${imageServerUrl}/featurettes/featurettessized_0004_Layer 5.jpg`}
        >
          <>
            <h2 className="tw-text-skiptiOrange">Save The Planet</h2>
            <p className="text-color4">
              Rent nearly anything online and have it delivered right to your
              door. Start trying new things, taking more adventures, learning
              new skills, and experiencing more - for less.
            </p>
            <br />
          </>
        </Featurette>

        <Featurette
          type={0}
          left
          image={`${imageServerUrl}/featurettes/featurettessized_0002_Layer 8.jpg`}
        >
          <>
            <h2 className="tw-text-skiptiOrange">Try Before You Buy</h2>
            <p className="text-color4">
              Rent nearly anything online and have it delivered right to your
              door. Start trying new things, taking more adventures, learning
              new skills, and experiencing more - for less.
            </p>
            <br />
          </>
        </Featurette>
      </LayoutCategory>
    );
  }
}
const mapStateToProps = (state) => ({
  categories: getCategories(state).data || [],
  experiences: getExperiences(state),
});

export default connect(mapStateToProps, {
  openLogInModal,
  fetchCategories,
  fetchExperiencesAll,
})(Renters);
