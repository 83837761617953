import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { showSnackbar } from "../../actions/snackbar";
import { getToken, getUserID } from "../../reducers/authReducer";

import { serverUrl } from "../../utils";
import { addonOrInventActReasons } from "./new/Depot/ConfirmModal";
import { modifyBusinessHours } from "./new/Depot/Hours/hours-service";

let _slice;

export const selectDepots = (state) => state.depots.depots;
export const selectDepotsMeta = (state) => state.depots.meta;
export const selectDepotById = (state) => state.depots.depotById;
export const selectDepotBusinessHours = (state) =>
  state.depots.depotById.businessHours;
export const selectDepotServicePhotos = (state) =>
  state.depots.depotById.servicePhotos;
export const selectDepotsFetching = (state) => state.depots.fetching;
export const selectDepotsError = (state) => state.depots.error;
export const selectDepotTechnicianList = (state) => state.depots.technicianList;
export const selectDepotTechnician = (state) => state.depots.technician;
export const selectDepotReportDate = (state) => state.depots.date;
export const selectServiceModal = (state) => state.depots.serviceModal;
export const selectIsPhotoFetching = (state) => state.depots.isPhotoFetching;
export const selectIsSavingContacts = (state) => state.depots.isSavingContacts;

export const getTechnicians = createAsyncThunk(
  "depots/getTechnicians",
  async (organizationId, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    const depot = selectDepotById(state);
    const userID = getUserID(state);
    try {
      const { data } = await axios.get(
        `${serverUrl}/api/v1/admin/techs/${
          organizationId || depot.organizationId
        }`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const currentUser =
        data.find((el) => el.key === userID)?.key || data[0]?.key;
      return { list: data, currentUser };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar((e.response && e.response.data) || e.message, "error")
      );
      throw e;
    }
  }
);

export const uploadAServicePhoto = createAsyncThunk(
  "depots/uploadAServicePhoto",
  async (photo, thunkAPI) => {
    try {
      thunkAPI.dispatch(_slice.changeIsPhotoFetching(true));

      const state = thunkAPI.getState();
      const jwt = getToken(state);
      let technician = selectDepotTechnician(state);

      if (!technician) {
        technician = getUserID(state);
      }

      const { depotId } = selectDepotById(state);
      const date = selectDepotReportDate(state);
      const servicePhotos = selectDepotServicePhotos(state);

      const formData = new FormData();

      formData.append("file", photo, photo.name);

      const { data } = await axios.post(
        `${serverUrl}/api/v1/images/local`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      const updatedData = [...servicePhotos, data];
      await axios.put(
        `${serverUrl}/api/v1/admin/servicephoto/${depotId}?servicedDate=${date.toISOString()}&agent=${technician}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return updatedData;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      thunkAPI.dispatch(_slice.changeIsPhotoFetching(false));
      throw e;
    }
  }
);

export const updateAServicePhoto = createAsyncThunk(
  "depots/updateAServicePhoto",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const jwt = getToken(state);
      let technician = selectDepotTechnician(state);
      if (!technician) {
        technician = getUserID(state);
      }
      const { depotId } = selectDepotById(state);
      const date = selectDepotReportDate(state);
      const servicePhotos = selectDepotServicePhotos(state);
      const updatedData = servicePhotos.filter((el) => id !== el);
      await axios.put(
        `${serverUrl}/api/v1/admin/servicephoto/${depotId}?servicedDate=${date.toISOString()}&agent=${technician}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return updatedData;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const addServiceRecordNotes = createAsyncThunk(
  "depots/addServiceRecordNotes",
  async ({ notes }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (!technician) {
      technician = getUserID(state);
    }
    const { depotId } = selectDepotById(state);
    const date = selectDepotReportDate(state);
    try {
      const { data } = await axios.put(
        `${serverUrl}/api/v1/admin/servicenotes/${depotId}?servicedDate=${date.toISOString()}&agent=${technician}`,
        { note: notes },
        {
          headers: {
            "content-type": "application/json, charset=utf-8",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDepotBusinessHours = createAsyncThunk(
  "depots/business-hours",
  async ({ locationId }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      let businessHours = [];
      const res = await axios.get(
        `${serverUrl}/api/v1/depotadmin/hours/${locationId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (res.data) {
        businessHours = modifyBusinessHours(res.data);
      }

      return businessHours;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const updateDepotBusinessHours = createAsyncThunk(
  "depots/update-business-hours",
  async ({ requestBody, locationId }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/depotadmin/hours`,
        {
          ...requestBody,
          locationResourceId: locationId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDepotServicePhotos = createAsyncThunk(
  "depots/depot-service-photos",
  async ({ locationId }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/admin/lastservicerecordphotos/${locationId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return res.data ?? [];
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDepotById = createAsyncThunk(
  "depots/getById",
  async ({ id, withTechnician, isEdit, search, date }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());

    try {
      let responseResult = null;

      if (isEdit) {
        const queryParams = new URLSearchParams(search);
        const recordID = queryParams.get("recordID");
        const res = await axios.get(
          `${serverUrl}/api/v1/admin/lastservicerecord/${recordID}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        thunkAPI.dispatch(_slice.changeDate(moment.utc(res.data.utcServiced)));
        thunkAPI.dispatch(
          getTechnicians.fulfilled({
            list: [
              {
                key: res.data.technicianId,
                value: res.data.technicianDisplay,
              },
            ],
            currentUser: res.data.technicianId,
          })
        );

        responseResult = {
          ...res.data.depot,
          notes: res.data.notes?.shift?.() ?? "",
          photos: res.data.photos,
          isEdit: true,
        };
        // eslint-disable-next-line no-else-return
      } else if (date) {
        const res = await axios.get(
          `${serverUrl}/api/v1/admin/depot/${id}?utcBegins=${date.startDate
            ?.utc?.()
            ?.toISOString?.()}&utcEnds=${date.endDate
            ?.utc?.()
            ?.toISOString?.()}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (withTechnician) {
          await thunkAPI.dispatch(getTechnicians(res.data.organizationId));
        }
        responseResult = res.data;
      } else {
        const res = await axios.get(`${serverUrl}/api/v1/admin/depot/${id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (withTechnician) {
          await thunkAPI.dispatch(getTechnicians(res.data.organizationId));
        }
        responseResult = res.data;
      }

      if (responseResult) {
        // load depot business hours
        thunkAPI.dispatch(
          getDepotBusinessHours({
            locationId: responseResult.locationId,
          })
        );

        // load depot service photos
        if (isEdit) {
          thunkAPI.dispatch(
            getDepotServicePhotos({
              locationId: responseResult.locationId,
            })
          );
        }
      }

      return responseResult;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const resetSinceMaintenance = createAsyncThunk(
  "depots/resetSinceMaintenance",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/admin/depotmaintenance/${values.locationId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      thunkAPI.dispatch(getDepotById({ id: values.depotId }));
      return res;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const changeAddonQuantity = createAsyncThunk(
  "depots/changeAddonQuantity",
  async (values, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    // eslint-disable-next-line prefer-destructuring
    let depotId = values.depotId;
    if (!technician) {
      technician = getUserID(state);
    }
    if (depotId === undefined) {
      const depot = selectDepotById(state);
      depotId = depot.depotId;
    }
    const date = selectDepotReportDate(state);
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/admin/setaddonquantity/${depotId}?addOnId=${
          values.addonId
        }&quantity=${
          values.quantity
        }&servicedDate=${date.toISOString()}&agent=${technician}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const changeDecommissionStatus = createAsyncThunk(
  "depots/changeDecommissionStatus",
  async (values, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (technician === "") {
      technician = getUserID(state);
    }
    const date = selectDepotReportDate(state);
    try {
      const { reason, reasonText } = values.values;
      await axios.put(
        `${serverUrl}/api/v1/admin/setinrotation`,
        {
          agentId: technician,
          servicedDate: date.toISOString?.(),
          id: values.item.inventoryId,
          inRotation: values.checked,
          reasonId: reason,
          reason: reasonText,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      let _reason;
      if (reason !== 50) {
        if (values.checked) {
          _reason = addonOrInventActReasons.activationOptions.find(
            (el) => el.value === reason
          ).label;
        } else {
          _reason = addonOrInventActReasons.inActivationOptions.find(
            (el) => el.value === reason
          ).label;
        }
      } else {
        _reason = reasonText;
      }
      return { item: values.item, inService: values.checked, reason: _reason };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const changeDecommissionStatusOfAddOn = createAsyncThunk(
  "depots/changeDecommissionStatusOfAddOn",
  async (values, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (technician === "") {
      technician = getUserID(state);
    }
    const date = selectDepotReportDate(state);
    try {
      const { reason, reasonText } = values.values;
      await axios.put(
        `${serverUrl}/api/v1/admin/nonconsumableactive/`,
        {
          agent: technician,
          servicedDate: date.toISOString?.(),
          id: values.item.instanceId,
          reasonId: reason,
          reason: reasonText,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      let _reason;
      if (reason !== 50) {
        if (values.checked) {
          _reason = addonOrInventActReasons.activationOptions.find(
            (el) => el.value === reason
          ).label;
        } else {
          _reason = addonOrInventActReasons.inActivationOptions.find(
            (el) => el.value === reason
          ).label;
        }
      } else {
        _reason = reasonText;
      }
      return { item: values.item, isActive: values.checked, reason: _reason };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDepots = createAsyncThunk(
  "depots/get",
  async (values, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const { pageSize } = selectDepotsMeta(thunkAPI.getState());
    const _pageSize = values.pageSize ? values.pageSize : pageSize;
    try {
      const res = await axios.get(
        `${serverUrl}/api/v1/depotadmin/report?isActive=${values.isActive}&pageSize=${_pageSize}&pageNumber=${values.pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const changeDepotStatus = createAsyncThunk(
  "depots/changeStatus",
  async ({ selectedDepot: values, reason, isActive }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (technician === "") {
      technician = getUserID(state);
    }
    const date = selectDepotReportDate(state);
    try {
      const res = await axios.put(
        `${serverUrl}/api/v1/admin/depotactive/${
          values.locationId
        }?isActive=${!values.depotIsActive}&agent=${technician}&servicedDate=${date.toISOString()}&note=${encodeURIComponent(
          reason
        )}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const { pageNumber, pageSize } = selectDepotsMeta(thunkAPI.getState());
      thunkAPI.dispatch(
        getDepots({ pageNumber: pageNumber + 1, pageSize, isActive })
      );
      return res;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const setNonConsumableBin = createAsyncThunk(
  "depots/setNonConsumableBin",
  async ({ id, bin }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (technician === "") {
      technician = getUserID(state);
    }
    const date = selectDepotReportDate(state);
    try {
      await axios.put(
        `${serverUrl}/api/v1/admin/nonconsumable-bin/${id}?bin=${bin}&servicedDate=${date.toISOString()}&agent=${technician}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return { id, bin };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const setNonConsumableServiced = createAsyncThunk(
  "depots/setNonConsumableServiced",
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (technician === "") {
      technician = getUserID(state);
    }
    const date = selectDepotReportDate(state);
    try {
      await axios.put(
        `${serverUrl}/api/v1/admin/nonconsumable-serviced/${id}?servicedDate=${date.toISOString()}&agent=${technician}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return { id };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const setInventoryBin = createAsyncThunk(
  "depots/setInventoryBin",
  async ({ id, bin }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (technician === "") {
      technician = getUserID(state);
    }
    const date = selectDepotReportDate(state);
    try {
      await axios.put(
        `${serverUrl}/api/v1/admin/inventory-bin/${id}?bin=${bin}&servicedDate=${date.toISOString()}&agent=${technician}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return { id, bin };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const setInventoryServiced = createAsyncThunk(
  "depots/setInventoryServiced",
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);
    let technician = selectDepotTechnician(state);
    if (technician === "") {
      technician = getUserID(state);
    }
    const date = selectDepotReportDate(state);
    try {
      await axios.put(
        `${serverUrl}/api/v1/admin/inventory-serviced/${id}?servicedDate=${date.toISOString()}&agent=${technician}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return { id };
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const saveDepotContacts = createAsyncThunk(
  "depots/saveDepotContacts",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const jwt = getToken(state);

    try {
      await axios.put(
        `${serverUrl}/api/v1/depotadmin/contact-details`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "depots",
  initialState: {
    depots: [],
    technician: null,
    date: moment.utc(),
    depotById: null,
    fetching: false,
    error: false,
    technicianList: [],
    meta: {
      pageNumber: 1,
      pageSize: 50,
      totalPages: 0,
      totalItems: 0,
    },
    serviceModal: false,
    isPhotoFetching: false,
    isSavingContacts: false,
  },
  reducers: {
    clearDepotByIdData: (state) => ({ ...state, depotById: null }),
    changeDepotsPageSize: (state, { payload }) => ({
      ...state,
      meta: { ...state.meta, pageSize: payload, pageNumber: 1 },
    }),
    closeServiceModal: (state) => ({
      ...state,
      serviceModal: false,
    }),
    changeTechnician: (state, { payload }) => ({
      ...state,
      technician: payload,
    }),
    changeDate: (state, { payload }) => ({
      ...state,
      date: payload,
    }),
    changeIsPhotoFetching: (state, { payload }) => ({
      ...state,
      isPhotoFetching: payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveDepotContacts.pending, (state) => {
        state.isSavingContacts = true;
      })
      .addCase(saveDepotContacts.rejected, (state) => {
        state.isSavingContacts = false;
      })
      .addCase(saveDepotContacts.fulfilled, (state) => {
        state.isSavingContacts = false;
      })
      .addCase(getDepots.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(getDepots.rejected, (state) => {
        state.fetching = false;
        state.error = true;
        state.meta.pageNumber = 1;
        state.meta.pageSize = 50;
        state.meta.totalPages = 0;
        state.meta.totalItems = 0;
        state.depots = [];
      })
      .addCase(getDepots.fulfilled, (state, { payload }) => {
        state.error = false;
        state.fetching = false;
        state.meta.pageNumber = payload.pageNumber - 1;
        state.meta.pageSize = payload.pageSize;
        state.meta.totalPages = payload.totalPages;
        state.meta.totalItems = payload.totalItems;
        state.depots = payload ? payload.locations : [];
      })
      .addCase(getDepotById.fulfilled, (state, { payload }) => {
        state.depotById = {
          ...state.depotById,
          ...payload,
          servicePhotos: payload.photos || [],
          businessHours: [],
        };
      })
      .addCase(getDepotBusinessHours.fulfilled, (state, { payload }) => {
        state.depotById = state.depotById || {};
        state.depotById.businessHours = payload;
      })
      .addCase(getDepotServicePhotos.fulfilled, (state, { payload }) => {
        state.depotById = state.depotById || {};
        state.depotById.servicePhotos = payload;
      })
      /**
       * ! This is incorrect, The update is done with direct assignment,
       * ! which is not compatible with immutable paradigm
       * .addCase(changeDecommissionStatus.fulfilled, (state, { payload }) => {
        state.depotById.inventory = state.depotById.inventory.map((item) =>
          item.inventoryId === payload.item.inventoryId
            ? { ...item, inService: payload.inService }
            : item
        );
      });
       */
      .addCase(changeDecommissionStatus.fulfilled, (state, { payload }) => {
        state.depotById = {
          ...state.depotById,
          inventory: state.depotById.inventory.map((item) =>
            item.inventoryId === payload.item.inventoryId
              ? {
                  ...item,
                  ...payload,
                  inService: payload.inService,
                  _pending: false,
                }
              : item
          ),
        };
      })
      .addCase(
        changeDecommissionStatus.pending,
        (
          state,
          {
            meta: {
              arg: {
                item: { inventoryId },
              },
            },
          }
        ) => {
          state.depotById = {
            ...state.depotById,
            inventory: state.depotById.inventory.map((item) =>
              item.inventoryId === inventoryId
                ? { ...item, _pending: true }
                : item
            ),
          };
        }
      )
      .addCase(
        changeDecommissionStatus.rejected,
        (
          state,
          {
            meta: {
              arg: {
                item: { inventoryId },
              },
            },
          }
        ) => {
          state.depotById = {
            ...state.depotById,
            inventory: state.depotById.inventory.map((item) =>
              item.inventoryId === inventoryId
                ? { ...item, _pending: false }
                : item
            ),
          };
        }
      )
      .addCase(
        changeDecommissionStatusOfAddOn.fulfilled,
        (state, { payload }) => {
          state.depotById = {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map(
              (item) =>
                item.instanceId === payload.item.instanceId
                  ? {
                      ...item,
                      ...payload,
                      isActive: payload.isActive,
                      _pending: false,
                    }
                  : item
            ),
          };
        }
      )
      .addCase(
        changeDecommissionStatusOfAddOn.pending,
        (
          state,
          {
            meta: {
              arg: {
                item: { id },
              },
            },
          }
        ) => {
          state.depotById = {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map(
              (item) =>
                item.instanceId === id ? { ...item, _pending: true } : item
            ),
          };
        }
      )
      .addCase(
        changeDecommissionStatusOfAddOn.rejected,
        (
          state,
          {
            meta: {
              arg: {
                item: { id },
              },
            },
          }
        ) => {
          state.depotById = {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map(
              (item) =>
                item.instanceId === id ? { ...item, _pending: false } : item
            ),
          };
        }
      )
      .addCase(
        setNonConsumableBin.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id, bin },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map((el) =>
              id === el.instanceId ? { ...el, _pending: false, bin } : el
            ),
          },
        })
      )
      .addCase(
        setNonConsumableBin.pending,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map((el) =>
              id === el.instanceId ? { ...el, _pending: true } : el
            ),
          },
        })
      )
      .addCase(
        setNonConsumableBin.rejected,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map((el) =>
              id === el.instanceId ? { ...el, _pending: false } : el
            ),
          },
        })
      )
      .addCase(
        setInventoryBin.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id, bin },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            inventory: state.depotById.inventory.map((el) =>
              id === el.inventoryId ? { ...el, _pending: false, bin } : el
            ),
          },
        })
      )
      .addCase(
        setInventoryBin.rejected,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            inventory: state.depotById.inventory.map((el) =>
              id === el.inventoryId ? { ...el, _pending: false } : el
            ),
          },
        })
      )
      .addCase(
        setInventoryBin.pending,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            inventory: state.depotById.inventory.map((el) =>
              id === el.inventoryId ? { ...el, _pending: true } : el
            ),
          },
        })
      )
      .addCase(
        setInventoryServiced.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            inventory: state.depotById.inventory.map((el) =>
              id === el.inventoryId
                ? { ...el, _pending: false, _checked: true }
                : el
            ),
          },
        })
      )
      .addCase(
        setInventoryServiced.rejected,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            inventory: state.depotById.inventory.map((el) =>
              id === el.inventoryId ? { ...el, _pending: false } : el
            ),
          },
        })
      )
      .addCase(
        setInventoryServiced.pending,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            inventory: state.depotById.inventory.map((el) =>
              id === el.inventoryId ? { ...el, _pending: true } : el
            ),
          },
        })
      )
      .addCase(
        setNonConsumableServiced.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map((el) =>
              id === el.instanceId
                ? { ...el, _pending: false, _checked: true }
                : el
            ),
          },
        })
      )
      .addCase(
        setNonConsumableServiced.rejected,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map((el) =>
              id === el.instanceId ? { ...el, _pending: false } : el
            ),
          },
        })
      )
      .addCase(
        setNonConsumableServiced.pending,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            nonConsumableAddOns: state.depotById.nonConsumableAddOns.map((el) =>
              id === el.instanceId ? { ...el, _pending: true } : el
            ),
          },
        })
      )
      .addCase(
        changeAddonQuantity.fulfilled,
        (
          state,
          {
            meta: {
              arg: { addonId, quantity, rows },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            productAddOns: rows.map((el) =>
              addonId === el.productAddOnId
                ? { ...el, _pending: false, quantity }
                : el
            ),
          },
        })
      )
      .addCase(
        changeAddonQuantity.pending,
        (
          state,
          {
            meta: {
              arg: { addonId, rows },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            productAddOns: rows.map((el) =>
              addonId === el.productAddOnId ? { ...el, _pending: true } : el
            ),
          },
        })
      )
      .addCase(
        changeAddonQuantity.rejected,
        (
          state,
          {
            meta: {
              arg: { addonId },
            },
          }
        ) => ({
          ...state,
          depotById: {
            ...state.depotById,
            productAddOns: state.depotById.productAddOns.map((el) =>
              addonId === el.productAddOnId ? { ...el, _pending: false } : el
            ),
          },
        })
      )
      .addCase(addServiceRecordNotes.fulfilled, (state) => ({
        ...state,
        serviceModal: true,
      }))
      .addCase(uploadAServicePhoto.fulfilled, (state, { payload }) => ({
        ...state,
        isPhotoFetching: false,
        depotById: { ...state.depotById, servicePhotos: payload },
      }))
      .addCase(updateAServicePhoto.fulfilled, (state, { payload }) => ({
        ...state,
        depotById: { ...state.depotById, servicePhotos: payload },
      }))
      .addCase(
        getTechnicians.fulfilled,
        (state, { payload: { list, currentUser } }) => ({
          ...state,
          technicianList: list,
          technician: currentUser ?? "",
        })
      );
  },
});

_slice = slice.actions;
export const {
  changeDepotsPageSize,
  closeServiceModal,
  changeTechnician,
  changeDate,
  changeIsPhotoFetching,
  clearDepotByIdData,
} = slice.actions;

export default slice.reducer;
