import {
  ITEM_DELIVERY_MODEL,
  transformDeliveryModel,
  orderSources,
} from "../../../actions/utils";

const moment = require("moment");

export const t = 1;

export const createFinalProductResourceRequest = ({
  item,
  user,
  itemRentInfo,
  deliveryEvent,
  returnEvent,
  size,
  selectedAddons,
  selectedItems,
  events,
  promoCode,
  adminRegisterUser,
  isForAnotherUser,
}) => {
  const { getItNow } = itemRentInfo;
  let DeliveryWindowId;
  let dropoffEnds;
  let FetchWindowId;

  const orderSource = orderSources.find((c) => c.name === itemRentInfo.client);
  let fetchWindowId;
  let deliveryWindowId;

  if (events) {
    fetchWindowId = events.FetchWindowId;
    deliveryWindowId = events.DeliveryWindowId;
  }

  if (returnEvent) {
    FetchWindowId = returnEvent.id;
  }
  if (deliveryEvent) {
    DeliveryWindowId = deliveryEvent.id;
  }

  if (item?.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY) {
    FetchWindowId = "";

    if (events) {
      deliveryWindowId = events.DeliveryWindowId;
      fetchWindowId = "";
    }
  }

  if (item.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY) {
    DeliveryWindowId = "";

    if (events) {
      fetchWindowId = events.FetchWindowId;
      deliveryWindowId = "";
    }
  }

  if (!selectedAddons || typeof selectedAddons === "undefined") {
    selectedAddons = "";
  }

  if (!selectedItems || typeof selectedItems === "undefined") {
    selectedItems = "";
  }

  const itemProfilesCSV =
    itemRentInfo &&
    itemRentInfo.itemProfiles &&
    itemRentInfo.itemProfiles.join(",");

  const checkoutAttributes = itemRentInfo && itemRentInfo.checkoutAttributes;

  return {
    addOnsCsv: selectedAddons.length === 0 ? "" : selectedAddons.join(","),
    getItNow,
    organizationId: item.organizationId,
    resourceType: 0, // Product
    resourceId: item.resourceId,
    customerUserId:
      adminRegisterUser && user && isForAnotherUser
        ? adminRegisterUser.userId
        : user.userId,
    merchantUserId: item?.owners ? item.owners[0].userId : null,
    attributes: size
      ? [
          {
            label: "size",
            attributes: [{ value: size || 0 }],
          },
        ]
      : [],

    bundledCsv: selectedItems.length === 0 ? "" : selectedItems.join(","),
    dropoffEnds,
    locationIdPickup: item.currentLocationId, // owner
    locationIdDeliver:
      itemRentInfo.location !== ""
        ? itemRentInfo.location
        : (user && user.defaultLocation) ||
          "00000000-0000-0000-0000-000000000000", // renter
    locationIdReturn:
      itemRentInfo.location !== ""
        ? itemRentInfo.location
        : (user && user.defaultLocation) ||
          "00000000-0000-0000-0000-000000000000", // renter
    locationIdDropoff: item.currentLocationId, // owner
    ...events,
    DeliveryWindowId: DeliveryWindowId || deliveryWindowId,
    FetchWindowId: FetchWindowId || fetchWindowId,
    promoCode,
    quantity: itemRentInfo.quantity,
    orderSource:
      orderSource && typeof orderSource !== "undefined" ? orderSource.id : 1,
    ProfiledResourcesCsv: itemProfilesCSV || "",
    CheckoutAttributes: checkoutAttributes || "",
  };
};

export const createProductResourceRequest = ({
  item,
  user,
  itemRentInfo,
  deliveryEvent,
  returnEvent,
  size,
  selectedAddons,
  events,
  promoCode,
  region,
}) => {
  const { getItNow } = itemRentInfo;

  const attributes = size
    ? [
        {
          label: "size",
          attributes: [{ value: size || 0 }],
        },
      ]
    : [];
  let pickupBegins;
  let pickupEnds;
  let deliverBegins;
  let deliverEnds;
  let returnBegins;
  let returnEnds;
  let dropoffBegins;
  let dropoffEnds;
  if (deliveryEvent) {
    pickupBegins = moment(deliveryEvent.starts_at).utc().format();
    pickupEnds = moment(deliveryEvent.ends_at).utc().format();
    deliverBegins = moment(deliveryEvent.starts_at).utc().format();
    deliverEnds = moment(deliveryEvent.ends_at).utc().format();
  }
  if (returnEvent) {
    returnBegins = moment(returnEvent.starts_at).utc().format();
    returnEnds = moment(returnEvent.ends_at).utc().format();
    dropoffBegins = moment(returnEvent.starts_at).utc().format();
    dropoffEnds = moment(returnEvent.ends_at).utc().format();
  }

  if (getItNow) {
    pickupBegins = moment();
    pickupEnds = moment();
    deliverBegins = moment();
    deliverEnds = moment();
  }
  if (item && item.deliveryModel === ITEM_DELIVERY_MODEL.DELIVERY_ONLY) {
    returnBegins = moment().add(1, "week");
    returnEnds = moment().add(1, "week");
    dropoffBegins = moment().add(1, "week");
    dropoffEnds = moment().add(1, "week");
  }
  if (item && item.deliveryModel === ITEM_DELIVERY_MODEL.FETCH_ONLY) {
    pickupBegins = moment().add(5, "minute");
    pickupEnds = moment().add(10, "minute");
    dropoffBegins = moment().add(15, "minute");
    dropoffEnds = moment().add(20, "minute");
    if (deliveryEvent) {
      returnBegins = moment(deliveryEvent.starts_at).utc().format();
      returnEnds = moment(deliveryEvent.ends_at).utc().format();
      dropoffBegins = moment(deliveryEvent.starts_at).utc().format();
      dropoffEnds = moment(deliveryEvent.ends_at).utc().format();
    }
  }

  if (!selectedAddons || typeof selectedAddons === "undefined") {
    selectedAddons = "";
  }

  const newDeliveryModel = transformDeliveryModel(
    item && item.deliveryModel,
    (itemRentInfo && itemRentInfo.method) || "delivery"
  );

  const itemProfilesCSV =
    itemRentInfo &&
    itemRentInfo.itemProfiles &&
    itemRentInfo.itemProfiles.join(",");

  return {
    ...events,
    addOnsCsv: selectedAddons.length === 0 ? "" : selectedAddons.join(","),
    getItNow: getItNow || false,
    organizationId: item && item.organizationId,
    resourceType: 0, // Product
    resourceId: item && item.resourceId,
    customerUserId:
      (user && user.userId) || "00000000-0000-0000-0000-000000000000",
    merchantUserId: item && item.owners[0]?.userId,
    attributes,
    pickupBegins:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? pickupBegins
        : returnBegins,
    pickupEnds:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? pickupEnds
        : returnEnds,
    deliverBegins:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? deliverBegins
        : returnBegins,
    deliverEnds:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? deliverEnds
        : returnEnds,
    returnBegins:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? returnBegins
        : deliverBegins,
    returnEnds:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? returnEnds
        : deliverEnds,
    dropoffBegins:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? dropoffBegins
        : deliverBegins,
    dropoffEnds:
      item && item.deliveryModel !== ITEM_DELIVERY_MODEL.REPAIR
        ? dropoffEnds
        : deliverEnds,
    locationIdPickup: item && item.currentLocationId, // owner
    locationIdDeliver:
      itemRentInfo.location !== ""
        ? itemRentInfo.location
        : "00000000-0000-0000-0000-000000000000", // renter
    locationIdReturn:
      itemRentInfo.location !== ""
        ? itemRentInfo.location
        : "00000000-0000-0000-0000-000000000000", // renter
    locationIdDropoff: item && item.currentLocationId, // owner
    DeliveryModel: newDeliveryModel,
    bundledCsv: "",
    promoCode,
    quantity: itemRentInfo.quantity,

    ProfiledResourcesCsv: itemProfilesCSV || "",
    region,
  };
};
