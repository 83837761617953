import React from "react";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";

/**
 * example articles for props
 * const articles = [
    {
        id: 1,
        category: 1,
        title: "Lorem ipsum dolor sit amet",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non dolor in mauris sollicitudin mollis. Suspendisse malesuada efficitur condimentum. Praesent faucibus libero ut mauris pretium porttitor. Sed condimentum diam velit, et malesuada tellus maximus et. Praesent quis mollis nulla, vitae feugiat nisl. Curabitur vel eros erat. Vestibulum commodo, elit a tincidunt fermentum, dui felis molestie metus, non porttitor tortor justo id quam. Vestibulum nec elementum magna. Pellentesque porta enim vitae ipsum mollis, vel interdum urna tincidunt. Etiam non elementum est, vitae tincidunt nunc. Phasellus sodales tortor vel odio posuere egestas. Duis semper dolor tortor. Nulla semper finibus sapien, et porta nisl suscipit euismod.",
        lead: "Lorem ipsum dolor sit amet",
        quote: "Lorem ipsum dolor sit amet",
        quote_author: "Andy",
        video: _.sample(["yy7MH9TyZck", "q8shRsyeGr0", "LrX-IMzP4Wc"]),
        cover: `https://picsum.photos/1920x250?image=${_.random(0, 800)}`,
        thumbnail: `https://dummyimage.com/640x360/ccc/fff&text=+`,
        author: current_user,
        createdAt: '01.01.2018'
    },
    {
        id: 2,
        category: 2,
        title: "Lorem ipsum dolor sit amet",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non dolor in mauris sollicitudin mollis. Suspendisse malesuada efficitur condimentum. Praesent faucibus libero ut mauris pretium porttitor. Sed condimentum diam velit, et malesuada tellus maximus et. Praesent quis mollis nulla, vitae feugiat nisl. Curabitur vel eros erat. Vestibulum commodo, elit a tincidunt fermentum, dui felis molestie metus, non porttitor tortor justo id quam. Vestibulum nec elementum magna. Pellentesque porta enim vitae ipsum mollis, vel interdum urna tincidunt. Etiam non elementum est, vitae tincidunt nunc. Phasellus sodales tortor vel odio posuere egestas. Duis semper dolor tortor. Nulla semper finibus sapien, et porta nisl suscipit euismod.",
        lead: "Lorem ipsum dolor sit amet",
        quote: "Lorem ipsum dolor sit amet",
        quote_author: "Andy",
        video: _.sample(["yy7MH9TyZck", "q8shRsyeGr0", "LrX-IMzP4Wc"]),
        cover: `https://picsum.photos/1920x250?image=${_.random(0, 800)}`,
        thumbnail: `https://dummyimage.com/640x360/ccc/fff&text=+`,
        author: current_user,
        createdAt: '01.01.2018'
    }
];
* example current_user for props
const current_user = {
  id: 1,
  avatar: `https://randomuser.me/api/portraits/men/${_.random(0, 99)}.jpg`,
  email: "radu@skipti.com",
  password: "1234",
  first_name: "Bob",
  type: "renter",
  cereatedAt: "01.01.2018",
  points: _.random(0, 10000),
  badge: {
    title: "badge1",
    points_required: 123,
    description: "Lorem ipsum"
  },
  addresses: [
    {
      street: "street address",
      city: "Boston",
      state: "MS",
      zip: "123",
      default: true
    }
  ],
  payments: [
    {
      cc_type: _.sample(["Mastercard", "Visa", "Discover", "Amex"]),
      cc_num: _.random(1000, 9999),
      expiration: `${_.sample([1, 12])}/${_.sample([2018, 2023])}`,
      ccv: "123",
      default: _.sample([true, false])
    }
  ]
};
 */

export default function LayoutBlog({ children }) {
  return (
    <div>
      <Navbar />

      <div className="">{children}</div>

      <Footer />
    </div>
  );
}
