import axios from "axios";
import * as helpers from "../utils";

export const FETCH_CHART_DATA = "FETCH_CHART_DATA";
export const FETCH_CHART_DATA2 = "FETCH_CHART_DATA2";
export const ISLOGGEDIN = "ISLOGGEDIN";
export const GETPRODUCTCATEGORIES = "GETPRODUCTCATEGORIES";

const ROOT_URL = `${helpers.serverUrl}/api/v1`;
// const username = "DevelopmentProcess";
// const password = "d7QBEt62S/{)rNFKXJn3,>";

// const ROOT_URL_TEST = "http://reduxblog.herokuapp.com/api";
// const API_KEY = "?key=PAPERCLIP1234";

export function fetchChartData(userId, startDate, endDate) {
  const request = axios.get(
    `${ROOT_URL}/reports/pandl/merchant/${userId}?beginDt=${startDate}&endDt=${endDate}`
  );
  // const request = axios.get(`${ROOT_URL_TEST}/posts${API_KEY}`);
  return {
    type: FETCH_CHART_DATA,
    payload: request,
  };
}

export function fetchChartData2(userId, startDate, endDate) {
  const request = axios.get(
    `${ROOT_URL}/reports/pandl/merchant/${userId}?beginDt=${startDate}&endDt=${endDate}`
  );
  // const request = axios.get(`${ROOT_URL_TEST}/posts${API_KEY}`);
  return {
    type: FETCH_CHART_DATA2,
    payload: request,
  };
}
