import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import {
  withStyles,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { TimePicker } from "@material-ui/pickers";
import moment from "moment";
import { Formik, Form, FieldArray } from "formik";
import uuid from "uuid/v4";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { showSnackbar } from "../../actions";
import SkiptiButton from "../skipti/Button";
import { serverUrl, imageServerUrl, city } from "../../utils";
// import skiptip from "../../img/skiptip.svg";
import { getToken, getUser } from "../../reducers/authReducer";

// BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
const computeDaysToAdd = (day) => {
  switch (day) {
    case "Monday":
      return 0;
    case "Tuesday":
      return 1;
    case "Wednesday":
      return 2;
    case "Thursday":
      return 3;
    case "Friday":
      return 4;
    case "Saturday":
      return 5;
    case "Sunday":
      return 6;
    default:
      return 0;
  }
};
const dayMapper = {
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thr",
  Friday: "Fri",
  Saturday: "Sat",
  Sunday: "Sun",
};
const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  root: {
    color: theme.palette.primary.main,
  },
});
const initialValues = {
  daysOfTheWeek: [
    {
      day: "Monday",
      key: uuid(),
      timeSlots: [
        {
          beginDt: moment().startOf("isoWeek").add(9, "h"),
          endDt: moment().startOf("isoWeek").add(23, "h"),
          key: uuid(),
          defaultLocation: "home",
        },
      ],
    },
    {
      day: "Tuesday",
      key: uuid(),
      timeSlots: [
        {
          beginDt: moment().startOf("isoWeek").add(1, "d").add(9, "h"),
          endDt: moment().startOf("isoWeek").add(1, "d").add(23, "h"),
          key: uuid(),
          defaultLocation: "home",
        },
      ],
    },
    {
      day: "Wednesday",
      key: uuid(),
      timeSlots: [
        {
          beginDt: moment().startOf("isoWeek").add(2, "d").add(9, "h"),
          endDt: moment().startOf("isoWeek").add(2, "d").add(23, "h"),
          key: uuid(),
          defaultLocation: "home",
        },
      ],
    },
    {
      day: "Thursday",
      key: uuid(),
      timeSlots: [
        {
          beginDt: moment().startOf("isoWeek").add(3, "d").add(9, "h"),
          endDt: moment().startOf("isoWeek").add(3, "d").add(23, "h"),
          key: uuid(),
          defaultLocation: "home",
        },
      ],
    },
    {
      day: "Friday",
      key: uuid(),
      timeSlots: [
        {
          beginDt: moment().startOf("isoWeek").add(4, "d").add(9, "h"),
          endDt: moment().startOf("isoWeek").add(4, "d").add(23, "h"),
          key: uuid(),
          defaultLocation: "home",
        },
      ],
    },
    {
      day: "Saturday",
      key: uuid(),
      timeSlots: [
        {
          beginDt: moment().startOf("isoWeek").add(5, "d").add(9, "h"),
          endDt: moment().startOf("isoWeek").add(5, "d").add(23, "h"),
          key: uuid(),
          defaultLocation: "home",
        },
      ],
    },
    {
      day: "Sunday",
      key: uuid(),
      timeSlots: [
        {
          beginDt: moment().startOf("isoWeek").add(6, "d").add(9, "h"),
          endDt: moment().startOf("isoWeek").add(6, "d").add(23, "h"),
          key: uuid(),
          defaultLocation: "home",
        },
      ],
    },
  ],
};
class OwnerAvailabilityStep extends Component {
  static propTypes = {
    showSnackbar: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    nextStep: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      paper: PropTypes.string.isRequired,
    }).isRequired,
    subTitle: PropTypes.string.isRequired,
  };

  async componentDidMount() {
    if (city === "albuquerque") {
      await this.handleSubmit(initialValues);
    }
  }

  setDate = (day, date, name, setFieldValue) => {
    const d = moment()
      .startOf("isoWeek")
      .add(computeDaysToAdd(day), "d")
      .set("hour", date.hours())
      .set("minute", date.minutes());
    setFieldValue(name, d);
  };

  handleSubmit = async (values) => {
    const { jwt, user } = this.props;
    const events = [];
    let errors = false;
    _.forEach(values.daysOfTheWeek, (d) => {
      if (!d.away) {
        _.forEach(d.timeSlots, (ts) => {
          if (ts.beginDt.unix() > ts.endDt.unix()) {
            errors = true;
            return false;
          }
          events.push({
            title: "Available for delivery",
            descriptionHtml: "",
            beginDt: ts.beginDt.toISOString(),
            endDt: ts.endDt.toISOString(),
            recurrence: {
              initiateDt: ts.beginDt.toISOString(),
              [`can${dayMapper[d.day]}`]: true,
            },
            resourceType: 1,
            resourceId: user.humanResourceId,
            defaultLocation: user.defaultLocation,
          });
        });
      }
      if (errors) {
        return false;
      }
    });
    if (errors) {
      return;
    }
    try {
      await axios.post(
        `${serverUrl}/api/v1/calendar/availability/${user.userId}`,
        events,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      this.props.nextStep();
    } catch (e) {
      this.props.showSnackbar(e.message);
    }
  };

  render() {
    const { step, title, subTitle } = this.props;
    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <div className="px-2" key="step2">
              <div className="row d-flex h-100">
                <div className="col-9">
                  <h1 className="mb-4 fw-light">
                    <span className="fa-layers fa-fw tw-text-skiptiOrange">
                      <i className="fas fa-circle" />
                      <span
                        className="fa-layers-text fa-inverse"
                        style={{ fontWeight: 300 }}
                      >
                        {step}
                      </span>
                    </span>
                    {title}
                  </h1>
                  <h2 className="mb-4 fw-light">{subTitle}</h2>
                  {values.daysOfTheWeek.map((d, dayIndex) => (
                    <FieldArray
                      key={d.key}
                      name={`daysOfTheWeek.${dayIndex}.timeSlots`}
                      render={(arrayHelpers) => (
                        <div>
                          {values.daysOfTheWeek[dayIndex].timeSlots.map(
                            (ts, tsIndex) => (
                              <div
                                className="row"
                                style={{ minHeight: "56px" }}
                                key={ts.key}
                              >
                                <div className="col-2">
                                  <TextField
                                    fullWidth
                                    value={d.day.substring(0, 3)}
                                    style={{ bottom: 0 }}
                                    disabled
                                  />
                                </div>
                                <div className="col-3">
                                  <TimePicker
                                    fullWidth
                                    placeholder="8:00 AM"
                                    keyboard
                                    error={
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].beginDt.unix() >
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].endDt.unix()
                                    }
                                    FormHelperTextProps={{
                                      error: true,
                                    }}
                                    helperText={
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].beginDt.unix() >
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].endDt.unix()
                                        ? "Start time should not be after end time"
                                        : null
                                    }
                                    disabled={
                                      values.daysOfTheWeek[dayIndex].away
                                    }
                                    mask={[
                                      /\d/,
                                      /\d/,
                                      ":",
                                      /\d/,
                                      /\d/,
                                      " ",
                                      /a|p/i,
                                      "M",
                                    ]}
                                    disableOpenOnEnter
                                    value={
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].beginDt
                                    }
                                    onChange={(date) =>
                                      this.setDate(
                                        d.day,
                                        date,
                                        `daysOfTheWeek.${dayIndex}.timeSlots.${tsIndex}.beginDt`,
                                        setFieldValue
                                      )
                                    }
                                    name={`daysOfTheWeek.${dayIndex}.timeSlots.${tsIndex}.beginDt`}
                                  />
                                </div>
                                <div
                                  className="col-1"
                                  style={{ textAlign: "center" }}
                                >
                                  -
                                </div>
                                <div className="col-3">
                                  <TimePicker
                                    fullWidth
                                    keyboard
                                    placeholder="10:00 AM"
                                    disabled={
                                      values.daysOfTheWeek[dayIndex].away
                                    }
                                    error={
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].endDt.unix() <
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].beginDt.unix()
                                    }
                                    FormHelperTextProps={{
                                      error: true,
                                    }}
                                    helperText={
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].endDt.unix() <
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].beginDt.unix()
                                        ? "End time should be after start time"
                                        : null
                                    }
                                    mask={[
                                      /\d/,
                                      /\d/,
                                      ":",
                                      /\d/,
                                      /\d/,
                                      " ",
                                      /a|p/i,
                                      "M",
                                    ]}
                                    disableOpenOnEnter
                                    value={
                                      values.daysOfTheWeek[dayIndex].timeSlots[
                                        tsIndex
                                      ].endDt
                                    }
                                    onChange={(date) =>
                                      this.setDate(
                                        d.day,
                                        date,
                                        `daysOfTheWeek.${dayIndex}.timeSlots.${tsIndex}.endDt`,
                                        setFieldValue
                                      )
                                    }
                                    name={`daysOfTheWeek.${dayIndex}.timeSlots.${tsIndex}.endDt`}
                                  />
                                </div>
                                <div className="col-3">
                                  <FormControlLabel
                                    style={{
                                      display:
                                        tsIndex === 0 ? "inline-flex" : "none",
                                    }}
                                    control={
                                      <Checkbox
                                        checked={
                                          values.daysOfTheWeek[dayIndex].away
                                        }
                                        onChange={handleChange}
                                        name={`daysOfTheWeek.${dayIndex}.away`}
                                        value={`daysOfTheWeek.${dayIndex}.away`}
                                      />
                                    }
                                    label="Away all day"
                                  />
                                  <IconButton
                                    style={{
                                      display:
                                        tsIndex !== 0 ? "inline-flex" : "none",
                                      padding: "8px 8px 8px 8px",
                                    }}
                                    onClick={() => arrayHelpers.remove(tsIndex)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            )
                          )}
                          <div className="row mb-2">
                            <Button
                              color="secondary"
                              disabled={values.daysOfTheWeek[dayIndex].away}
                              onClick={() =>
                                arrayHelpers.push({
                                  endDt: moment()
                                    .startOf("isoWeek")
                                    .add(computeDaysToAdd(d.day), "d"),
                                  beginDt: moment()
                                    .startOf("isoWeek")
                                    .add(computeDaysToAdd(d.day), "d"),
                                  key: uuid(),
                                  defaultLocation: "home",
                                })
                              }
                            >
                              + add hours
                            </Button>
                          </div>
                        </div>
                      )}
                    />
                  ))}
                </div>
                <div className="col-3 text-center align-self-center">
                  <img
                    src={`${imageServerUrl}/skiptip.svg`}
                    className="mx-auto h6 my-2"
                    alt=""
                    height={200}
                    width={200}
                  />
                  <h6 className="text-uppercase tw-text-skiptiOrange fw-light mt-3 mb-2">
                    Skiptip
                  </h6>
                  <p className="tw-text-xl tw-font-lighttw-text-skiptiOrange px-5">
                    <b>ATTENTION BETA USERS</b> - Please do not edit this
                    section. We will be contacting you directly to ensure your
                    availability per rental.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-9">
                <div className="float-right">
                  <SkiptiButton primary type="submit">
                    Save
                  </SkiptiButton>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => ({
  jwt: getToken(state),
  user: getUser(state),
});

export default connect(mapStateToProps, { showSnackbar })(
  withStyles(styles)(OwnerAvailabilityStep)
);
