import React, { Component } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchUserInfoById, fetchItemsByOrganizationId } from "../../actions";
import { getUserInfoById } from "../../reducers/userReducer";
import { getProductsByOrganization } from "../../reducers/itemsReducer";
import ShowUser from "./ShowUser";
import LayoutMain from "../layouts/LayoutMain";

class Profile extends Component {
  componentDidMount() {
    if (this.props.match != null) {
      this.props.fetchUserInfoById(this.props.match.params.id);
    }
    // this.props.fetchItemsByOrganizationId({organizationId: "9ce7ce27-6444-45d7-a054-d2049a561ca9"});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.user.infoById != null &&
      nextProps.user.infoById.isLoaded === true &&
      nextProps.user.infoById.data !== null
    ) {
      this.props.fetchItemsByOrganizationId({
        organizationId: nextProps.user.infoById.data.resourceOrganization,
      });
    }
  }

  render() {
    const { props } = this;
    const user =
      props.user && props.user.isLoaded && props.user.data
        ? props.user.data
        : {};
    const items =
      props.items && props.items.isLoaded && props.items.data.resources
        ? props.items.data.resources
        : [];

    return (
      <LayoutMain withContainer>
        {this.props.user &&
        this.props.user.isFetching === true &&
        this.props.items &&
        this.props.items.isFetching === true ? (
          <div className="text-center">
            <h3>Loading...</h3>
          </div>
        ) : (
          <ShowUser user={user} items={items} />
        )}
      </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUserInfoById(state),
  // user: getUser(state),
  items: getProductsByOrganization(state),
});

export default connect(mapStateToProps, {
  fetchUserInfoById,
  fetchItemsByOrganizationId,
})(Profile);
