import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

class PriceInfoPopper extends Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handlePriceInfoClick = (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  handleClickAway = () => {
    this.setState({
      open: false,
    });
  };

  handleArrowRef = (node) => {
    // this.setState({
    //   arrowRef: node,
    // });
  };

  render() {
    const {
      product,
      rentDuration,
      total,
      salesTax,
      subtotal,
      delivery,
      productCosts,
      skiptiCredit,
      insurance,
      black,
      productNames,
      perimeter,
    } = this.props;
    const { anchorEl, open } = this.state;
    const id = open ? "simple-popper" : null;
    const serviceFees = insurance || 0;
    const isBlack =
      black || (isWidthDown("xs", this.props.width) ? "white" : "black");
    return (
      <div className="tw-inline-block">
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <>
            <button
              className="tw-border-none tw-bg-none tw-cursor-pointer tw-py-1 tw-px-1"
              type="button"
              aria-describedby={id}
              variant="contained"
              onClick={this.handlePriceInfoClick}
            >
              <i
                className="far fa-question-circle"
                style={{
                  color: isBlack,
                }}
              />
            </button>
            <Popper
              id={id}
              open={open}
              style={{ zIndex: 3333 }}
              anchorEl={anchorEl}
              transition
              placement="top-end"
              modifiers={
                {
                  // flip: {
                  //   enabled: true,
                  // },
                  // preventOverflow: {
                  //   enabled: true,
                  //   boundariesElement: 'scrollParent',
                  // },
                  // arrow: {
                  //   enabled: true,
                  //   element: this.state.arrowRef,
                  // },
                }
              }
            >
              {/* {
                    true &&
                    <span className="popover-arrow" ref={this.handleArrowRef} />
                  } */}
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <div className="tw-p-12">
                      <h4 className="tw-mb-4">
                        <strong>Price Breakdown</strong>
                      </h4>
                      <button
                        type="button"
                        className="tw-float-right close-button-modal"
                        onClick={this.handleClickAway}
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="tw-flex tw-justify-between tw-flex-col tw-my-6">
                        {product &&
                        product.subsequentDayUsd &&
                        product.subsequentDayUsd > 0 ? (
                          <>
                            <LineItemInPopper
                              label="First night (up to 36hrs)"
                              value={`$${product.basePerDayUsd}`}
                            />
                            <hr className="tw-w-full" />
                            <LineItemInPopper
                              value={`${
                                productCosts && productCosts[0]
                                  ? `$${
                                      productCosts[0].toFixed(2) -
                                      product.basePerDayUsd
                                    }`
                                  : ""
                              }`}
                              label={`Additional nights $${
                                product.subsequentDayUsd
                              }
                                ${
                                  rentDuration && rentDuration > 1
                                    ? `x ${rentDuration - 1} nights`
                                    : ""
                                }`}
                            />
                            <hr className="tw-w-full" />
                            {productNames &&
                              productNames.slice(1).map(
                                (
                                  prodName,
                                  index // to ignore main product
                                ) => (
                                  <>
                                    <LineItemInPopper
                                      label={prodName}
                                      value={`$${
                                        productCosts &&
                                        productCosts[index + 1] &&
                                        productCosts[index + 1].toFixed(2)
                                      }`} // to account for sliced product
                                    />
                                    {/* <LineItemInPopper
                                  label={`${prodName} $${productCosts[
                                    index + 1
                                  ].toFixed(2)} ${
                                    rentDuration && rentDuration > 1
                                      ? `x ${rentDuration - 1} nights`
                                      : ""
                                  }`}
                                  value={`$${(
                                    productCosts[index + 1] *
                                    (rentDuration - 1)
                                  ).toFixed(2)}`} // to account for sliced product
                                /> */}
                                    <hr
                                      style={{
                                        width: "100%",
                                      }}
                                    />
                                  </>
                                )
                              )}

                            <LineItemInPopper
                              condition={skiptiCredit > 0}
                              value={`$-${
                                skiptiCredit && skiptiCredit.toFixed(2)
                              }`}
                              label="Skipti Credit"
                            />

                            {product.productName.includes(
                              "Rug Doctor Home Bundle"
                            ) && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div className="tw-mr-1">
                                    Upholstery Tool ($5.99 /night)
                                  </div>
                                  <div>
                                    <span className="tw-text-skiptiOrange">
                                      FREE
                                    </span>
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    width: "100%",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div className="tw-mr-1">
                                    48oz Oxy Cleaner ($14.99)
                                  </div>
                                  <div>
                                    <span className="tw-text-skiptiOrange">
                                      FREE
                                    </span>
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </>
                            )}
                            {product.productName.includes(
                              "Rug Doctor Spot Cleaner Bundle"
                            ) && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div className="tw-mr-1">
                                    32oz Rug Doctor Detailing,
                                    <br />
                                    Spot And Upholstery Cleaner
                                  </div>
                                  <div>
                                    <span className="tw-text-skiptiOrange">
                                      FREE
                                    </span>
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </>
                            )}
                            {product.productName.includes(
                              "Rug Doctor Pro-Electric Pressure Washer"
                            ) && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div className="tw-mr-1">
                                    120oz Rug Doctor Outdoor Cleaner
                                    <br />
                                    ($12.99)
                                  </div>
                                  <div>
                                    <span className="tw-text-skiptiOrange">
                                      FREE
                                    </span>
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </>
                            )}
                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <div className="mr-1">
                                <Typography
                                  style={{ marginTop: "3px" }}
                                  variant="body2"
                                >
                                  Two-way Delivery
                                </Typography>
                              </div>
                              <div>
                                <span className="tw-text-skiptiOrange">FREE</span>
                              </div>
                            </div> */}
                          </>
                        ) : (
                          productCosts &&
                          total && (
                            <>
                              {product.deliveryOnly === "false" && (
                                <>
                                  <LineItemInPopper
                                    value={rentDuration ? `${rentDuration}` : 0}
                                    label="Number of days"
                                  />
                                  <hr
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </>
                              )}
                              <LineItemInPopper
                                value={`$${
                                  productCosts && productCosts[0]
                                    ? productCosts[0].toFixed(2)
                                    : 0
                                }`}
                                label="Product Cost"
                              />
                              {product.productName.includes(
                                "Racquet Restringing"
                              ) && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div className="tw-mr-1">
                                      <Typography
                                        variant="body2"
                                        className="tw-mt-4"
                                      >
                                        Strings
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography
                                        variant="body2"
                                        className="tw-mt-4"
                                      >
                                        Added to Total
                                      </Typography>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* <LineItemInPopper
                                condition={skiptiCredit > 0}
                                value={`$-${
                                  skiptiCredit && skiptiCredit.toFixed(2)
                                }`}
                                label="Skipti Credit"
                              />
                              <LineItemInPopper
                                isLabelStrong
                                label="Subtotal"
                                value={`$${subtotal ? subtotal.toFixed(2) : 0}`}
                              />

                              <hr
                                style={{
                                  width: "100%",
                                }}
                              />
                              <LineItemInPopper
                                condition={serviceFees > 0}
                                label="Service Fee"
                                value={`$${
                                  serviceFees ? serviceFees.toFixed(2) : 0
                                }`}
                              /> */}

                              {/* <LineItemInPopper
                                label="Sales tax"
                                value={`$${salesTax ? salesTax.toFixed(2) : 0}`}
                              />
                              <hr
                                style={{
                                  width: "100%",
                                }}
                              /> */}
                              {productNames.slice(1).map((prodName, index) => (
                                <>
                                  <LineItemInPopper
                                    label={prodName}
                                    value={`$${
                                      productCosts &&
                                      productCosts[index] &&
                                      productCosts[index].toFixed(2)
                                    }`}
                                  />
                                  <hr
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </>
                              ))}
                              {/* <div className="tw-flex tw-justify-between tw-flex-row">
                                <div className="tw-mr-1">
                                  <Typography variant="h6">Total</Typography>
                                </div>
                                <div>
                                  <Typography
                                    variant="h6"
                                    className="tw-text-skiptiOrange"
                                  >
                                    {`$${total ? total.toFixed(2) : 0}`}
                                  </Typography>
                                </div>
                              </div> */}
                            </>
                          )
                        )}

                        <LineItemInPopper
                          condition={skiptiCredit > 0}
                          value={`$-${skiptiCredit && skiptiCredit.toFixed(2)}`}
                          label="Skipti Credit"
                        />
                        <LineItemInPopper
                          isLabelStrong
                          label="Subtotal"
                          value={`$${subtotal ? subtotal.toFixed(2) : 0}`}
                        />

                        <hr
                          style={{
                            width: "100%",
                          }}
                        />
                        <LineItemInPopper
                          condition={serviceFees > 0}
                          label="Service Fee"
                          value={`$${serviceFees ? serviceFees.toFixed(2) : 0}`}
                        />
                        {delivery > 0 && (
                          <LineItemInPopper
                            condition={!!(delivery && delivery > 0)}
                            value={`$${delivery && delivery.toFixed(2)}`}
                            label="Shipping"
                            withHr
                          />
                        )}
                        {perimeter > 0 && (
                          <>
                            <LineItemInPopper
                              value={`$${perimeter ? perimeter.toFixed(2) : 0}`}
                              label="Perimeter Price"
                            />
                            <hr
                              style={{
                                width: "100%",
                              }}
                            />
                          </>
                        )}
                        {salesTax > 0 && (
                          <>
                            <LineItemInPopper
                              // value={`$${salesTax ? salesTax.toFixed(2) : 0}`}
                              label="Sales tax will be calculated at checkout"
                            />
                            <hr
                              style={{
                                width: "100%",
                              }}
                            />
                          </>
                        )}

                        {total && (
                          <div className="tw-flex tw-justify-between tw-flex-row">
                            <div className="tw-mr-1">
                              <Typography variant="h6">Total</Typography>
                            </div>
                            <div>
                              <Typography
                                variant="h6"
                                className="tw-text-skiptiOrange"
                              >
                                {`$${total ? total.toFixed(2) : 0}`}
                              </Typography>
                            </div>
                          </div>
                        )}
                        {(!product ||
                          !product.subsequentDayUsd ||
                          !product.subsequentDayUsd > 0) &&
                          (!productCosts || !total) && (
                            <Typography
                              variant="body2"
                              // style={{
                              //   marginBottom: "1.5rem"
                              // }}
                            >
                              Please select dates to be able to see the Price
                              Breakdown
                            </Typography>
                          )}
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </>
        </ClickAwayListener>
      </div>
    );
  }
}
export default withWidth()(PriceInfoPopper);

const LineItemInPopper = (props) => {
  return (
    props.condition && (
      <>
        <div className="tw-flex tw-justify-between tw-flex-row">
          <div className="tw-mr-1">
            <Typography variant="body2" className="tw-mt-4">
              {props.isLabelStrong ? (
                <strong>{props.label}</strong>
              ) : (
                props.label
              )}
            </Typography>
          </div>
          <div>{props.value}</div>
        </div>
        {props.withHr && <hr className="tw-w-full" />}
      </>
    )
  );
};

LineItemInPopper.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  condition: PropTypes.bool,
  isLabelStrong: PropTypes.bool,
};

LineItemInPopper.defaultProps = {
  condition: true,
  isLabelStrong: false,
};
