import React from "react";
import DateBlock from "./DateBlock";
import { experienceTypes, fulfillmentTypes } from "../../../actions/utils";
import { useSelector } from "react-redux";
import { getItemRentInfo } from "../../../reducers/itemRentReducer";

const DateBlockWrapper = ({
  dateRange,
  deliveryEvent,
  fetchEvent,
  fulfillmentType,
}) => {
  const itemRentInfo = useSelector(getItemRentInfo);

  if (
    fulfillmentType?.id === fulfillmentTypes.CourierInboundOnly.id ||
    fulfillmentType?.id === fulfillmentTypes.CourierOutboundOnly.id
  ) {
    return (
      <div className="tw-grid tw-justify-items-stretch">
        <DateBlock
          dateRange={
            // fulfillmentType?.id === fulfillmentTypes.CourierInboundOnly.id
            //   ? dateRange?.endDate
            //   :
            dateRange?.startDate
          }
          title={
            fulfillmentType?.id === fulfillmentTypes.CourierInboundOnly.id
              ? "RETURN DATE"
              : "DELIVERY DATE"
          }
          events={
            // fulfillmentType?.id === fulfillmentTypes.CourierInboundOnly.id
            //   ? fetchEvent
            //   :
            deliveryEvent
          }
          styles={{ borderBottom: "none" }}
        />
      </div>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-2 tw-justify-items-stretch">
      <DateBlock
        dateRange={dateRange?.startDate}
        title={
          itemRentInfo.experienceType?.id === experienceTypes.TransportAssist.id
            ? "DELIVERY"
            : fulfillmentType?.deliveryDateText
        }
        events={deliveryEvent}
        styles={{ borderRight: "none", borderBottom: "none" }}
      />

      <DateBlock
        dateRange={dateRange?.endDate}
        title={
          itemRentInfo.experienceType?.id === experienceTypes.TransportAssist.id
            ? "RETURN"
            : fulfillmentType?.returnDateText
        }
        events={fetchEvent}
        styles={{ borderBottom: "none" }}
      />
    </div>
  );
};

export default DateBlockWrapper;
