import React from "react";

export default function Alerts(props) {
  return props.flash != null ? (
    <div>
      {props.flash.success != null ? (
        <div className="alert alert-success mb-4">
          <button
            type="button"
            data-dismiss="alert"
            aria-hidden="true"
            className="close"
          >
            ×
          </button>
          <div className="icon text-white d-flex h-100">
            <i className="fas fa-check-circle justify-content-center align-self-center mx-auto" />
          </div>
          <strong>{props.flash.success.title}</strong>
          {props.flash.success.message}
        </div>
      ) : null}

      {props.flash.info != null ? (
        <div className="alert alert-info mb-4">
          <button
            type="button"
            data-dismiss="alert"
            aria-hidden="true"
            className="close"
          >
            ×
          </button>
          <div className="icon text-white d-flex h-100">
            <i className="fas fa-info-circle justify-content-center align-self-center mx-auto" />
          </div>
          <strong>{props.flash.info.title}</strong>
          {props.flash.info.message}
        </div>
      ) : null}

      {props.flash.warning != null ? (
        <div className="alert alert-warning mb-4">
          <button
            type="button"
            data-dismiss="alert"
            aria-hidden="true"
            className="close"
          >
            ×
          </button>
          <div className="icon text-white d-flex h-100">
            <i className="fas fa-exclamation-triangle justify-content-center align-self-center mx-auto" />
          </div>
          <strong>{props.flash.warning.title}</strong>
          {props.flash.warning.message}
        </div>
      ) : null}

      {props.flash.danger != null ? (
        <div className="alert alert-danger mb-4">
          <button
            type="button"
            data-dismiss="alert"
            aria-hidden="true"
            className="close"
          >
            ×
          </button>
          <div className="icon text-white d-flex h-100">
            <i className="fas fa-times-circle justify-content-center align-self-center mx-auto" />
          </div>
          <strong>{props.flash.danger.title}</strong>
          {props.flash.danger.message}
        </div>
      ) : null}
    </div>
  ) : null;
}
