import axios from "axios";
import { find, memoize } from "lodash";
import moment from "moment-timezone";

export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const regIntServerURL = process.env.REACT_APP_SKIPTI_INTEREST_API_DOMAIN;
export const webServerUrl =
  process.env.REACT_APP_IMAGE_SERVER_URL.split("/images")[0];
export const imageServerUrl = process.env.REACT_APP_IMAGE_SERVER_URL;
export const mixpanelKey = process.env.REACT_APP_MIXPANEL_KEY;
export const stripeKey = process.env.REACT_APP_STRIPE_KEY;
export const googleAnalyticsTrackingID = process.env.REACT_APP_GA_KEY;
export const city = process.env.REACT_APP_CITY;
export const environment = process.env.NODE_ENV;
export const homeDepotId =
  process.env.REACT_APP_THE_HOME_DEPOT ||
  "708e1d81-9369-4013-a9dc-5e07ddda203a";

export const jackRabbitId =
  process.env.REACT_APP_JACK_RABBIT || "445AB2A6-BB95-4FD0-85EB-CF15FBEEE190";

export const powerBiAnalyticsReportId = process.env.POWERBI_ANALYTICS_REPORT;

export const routesAPIUrl = process.env.REACT_APP_ROUTES_API_URL;

// TODO: Change this to server variable
export const sizeMap = {
  Small: { value: 10, disabled: false },
  Medium: { value: 20, disabled: false },
  Large: { value: 30, disabled: false },
  "X-Large": { value: 40, disabled: false },
};

export const makeInitial = (rest) => ({
  isFetching: false,
  isLoaded: false,
  isError: false,
  isInvalidated: false,
  ...rest,
});
export const makeStartLoading = (rest) => ({
  isFetching: true,
  isLoaded: false,
  isError: false,
  isInvalidated: false,
  ...rest,
});
export const makeLoadSuccess = (data, rest) => ({
  isFetching: false,
  isLoaded: true,
  isError: false,
  isInvalidated: false,
  lastLoadDate: new Date(),
  data,
  ...rest,
});

export const makeValidationStart = () => ({
  isLoading: true,
  isValid: false,
  isLoaded: false,
  isError: false,
});

export const makeValidationSuccess = () => ({
  isLoading: false,
  isValid: true,
  isLoaded: true,
  isError: false,
});

export const makeValidationEnd = () => ({
  isLoading: false,
  isValid: false,
  isLoaded: true,
  isError: false,
});

export const makeLoadFailed = (error, rest) => ({
  isFetching: false,
  isError: true,
  isLoaded: false,
  isInvalidated: false,
  lastLoadDate: new Date(),
  error,
  ...rest,
});

export const triggerReload = (rest) => ({
  isFetching: true,
  isLoaded: false,
  isError: false,
  isInvalidated: true,
  ...rest,
});

export const computeRecommendedPricing = (perDay) => ({
  perDay,
  perWeek: (perDay * 6).toFixed(2),
  perMonth: (perDay * 6 * 3.5).toFixed(2),
});

export const getPriceEstimations = (perDay) => {
  const markup = 1.3;
  const durationDaysAverageRental = 5.75;
  return {
    avgRentalOwnerGets: Math.round(
      (perDay / markup) * durationDaysAverageRental
    ).toFixed(2),
    avgRentalRenterPays: Math.round(perDay * durationDaysAverageRental).toFixed(
      2
    ),
  };
};

/**
 * This method will find first not undefined property value and return
 * There important to keep ordering of properties
 * @param {{
 *   [key: string]: any,
 * }} properties
 * @return {{[p: string]: *} | undefined}
 */
export const conditionalProp = (properties) => {
  if (!properties) return;

  for (const prop of Object.keys(properties)) {
    if (typeof properties[prop] !== "undefined") {
      return { [prop]: properties[prop] };
    }
  }
};

export const checkZipCode = (zipCode) => {
  const maxFiveNumbers = new RegExp(/^[0-9]{5}$/);

  return maxFiveNumbers.test(zipCode);
};

export const voteProduct = async (
  jwt,
  voteForProductAfterAuth,
  mixpanel,
  analytics,
  userEmail
) => {
  const { productId, productName, productCategory, productPrice } =
    voteForProductAfterAuth;
  try {
    const res = await axios.post(
      `${serverUrl}/api/v1/review/product-vote/${productId}?email=${userEmail}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    mixpanel.track("Item Voted", {
      productResourceId: productId,
      productName,
      productCategory,
      basePerDayUsd: productPrice,
    });
    analytics.track(`Item Voted`, {
      productResourceId: productId,
      productName,
      productCategory,
      basePerDayUsd: productPrice,
    });

    localStorage.setItem("votedAnItem", "false");

    return res;
  } catch (e) {
    return e;
  }
};

export const suggestProduct = async (
  jwt,
  suggestProductAfterAuth,
  mixpanel,
  analytics
) => {
  const { suggestion } = suggestProductAfterAuth;
  try {
    const res = await axios.post(
      `${serverUrl}/api/v1/review/suggestproduct`,
      suggestion,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    mixpanel.track("Suggested Item", {
      suggestion,
    });
    analytics.track(`Suggested Item`, {
      suggestion,
    });

    return res;
  } catch (e) {
    console.log(`vote error: ${JSON.stringify(e)}`);
    return e;
  }
};

/**
 * @param {number} x
 * @param {number} accuracy
 * @param {boolean} prettify
 * @returns {string}
 */
export function financial(x, accuracy = 2, prettify = false, rounding = true) {
  let result;
  if (rounding) {
    result = Number.parseFloat(x).toFixed(accuracy);
  } else {
    result = Math.floor(x * 10 ** accuracy) / 10 ** accuracy;
  }
  if (prettify) {
    // eslint-disable-next-line no-inner-declarations
    function prettifyFunc(_result) {
      if (
        (_result[_result.length - 1] === "0" ||
          _result[_result.length - 1] === ".") &&
        _result.length !== 1 &&
        _result.includes(".")
      ) {
        return prettifyFunc(_result.substring(0, _result.length - 1));
      }
      return _result;
    }
    return prettifyFunc(result);
  }
  return result;
}

/**
 * Query string's object representation
 * @param queryString
 * @return {module:url.URLSearchParams}
 */
export const toQueryObject = (queryString) => new URLSearchParams(queryString);

export const convertUtsOffsetToTimezoneAbr = memoize((offset) => {
  let offsetStr;
  if (offset < 0) {
    offsetStr = `${Math.abs(offset)}`.padStart(2, 0);
    offsetStr = `-${offsetStr}:00`;
  } else {
    offsetStr = `${offset}`.padStart(2, 0);
    offsetStr = `+${offsetStr}:00`;
  }

  const timeZone = find(moment.tz.names(), (timezoneName) => {
    return offsetStr === moment.tz(timezoneName).format("Z");
  });
  return timeZone ? moment.tz(timeZone).zoneAbbr() : "EST";
});

// Cancel Order - ALL
// Modify Order - AllAdmins
// Manage Routes - SuperSkiptiAdmin

export const userRoles = {
  SuperAdmin: "SUPER-ADMIN",
  SkiptiAdmin: "SKIPTI-ADMIN",
  PartnerAdmin: "PARTNER-ADMIN",
  Associate: "DEPOT-TECH",
  DefaultOwner: "DEFAULT-OWNER",
  DefaultRenter: "DEFAULT-RENTER",
  BlackList: "BLACKLIST",
  DepotTech: "DEPOT-TECH",
  MaintenanceManager: "MAINTENANCE-MANAGER",
  SkiptiAccountManager: "SKIPTI-ACCOUNT-MANAGER",
  SkiptiDriver: "SKIPTI-DRIVER",
  DeliveryPerson: "DELIVERY-PERSON",
  OrgAdmin: "ORG-ADMIN",
  Celebrity: "CELEBRITY",
  ValidatedUser: "VALIDATED-USER",
  VerifiedUser: "VERIFIED-USER",
  Salesperson: "SALESPERSON",
};

export const statesList = [
  {
    name: "Alabama",
    id: "AL",
  },
  {
    name: "Alaska",
    id: "AK",
  },
  {
    name: "American Samoa",
    id: "AS",
  },
  {
    name: "Arizona",
    id: "AZ",
  },
  {
    name: "Arkansas",
    id: "AR",
  },
  {
    name: "California",
    id: "CA",
  },
  {
    name: "Colorado",
    id: "CO",
  },
  {
    name: "Connecticut",
    id: "CT",
  },
  {
    name: "Delaware",
    id: "DE",
  },
  {
    name: "District Of Columbia",
    id: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    id: "FM",
  },
  {
    name: "Florida",
    id: "FL",
  },
  {
    name: "Georgia",
    id: "GA",
  },
  {
    name: "Guam",
    id: "GU",
  },
  {
    name: "Hawaii",
    id: "HI",
  },
  {
    name: "Idaho",
    id: "ID",
  },
  {
    name: "Illinois",
    id: "IL",
  },
  {
    name: "Indiana",
    id: "IN",
  },
  {
    name: "Iowa",
    id: "IA",
  },
  {
    name: "Kansas",
    id: "KS",
  },
  {
    name: "Kentucky",
    id: "KY",
  },
  {
    name: "Louisiana",
    id: "LA",
  },
  {
    name: "Maine",
    id: "ME",
  },
  {
    name: "Marshall Islands",
    id: "MH",
  },
  {
    name: "Maryland",
    id: "MD",
  },
  {
    name: "Massachusetts",
    id: "MA",
  },
  {
    name: "Michigan",
    id: "MI",
  },
  {
    name: "Minnesota",
    id: "MN",
  },
  {
    name: "Mississippi",
    id: "MS",
  },
  {
    name: "Missouri",
    id: "MO",
  },
  {
    name: "Montana",
    id: "MT",
  },
  {
    name: "Nebraska",
    id: "NE",
  },
  {
    name: "Nevada",
    id: "NV",
  },
  {
    name: "New Hampshire",
    id: "NH",
  },
  {
    name: "New Jersey",
    id: "NJ",
  },
  {
    name: "New Mexico",
    id: "NM",
  },
  {
    name: "New York",
    id: "NY",
  },
  {
    name: "North Carolina",
    id: "NC",
  },
  {
    name: "North Dakota",
    id: "ND",
  },
  {
    name: "Northern Mariana Islands",
    id: "MP",
  },
  {
    name: "Ohio",
    id: "OH",
  },
  {
    name: "Oklahoma",
    id: "OK",
  },
  {
    name: "Oregon",
    id: "OR",
  },
  {
    name: "Palau",
    id: "PW",
  },
  {
    name: "Pennsylvania",
    id: "PA",
  },
  {
    name: "Puerto Rico",
    id: "PR",
  },
  {
    name: "Rhode Island",
    id: "RI",
  },
  {
    name: "South Carolina",
    id: "SC",
  },
  {
    name: "South Dakota",
    id: "SD",
  },
  {
    name: "Tennessee",
    id: "TN",
  },
  {
    name: "Texas",
    id: "TX",
  },
  {
    name: "Utah",
    id: "UT",
  },
  {
    name: "Vermont",
    id: "VT",
  },
  {
    name: "Virgin Islands",
    id: "VI",
  },
  {
    name: "Virginia",
    id: "VA",
  },
  {
    name: "Washington",
    id: "WA",
  },
  {
    name: "West Virginia",
    id: "WV",
  },
  {
    name: "Wisconsin",
    id: "WI",
  },
  {
    name: "Wyoming",
    id: "WY",
  },
];

export const orderStatusEnum = {
  Cancelled: "Cancelled",
  Canceled: "Canceled",
  Complete: "Complete",
  Placed: "Placed",
  Active: "Active",
  Failed_Pick_Up: "Failed Pick Up",
  Failed_Drop_Off: "Failed Drop Off",
  Action_Needed: "Action Needed",
  Denied: "Denied",
  AutoAssigned: "AutoAssigned",
  Interrupted: "Interrupted",
  Returned: "Returned",
  Delivered: "Delivered",
  In_Transit: "In Transit",
  Order_Shipped: "Order Shipped",
  Order_Delivered: "Order Delivered",
  Demo_Scheduled: "Demo Scheduled",
};

export const phoneFormCreator = (phone) => {
  let validNumber = phone;

  phone = phone.replace(/\+/g, "");
  if (phone) {
    const matches = phone.replace(/[^0-9]/g, "");
    if (matches) {
      const digits = matches.split("");
      if (matches.length === 10) {
        const areaCode = digits.slice(0, 3);
        const firstPart = digits.slice(3, 6);
        const secondPart = digits.slice(6);
        validNumber = `(${areaCode.join("")}) ${firstPart.join(
          ""
        )}-${secondPart.join("")}`;
      } else if (matches.length === 11) {
        const areaCode = digits.slice(1, 4);
        const firstPart = digits.slice(4, 7);
        const secondPart = digits.slice(7);
        validNumber = `+${digits[0]} (${areaCode.join("")}) ${firstPart.join(
          ""
        )}-${secondPart.join("")}`;
      }
    }
  }
  return validNumber;
};

export const photoLimitSize = 5_242_000;

export const txTypesEnum = {
  CCPreAuthForRental: 1,
  CCCapture: 2,
  CCCharge: 3,
  PreAuthDeposit: 5,
  CancelPreCapture: 6,
  PartialRefundPostCapture: 7,
  PartialRefundPreCapture: 8,
  ReleaseDeposit: 30,
};

export const transItemsTypesEnum = {
  ProductSalesTaxRequired: 0,
  ProductSalesTaxNotRequired: 1,

  BundledItem: 10,
  ProfiledItem: 11,
  AddOnConsumable: 12,
  AddOnNonConsumable: 13,

  PerimeterPricing: 20,
  DeliveryPricing: 21,

  ReschedulingFee: 30,
  ExtraNightFee: 31,

  Discount: 40,

  CustomCharge: 50,
  DriverTip: 51,

  Transaction: 100,
  SubTransactionItem: 101,
  PreAuthRelease: 200,
};

export const consolidateTransactionsArray = (arr) => {
  // Create an object to store unique items and their quantities
  const consolidatedObj = arr.reduce((acc, item) => {
    if (!acc[item.sourceReferanceId]) {
      // If the item does not exist in the accumulator, add it
      acc[item.sourceReferanceId] = item;
      acc[item.sourceReferanceId].productTotal = item.baseCost?.amount;
      acc[item.sourceReferanceId].damageProtectionTotal =
        item.damageProtection?.price?.amount;
    } else {
      // If the item already exists, add the quantity to the existing item
      acc[item.sourceReferanceId].quantity += item.quantity;
      acc[item.sourceReferanceId].productTotal += item.baseCost?.amount;
      acc[item.sourceReferanceId].damageProtectionTotal +=
        item.damageProtection?.price?.amount;
    }
    return acc;
  }, {});
  // Convert the consolidated object back to an array
  const consolidatedArray = Object.values(consolidatedObj);
  return consolidatedArray;
};
