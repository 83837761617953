import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import MixpanelContext from "react-mixpanel";
import * as helpers from "../../utils";
import ItemTile from "../partials/ItemTile";
import { getProductsVotable } from "../../reducers/itemsReducer";

import {
  showSnackbar,
  fetchVotableProducts,
  setVoteForProductAfterAuth,
  openLogInModal,
  setSignInMessage,
} from "../../actions";
import { getToken, getIsLoggedIn } from "../../reducers/authReducer";
import SkiptiSpinner from "../skipti/Spinner";

class VoteItems extends Component {
  static propTypes = {
    setVotingRowPopulated: PropTypes.func,
    items: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      isLoaded: PropTypes.bool.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    setVotingRowPopulated: undefined,
  };

  static contextType = MixpanelContext;

  state = {
    pageNumber: 1,
    pageSize: 6,
    totalPages: 1,
    products: [],
    voted: localStorage.getItem("votedAnItem"),
  };

  static getDerivedStateFromProps = (props, state) => {
    const newProducts =
      props.items && props.items.isLoaded ? props.items.data : [];

    return {
      pageNumber: state.pageNumber,
      totalPages: props.items.isLoaded
        ? props.items.data.totalPages
        : state.totalPages,
      products: _(state.products)
        .keyBy("resourceId")
        .merge(_.keyBy(newProducts, "resourceId"))
        .values()
        .value(),
    };
  };

  async componentDidMount() {
    localStorage.setItem("votedAnItem", "false");
    await this.fetchData();
    this.setState({
      voted: localStorage.getItem("votedAnItem"),
    });
  }

  fetchData = async () => {
    const { pageNumber } = this.state;
    this.setState({
      pageNumber: pageNumber + 1,
    });
    if (
      this.props.setVotingRowPopulated &&
      this.props.items.isLoaded === true &&
      this.props.items.data.length !== 0
    ) {
      this.props.setVotingRowPopulated(true);
    }
  };

  voteItem = async (productId, productName, productCategory, productPrice) => {
    const { pageSize, pageNumber } = this.state;

    const voteThisProduct = {
      productId,
      productName,
      productCategory,
      productPrice,
    };

    if (this.props.isLoggedIn) {
      const voteResult = await helpers.voteProduct(
        this.props.jwt,
        voteThisProduct,
        this.context,
        window.analytics
      );

      if (voteResult.message && voteResult.status !== 200) {
        this.props.showSnackbar(voteResult.message, "error");
      } else {
        this.props.showSnackbar("Item voted successfully!", "success");
      }
    } else {
      this.props.setVoteForProductAfterAuth(voteThisProduct);
      this.props.setSignInMessage(
        "Thanks for voting. Please create an account or use your login so we can notify you when this item becomes available. Accounts are free and you will not be required to rent."
      );
      this.props.openLogInModal();
    }

    this.props.fetchVotableProducts({
      pageSize,
      pageNumber,
    });
  };

  render() {
    const { props } = this;
    const { pageSize, pageNumber, products } = this.state;

    const items =
      props.items && props.items.isLoaded && props.items.data
        ? props.items.data
        : {};
    return (
      <>
        <SkiptiSpinner
          loading={props.items.isFetching}
          opacity="0"
          absolute={false}
        />
        {items.length > 0 ? (
          <div className="tw-my-12 tw-w-full tw-px-4 tw-mx-auto">
            <div className="tw-flex tw-flex-wrap">
              {products.map((item) => (
                <ItemTile
                  {...item}
                  noOfProducts={6}
                  votingMode
                  voted={this.state.voted}
                  key={item.resourceId}
                  castVote={this.voteItem}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                />
              ))}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jwt: getToken(state),
  items: getProductsVotable(state),
  isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps, {
  showSnackbar,
  fetchVotableProducts,
  setVoteForProductAfterAuth,
  openLogInModal,
  setSignInMessage,
})(VoteItems);
