import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { showSnackbar } from "../../actions/snackbar";
import { getToken, getUserRoles } from "../../reducers/authReducer";
import { serverUrl, userRoles } from "../../utils";

export const selectDepotFolder = (state) => state.policyPortal.depots;
export const selectAllDepotFolder = (state) => state.policyPortal.allDepots;
export const selectDepotFoldersMeta = (state) => state.policyPortal.depotsMeta;
export const selectDepotsFiles = (state) => state.policyPortal.depotsFiles;
export const selectDepotsFilesMeta = (state) =>
  state.policyPortal.depotsFilesMeta;
export const selectIsUploadingFile = (state) => state.policyPortal.isUploading;
export const selectIsIsFetching = (state) => state.policyPortal.isFetching;

export const handleOpenPdf = async (path, setIsPending) => {
  setIsPending(true);
  try {
    const res = await axios.get(path, {
      headers: {
        "Content-Type": "application/pdf",
        Accept: "application/octet-stream",
      },
      crossDomain: true,
      responseType: "blob",
    });
    const blob = new Blob([res.data], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  } catch (e) {
    console.error("handleOpenPdf", e);
  } finally {
    setIsPending(false);
  }

  // window.URL.revokeObjectURL(url);
};

export const getDepots = createAsyncThunk(
  "policyPortal/getDepots",
  async ({ pageNumber = 1, pageSize = 15 }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const roles = getUserRoles(thunkAPI.getState());
    try {
      const res = await axios.get(`${serverUrl}/api/v1/policy/depotsfolder`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageNumber,
          pageSize,
        },
      });

      if (roles.includes(userRoles.PartnerAdmin)) {
        res.data.depots = [
          ...res.data.depots,
          {
            folderName: "Add new document",
            id: "add_new_document",
            addPdf: true,
          },
        ];
      }
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getAllDepots = createAsyncThunk(
  "policyPortal/getAllDepots",
  async ({ pageNumber = 1 }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.get(`${serverUrl}/api/v1/policy/depotsfolder`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageNumber,
          pageSize: "-1",
        },
      });
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const getDepotsFiles = createAsyncThunk(
  "policyPortal/getDepotsFiles",
  async ({ folderId, pageNumber }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const roles = getUserRoles(thunkAPI.getState());

    try {
      const res = await axios.get(`${serverUrl}/api/v1/policy/folderdocs`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          pageNumber,
          pageSize: 15,
          folderId,
        },
      });
      if (roles.includes(userRoles.PartnerAdmin)) {
        res.data.documents = [
          ...res.data.documents,
          {
            docName: "Add new document",
            id: "add_new_document",
            addPdf: true,
          },
        ];
      }

      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const uploadPdf = createAsyncThunk(
  "policyPortal/uploadPdf",
  async ({ file, name, depotIds }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    const formData = new FormData();

    depotIds.forEach((id) => {
      formData.append("depotIds[]", id);
    });

    formData.set("file", file);
    formData.set("name", name);
    try {
      const res = await axios.post(
        `${serverUrl}/api/v1/policy/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const deletePdf = createAsyncThunk(
  "policyPortal/deletePdf",
  async ({ fileId }, thunkAPI) => {
    const jwt = getToken(thunkAPI.getState());
    try {
      const res = await axios.delete(
        `${serverUrl}/api/v1/policy/doc/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      thunkAPI.dispatch(
        showSnackbar(
          (e.response && e.response.data) || e.message,
          "error",
          null,
          null,
          JSON.stringify(e)
        )
      );
      throw e;
    }
  }
);

export const slice = createSlice({
  name: "policyPortal",
  initialState: {
    allDepots: [],
    depots: [],
    depotsMeta: {
      pageNumber: 1,
      totalPages: 1,
    },
    isFetching: false,
    isUploading: false,
    depotsFiles: [],
    depotsFilesMeta: {
      pageNumber: 1,
      totalPages: 1,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepots.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getDepots.rejected, (state) => {
        state.isFetching = false;
        state.depots = [];
        state.depotsMeta = {
          pageNumber: 1,
          totalPages: 0,
        };
      })
      .addCase(getDepots.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.depots = payload.depots;
        state.depotsMeta = {
          pageNumber: payload.pageNumber,
          totalPages: payload.totalPages,
        };
      })
      .addCase(getAllDepots.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllDepots.rejected, (state) => {
        state.isFetching = false;
        state.allDepots = [];
      })
      .addCase(getAllDepots.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.allDepots = payload.depots;
      })
      .addCase(getDepotsFiles.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getDepotsFiles.rejected, (state) => {
        state.isFetching = false;
        state.depotsFiles = [];
        state.depotsFilesMeta = {
          pageNumber: 1,
          totalPages: 1,
        };
      })
      .addCase(getDepotsFiles.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.depotsFiles = payload.documents;
        state.depotsFilesMeta = {
          pageNumber: payload.pageNumber,
          totalPages: payload.totalPages,
        };
      })
      .addCase(uploadPdf.pending, (state) => {
        state.isUploading = true;
      })
      .addCase(uploadPdf.fulfilled, (state) => {
        state.isUploading = false;
      })
      .addCase(uploadPdf.rejected, (state) => {
        state.isUploading = false;
      });
  },
});

// export const {} = slice.actions;

export default slice.reducer;
