const itemDemoReducer = (
  state = {
    selectedItems: [],
    selectedAppointmentItems: [],
  },
  action
) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_ALL_DEMO_ITEMS":
      return { ...state, allDemoItems: payload };
    case "SET_DEMO_ITEMS":
      return { ...state, selectedItems: payload };
    case "SET_APPOINTMENT_ITEMS":
      return {
        ...state,
        selectedAppointmentItems: [
          ...state.selectedAppointmentItems,
          ...payload,
        ],
      };
    case "SET_APPOINTMENT_ITEM":
      return {
        ...state,
        selectedAppointmentItems: payload,
      };
    default:
      return state;
  }
};

export const getSelectedItems = (state) => state.itemDemoReducer.selectedItems;
export const getAllDemoItems = (state) => state.itemDemoReducer.allDemoItems;
export const getAppointmentItem = (state) =>
  state.itemDemoReducer.selectedAppointmentItems;

export const removeAppointment = (resourceId) => (dispatch, getState) => {
  const appointments =
    getState().itemDemoReducer.selectedAppointmentItems.filter(
      (el) => el.resourceId !== resourceId
    );
  dispatch({
    type: "SET_APPOINTMENT_ITEM",
    payload: appointments,
  });
};

export default itemDemoReducer;
